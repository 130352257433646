import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Progress from 'antd/es/progress';
import Statistic from 'antd/es/statistic';
import Spin from 'antd/es/spin';
import Drawer from 'antd/es/drawer';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';
import Radio from 'antd/es/radio';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

import PaymentMethodDlg from './UI_Module/PaymentMethodDlg.js'
import { UserActiveUpdate } from "../../functions/DataStatistic.js"

import '../../styles/ClientSide/store_cut_price_page.css';

const { Countdown } = Statistic;
const { Option } = Select;
// const mapStyles = { width: '96vw', height: '54vw' };
// const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

class StoreCutPrice extends Component {
    constructor(props) {
        super();

        let strParams = window.location.search;
        strParams = strParams.substring(strParams.indexOf('?') + 1);

        this.iItem = parseInt(strParams.split('&')[0].split('=')[1], 10);

        this.state = {
            bTypeLoading: true,
            bItemLoading: true,
            iCurrentItemType: 0,
            bItemDetailsShow: false,
            bShareDlg: false,
            iShareDlg: -1,
            bItemBuyShow: false,
            iItemBuyType: -1,
            bLogin: false,
            bSelectPaymentDlg: false,
            iCurrentItemNum: 1,
            bPaymentResultDlg: false,
            iPaymentResult: 0,
            bToDoor: false,
            bLocating: false,
            bChangeContactInfoDlg: false,
            strPhoneCode: "+1",
            iContactInfo: 0,
            iCurrentArea: 0
        };
        this.arrItemTypesData = [];
        this.arrItemsData = [];
        this.arrCutPriceData = [];
        this.iCurrentCutPriceIndex = 0;
        this.objCurrentItem = { id: 0 };
        this.strCurrentUserCode = "";
        this.objUserInfo = -1;
        this.objStoreInfo = {};

        this.arrPaymentMethod = ["选择支付方式", "信用卡/借记卡", "微信", "支付宝"];
        this.iConfirmedPaymentMethod = 0;
        this.strCurrentLast4 = "";
        this.strCurrentCardBrand = "";
        this.objCardInfo = {};
        this.arrCards = [];
        this.strOrderID = "";

        this.strCurrentLocation = "";
        // this.objCurrentPosition = { lat: 0, lng: 0 };
        // this.strCurrentName = "";
        // this.strCurrentPhone = "";

        this.arrBrandIntro = [
            "华美心月饼不但深受市民欢迎，而且更屡获殊荣。香港美心月饼不但是本港首间荣获香港工业总会颁发Q唛优质产品标志的月饼品牌，且更连续多年荣获比利时Monde Selection颁发「世界精选优质食品奖项」，足证质素备受国际认同。",
            "深圳富锦食品工业有限责任公司创始于1997 年，是一家集研发、生产、销售、贸易为一体的大型食品企业。“富锦”品牌已荣获多项奖项，是“广东省著名商标”，是广东省节日食品市场的主导品牌之一。",
            "香港圣安娜饼屋自于1972 年在香港成立，是香港利丰集团成员，目前粤港澳分店数目已逾125间。香港首家荣获ISO9001 质量管理及HACCP 食品卫生安全重点控制系统证书连锁西饼店，获“香港名牌”、“香港超级品牌”、“中国名饼”、“全国放心月饼”、“名牌月饼”等奖项。",
            "麦轩食品隶属于深圳市德隆轩商贸有限责任公司是一家集设计、研发、生产、销售、服务于一体的食品企业；主要产品有：中式传统饼点（主导产品有老婆饼、合桃酥、鸡仔饼）、月饼系列（广式月饼、港式月饼、台式酥饼、异国风情月饼）和年货系列（油炸类糕点、烘烤类糕点、曲奇饼干）等至臻至美饼点系列。",
            "星巴克月饼，无论是特制醇正星巴克哥伦比亚咖啡，加入精选比利时榛子脆软馅所制成的哥伦比亚咖啡牛奶榛子脆口味，还是蕴含着丰富水果风味的星巴克肯亚咖啡搭配香浓芝士，包裹着蓝莓软馅的肯亚咖啡芝士蓝莓口味，都会给你带来前所未有的味觉体验。"
        ];
        this.arrArea = ["大温地区", "大多地区"];
        this.iCurrentArea = 0;

    }
    // 查看商品详情
    ItemDetailsShow = (iItemIndex) => {
        this.objCurrentItem = this.arrItemsData[iItemIndex];
        this.iItem = this.objCurrentItem.id;
        this.setState({
            ...this.state,
            bItemDetailsShow: true
        });
    }
    // 关闭商品详情
    ItemDetailsClose = () => {
        this.SwitchType(this.state.iCurrentItemType);
        this.iItem = 0;
        this.setState({
            ...this.state,
            bItemDetailsShow: false
        });
    }
    // 进行商品数量选择
    ItemBuyShow = (iBuyType) => {
        if (this.state.bLogin) {
            this.setState({
                ...this.state,
                bItemBuyShow: true,
                iItemBuyType: iBuyType,
                iCurrentItemNum: 1
            });
        } else {
            if (this.iItem > 0)
                window.location.href = "/webapp/login?store=10005&item=" + this.iItem;
            else
                window.location.href = "/webapp/login?store=10005";
        }
    }
    // 关闭商品选择
    ItemBuyClose = () => {
        this.setState({
            ...this.state,
            bItemBuyShow: false
        });
    }
    // 切换商品类别
    SwitchType = (index) => {
        if (index === this.state.iCurrentItemType && !this.state.bItemDetailsShow)
            return;
        this.setState({
            ...this.state,
            bItemLoading: true,
            iCurrentItemType: index
        });
        // this.GetItemsData(index);
        // 获取团购信息
        axios('/GetCutPrice', { params: { store: this.props.iStoreID } })
            .then(({ data }) => {
                this.arrCutPriceData = data;
                this.GetItemsData(index);
            }).catch(function (error) { console.log(error); });
    }
    // 获取商品类别/团购数据
    GetStoreData = () => {
        axios('/GetStoreInfo', { params: { id: this.props.iStoreID } })
            .then(({ data }) => {
                this.objStoreInfo = data[0];
                this.strCurrentLocation = this.objStoreInfo.address;
                // 获取商品分类信息
                axios('/GetStoreItemTypes', { params: { store_id: this.props.iStoreID } })
                    .then(({ data }) => {
                        this.arrItemTypesData = data;
                        // 获取团购信息
                        axios('/GetCutPrice', { params: { store: this.props.iStoreID } })
                            .then(({ data }) => {
                                this.arrCutPriceData = data;
                                console.log("GetStoreData >>> All CutPriceData: ", this.arrCutPriceData);
                                this.GetItemsData(0);
                                // 如果指定了商品（来自分享）
                                if (this.iItem > 0) {
                                    axios('/GetItem', { params: { id: this.iItem } })
                                        .then(({ data }) => {
                                            if (data.length > 0) {
                                                this.objCurrentItem = data[0];
                                                this.objCurrentItem.cut_price = 0.0;
                                                this.objCurrentItem.num = 0;
                                                this.objCurrentItem.expire_date = new Date();
                                                for (let j = 0; j < this.arrCutPriceData.length; j++) {
                                                    if (this.objCurrentItem.id === this.arrCutPriceData[j].item) {
                                                        this.objCurrentItem.cut_price_index = j;
                                                        this.objCurrentItem.cut_price = parseFloat(this.arrCutPriceData[j].price).toFixed(2);
                                                        this.objCurrentItem.num = parseInt(this.arrCutPriceData[j].num, 10);
                                                        this.objCurrentItem.expire_date = new Date(this.arrCutPriceData[j].expire_date);
                                                        break;
                                                    }
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    bItemDetailsShow: true,
                                                    bTypeLoading: false
                                                });
                                            } else {
                                                console.log("非有效分享链接，无法定位到指定商品！");
                                                this.setState({
                                                    ...this.state,
                                                    bTypeLoading: false
                                                })
                                            }
                                        });
                                } else {
                                    this.setState({
                                        ...this.state,
                                        bTypeLoading: false
                                    })
                                }
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 获取商品数据
    GetItemsData = (iItemType) => {
        let strType = '%' + this.arrItemTypesData[iItemType].id.toString() + '%';
        axios('/GetItems', { params: { store_id: this.props.iStoreID, type: strType } })
            .then(({ data }) => {
                this.arrItemsData = data;
                for (let i = 0; i < this.arrItemsData.length; i++) {
                    this.arrItemsData[i].cut_price = 0.0;
                    this.arrItemsData[i].num = 0;
                    this.arrItemsData[i].expire_date = new Date();
                    for (let j = 0; j < this.arrCutPriceData.length; j++) {
                        if (this.arrItemsData[i].id === this.arrCutPriceData[j].item) {
                            this.arrItemsData[i].cut_price_batch = this.arrCutPriceData[j].batch;
                            this.arrItemsData[i].cut_price_index = j;
                            let strCutPrice = this.arrCutPriceData[j].price;
                            if (strCutPrice.indexOf(',') >= 0) {
                                this.arrItemsData[i].cut_price = parseFloat(strCutPrice.split(',')[this.iCurrentArea]).toFixed(2);
                            } else {
                                this.arrItemsData[i].cut_price = parseFloat(strCutPrice).toFixed(2);
                            }
                            this.arrItemsData[i].num = parseInt(this.arrCutPriceData[j].num, 10);
                            this.arrItemsData[i].expire_date = new Date(this.arrCutPriceData[j].expire_date);
                            this.GetItemCutPriceInfo(this.arrCutPriceData[j], this.arrItemsData[i]);
                            break;
                        }
                    }
                }
                // console.log("Item Data : ", this.arrItemsData);
                // this.setState({
                //     ...this.state,
                //     bItemLoading: false
                // })
            });
    }
    // 获取某个商品当前的团购数据（主要用于获取batch信息，更新进度条，更新batch等）
    GetItemCutPriceInfo = (objCutPrice, objItem) => {
        axios('/GetItemCutPrice', { params: { id: objCutPrice.id } })
            .then(({ data }) => {
                console.log("GetItemCutPrice >>> ", data);
                let iCurrentCutPriceNum = 0;
                if (data.orders.length > 0) {
                    for (let i = 0; i < data.orders.length; i++) {
                        iCurrentCutPriceNum += JSON.parse(data.orders[i].items).total_num;
                    }
                    if (iCurrentCutPriceNum >= objCutPrice.num) {
                        console.log("当前批次的团购已满，当前的订单被分配到新的团购批次！")
                        // 无论当前用户的订单购买多少商品
                        objCutPrice.batch++;
                        // 更新 cut_price 的 batch 信息 & 相关 order 的 cut_price 信息
                        axios.post('/UpdateCutPriceBatch', { objCutPrice: objCutPrice })
                            .then(({ data }) => {
                                console.log("UpdateCutPriceBatch", data);
                                iCurrentCutPriceNum = 0;
                                // objItem.cut_price_num = iCurrentCutPriceNum;
                                this.setState({
                                    ...this.state,
                                    bItemLoading: false
                                });
                            });
                    }
                }
                objItem.cut_price_num = iCurrentCutPriceNum;
                this.setState({
                    ...this.state,
                    bItemLoading: false
                })
            }).catch(function (error) { console.log(error); });
    }
    // 客服/分享弹窗
    ShowShareDlg = (bShow, iDlg) => {
        this.setState({
            bShareDlg: bShow,
            iShareDlg: iDlg
        });
    };
    // 跳转到用户中心
    JumpToUserCenter = () => {
        window.location.href = "/webapp/user?tab=3&store=10005";
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === 1) {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.contact === null || this.objUserInfo.contact.trim() === "") {
                        this.objUserInfo.contact = this.objUserInfo.name;
                    }
                    let strPhoneCode = "+1";
                    if (this.objUserInfo.contact_phone === null || this.objUserInfo.contact_phone === "") {
                        if (this.objUserInfo.phone === null || this.objUserInfo.phone === "") {
                            this.objUserInfo.contact_phone = "无";
                        } else {
                            strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
                            this.objUserInfo.contact_phone = this.objUserInfo.phone;
                        }
                    } else {
                        strPhoneCode = this.objUserInfo.contact_phone.split(') ')[0].split('(')[1];
                    }
                    if (this.objUserInfo.address === null || this.objUserInfo.address.trim() === "") {
                        this.objUserInfo.address = "无";
                    }
                    this.GetCardList();
                    this.GetOrderOnlyInfo();
                    this.setState({
                        ...this.state,
                        strPhoneCode: strPhoneCode,
                        bLogin: true
                    });
                } else {
                    this.objUserInfo = -1;
                    this.setState({
                        ...this.state,
                        bLogin: false
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 从Cookie中获取联系姓名/联系电话/备注等订单详情信息
    GetOrderOnlyInfo = () => {
        let objOrderInfo = cookie.load('wm_order_info');
        if (objOrderInfo) {
            if (objOrderInfo !== null) {
                if (objOrderInfo !== "") {
                    this.objUserInfo.contact = objOrderInfo.contact;
                    this.objUserInfo.contact_phone = objOrderInfo.phone;
                    if (objOrderInfo.address !== undefined)
                        this.objUserInfo.address = objOrderInfo.address;
                } else {
                    cookie.save('wm_order_info', "", { path: '/' });
                }
            } else {
                cookie.save('wm_order_info', "", { path: '/' });
            }
        } else {
            cookie.save('wm_order_info', "", { path: '/' });
        }
        console.log(objOrderInfo);
    }
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付弹窗结果返回
    PaymentMethodResult = (bConfirm, iCardBrand, iCardLast4, iPaymentMethod, objCardInfo) => {
        if (bConfirm) {
            this.strCurrentLast4 = iCardLast4;
            this.strCurrentCardBrand = iCardBrand;
            this.iConfirmedPaymentMethod = iPaymentMethod;
            this.objCardInfo = objCardInfo;
        }
        if (iPaymentMethod === -1) {
            this.strCurrentLast4 = "";
            this.strCurrentCardBrand = "";
            this.iConfirmedPaymentMethod = 0;
            this.objCardInfo = {};
        }
        this.setState({
            ...this.state,
            bSelectPaymentDlg: false
        });
    }
    // Get Customer's Exist Cards List
    GetCardList = () => {
        // 当该用户的支付方式不为空，且是使用卡片支付时，采取调取卡片列表信息
        if (this.objUserInfo === -1) {
            return;
        }
        if (this.objUserInfo.payment_id === null) {
            return;
        }
        if (this.objUserInfo.payment_id < 0) {
            return;
        }
        axios('/ListCustomerCards', {
            params: this.objUserInfo
        }).then(({ data }) => {
            console.log("List Customer Cards Succeed ...");
            console.log(data);
            this.arrCards = data.confirm;
            this.GetCustomerDefaultPaymentMethod();
        }).catch(function (error) { console.log(error); });
    }
    // 获取用户当前的支付方式（如果是卡片，获取卡片相应信息）
    GetCustomerDefaultPaymentMethod = () => {
        axios('/GetUserPaymentMethod', {
            params: { code: this.objUserInfo.code }
        }).then(({ data }) => {
            let iCardID = 0;
            if (data[0].payment_id !== null) {
                // 卡片
                if (data[0].payment_id >= 0) {
                    iCardID = data[0].payment_id;
                    this.iConfirmedPaymentMethod = 1;
                    this.strCurrentCardBrand = this.arrCards[iCardID].card.brand;
                    this.strCurrentLast4 = this.arrCards[iCardID].card.last4;
                    this.objCardInfo = this.arrCards[iCardID];
                }
            }
        }).catch(function (error) { console.log(error); });
    }
    // 移除商品
    RemoveItem = () => {
        let iItemNum = this.state.iCurrentItemNum - 1;
        if (iItemNum <= 1)
            iItemNum = 1;
        this.setState({
            ...this.state,
            iCurrentItemNum: iItemNum
        });
    }
    // 增加商品
    AddItem = () => {
        let iItemNum = this.state.iCurrentItemNum + 1;
        if (iItemNum >= 999)
            iItemNum = 999;
        this.setState({
            ...this.state,
            iCurrentItemNum: iItemNum
        });
    }
    // 下单付款
    ConfirmAndPay = (objPrice) => {
        if (this.iConfirmedPaymentMethod === 0 || this.strCurrentLast4 === "") {
            message.warning("无法下单支付，请选择有效的支付方式！");
            return;
        }
        if (this.objUserInfo.address === null || this.objUserInfo.address.trim() === "" || this.objUserInfo.address === "无") {
            if (this.state.bToDoor) {
                message.warning("邮寄地址不能为空！");
                return;
            }
        }
        if (this.objUserInfo.contact === null || this.objUserInfo.contact.trim() === "") {
            if (this.objUserInfo.name === null || this.objUserInfo.name.trim() === "") {
                message.warning("联系人不能为空！");
                return;
            } else {
                this.objUserInfo.contact = this.objUserInfo.name;
            }
        }
        if (this.objUserInfo.contact_phone === null || this.objUserInfo.contact_phone.trim() === "" || this.objUserInfo.contact_phone === "无") {
            if (this.objUserInfo.phone === null || this.objUserInfo.phone.trim() === "" || this.objUserInfo.phone === "无") {
                message.warning("电话不能为空！");
                return;
            } else {
                this.objUserInfo.contact_phone = this.objUserInfo.phone;
            }
        }
        if (this.objUserInfo.address === "无") {
            this.objUserInfo.address = "";
        }
        if (this.objUserInfo.phone === "无") {
            this.objUserInfo.phone = "";
        }
        if (this.objUserInfo.contact_phone === "无") {
            this.objUserInfo.contact_phone = "";
        }

        // 更新用户联系方式
        this.UpdateUserContactInfo();

        this.setState({
            ...this.state,
            bPaymentResultDlg: true,
            iPaymentResult: 0
        });
        // DataStatistic
        let arrItems = this.objCurrentItem.id;
        UserActiveUpdate(17, { active: "下单了", arrItems: arrItems });
        console.log("arrItem = ", arrItems);

        let objItem = Object.assign({}, this.objCurrentItem);
        delete objItem.expire_date;
        delete objItem.cut_price;
        delete objItem.cut_price_index;
        delete objItem.num;
        delete objItem.cut_price_num;
        let objCart = {
            iItemTotalNum: this.state.iCurrentItemNum,
            fItemTotalPrice: objPrice.fTotalPrice,
            arrBudgetItems: [{ num: this.state.iCurrentItemNum, item: objItem }]
        };
        console.log("确认下单……");
        console.log("用户信息：", this.objUserInfo);
        console.log("价格信息：", objPrice);
        console.log("购买类型：", this.state.iItemBuyType);

        /******** 支付流程开始 ********/
        // 1. Stripe 订单生成，扣款
        axios.post('/PlaceOrderAndPay', {
            objUserInfo: this.objUserInfo,
            objCardInfo: this.objCardInfo,
            objCartInfo: objCart,
            objStoreInfo: this.objStoreInfo,
            fPriceOff: 0
        }).then(({ data }) => {
            console.log("支付完成后的Strip数据返回", data);
            // 支付成功
            if (data.message === "succeed" && data.pid !== "") {
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: 1
                });
                // DataStatistic
                UserActiveUpdate(18, { active: "下单成功", arrItems: arrItems });

                console.log("开始记录订单……");
                let strPid = data.pid;
                let objOrderItems = { total_num: objCart.iItemTotalNum, items: [] };
                for (let i = 0; i < objCart.arrBudgetItems.length; i++) {
                    let item = { id: 0, num: 0 };
                    item.id = objCart.arrBudgetItems[i].item.id;
                    item.name = objCart.arrBudgetItems[i].item.name;
                    item.num = objCart.arrBudgetItems[i].num;
                    objOrderItems.items.push(item);
                }
                // 2. 更新商品的sale数(包括数据库中的)
                this.objCurrentItem.sale += objOrderItems.total_num;
                axios.post('/UpdateItemSale', { item: this.objCurrentItem })
                    .then(({ data }) => {
                        console.log("商品销量更新：", data);
                    });

                let objCutPrice = this.arrCutPriceData[this.objCurrentItem.cut_price_index];
                // 3.1 团购：获取并更新cut_price相关信息，已当前用户端此时获得的数据为准
                if (this.state.iItemBuyType === 1) {
                    console.log("拼单购买的记录……");
                    axios('/GetItemCutPrice', { params: { id: objCutPrice.id } })
                        .then(({ data }) => {
                            console.log("获取最新的团购信息成功 >>> ", data);
                            if (data.status === 1) {
                                let iCurrentCutPriceNum = 0;
                                if (data.orders.length > 0) {
                                    for (let i = 0; i < data.orders.length; i++) {
                                        iCurrentCutPriceNum += JSON.parse(data.orders[i].items).total_num;
                                    }
                                    if (iCurrentCutPriceNum >= objCutPrice.num) {
                                        console.log("当前批次的团购已满，当前的订单被分配到新的团购批次！")
                                        // 无论当前用户的订单购买多少商品
                                        objCutPrice.batch++;
                                        // 4. 更新 cut_price 的 batch 信息 & 相关 order 的 cut_price 信息
                                        axios.post('/UpdateCutPriceBatch', { objCutPrice: objCutPrice })
                                            .then(({ data }) => {
                                                console.log("UpdateCutPriceBatch", data);
                                                iCurrentCutPriceNum = 0;
                                                this.setState({
                                                    ...this.state,
                                                    bItemLoading: false
                                                });
                                            });
                                    }
                                }

                                let iDeliveryWay = 0;
                                if (this.state.bToDoor)
                                    iDeliveryWay = 1;
                                // 生成目标订单信息
                                let objOrderInfo = {
                                    pid: strPid,
                                    status: 1,
                                    final_price: parseInt(objPrice.fFinalPrice.toFixed(2) * 100, 10),
                                    stripe_fee: parseInt(objPrice.fStripFee.toFixed(2) * 100, 10),
                                    total_price: parseInt(objPrice.fTotalPrice.toFixed(2) * 100, 10),
                                    price_off: 0,
                                    items: JSON.stringify(objOrderItems),
                                    store_id: this.objStoreInfo.id,
                                    store_name: this.objStoreInfo.store_name,
                                    user_code: this.objUserInfo.code,
                                    client_order_time: new Date(),
                                    card_info: this.strCurrentCardBrand + "," + this.strCurrentLast4,
                                    contact: this.objUserInfo.contact,
                                    phone: this.objUserInfo.phone,
                                    comments: "拼单中",
                                    cut_price_id: objCutPrice.id,
                                    cut_price_batch: objCutPrice.batch,  // * 该batch号为所在批次的
                                    cut_price_status: 1,
                                    delivery_way: iDeliveryWay,
                                    delivery_fee: 0,
                                    address: this.strCurrentLocation,
                                    area: this.arrArea[this.iCurrentArea],
                                    viewed: 0
                                };

                                console.log("订单信息：")
                                console.log(objOrderInfo);

                                // 5. 订单的入库记录
                                axios.post('/RecordOrder', {
                                    objOrderInfo: objOrderInfo, objUserInfo: this.objUserInfo
                                }).then(({ data }) => {
                                    console.log("订单记录完成，返回数据 ： ", data);
                                    if (data.oid === -1) {
                                        // 订单在mouthing数据库记录异常
                                        message.warning(data.message);
                                        this.setState({
                                            ...this.state,
                                            bPaymentResultDlg: true,
                                            iPaymentResult: -2
                                        });
                                    } else {
                                        // 订单支付记录成功，此时订单结果对话框处于打开状态并显示结果
                                        this.strOrderID = data.oid;
                                        this.setState({
                                            ...this.state,
                                            bPaymentResultDlg: true,
                                            iPaymentResult: 1
                                        });
                                    }
                                }).catch(function (error) { console.log(error); });

                            } else {
                                message.info("为获取到有效的团购信息，请到用户中心检查！");
                                this.setState({
                                    ...this.state,
                                    bPaymentResultDlg: true,
                                    iPaymentResult: -2
                                });
                            }
                        });
                }
                // 3.2 单独购买
                if (this.state.iItemBuyType === 0) {
                    console.log("单独购买的记录……");
                    let iDeliveryWay = 0;
                    if (this.state.bToDoor)
                        iDeliveryWay = 1;
                    // 生成目标订单信息
                    let objOrderInfo = {
                        pid: strPid,
                        status: 1,
                        final_price: parseInt(objPrice.fFinalPrice.toFixed(2) * 100, 10),
                        stripe_fee: parseInt(objPrice.fStripFee.toFixed(2) * 100, 10),
                        total_price: parseInt(objPrice.fTotalPrice.toFixed(2) * 100, 10),
                        price_off: 0,
                        items: JSON.stringify(objOrderItems),
                        store_id: this.objStoreInfo.id,
                        store_name: this.objStoreInfo.store_name,
                        user_code: this.objUserInfo.code,
                        client_order_time: new Date(),
                        card_info: this.strCurrentCardBrand + "," + this.strCurrentLast4,
                        contact: this.objUserInfo.contact,
                        phone: this.objUserInfo.phone,
                        comments: "单独购买",
                        cut_price_id: objCutPrice.id,
                        cut_price_batch: -1,  // 单独购买未特殊批号，随时发货
                        cut_price_status: 1,
                        delivery_way: iDeliveryWay,
                        delivery_fee: 0,
                        address: this.strCurrentLocation,
                        area: this.arrArea[this.iCurrentArea],
                        viewed: 0
                    };

                    // 5. 订单的入库记录
                    axios.post('/RecordOrder', {
                        objOrderInfo: objOrderInfo, objUserInfo: this.objUserInfo
                    }).then(({ data }) => {
                        console.log("订单记录完成，返回数据 ： ", data);
                        if (data.oid === -1) {
                            // 订单在mouthing数据库记录异常
                            message.warning(data.message);
                            this.setState({
                                ...this.state,
                                bPaymentResultDlg: true,
                                iPaymentResult: -2
                            });
                        } else {
                            // 订单支付记录成功，此时订单结果对话框处于打开状态并显示结果
                            this.strOrderID = data.oid;
                            this.setState({
                                ...this.state,
                                bPaymentResultDlg: true,
                                iPaymentResult: 1
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                }
            }
            // 支付失败（卡片无效/网络问题？等）
            else {
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: -1
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 订单支付完成后的操作
    AfterPayment = (bStay) => {
        if (!bStay) {
            window.location.href = '/webapp/user?tab=3&store=' + this.objStoreInfo.id;
        } else {
            window.location.href = '/webapp/store/' + this.objStoreInfo.id;
        }
    }
    // 取货方式更换
    ItemPickupOptionChange = (event) => {
        if (event.target.value === "to_door") {
            this.strCurrentLocation = this.objUserInfo.address;
            this.setState({
                ...this.state,
                bToDoor: true
            });
        } else {
            this.strCurrentLocation = this.objStoreInfo.address;
            this.setState({
                ...this.state,
                bToDoor: false
            });
        }
    }
    // 电话区号选择
    PhoneCodeSelector = e => {
        this.setState({
            ...this.state,
            strPhoneCode: e,
        });
    }
    // 更改联系方式
    ChangeContactInfo = (iContactInfo) => {
        if (iContactInfo === 2 && this.refs.user_info_input)
            this.refs.user_info_input.state.value = this.objUserInfo.address;

        if (iContactInfo === 0 && this.refs.user_info_input)
            this.refs.user_info_input.state.value = this.objUserInfo.contact;

        let strPhoneCode = "+1";
        if (iContactInfo === 1 && this.refs.user_info_input) {
            if (this.objUserInfo.contact_phone !== "" && this.objUserInfo.contact_phone !== null && this.objUserInfo.contact_phone !== "无") {
                this.refs.user_info_input.state.value = this.objUserInfo.contact_phone.split(') ')[1];
                strPhoneCode = this.objUserInfo.contact_phone.split(') ')[0].split('(')[1];
            } else {
                if (this.objUserInfo.phone !== "" && this.objUserInfo.phone !== null && this.objUserInfo.phone !== "无") {
                    this.objUserInfo.contact_phone = this.objUserInfo.phone;
                    this.refs.user_info_input.state.value = this.objUserInfo.phone.split(') ')[1];
                    strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
                } else {
                    this.refs.user_info_input.state.value = "";
                    strPhoneCode = "+1";
                }
            }
        } else {
            strPhoneCode = this.state.strPhoneCode;
        }

        this.setState({
            ...this.state,
            strPhoneCode: strPhoneCode,
            bChangeContactInfoDlg: true,
            iContactInfo: iContactInfo
        });
    }
    // 确认/取消修改联系方式
    ConfirmCancelContactInfo = (bConfirm) => {
        if (bConfirm) {
            let strValue = this.refs.user_info_input.state.value;
            if (this.state.iContactInfo === 0) {
                if (strValue.trim() === "") {
                    message.warning("联系人不能为空！");
                    return;
                }
                this.objUserInfo.contact = this.refs.user_info_input.state.value;
            }
            if (this.state.iContactInfo === 1) {
                if (strValue.trim() === "") {
                    message.warning("电话不能为空！");
                    return;
                }
                this.objUserInfo.contact_phone = "(" + this.state.strPhoneCode + ") " + this.refs.user_info_input.state.value;
            }
            if (this.state.iContactInfo === 2) {
                if (strValue.trim() === "") {
                    message.warning("邮寄地址不能为空！");
                    return;
                }
                this.objUserInfo.address = this.refs.user_info_input.state.value;
                this.strCurrentLocation = this.objUserInfo.address;
            }
            let objOrderInfo = {
                contact: this.objUserInfo.contact,
                phone: this.objUserInfo.contact_phone,
                address: this.objUserInfo.address
            };
            cookie.save('wm_order_info', objOrderInfo, { path: '/' });

            this.UpdateUserContactInfo();
        }
        this.setState({
            ...this.state,
            bChangeContactInfoDlg: false
        });
    }
    // 用户联系方式更新
    UpdateUserContactInfo = () => {
        axios.post('/UpdateUserBasicInfo', {
            objUserInfo: this.objUserInfo,
            bNameCheck: false
        }).then(({ data }) => {
            if (data.status === 1) {
                // DataStatistic
                UserActiveUpdate(4, { active: "用户信息修改成功", name: this.objUserInfo.name });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 切换地区
    ChangeArea = ({ key }) => {
        this.iCurrentArea = parseInt(key, 10);
        this.GetItemsData(this.state.iCurrentItemType);
    }

    componentDidMount() {
        this.GetStoreData();
        // 获取用户信息
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.objUserInfo = -1;
        }
    }

    render() {

        document.title = "共享佳月，中秋别忘寄";

        // 1. 顶部背景图
        let uiBGP = (
            <div className="store_cut_price_bgp" />
        );

        // 2. 商品类别列表
        let uiItemTypeLsit = (
            <div className="store_cut_price_type_list">
                <div className="store_cur_price_type_scoll_area">
                    {this.arrItemTypesData.map((item, index) => {
                        let strStyle = "store_cut_price_type_icon brand_icon_" + index;
                        if (index === this.state.iCurrentItemType)
                            strStyle = "store_cut_price_type_icon_selected brand_icon_" + index;
                        return (
                            <div className={strStyle} key={index} onClick={this.SwitchType.bind(this, index)} />
                        );
                    })}
                </div>
            </div>
        );
        if (this.state.bTypeLoading) {
            uiItemTypeLsit = (
                <div className="store_cut_price_type_list store_type_spin">
                    <Spin size="default" />
                </div>
            );
        }

        // 3. 商家品牌描述
        let uiBrandIntro = (
            <div className="store_cut_price_intro_area">
                {this.arrBrandIntro[this.state.iCurrentItemType]}
            </div>
        );

        // 3. 商品列表
        let uiItemList = (
            <div className="store_cut_price_item_list">
                {uiBrandIntro}
                {this.arrItemsData.map((item, index) => {
                    let strIcon = item.logo;
                    let uiItemIcon = (
                        <img className="store_cut_price_item_icon" src={strIcon} alt=''
                            onClick={this.ItemDetailsShow.bind(this, index)} />
                    );
                    if (strIcon === null || strIcon === "") {
                        uiItemIcon = <div className="store_cut_price_item_icon" />
                    };
                    let uiDeadLine = (
                        <div className="store_cut_price_item_details">
                            剩余
                            <Countdown valueStyle={{ width: 'auto', fontSize: '2.75vw', color: 'lightcoral', marginLeft: '1vw' }}
                                value={item.expire_date} format="D 天 H 时 m 分 s 秒" />
                        </div>
                    );
                    let iNumLeft = parseInt(item.num, 10) - parseInt(item.cut_price_num, 10);
                    let iPercentage = parseInt((item.cut_price_num / item.num) * 100.00, 10);
                    if (iPercentage === 0) {
                        iPercentage = parseInt((1 / item.num) * 100, 10);
                        if (iPercentage > 10)
                            iPercentage = Math.floor(Math.random() * 5) + 5;
                    }
                    let strJoinButton = "参与拼单";
                    let strJoinButtonStyle = "store_cut_price_item_buy_button";
                    let strResult = "已拼 " + item.sale + " 件, 本轮还差 " + iNumLeft + " 件拼成";
                    let strStatus = "active";
                    // 拼单成功的情况
                    if (iNumLeft <= 0) {
                        iPercentage = 100;
                        strJoinButton = "再拼一单";
                        strJoinButtonStyle = "store_cut_price_item_buy_button_again";
                        strResult = "已拼 " + item.sale + " 件";
                        uiDeadLine = (
                            <div className="store_cut_price_item_details">
                                您所参与的轮次已拼单成功！
                            </div>
                        );
                        strStatus = "success";
                    } else {
                        // 拼单失败的情况（目前只有一种）：时间到了，但是人数没够
                        let dateToday = new Date();
                        if (dateToday > item.expire_date) {
                            strJoinButton = "超过时限";
                            strJoinButtonStyle = "store_cut_price_item_buy_button_over";
                            strResult = "共拼单" + item.sale + "件,还差" + iNumLeft + "件拼成";
                            strStatus = "exception";
                            uiDeadLine = (
                                <div className="store_cut_price_item_details">
                                    人数未达标，下次继续努力哦！
                                </div>
                            );
                        }
                    }

                    let strShipWay = "store_cut_price_ship_way plane_icon";
                    if (item.comments === "海运")
                        strShipWay = "store_cut_price_ship_way ship_icon";

                    let strFirstRowStyle = "store_cut_price_item_row store_cut_price_item_row_first";
                    if (index > 0)
                        strFirstRowStyle = "store_cut_price_item_row";
                    if (index % 2 === 0) {
                        return (
                            <div className={strFirstRowStyle} key={index}>
                                {uiItemIcon}
                                <div className="store_cut_price_item_active_area">
                                    <div className="store_cut_price_item_price">
                                        <div className="store_cut_price_dollar_sign">$</div>
                                        {item.cut_price}
                                    </div>
                                    <div className="store_cut_price_item_price_oge">{"原价 $" + item.price}</div>
                                    <div className={strJoinButtonStyle} onClick={this.ItemDetailsShow.bind(this, index)}>
                                        {strJoinButton}</div>
                                </div>
                                <div className="store_cut_price_item_info" >
                                    <div className="store_cut_price_item_name">{item.name}</div>
                                    <div className="store_cut_price_item_details">
                                        {strResult}
                                    </div>
                                    <div className="store_cut_price_item_progress">
                                        <Progress percent={iPercentage} size="small" status={strStatus} />
                                    </div>
                                    {uiDeadLine}
                                </div>
                                <div className={strShipWay} />
                            </div>
                        );
                    } else {
                        return (
                            <div className={strFirstRowStyle} key={index}>
                                <div className="store_cut_price_item_info" >
                                    <div className="store_cut_price_item_name">{item.name}</div>
                                    <div className="store_cut_price_item_details">
                                        {strResult}
                                    </div>
                                    <div className="store_cut_price_item_progress">
                                        <Progress percent={iPercentage} size="small" status={strStatus} />
                                    </div>
                                    {uiDeadLine}
                                </div>
                                {uiItemIcon}
                                <div className="store_cut_price_item_active_area">
                                    <div className="store_cut_price_item_price">
                                        <div className="store_cut_price_dollar_sign">$</div>
                                        {item.cut_price}
                                    </div>
                                    <div className="store_cut_price_item_price_oge">{"原价 $" + item.price}</div>
                                    <div className={strJoinButtonStyle} onClick={this.ItemDetailsShow.bind(this, index)}>
                                        {strJoinButton}</div>
                                </div>
                                <div className={strShipWay} />
                            </div>
                        );
                    }
                })}
            </div>
        );
        if (this.state.bItemLoading) {
            uiItemList = (
                <div className="store_item_spin">
                    <Spin size="default" />
                </div>
            );
        }

        // 4. 商品详情
        let uiItemDetailsTitle = (
            <div className="item_details_title">
                <div className="item_details_back" onClick={this.ItemDetailsClose}>
                    <Icon type="left-circle" theme="filled" />
                </div>
                <div className="item_details_title_text">拼单进行中，快来加入吧！</div>
            </div>
        );
        let uiDescription = null;
        let iPercentage = 0;
        let iNumLeft = 0;
        let strStatus = "active"
        if (this.objCurrentItem.description) {
            uiDescription = (
                <div>
                    <div className="item_details_description">{this.objCurrentItem.description}</div>
                    <div className="item_details_bottom_space" />
                </div>
            );
            if (this.objCurrentItem.description !== null) {
                if (this.objCurrentItem.description.indexOf(' | ') > 0) {
                    let arrDescription = this.objCurrentItem.description.split(' | ');
                    uiDescription = (
                        <div>
                            {arrDescription.map((item, index) => {
                                return (
                                    <div className="item_details_description" key={index}>{item}</div>
                                );
                            })}
                            <div className="item_details_bottom_space" />
                        </div>
                    );
                }
            }
            iNumLeft = parseInt(this.objCurrentItem.num, 10) - parseInt(this.objCurrentItem.cut_price_num, 10);
            iPercentage = (this.objCurrentItem.cut_price_num / this.objCurrentItem.num) * 100.00;
            if (iPercentage === 100 || iPercentage === 0) {
                iPercentage = parseInt((1 / this.objCurrentItem.num) * 100, 10);
                if (iPercentage > 10)
                    iPercentage = Math.floor(Math.random() * 5) + 5;
            }
        }
        // 4.1 购买窗口
        let uiItemBuyDlg = null;
        if (this.objCurrentItem.id > 0) {
            let strIcon = this.objCurrentItem.logo;
            let uiItemIcon = (
                <img className="store_cut_price_item_icon" src={strIcon} alt='' />
            );
            let strJoinButton = "图片仅供参考";
            let strJoinButtonStyle = "item_select_image_cover";
            // 支付方式显示
            let uiCurrentPaymentMethod = (
                <div className="select_card_brief_info">
                    <div className="align_right" />
                    <div className="select_card_brief_last4">{this.arrPaymentMethod[this.iConfirmedPaymentMethod] + " >"}</div>
                </div>
            );
            // 如果是信用卡/借记卡支付
            if (this.iConfirmedPaymentMethod === 1) {
                let uiCardBrand = <div className="card_brand_icon master_icon align_right" />
                if (this.strCurrentCardBrand === "visa")
                    uiCardBrand = <div className="card_brand_icon visa_icon align_right" />
                uiCurrentPaymentMethod = (
                    <div className="select_card_brief_info">
                        {uiCardBrand}
                        <div className="select_card_brief_last4">{this.strCurrentLast4 + " >"}</div>
                    </div>
                );
            }
            // 支付方式选择框
            let uiPaymentOptions = (
                <PaymentMethodDlg bSelectPaymentDlg={this.state.bSelectPaymentDlg} PaymentMethodResult={this.PaymentMethodResult}
                    strTitle={"支付方式"} objUserInfo={this.objUserInfo} />
            );
            // 购买物品弹窗的价格显示
            let fItemPrice = this.objCurrentItem.cut_price;
            let uiPriceShow = (
                <div className="store_cut_price_item_active_area">
                    <div className="store_cut_price_item_price">
                        <div className="store_cut_price_dollar_sign">$</div>
                        {this.objCurrentItem.cut_price}
                    </div>
                    <div className="store_cut_price_item_price_oge">{"原价 $" + this.objCurrentItem.price}</div>
                    <div className={strJoinButtonStyle}>{strJoinButton}</div>
                </div>
            );
            let strConfirmButton = "确定拼单并支付";
            if (this.state.iItemBuyType === 0) {
                fItemPrice = this.objCurrentItem.price;
                uiPriceShow = (
                    <div className="store_cut_price_item_active_area">
                        <div className="store_cut_price_item_price">
                            <div className="store_cut_price_dollar_sign">$</div>
                            {this.objCurrentItem.price}
                        </div>
                        <div className={strJoinButtonStyle}>{strJoinButton}</div>
                    </div>
                );
                strConfirmButton = "确定下单并支付";
            }
            let fItemTotalPrice = parseFloat(fItemPrice) * this.state.iCurrentItemNum;
            let fTotalPrice = fItemTotalPrice;
            // let fDeliveryFee = fTotalPrice * 0.1;
            let fGSTFee = fTotalPrice * 0.12;
            let fFinalPrice = (fTotalPrice /*+ fDeliveryFee*/ + fGSTFee + 0.3) / (1 - (0.029 + 0.006));
            let fStripFee = fFinalPrice * (0.029 + 0.006) + 0.3;
            if (fTotalPrice <= 0.0) {
                fFinalPrice = 0.0;
                fStripFee = 0.0;
            }
            let objPrice = {
                fTotalPrice: fTotalPrice,
                // fDeliveryFee: fDeliveryFee,
                fGSTFee: fGSTFee,
                fStripFee: fStripFee,
                fFinalPrice: fFinalPrice
            };
            // 地址
            let uiSelectAddress = null;
            if (this.state.bToDoor) {
                uiSelectAddress = (
                    <div className="final_price_calculate_info_row" onClick={this.ChangeContactInfo.bind(this, 2)}>
                        <div className="final_price_calculate_row_icon">
                            <Icon type="shop" />
                        </div>
                        <div className="final_price_calculate_row_text">{"邮寄地址：" + this.strCurrentLocation}</div>
                        <div className="final_price_calculate_row_right_text">{"更改 > "}</div>
                    </div >
                );
            } else {
                uiSelectAddress = (
                    <div className="final_price_calculate_info_row">
                        <div className="final_price_calculate_row_icon">
                            <Icon type="shop" />
                        </div>
                        <div className="final_price_calculate_row_text">{"取货地址：" + this.strCurrentLocation}</div>
                    </div>
                );
            }
            // 修改地址/联系人/电话的对话框
            let uiChangeAddress = (
                <div className="user_info_change_line">
                    <div className="user_info_change_title font-adjust">地址</div>
                    <Input style={{ width: '60vw' }} size="large" allowClear={true}
                        defaultValue={this.objUserInfo.address} ref="user_info_input" />
                </div>
            );
            let uiChangeName = (
                <div className="user_info_change_line">
                    <div className="user_info_change_title font-adjust">姓名</div>
                    <Input style={{ width: '60vw' }} size="large" allowClear={true}
                        defaultValue={this.objUserInfo.contact} ref="user_info_input" />
                </div>
            );
            const prefixSelector = (
                <Select style={{ width: '17.5vw' }} onChange={this.PhoneCodeSelector}
                    value={this.state.strPhoneCode}>
                    <Option value="+1">+1</Option>
                    <Option value="+86">+86</Option>
                </Select>
            );
            let strNewPhone = "无";
            if (this.objUserInfo !== -1) {
                if (this.objUserInfo.contact_phone !== null && this.objUserInfo.contact_phone !== "") {
                    if (this.objUserInfo.contact_phone.indexOf(') ') >= 0)
                        strNewPhone = this.objUserInfo.contact_phone.split(') ')[1];
                } else {
                    if (this.objUserInfo.phone !== null && this.objUserInfo.phone !== "") {
                        if (this.objUserInfo.contact_phone.indexOf(') ') >= 0) {
                            strNewPhone = this.objUserInfo.phone.split(') ')[1];
                            this.objUserInfo.contact_phone = this.objUserInfo.phone;
                        }
                    }
                }
            }

            let uiChangePhone = (
                <div className="user_info_change_line">
                    <div className="user_info_change_title font-adjust">电话</div>
                    <Input style={{ width: '60vw' }} size="large" allowClear={true}
                        addonBefore={prefixSelector} defaultValue={strNewPhone} ref="user_info_input" />
                </div>
            );
            let uiChangeContactInfoContents = null;
            if (this.state.iContactInfo === 0)
                uiChangeContactInfoContents = uiChangeName;
            if (this.state.iContactInfo === 1)
                uiChangeContactInfoContents = uiChangePhone;
            if (this.state.iContactInfo === 2)
                uiChangeContactInfoContents = uiChangeAddress
            let uiUserInfoUpdateDlg = (
                <Modal visible={this.state.bChangeContactInfoDlg} okText="确定" cancelText="取消" closable={false} centered
                    onOk={this.ConfirmCancelContactInfo.bind(this, true)} onCancel={this.ConfirmCancelContactInfo.bind(this, false)}
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    {uiChangeContactInfoContents}
                </Modal>
            );

            uiItemBuyDlg = (
                <Modal title={this.objCurrentItem.name} centered={true} closable={true}
                    onCancel={this.ItemBuyClose} visible={this.state.bItemBuyShow} footer={null}>
                    <div className="item_select_dlg">
                        {uiItemIcon}
                        {uiPriceShow}
                        <div className="store_cut_price_item_info" >
                            <div className="item_select_num_area">
                                <div className="item_select_add_button" onClick={this.RemoveItem}>-</div>
                                <div className="item_select_num">{this.state.iCurrentItemNum}</div>
                                <div className="item_select_add_button" onClick={this.AddItem}>+</div>
                            </div>
                        </div>
                    </div>
                    {/* 订单详情1：取货方式 */}
                    <div className="final_price_calculate_pickup_way_area">
                        <div className="final_price_calculate_pickup_way_select">
                            <Radio.Group defaultValue={"to_store"} /*buttonStyle="solid" size="large"*/
                                onChange={this.ItemPickupOptionChange.bind(this)}>
                                <Radio value="to_store"> 到店自提 </Radio>
                                <Radio value="to_door"> 送货上门 </Radio>
                            </Radio.Group>
                        </div>
                        {uiSelectAddress}
                    </div>
                    <div className="final_price_calculate_info_row" onClick={this.ChangeContactInfo.bind(this, 0)}>
                        <div className="final_price_calculate_row_icon">
                            <Icon type="user" />
                        </div>
                        <div className="final_price_calculate_row_text">{"联系人：" + this.objUserInfo.contact}</div>
                        <div className="final_price_calculate_row_right_text">{"更改 > "}</div>
                    </div >
                    <div className="final_price_calculate_info_row" onClick={this.ChangeContactInfo.bind(this, 1)}>
                        <div className="final_price_calculate_row_icon">
                            <Icon type="phone" />
                        </div>
                        <div className="final_price_calculate_row_text">{"电话：" + this.objUserInfo.contact_phone}</div>
                        <div className="final_price_calculate_row_right_text">{"更改 > "}</div>
                    </div >
                    <div className="final_price_calculate_info_row" onClick={this.SelectPaymentMethod}>
                        <div className="final_price_calculate_row_icon">
                            <Icon type="credit-card" />
                        </div>
                        <div className="final_price_calculate_row_text">{"支付方式："}</div>
                        <div className="order_details_right_side_option">{uiCurrentPaymentMethod}</div>
                    </div>

                    <div className="final_price_calculate_area">
                        <div className="final_price_calculate_row">
                            <div className="final_price_calculate_text">
                                {"商品价格: $ " + fItemTotalPrice.toFixed(2)}
                            </div>
                        </div>
                        <div className="final_price_calculate_row">
                            <Tooltip placement="topRight" title="政府税费" trigger="click">
                                <div className="final_price_tips">
                                    <Icon type="question-circle" />
                                </div>
                            </Tooltip>
                            <div className="final_price_calculate_text">
                                {"PST+GST: $ " + fGSTFee.toFixed(2)}
                            </div>
                        </div>
                        <div className="final_price_calculate_row">
                            <Tooltip placement="topRight" title="手续费用于支付给合作的信用卡公司" trigger="click">
                                <div className="final_price_tips">
                                    <Icon type="question-circle" />
                                </div>
                            </Tooltip>
                            <div className="final_price_calculate_text">
                                {"手续费: $ " + fStripFee.toFixed(2)}
                            </div>
                        </div>
                        {/* <div className="final_price_calculate_row final_price_claculate_line">
                            <div className="final_price_calculate_text">
                                {"运费: $ " + fDeliveryFee.toFixed(2)}
                            </div>
                        </div> */}
                        <div className="final_price_calculate_row">
                            <div className="final_price_calculate_text final_price_calculate_result">
                                {"实际支付: $ " + fFinalPrice.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    {/* <div className="final_price_agreement">
                        <Checkbox onChange={this.PurchaseAgreementChange}>Checkbox</Checkbox>
                    </div> */}
                    <div className="confirm_payment_button" onClick={this.ConfirmAndPay.bind(this, objPrice)}>{strConfirmButton}</div>
                    {uiPaymentOptions}
                    {uiUserInfoUpdateDlg}
                </Modal>
            );
        }

        // 4.2 底部栏
        let uiBottomBar = (
            <div className="cut_price_bottom_bar">
                <div className="cut_price_bottom_contact" onClick={this.ShowShareDlg.bind(this, true, 0)} >
                    <div className="cut_price_bottom_text">
                        <Icon type="wechat" theme="filled" />
                        <div>客服</div>
                    </div>
                </div>
                <div className="cut_price_bottom_buy" onClick={this.ItemBuyShow.bind(this, 0)}>
                    <div>{"$ " + this.objCurrentItem.price}</div>
                    <div>单独购买</div>
                </div>
                <div className="cut_price_bottom_cut_price" onClick={this.ItemBuyShow.bind(this, 1)}>
                    <div>{"$ " + this.objCurrentItem.cut_price}</div>
                    <div>拼单购买</div>
                </div>
                <div className="cut_price_bottom_share" onClick={this.ShowShareDlg.bind(this, true, 1)}>
                    <div className="cut_price_bottom_text">
                        <Icon type="share-alt" />
                        <div>分享</div>
                    </div>
                </div>
                {uiItemBuyDlg}
            </div>
        );
        let strShipWay = "store_cut_price_item_details_ship_way plane_icon";
        if (this.objCurrentItem.comments === "海运")
            strShipWay = "store_cut_price_item_details_ship_way ship_icon";

        let uiItemDetails = (
            <Drawer title={uiItemDetailsTitle} placement="left" closable={false} width={'100vw'} height={'100vh'}
                headerStyle={{ width: '0vw', height: '0vw' }}
                bodyStyle={{ margin: '0', padding: '0', width: '100vw', height: '100vh', backgroundColor: 'blue', position: 'fixed', top: '0vw', left: '0vw' }}
                onClose={this.ItemDetailsClose} visible={this.state.bItemDetailsShow} >
                <div className="item_details_dlg_area">
                    {uiItemDetailsTitle}
                    <div className="item_details_bgp" >
                        <img className="item_details_image_area" src={this.objCurrentItem.logo} alt='' />
                        <div className="item_details_image_cover">图片仅供参考</div>
                    </div>
                    <div className={strShipWay} />
                    <div className="item_details_bgc">
                        <div className="item_details_row">
                            <div className="item_details_cut_price">
                                团购价
                            <div className="store_cut_price_dollar_sign">$</div>
                                {this.objCurrentItem.cut_price}
                            </div>
                            <div className="item_details_org_price">{"原价 $" + this.objCurrentItem.price}</div>
                            <div className="item_details_num">{"已拼 " + this.objCurrentItem.sale + " 件"}</div>
                        </div>
                        <div className="item_details_progress">
                            <Progress percent={parseInt(iPercentage, 10)} size="small" status={strStatus} />
                        </div>
                        <div className="item_details_row">
                            <div className="item_details_time_left">剩余</div>
                            <Countdown valueStyle={{ width: 'auto', fontSize: '3.5vw', color: 'lightcoral', marginLeft: '1vw' }}
                                value={this.objCurrentItem.expire_date} format="D 天 H 时 m 分 s 秒" />
                            <div className="item_details_num">{"本轮还差 " + iNumLeft + " 件拼成"}</div>
                        </div>
                        <div className="item_details_row">
                            <div className="item_details_name">{this.objCurrentItem.name}</div>
                        </div>
                        {uiDescription}
                    </div>
                    {uiBottomBar}
                </div>
            </Drawer >
        );
        let strShareDlgTitle = "分享拼单，大家一起来团！";
        let strShareDlgDetails = "扫描或长按二维码，分享给您的家人朋友";
        let uiQRCodeShare = (
            <img src={"https://api.qrserver.com/v1/create-qr-code/?data=https://www.mouthing.info/webapp/store/10005?item=" + this.objCurrentItem.id + "&amp;size=100x100"}
                alt="" title="" />
        );
        let uiQRCodeContact = (
            <img src="https://res.cloudinary.com/jingtu-ego-test/image/upload/c_scale,w_256/v1597536024/0-%E9%B2%B8%E5%9B%BEWhaleMap/ContactQRCode_iz6wng.jpg"
                alt="" title="" />
        );
        let uiQRCode = null;
        if (this.state.iShareDlg === 1) {
            uiQRCode = uiQRCodeShare;
        }
        if (this.state.iShareDlg === 0) {
            strShareDlgTitle = "联系我们";
            strShareDlgDetails = (
                <div>
                    {/* <div className="share_dlg_details">电话：250-616-6319</div> */}
                    <div className="share_dlg_details">微信：</div>
                </div >
            );
            uiQRCode = uiQRCodeContact
        }

        // 7. 客服/分享弹窗
        let uiShareDlg = (
            <Modal title={strShareDlgTitle} visible={this.state.bShareDlg} footer={null} centered={true}
                onCancel={this.ShowShareDlg.bind(this, false, -1)} >
                <div className="share_dlg_title">
                    {strShareDlgDetails}
                    <div className="share_dlg_qrcode">
                        {uiQRCode}
                    </div>
                </div>

            </Modal>
        );

        // 8. 用户按钮
        let uiUserButton = (
            <div className="store_cut_price_user_button" onClick={this.JumpToUserCenter}>
                <Icon type="user" />
            </div>
        );

        // 9. 支付结果弹窗
        // 支付中……
        let bPaymentProcessing = true;
        let strPaymentResultDlgTitle = "您的订单";
        let uiPaymentResultContents = (
            <div className="payment_processing">
                <Spin tip="交易处理中……" />
            </div>
        );
        // 支付成功
        if (this.state.iPaymentResult === 1) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功 !
                </div>
            );
        }
        // 支付失败
        if (this.state.iPaymentResult === -1) {
            bPaymentProcessing = false;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付失败 !
                </div>
            );
        }
        // 支付成功 但 订单信息记录出现问题
        if (this.state.iPaymentResult === -2) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功, 但订单记录出现错误，请联系客服！
                </div>
            );
        }
        let uiPaymentResultDlg = (
            <Modal title={strPaymentResultDlgTitle} visible={this.state.bPaymentResultDlg} closable={false}
                confirmLoading={bPaymentProcessing}
                onOk={this.AfterPayment.bind(this, false)} okText="查看订单"
                onCancel={this.AfterPayment.bind(this, true)} cancelText="继续团购">
                {uiPaymentResultContents}
            </Modal>
        );

        // 地区选择下拉框
        const menu = (
            <Menu onClick={this.ChangeArea}>
                <Menu.Item key="0" className="area_selector_button" >
                    <div className="area_selector_button" >
                        大温地区
                    </div>
                </Menu.Item>
                <Menu.Item key="1">大多地区</Menu.Item>
            </Menu>
        );
        let uiAreaSelector = (
            <div className="area_select_area">
                <Dropdown overlay={menu} trigger={['click']} >
                    <div className="area_selector_button" >
                        {this.arrArea[this.iCurrentArea]} <Icon type="down" />
                    </div>
                </Dropdown>
            </div>
        );

        return (
            <div className="store_cut_price_page">
                {uiAreaSelector}
                {uiUserButton}
                {uiBGP}
                {uiItemTypeLsit}
                {/* {uiBrandIntro} */}
                {uiItemList}
                {uiItemDetails}
                {uiShareDlg}
                {uiPaymentResultDlg}
            </div>
        );
    }
}
export default StoreCutPrice;
// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
//     language: 'cn', region: 'CN'
// })(StoreCutPrice);
