import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import md5 from 'js-md5';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Select from 'antd/es/select';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';

import { deviceType } from "react-device-detect";

import "../../styles/ClientSide/login_register_page.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"

React.Component.prototype.$md5 = md5;
const { Option } = Select;
const g_strMessageKey = 'updating';

class Login extends Component {
    constructor(props) {
        super();
        this.state = {
            bCheck: false,
            bLogin: true,
            bLoading: true,
            confirmDirty: false
        };
        this.strCurrentUserName = "";
        this.strCurrentUserCode = "";
        this.iStoreID = 0;
        this.iItem = 0;
        this.strFromPage = "";
        let strParams = window.location.search;
        this.bXiYouJi = -1; this.bSooning = -1;
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        if (strParams.indexOf('store=') !== -1) {
            if (strParams.split('=')[1] === 'zhihe') {
                this.bXiYouJi = -1; this.iStoreID = 10000; this.bSooning = -1;
            } else {
                if (strParams.split('=')[1] === 'xiyouji') {
                    this.bXiYouJi = 1; this.iStoreID = 30000; this.bSooning = -1;
                } else {
                    if (strParams.split('=')[1] === 'sooning') {
                        this.bXiYouJi = -1; this.iStoreID = 50000; this.bSooning = 1;
                    } else {
                        this.iStoreID = parseInt(strParams.split('=')[1], 10);
                        if (strParams.indexOf('&item=') !== -1) {
                            this.iItem = parseInt(strParams.split('&')[1].split('=')[1], 10);
                        }
                    }
                }
            }
        }
        if (strParams.indexOf('from=') !== -1) {
            this.strFromPage = strParams.split('from=')[1];
        }
        this.objStoreInfo = -1;
        // 设备判别
        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        // MD5 Test
        // let str = 'j0201h';
        // let data = this.$md5(str);
        // console.log("after md5 = " + data);
        // if (data.toUpperCase() === "8837FA1E78D7B3C3340A8DA79481E288")
        //     console.log("密码对了");
        // else
        //     console.log("密码不对");

        // 西邮寄标志
        if (strParams.indexOf('jwestlog') >= 0) {
            this.bXiYouJi = 1; this.iStoreID = 30000;
        }

        console.log(this.bXiYouJi, this.bSooning);
    }

    // 点击注册按钮
    RegisterButtonClicked = () => {
        console.log("RegisterButtonClicked");
        this.JumpToRegister();
        // UserActiveUpdateBack(1, { active: "登录页->注册账号" }, this.JumpToRegister);
    }
    // 跳转到注册页
    JumpToRegister = () => {
        console.log("JumpToRegister : " + this.bMStation);
        if (this.bMStation) {
            if (this.iStoreID === 0) {
                if (this.strFromPage === "")
                    window.location.href = '/webapp/register';
                else
                    window.location.href = '/webapp/register?from=' + this.strFromPage;
            } else {
                if (this.iItem === 0)
                    window.location.href = '/webapp/register?store=' + this.iStoreID.toString();
                else
                    window.location.href = '/webapp/register?store=' + this.iStoreID + '&item=' + this.iItem;
            }
        } else {
            this.setState({
                ...this.state,
                bLogin: false
            });
        }
    }
    BackToLogin = () => {
        this.setState({
            ...this.state,
            bLogin: true
        });
    }
    // 登录提交按钮响应
    LoginSubmit = e => {
        // DataStatistic
        UserActiveUpdate(1, { active: "登录页->尝试登录" });
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.name && values.password) {
                    if (values.name.trim() !== "" && values.password.trim() !== "") {
                        message.loading({ content: '登录中……', key: g_strMessageKey });
                        this.TryLogin(values.name, values.password);
                    } else {
                        message.warning("请输入您的用户名和密码！");
                    }
                } else {
                    message.warning("请输入您的用户名和密码！");
                }

            }
        });
    };
    // 注册提交按钮响应
    handleRegisterSubmit = e => {
        // console.log("RegisterButtonClicked");
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // 用户名
                if (values.name === undefined || values.name === null || values.name === '') {
                    message.warning("请填写用户名称！")
                    return;
                }
                if (values.name.trim() === "") {
                    message.warning("请填写用户名称！")
                    return;
                }
                if (this.bXiYouJi === 1) {
                    // 邮箱
                    if (values.email === undefined || values.email === null || values.email === '') {
                        message.warning("请填写邮箱！")
                        return;
                    }
                    if (values.email.trim() === "") {
                        message.warning("请填写邮箱！")
                        return;
                    }
                }
                if (values.name.length < 2) {
                    message.warning("用户名称太短，请大于1个字符！")
                    return;
                }
                // 密码
                if (values.password === undefined) {
                    message.warning("请设置密码！")
                    return;
                } else {
                    if (values.password.trim() === "") {
                        message.warning("请设置密码！")
                        return;
                    }
                }
                if (values.password.length < 6) {
                    message.warning("密码太短，请大于6位！")
                    return;
                }
                // 手机号
                if (values.phone === undefined) {
                    message.warning("请填写手机号码！")
                    return;
                } else {
                    if (values.phone.trim() === "") {
                        message.warning("请填写手机号码！")
                        return;
                    }
                }
                // 邀请码
                if (values.code !== undefined) {
                    if (values.code !== null && values.code.trim() !== "") {
                        if (values.code.split('_')[0] !== 'iu') {
                            message.warning("输入的邀请码无效！")
                            return;
                        }
                    }
                }
                // 推荐码
                if (values.invite_code !== undefined) {
                    if (values.invite_code === null || values.invite_code.trim() === "") {
                        this.strInviteCode = "";
                    } else {
                        this.strInviteCode = values.invite_code;
                    }
                } else {
                    if (this.bXiYouJi === 1)
                        this.strInviteCode = "LK39";
                    else {
                        if (this.bSooning === 1)
                            this.strInviteCode = "SN77";
                        else {
                            message.warning("请填写进仓编码！");
                            return;
                        }
                    }
                }
                // 注册协议
                if (values.agreement !== true) {
                    // if (this.bXiYouJi) {
                    //     message.warning('请阅读并同意物流服务协议!');
                    //     return;
                    // }
                    if (this.objStoreInfo.type === 5)
                        message.warning("请阅读并同意物流服务协议！");
                    else
                        message.warning("请阅读并同意拼单购物协议！");
                    return;
                }
                let objNewUser = {};
                objNewUser.name = values.name;
                objNewUser.password = values.password;
                objNewUser.level = 1;
                objNewUser.status = 1;
                objNewUser.email = null;
                if (values.email !== undefined) {
                    if (values.email.trim() !== "" && values.email.trim() !== null)
                        objNewUser.email = values.email;
                }
                objNewUser.phone = null;
                if (values.phone !== undefined) {
                    if (values.phone.trim() !== "" && values.phone.trim() !== null)
                        objNewUser.phone = "(+" + values.phone_area_code + ") " + values.phone;
                }
                objNewUser.wechat = values.wechat;
                objNewUser.store_id = null;
                if (this.bSooning === 1)
                    objNewUser.store_id = 50000;
                let strEmailCheck = objNewUser.email;
                if (strEmailCheck === null)
                    strEmailCheck = "";
                let strPhoneCheck = objNewUser.phone;
                if (strPhoneCheck === null)
                    strPhoneCheck = "";

                if (this.strInviteCode === "无")
                    this.strInviteCode = "";
                objNewUser.invite_code = this.strInviteCode;

                // return;
                axios('/EmailPhoneCheck', { params: { email: strEmailCheck, phone: strPhoneCheck } })
                    .then(({ data }) => {
                        if (data.status === 1) {
                            axios.post('/NewUser', objNewUser)
                                .then(({ data }) => {
                                    console.log("new user : ", data);
                                    if (data.status === 1) {
                                        message.success(data.message);
                                        cookie.save('wm_user_code', data.code, { path: '/' });
                                        cookie.save('wm_login_status', 1, { path: '/' });
                                        this.LoginSuccessJump();
                                    } else {
                                        message.warning(data.message);
                                    }
                                }).catch(function (error) { console.log(error); });
                        } else {
                            message.warning(data.message);
                        }
                    }).catch(function (error) { console.log(error); });
            }
        });
    };
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback(<div className="login_page_web_input_tip">两次输入的密码不一样!</div>);
        } else {
            callback();
        }
    };
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password, password_md5: this.$md5(password).toUpperCase(), bXiYouJi: this.bXiYouJi };
        console.log("try login : ", objUserInfo);

        if ((objUserInfo.name === 'Renee SN' || objUserInfo.name === 'renee.ctoc@gmail.com' || objUserInfo.name === '6045599070')
            && this.bXiYouJi === 1) {
            message.warning({ content: '账号错误，请使用西邮寄账号登录！', key: g_strMessageKey, duration: 2 });
            return;
        }
        if ((objUserInfo.name === 'Renee JW' || objUserInfo.name === 'renee-quiana@hotmail.com' || objUserInfo.name === '6046797272')
            && this.bSooning === 1) {
            message.warning({ content: '账号错误，请使用顺致账号登录！', key: g_strMessageKey, duration: 2 });
            return;
        }

        axios('/UserFrontEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                console.log("UserFrontEndLogin : ", data);
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = data.user.name;
                    this.strCurrentUserCode = data.user.code;
                    console.log(this.strCurrentUserName, this.strCurrentUserCode);
                    cookie.save('wm_user_code', this.strCurrentUserCode, { path: '/' });
                    cookie.save('wm_login_status', 1, { path: '/' });
                    // DataStatistic
                    UserActiveUpdateBack(1, { active: "登录页->登录成功" }, this.LoginSuccessJump);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 登录成功跳转
    LoginSuccessJump = () => {

        // 无指定商家
        if (this.iStoreID === 0) {
            if (this.strFromPage === "") {
                if (this.bMStation)
                    window.location.href = '/webapp/home';
                else
                    window.location.href = '/';
            } else
                window.location.href = '/webapp/' + this.strFromPage;
        } else {
            // 团购页面
            if (this.objStoreInfo.type === 2) {
                if (this.iItem === 0)
                    window.location.href = '/webapp/store/' + this.iStoreID.toString();
                else
                    window.location.href = '/webapp/store/' + this.iStoreID + '?item=' + this.iItem;
            }
            // 其他
            else {
                // 物流类商家
                if (this.objStoreInfo.type === 5) {
                    if (this.bMStation) {
                        // 获取IP地址
                        axios.get("https://ipapi.co/json/")  // Another Option: https://api.ipify.org/?format=json  https://ipapi.co/json/
                            .then((response) => {
                                console.log("Get IP Address:");
                                console.log(response.data);
                                if (response.data.country === 'CN') {
                                    window.location.href = '/webapp/user_cn/?tab=5&store=' + this.iStoreID;
                                } else {
                                    window.location.href = '/webapp/user_profile/?tab=5&store=' + this.iStoreID;
                                }
                            }).catch(err => { console.log(err); });
                        // if (this.iStoreID === 20000) {

                        // }
                        // else
                        //     window.location.href = '/webapp/user_profile/?tab=5&store=' + this.iStoreID;
                    } else {
                        if (this.bXiYouJi === 1)
                            window.location.href = '/';
                        else {
                            if (this.bSooning === 1)
                                window.location.href = '/sooning';
                            else
                                window.location.href = '/zhihe';
                        }
                    }
                }
                // 其他商家
                else {
                    window.location.href = '/webapp/store/' + this.iStoreID + "?tab=0";
                }
            }
        }
    }
    // 返回按钮响应
    BackBtnClick = () => {
        this.LoginSuccessJump();
        // DataStatistic
        // UserActiveUpdateBack(1, { active: "登录页->返回首页" }, this.BackToHomePage);
    }
    // 从数据库获取当前商家的数据
    GetStoreData() {
        axios('/GetStoreInfo', { params: { id: this.iStoreID } }).then(({ data }) => {
            if (data.length <= 0) {
                message.warning("商家不存在！");
                return;
            }
            this.objStoreInfo = data[0];
            console.log("GetStoreInfo : ", this.objStoreInfo);
            this.setState({
                ...this.state,
                bLoading: false
            });

        }).catch(function (error) { console.log(error); });
    }
    // 跳回首页
    HomePage = () => {
        if (this.bXiYouJi === 1)
            window.location.href = '/xiyouji';
        else
            if (this.bSooning === 1)
                window.location.href = '/sooning';
            else
                window.location.href = '/';
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问登录页" });
        if (this.iStoreID > 0)
            this.GetStoreData();

        console.log("Login Page");
    }

    render() {
        const { getFieldDecorator } = this.props.form;  // 登录用

        if (this.bMStation) {
            // 登录表单
            const uiLoginForm = (
                <Form onSubmit={this.LoginSubmit}>
                    <Form.Item>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: '请输入用户名……' }],
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.5)' }} />} placeholder="用户名/邮箱/电话" allowClear={true} />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: '请输入密码……' }],
                        })(
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />}
                                type="password" placeholder="密码" allowClear={true} />,
                        )}
                    </Form.Item>
                    {/* {uiInviteCode} */}
                    <Form.Item>
                        {getFieldDecorator('remember', {
                            valuePropName: 'checked',
                            initialValue: true,
                        })(
                            <div className="agreement_forget_password">
                                <Checkbox>记住我</Checkbox>
                                <div className="click_link">忘记密码</div>
                            </div>
                        )}
                        <div className="login_button_area">
                            <Button type="primary" htmlType="submit" className="login_button_line">
                                登　录 </Button>
                        或
                        <div className="click_link" onClick={this.JumpToRegister}>注册账号</div>
                        </div>
                    </Form.Item>
                </Form>
            );
            return (
                <div className="login_page bgc_green_light">
                    <div className="login_bgp_area">
                        <div className="login_bgp" />
                    </div>
                    <div className="login_form_area">
                        {uiLoginForm}
                    </div>
                    <div className="login_back_btn" onClick={this.BackBtnClick}>
                        <Icon type="left-circle" theme="filled" />
                    </div>
                </div>
            );
        } else {
            let uiLoginForm = null;
            let uiRegisterForm = null;
            // 登录表单
            let strMainColorStyle = "darkcyan";
            let strLoginTips = "用户名/邮箱/电话";
            if (this.bXiYouJi === 1) {
                strMainColorStyle = "darksalmon";
                strLoginTips = "邮箱/电话";
            }
            if (this.bSooning === 1) {
                strMainColorStyle = "orange";
                strLoginTips = "邮箱/电话";
            }

            if (this.state.bLogin) {
                uiLoginForm = (
                    <Form onSubmit={this.LoginSubmit}>
                        <Form.Item>
                            {getFieldDecorator('name', {
                                rules: [{ required: false }],
                            })(<Input style={{ width: '80%', height: '10%', marginLeft: '10%', fontSize: "20px" }}
                                placeholder={strLoginTips} allowClear={true} size="large" />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: false }],
                            })(<Input style={{ width: '80%', height: '10%', marginLeft: '10%', fontSize: "20px", borderRadius: '0px' }}
                                type="password" placeholder="密码" allowClear={true} size="large" />,
                            )}
                        </Form.Item>
                        <Button htmlType="submit" className="login_page_web_login_button"
                            style={{ background: strMainColorStyle, color: 'white', fontSize: '17.5px', fontWeight: 'bold', height: '45px', border: 'none' }}>
                            登　录 </Button>
                        <div className="login_page_web_regist_now" style={{ color: strMainColorStyle }}
                            onClick={this.RegisterButtonClicked}>没有账号？现在注册</div>
                    </Form>
                );
            } else {
                let uiInviteCode = null;
                uiInviteCode = (
                    <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                        {getFieldDecorator('invite_code', {
                            rules: [{ required: false }],
                        })(<div className="login_page_web_regist_form_row">
                            <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>进仓编码</div>
                            <Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                placeholder="" allowClear={true} size="large" /></div>)}
                    </Form.Item>
                );
                let uiEmail = null;
                let uiWechat = null;
                if (this.bXiYouJi === 1) {
                    uiInviteCode = null;
                    uiEmail = (
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('email', {
                                rules: [{ required: false/*, type: 'email'message: '请输入有效的邮箱'*/ }]
                            })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>登录邮箱</div>
                                    < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>
                            )}
                        </Form.Item>
                    );
                    uiWechat = (
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('wechat', {
                                rules: [{ required: false/*, type: 'email'message: '请输入有效的邮箱'*/ }]
                            })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>微信账号</div>
                                    < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>
                            )}
                        </Form.Item>
                    );
                }
                if (this.bSooning === 1) {
                    uiInviteCode = null;
                    uiEmail = (
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('email', {
                                rules: [{ required: false }]
                            })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>登录邮箱</div>
                                    < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>
                            )}
                        </Form.Item>
                    );
                    uiWechat = (
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('wechat', {
                                rules: [{ required: false }]
                            })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>微信账号</div>
                                    < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>
                            )}
                        </Form.Item>
                    );
                }

                // 注册表单
                const prefixSelector = getFieldDecorator('phone_area_code', { initialValue: '1', })(
                    <Select style={{ width: 'auto' }}>
                        <Option value="1">+1</Option>
                        <Option value="86">+86</Option>
                    </Select>
                );
                let strAgreement = "查看物流服务协议";
                uiRegisterForm = (
                    <Form onSubmit={this.handleRegisterSubmit}>
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('name', { rules: [{ required: true }] })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>用户名称</div>
                                    < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }} >
                            {getFieldDecorator('phone', { rules: [{ required: false }] })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>手机号码</div>
                                    <Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        addonBefore={prefixSelector} allowClear={true} size="large" />
                                </div>)}
                        </Form.Item>
                        {uiEmail}
                        {uiWechat}
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('password', {
                                rules: [{ required: false }, { validator: this.validateToNextPassword, }],
                            })(<div className="login_page_web_regist_form_row">
                                <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>设置密码</div>
                                <Input.Password style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                    placeholder="" allowClear={true} size="large" />
                            </div>)}
                        </Form.Item>
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                            {getFieldDecorator('confirm', {
                                rules: [{ required: false }, { validator: this.compareToFirstPassword }],
                            })(<div className="login_page_web_regist_form_row">
                                <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>确认密码</div>
                                <Input.Password style={{ width: '60%', height: '10%', fontSize: "20px" }} onBlur={this.handleConfirmBlur} allowClear={true} size="large" />
                            </div>)}
                        </Form.Item>
                        {uiInviteCode}
                        <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%', marginBottom: '5%' }} >
                            {getFieldDecorator('agreement', { valuePropName: 'checked' })(
                                <div className="login_page_web_regist_form_row">
                                    <Checkbox style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '9%' }}>我已阅读</Checkbox>
                                    {/* <div>test</div> */}
                                    <Tooltip title={"即将呈现，敬请期待！"} trigger="click" onClick={this.AgreementClicked}>
                                        <div style={{
                                            fontWeight: 'bold', fontSize: '15px', color: strMainColorStyle,
                                            marginLeft: 'auto', marginRight: '10%', textDecoration: 'underline'
                                        }}> {strAgreement} </div>
                                    </Tooltip>
                                </div>
                            )}
                        </Form.Item>
                        <div className="login_page_web_regist_form_row">
                            <Button htmlType="submit" className="login_page_web_login_button"
                                style={{
                                    width: '82.5%', height: '45px', marginBottom: '2.5%', marginLeft: '0%', background: strMainColorStyle,
                                    color: 'white', fontSize: '17.5px', fontWeight: 'bold', border: 'none'
                                }}>注 册</Button>
                        </div>
                        <div className="login_page_web_regist_now" style={{ margin: '0', marginLeft: '9%', color: strMainColorStyle }}
                            onClick={this.BackToLogin}>已有账号？立刻登录</div>
                    </Form>
                );
            }

            let uiLogo = (
                <div className="login_page_web_title_logo" />
            );
            let strSlogan = "login_page_web_title_slogan";
            let uiCenterBGP = (<div className="web_center_area_bgp web_center_area_bgp_left_layout" />);
            let strCenterStyle = "login_page_web_center_area";
            let strLoginForm = "login_page_web_login_form";
            let uiCopyright = (
                <div className="login_page_web_bottom_area">
                    <div className="login_page_web_bottom_copyright">
                        至禾国际信息科技有限公司版权所有
                        </div>
                    <div className="login_page_web_bottom_copyright">
                        Copyright © 2018-2028 mouthing.info All Right Reseved
                            {/* 粤ICP备16088898号 */}
                    </div>
                </div>
            );
            // 西邮寄
            if (this.bXiYouJi === 1) {
                uiLogo = null; strSlogan = "login_page_web_title_slogan_xiyouji";
                uiCenterBGP = null; strLoginForm = "login_page_web_login_form_xiyouji";
                strCenterStyle = "login_page_web_center_area_xiyouji";
                uiCopyright = (
                    <div className="login_page_web_bottom_area">
                        <div className="login_page_web_bottom_copyright">
                            Copyright © 2018-2028 xiyouji.ca All Right Reseved
                        </div>
                    </div>
                );
            }
            // 顺致
            if (this.bSooning === 1) {
                uiLogo = null; strSlogan = "sooning_page_web_title_slogan";
                // <div className="sooning_page_web_title_slogan" onClick={this.HomePage} />
                uiCenterBGP = null; strLoginForm = "login_page_web_login_form_sooning";
                strCenterStyle = "login_page_web_center_area_sooning";
                uiCopyright = (
                    <div className="login_page_web_bottom_area">
                        <div className="login_page_web_bottom_copyright">
                            Copyright © 2018-2028 Sonning Logistics Inc. All Right Reseved
                        </div>
                    </div>
                );
            }

            return (
                <div className="login_page_web">
                    <div className="login_page_web_title_area" onClick={this.HomePage} >
                        {uiLogo}
                        <div className={strSlogan}></div>
                    </div>
                    <div className={strCenterStyle}>
                        <div className={strLoginForm}>
                            <div className="login_page_web_login_title">
                                登录 | 注册
                            </div>
                            {uiLoginForm}
                            {uiRegisterForm}
                        </div>
                        {uiCenterBGP}
                    </div>
                    {uiCopyright}
                </div>
            );
        }
    }
}

export default Form.create()(Login);
