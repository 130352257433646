import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import Clipboard from 'react-clipboard.js';

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Collapse from 'antd/es/collapse';
import Icon from 'antd/es/icon';
import DatePicker from 'antd/es/date-picker';
import Drawer from 'antd/es/drawer';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import { Map, Marker } from 'google-maps-react';
import { deviceType } from "react-device-detect";

import TicketList from './UI_Module/TicketList.js';
import StoreList from './UI_Module/StoreList.js';
import PaymentMethodDlg from './UI_Module/PaymentMethodDlg.js';
import "../../styles/ClientSide/user_center_page.css";
import '../../styles/ClientSide/store_pickup_page.css';
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js";
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js";

import moment from 'moment';
const dateFormat = 'YYYY/MM/DD';

const g_strMessageKey = 'updating';
const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;
const mapStyles = { width: '95vw', height: '60vw' };

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// }

class UserCenterIndieCN extends Component {
    constructor(props) {
        super();
        let strParams = window.location.search;  // 筛选出?后面的部分
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.iTab = 0;
        this.iStoreID = 0;
        this.iItem = 0;
        this.strFromPage = "";
        this.bEnglish = false;

        if (strParams !== "") {
            // 针对从特殊指定商家页面跳转到用户中心
            if (strParams.indexOf('store=') === -1)
                this.iTab = parseInt(strParams.split('=')[1], 10);
            else {
                this.iTab = parseInt(strParams.split('&')[0].split('=')[1], 10);
                this.iStoreID = parseInt(strParams.split('&')[1].split('=')[1], 10);
                if (strParams.indexOf('&item=') !== -1) {
                    this.iItem = parseInt(strParams.split('&')[2].split('=')[1], 10);
                    console.log("iItem = " + this.iItem);
                }
            }
            // 针对从首页/分类页/普通商家页面/用户页/搜索页等跳转到用户中心；且iFromPage主要针对登录和注册页，在登录或注册完成后，跳转到之前的页面
            if (strParams.indexOf('from=') !== -1) {
                this.strFromPage = strParams.split('from=')[1];
            }
            if (strParams.indexOf('lang=' !== -1)) {
                this.bEnglish = strParams.split('lang=')[1];
                if (this.bEnglish === "en")
                    this.bEnglish = true;
                else
                    this.bEnglish = false;
            }
        }
        this.state = {
            iCurrentTab: 0,  // 0-优惠券列表, 1-收藏列表, 2-积分相关信息, 3-个人信息
            bLogin: false,
            bShowDlg: false,  // 用户信息修改框
            iUserInfo: -1,
            strUserInfoTitle: "",
            bAvatarUploading: false,
            iGender: 0,
            strPhoneCode: "+1",
            iCollectStores: 0,
            bDataLoading: false,
            bSelectPaymentDlg: false,
            bShareDlg: false,
            bLoading: false,
            bMainMenu: false,
            bEnglish: this.bEnglish,
            bSubmitOrderDlg: false,
            bCancelOrderDlg1: false,
            bCancelOrderDlg2: false,
            iServiceFunction: 1,
            bAddNewDeliveryAddressDlg: false,
            iAddNewDeliveryAddressDlg: 0,
            bContactUsDlg: false,
            objStorePos: { lat: null, lng: null },
            iCurrentOrderStatus: 0,
            bDeliveryStatusFilterChange: false
        };
        this.strCurrentUserCode = '';
        this.objUserInfo = {};
        this.strDefaultUserInfo = "";
        this.bAvatarLocalUploading = false;
        this.arrGender = ['男', '女', ''];
        this.arrStoreList = [];
        this.arrMainCategory = [];
        this.arrSubCategory = [];
        this.strBirthday = "";
        this.arrOrderList = [];
        this.arrOrderShowList = [];
        this.arrOrderListOge = [];
        this.arrTicketsData = -1;  // 用户拥有的优惠券列表
        this.arrTicketUser = [];  // 优惠券和用户之间的关系，使用状态等
        this.bDataLoading = false;
        this.arrTicketsStatus = [];
        this.iShareItem = 0;
        this.objStoreInfo = -1;
        this.arrMainMenu = [
            { cn: "关于商家", en: "About", icon: "shop" },
            { cn: "浏览菜单", en: "Menu", icon: "read" },
            { cn: "个人中心", en: "Profile", icon: "user" },
            { cn: "中文→English", en: "English→中文", icon: "message" }
        ];
        this.arrAddOrders = [];  // 物流用户提交物流单号用
        this.arrOrderStatus = ["待签收", "已签收", "已合箱", "已发货", "已投递"];  // 物流用户订单状态用
        this.arrOrderStatusMenu = ["全部", "待签收", "已签收", "已合箱", "已发货", "已投递"];
        this.arrTransitTrackingNo = [];
        this.arrPackageDetails = [];
        this.strPostcode = "100001";
        this.objPickupLocation = {
            address: "Unit 119, 7080 River Rd., Richmond, BC V6X1X5",
            contact: "Lekon 客服",
            phone: "778-952-0886"
        }
        this.arrDeliveryStatusFilter = ["签收后立即转运", "签收后寄存仓库(最多90天)"];
        this.arrOrderDeliveryStatus = [];

        // 新消息提醒
        this.audioNewOrder = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1603042409/0-%E9%B2%B8%E5%9B%BEWhaleMap/NewMessage_glprkc.mp3');
        this.audioNewOrder.load();

        this.objCurrentOrder = { comments: "" };
        this.iCurrentOrderStatus = 0;

        // 设备判别
        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;
    }
    // // 定时器倒计时
    // TimeCountDown = () => {
    //     console.log("Order Status Monitor ...");
    //     this.ShowList(3);
    //     this.setState({
    //         ...this.state,
    //         deadline: Date.now() + 1000 * 15
    //     });
    // }
    // 展示用户中心相应的页面内容
    ShowList(iTab) {
        if (this.bDataLoading)
            return;
        let strTab = "";
        switch (iTab) {
            case 0: strTab = "优惠券列表"; break;
            case 1: strTab = "收藏列表"; break;
            case 2: strTab = "积分列表"; break;
            case 3: strTab = "订单列表"; break;
            case 4: strTab = "个人信息"; break;
            default: strTab = ""; break;
        }
        // DataStatistic
        UserActiveUpdate(1, { active: "用户中心", order: iTab, tab: strTab });
        if (iTab !== 4)
            this.iTab = iTab;
        // 获取收藏商家列表
        if (iTab === 1) {
            if (this.objUserInfo.collect_stores !== "" && this.objUserInfo.collect_stores !== null) {
                let arrStoresID = this.objUserInfo.collect_stores.split(',');
                if (arrStoresID.length > 0) {
                    for (let i = 0; i < arrStoresID.length; i++) {
                        arrStoresID[i] = parseInt(arrStoresID[i], 10);
                    }
                    this.bDataLoading = true;
                    this.setState({
                        ...this.state,
                        bDataLoading: true
                    });
                    axios('/GetStoresFromID', { params: { arrStoresID: arrStoresID } })
                        .then(({ data }) => {
                            this.arrStoreList = [];
                            for (let i = arrStoresID.length - 1; i >= 0; i--) {
                                for (let j = 0; j < data.length; j++) {
                                    if (arrStoresID[i] === data[j].id) {
                                        this.arrStoreList.push(data[j]);
                                        break;
                                    }
                                }
                            }
                            this.bDataLoading = false;
                            this.setState({
                                ...this.state,
                                iCurrentTab: iTab,
                                bDataLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            }
        }
        // 获取订单列表
        if (iTab === 3) {
            this.bDataLoading = true;
            this.setState({
                ...this.state,
                bDataLoading: true
            });
            // 物流订单页
            if (this.objStoreInfo.type === 5) {
                if (this.objUserInfo.code !== "") {
                    axios('/GetUserExpressEnter', {
                        params: {
                            name: this.objUserInfo.name,
                            user_code: this.objUserInfo.code,
                            storage_code: this.objUserInfo.storage_code
                        }
                    }).then(({ data }) => {
                        // this.arrOrderList = [];
                        // if (data.length > 0) {
                        //     for (let i = 0; i < data.length; i++) {
                        //         if (data[i].store_id === this.objStoreInfo.id)
                        //             this.arrOrderList.push(data[i]);
                        //     }
                        //     this.GetOrderPackageList();
                        // }
                        // this.bDataLoading = false;
                        // this.setState({
                        //     ...this.state,
                        //     bDataLoading: false,
                        //     bSubmitOrderDlg: false
                        // });
                        this.arrOrderListOge = [];
                        this.arrOrderList = [];
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].store_id === this.objStoreInfo.id) {
                                    this.arrOrderListOge.push(data[i]);
                                    this.arrOrderList.push(data[i]);
                                }
                            }
                            if (this.iCurrentOrderStatus > 0)
                                this.OrganizeOrderList();
                            if (this.arrOrderList.length > 0) {
                                this.GetOrderPackageList();
                                this.bDataLoading = false;
                                this.setState({
                                    ...this.state,
                                    bDataLoading: false,
                                    bSubmitOrderDlg: false
                                });
                            } else {
                                this.arrOrderShowList = [];
                                this.bDataLoading = false;
                                this.setState({
                                    ...this.state,
                                    bDataLoading: false,
                                    bSubmitOrderDlg: false
                                });
                            }
                        } else {
                            this.arrOrderShowList = [];
                            this.bDataLoading = false;
                            this.setState({
                                ...this.state,
                                bDataLoading: false,
                                bSubmitOrderDlg: false
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                }
            }
            // 电商/自提/团购
            else {
                if (this.objUserInfo.code !== "") {
                    axios('/GetUserOrders', { params: { user_code: this.objUserInfo.code/*, store_id: this.objStoreInfo.id*/ } })
                        .then(({ data }) => {
                            console.log("Get User Orders : ", data);
                            this.arrOrderList = [];
                            if (data.length > 0) {
                                let bUnviewed = false;
                                for (let i = 0; i < data.length; i++) {
                                    if (parseInt(data[i].store_id, 10) === parseInt(this.objStoreInfo.id, 10)) {
                                        this.arrOrderList.push(data[i]);
                                        if (!bUnviewed) {
                                            if (data[i].user_viewed === 0) {
                                                bUnviewed = true;
                                                this.audioNewOrder.play();
                                                message.info("您的订单状态有更新！");
                                            }
                                        }
                                    }
                                }
                            }
                            this.bDataLoading = false;
                            this.setState({
                                ...this.state,
                                bDataLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            }
        }
        // 获取优惠券列表
        if (iTab === 0) {
            this.bDataLoading = true;
            this.setState({
                ...this.state,
                bDataLoading: true
            });
            if (this.objUserInfo.id !== "") {
                axios('/GetUserTickets', { params: { user_id: this.objUserInfo.id } })
                    .then(({ data }) => {
                        this.arrTicketsData = data.tickets;
                        this.arrTicketUser = data.ticket_user;

                        let arrTicketsID = [];
                        for (let i = 0; i < this.arrTicketsData.length; i++)
                            arrTicketsID.push(this.arrTicketsData[i].id);
                        axios('/GetTicketsUseStatus', { params: { arrTicketsID: arrTicketsID, user_id: this.objUserInfo.id } })
                            .then(({ data }) => {
                                this.arrTicketsStatus = data;
                                console.log("UserCenter : GetTicketsUseStatus >>> ");
                                console.log(this.arrTicketsStatus);
                                this.bDataLoading = false;
                                this.setState({
                                    ...this.state,
                                    bDataLoading: false
                                });
                            }).catch(function (error) { console.log(error); });

                    }).catch(function (error) { console.log(error); });

            }
        }
        // 我要寄货
        if (iTab === 5) {

        }

        this.setState({
            ...this.state,
            bSubmitOrderDlg: false,
            iCurrentTab: iTab
        });
    }
    // 跳转回上个界面
    BackBtnClicked = () => {
        this.ShowList(this.iTab);
    };
    // 退出登录
    LogoutBtnClicked = () => {
        // this.strFromPage = window.location.href.split('/webapp/')[1];
        this.strFromPage = "";
        // DataStatistic
        UserActiveUpdateBack(3, { active: "退出登录" }, this.JumpToLogin);
    }
    // 跳转回登录页
    JumpToLogin = () => {
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        if (this.iStoreID === 0) {
            if (this.strFromPage === "")
                window.location.href = '/webapp/login';
            else
                window.location.href = '/webapp/login?from=' + this.strFromPage;
        } else {
            if (this.iItem === 0)
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            else
                window.location.href = '/webapp/login?store=' + this.iStoreID + '&item=' + this.iItem;
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    let strPhoneCode = ""
                    if (this.objUserInfo.phone !== null && this.objUserInfo.phone !== "")
                        strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
                    let iCollectStores = 0;
                    if (this.objUserInfo.collect_stores !== null &&
                        this.objUserInfo.collect_stores !== "" && this.objUserInfo.collect_stores !== undefined) {
                        if (this.objUserInfo.collect_stores.indexOf(',') === -1)
                            iCollectStores = 1;
                        else
                            iCollectStores = this.objUserInfo.collect_stores.split(',').length;
                    }
                    console.log("user info = ", this.objUserInfo);
                    // DataStatistic
                    UserActiveUpdate(0, { active: "访问用户中心" });
                    this.GetStoreData();
                    this.setState({
                        ...this.state,
                        bLogin: true,
                        imageUrl: this.objUserInfo.avatar,
                        iGender: this.objUserInfo.gender,
                        strPhoneCode: strPhoneCode,
                        iCollectStores: iCollectStores
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 上传头像
    AfterLocalUpload = (imgUrl) => {
        // 上传到云端
        axios.post('/UploadAvatarToCloudinary', { id: this.objUserInfo.id, imgUrl: imgUrl })
            .then((response) => {
                // DataStatistic
                UserActiveUpdate(4, { active: "用户信息修改", order: -1, name: "头像修改" });
                message.success({ content: "头像已更新！", key: g_strMessageKey, duration: 2 });
                this.objUserInfo.avatar = response.data;
                this.setState({
                    ...this.state,
                    bAvatarUploading: false,
                    imageUrl: response.data
                });
            });
    }
    // 更换头像
    handleChange = info => {
        if (!this.bAvatarLocalUploading) {
            this.bAvatarLocalUploading = true;
            message.loading({ content: '头像上传中……', key: g_strMessageKey, duration: 0 });
        }
        if (info.file.status === 'uploading') {
            this.setState({ bAvatarUploading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.bAvatarLocalUploading = false;
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, this.AfterLocalUpload.bind(this));
        }
    };
    // 显示对话框
    ShowDlg = (iUserInfo, strTitle) => {
        let strInfoName = "";
        switch (iUserInfo) {
            case 0: {  // 用户名昵称
                strInfoName = "昵称";
                this.strDefaultUserInfo = this.objUserInfo.name;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.name;
                break;
            }
            case 1: {  // 邮箱
                strInfoName = "邮箱";
                this.strDefaultUserInfo = this.objUserInfo.email;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.email;
                break;
            }
            case 2: {  // 电话
                strInfoName = "电话";
                if (this.objUserInfo.phone !== null)
                    this.strDefaultUserInfo = this.objUserInfo.phone.split(') ')[1];
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.strDefaultUserInfo;
                break;
            }
            case 3: {  // QQ
                strInfoName = "QQ";
                this.strDefaultUserInfo = this.objUserInfo.qq;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.qq;
                break;
            }
            case 4: {  // 微信
                strInfoName = "微信";
                this.strDefaultUserInfo = this.objUserInfo.wechat;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.wechat;
                break;
            }
            case 5: {  // 生日
                strInfoName = "生日";
                this.strDefaultUserInfo = this.objUserInfo.birthday;
                // if (this.refs.user_info_input !== undefined)
                //     this.refs.user_info_input.state.value = this.objUserInfo.birthday;
                break;
            }
            case 6: {  // 地址
                strInfoName = "地址";
                this.strDefaultUserInfo = this.objUserInfo.address;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.address;
                break;
            }
            case 7: {  // 密码
                strInfoName = "密码";
                if (this.refs.user_info_input0 !== undefined) {
                    this.refs.user_info_input0.state.value = "";
                    this.refs.user_info_input1.state.value = "";
                    this.refs.user_info_input2.state.value = "";
                }
                break;
            }
            case 8: strInfoName = "性别"; break;
            default: break;
        }
        // DataStatistic
        UserActiveUpdate(4, { active: "用户尝试修改信息", name: strInfoName });
        let strPhoneCode = "+1";
        if (iUserInfo === 2 & this.objUserInfo.phone !== null)
            strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
        this.setState({
            ...this.state,
            bShowDlg: true,
            iUserInfo: iUserInfo,
            strUserInfoTitle: strTitle,
            strPhoneCode: strPhoneCode
        });
    };
    // 确认按钮点击相应
    OKButtonClicked = e => {
        // 获取 & 重置数据
        this.strDefaultUserInfo = "";
        let strOgeName = this.objUserInfo.name;
        let bNameCheck = false;
        let strInfoName = "";
        switch (this.state.iUserInfo) {
            case 0: { // 用户名昵称
                strInfoName = "昵称";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === "") {
                        message.warning("昵称（用户名）不能为空！");
                        return;
                    }
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.name) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    bNameCheck = true;
                    this.objUserInfo.name = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 1: { // 邮箱
                strInfoName = "邮箱";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.email) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.email = this.refs.user_info_input.state.value.trim();
                    if (this.objUserInfo.email === "")
                        this.objUserInfo.email = null;
                }
                break;
            }
            case 2: { // 电话
                strInfoName = "电话";
                if (this.refs.user_info_input !== undefined) {
                    let strNewPhone = this.refs.user_info_input.state.value.trim();
                    strNewPhone = "(" + this.state.strPhoneCode + ") " + strNewPhone;
                    if (strNewPhone === this.objUserInfo.phone) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    if (this.refs.user_info_input.state.value.trim() === "")
                        this.objUserInfo.phone = null;
                    else
                        this.objUserInfo.phone = strNewPhone;
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 3: { // QQ
                strInfoName = "QQ";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.qq) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.qq = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 4: { // 微信
                strInfoName = "微信";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.wechat) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.wechat = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 5: { // 生日
                strInfoName = "生日";
                if (this.strBirthday === this.objUserInfo.birthday) {
                    this.setState({ ...this.state, bShowDlg: false });
                    return;
                }
                this.objUserInfo.birthday = this.strBirthday;
                break;
            }
            case 6: { // 地址
                strInfoName = "地址";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.address) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.address = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 7: { // 密码
                strInfoName = "密码";
                if (this.refs.user_info_input0 !== undefined) {
                    // 旧密码不能为空
                    if (this.refs.user_info_input0.state.value.trim() === "") {
                        message.warning("请输入旧的密码！");
                        return;
                    }
                    if (this.refs.user_info_input1.state.value.trim().length < 6) {
                        message.warning("新的密码至少6位！");
                        return;
                    }
                    if (this.refs.user_info_input1.state.value.trim() !== this.refs.user_info_input2.state.value.trim()) {
                        message.warning("两次输入的密码不一致！");
                        return;
                    }
                    if (this.refs.user_info_input0.state.value.trim() === this.refs.user_info_input1.state.value.trim()) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.password = this.refs.user_info_input1.state.value.trim();
                }
                break;
            }
            case 8: { // 性别
                strInfoName = "性别";
                this.objUserInfo.gender = this.state.iGender;
                break;
            }
            default: break;
        }
        message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
        let strEmailCheck = this.objUserInfo.email;
        if (strEmailCheck === null)
            strEmailCheck = "";
        if (this.state.iUserInfo !== 1)
            strEmailCheck = "";
        let strPhoneCheck = this.objUserInfo.phone;
        if (strPhoneCheck === null)
            strPhoneCheck = "";
        if (this.state.iUserInfo !== 2)
            strPhoneCheck = "";
        let strOldPassword = "";
        if (this.state.iUserInfo === 7)
            strOldPassword = this.refs.user_info_input0.state.value.trim();
        axios('/EmailPhoneCheck', { params: { email: strEmailCheck, phone: strPhoneCheck } })
            .then(({ data }) => {
                if (data.status === 1) {
                    axios('/PasswordCheck', {
                        params: {
                            id: this.objUserInfo.id,
                            password: strOldPassword
                        }
                    }).then(({ data }) => {
                        if (data.status === 1) {
                            axios.post('/UpdateUserBasicInfo', {
                                objUserInfo: this.objUserInfo,
                                bNameCheck: bNameCheck
                            }).then(({ data }) => {
                                if (data.status === 1) {
                                    // DataStatistic
                                    UserActiveUpdate(4, { active: "用户信息修改成功", name: strInfoName });
                                    message.success({
                                        content: "更新完成！",
                                        key: g_strMessageKey, duration: 2
                                    });
                                    this.setState({
                                        ...this.state,
                                        bShowDlg: false
                                    });
                                }
                                else {
                                    message.warning({
                                        content: data.message,
                                        key: g_strMessageKey, duration: 2
                                    });
                                    this.objUserInfo.name = strOgeName;
                                }
                            }).catch(function (error) { console.log(error); });
                        } else {
                            message.warning({
                                content: data.message,
                                key: g_strMessageKey, duration: 2
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                } else {
                    message.warning({
                        content: data.message,
                        key: g_strMessageKey, duration: 2
                    });
                }
            }).catch(function (error) { console.log(error); });
    };
    // 取消按钮点击相应
    CancelButtonClicked = e => {
        this.strDefaultUserInfo = "";
        if (this.refs.user_info_input !== undefined) {
            this.refs.user_info_input.state.value = "";
        }
        this.setState({
            ...this.state,
            bShowDlg: false
        });
    };
    // 性别选择
    GenderSelector = e => {
        this.setState({
            ...this.state,
            iGender: e.target.value,
        });
    };
    // 电话区号选择
    PhoneCodeSelector = e => {
        this.setState({
            ...this.state,
            strPhoneCode: e,
        });
    }
    // 获取目标分类信息 
    GetCategoriesInfo = () => {
        axios('/GetMainCategories').then(
            ({ data }) => {
                this.arrMainCategories = data.slice(1, data.length);
            }
        ).catch(function (error) { console.log(error); });
        axios('/GetSubCategories').then(
            ({ data }) => {
                this.arrSubCategories = data;
            }
        ).catch(function (error) { console.log(error); });
    }
    // 日期选择器
    onChange = (date, dateString) => {
        this.strBirthday = dateString;
    }
    // 订单列表折叠区域点击响应(自提、电商等商家类型用)
    OrderListCollapse = (key) => {
        if (key.length > 0) {
            let index = parseInt(key[key.length - 1], 10);
            if (this.arrOrderList[index].user_viewed === 0) {
                this.arrOrderList[index].user_viewed = 1;
                axios.post('/UpdateOrderUserViewed', { oid: this.arrOrderList[index].oid })
                    .then(({ data }) => {
                        this.setState({
                            ...this.state,
                            bDataLoading: false
                        });
                    });
            }
        }
    }
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付弹窗结果返回
    PaymentMethodResult = (bConfirm, iCardBrand, iCardLast4, iPaymentMethod, objCardInfo) => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: false
        });
    }
    // 返回商家页面
    BackToStore = () => {
        if (this.iItem === 0) {
            let strUrl = "/webapp/store/" + this.iStoreID + "?tab=0";
            if (this.state.bEnglish)
                strUrl += "&lang=en";
            else
                strUrl += "&lang=cn";
            window.location.href = strUrl;
        } else
            window.location.href = "/webapp/store/" + this.iStoreID + "&item=" + this.iItem;
    }
    // 客服/分享弹窗
    ShowShareDlg = (bShow, iShareItem) => {
        if (iShareItem > 0) {
            this.iShareItem = iShareItem;
        }
        this.setState({
            bShareDlg: bShow
        });
    };
    // 整理出合箱分级数据
    GetOrderPackageList = () => {
        this.arrOrderShowList = [];
        let strPackageNo = "";
        let arrOrderPackage = [];
        for (let i = 0; i < this.arrOrderList.length; i++) {
            if (this.arrOrderList[i].package_no !== null && this.arrOrderList[i].package_no !== '') {
                if (strPackageNo === "") {
                    strPackageNo = this.arrOrderList[i].package_no;
                    arrOrderPackage.push(this.arrOrderList[i]);
                    if (i === this.arrOrderList.length - 1) {
                        this.arrOrderShowList.push(arrOrderPackage);
                    }
                } else {
                    if (strPackageNo === this.arrOrderList[i].package_no) {
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    } else {
                        this.arrOrderShowList.push(arrOrderPackage);
                        arrOrderPackage = [];
                        strPackageNo = this.arrOrderList[i].package_no;
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    }
                }
            } else {
                if (strPackageNo !== "") {
                    strPackageNo = "";
                    this.arrOrderShowList.push(arrOrderPackage);
                    arrOrderPackage = [];
                }
                this.arrOrderShowList.push(this.arrOrderList[i]);
            }
        }
    }
    // 从数据库获取当前商家的数据
    GetStoreData() {
        axios('/GetStoreInfo', { params: { id: this.iStoreID } }).then(({ data }) => {
            if (data.length <= 0) {
                message.warning("商家不存在！");
                return;
            }
            this.objStoreInfo = data[0];
            console.log("GetStoreInfo : ", this.objStoreInfo);
            if (this.objStoreInfo.address.indexOf('|') >= 0) {
                this.strPostcode = this.objStoreInfo.address.split('|')[1];
                this.objStoreInfo.address = this.objStoreInfo.address.split('|')[0];
            }
            if (this.objStoreInfo.type === 5) {
                this.GetStoreLocation(this.objPickupLocation.address);
                if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                    // 分配Storage Code
                    axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id })
                        .then(({ data }) => {
                            this.objUserInfo.storage_code = data[0].storage_code;
                        }).catch(function (error) { console.log(error); });
                } else {
                    axios('/GetUserExpressEnter', {
                        params: {
                            name: this.objUserInfo.name,
                            user_code: this.objUserInfo.code,
                            storage_code: this.objUserInfo.storage_code
                        }
                    }).then(({ data }) => {
                        console.log('Get Express Enter :', data);
                        this.arrOrderList = [];
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].store_id === this.objStoreInfo.id)
                                    this.arrOrderList.push(data[i]);
                            }
                            // this.arrOrderList = data;
                            this.GetOrderPackageList();
                            console.log("arrOrderShowList : ", this.arrOrderShowList);
                            this.GetPackageTransitInfo();
                        }
                        this.bDataLoading = false;
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bDataLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
                }
                this.iTab = 5;
            }
            this.ShowList(this.iTab);
            this.setState({
                ...this.state,
                bLoading: false,
                iCurrentTab: this.iTab
            });
        }).catch(function (error) { console.log(error); });
    }
    // 打开主菜单
    OpenMainMenu = (bShow) => {
        this.setState({
            ...this.state,
            bMainMenu: bShow
        });
    }
    // 点击了菜单
    ClickMenu = (index) => {
        // 商家信息
        if (index === 0) {
            window.location.href = '/webapp/store/' + this.objStoreInfo.id + "?tab=0";
        }
        // 查看菜单
        if (index === 1) {
            window.location.href = '/webapp/store/' + this.objStoreInfo.id + "?tab=1";
        }
        // 个人中心
        if (index === 2) {

        }
        // 切换中英文
        if (index === 3) {
            if (!this.state.bEnglish)
                this.arrGender = ["Male", "Female", ""];
            this.setState({
                ...this.state,
                bEnglish: !this.state.bEnglish,
                bMainMenu: false
            });
        }
    }
    // 添加订单
    AddNewOrder = (iType, objOrder) => {
        if (iType === 0) {
            let strAddOrder = this.refs.order_input.state.value;
            if (!strAddOrder || strAddOrder.trim() === "") {
                message.warning("请输入快递单号！");
                return;
            }
            this.arrAddOrders = [];
            this.arrOrderDeliveryStatus = [];
            if (strAddOrder.indexOf('\n') >= 0) {
                let arrAddOrders = strAddOrder.split('\n');
                for (let i = 0; i < arrAddOrders.length; i++) {
                    if (arrAddOrders[i] !== "" && arrAddOrders[i].trim() !== "") {
                        this.arrAddOrders.push(arrAddOrders[i].trim());
                        this.arrOrderDeliveryStatus.push(0);
                    }
                }
            } else {
                this.arrAddOrders.push(strAddOrder);
                this.arrOrderDeliveryStatus.push(0);
            }
        } else {
            this.arrAddOrders = [];
            this.arrAddOrders.push(objOrder.express_order);
            this.arrOrderDeliveryStatus = [];
            this.arrOrderDeliveryStatus.push(0);
            this.objCurrentOrder = objOrder;
        }
        this.setState({
            ...this.state,
            bSubmitOrderDlg: true
        });
    }
    // 确认/取消提交订单（物流单号专用）
    SubmitOrder = (bConfirm) => {
        this.objCurrentOrder = { comments: "" };
        if (!bConfirm) {
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            return;
        }
        let arrExpressOrders = [];
        for (let i = 0; i < this.arrAddOrders.length; i++) {
            let strInputKey = "express_comments_input_" + i.toString();
            let objExpressOrders = {
                express_order: this.arrAddOrders[i],
                receiver: this.objUserInfo.name,
                storage_code: this.objUserInfo.storage_code,
                user_code: this.objUserInfo.code,
                store_id: this.objStoreInfo.id,
                comments: this.refs[strInputKey].state.value,
                status: 0,
                delivery_status: this.arrOrderDeliveryStatus[i]
            }
            if (objExpressOrders.comments === undefined || objExpressOrders.comments === null) {
                objExpressOrders.comments = "";
            }
            arrExpressOrders.push(objExpressOrders);
        }
        console.log(arrExpressOrders);
        message.loading({ content: '正在提交……', key: g_strMessageKey });
        axios.post('/CreateExpressOrders', {
            arrExpressOrders: arrExpressOrders
        }).then(({ data }) => {
            console.log("包裹预报返回：", data);
            if (data.status >= 0) {
                message.success({ content: "订单编号提交成功！请在订单列表页查看", key: g_strMessageKey, duration: 2 });
                // DataStatistic
                UserActiveUpdate(4, { active: "用户端: 批量提交订单号", action: "新建" });
                if (this.refs.order_input)
                    this.refs.order_input.state.value = "";
                this.ShowList(3);
            } else {
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }

        }).catch(function (error) { console.log(error); });

    }
    // 取消订单（自提/通用）
    CancelOrder = (type, index) => {
        // 弹出提示框
        if (type === 0 || type === 1) {
            if (type === 0)
                this.setState({
                    ...this.state,
                    bCancelOrderDlg1: true
                });
            if (type === 1)
                this.setState({
                    ...this.state,
                    bCancelOrderDlg2: true
                });
        } else {
            // 点击确定货取消按钮
            if (type === 2) {
                // 现金/前台退款
                if (this.arrOrderList[index].card_info === "-4" || this.arrOrderList[index].card_info === "-5") {
                    console.log('现金/前台申请退款 : ', this.arrOrderList[index]);
                    // 订单状态更新
                    let oid = this.arrOrderList[index].oid;
                    axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: -1 })
                        .then(({ data }) => {
                            message.info("订单已取消！");
                            this.ShowList(3);
                        });
                } else {
                    // Stripe 退款
                    axios.post('/OrderRefund', {
                        pid: this.arrOrderList[index].pid
                    }).then(({ data }) => {
                        if (data.refund === 1) {
                            message.info(data.message);
                            // 订单状态更新
                            let oid = this.arrOrderList[index].oid;
                            axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: -1 })
                                .then(({ data }) => {
                                    message.info("订单已取消！");
                                    this.ShowList(3);
                                });
                        } else {
                            message.warning("退款失败，请联系客服！");
                        }
                    }).catch(function (error) { console.log(error); });
                }
            }
            this.setState({
                ...this.state,
                bCancelOrderDlg1: false,
                bCancelOrderDlg2: false
            })
        }

    }
    // 生成订单子控件
    GenerateChildPanelUI = (item, index) => {
        let strDateTime = "无记录";
        if (item.order_date !== null) {
            if (item.order_date.indexOf('T') >= 0) {
                let dateNow = new Date();
                let dateOrder = new Date(item.order_date);
                let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                let day = parseInt(drr / (24 * 60 * 60 * 1000));
                if (day > 0)
                    strDateTime = day + "天前";
                else {
                    let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                    if (hours > 0) {
                        strDateTime = hours + "小时前";
                    } else {
                        let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                        if (minutes > 0) {
                            strDateTime = minutes + "分钟前";
                        } else {
                            strDateTime = "刚刚";
                        }
                    }
                }
            }
        }
        let strPriceWeight = "还未称重";
        if (item.express_info !== null && item.express_info !== "") {
            strPriceWeight = JSON.parse(item.express_info);
            if (parseFloat(strPriceWeight.price_weight) <= 0.0)
                strPriceWeight = "还未称重";
            else
                strPriceWeight = parseFloat(strPriceWeight.price_weight) + " kg";
        }
        let uiPackageNo = null;
        let uiStatus = null;
        if (item.package_no !== null && item.package_no !== "")
            uiPackageNo = (
                <div className="user_center_order_details_text">
                    {"合箱编码：" + item.package_no}</div>
            );
        else
            uiStatus = (<div className="user_center_order_details_title_right">
                {" " + this.arrOrderStatus[parseInt(item.status, 10)]}
            </div>);
        let uiTitle = (
            <div className="user_center_order_details_title">
                <div className="user_center_order_details_title_left">
                    <Icon type="file" theme="filled" />
                    {" " + item.express_order}
                </div>
                {uiStatus}
            </div>
        );
        uiPackageNo = null;
        let uiComments = null;
        if (item.comments) {
            if (item.comments !== null && item.comments !== "")
                if (item.comments.trim() !== "")
                    uiComments = (
                        <div className="user_center_order_details_text">
                            {"备注信息：" + item.comments}
                            <div className="user_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                        </div>
                    );
        }
        let iDeliveryStatus = 0;
        if (item.delivery_status) {
            if (item.delivery_status !== null && item.delivery_status !== "") {
                iDeliveryStatus = parseInt(item.delivery_status, 10);
            }
        }
        return (
            <Panel header={uiTitle} key={(index + 1).toString()}>
                <div className="user_center_order_details_area">
                    <div className="user_center_order_details_text">
                        {"提交时间：" + strDateTime}
                    </div>
                    <div className="user_center_order_details_text">
                        {"发货选项："}
                        {this.arrDeliveryStatusFilter[iDeliveryStatus]}
                        {/* <div className="user_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div> */}
                    </div>
                    {uiComments}
                    {uiPackageNo}
                </div>
            </Panel>
        );
    }
    // 生成订单母控件
    GenerateParentPanelUI = (orders, index) => {
        let uiPanel = null;
        // 已合箱的订单
        if (orders.length) {
            let uiTitle = (
                <div className="user_center_order_details_title">
                    <div className="user_center_order_details_title_left">
                        {/* <Icon type="file" theme="filled" /> */}
                        <Icon type="code-sandbox" />
                        {" 合箱编码 " + orders[0].package_no}
                    </div>
                    <div className="user_center_order_details_title_right">
                        {" " + this.arrOrderStatus[parseInt(orders[0].status, 10)]}
                    </div>
                </div>
            );
            let strPriceWeight = "货物称重：还未称重";
            let strTotalPrice = "总计费用：还未称重";
            let strAutoOrderNo = "";
            // if (this.arrPackageDetails[index]) {
            //     strPriceWeight = "货物称重：" + this.arrPackageDetails[index].price_weight + " kg";
            // }

            // if (this.arrPackageDetails[index]) {
            //     strTotalPrice = "总计费用：$ " + this.arrPackageDetails[index].total_price;
            // }
            console.log("orders[0].package_no = ", orders[0]);
            console.log("this.arrPackageDetails : ", this.arrPackageDetails);
            if (this.arrPackageDetails.length > 0) {
                for (let i = 0; i < this.arrPackageDetails.length; i++) {
                    if (this.arrPackageDetails[i]) {
                        if (this.arrPackageDetails[i].package_no === orders[0].package_no) {
                            strPriceWeight = "货物称重：" + this.arrPackageDetails[i].price_weight + " kg";
                            strTotalPrice = "总计费用：$ " + this.arrPackageDetails[i].total_price;
                            strAutoOrderNo = this.arrPackageDetails[i].auto_order_no;
                            break;
                        }
                    } else {
                        console.log("this.arrPackageDetails[" + i + "] = ", this.arrPackageDetails[i]);
                    }
                }
            }
            let iTargetOrder = 0;
            if (this.arrTransitTrackingNo.length > 0) {
                for (let i = 0; i < this.arrTransitTrackingNo.length; i++) {
                    if (this.arrTransitTrackingNo[i] === strAutoOrderNo) {
                        iTargetOrder = i;
                        break;
                    }
                }
            }
            uiPanel = (
                <Panel header={uiTitle} key={(index + 1).toString()}>
                    {/* <div className="user_center_transit_tracking_no">
                        {"物流追踪号：" + this.arrTransitTrackingNo[index]}
                        <div style={{ marginLeft: 'auto', marginRight: '2vw', color: 'darkcyan', fontWeight: 'bold' }}
                            onClick={this.ViewTransitDetails.bind(this, index)}>查看物流</div>
                    </div> */}
                    <div className="user_center_transit_tracking_no">
                        {strPriceWeight}
                        <div style={{ marginLeft: 'auto', marginRight: '2vw', color: 'darkcyan', fontWeight: 'bold' }}
                            onClick={this.ViewTransitDetails.bind(this, iTargetOrder)}>查看物流</div>
                    </div>
                    <div className="user_center_transit_tracking_no">
                        {strTotalPrice}
                    </div>
                    <Collapse>
                        {orders.map((order, indexChild) => {
                            let uiChildPanel = this.GenerateChildPanelUI(order, indexChild);
                            return (uiChildPanel);
                        })}
                    </Collapse>
                </Panel>
            );
        }
        // 未合箱的订单
        else {
            uiPanel = this.GenerateChildPanelUI(orders, index);

        }
        return uiPanel;
    }
    // 获取已合箱包裹的物流信息
    GetPackageTransitInfo = () => {
        for (let i = 0; i < this.arrOrderShowList.length; i++) {
            if (this.arrOrderShowList[i].length > 0) {
                axios('/GetPackageDetails', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: this.objStoreInfo.id } })
                    .then(({ data }) => {
                        console.log("GetPackageTransitInfo " + i + " : ", this.arrOrderShowList[i][0].package_no);
                        console.log("GetPackageTransitInfo " + i + " : ", data);
                        if (data.length > 0 && data[0]) {
                            this.arrPackageDetails.push(data[0]);
                            axios('/GetPackageAutoOrder', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: this.objStoreInfo.id } })
                                .then(({ data }) => {
                                    this.arrTransitTrackingNo.push(data);
                                    this.setState({
                                        ...this.state,
                                        bLoading: false
                                    });
                                }).catch(function (error) { console.log(error); });
                        }
                    }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 跳转到物流追踪页面
    ViewTransitDetails = (index) => {
        window.location.href = '/tracking/no=' + this.arrTransitTrackingNo[index];
    }
    // 切换展示的服务内容信息（物流专用）
    SwitchServiceFunction = (index) => {
        this.setState({
            ...this.state,
            iServiceFunction: index
        })
    }
    // 拷贝信息到粘贴板
    CopyAddressInfo = (/*strInfo*/) => {
        message.info("复制成功！");
        // navigator.clipboard.writeText(strInfo);
    }
    // 新增收货地址相应
    AddNewDeliverAddress = (iDlg) => {
        let bShow = false;
        if (iDlg > 0)
            bShow = true;
        this.setState({
            ...this.state,
            bAddNewDeliveryAddressDlg: bShow,
            iAddNewDeliveryAddressDlg: iDlg
        })
    }
    // 显示客服二维码
    ShowContactUsDlg = (bShow) => {
        this.setState({
            ...this.state,
            bContactUsDlg: bShow
        })
    }
    // 获取商家位置信息，Tab2响应
    GetStoreLocation = (strAddress) => {
        // 获取IP地址
        axios.get("https://ipapi.co/json/")  // Another Option: https://api.ipify.org/?format=json  https://ipapi.co/json/
            .then((response) => {
                console.log("Get IP Address:");
                console.log(response.data);
                if (response.data.country === 'CN') {
                    this.setState({
                        ...this.state,
                        objStorePos: { lat: null, lng: null }
                    });
                } else {
                    let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
                    strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
                    axios.get(strUrl)
                        .then((response) => {
                            this.setState({
                                ...this.state,
                                objStorePos: response.data.results[0].geometry.location
                            });
                        }).catch(err => {
                            console.log(err) //Axios entire error message
                            console.log(err.response.data.error) //Google API error message 
                        });
                }
            }).catch(err => { console.log(err); });
    }
    // 物流追踪
    ExpressTracking = (strExpressOrder) => {
        console.log("ExpressTracking : ", strExpressOrder);
        // window.location.href = 'https://track24.net/service/expwd/tracking/' + strExpressOrder;
        window.open("https://www.canadapost-postescanada.ca/track-reperage/en#/details/" + strExpressOrder);
    }
    // 订单筛选下拉框点击
    OrderTypeFilterCLicked = (index) => {
        this.iCurrentOrderStatus = index;
        this.ShowList(3);
        this.setState({
            ...this.state,
            iCurrentOrderStatus: index
        });
    }
    // 订单筛选整理
    OrganizeOrderList = () => {
        this.arrOrderList = []
        for (let i = 0; i < this.arrOrderListOge.length; i++) {
            if (this.arrOrderListOge[i].status === this.iCurrentOrderStatus - 1) {
                this.arrOrderList.push(this.arrOrderListOge[i]);
            }
        }
    }
    // 转运状态过滤器切换
    SwitchDeliveryStatusFilter = (index, status) => {
        this.arrOrderDeliveryStatus[index] = status;
        this.setState({
            ...this.state,
            bDeliveryStatusFilterChange: true
        });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        console.log("用户中心页面");
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            this.JumpToLogin();
        }
    }

    render() {

        let arrText = [];
        let arrOrderStatus = [];
        let arrOrderInfoTitle = [];
        if (!this.state.bLoading && this.objStoreInfo !== -1) {
            document.title = this.objStoreInfo.store_name.split(',')[0];
            arrText = ["我的订单", "无订单信息，快去选购吧！", "取消订单", '支付方式', '现金支付',
                '前台支付'];
            if (this.objStoreInfo.type === 5)
                arrText[1] = "无订单信息，快去提交订单吧！";

            arrOrderStatus = ["未支付", "等待商家接单", "商家已接单", "来取餐吧！", "已完成 √", "已取消 x", "已退款", "售后争议", "已发货"];
            if (this.objStoreInfo.type === 1)
                arrOrderStatus[3] = "货已备好！";
            arrOrderInfoTitle = ["订单编号", "下单时间", "支付方式", "支付金额", "联系姓名",
                "联系电话", "提货地址", "送货上门", "商品信息", "快递邮寄",
                "取餐地址", "物流单号", '物流追踪', '暂无'];
            if (this.state.bEnglish) {
                arrText = ["My Orders", "No order，go shopping now！", "Cancel Order", 'Payment Method', 'Cash',
                    'Pay At Reciption'];
                arrOrderStatus = ["Not Paid", "Paid", "Confirmed", "Ready", "Completed √", "Canceled x", "Refunded", "Aurguing", "On the way"];
                arrOrderInfoTitle = ["Order No.", "Order Time", "Payment", "Amount", "Contact",
                    "Phone", "Pickup At", "To Door", "Item Info", "Express",
                    "Pickup At", "Tracking No.", 'Tracking', 'Null'];
                document.title = this.objStoreInfo.store_name.split(',')[1];
            }
        } else {
            document.title = "服务/咨询/娱乐/便民";
            if (this.state.bEnglish)
                document.title = "Service/Info./Play/Shopping";
        }

        if (this.state.bLogin) {
            let uiPageContent = null;
            let uiMenButton = null;
            let uiMainMenuContents = null;
            let uiUserTitle = null;
            let uiUserCollects = null;
            let uiList = null;
            let uiBackButton = null;
            let uiUserInfo = null;

            /******** 手机版布局 ********/
            // if (this.bMStation) {
            // console.log("Mobile Layout");
            // 1. 标题部分
            let uiAvatar = (
                <div className="user_center_avatar_indie" onClick={this.ShowList.bind(this, 4)}>
                    <Icon type="user" style={{ color: 'white', fontWeight: 'bold', fontSize: '10vw' }} />
                </div>
            );
            // 支付方式选择框
            let uiPaymentOptions = (
                <PaymentMethodDlg bSelectPaymentDlg={this.state.bSelectPaymentDlg} PaymentMethodResult={this.PaymentMethodResult} bEnglish={this.state.bEnglish}
                    strTitle={arrText[3]} objUserInfo={this.objUserInfo} objStoreInfo={this.objStoreInfo} />
            );
            let strUserName = this.objUserInfo.name;
            uiUserTitle = (
                <div className="user_center_title_indie">
                    {uiAvatar}
                    <div>
                        <div className="user_center_name_indie">{strUserName}</div>
                        <div className="user_center_info_title_row_indie">
                            <Icon type="setting" theme="filled" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '5vw', marginRight: '5vw' }} onClick={this.ShowList.bind(this, 4)} />
                            <Icon type="credit-card" theme="filled" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '5vw', marginRight: '5vw' }} onClick={this.SelectPaymentMethod} />
                        </div>
                    </div>
                    {uiPaymentOptions}
                </div>
            );
            // 2. 用户收集信息部分: 优惠券、收藏、积分
            let strSelectStyle = ["user_collects_item", "user_collects_item", "user_collects_item", "user_collects_item"];
            strSelectStyle[this.state.iCurrentTab] = "user_collects_item_select";
            // 3. 列表区域内容
            let iOrderNum = 0;
            uiList = (
                <div className="user_center_list_area">
                    <div className="user_center_loading_area">
                        <Spin size="large" />
                    </div>
                </div>
            );
            let uiSubmitOrderDlg = null; // 物流用户提交订单专用
            // 3.0 卡券列表
            if (this.state.iCurrentTab === 0) {
                if (!this.bDataLoading) {
                    let uiTicketList = (
                        <div className="store_ticket_list_area bgc_brown_super_light">
                            <TicketList objStoreInfo={-1} arrTicketUser={this.arrTicketUser} iCurrentPage={3}
                                arrTicketsData={this.arrTicketsData} arrTicketsStatus={this.arrTicketsStatus}
                                objUserInfo={this.objUserInfo} />
                        </div>
                    );
                    uiList = uiTicketList;
                }
            }
            // 3.1 收藏列表
            if (this.state.iCurrentTab === 1) {
                uiList = (
                    <div className="user_center_list_area">
                        <StoreList iCurrentPage={3} /*iFromPage={3}*/
                            strTitle={"关注的商家"} bShowFilter={false}
                            arrMainCategory={this.arrMainCategories}
                            arrSubCategory={this.arrSubCategories}
                            arrStoreList={this.arrStoreList} />
                    </div>
                );
            }
            // 3.2 积分列表
            if (this.state.iCurrentTab === 2) {
                uiList = (<Empty description="暂无数据" className="user_center_empty" />);
            }
            // 3.3 订单列表
            if (this.state.iCurrentTab === 3) {
                if (!this.bDataLoading) {
                    let uiOrderList = null;
                    let strListAreaStyle = "user_center_list_area";
                    if (this.iStoreID === 10005)
                        strListAreaStyle = "user_center_list_area user_center_list_area_top_space";
                    const uiOrderTypeMenu = (
                        <Menu>
                            {this.arrOrderStatusMenu.map((menu, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.OrderTypeFilterCLicked.bind(this, index)}>
                                        <div className="webpage_dropdown_menu_item">{menu}</div>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    let uiOrderTypeDropdown = (
                        <Dropdown overlay={uiOrderTypeMenu} trigger={['click']}>
                            <div className="user_center_dropdown_menu_button" onClick={e => e.preventDefault()}>
                                {this.arrOrderStatusMenu[this.state.iCurrentOrderStatus]}<Icon type="caret-down" style={{ marginLeft: '12.5px' }} />
                            </div>
                        </Dropdown>
                    );
                    if (this.arrOrderList.length <= 0) {
                        uiOrderList = <Empty description={arrText[1]} className="user_center_empty" />;
                        if (this.objStoreInfo.type === 5) {
                            uiOrderList = (
                                <div>
                                    {uiOrderTypeDropdown}
                                    <Empty description={arrText[1]} className="user_center_empty" />;
                                </div>
                            );
                        }
                    } else {
                        // 物流单
                        if (this.objStoreInfo.type === 5) {
                            iOrderNum = this.arrOrderList.length;
                            let arrActiveKey = [];
                            let uiSubmitOrderDlg = null;
                            if (this.state.bSubmitOrderDlg)
                                uiSubmitOrderDlg = (
                                    <Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null}
                                        onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                                        okText="确定" cancelText="取消">
                                        <div>
                                            <div className="user_center_order_submit_tip">
                                                备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                                            </div>
                                            {this.arrAddOrders.map((item, index) => {
                                                // 寄送状态过滤器
                                                const menuDeliveryStatusFilter = (
                                                    <Menu>
                                                        {this.arrDeliveryStatusFilter.map((item, status) => {
                                                            return (
                                                                <Menu.Item key={index} onClick={this.SwitchDeliveryStatusFilter.bind(this, index, status)}>
                                                                    {item}
                                                                </Menu.Item>
                                                            );
                                                        })}
                                                    </Menu>
                                                );
                                                return (
                                                    <div className="user_center_express_order_info_area">
                                                        <div className="user_center_express_order_info_row">
                                                            <div className="user_center_express_info_title">
                                                                <Icon type="codepen" />
                                                                {" 单号 : "}</div>
                                                            <div className="user_center_express_info_contents"> {item}</div>
                                                        </div>
                                                        <div className="user_center_express_order_info_row">
                                                            <div className="user_center_express_info_title">
                                                                <Icon type="rocket" />
                                                                {" 发货 : "}</div>
                                                            <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                                <Button className="webpage_delivery_filter_dropdown_button">
                                                                    {this.arrDeliveryStatusFilter[this.arrOrderDeliveryStatus[index]]}
                                                                    <Icon type="caret-down" />
                                                                </Button>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="user_center_express_order_info_row" >
                                                            <div className="user_center_express_info_title">
                                                                <Icon type="file-text" />
                                                                {" 备注 : "}</div>
                                                            <div className="user_center_express_info_contents">
                                                                <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index}
                                                                    defaultValue={this.objCurrentOrder.comments} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="user_center_express_order_info_row">
                                                <div className="user_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                                <div className="user_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                                            </div>
                                        </div>
                                    </Modal>
                                );
                            uiOrderList = (
                                <div>
                                    {uiOrderTypeDropdown}
                                    <Collapse defaultActiveKey={arrActiveKey}>
                                        {this.arrOrderShowList.map((item, index) => {
                                            let uiPanel = null;
                                            uiPanel = this.GenerateParentPanelUI(item, index);
                                            return (uiPanel);
                                        })}
                                    </Collapse>
                                    {uiSubmitOrderDlg}
                                </div>
                            );
                        }
                        // 自提/电商/团购等
                        else {
                            uiOrderList = (
                                <div className={strListAreaStyle}>
                                    <Collapse onChange={this.OrderListCollapse}>
                                        {this.arrOrderList.map((item, index) => {
                                            iOrderNum++;
                                            let strStoreName = item.store_name.split(',')[0];
                                            if (this.state.bEnglish)
                                                strStoreName = item.store_name.split(',')[1];
                                            let uiOrderReminder = null;
                                            if (item.user_viewed === 0)
                                                uiOrderReminder = (
                                                    <div className="user_center_order_reminder" />
                                                );
                                            let iStatus = item.status;
                                            if (item.delivery_way >= 1) {
                                                if (iStatus === 3)
                                                    iStatus = 8;
                                            }
                                            let uiTitle = (
                                                <div className="user_center_order_details_title">
                                                    {uiOrderReminder}
                                                    <div className="user_center_order_details_title_left">
                                                        <Icon type="shop" theme="filled" />
                                                        {" " + strStoreName}
                                                    </div>
                                                    {/* <div className="user_center_order_details_title_center">
                                                    <Icon type="calendar" theme="filled" />
                                                    {" " + item.client_order_time.split('T')[0]}
                                                </div> */}
                                                    <div className="user_center_order_details_title_right">
                                                        {" " + arrOrderStatus[iStatus]}
                                                    </div>
                                                </div>
                                            );
                                            let strDateTime = item.client_order_time.split('T')[0] + " " + item.client_order_time.split('T')[1].split('.')[0];
                                            let dateNow = new Date();
                                            let dateOrder = new Date(item.client_order_time);
                                            let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                                            let day = parseInt(drr / (24 * 60 * 60 * 1000));
                                            if (day > 0)
                                                strDateTime = day + "天前";
                                            else {
                                                let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                                                if (hours > 0) {
                                                    strDateTime = hours + "小时前";
                                                } else {
                                                    let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                                                    if (minutes > 0) {
                                                        strDateTime = minutes + "分钟前";
                                                    } else {
                                                        strDateTime = "刚刚";
                                                    }
                                                }
                                            }

                                            let strUnknown = "未知";
                                            if (this.state.bEnglish)
                                                strUnknown = "Unknown";
                                            let uiPaymentMethod = (
                                                <div className="user_center_order_details_text">
                                                    {arrOrderInfoTitle[2] + "：" + strUnknown}
                                                </div>
                                            );
                                            if (item.card_info !== null) {
                                                uiPaymentMethod = (
                                                    <div className="user_center_order_details_text">
                                                        {arrOrderInfoTitle[2] + "："}
                                                        <Icon type="credit-card" style={{ marginRight: '1vw', marginBottom: '0.5vw' }} />
                                                        {" " + item.card_info.split(',')[1]}
                                                    </div>
                                                );
                                            }
                                            if (item.card_info === "-4") {
                                                uiPaymentMethod = (
                                                    <div className="user_center_order_details_text">
                                                        {arrOrderInfoTitle[2] + "："}
                                                        <Icon type="dollar" style={{ marginRight: '1vw', marginBottom: '0.5vw' }} />
                                                        {arrText[4]}
                                                    </div>
                                                );
                                            }
                                            if (item.card_info === "-5") {
                                                uiPaymentMethod = (
                                                    <div className="user_center_order_details_text">
                                                        {arrOrderInfoTitle[2] + "："}
                                                        {arrText[5]}
                                                    </div>
                                                );
                                            }
                                            let strName = "无";
                                            let strPhone = "无";
                                            let strAddress = "无";
                                            let strItemInfo = "无";
                                            if (item.contact !== null && item.contact !== "")
                                                strName = item.contact;
                                            if (item.phone !== null && item.phone !== "")
                                                strPhone = item.phone;
                                            if (item.address !== null && item.address !== "")
                                                strAddress = item.address;
                                            if (item.items !== null && item.items !== "") {
                                                let objItems = JSON.parse(item.items);
                                                strItemInfo = objItems.items[0].name.split(',')[0];
                                                if (objItems.items.length > 1)
                                                    for (let i = 1; i < objItems.items.length; i++)
                                                        strItemInfo += ", " + objItems.items[i].name.split(',')[0];
                                                if (this.state.bEnglish) {
                                                    strItemInfo = objItems.items[0].name.split(',')[1];
                                                    if (objItems.items.length > 1)
                                                        for (let i = 1; i < objItems.items.length; i++)
                                                            strItemInfo += ", " + objItems.items[i].name.split(',')[1];
                                                }
                                            }
                                            let uiComments = null;
                                            let uiRefund = null;
                                            if (item.comments !== null && item.comments !== "") {
                                                console.log(index, item.status);
                                                let strComments = "";
                                                // 已取消的订单
                                                if (item.status === 5) {
                                                    let strRefund = "";
                                                    let strReason = item.comments;
                                                    if (item.comments.indexOf('/') >= 0)
                                                        strReason = item.comments.split('/')[0];
                                                    strComments = "取消原因：" + strReason;
                                                    strRefund = "已退款：将于3-5个工作日到帐";
                                                    if (item.card_info === '-4' || item.card_info === '-5')
                                                        strRefund = "已退单";
                                                    if (this.state.bEnglish) {
                                                        if (item.comments.indexOf('/') >= 0)
                                                            strReason = item.comments.split('/')[1];
                                                        strComments = "Cancel Reason: " + strReason;
                                                        strRefund = "Refunds take 5-10 days to appear on your statement";
                                                        if (item.card_info === '-4' || item.card_info === '-5')
                                                            strRefund = "This order is canceled";
                                                    }
                                                    if (item.comments.indexOf(",") >= 0) {
                                                        strComments = "取消原因：" + item.comments.split(',')[0];
                                                        if (this.state.bEnglish) {
                                                            strRefund = "Refunds take 5-10 days to appear on your statement";
                                                            strComments = "Cancel Reason: " + item.comments.split(',')[1];
                                                        }
                                                    }
                                                    uiComments = (
                                                        <div className="user_center_order_details_text user_center_order_details_comments">
                                                            {strComments}
                                                        </div>
                                                    );
                                                    uiRefund = (
                                                        <div className="user_center_order_details_text user_center_order_details_comments"
                                                            style={{ background: 'rgba(32, 178, 171, 0.25)' }}>
                                                            {strRefund}
                                                        </div>
                                                    );
                                                } else {
                                                    strComments = item.comments;
                                                    uiComments = (
                                                        <div className="user_center_order_details_text user_center_order_details_comments"
                                                            style={{ background: 'rgba(32, 178, 171, 0.25)' }}>
                                                            {strComments}
                                                        </div>
                                                    );
                                                }
                                            }
                                            let uiCancelRemind = null;
                                            let uiStoreQRCode = null;
                                            let uiCancelButton = null;
                                            let strCancelTitle = "取消订单";
                                            let strConfirmButton = "确定";
                                            let strCancelButton = "取消";
                                            let strStorePhone = "客服电话：" + this.objStoreInfo.phone;
                                            // let strStoreWechat = "客服微信：";
                                            if (this.state.bEnglish) {
                                                strCancelTitle = "Cancel Order";
                                                strConfirmButton = "OK";
                                                strCancelButton = "Cancel";
                                            }
                                            let uiCancelDlg1 = null;
                                            let uiCancelDlg2 = null;
                                            if (item.status <= 3) {
                                                if (item.status <= 1) {
                                                    uiCancelButton = (
                                                        <div className="user_center_order_details_cancel_button"
                                                            onClick={this.CancelOrder.bind(this, 0, index)}>
                                                            {arrText[2]}</div>
                                                    );
                                                    uiCancelRemind = (<div style={{ fontSize: '3.75vw', fontWeight: 'bold' }}>确定取消该订单？</div>);
                                                } else {
                                                    uiCancelButton = (
                                                        <div className="user_center_order_details_cancel_button"
                                                            onClick={this.CancelOrder.bind(this, 1, index)}>
                                                            {arrText[2]}</div>
                                                    );
                                                    uiCancelRemind = (<div style={{ fontSize: '3.75vw', fontWeight: 'bold' }}>商家已接单，请联系商家取消订单</div>);
                                                }
                                                if (this.state.bEnglish) {
                                                    if (item.status <= 1) {
                                                        uiCancelRemind = (<div>The order will be canceled !</div>);
                                                    } else {
                                                        uiCancelRemind = (<div>Please contact customer service to cancel the order !</div>);
                                                    }
                                                    strStorePhone = "Phone : " + this.objStoreInfo.phone;
                                                    // strStoreWechat = "WeChat : ";
                                                }
                                                uiStoreQRCode = (
                                                    <div className="user_center_store_customer_info">
                                                        {strStorePhone}<br />
                                                        {/* {strStoreWechat}<br /> */}
                                                        <img className="user_center_order_detials_store_qrcode" src={this.objStoreInfo.wechat} alt='' />
                                                    </div>
                                                );
                                                if (this.objStoreInfo.type === 3)
                                                    uiStoreQRCode = null;

                                                if (item.status <= 1) {
                                                    uiCancelDlg1 = (
                                                        <Modal title={strCancelTitle} visible={this.state.bCancelOrderDlg1} centered
                                                            onOk={this.CancelOrder.bind(this, 2, index)} onCancel={this.CancelOrder.bind(this, -1, index)}
                                                            okText={strConfirmButton} cancelText={strCancelButton}>
                                                            {uiCancelRemind}
                                                        </Modal>);
                                                } else {
                                                    uiCancelDlg2 = (
                                                        <Modal title={strCancelTitle} visible={this.state.bCancelOrderDlg2} centered
                                                            onCancel={this.CancelOrder.bind(this, -1, index)} footer={null}>
                                                            {uiCancelRemind}
                                                            {uiStoreQRCode}
                                                        </Modal>
                                                    );
                                                }
                                            }
                                            let strLocation = arrOrderInfoTitle[6];
                                            if (item.delivery_way === -1)
                                                strLocation = arrOrderInfoTitle[10];
                                            if (item.delivery_way === 1)
                                                strLocation = arrOrderInfoTitle[7];
                                            let uiExpressOrder = null;
                                            if (item.delivery_way === 2) {
                                                strLocation = arrOrderInfoTitle[9];
                                                let strExpressOrder = arrOrderInfoTitle[13];
                                                if (item.tracking_pin !== undefined && item.tracking_pin !== null && item.tracking_pin !== "")
                                                    strExpressOrder = item.tracking_pin;
                                                uiExpressOrder = (
                                                    <div className="user_center_order_details_text">
                                                        {arrOrderInfoTitle[11] + "：" + strExpressOrder}
                                                        <div className="user_center_order_express_tracking"
                                                            onClick={this.ExpressTracking.bind(this, strExpressOrder)}>
                                                            {arrOrderInfoTitle[12]}</div>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <Panel header={uiTitle} key={index}>
                                                    <div className="user_center_order_details_area">
                                                        {uiComments}
                                                        {uiRefund}
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[0] + "：" + item.oid}
                                                        </div>
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[1] + "：" + strDateTime}
                                                        </div>
                                                        {uiPaymentMethod}
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[3] + "：$ " + parseFloat(item.final_price).toFixed(2)}</div>
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[4] + "：" + strName}
                                                        </div>
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[5] + "：" + strPhone}
                                                        </div>
                                                        <div className="user_center_order_details_text">
                                                            {strLocation + "：" + strAddress}
                                                        </div>
                                                        {uiExpressOrder}
                                                        <div className="user_center_order_details_text">
                                                            {arrOrderInfoTitle[8] + "：" + strItemInfo}
                                                        </div>
                                                        {uiCancelButton}
                                                        {uiCancelDlg1}
                                                        {uiCancelDlg2}
                                                    </div>
                                                </Panel>
                                            );
                                        })
                                        }
                                    </Collapse >
                                </div >
                            );
                        }
                    }
                    if (iOrderNum <= 0) {
                        uiOrderList = <Empty description={arrText[1]} className="user_center_empty" />;
                        if (this.objStoreInfo.type === 5) {
                            uiOrderList = (
                                <div>
                                    {uiOrderTypeDropdown}
                                    <Empty description={arrText[1]} className="user_center_empty" />;
                                </div>
                            );
                        }
                    }
                    uiList = uiOrderList;
                }
            }
            // 3.5 物流单号提交/订单列表
            if (this.objStoreInfo.type === 5 && this.state.iCurrentTab === 5) {
                iOrderNum = this.arrOrderList.length;
                let uiServiceContents = null;
                let uiContactUsDlg = null;
                let arrServiceButtonStyle = ["user_center_express_order_info_button_select", "user_center_express_order_info_button", "user_center_express_order_info_button"];
                if (this.state.iServiceFunction === 0) {
                    uiSubmitOrderDlg = (
                        <Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null}
                            onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                        // okText="确定" cancelText="取消"
                        >
                            <div>
                                <div className="user_center_order_submit_tip">
                                    备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                                </div>
                                {this.arrAddOrders.map((item, index) => {
                                    // 寄送状态过滤器
                                    const menuDeliveryStatusFilter = (
                                        <Menu>
                                            {this.arrDeliveryStatusFilter.map((item, status) => {
                                                return (
                                                    <Menu.Item key={index} onClick={this.SwitchDeliveryStatusFilter.bind(this, index, status)}>
                                                        {item}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    );
                                    return (
                                        <div className="user_center_express_order_info_area">
                                            <div className="user_center_express_order_info_row">
                                                <div className="user_center_express_info_title">
                                                    <Icon type="codepen" />
                                                    {" 单号 : "}</div>
                                                <div className="user_center_express_info_contents"> {item}</div>
                                            </div>
                                            <div className="user_center_express_order_info_row">
                                                <div className="user_center_express_info_title">
                                                    <Icon type="codepen" />
                                                    {" 发货 : "}</div>
                                                <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                    <Button className="webpage_delivery_filter_dropdown_button">
                                                        {this.arrDeliveryStatusFilter[this.arrOrderDeliveryStatus[index]]}
                                                        <Icon type="caret-down" />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <div className="user_center_express_order_info_row" >
                                                <div className="user_center_express_info_title">
                                                    <Icon type="file-text" />
                                                    {" 备注 : "}</div>
                                                <div className="user_center_express_info_contents">
                                                    <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="user_center_express_order_info_row">
                                    <div className="user_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                    <div className="user_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                                </div>
                            </div>
                        </Modal>
                    );
                    uiContactUsDlg = (
                        <Modal title="联系我们" visible={this.state.bContactUsDlg} footer={null} centered
                            onOk={this.ShowContactUsDlg.bind(this, false)} onCancel={this.ShowContactUsDlg.bind(this, false)}
                            okText="确定" cancelText="取消">
                            <div>
                                <div className="user_center_contact_us_qrcode_title">客服微信</div>
                                <div className="user_center_contact_us_qrcode" />
                                {/* <img className="user_center_contact_us_qrcode" alt=''
                                    src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1603945333/0-%E9%B2%B8%E5%9B%BEWhaleMap/CustomerServiceQRCode_ppgktf.jpg"} /> */}
                                <div className="user_center_contact_us_qrcode_text">扫描或长按二维码图片识别</div>
                            </div>
                        </Modal>
                    );
                    uiServiceContents = (
                        <div>
                            <div className="user_center_express_order_input_title">物流单号输入：</div>
                            <div className="user_center_express_order_input_area">
                                <TextArea size="large" autoSize={{ minRows: 5 }} allowClear={true} ref="order_input"
                                    placeholder="可以用回车分隔，一次输入多个单号" />
                                <div className="user_center_express_order_submit_button" onClick={this.AddNewOrder.bind(this, 0, null)}>确认添加</div>
                            </div>
                            <div className="user_center_exress_order_submit_tip_area">
                                <div className="user_center_exress_order_submit_tip_title">温馨提示：</div>
                                <div className="user_center_exress_order_submit_tip_row">1. 批量输入快递单号后点击“确认添加”，生成订单信息</div>
                                <div className="user_center_exress_order_submit_tip_row">2. 可以对每笔订单进行备注，填写完成后“提交”</div>
                                <div className="user_center_exress_order_submit_tip_row">
                                    3. 如果仍有疑问，请咨询<div className="user_center_contact_us_button" onClick={this.ShowContactUsDlg.bind(this, true)}>在线客服</div>
                                </div>
                            </div>
                            {uiSubmitOrderDlg}
                            {uiContactUsDlg}
                        </div>
                    );
                }
                if (this.state.iServiceFunction === 1) {
                    arrServiceButtonStyle = ["user_center_express_order_info_button", "user_center_express_order_info_button_select", "user_center_express_order_info_button"];
                    let strCopyAllText = this.objStoreInfo.contact_name + " ( " + this.objUserInfo.storage_code + " ),";
                    strCopyAllText += this.objStoreInfo.cell_phone + ",";
                    let strInviteCode = "";
                    if (this.objUserInfo.invite_code !== null && this.objUserInfo.invite_code !== "") {
                        strInviteCode = " " + this.objUserInfo.invite_code;
                    }
                    strCopyAllText += this.objStoreInfo.address + strInviteCode + ",";
                    strCopyAllText += this.strPostcode;
                    uiServiceContents = (
                        <div>
                            <div className="user_center_address_info_tips">务必将收件人括号内的编码填写在快递单上！避免延误签收！</div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">收件人</div>
                                <div className="user_center_address_info_contents">
                                    {this.objStoreInfo.contact_name + " ( " + this.objUserInfo.storage_code + " )"}</div>
                                {/* <div className="user_center_address_info_copy_button"
                                    onClick={this.CopyAddressInfo.bind(this, this.objStoreInfo.contact_name + " ( " + this.objUserInfo.storage_code + " )")}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.objStoreInfo.contact_name + " ( " + this.objUserInfo.storage_code + " )"}
                                    onClick={this.CopyAddressInfo}
                                    className="user_center_address_info_copy_button" style={{ border: 'none' }}>
                                    复制
                                </Clipboard>
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">手机</div>
                                <div className="user_center_address_info_contents">{this.objStoreInfo.cell_phone}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.objStoreInfo.cell_phone)}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.objStoreInfo.cell_phone} onClick={this.CopyAddressInfo}
                                    className="user_center_address_info_copy_button" style={{ border: 'none' }}>
                                    复制
                                </Clipboard>
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">地址</div>
                                <div className="user_center_address_info_contents">{this.objStoreInfo.address + strInviteCode}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.objStoreInfo.address + strInviteCode)}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.objStoreInfo.address + strInviteCode} onClick={this.CopyAddressInfo}
                                    className="user_center_address_info_copy_button" style={{ border: 'none' }}>
                                    复制
                                </Clipboard>
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">邮编</div>
                                <div className="user_center_address_info_contents">{this.strPostcode}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.strPostcode)}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.strPostcode} onClick={this.CopyAddressInfo}
                                    className="user_center_address_info_copy_button" style={{ border: 'none' }}>
                                    复制
                                </Clipboard>
                            </div>
                            {/* <div className="user_center_address_info_copy_all_button" onClick={this.CopyAddressInfo.bind(this, strCopyAllText)}>
                                复制全部
                            </div> */}
                            <Clipboard data-clipboard-text={strCopyAllText} className="user_center_address_info_copy_all_button"
                                style={{ border: 'none' }} onClick={this.CopyAddressInfo}>
                                复制全部
                            </Clipboard>
                        </div>
                    );
                }
                if (this.state.iServiceFunction === 2) {
                    arrServiceButtonStyle = ["user_center_express_order_info_button", "user_center_express_order_info_button", "user_center_express_order_info_button_select"];
                    uiServiceContents = (
                        <div>
                            {/* <div className="user_center_address_manage_area">{uiAddressList}</div>
                            <div className="user_center_add_new_address_button" onClick={this.AddNewDeliverAddress.bind(this, 1)}> + 添加新地址</div>
                            {uiAddNewAddressDlg} */}
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">联系人</div>
                                <div className="user_center_address_info_contents">{this.objPickupLocation.contact}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.strPostcode)}>
                                        复制</div> */}
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">电话</div>
                                <div className="user_center_address_info_contents">{this.objPickupLocation.phone}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.objPickupLocation.phone)}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.objPickupLocation.phone} className="user_center_address_info_copy_button"
                                    style={{ border: 'none' }} onClick={this.CopyAddressInfo}>
                                    复制
                                </Clipboard>
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_address_info_title">地址</div>
                                <div className="user_center_address_info_contents">{this.objPickupLocation.address}</div>
                                {/* <div className="user_center_address_info_copy_button" onClick={this.CopyAddressInfo.bind(this, this.objPickupLocation.address)}>
                                    复制</div> */}
                                <Clipboard data-clipboard-text={this.objPickupLocation.address} className="user_center_address_info_copy_button"
                                    style={{ border: 'none' }} onClick={this.CopyAddressInfo}>
                                    复制
                                </Clipboard>
                            </div>
                            <div className="user_center_address_info_row">
                                <div className="user_center_pickup_reminds">提货时请出示收件人名或用户编码</div>
                            </div>
                            <div className="store_details_map" style={{ marginTop: '5vw', marginLeft: '2.5vw', marginBottom: '5vw', width: '95vw', height: '60vw' }} >
                                <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={this.state.objStorePos}
                                    center={this.state.objStorePos} containerStyle={{ position: 'relative', width: '95vw', height: '60vw' }}>
                                    <Marker position={this.state.objStorePos} />
                                </Map>
                            </div>
                        </div >
                    );
                }
                uiList = (
                    <div className="user_center_list_area">
                        <div className="user_center_user_service_functions">
                            <div className={arrServiceButtonStyle[1]} onClick={this.SwitchServiceFunction.bind(this, 1)}>
                                <Icon type="shop" className="user_center_service_function_icon" />转运中心</div>
                            <div className={arrServiceButtonStyle[0]} onClick={this.SwitchServiceFunction.bind(this, 0)}>
                                <Icon type="rocket" className="user_center_service_function_icon" />提交订单</div>
                            <div className={arrServiceButtonStyle[2]} onClick={this.SwitchServiceFunction.bind(this, 2)}>
                                <Icon type="home" className="user_center_service_function_icon" />提货地址</div>
                        </div>
                        {uiServiceContents}
                    </div>
                );
            }
            uiUserCollects = (
                <div className="user_collects_area">
                    <div className="user_collects_text">{arrText[0]}</div>
                    <div className="user_collects_num">
                        <Icon type="file-text" theme="filled" style={{ marginRight: '2.5vw' }} />
                        {iOrderNum}
                    </div>
                </div>
            );
            // 4. 个人信息，修改信息对话框
            let arrUserIntoTitle = ["个人信息", "退 出", "确 定", "取 消"];
            let arrUserInfoText = ["更改", "昵称", "密码", "性别", "生日", "邮箱", "电话", "地址"];
            if (this.state.bEnglish) {
                arrUserInfoText = ["Change", "Name", "PWD", "Gender", "Birthday", "Email", "Phone", "Address"];
                arrUserIntoTitle = ["Your Profile", "Logout", "OK", "Cancel"];
            }
            let strInputLength = '75vw';
            if (this.state.bEnglish)
                strInputLength = '65vw';
            let uiUserInfoUpdateContents = (
                <div className="user_info_change_line">
                    <div className="user_info_change_title">{this.state.strUserInfoTitle}</div>
                    <Input style={{ width: strInputLength, fontSize: '4vw' }} size="large" allowClear={true}
                        defaultValue={this.strDefaultUserInfo} ref="user_info_input" />
                </div>
            );
            // 密码
            if (this.state.iUserInfo === 7) {
                let arrChangePWD = ["旧的密码", "新的密码", "再次确认"];
                if (this.state.bEnglish)
                    arrChangePWD = ["Old PWD", "New PWD", "Confirm"];
                uiUserInfoUpdateContents = (
                    <div>
                        <div className="user_info_change_line user_password_input_margin">
                            <div className="user_info_change_title user_info_change_pwd">{arrChangePWD[0]}</div>
                            <Input type="password" style={{ width: '62.5vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input0" />
                        </div>
                        <div className="user_info_change_line user_password_input_margin">
                            <div className="user_info_change_title user_info_change_pwd">{arrChangePWD[1]}</div>
                            <Input type="password" style={{ width: '62.5vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input1" />
                        </div>
                        <div className="user_info_change_line">
                            <div className="user_info_change_title user_info_change_pwd">{arrChangePWD[2]}</div>
                            <Input type="password" style={{ width: '62.5vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input2" />
                        </div>
                    </div>
                );
            }
            // 性别
            if (this.state.iUserInfo === 8) {
                let arrGenderText = ["性别", "男", "女"];
                if (this.state.bEnglish) {
                    arrGenderText = ["Gender", "Male", "Female"];
                    this.arrGender = ["Male", "Female", ""];
                }

                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">{arrGenderText[0]}</div>
                        <Radio.Group onChange={this.GenderSelector} value={this.state.iGender}>
                            <Radio value={0}>{arrGenderText[1]}</Radio>
                            <Radio value={1}>{arrGenderText[2]}</Radio>
                        </Radio.Group>
                    </div>
                );
            }
            // 电话
            if (this.state.iUserInfo === 2) {
                const prefixSelector = (
                    <Select style={{ width: '17.5vw' }} onChange={this.PhoneCodeSelector}
                        value={this.state.strPhoneCode}>
                        <Option value="+1">+1</Option>
                        <Option value="+86">+86</Option>
                    </Select>
                );
                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">{this.state.strUserInfoTitle}</div>
                        <Input style={{ width: '75vw' }} size="large" allowClear={true}
                            addonBefore={prefixSelector} ref="user_info_input"
                            defaultValue={this.strDefaultUserInfo} />
                    </div>
                );
            }
            // 生日
            if (this.state.iUserInfo === 5) {
                let strDate = new Date().toISOString().split('T')[0];
                let arrDate = strDate.split('-');
                strDate = arrDate[0] + "/" + arrDate[1] + "/" + arrDate[2];
                if (this.objUserInfo.birthday !== "" && this.objUserInfo.birthday !== null)
                    strDate = this.objUserInfo.birthday;
                this.strBirthday = strDate;
                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">{arrUserInfoText[4]}</div>
                        <DatePicker defaultValue={moment(strDate, dateFormat)}
                            style={{ width: strInputLength }} size="large" format={dateFormat}
                            onChange={this.onChange} />
                    </div>
                );
            }
            let uiUserInfoUpdateDlg = (
                <Modal visible={this.state.bShowDlg} closable={false} centered footer={null} maskClosable={true} onCancel={this.CancelButtonClicked}>
                    {uiUserInfoUpdateContents}
                    <div className="user_info_change_button_row">
                        <div className="user_info_change_cancel_button" onClick={this.CancelButtonClicked}>{arrUserIntoTitle[3]}</div>
                        <div className="user_info_change_confirm_button" onClick={this.OKButtonClicked}>{arrUserIntoTitle[2]}</div>
                    </div>
                </Modal>
            );
            uiUserInfo = (
                <div className="user_center_page">
                    <div className="user_info_title_area">
                        <div className="login_back_btn" onClick={this.BackBtnClicked} >
                            <Icon type="left-circle" theme="filled" />
                        </div>
                        <div className="user_info_title">{arrUserIntoTitle[0]}</div>
                    </div>
                    <div className="user_avatar_space" />
                    {/* <div className="user_info_avatar_area">
                        <Upload name="avatar" listType="picture" className="avatar_uploader"
                            style={{ width: '18vw', height: '18vw' }} ref={"avatar_uploader"}
                            showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload} onChange={this.handleChange} >
                            {imageUrl ? <img className="avator_uploader_shadow" src={imageUrl} alt="avatar" style={{ width: '18vw', height: '18vw' }} /> : uiAvatarButton}
                        </Upload>
                    </div> */}
                    {/* 昵称 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[1]}</div>
                        <div className="user_info_item_content">{this.objUserInfo.name}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 0, arrUserInfoText[1])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 密码 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[2]}</div>
                        <div className="user_info_item_content">********</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 7, arrUserInfoText[2])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 性别 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[3]}</div>
                        <div className="user_info_item_content">{this.arrGender[this.state.iGender]}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 8, arrUserInfoText[3])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 生日 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[4]}</div>
                        <div className="user_info_item_content">{this.objUserInfo.birthday}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 5, arrUserInfoText[4])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 邮箱 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[5]}</div>
                        <div className="user_info_item_content">{this.objUserInfo.email}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 1, arrUserInfoText[5])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 电话 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[6]}</div>
                        <div className="user_info_item_content">{this.objUserInfo.phone}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 2, arrUserInfoText[6])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* QQ */}
                    {/* <div className="user_info_item">
                        <div className="user_info_item_title">QQ</div>
                        <div className="user_info_item_content">{this.objUserInfo.qq}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 3, "QQ")}>
                            更改</div>
                    </div> */}
                    {/* WeChat */}
                    {/* <div className="user_info_item">
                        <div className="user_info_item_title">微信</div>
                        <div className="user_info_item_content">{this.objUserInfo.wechat}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 4, "微信")}>
                            更改</div>
                    </div> */}
                    {/* 地址 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">{arrUserInfoText[7]}</div>
                        <div className="user_info_item_content user_info_address">{this.objUserInfo.address}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 6, arrUserInfoText[7])}>
                            {arrUserInfoText[0]}</div>
                    </div>
                    {/* 用户信息修改框 */}
                    {uiUserInfoUpdateDlg}
                    <div className="user_info_bottom_area" onClick={this.LogoutBtnClicked}>
                        {arrUserIntoTitle[1]}
                    </div>
                </div>
            );
            // 分享弹窗
            // let strShareDlgTitle = "分享拼单，大家一起来团！";
            // let strShareDlgDetails = "扫描或长按二维码，分享给您的家人朋友";
            // let uiShareDlg = (
            //     <Modal title={strShareDlgTitle} visible={this.state.bShareDlg} footer={null} centered={true}
            //         onCancel={this.ShowShareDlg.bind(this, false, -1)} >
            //         <div className="share_dlg_title">
            //             {strShareDlgDetails}
            //             <div className="share_dlg_qrcode">
            //                 <img src={"https://api.qrserver.com/v1/create-qr-code/?data=https://www.mouthing.info/webapp/store/10005?item=" + this.iShareItem + "&amp;size=100x100"}
            //                     alt="" title="" />
            //             </div>
            //         </div>
            //     </Modal>
            // );
            uiMenButton = null; uiMainMenuContents = null; uiBackButton = null;
            // 非物流用户界面有顶部栏
            if (this.objStoreInfo.type === 1 || this.objStoreInfo.type === 3) {
                // 顶部菜单按钮
                uiMenButton = (
                    <div className="store_pickup_menu_button" onClick={this.OpenMainMenu.bind(this, true)}>
                        <Icon type="menu" />
                        {/* {uiReminder} */}
                    </div>
                );
                // 顶部菜单栏
                uiMainMenuContents = (
                    <Drawer title={null} placement="top" closable={false} height={"60vw"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                        onClose={this.OpenMainMenu.bind(this, false)} visible={this.state.bMainMenu}>
                        <div>
                            {this.arrMainMenu.map((item, index) => {
                                let strStyle = "store_pickup_main_menu_row";
                                let strText = item.cn;
                                if (this.state.bEnglish)
                                    strText = item.en;
                                return (
                                    <div className={strStyle} key={index} onClick={this.ClickMenu.bind(this, index)}>
                                        <Icon type={item.icon} className="store_pickup_menu_icon" style={{ color: 'darkcyan' }} />
                                        <div className="store_pickup_main_menu_text" key={index}>{strText}</div>
                                    </div>
                                );
                            })}
                            <div className="store_pickup_menu_button_close">
                                <Icon type="close-circle" theme="filled" style={{ color: 'darkcyan' }}
                                    onClick={this.OpenMainMenu.bind(this, false)} />
                            </div>
                        </div>
                    </Drawer>
                );
                // 返回按钮
                uiBackButton = (
                    <div className="login_back_btn" style={{ left: '2vw', top: '2vw' }} onClick={this.BackToStore} >
                        <Icon type="left-circle" theme="filled" style={{ color: 'rgba(0, 139, 139, 0.75)' }} />
                    </div>
                );
            }
            // 物流用户页面专用
            if (this.objStoreInfo.type === 5) {
                let arrTabStyle = ["transit_user_options_button_select", "transit_user_options_button"];
                if (this.state.iCurrentTab === 3) {
                    arrTabStyle = ["transit_user_options_button", "transit_user_options_button_select"];
                }
                uiUserCollects = (
                    <div className="user_collects_area">
                        <div className={arrTabStyle[0]} onClick={this.ShowList.bind(this, 5)}>
                            <Icon type="code-sandbox" style={{ marginRight: '2.5vw' }} />
                            {"我要寄货"}</div>
                        <div className={arrTabStyle[1]} onClick={this.ShowList.bind(this, 3)} >
                            <Icon type="file-text" theme="filled" style={{ marginRight: '2.5vw' }} />
                            {arrText[0] + " (" + iOrderNum.toString() + ")"}</div>
                    </div>
                );
            }
            // 0. 页面内容
            uiPageContent = (
                <div className="user_center_page_indie">
                    {uiMenButton}
                    {uiMainMenuContents}
                    {/* 用户头像、昵称 */}
                    {uiUserTitle}
                    {/*用户信息：优惠券、收藏、余额 */}
                    {uiUserCollects}
                    {/* 列表区域 */}
                    {uiList}
                    {uiBackButton}
                    {/* {uiShareDlg} */}
                </div>
            );
            // 3.4 个人信息
            if (this.state.iCurrentTab === 4) {
                uiPageContent = uiUserInfo;
            }
            return (uiPageContent);

        } else {
            return (
                <div className="user_page_spin">
                    <Spin size="large" />
                </div>
            );
        }
    }
}
export default UserCenterIndieCN;
