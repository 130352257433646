import React, { Component } from "react";
import ReactPlayer from 'react-player';

import Carousel from 'antd/es/carousel';

// import { Carousel } from 'antd'
import "../../../styles/ClientSide/UI_Module/ads_carousel.css";
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

class AdsCarousel extends Component {

    constructor(props) {
        super();
        this.state = {
            arrVedioPlay: [true, true, true, true, true, true, true, true, true, true],
            arrVedioLight: [true, true, true, true, true, true, true, true, true, true],
            bAutoScroll: true,
            bAdsShow: false
        };
        this.arrTitleAds = [];  // 头图广告数据
        this.iCurrentStore = 0;
    }

    // 获取头图数据
    GetAdsImages = () => {
        if (this.props.arrAdsImages !== undefined) {
            if (this.props.arrAdsImages.length > 0) {
                this.arrTitleAds = [];
                for (let i = 0; i < this.props.arrAdsImages.length; i++) {
                    let strImage = this.props.arrAdsImages[i].image;
                    if (strImage !== "" && strImage !== null && strImage !== undefined)
                        this.arrTitleAds.push(this.props.arrAdsImages[i]);
                }
                this.setState({
                    ...this.state,
                    bAdsShow: true
                });
            }
        }
    }
    //头图广告被点击
    AdsImageClicked = (iStoreID, iAdsIndex) => {
        let strType = "图片"
        if (this.arrTitleAds[iAdsIndex].type !== 0)
            strType = "视频"
        if (this.props.iCurrentPage === 0) {
            this.iCurrentStore = iStoreID;
            // DataStatistic
            UserActiveUpdateBack(1, {
                active: "点击首页广告头图",
                store: iStoreID, ads_order: iAdsIndex, type: strType
            }, this.JumpToStore);
        } else {
            // 只做商家的宣传图展示，以后可设置跳转到任何地方的链接
            // DataStatistic
            UserActiveUpdate(1, {
                active: "点击商家广告头图", store: iStoreID, ads_order: iAdsIndex, type: strType
            });
            // window.location.href = '/webapp/store/' + this.props.iStoreID + '/item/' + iAdsID;
        }
    }
    // 跳转到目标商家
    JumpToStore = () => {
        // window.location.href = '/webapp/store/' + iStoreID + "?tab=2&from=" + this.props.iCurrentPage;
        window.location.href = '/webapp/store/' + this.iCurrentStore + "?tab=2";
    }
    handlePlay = (iStoreID, iAdsIndex, e) => {
        // DataStatistic
        UserActiveUpdate(2, { active: "播放广告视频", store: iStoreID, ads_order: iAdsIndex });

        let arrVedioLight = this.state.arrVedioLight;
        arrVedioLight[iAdsIndex] = false;
        this.setState({
            ...this.state,
            // arrVedioPlay: true,
            arrVedioLight: arrVedioLight,
            bAutoScroll: false
        });
    }
    handlePause = (iVideoID) => {
        if (iVideoID >= 0) {
            let arrVedioLight = this.state.arrVedioLight;
            arrVedioLight[iVideoID] = true;
            this.setState({
                ...this.state,
                // arrVedioPlay: false,
                arrVedioLight: arrVedioLight,
                bAutoScroll: true
            });
        } else {
            this.setState({
                ...this.state,
                arrVedioLight: [true, true, true, true, true, true, true, true, true, true],
                bAutoScroll: true
            });
        }
    }

    // 渲染完毕……
    componentDidMount() {
        this.GetAdsImages();
    }

    render() {

        let uiAds = [];
        let iAdsNum = this.arrTitleAds.length;
        if (iAdsNum > 0) {
            for (let i = 0; i < iAdsNum; i++) {
                let uiImage;
                if (this.arrTitleAds[i].type === 0) {  // 图片
                    uiImage = (
                        <img className="ads_img" key={i} src={this.arrTitleAds[i].image} alt=''
                            onClick={this.AdsImageClicked.bind(this, this.props.arrAdsImages[i].store_id, i)} />
                    );
                } else {  // 视频
                    uiImage = (
                        <ReactPlayer key={iAdsNum} width='100vw' height='56.25vw'
                            loop playing={this.state.arrVedioPlay[i]} light={this.state.arrVedioLight[i]}
                            onPlay={this.handlePlay.bind(this, this.props.arrAdsImages[i].store_id, i)}
                            onPause={this.handlePause.bind(this, i)}
                            onClick={this.AdsImageClicked.bind(this, this.props.arrAdsImages[i].store_id, i)}
                            url={this.arrTitleAds[i].image} />
                    );
                }
                uiAds.push(uiImage);
            }
        } else {
            // this.GetAdsImages();
        }

        return (
            < Carousel className="ads_carousel_container" afterChange={this.handlePause.bind(this, -1)}
                effect="fade" autoplay dotPosition="top" >
                {uiAds}
            </Carousel >
        );
    }
}
export default AdsCarousel;

// 备用代码
// let strImageURL = "https://a.slack-edge.com/80588/img/tractor/banners/second_setup@2x.png";
// const uiAdvertiseArea = (
//     <Carousel effect="fade" autoplay className="ads_carousel_container">
//         {this.arrTitleAds.map((data, index) => {
//             let strStyle = "ads_img " + this.arrTitleAds[index].store_image;
//             return (
//                 <img className={strStyle} src={strImageURL} key={index}
//                     onClick={this.JumpToStore.bind(this, index)} />
//             );
//         })}
//     </Carousel>
// )
