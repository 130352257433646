import React, { Component } from 'react'
import axios from 'axios';

import Affix from 'antd/es/affix';
import Spin from 'antd/es/spin';

// import { Affix, Spin } from 'antd'
import Sidebar from './UI_Module/Sidebar.js'
import SearchBox from './UI_Module/SearchBox.js'
import AdsCarousel from './UI_Module/AdsCarousel.js'
import MainCategories from './UI_Module/MainCategories.js'
import SubCategories from './UI_Module/SubCategories.js'
// import BannerCarousel from './UI_Module/BannerCarousel.js'
import Recommends from './UI_Module/Recommends.js'
import StoreList from './UI_Module/StoreList.js'
import '../../styles/ClientSide/home_page.css'
import '../../styles/icon_image.css'
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { CheckUserLocationFromCookie } from "../../functions/PositionProcess"
import { GetCityIDFromName } from "../../functions/PositionProcess"
import { StoreOrderBasedOnDistance } from "../../functions/PositionProcess"
import uuid from "uuid";

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            strAffixStyle: "affix_navbar_white",
            bLoading: [true, true, true, true, true]
        };
        this.arrMainCategory = [];
        this.arrSubCategory = [];
        this.arrStoreList = [];
        this.arrAdsImages = [];
        this.arrRecommendStores = [];
        this.arrPosition = [];
        this.strCityName = "";
    }
    // 顶部栏移动/锁定在顶部
    OnAffixBarMoving = (affixed) => {
        // console.log("首页搜索栏：" + affixed);
        if (affixed) {
            this.setState({
                ...this.state,
                strAffixStyle: "navbar_affixed_in"
            });
        } else {
            this.setState({
                ...this.state,
                strAffixStyle: "navbar_affixed_out"
            });
        }
    }
    // 点击了定位按钮
    LocationButtonClick = () => {
        // DataStatistic
        UserActiveUpdateBack(1, { active: "点击定位按钮" }, this.JumpToLocationPage);
    }
    // 跳转到定位界面
    JumpToLocationPage = () => {
        window.location.href = '/webapp/location';
    }
    // 请求错误或超时的处理
    AxiosErrorOrTimeOut = (iRequest, error) => {
        console.log(error);
        switch (iRequest) {
            case 0:
            case 1: {  // GetAdsInfo or GetAdsImages：获取获取头图数据
                this.GetAdsList();
                break;
            }
            case 2:
            case 3:
            case 6: {  // GetMainCategories or GetSubCategories：获取所有分类信息；获取商家列表信息
                this.GetMainCategoriesData(iRequest);
                break;
            }
            case 4:
            case 5: {  // 获取推荐区域数据
                this.GetRecommendList();
                break;
            }
            default: break;
        }
    }
    // 获取头图数据
    GetAdsList = () => {
        axios('/GetAdsInfo', { params: { type: [1] } })
            .then(({ data }) => {
                let arrStoreID = [];
                for (let i = 0; i < data.length; i++) {
                    arrStoreID.push(data[i].store_id);
                }
                if (arrStoreID.length > 0) {
                    axios('/GetAdsImages', {
                        params: { store_id: arrStoreID, ads_order: 10 }
                    }).then(
                        ({ data }) => {
                            for (let i = 0; i < arrStoreID.length; i++) {
                                for (let j = 0; j < data.length; j++) {
                                    if (data[j].store_id === arrStoreID[i]) {
                                        this.arrAdsImages.push(data[j]);
                                        break;
                                    }
                                }
                            }
                            let bLoading = this.state.bLoading;
                            bLoading[3] = false;
                            this.setState({
                                ...this.state,
                                bLoading: bLoading,
                            });
                        }
                    ).catch(function (error) { this.AxiosErrorOrTimeOut(0, error); });
                }
            }).catch(function (error) { this.AxiosErrorOrTimeOut(1, error); });
    }
    // 获取所有分类信息
    GetCategoriesData = (iRequest) => {
        let strRequest = '';
        if (iRequest === 6)
            iRequest = 2;
        if (iRequest === 2)
            strRequest = '/GetMainCategories';
        if (iRequest === 3)
            strRequest = '/GetSubCategories';
        if (strRequest === '')
            return;
        axios(strRequest).then(
            ({ data }) => {
                let bLoading = this.state.bLoading;
                if (iRequest === 2) {
                    this.arrMainCategory = data;
                    console.log("city = " + this.strCityName);
                    GetCityIDFromName(this.GetStoreList, this.strCityName);
                    bLoading[0] = false;
                }
                if (iRequest === 3) {
                    this.arrSubCategory = data;
                    bLoading[1] = false;
                }
                this.setState({
                    ...this.state,
                    bLoading: bLoading
                });
            }
        ).catch(function (error) { this.AxiosErrorOrTimeOut(iRequest, error); });
    }
    // 获取推荐区域数据
    GetRecommendList = () => {
        axios('/GetAdsInfo', { params: { type: [2, 3, 4, 5] } })
            .then(({ data }) => {
                let arrStoreID = [];
                for (let i = 0; i < data.length; i++) {
                    arrStoreID.push(data[i].store_id);
                }
                if (arrStoreID.length > 0) {
                    axios('/GetStoreThumb', {
                        params: { id: arrStoreID }
                    }).then(
                        ({ data }) => {
                            this.arrRecommendStores = data;
                            let bLoading = this.state.bLoading;
                            bLoading[4] = false;
                            this.setState({
                                ...this.state,
                                bLoading: bLoading
                            });
                        }
                    ).catch(function (error) { this.AxiosErrorOrTimeOut(4, error); });
                }
            }).catch(function (error) { this.AxiosErrorOrTimeOut(5, error); });
    }
    // 获取商家列表信息
    GetStoreList = (iLocation) => {
        let arrMainCategoriesID = [];
        for (let i = 0; i < this.arrMainCategory.length; i++) {
            arrMainCategoriesID.push(this.arrMainCategory[i].id);
        }
        // 仅限大温地区（临时）
        // axios('/GetChildrenLocations', { params: { id: 3 } })
        //     .then(({ data }) => {
        //         let arrLocations = []
        //         for (let i = 0; i < data.length; i++)
        //             arrLocations.push(data[i].id);
        axios('/GetStores', {
            params: { location: [iLocation], main_category: arrMainCategoriesID }
        }).then(({ data }) => {
            this.arrStoreList = StoreOrderBasedOnDistance(this.arrPosition, data);
            let bLoading = this.state.bLoading;
            bLoading[2] = false;
            this.setState({
                ...this.state,
                bLoading: bLoading,
            });
        }).catch(function (error) { this.AxiosErrorOrTimeOut(6, error); });
        // }).catch(function (error) { console.log(error); });
    }
    // 获取用户定位信息之后
    AfterGetLocation = (city, address, position) => {
        this.arrPosition = position;
        this.strCityName = city;
        // 获取页面各部分数据
        this.GetAdsList();
        this.GetCategoriesData(2);
        this.GetCategoriesData(3);
        this.GetRecommendList();
    }
    // 渲染完毕……
    componentDidMount() {
        console.log(uuid.v4());
        // DataStatistic
        UserActiveUpdate(0, { active: "访问首页" });
        // 检查Cookie中的用户位置信息
        CheckUserLocationFromCookie(false, this.AfterGetLocation);
    }

    render() {
        // 左上角定位按钮
        const uiLocationButton = (
            <div className="navbar_location_btn navbar_location_icon  location_icon_brown"
                onClick={this.LocationButtonClick} />
        );
        // Affix 搜索栏控件
        const uiAffix = (
            <div className="affix_navbar_container">
                <Affix target={() => this.container} onChange={this.OnAffixBarMoving.bind(this)}>
                    <div className={"affix_navbar " + this.state.strAffixStyle}>
                        <SearchBox />
                    </div>
                </Affix>
            </div>
        );
        // 头图区域
        let uiAdsTitle = (
            <div className="ads_loading">
                <Spin className="ads_spin" size="large" />
            </div>
        );
        if (!this.state.bLoading[3]) {
            uiAdsTitle = <AdsCarousel iCurrentPage={0} arrAdsImages={this.arrAdsImages} />
        }
        // 主分类按钮区域
        let uiMainCategories = (
            <div className="categories_loading">
                <Spin className="categories_spin" size="large" />
            </div>
        );
        if (!this.state.bLoading[0] && !this.state.bLoading[1]) {
            uiMainCategories = <MainCategories arrMainCategory={this.arrMainCategory} />
        }
        // 子分类按钮区域
        let uiSubCategories = (<div />);
        if (!this.state.bLoading[0] && !this.state.bLoading[1]) {
            uiSubCategories = <SubCategories type={0} arrSubCategory={this.arrSubCategory}
                bSwitching={false} />
        }
        // Mouthing推荐区域
        let uiRecommends = (
            <div className="recommends_loading">
                <Spin className="recommends_spin" size="large" />
            </div>
        );
        if (!this.state.bLoading[0] && !this.state.bLoading[4]) {
            uiRecommends = <Recommends bShowFilter={true} iCurrentPage={0}
                arrMainCategory={this.arrMainCategory}
                arrRecommendStores={this.arrRecommendStores} />;
        }
        // 商家列表区域
        let uiStoreList = (
            <div className="storelist_loading">
                <Spin className="storelist_spin" size="large" />
            </div>
        );
        if (!this.state.bLoading[0] && !this.state.bLoading[1] && !this.state.bLoading[2]) {
            uiStoreList = <StoreList iCurrentPage={0} strTitle={"附近商家"}
                bShowFilter={true}
                arrMainCategory={this.arrMainCategory}
                arrSubCategory={this.arrSubCategory}
                arrStoreList={this.arrStoreList} />;
        }

        return (
            <div className="home_page" ref={node => { this.container = node; }}>
                {/* 顶部标题栏 */}
                <Sidebar iCurrentPage={0} /> {uiLocationButton}
                {/* 首页广告轮播区域 */}
                {uiAdsTitle}
                {/* Affix搜索栏 */}
                {uiAffix}
                {/* 主分类区域 */}
                {uiMainCategories}
                {/* 子分类轮播区域 */}
                {uiSubCategories}
                {/* 优惠券轮播区域 */}
                {/* <BannerCarousel /> */}
                {/* Mouthing优惠 */}
                {uiRecommends}
                {/* 附近商家 */}
                {uiStoreList}
            </div>
        )
    }
}
export default Home
