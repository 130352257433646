import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ZhiHeWebsite from "./ZhiHeWebsite.js";
import XiYouJiWebsite from "./XiYouJiWebsite.js";
import SooningWebsite from "./SooningWebsite.js";
import LekonWebsite from "./LekonWebsite.js";
import JetonWebsite from "./JetonWebsite.js";
import UserBackendJW from "./UserBackendJW.js";
import UserBackendSN from "./UserBackendSN.js";
import Start from "./Start.js";
import Home from "./Home.js";
import Location from "./Location.js";
import Login from "./Login.js";
import Register from "./Register.js";
import UserCenter from "./UserCenter.js";
import UserCenterIndieCN from "./UserCenterIndieCN.js";
import UserCenterIndie from "./UserCenterIndie.js";
import UserCenterIndie2 from "./UserCenterIndie2.js";
import SearchPage from "./SearchPage.js";
import Category from "./Category.js";
import Store from "./Store.js";
import Item from './Item.js'
import "../../styles/ClientSide/home_page.css";
import { GeocodeInit } from "../../functions/PositionProcess.js"

import "antd/dist/antd.css";

class Mouthing extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    // 渲染完毕……
    componentDidMount() {
        GeocodeInit();
    }

    render() {
        return (
            <div className="page_basic">
                <Switch>
                    <Route exact path="/" component={XiYouJiWebsite} />
                    <Route exact path="/webapp" component={Start} />
                    <Route exact path="/zhihe" component={ZhiHeWebsite} />
                    <Route exact path="/xiyouji" component={XiYouJiWebsite} />
                    <Route exact path="/sooning" component={SooningWebsite} />
                    <Route exact path="/lekon" component={LekonWebsite} />
                    <Route exact path="/jeton" component={JetonWebsite} />
                    <Route path="/xiyouji/user_center" component={UserBackendJW} />
                    <Route path="/sooning/user_center" component={UserBackendSN} />
                    <Route path="/webapp/start" component={Start} />
                    <Route path="/webapp/home" component={Home} />
                    <Route path="/webapp/location" component={Location} />
                    <Route path="/webapp/login" component={Login} />
                    <Route path="/webapp/register" component={Register} />
                    <Route path="/webapp/user" component={UserCenter} />
                    <Route path="/webapp/user_cn" component={UserCenterIndieCN} />
                    <Route path="/webapp/user_profile" component={UserCenterIndie} />
                    <Route path="/webapp/user_indie" component={UserCenterIndie2} />
                    <Route path="/webapp/search" component={SearchPage} />
                    <Route path="/webapp/category" component={Category} />
                    <Route path="/webapp/store/:id/item/:id" component={Item} />
                    <Route path="/webapp/store/:id" component={Store} />
                </Switch>
            </div>
        );
    }

}
export default Mouthing;
