import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import Clipboard from 'react-clipboard.js';

import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Collapse from 'antd/es/collapse';
import Modal from 'antd/es/modal';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
// import Tooltip from 'antd/es/tooltip';
import Timeline from 'antd/es/timeline';
import message from 'antd/es/message';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import "../../styles/ClientSide/login_register_page.css";
import "../../styles/ClientSide/webpage.css";
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
const { Panel } = Collapse;
const { TextArea } = Input;

// const mapStyles = { width: '100%', height: '100%' };

class XiYouJiWebsite extends Component {
    constructor(props) {
        super();
        console.log("host name = ", window.location.hostname, window.location.host);
        console.log("domain name = ", document.domain);
        console.log("url = ", window.location.href);

        let strDomain = window.location.href;
        if (strDomain.indexOf('mouthing.life') >= 0/*|| strDomain.indexOf('mouthing.info') >= 0*/) {
            console.log("forward to zhihe express");
            window.location.href = 'https://www.mouthing.info/zhihe';
        }

        let strParams = window.location.search;  // 筛选出?后面的部分
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.strCNum = "";
        if (strParams.indexOf('cnum') >= 0)
            this.strCNum = strParams.split('cnum=')[1];
        this.strUserID = "";
        if (strParams.indexOf('uid') >= 0)
            this.strUserID = strParams.split('uid=')[1];
        this.iTab = 0;
        if (strParams.indexOf('tab') >= 0)
            this.iTab = parseInt(strParams.split('tab=')[1], 10);
        this.strUserCode = "";
        if (strParams.indexOf('user') >= 0)
            this.strUserCode = strParams.split('user=')[1];

        this.state = {
            bLoading: true,
            bLogin: false,
            bSubmitOrderDlg: false,
            iCurrentMenu: this.iTab,
            objPickupLocation: { lat: null, lng: null },
            iCurrentOrderStatus: 0,
            bDeliveryStatusFilterChange: false,
            bViewTrackingInfo: false,
            iCurrentTransitWay: 0
        };
        this.objUserInfo = {};
        this.arrAddOrders = [];
        this.arrMenuButton = [
            { name: "首页", icon: 'home' },
            { name: "个人中心", icon: 'user' },
            // { name: "关于我们", icon: 'profile' }
            // { name: "运费预估", icon: 'dollar' }
        ];
        this.arrOrderListOge = [];
        this.arrOrderList = [];
        this.arrOrderShowList = [];
        this.arrTransitTrackingNo = [];
        this.arrPackageDetails = [];
        this.arrOrderStatus = ["待签收", "已签收", "已合箱", "已发货", "已投递"];  // 物流用户订单状态用
        this.arrOrderStatusMenu = ["全部", "待签收", "已签收", "已合箱", "已发货", "已投递"];
        this.objCurrentOrder = { comments: "" };
        this.arrDeliveryStatusFilter = ["签收后立即转运", "签收后寄存在仓库（最多90天），等待其它货物"];
        this.arrOrderDeliveryStatus = [];
        this.arrExpressDetails = [];
        this.arrTransitWay = ['中国至加拿大', '加拿大至中国'];
    }
    // 切换菜单按钮
    SwitchMenuButton = (index) => {
        if (index !== 0 && !this.state.bLogin) {
            this.JumpToLogin();
            return;
        }
        this.iTab = index;
        if (index === 0) {
            this.setState({
                ...this.state,
                iCurrentMenu: index,
                bLoading: false,
                iCurrentOrderStatus: 0
            });
        }
        if (index === 1) {
            this.JumpToUserCenter();
            // this.setState({
            //     ...this.state,
            //     iCurrentMenu: index,
            //     bLoading: false,
            //     iCurrentOrderStatus: 0
            // });
        }
        if (index === 2) {
            this.setState({
                ...this.state,
                iCurrentMenu: index,
                bLoading: true,
                iCurrentOrderStatus: 0
            });
            this.arrPackageDetails = [];
            if (this.objUserInfo.code !== "") {
                if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                    // 分配storage Code
                    axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id, code: 'LK39' })
                        .then(({ data }) => {
                            this.objUserInfo.storage_code = data[0].storage_code;
                        }).catch(function (error) { console.log(error); });
                } else {
                    axios('/GetUserExpressEnter', {
                        params: {
                            name: this.objUserInfo.name,
                            user_code: this.objUserInfo.code,
                            storage_code: this.objUserInfo.storage_code
                        }
                    }).then(({ data }) => {
                        this.arrOrderList = [];
                        if (data.length > 0) {
                            this.arrOrderListOge = data;
                            this.arrOrderList = data;
                            if (this.state.iCurrentOrderStatus > 0)
                                this.OrganizeOrderList();
                            if (this.arrOrderList.length > 0) {
                                this.GetOrderPackageList();
                                this.GetPackageTransitInfo();
                            } else {
                                this.arrOrderShowList = [];
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }
                        } else {
                            this.arrOrderShowList = [];
                            this.setState({
                                ...this.state,
                                bLoading: false
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                }
            }
        }
    }
    // 获取已合箱包裹的物流信息
    GetPackageTransitInfo = () => {
        for (let i = 0; i < this.arrOrderShowList.length; i++) {
            if (this.arrOrderShowList[i].length > 0) {
                axios('/GetPackageDetails', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                    .then(({ data }) => {
                        this.arrPackageDetails.push(data[0]);
                        axios('/GetPackageAutoOrder', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                            .then(({ data }) => {
                                this.arrTransitTrackingNo.push(data);
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }
        }
    }
    // 整理出合箱分级数据
    GetOrderPackageList = () => {
        this.arrOrderShowList = [];
        let strPackageNo = "";
        let arrOrderPackage = [];
        for (let i = 0; i < this.arrOrderList.length; i++) {
            if (this.arrOrderList[i].package_no !== null && this.arrOrderList[i].package_no !== '') {
                if (strPackageNo === "") {
                    strPackageNo = this.arrOrderList[i].package_no;
                    arrOrderPackage.push(this.arrOrderList[i]);
                    if (i === this.arrOrderList.length - 1) {
                        this.arrOrderShowList.push(arrOrderPackage);
                    }
                } else {
                    if (strPackageNo === this.arrOrderList[i].package_no) {
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    } else {
                        this.arrOrderShowList.push(arrOrderPackage);
                        arrOrderPackage = [];
                        strPackageNo = this.arrOrderList[i].package_no;
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    }
                }
            } else {
                if (strPackageNo !== "") {
                    strPackageNo = "";
                    this.arrOrderShowList.push(arrOrderPackage);
                    arrOrderPackage = [];
                }
                this.arrOrderShowList.push(this.arrOrderList[i]);
            }
        }
    }
    // 生成订单子控件
    GenerateChildPanelUI = (item, index) => {
        let strDateTime = "无记录";
        if (item.order_date !== null) {
            if (item.order_date.indexOf('T') >= 0) {
                let dateNow = new Date();
                let dateOrder = new Date(item.order_date);
                let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                let day = parseInt(drr / (24 * 60 * 60 * 1000));
                if (day > 0)
                    strDateTime = day + "天前";
                else {
                    let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                    if (hours > 0) {
                        strDateTime = hours + "小时前";
                    } else {
                        let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                        if (minutes > 0) {
                            strDateTime = minutes + "分钟前";
                        } else {
                            strDateTime = "刚刚";
                        }
                    }
                }
            }
        }
        // let strPriceWeight = "还未称重";
        // if (item.express_info !== null && item.express_info !== "") {
        //     strPriceWeight = JSON.parse(item.express_info);
        //     if (parseFloat(strPriceWeight.price_weight) <= 0.0)
        //         strPriceWeight = "还未称重";
        //     else
        //         strPriceWeight = parseFloat(strPriceWeight.price_weight) + " kg";
        // }
        let uiPackageNo = null;
        let uiStatus = null;
        if (item.package_no !== null && item.package_no !== "")
            uiPackageNo = (
                <div className="web_center_order_details_text">
                    {"合箱编码：" + item.package_no}</div>
            );
        else
            uiStatus = (<div className="web_center_order_details_title_right">
                {" " + this.arrOrderStatus[parseInt(item.status, 10)]}
            </div>);
        let uiTitle = (
            <div className="web_center_order_details_title">
                <div className="web_center_order_details_title_left">
                    <Icon type="file" theme="filled" />
                    {" " + item.express_order}
                </div>
                {uiStatus}
            </div>
        );
        uiPackageNo = null;
        let uiComments = null;
        if (item.comments)
            if (item.comments !== null && item.comments !== "")
                if (item.comments.trim() !== "")
                    uiComments = <div className="web_center_order_details_text">
                        {"备注信息：" + item.comments}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>

        let iDeliveryStatus = 0;
        if (item.delivery_status) {
            if (item.delivery_status !== null && item.delivery_status !== "") {
                iDeliveryStatus = parseInt(item.delivery_status, 10);
            }
        }
        return (
            <Panel header={uiTitle} key={(index + 1).toString()} >
                <div className="web_center_order_details_area">
                    <div className="web_center_order_details_text">
                        {"提交时间：" + strDateTime}
                    </div>
                    <div className="web_center_order_details_text">
                        {"发货选项："}
                        {this.arrDeliveryStatusFilter[iDeliveryStatus]}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>
                    {uiComments}
                    {uiPackageNo}
                </div>
            </Panel>
        );
    }
    // 生成订单母控件
    GenerateParentPanelUI = (orders, index) => {
        let uiPanel = null;

        // 已合箱的订单
        if (orders.length) {
            let uiTitle = (
                <div className="web_center_order_details_title">
                    <div className="web_center_order_details_title_left">
                        <Icon type="code-sandbox" />
                        {" 合箱编码 " + orders[0].package_no}
                    </div>
                    <div className="web_center_order_details_title_right">
                        {" " + this.arrOrderStatus[parseInt(orders[0].status, 10)]}
                    </div>
                </div>
            );
            let strPriceWeight = "货物称重：还未称重";
            let strTotalPrice = "总计费用：还未称重";
            let strAutoOrderNo = "";
            if (this.arrPackageDetails.length > 0) {
                for (let i = 0; i < this.arrPackageDetails.length; i++) {
                    if (this.arrPackageDetails[i].package_no === orders[0].package_no) {
                        strPriceWeight = "货物称重：" + this.arrPackageDetails[i].price_weight + " kg";
                        strTotalPrice = "总计费用：$ " + this.arrPackageDetails[i].total_price;
                        strAutoOrderNo = this.arrPackageDetails[i].auto_order_no;
                        break;
                    }
                }
            }
            let iTargetOrder = 0;
            if (this.arrTransitTrackingNo.length > 0) {
                for (let i = 0; i < this.arrTransitTrackingNo.length; i++) {
                    if (this.arrTransitTrackingNo[i] === strAutoOrderNo) {
                        iTargetOrder = i;
                        break;
                    }
                }
            }
            // if (this.arrPackageDetails[index]) {
            //     strPriceWeight = "货物称重：" + this.arrPackageDetails[index].price_weight + " kg";
            // }
            // if (this.arrPackageDetails[index]) {
            //     strTotalPrice = "总计费用：$ " + this.arrPackageDetails[index].total_price;
            // }
            uiPanel = (
                <Panel header={uiTitle} key={(index + 1).toString()}>
                    {/* <div className="web_center_transit_tracking_no">
                        {"物流追踪号：" + this.arrTransitTrackingNo[index]}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, index)}>查看物流</div>
                    </div> */}
                    <div className="web_center_transit_tracking_no">
                        {strPriceWeight}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, iTargetOrder)}>查看物流</div>
                    </div>
                    <div className="web_center_transit_tracking_no">
                        {strTotalPrice}
                    </div>
                    <Collapse >
                        {orders.map((order, indexChild) => {
                            let uiChildPanel = this.GenerateChildPanelUI(order, indexChild);
                            return (uiChildPanel);
                        })}
                    </Collapse>
                </Panel>
            );
        }
        // 未合箱的订单
        else {
            uiPanel = this.GenerateChildPanelUI(orders, index);
        }
        return uiPanel;
    }
    // 跳转到物流追踪页面
    ViewTransitDetails = (index) => {
        window.location.href = '/tracking/no=' + this.arrTransitTrackingNo[index];
    }
    // 跳转用户中心
    JumpToUserCenter = () => {
        // 已登录
        if (this.state.bLogin) {
            window.location.href = '/xiyouji/user_center';
        }
        // 未登录
        else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            window.location.href = '/webapp/login?store=xiyouji';
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    if (this.iTab !== 0)
                        this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    console.log("user info : ", this.objUserInfo);
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                        // 分配storage Code
                        axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id, code: 'LK39' })
                            .then(({ data }) => {
                                console.log("AllocateUserStorageCode : ", data);
                                this.objUserInfo.storage_code = data[0].storage_code;
                                console.log("user info = ", this.objUserInfo);
                                this.SwitchMenuButton(this.iTab);
                                this.setState({
                                    ...this.state,
                                    bLogin: true
                                });
                            }).catch(function (error) { console.log(error); });
                    } else {
                        this.SwitchMenuButton(this.iTab);
                        this.setState({
                            ...this.state,
                            bLogin: true
                        });
                    }
                }
            }).catch(function (error) { console.log(error); });
    }
    // 跳回首页
    HomePage = () => {
        window.location.href = '/';
    }
    // 拷贝信息到粘贴板
    CopyAddressInfo = () => {
        // console.log(strInfo);
        // if (navigator.clipboard) {
        message.info("复制成功！");
        //     navigator.clipboard.writeText(strInfo);
        // } else {
        //     message.info("正在为您跳转到安全链接！请重新尝试复制");
        //     window.location.href = "https://www.mouthing.info";
        // }
    }
    // 添加订单
    AddNewOrder = (iType, objOrder) => {
        if (iType === 0) {
            let strAddOrder = this.refs.order_input.state.value;
            if (!strAddOrder || strAddOrder.trim() === "") {
                message.warning("请输入快递单号！");
                return;
            }
            this.arrAddOrders = [];
            this.arrOrderDeliveryStatus = [];
            if (strAddOrder.indexOf('\n') >= 0) {
                let arrAddOrders = strAddOrder.split('\n');
                for (let i = 0; i < arrAddOrders.length; i++) {
                    if (arrAddOrders[i] !== "" && arrAddOrders[i].trim() !== "") {
                        this.arrAddOrders.push(arrAddOrders[i].trim());
                        this.arrOrderDeliveryStatus.push(0);
                    }
                }
            } else {
                this.arrAddOrders.push(strAddOrder);
                this.arrOrderDeliveryStatus.push(0);
            }
        } else {
            this.arrAddOrders = [];
            this.arrAddOrders.push(objOrder.express_order);
            this.arrOrderDeliveryStatus = [];
            this.arrOrderDeliveryStatus.push(objOrder.delivery_status);
            this.objCurrentOrder = objOrder;
        }
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            bSubmitOrderDlg: true
        });
    }
    // 确认/取消提交订单（物流单号专用）
    SubmitOrder = (bConfirm) => {
        this.objCurrentOrder = { comments: "" };
        if (!bConfirm) {
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            return;
        }
        let arrExpressOrders = [];
        for (let i = 0; i < this.arrAddOrders.length; i++) {
            let strInputKey = "express_comments_input_" + i.toString();
            let objExpressOrders = {
                express_order: this.arrAddOrders[i],
                receiver: this.objUserInfo.name,
                storage_code: this.objUserInfo.storage_code,
                user_code: this.objUserInfo.code,
                store_id: 20000,
                comments: this.refs[strInputKey].state.value,
                status: 0,
                delivery_status: this.arrOrderDeliveryStatus[i]
            }
            if (objExpressOrders.comments === undefined || objExpressOrders.comments === null) {
                objExpressOrders.comments = "";
            }
            arrExpressOrders.push(objExpressOrders);
        }
        message.loading({ content: '正在提交……', key: g_strMessageKey });
        axios.post('/CreateExpressOrders', {
            arrExpressOrders: arrExpressOrders
        }).then(({ data }) => {
            message.success({ content: "订单编号提交成功！请在订单列表页查看", key: g_strMessageKey, duration: 2 });
            // DataStatistic
            UserActiveUpdate(4, { active: "用户端: 批量提交订单号", action: "新建" });
            if (this.refs.order_input)
                this.refs.order_input.state.value = "";
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            this.SwitchMenuButton(2);
        }).catch(function (error) { console.log(error); });

    }
    // 取消订单（自提/通用）
    CancelOrder = (type, index) => {
        if (type === 0) {
            this.setState({
                ...this.state,
                bCancelOrderDlg: true
            });
        } else {
            if (type === 1) {
                let oid = this.arrOrderList[index].oid;
                axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: -1 })
                    .then(({ data }) => {
                        message.info("订单已取消！");
                        this.ShowList(3);
                    });
            }
            this.setState({
                ...this.state,
                bCancelOrderDlg: false
            })
        }
    }
    // 获取自提点位置信息
    // GetPickupLocation = (strAddress) => {
    //     let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
    //     strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
    //     axios.get(strUrl)
    //         .then((response) => {
    //             this.setState({
    //                 ...this.state,
    //                 objPickupLocation: response.data.results[0].geometry.location
    //             });
    //         }).catch(err => {
    //             console.log(err) //Axios entire error message
    //             console.log(err.response.data.error) //Google API error message 
    //         });
    // }
    // 跳转回登录页
    JumpToLogin = () => {
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        // window.location.href = '/webapp/login';
        window.location.href = '/webapp/login?store=xiyouji';
    }
    // 切换物流运输查询方式
    TransitWaySwitch = (index) => {
        this.setState({
            ...this.state,
            iCurrentTransitWay: index
        });
    }
    // 追踪订单
    OrderTracking = () => {
        let strSearch = this.refs.order_search.state.value;
        if (strSearch) {
            if (strSearch === null || strSearch === "") {
                message.warning("请输入需要查询的订单号！");
            } else {
                if (strSearch.trim() === "")
                    message.warning("请输入需要查询的订单号！");
                else {
                    // 西邮寄加拿大至中国运单
                    if (this.state.iCurrentTransitWay === 1) {
                        console.log("OrderTracking : ", strSearch);
                        this.setState({
                            ...this.state,
                            bLoading: true
                        });
                        message.loading({ content: '运单查找中……', key: g_strMessageKey });
                        let objParms = {
                            order_no_search: strSearch,
                            user_search: "",
                            item_search: "",
                            store_id: 30000,
                            user_name: "",
                            page: 1,
                            num: 1
                        };
                        axios('/GetCACNOrder', {
                            params: objParms
                        }).then(({ data }) => {
                            console.log(data);
                            if (data.orders.length > 0) {
                                if (data.orders[0].express_detail !== undefined && data.orders[0].express_detail !== null && data.orders[0].express_detail !== "") {
                                    message.success({ content: "运单信息加载完成！", key: g_strMessageKey, duration: 2 });
                                    this.arrExpressDetails = JSON.parse(data.orders[0].express_detail);
                                    console.log("GetCACNOrder : ", data.orders[0].express_detail);
                                    this.setState({
                                        ...this.state,
                                        bLoading: false,
                                        bViewTrackingInfo: true
                                    });
                                } else {
                                    this.arrExpressDetails = [];
                                    message.info({ content: "该运单暂无物流信息！", key: g_strMessageKey, duration: 2 });
                                    this.setState({
                                        ...this.state,
                                        bViewTrackingInfo: true,
                                        bLoading: false
                                    });
                                }
                            } else {
                                message.info({ content: "未找到相应运单，请检查您的单号是否正确", key: g_strMessageKey, duration: 2 });
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }

                        }).catch(function (error) {
                            console.log(error);
                        });

                    } else
                        window.open('/tracking/no=' + strSearch);
                    // window.location.href = '/tracking/no=' + strSearch;
                }
            }
        } else
            message.warning("请输入需要查询的订单号！");
    }
    // 关闭物流状态
    CloseExpressDetail = () => {
        this.setState({
            ...this.state,
            bViewTrackingInfo: false
        });
    }
    // 订单筛选下拉框点击
    OrderTypeFilterCLicked = (index) => {
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            iCurrentOrderStatus: index
        });
    }
    // 订单筛选整理
    OrganizeOrderList = () => {
        this.arrOrderList = []
        for (let i = 0; i < this.arrOrderListOge.length; i++) {
            if (this.arrOrderListOge[i].status === this.state.iCurrentOrderStatus - 1) {
                this.arrOrderList.push(this.arrOrderListOge[i]);
            }
        }
    }
    // 退出登录
    LogOut = () => {
        this.setState({
            ...this.state,
            bLogin: false
        });
        // DataStatistic
        UserActiveUpdate(3, { active: "退出登录" });
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        // window.location.href = '/';
    }
    // 转运状态过滤器切换
    SwitchDeliveryStatusFilter = (index, status) => {
        this.arrOrderDeliveryStatus[index] = status;
        this.setState({
            ...this.state,
            bDeliveryStatusFilterChange: true
        });
    }
    // 渲染完毕……
    componentDidMount() {
        document.title = "西邮寄物流";
        // document.title = "服务/娱乐/资讯/便民";
        // DataStatistic
        UserActiveUpdate(0, { active: "访问主网站" });
        // this.GetPickupLocation("7080 River Rd., Richmond");
        // 链接后带有用户编码
        if (this.strUserCode !== "") {
            cookie.save('wm_user_code', this.strUserCode, { path: '/' });
            cookie.save('wm_login_status', 1, { path: '/' });
            message.loading({ content: '用户数据获取中……', key: g_strMessageKey });
            // 先获取总页数
            let objBody = { "PageIndex": "0", "OpenID": "XiYouJi" };
            objBody = { "Keywords": this.strUserCode, "PageIndex": "0", "OpenID": "XiYouJi" };
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/';  // 跨域访问代理
            fetch(proxyUrl + "http://cn.jwestlog.com/API/UserSelectAPI.aspx", {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(objBody)
            }).then(response => response.json())
                .then(data => {
                    message.success({ content: "用户数据加载完成！", key: g_strMessageKey, duration: 2 });
                    this.objUserInfo = data.content[0];
                    this.objUserInfo.id = this.objUserInfo.ID;
                    this.objUserInfo.username = this.objUserInfo.UserName;
                    this.objUserInfo.depotname = this.objUserInfo.DepotName;
                    this.objUserInfo.mobilephone = this.objUserInfo.MobilePhone;
                    this.objUserInfo.email = this.objUserInfo.Email;
                    this.objUserInfo.qq = this.objUserInfo.QQ;
                    this.objUserInfo.mymoney = this.objUserInfo.MyMoney;
                    this.objUserInfo.password = this.objUserInfo.Password;
                    this.objUserInfo.cnum = this.objUserInfo.CNum;

                    this.objUserInfo.xiyouji_id = this.objUserInfo.ID;
                    this.objUserInfo.name = this.objUserInfo.UserName;
                    this.objUserInfo.phone = this.objUserInfo.MobilePhone;
                    this.objUserInfo.storage_code = this.objUserInfo.CNum;

                    delete this.objUserInfo.ID;
                    delete this.objUserInfo.UserName;
                    delete this.objUserInfo.DepotName;
                    delete this.objUserInfo.MobilePhone;
                    delete this.objUserInfo.Email;
                    delete this.objUserInfo.MyMoney;
                    delete this.objUserInfo.Password;
                    delete this.objUserInfo.CNum;
                    delete this.objUserInfo.QQ;
                    axios.post('/DB/UpdateAllXiYouJiUsers', { arrUserData: [this.objUserInfo] })
                        .then(({ data }) => {
                        }).catch(function (error) { console.log(error); });

                    this.setState({
                        ...this.state,
                        bLogin: true
                    });
                }).catch(err => {
                    console.log("err : ", err);
                });
        } else {
            let strUserCode = cookie.load('wm_user_code');
            let iUserLoginStatus = cookie.load('wm_login_status');
            if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
                this.strCurrentUserCode = strUserCode;
                this.UserCodeCheck();
            } else {
                cookie.save('wm_user_code', '', { path: '/' });
                cookie.save('wm_login_status', -1, { path: '/' });
                if (this.iTab !== 0)
                    this.JumpToLogin();
            }
        }
    }

    render() {

        let strUserName = "";
        let uiFunctionArea = null;
        // 0-首页
        if (this.state.iCurrentMenu === 0) {
            const uiTransitWay = (
                <Menu>
                    {this.arrTransitWay.map((menu, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.TransitWaySwitch.bind(this, index)}>
                                <div className="webpage_dropdown_menu_item">{menu}</div>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiTransitWayDropdown = (
                <Dropdown overlay={uiTransitWay} trigger={['click']}>
                    <div className="transitway_dropdown_menu_button" onClick={e => e.preventDefault()}>
                        {this.arrTransitWay[this.state.iCurrentTransitWay]}<Icon type="caret-down" style={{ marginLeft: '12.5px' }} />
                    </div>
                </Dropdown>
            );
            uiFunctionArea = (
                <div className="web_center_home_page">
                    <div className="web_center_home_title web_center_home_title_xiyouji" >专业快递解决方案</div>
                    <div className="web_center_home_subtitle web_center_home_subtitle_xiyouji">安全·快速·高效·省心</div>
                    <div className="web_center_home_search_area">
                        {uiTransitWayDropdown}
                        <Input className="web_center_search_input" style={{ width: '290px', height: '40px' }} ref="order_search"
                            size="large" placeholder="请输入要查询的运单号" />
                        <div className="web_center_search_button web_center_search_button_xiyouji" onClick={this.OrderTracking}>查 询</div>
                    </div>
                </div>
            );
        }
        if (this.state.bLogin) {
            strUserName = this.objUserInfo.name;
            // 1-我要寄货
            if (this.state.iCurrentMenu === 1) {
                let strReceiver = "何顺明 ( " + this.objUserInfo.storage_code + " )";
                let strPhone = "13750335052";
                let strPostCode = "529000";
                let strAddress = "广东省江门市蓬江区周郡海滩围55号";
                let strCopyAllText = strReceiver + ",";
                strCopyAllText += strPhone + ",";
                let strInviteCode = "";
                if (this.objUserInfo.invite_code !== null && this.objUserInfo.invite_code !== "") {
                    strInviteCode = " " + this.objUserInfo.invite_code;
                }
                strCopyAllText += strAddress + strInviteCode + ",";
                strCopyAllText += strPostCode;

                let uiSubmitOrderDlg = (
                    <Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null} centered={true} width={"40%"}
                        onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                        okText="确定" cancelText="取消">
                        <div>
                            <div className="web_center_order_submit_dlg_tip">
                                提示：备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                        </div>
                            {this.arrAddOrders.map((item, index) => {

                                // 寄送状态过滤器
                                const menuDeliveryStatusFilter = (
                                    <Menu>
                                        {this.arrDeliveryStatusFilter.map((item, status) => {
                                            return (
                                                <Menu.Item key={index} onClick={this.SwitchDeliveryStatusFilter.bind(this, index, status)}>
                                                    {item}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                );

                                return (
                                    <div className="web_center_express_order_info_area">
                                        <div className="web_center_express_order_info_row">
                                            <div className="web_center_express_info_title">
                                                <Icon type="codepen" />
                                                {" 单号 : "}</div>
                                            <div className="web_center_express_info_contents">{item}</div>
                                        </div>
                                        <div className="web_center_express_order_info_row">
                                            <div className="web_center_express_info_title">
                                                <Icon type="rocket" />
                                                {" 发货 : "}</div>
                                            <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                <Button className="webpage_delivery_filter_dropdown_button">
                                                    {this.arrDeliveryStatusFilter[this.arrOrderDeliveryStatus[index]]}
                                                    <Icon type="caret-down" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                        <div className="web_center_express_order_info_row" >
                                            <div className="web_center_express_info_title">
                                                <Icon type="file-text" />
                                                {" 备注 : "}</div>
                                            <div className="web_center_express_info_contents">
                                                <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="web_center_express_order_info_row">
                                <div className="web_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                <div className="web_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                            </div>
                        </div>
                    </Modal>
                );
                uiFunctionArea = (
                    <div className="web_center_transit_function_area">
                        <div className="web_center_transit_function_vertical_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="shop" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />转运中心
                                </div>
                            <div className="web_center_transit_fucntion_row">
                                {"收件人：" + strReceiver}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strReceiver)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strReceiver} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">{"手机：" + strPhone}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPhone)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strPhone} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">
                                <div style={{ marginRight: '5px' }}>
                                    {"地址：" + strAddress + strInviteCode}</div>
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strAddress + strInviteCode)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strAddress + strInviteCode} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">{"邮编：" + strPostCode}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPostCode)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strPostCode} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            {/* <div className="web_center_transit_function_copy_all" onClick={this.CopyAddressInfo.bind(this, strCopyAllText)}>复 制 全 部</div> */}
                            <Clipboard data-clipboard-text={strCopyAllText} className="web_center_transit_function_copy_all"
                                style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                复 制 全 部
                                </Clipboard>
                            <div className="web_center_transit_function_tips">
                                <b>小提示：</b><br />
                            务必将收件人括号内的编码填写在快递单上！避免延误签收！
                        </div>
                        </div>
                        <div className="web_center_transit_function_horizontal_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="rocket" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />提交订单
                        </div>
                            <div className="web_center_transit_fucntion_row">
                                {"物流单号输入："}
                            </div>
                            <div className="web_center_express_order_input_area">
                                <TextArea size="large" autoSize={{ minRows: 5 }} allowClear={true} ref="order_input"
                                    placeholder="可以用回车分隔，一次输入多个单号" />
                            </div>
                            <div className="web_center_express_order_submit_button" onClick={this.AddNewOrder.bind(this, 0, null)}>确认添加</div>
                            <div className="web_center_exress_order_submit_tip_area">
                                <div className="web_center_exress_order_submit_tip_row">
                                    <b>温馨提示：</b>
                                </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    1. 批量输入快递单号后点击“确认添加”
                            </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    2. 对每笔订单进行备注，填写完成后“提交”
                            </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    3. 如果仍有疑问，请咨询
                                <div className="web_center_exress_order_submit_tip_link" /*onClick={this.ShowContactUsDlg.bind(this, true)}*/>在线客服</div>
                                </div>
                            </div>
                            {uiSubmitOrderDlg}
                        </div>
                        <div className="web_center_transit_function_vertical_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="home" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />提货地址
                            </div>
                            <div className="web_center_transit_fucntion_row">
                                {"联系人：Lekon 客服"}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strReceiver)}> 复 制 </div> */}
                            </div>
                            <div className="web_center_transit_fucntion_row">{"手机：778-952-0886"}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPhone)}> 复 制 </div> */}
                            </div>
                            <div className="web_center_transit_fucntion_row" style={{ border: 'none' }}>
                                <div style={{ marginRight: '5px' }}>
                                    {"地址：Unit 119, 7080 River Rd., Richmond, BC V6X1X5"}</div>
                            </div>
                            <div className="web_center_pickup_reminds">
                                {"提货时请提供收件人名或用户编码"}
                            </div>
                            {/* <div className="web_pickup_location_map" >
                                <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={this.state.objPickupLocation}
                                    center={this.state.objPickupLocation} containerStyle={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Marker position={this.state.objPickupLocation} />
                                </Map>
                            </div> */}
                        </div>
                    </div>
                );
            }
            // 2-订单记录
            if (this.state.iCurrentMenu === 2) {
                if (!this.state.bLoading) {
                    let uiOrderList = null;
                    let uiSubmitOrderDlg = null;
                    if (this.state.bSubmitOrderDlg)
                        uiSubmitOrderDlg = (<Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null} centered={true} width={"40%"}
                            onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                            okText="确定" cancelText="取消">
                            <div>
                                <div className="web_center_order_submit_dlg_tip">
                                    提示：备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                            </div>
                                {this.arrAddOrders.map((item, index) => {
                                    // 寄送状态过滤器
                                    const menuDeliveryStatusFilter = (
                                        <Menu>
                                            {this.arrDeliveryStatusFilter.map((item, status) => {
                                                return (
                                                    <Menu.Item key={index} onClick={this.SwitchDeliveryStatusFilter.bind(this, index, status)}>
                                                        {item}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    );
                                    return (
                                        <div className="web_center_express_order_info_area">
                                            <div className="web_center_express_order_info_row">
                                                <div className="web_center_express_info_title">
                                                    <Icon type="codepen" />
                                                    {" 单号 : "}</div>
                                                <div className="web_center_express_info_contents"> {item}</div>
                                            </div>
                                            <div className="web_center_express_order_info_row">
                                                <div className="web_center_express_info_title">
                                                    <Icon type="rocket" />
                                                    {" 发货 : "}</div>
                                                <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                    <Button className="webpage_delivery_filter_dropdown_button">
                                                        {this.arrDeliveryStatusFilter[this.arrOrderDeliveryStatus[index]]}
                                                        <Icon type="caret-down" />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <div className="web_center_express_order_info_row" >
                                                <div className="web_center_express_info_title">
                                                    <Icon type="file-text" />
                                                    {" 备注 : "}</div>
                                                <div className="web_center_express_info_contents">
                                                    <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index} defaultValue={this.objCurrentOrder.comments} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="web_center_express_order_info_row">
                                    <div className="web_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                    <div className="web_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                                </div>
                            </div>
                        </Modal>);
                    const uiOrderTypeMenu = (
                        <Menu>
                            {this.arrOrderStatusMenu.map((menu, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.OrderTypeFilterCLicked.bind(this, index)}>
                                        <div className="webpage_dropdown_menu_item">{menu}</div>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    let uiOrderTypeDropdown = (
                        <Dropdown overlay={uiOrderTypeMenu} trigger={['click']}>
                            <div className="webpage_dropdown_menu_button" onClick={e => e.preventDefault()}>
                                {this.arrOrderStatusMenu[this.state.iCurrentOrderStatus]}<Icon type="caret-down" style={{ marginLeft: '12.5px' }} />
                            </div>
                        </Dropdown>
                    );
                    if (this.arrOrderShowList.length <= 0) {
                        uiFunctionArea = (
                            <div className="web_center_transit_function_area">
                                <div className="web_center_order_list_area">
                                    <div className="web_center_transit_fucntion_title" style={{ height: '60px', marginBottom: '15px', padding: '10px' }}>
                                        <Icon type={"profile"} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />我的订单
                                        {uiOrderTypeDropdown}
                                    </div>
                                    <Empty description={"暂无该类型订单的记录，快去提交订单吧！"} className="web_center_empty" />
                                </div>
                            </div>
                        );
                    } else {
                        let arrActiveKey = [];
                        uiOrderList = (
                            <div>
                                <Collapse defaultActiveKey={arrActiveKey} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                    {this.arrOrderShowList.map((item, index) => {
                                        let uiPanel = null;
                                        uiPanel = this.GenerateParentPanelUI(item, index);
                                        return (uiPanel);
                                    })}
                                </Collapse>
                                { uiSubmitOrderDlg}
                            </div>

                        );

                        uiFunctionArea = (
                            <div className="web_center_transit_function_area">
                                <div className="web_center_order_list_area">
                                    <div className="web_center_transit_fucntion_title" style={{ height: '60px', marginBottom: '15px', padding: '10px' }}>
                                        <Icon type={"profile"} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />我的订单
                                        {uiOrderTypeDropdown}
                                    </div>
                                    {uiOrderList}
                                </div>
                            </div>
                        );
                    }
                } else {
                    uiFunctionArea = (
                        <div className="web_center_transit_function_area" style={{ width: '600px', height: '90%' }}>
                            <Spin size="large" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    );
                }
            }
            // 3-运费预估
            if (this.state.iCurrentMenu === 3) {

            }
        } else {
            strUserName = "立即登录/注册";
        }

        // let uiLogoutButton = null;
        // uiLogoutButton = (
        //     <div className="webpage_title_logout" onClick={this.LogOut}>退出登录</div>
        // );
        let uiUserName = null;
        if (this.state.bLogin) {
            uiUserName = (
                "用户：" + strUserName
                // <Tooltip placement="bottom" title={uiLogoutButton}>

                // </Tooltip>
            );
        } else {
            uiUserName = strUserName;
        }

        let uiTimeLineInfo = (
            <Timeline>
                <Timeline.Item>暂无物流信息</Timeline.Item>
            </Timeline>
        );
        if (this.arrExpressDetails !== undefined && this.arrExpressDetails !== null && this.arrExpressDetails !== '') {
            if (this.arrExpressDetails.length > 0) {
                uiTimeLineInfo = (
                    <Timeline style={{ marginLeft: '15px' }}>
                        { this.arrExpressDetails.map((item, index) => {
                            return (
                                <Timeline.Item key={index} >
                                    <div className="user_edit_info_row" style={{ minWidth: '50%', marginLeft: '15px', marginTop: '15px', marginBottom: '10px' }}>
                                        <div style={{ minWidth: '50%', marginLeft: '5px', fontSize: '15px' }}>
                                            {item.status + " - " + item.update_time}</div>
                                    </div>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                );
            }
        }
        let uiViewTrackingInfoDlg = null;
        if (this.state.bViewTrackingInfo)
            uiViewTrackingInfoDlg = (
                <Modal title={'物流信息'} width={600} visible={this.state.bViewTrackingInfo} maskClosable={true} centered footer={null} onCancel={this.CloseExpressDetail} >
                    {uiTimeLineInfo}
                </Modal>
            );

        return (
            <div className="login_page_web">
                <div className="login_page_web_title_area">
                    {/* <div className="login_page_web_title_logo_xiyouji" onClick={this.HomePage} /> */}
                    <div className="login_page_web_title_slogan_xiyouji" onClick={this.HomePage} />
                    <div className="webpage_title_menu_row">
                        {this.arrMenuButton.map((button, index) => {
                            let strMenuButtonStyle = "webpage_title_menu_button_xiyouji";
                            if (this.state.iCurrentMenu === index)
                                strMenuButtonStyle += " webpage_title_menu_button_select_xiyouji";
                            if (index === 0)
                                return (
                                    <div className={strMenuButtonStyle} style={{ width: '115px', minWidth: '115px' }}
                                        onClick={this.SwitchMenuButton.bind(this, index)}>
                                        <Icon type={button.icon} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />{button.name}</div>
                                );
                            else
                                return (
                                    <div className={strMenuButtonStyle} onClick={this.SwitchMenuButton.bind(this, index)}>
                                        <Icon type={button.icon} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />{button.name}</div>
                                );
                        })}

                    </div>
                    {/* <div className="webpage_title_user_avatar" onClick={this.JumpToUserCenter} /> */}
                    <div className="webpage_title_user_name webpage_title_user_name_xiyouji" onClick={this.JumpToUserCenter} >
                        {uiUserName}
                    </div>
                </div>
                <div className="login_page_web_center_area_xiyouji">
                    {/* <div className="web_center_area_bgp web_center_area_bgp_xiyouji web_center_area_bgp_info " /> */}
                    <div className="web_center_black_cover">
                        {uiFunctionArea}
                    </div>
                    {uiViewTrackingInfoDlg}
                </div>
                <div className="login_page_web_bottom_area">
                    <div>
                        <div className="login_page_web_bottom_copyright">
                            西邮寄物流
                        </div>
                        <div className="login_page_web_bottom_copyright">
                            电话：(+1) 604-259-9743
                        </div>
                        <div className="login_page_web_bottom_copyright">
                            地址：Unit 119 7080 River Road Richmond BC
                        </div>
                        <div className="login_page_web_bottom_copyright">
                            Copyright © 2018-2028 xiyouji.ca All Right Reseved
                        </div>
                    </div>
                    <div>
                        <div className="xiyouji_qrcode" />
                        <div className="xiyouji_qrcode_tip">
                            请添加客服微信，报备您的用户编码
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default XiYouJiWebsite;
// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
//     language: 'cn', region: 'CN'
// })(XiYouJiWebsite);
