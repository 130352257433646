import React, { Component } from "react";
import axios from 'axios';

import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import message from 'antd/es/message';

import "antd/dist/antd.css";
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

class UserInfoSN extends Component {
    constructor(props) {
        super();
        this.state = {
            bShowDlg: false
        };
    }

    ChangeWechat = () => {
        console.log("Change Wechat");
        this.setState({
            ...this.state,
            bShowDlg: true
        })
    }

    ConfirmWechat = () => {
        this.props.objUserInfo.wechat = this.refs.wechat.state.value;
        console.log("Confirm Wechat : ", this.props.objUserInfo);
        message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
        axios.post('/UpdateUserWechat', {
            id: this.props.objUserInfo.id,
            wechat: this.props.objUserInfo.wechat
        }).then(({ data }) => {
            if (data.status === 1) {
                message.success({ content: "微信号已更新", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bShowDlg: false
                });
            } else
                message.warning({ content: '更新失败，请重新尝试！', key: g_strMessageKey, duration: 2 });
        }).catch(function (error) { console.log(error); });
    }

    CancleWechat = () => {
        console.log("Cancle Wechat");
        this.setState({
            ...this.state,
            bShowDlg: false
        })
    }

    // 渲染完毕……
    componentDidMount() {

    }

    render() {

        let uiChangeWechatDlg = (
            <Modal title={'填写/修改 微信号'} visible={this.state.bShowDlg} onOk={this.ConfirmWechat.bind(this)} onCancel={this.CancleWechat}
                okText='确定' cancelText="取消">
                <div className="user_info_sooning_title" style={{ border: 'none' }}>
                    微信号：
                    <Input ref={"wechat"} defaultValue={this.props.objUserInfo.wechat}
                        style={{ width: '350px', maxWidth: '350px' }} />
                </div>
            </Modal>
        );

        let strWechat = "无";
        if (this.props.objUserInfo !== undefined && this.props.objUserInfo.wechat !== null && this.props.objUserInfo.wechat !== '')
            strWechat = this.props.objUserInfo.wechat;

        return (
            <div className="store_manage_contents" style={{ background: 'white', height: '100%' }}>
                <div className="user_info_sooning_title" >
                    我的信息
                </div>
                <div className="user_info_sooning_area">
                    <div className="user_info_sooning_row">
                        <div className="user_info_sooning_column">{"用户名：" + this.props.objUserInfo.name}</div>
                        <div className="user_info_sooning_column">
                            {"用户编号：" + this.props.objUserInfo.storage_code}
                            {/* <div className="user_info_sooning_copy_button">复制</div> */}
                        </div>
                    </div>
                    <div className="user_info_sooning_row">
                        <div className="user_info_sooning_column">{"电话：" + this.props.objUserInfo.phone}</div>
                        <div className="user_info_sooning_column">{"邮箱：" + this.props.objUserInfo.email}</div>
                    </div>
                    <div className="user_info_sooning_row">
                        <div className="user_info_sooning_column" style={{ marginRight: '50px', width: 'auto' }}>{"微信：" + strWechat}</div>
                        <div className="user_info_sooning_copy_button" style={{ marginLeft: '0px', marginTop: '22.5px' }}
                            onClick={this.ChangeWechat}> 修 改</div>
                    </div>
                </div>
                {uiChangeWechatDlg}
                <div className="user_info_sooning_title" >
                    仓库信息
                </div>
                <div className="user_info_sooning_area">
                    <div className="user_info_sooning_row">
                        <div className="user_info_sooning_column">{"空运："}</div>
                    </div>
                    <div className="user_info_sooning_address_area">
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px" }}>
                            {"收件人(Name)： "}
                            <div style={{ color: "darkorange", fontWeight: 'bold', marginLeft: '10px', marginRight: '15px' }}>{"何顺明"}</div>
                            {"  *在购物网站填写收货人时，请务必填写此用户编码"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"地址(Address)：	广东省江门市蓬江区周郡海滩围55号 SN "}
                            <div style={{ color: 'darkorange', fontWeight: 'bold', marginLeft: '2.5px' }}>{this.props.objUserInfo.storage_code}</div>
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"国家(Country)：	China"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"城市(City)：	江门市"}
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"州/省(State)：	广东省"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"邮编(Zip)：	529030"}
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"电话(Tel)：	17363421325"}
                        </div>
                    </div>
                    <div className="user_info_sooning_row">
                        <div className="user_info_sooning_column">{"海运："}</div>
                    </div>
                    <div className="user_info_sooning_address_area">
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px" }}>
                            {"收件人(Name)： "}
                            <div style={{ color: "darkorange", fontWeight: 'bold', marginLeft: '10px', marginRight: '15px' }}>{"何顺明"}</div>
                            {"  *在购物网站填写收货人时，请务必填写此用户编码"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"地址(Address)：	广东省江门市蓬江区周郡海滩围55号 SN77 "}
                            <div style={{ color: 'darkorange', fontWeight: 'bold', marginLeft: '2.5px' }}>{this.props.objUserInfo.storage_code}</div>
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"国家(Country)：	China"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"城市(City)：	江门市"}
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"州/省(State)：	广东省"}
                        </div>
                        <div className="user_info_sooning_row" style={{ fontWeight: 'normal', fontSize: "15px", paddingRight: '50px' }}>
                            {"邮编(Zip)：	529030"}
                            <div style={{ width: '10px', marginLeft: 'auto', marginRight: '10px' }} />
                            {"电话(Tel)：	17363421325"}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UserInfoSN;
