import React, { Component } from "react";

import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Empty from 'antd/es/empty';

// import { Menu, Dropdown, Icon, Empty } from 'antd'
import "../../../styles/ClientSide/UI_Module/recommends.css";
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

class Recommends extends Component {
    constructor(props) {
        super();
        this.state = {
            iCurrentMainCategoryIndex: 0
        };
        this.arrStoreList = [];
        this.arrStoreShow = [];
        if (props.arrRecommendStores !== undefined) {
            this.arrStoreList = props.arrRecommendStores;
            this.arrStoreShow = this.arrStoreList;
        }
        this.arrMainCategoryFilter = ['全部', '美食', '生活服务', '休闲娱乐', '周边游'];
        this.arrMainCategoryInfo = [];
        if (props.arrMainCategory !== undefined) {
            this.arrMainCategoryFilter = [];
            this.arrMainCategoryInfo = [];
            for (let i = 0; i < props.arrMainCategory.length; i++) {
                if (props.arrMainCategory[i].level === 1) {
                    this.arrMainCategoryInfo.push(props.arrMainCategory[i]);
                    this.arrMainCategoryFilter.push(props.arrMainCategory[i].name);
                }
            }
            this.arrStoreShow = [];
            for (let i = 0; i < this.arrStoreList.length; i++) {
                if (this.arrStoreList[i].main_category ===
                    this.arrMainCategoryInfo[this.state.iCurrentMainCategoryIndex].id) {
                    this.arrStoreShow.push(this.arrStoreList[i]);
                }
            }
        }
        this.iStoreID = 0;
    }
    // 点击推荐区域商家缩略图
    StoreThumbClicked = (iStoreID, iIndex) => {
        this.iStoreID = iStoreID;
        // DataStatistic
        UserActiveUpdateBack(1, { active: "点击推荐区域缩略图", store: iStoreID, order: iIndex }, this.JumpToStore);
    }
    // 跳转到目标商家
    JumpToStore = () => {
        // window.location.href = '/webapp/store/' + iStoreID + "?tab=2&from=" + this.props.iFromPage;
        window.location.href = '/webapp/store/' + this.iStoreID + "?tab=2";
    }
    // 点击了分类过滤器
    // MainCategoryFilterClicked = (iMainCategoryIndex, item) => {
    //     this.RefreshRecommendList(iMainCategoryIndex);
    // }
    // 刷新附近商家列表
    RefreshRecommendsList = (iMainCategoryIndex, strName) => {
        if (this.props.iCurrentPage === 0) {
            // DataStatistic
            UserActiveUpdate(2, {
                active: "点击推荐区域分类过滤器",
                category_id: this.arrMainCategoryInfo[iMainCategoryIndex].id,
                category_index: iMainCategoryIndex,
                category_name: strName
            });
        }
        this.arrStoreShow = [];
        for (let i = 0; i < this.arrStoreList.length; i++) {
            if (this.arrStoreList[i].main_category === this.arrMainCategoryInfo[iMainCategoryIndex].id) {
                this.arrStoreShow.push(this.arrStoreList[i]);
            }
        }
        this.setState({
            ...this.state,
            iCurrentMainCategoryIndex: iMainCategoryIndex
        });
    }
    // 渲染完毕……
    componentDidMount() {
        let iMainCategoryIndex = Math.floor(Math.random() * this.arrMainCategoryInfo.length);
        // Mouthing推荐模块被用于：0-首页, 1-分类页, 3-用户中心的收藏
        // 用于测试版演示，首页固定显示编号3主分类：美食
        if (this.props.iCurrentPage === 0)
            iMainCategoryIndex = 3;
        let strName = this.arrMainCategoryInfo[iMainCategoryIndex].name;
        this.RefreshRecommendsList(iMainCategoryIndex, strName);
    }

    render() {

        let uiFilter = (<div />);
        if (this.props.bShowFilter) {
            const uiCategoryFilter = (
                <Menu className="recommends_filter_menu">
                    {this.arrMainCategoryInfo.map((item, index) => {
                        return (
                            <Menu.Item onClick={this.RefreshRecommendsList.bind(this, index, item.name)} key={index}>
                                {item.name}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiFilter = (
                <Dropdown className="recommends_filter"
                    overlay={uiCategoryFilter} trigger={['click']} >
                    <div> {this.arrMainCategoryFilter[this.state.iCurrentMainCategoryIndex]}
                        <Icon className="recommends_dropdown_icon" type="caret-down" />
                    </div>
                </Dropdown>
            );
        }

        const uiTitle = (
            <div className="recommends_title">
                Mouthing优惠 {uiFilter}
            </div>
        );
        let strRecommendAreaWidth = "ego_recommend_contents_layout";
        if (this.arrStoreShow.length >= 3)
            strRecommendAreaWidth += " ego_recommend_width_" + this.arrStoreShow.length.toString();

        // 商家列表本体
        let uiContents = (
            <Empty className="ego_recommend_empty" image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="" />
        );
        if (this.arrStoreShow.length > 0) {
            uiContents = (
                <div className="recommends_scroll_bar_hide_area">
                    <div className="recommends_main_contents_scroll">
                        <div className={strRecommendAreaWidth}  >
                            {this.arrStoreShow.map((item, index) => {
                                let uiThumb = (
                                    <div key={index}>
                                        <div className="ego_recommend_item"
                                            onClick={this.StoreThumbClicked.bind(this, item.id, index)} />
                                        <div className="ego_recommend_item_name">
                                            {item.store_name}</div>
                                    </div>
                                );
                                if (item.thumb !== undefined) {
                                    if (item.thumb !== null && item.thumb !== "") {
                                        uiThumb = (
                                            <div key={index}>
                                                <img className="ego_recommend_item"
                                                    src={item.thumb} alt=''
                                                    onClick={this.StoreThumbClicked.bind(this, item.id, index)} />
                                                <div className="ego_recommend_item_name">
                                                    {item.store_name}</div>
                                            </div>
                                        );
                                    }
                                }
                                return uiThumb;
                            })}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="recommends_area">
                {uiTitle}{uiContents}
            </div>
        );
    }
}
export default Recommends;
