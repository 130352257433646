import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Radio from 'antd/es/radio';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Popover from 'antd/es/popover';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';

// import { Radio, Modal, Input, Popover, Spin, message, Select, Tooltip, Icon } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CardSection from './UI_Module/CardSection.js';
import { GetLatLongBasedOnAddress } from "../../functions/PositionProcess.js"
// import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { GetUserLocation } from "../../functions/PositionProcess.js"
import { GetLatLongDistance } from "../../functions/PositionProcess.js"

import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { GetItemsID } from "../../functions/DataStatistic.js"

// import moment from 'moment';
import '../../styles/ClientSide/order_details_page.css';

const { TextArea } = Input;

const mapStyles = { width: '96vw', height: '54vw' };

// const stripePromise = loadStripe("pk_test_Ck37fMoMJvFwBDTkjTPltHz400q1ObN1bc");
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const g_strMessageKey = 'updating';

const { Option } = Select;

class OrderDetails extends Component {
    constructor(props) {
        super();

        // 用户名→联系人姓名，电话→联系方式转化
        let strUserName = "";
        let strPhoneCode = "+1";
        if (props.objUserInfo.name) {
            if (props.objUserInfo.name !== "" && props.objUserInfo.name !== null)
                strUserName = props.objUserInfo.name;
        }
        if (props.objUserInfo.phone) {
            if (props.objUserInfo.phone !== "" && props.objUserInfo.phone !== null) {
                strPhoneCode = props.objUserInfo.phone.split(') ')[0].split('(')[1];
            }
        }

        let strParams = window.location.search
        strParams = strParams.substring(strParams.indexOf('?') + 1);

        let bToDoor = false;
        if (strParams.indexOf('order') !== -1) {
            let iOrderDetails = parseInt(strParams.split('&')[1].split('=')[1], 10);
            if (iOrderDetails === 1)
                bToDoor = true;
        }

        this.state = {
            objMapPosition: props.objStorePos,
            strAddress: "",
            bSelectPaymentDlg: false,
            iPaymentMethod: 0,
            iPaymentCard: -1,
            bAddNewCard: false,
            bRemoveCardConfirm: false,
            bLoading: false,
            bPaymentResultDlg: false,
            iPaymentResult: 0,
            bToDoor: bToDoor,
            bChangeContactInfoDlg: false,
            iContactInfo: 0,
            strPhoneCode: strPhoneCode,
            bChangeCommentsDlg: false,
            bLocating: false,
            strCouponMethod: "coupon",
            iCurrentCoupon: -1
        };
        this.bFirstOpen = true;
        this.iConfirmedPaymentMethod = 0;
        this.arrPaymentMethod = ["选择支付方式", "信用卡/借记卡", "微信", "支付宝"];
        this.arrCards = [];
        this.bNewCardSubmitting = false;
        this.strCardHolder = "";
        this.strCurrentLast4 = "";
        this.strCurrentCardBrand = "";
        this.strOrderID = "";  // 订单号, Stripe提供的pid
        this.strNewName = strUserName;
        this.strNewPhone = props.objUserInfo.phone;
        this.strComments = "";
        this.strCurrentLocation = "";
        this.objCurrentPosition = { lat: 0, lng: 0 };
        this.fDistance = 0.0;
        this.fPriceOff = 0.0;
    }
    // 联系方式信息更新
    ContactInfoUpdate = () => {
        // 用户名→联系人姓名，电话→联系方式转化
        let strUserName = "";
        if (this.props.objUserInfo.name) {
            if (this.props.objUserInfo.name !== "" && this.props.objUserInfo.name !== null)
                strUserName = this.props.objUserInfo.name;
        }
        this.strNewName = strUserName;
        this.strNewPhone = this.props.objUserInfo.phone;
    }
    // 取货方式更换
    ItemPickupOptionChange = (event) => {
        if (event.target.value === "to_door") {
            this.GetUserLocation();
            this.setState({
                ...this.state,
                bToDoor: true
            });
        } else {
            console.log("ItemPickupOptionChange : ");
            console.log(this.props.objStorePos);
            this.setState({
                ...this.state,
                objMapPosition: this.props.objStorePos,
                bToDoor: false
            });
        }
    }
    // 优惠方式更换
    CouponOptionChange = (event) => {
        this.setState({
            ...this.state,
            strCouponMethod: event.target.value
        })
    }
    // 优惠券选择切换
    SelectCoupon = e => {
        console.log('SelectCoupon : ', e.target.value);

        if (e.target.value === -1) {
            this.fPriceOff = 0.0;
        }

        this.setState({
            iCurrentCoupon: e.target.value,
        });
    };
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付方式切换
    PaymentMethodChange = (e) => {
        this.setState({
            ...this.state,
            iPaymentMethod: e.target.value,
        });
    }
    // 确认支付方式
    PaymentMethodConfirm = (bConfirm) => {
        // 获取当前选中的支付方式(未确认的)
        let iPaymentMethod = this.state.iPaymentMethod;
        // 获取当前选中的卡片(未确认的)
        let iCurrentCard = this.state.iPaymentCard;
        if (bConfirm) {
            if (iPaymentMethod !== 1) {
                if (iPaymentMethod !== 0)
                    message.info("微信/支付宝支付还未开通，请选择别的支付方式！");
                else
                    message.warning("请选择一种有效的支付方式！");
                return;
            }
            if (this.state.iPaymentCard < 0) {
                message.warning("还未添加任何卡片！");
                return;
            }
            this.iConfirmedPaymentMethod = this.state.iPaymentMethod;
            iCurrentCard = this.state.iPaymentCard;
            let objCardInfo = { pm_id: this.arrCards[iCurrentCard].id }
            // 更新默认支付方式为用户最新确认使用的卡片
            axios.post('/UpdateDefaultPaymentMethod', {
                objCardInfo: objCardInfo, objUserInfo: this.props.objUserInfo
            }).then(({ data }) => {
                for (let i = 0; i < this.arrCards.length; i++) {
                    if (this.arrCards[i].id === objCardInfo.pm_id) {
                        this.strCurrentLast4 = this.arrCards[i].card.last4;
                        this.strCurrentCardBrand = this.arrCards[i].card.brand;
                        break;
                    }
                }
                this.bNewCardSubmitting = false;
                this.setState({
                    ...this.state,
                    bSelectPaymentDlg: false,
                    iPaymentMethod: iPaymentMethod,
                    iPaymentCard: iCurrentCard,
                    bAddNewCard: false,
                    bRemoveCardConfirm: false,
                    bLoading: false
                });
            }).catch(function (error) { console.log(error); });
            // 更新用户表中的默认支付方式ID
            this.props.objUserInfo.payment_id = iCurrentCard;
            console.log("user default payment update: ");
            console.log(this.props.objUserInfo);
            axios.post('/UpdateUserPaymentMethod', { objUserInfo: this.props.objUserInfo })
                .then(({ data }) => {
                    if (data.status !== 1) {
                        message.warning("用户支付信息更新失败,请重新尝试!");
                    }
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        } else {
            // 取消更改支付方式,则需要还原之前的支付方式以及卡片
            iPaymentMethod = this.iConfirmedPaymentMethod;
            console.log("PaymentMethodConfirm >>> this.iConfirmedPaymentMethod = " + this.iConfirmedPaymentMethod);
            if (this.props.objUserInfo.payment_id !== undefined) {
                if (this.props.objUserInfo.payment_id !== null) {
                    iCurrentCard = this.props.objUserInfo.payment_id;
                    console.log("取消了选择，当前的卡片恢复为之前的记录： " + iCurrentCard);
                } else {
                    iCurrentCard = -1;
                }
            } else {
                iCurrentCard = -1;
            }
            if (iCurrentCard >= 0) {
                this.strCurrentLast4 = this.arrCards[iCurrentCard].card.last4;
                this.strCurrentCardBrand = this.arrCards[iCurrentCard].card.brand;
            }
            this.bNewCardSubmitting = false;
            this.setState({
                ...this.state,
                bSelectPaymentDlg: false,
                iPaymentMethod: iPaymentMethod,
                iPaymentCard: iCurrentCard,
                bAddNewCard: false,
                bRemoveCardConfirm: false
            });
        }
    }
    // Card Hold Input Change
    CardHolderInputChange = (e) => {
        this.strCardHolder = e.target.value;
    }
    // Get Customer's Exist Cards List
    GetCardList = () => {
        // 当该用户的支付方式不为空，且是使用卡片支付时，采取调取卡片列表信息
        if (this.props.objUserInfo === -1) {
            return;
            // 获取用户信息，判断收藏状态
            // let strUserCode = cookie.load('wm_user_code');
            // if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            //    this.props.
            // }
        }
        if (this.props.objUserInfo.payment_id === null) {
            return;
        }
        if (this.props.objUserInfo.payment_id < 0) {
            return;
        }
        console.log("Getting Card List ...");
        console.log(this.props.objUserInfo);
        this.setState({
            ...this.state,
            bAddNewCard: false,
            bRemoveCardConfirm: false,
            bLoading: true
        });
        axios('/ListCustomerCards', {
            params: this.props.objUserInfo
        }).then(({ data }) => {
            console.log("List Customer Cards Succeed ...");
            console.log(data);
            this.arrCards = data.confirm;
            this.bNewCardSubmitting = false;
            let iPaymentCard = 0;
            if (this.arrCards.length === 0) {
                this.props.objUserInfo.payment_id = null;
                this.iConfirmedPaymentMethod = 0;
                iPaymentCard = -1;
            }
            // 更新用户的支付信息
            axios.post('/UpdateUserPaymentMethod', { objUserInfo: this.props.objUserInfo })
                .then(({ data }) => {
                    if (data.status !== 1) {
                        message.warning("用户支付信息更新失败,请重新尝试!");
                    }
                }).catch(function (error) { console.log(error); });
            if (this.bFirstOpen) {
                this.bFirstOpen = false;
                this.GetCustomerDefaultPaymentMethod();
            } else {
                this.setState({
                    ...this.state,
                    iPaymentMethod: 1,
                    iPaymentCard: iPaymentCard,
                    bAddNewCard: false,
                    bRemoveCardConfirm: false,
                    bLoading: false
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 切换用于支付的卡片
    PaymentCardChange = (event) => {
        this.setState({
            ...this.state,
            iPaymentCard: event.target.value
        });
    }
    // 显示添加新卡的表单
    AddNewCardFormShow = (bShow) => {
        this.setState({
            ...this.state,
            bAddNewCard: bShow
        });
    }
    // 解绑（删除）卡片
    RemoveCardFromCustomer = () => {
        this.setState({
            ...this.state,
            bRemoveCardConfirm: false
        });
        console.log("RemoveCardFromCustomer: ", this.state.iPaymentCard);
        message.loading({ content: '正在删除卡片……', key: g_strMessageKey });
        axios.post('/RemoveCardFromCustomer', {
            pm_id: this.arrCards[this.state.iPaymentCard].id
        }).then(({ data }) => {
            console.log(data);
            message.info({ content: "卡片删除成功！", key: g_strMessageKey, duration: 2 });
            // 如果当前删除的卡片是目前的默认支付卡片，则当前选中（还未确认）的以及用户默认支付的更改为第1张卡片，无论是否点击确认按钮
            if (this.state.iPaymentCard === this.props.objUserInfo.payment_id) {
                this.props.objUserInfo.payment_id = 0;
            } else {
                // 如果当前删除的卡片是在默认卡片的前面
                if (this.state.iPaymentCard < this.props.objUserInfo.payment_id) {
                    this.props.objUserInfo.payment_id--;
                }
            }
            console.log("RemoveCardFromCustomer >>> payment_id reset: " + this.props.objUserInfo.payment_id);
            this.GetCardList();
        }).catch(function (error) { console.log(error); });
    }
    // 显示删除卡片提示框
    ShowRemoveReminder = (bShow) => {
        console.log("ShowRemoveReminder >>>" + this.state.iPaymentCard);
        console.log("ShowRemoveReminder >>>" + this.props.objUserInfo.payment_id);
        if (this.state.iPaymentCard === this.props.objUserInfo.payment_id) {
            console.log("ShowRemoveReminder >>> removing current card");
        }
        console.log("ShowRemoveReminder: " + bShow);
        this.setState({
            ...this.state,
            bRemoveCardConfirm: bShow
        });
    }
    // 获取用户当前的支付方式（如果是卡片，获取卡片相应信息）
    GetCustomerDefaultPaymentMethod = () => {
        axios('/GetUserPaymentMethod', {
            params: { code: this.props.objUserInfo.code }
        }).then(({ data }) => {
            let iCardID = 0;
            if (data[0].payment_id !== null) {
                // 卡片
                if (data[0].payment_id >= 0) {
                    iCardID = data[0].payment_id;
                    this.iConfirmedPaymentMethod = 1;
                    this.strCurrentCardBrand = this.arrCards[iCardID].card.brand;
                    this.strCurrentLast4 = this.arrCards[iCardID].card.last4;
                }
            }
            this.setState({
                ...this.state,
                iPaymentMethod: 1,
                iPaymentCard: iCardID,
                bAddNewCard: false,
                bRemoveCardConfirm: false,
                bLoading: false
            });
        }).catch(function (error) { console.log(error); });

    }
    // 确认订单并进行支付
    OrderConfirmAndPay = () => {
        if (this.iConfirmedPaymentMethod === 0) {
            message.warning("请选择一种支付方式！");
            return;
        }
        this.setState({
            ...this.state,
            bPaymentResultDlg: true,
            iPaymentResult: 0
        });
        // DataStatistic
        let arrItems = GetItemsID(this.props.objCart);
        UserActiveUpdate(17, { active: "下单了", arrItems: arrItems });

        // console.log("OrderConfrimAndPay");
        // console.log(this.props.objCart);
        // return;

        axios.post('/PlaceOrderAndPay', {
            objUserInfo: this.props.objUserInfo,
            objCardInfo: this.arrCards[this.state.iPaymentCard],
            objCartInfo: this.props.objCart,
            objStoreInfo: this.props.objStoreInfo,
            fPriceOff: this.fPriceOff
        }).then(({ data }) => {
            // 支付成功
            if (data.message === "succeed" && data.pid !== "") {
                // DataStatistic
                UserActiveUpdate(18, { active: "下单成功", arrItems: arrItems });
                let objOrderItems = { total_num: this.props.objCart.iItemTotalNum, items: [] };
                for (let i = 0; i < this.props.objCart.arrBudgetItems.length; i++) {
                    let item = { id: 0, num: 0 };
                    item.id = this.props.objCart.arrBudgetItems[i].item.id;
                    item.num = this.props.objCart.arrBudgetItems[i].num;
                    objOrderItems.items.push(item);
                }
                // PST+GST & 手续费计算
                let fTotalPrice = this.props.objCart.fItemTotalPrice - this.fPriceOff;
                let fGSTFee = fTotalPrice * 0.12;
                let iFinalPrice = (fTotalPrice + fGSTFee + 0.3) / (1 - (0.029 + 0.006));
                iFinalPrice = iFinalPrice.toFixed(2);
                iFinalPrice = parseInt(iFinalPrice * 100, 10);
                let iTotalPrice = parseInt(this.props.objCart.fItemTotalPrice.toFixed(2) * 100, 10);
                let iStripeFee = iFinalPrice - parseInt(fTotalPrice.toFixed(2) * 100, 10) - parseInt(fGSTFee.toFixed(2) * 100, 10);
                let objOrderInfo = {
                    pid: data.pid,
                    status: 1,
                    final_price: iFinalPrice,
                    stripe_fee: iStripeFee,
                    total_price: iTotalPrice,
                    price_off: parseInt(this.fPriceOff.toFixed(2) * 100, 10),
                    items: JSON.stringify(objOrderItems),
                    store_id: this.props.objStoreInfo.id,
                    store_name: this.props.objStoreInfo.store_name,
                    user_code: this.props.objUserInfo.code,
                    client_order_time: new Date(),
                    card_info: this.arrCards[this.state.iPaymentCard].card.brand + "," + this.arrCards[this.state.iPaymentCard].card.last4,
                    contact: this.strNewName,
                    phone: this.strNewPhone,
                    comments: this.strComments,
                    viewed: 0
                };
                // 订单的入库记录
                axios.post('/RecordOrder', {
                    objOrderInfo: objOrderInfo, objUserInfo: this.props.objUserInfo
                }).then(({ data }) => {
                    if (data.oid === -1) {
                        // 订单在mouthing数据库记录异常
                        message.warning(data.message);
                        this.setState({
                            ...this.state,
                            bPaymentResultDlg: true,
                            iPaymentResult: -1
                        });
                    } else {
                        // 订单支付记录成功
                        // 清空购物车
                        let objCart = {
                            arrBudgetItems: [], iItemTotalNum: 0, fItemTotalPrice: 0.0
                        };
                        cookie.save('wm_user_cart', objCart, { path: '/' });

                        this.strOrderID = data.oid;
                        this.setState({
                            ...this.state,
                            bPaymentResultDlg: true,
                            iPaymentResult: 1
                        });
                    }
                }).catch(function (error) { console.log(error); });
                // 优惠券使用的入库记录
                if (this.state.iCurrentCoupon >= 0) {
                    axios.post('/TicketGetAndUseRecord', {
                        ticket_id: this.props.arrTicketsData[this.state.iCurrentCoupon].id, objUserInfo: this.props.objUserInfo
                    }).then(({ data }) => {
                        console.log(data);
                    }).catch(function (error) { console.log(error); });
                }
            } else {
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: -1
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 订单支付完成后的操作
    AfterPayment = (bStay) => {
        if (!bStay) {
            window.location.href = '/webapp/user?tab=3&store=' + this.props.objStoreInfo.id;
        } else {
            cookie.save('wm_user_cart', "", { path: '/' });
            window.location.href = '/webapp/store/' + this.props.objStoreInfo.id + "?tab=0";
            // this.setState({
            //     ...this.state,
            //     bPaymentResultDlg: false
            // });
        }
    }
    // 选择取货/送货时间
    TimeSelectorChange = (time, timeString) => {
        // console.log(time, timeString);
    }
    // 选择地址
    SelectAddress = (iOrder) => {
        console.log("Select Address ...");
        window.location.href = '/webapp/location?store=' + this.props.objStoreInfo.id + "&order=" + iOrder;
    }
    // 更改联系方式
    ChangeContactInfo = (iContactInfo) => {
        if (iContactInfo === 0 && this.refs.user_info_input)
            this.refs.user_info_input.state.value = this.strNewName;
        let strPhoneCode = "";
        if (iContactInfo === 1 && this.refs.user_info_input) {
            if (this.strNewPhone !== "" && this.strNewPhone !== null) {
                this.refs.user_info_input.state.value = this.strNewPhone.split(') ')[1];
                strPhoneCode = this.strNewPhone.split(') ')[0].split('(')[1];
            } else {
                this.refs.user_info_input.state.value = "";
                strPhoneCode = "+1";
            }
        } else {
            strPhoneCode = this.state.strPhoneCode;
        }
        this.setState({
            ...this.state,
            strPhoneCode: strPhoneCode,
            bChangeContactInfoDlg: true,
            iContactInfo: iContactInfo
        });
    }
    // 确认/取消修改联系方式
    ConfirmCancelContactInfo = (bConfirm) => {
        if (bConfirm) {
            if (this.state.iContactInfo === 0)
                this.strNewName = this.refs.user_info_input.state.value;
            if (this.state.iContactInfo === 1) {
                this.strNewPhone = "(" + this.state.strPhoneCode + ") " + this.refs.user_info_input.state.value;
            }
            let objOrderInfo = {
                name: this.strNewName,
                phone: this.strNewPhone,
                comments: this.strComments
            };
            cookie.save('wm_order_info', objOrderInfo, { path: '/' });
        }
        this.setState({
            ...this.state,
            bChangeContactInfoDlg: false
        });
    }
    // 电话区号选择
    PhoneCodeSelector = e => {
        this.setState({
            ...this.state,
            strPhoneCode: e,
        });
    }
    // 检查地址信息
    GetUserLocation = () => {
        console.log("GetUserLocation : ");
        let objPosition = { lat: "", lng: "" };
        let strAddress = "";
        let strUserLocation = cookie.load('wm_user_location');
        if (strUserLocation !== undefined) {
            if (strUserLocation !== null && strUserLocation.trim() !== "") {
                objPosition = {
                    lat: strUserLocation.split(',')[1],
                    lng: strUserLocation.split(',')[2]
                }
                strAddress = strUserLocation.split(',')[3];
                this.strCurrentLocation = strAddress;
                this.objCurrentPosition = objPosition;
                this.setState({
                    ...this.state,
                    objMapPosition: objPosition,
                    strAddress: strAddress,
                    bToDoor: true
                });
            } else {
                if (this.props.objUserInfo.address) {
                    if (this.props.objUserInfo.address !== null || this.props.objUserInfo.address !== "") {
                        strAddress = this.props.objUserInfo.address;
                        GetLatLongBasedOnAddress(0, strAddress, this.AfterGetUserLocation);
                    }
                }
            }
        } else {
            if (this.props.objUserInfo.address) {
                if (this.props.objUserInfo.address !== null || this.props.objUserInfo.address !== "") {
                    strAddress = this.props.objUserInfo.address;
                    GetLatLongBasedOnAddress(0, strAddress, this.AfterGetUserLocation);
                }
            }
        }
    }
    // 获取用户自定义地址后
    AfterGetUserLocation = (city, address, position) => {
        console.log("AfterGetUserLocation : ");
        console.log(address, position);
        console.log("this.props.objUserInfo.address = ");
        console.log(address);
        this.strCurrentLocation = address;
        this.objCurrentPosition = position;
        this.setState({
            ...this.state,
            objMapPosition: position,
            strAddress: address,
            bToDoor: true
        });
    }
    // 更改备注
    ChangeComments = () => {
        console.log("ChangeComments : ");
        if (this.refs.comments_input)
            this.refs.comments_input.state.value = this.strComments;
        this.setState({
            ...this.state,
            bChangeCommentsDlg: true
        });
    }
    // 确认/取消填写备注
    ConfirmComments = (bConfirm) => {
        if (bConfirm) {
            this.strComments = this.refs.comments_input.state.value;
            let objOrderInfo = {
                name: this.strNewName,
                phone: this.strNewPhone,
                comments: this.strComments
            };
            cookie.save('wm_order_info', objOrderInfo, { path: '/' });
        }
        this.setState({
            ...this.state,
            bChangeCommentsDlg: false
        });
    }
    // 从Cookie中获取联系姓名/联系电话/备注等订单详情信息
    GetOrderOnlyInfo = () => {
        console.log("GetOrderOnlyInfo");
        let objOrderInfo = cookie.load('wm_order_info');
        if (objOrderInfo) {
            if (objOrderInfo !== null) {
                if (objOrderInfo !== "") {
                    this.strNewName = objOrderInfo.name;
                    this.strNewPhone = objOrderInfo.phone;
                    this.strComments = objOrderInfo.comments;
                } else {
                    cookie.save('wm_order_info', "", { path: '/' });
                }
            } else {
                cookie.save('wm_order_info', "", { path: '/' });
            }
        } else {
            cookie.save('wm_order_info', "", { path: '/' });
        }
        console.log(objOrderInfo);
    }
    // 重新定位按钮响应
    GetCurrentLocation = () => {
        // 加载状态
        if (this.state.bLocating)
            return;
        this.setState({
            ...this.state,
            bLocating: true
        });
        // 是否需要定位
        let strUserLocation = cookie.load('wm_user_location');
        if (strUserLocation !== undefined) {
            if (strUserLocation !== null && strUserLocation.trim() !== "") {
                let objPosition = {
                    lat: strUserLocation.split(',')[1],
                    lng: strUserLocation.split(',')[2]
                }
                let strAddress = strUserLocation.split(',')[3];
                this.AfterGetCurrentLocation("", strAddress, objPosition);
            } else {
                if (this.props.objUserInfo.address) {
                    if (this.props.objUserInfo.address !== null || this.props.objUserInfo.address !== "") {
                        GetLatLongBasedOnAddress(0, this.props.objUserInfo.address, this.AfterGetCurrentLocation);
                    }
                } else {
                    GetUserLocation(this.AfterGetCurrentLocation);
                }
            }
        } else {
            if (this.props.objUserInfo.address) {
                if (this.props.objUserInfo.address !== null || this.props.objUserInfo.address !== "") {
                    GetLatLongBasedOnAddress(0, this.props.objUserInfo.address, this.AfterGetCurrentLocation);
                }
            } else {
                GetUserLocation(this.AfterGetCurrentLocation);
            }
        }

    }
    // 获得了返回的地址
    AfterGetCurrentLocation = (city, address, position) => {
        console.log("OrderDetails >>> AfterGetCurrentLocation : ");
        console.log(city, address, position);

        console.log("store position = ");
        console.log(this.props.objStorePos);

        this.strCurrentLocation = address;
        this.objCurrentPosition = position;
        this.setState({
            ...this.state,
            bLocating: false,
            bToDoor: false
        });
    }
    // 优惠券使用状态检查
    TicketStatusCheck = (iTicket) => {
        let iUsed = -1;
        if (this.props.arrTicketsStatus) {
            if (this.props.arrTicketsStatus.length > 0) {
                for (let i = 0; i < this.props.arrTicketsStatus.length; i++) {
                    if (this.props.arrTicketsStatus[i].ticket_id === iTicket) {
                        // console.log("TicketStatusCheck >>> iTicket = " + iTicket + ", status = " + this.props.arrTicketsStatus[i].status);
                        iUsed = 0;
                        if (this.props.arrTicketsStatus[i].status === 1)
                            iUsed = 1;
                        break;
                    }
                }
            }
        }
        // console.log("TicketStatusCheck >>> iTicket = " + iTicket + ", used = " + iUsed);
        return iUsed;
    }

    componentWillReceiveProps(nextProps) {
        // console.log("componentWillReceiveProps");
        // console.log(nextProps);

        this.fPriceOff = 0.0;
        this.setState({
            ...this.state,
            iCurrentCoupon: -1
        });
    }

    componentDidMount() {
        this.GetOrderOnlyInfo();
        this.GetCardList();
        if (this.state.bToDoor)
            this.GetUserLocation();
        else
            this.GetCurrentLocation();
    }

    render() {

        // 正在加载中控件……
        let uiLoading;
        if (this.state.bLoading) {
            uiLoading = (
                <div className="whole_page_loading_cover">
                    <Spin tip="处理中……" size="large" />
                </div>
            );
        } else {
            uiLoading = (
                <div className="whole_page_loading_cover_hide" />
            );
        }
        // 用户头像
        let uiAvatar = (<div className="order_details_icon user_icon" />);
        // if (this.props.objUserInfo !== -1 && this.props.objUserInfo.avatar !== null) {
        //     uiAvatar = (
        //         <img className="order_details_icon" src={this.props.objUserInfo.avatar} alt="avatar" />
        //     );
        // }
        // 商家Logo
        let uiStoreLogo = (<div className="order_details_icon store_icon" />);
        // if (this.props.objStoreInfo.logo.trim() !== "") {
        //     uiStoreLogo = (
        //         <img className="order_details_icon" src={this.props.objStoreInfo.logo} alt="logo" />
        //     );
        // }
        // 订单中的物品列表
        let uiItems = <div />;
        if (this.props.objCart.arrBudgetItems.length > 0) {
            uiItems = (
                <div className="order_details_items">
                    {this.props.objCart.arrBudgetItems.map((item, index) => {
                        return (
                            <div className="order_details_info_row" key={index}>
                                <img className="order_details_icon"
                                    src={item.item.logo} alt="" />
                                <div className="store_budget_details_item_name">
                                    <div className="order_details_itme_name_font">{item.item.name}</div>
                                    <div className="store_item_options">{item.item.options}</div>
                                </div>
                                <div className="store_item_add_area">
                                    <div className="store_item_add_num order_details_main_font order_details_num">
                                        {" x " + item.num}
                                    </div>
                                </div>
                                <div className="order_details_main_font order_details_price">
                                    <div className="order_details_main_font order_details_orange_color">
                                        {" $ " + item.item.price.toString()}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
        // 支付方式的具体信息确认区域
        let uiPaymentDetails = (<div>该功能即将上线，敬请期待</div>);
        if (this.state.iPaymentMethod === 0) {
            uiPaymentDetails = (<div>请选择一种支付方式</div>);
        }
        // 信用卡/借记卡 支付方式的信息确认区域
        if (this.state.iPaymentMethod === 1) {
            // 添加新卡片的表单控件
            if (this.state.bAddNewCard) {
                const AddNewCardForm = () => {
                    const stripe = useStripe();
                    const elements = useElements();
                    // Handle the form submit
                    const handleSubmit = async (event) => {
                        event.preventDefault();
                        if (this.bNewCardSubmitting)
                            return;
                        this.bNewCardSubmitting = true;
                        let card_object = {};
                        if (this.strCardHolder.trim() === "") {
                            message.warning("请填写持卡人姓名！");
                            return;
                        } else {
                            card_object = {
                                type: 'card',
                                card: elements.getElement(CardElement),
                                billing_details: { name: this.strCardHolder }
                            };
                        }
                        message.loading({ content: '正在提交卡片信息……', key: g_strMessageKey });
                        const { error, paymentMethod } = await stripe.createPaymentMethod(card_object);
                        if (!error) {
                            const { id } = paymentMethod;
                            let objCardInfo = { pm_id: id, card_holder: this.strCardHolder };
                            try {
                                const { data } = await axios.post("/AddCardToCustomer",
                                    { objUserInfo: this.props.objUserInfo, objCardInfo: objCardInfo });
                                console.log("Stripe: New Card Submit Succeed >>> data = ", data);
                                message.info({ content: "卡片提交成功！", key: g_strMessageKey, duration: 2 });
                                // 成功新增一张卡片后，会默认出现在列表第一位，因此之前记录的用户的默认支付卡片编号需要+1
                                if (this.props.objUserInfo.payment_id !== null) {
                                    if (this.props.objUserInfo.payment_id >= 0) {
                                        this.props.objUserInfo.payment_id += 1;
                                    } else {
                                        this.props.objUserInfo.payment_id = 0;
                                    }
                                } else {
                                    this.props.objUserInfo.payment_id = 0;
                                }
                                this.GetCardList();
                            } catch (error) {
                                message.warning({ content: "卡片提交失败！", key: g_strMessageKey, duration: 2 });
                                console.log("Stripe Error >>> ", error);
                            }

                        } else {
                            console.log("Stripe >>> handleSubmit Error : ");
                            console.log(error);
                            message.warning({ content: error.message, key: g_strMessageKey, duration: 2 });
                        }
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="payment_tips">持卡人（* 必填）</div>
                            <Input className="payment_info_input" id="name" type="name" placeholder=""
                                required={true} autoComplete="name"
                                onChange={this.CardHolderInputChange}
                                style={{ marginBottom: "2.5vw" }} />
                            <div className="payment_tips">卡号（* 必填）</div>
                            <CardSection />
                            <div className="new_card_button_area">
                                <div className="new_card_cancle_button" type="button"
                                    onClick={this.AddNewCardFormShow.bind(this, false)}>
                                    {"< 返回"}</div>
                                <button className="new_card_submit_button" type="submit">{"提交该卡片"}</button>
                            </div>
                        </form>
                    );
                }
                uiPaymentDetails = (
                    <Elements stripe={stripePromise}>
                        <AddNewCardForm />
                    </Elements>
                );
            }
            // 用户已有卡片列表
            else {
                const radioStyle = {
                    display: 'flex', alignItems: 'center',
                    width: '100%', height: '10vw', lineHeight: '10vw',
                };
                let uiCardList = (
                    <div className="no_card_yet_row">
                        <div> 还未添加任何信用卡/借记卡 </div>
                        <div className="add_new_card_button align_right" onClick={this.AddNewCardFormShow.bind(this, true)}>
                            + 添加新卡 </div>
                    </div>
                );
                let uiConfirmRemove = (
                    <div className="card_remove_confirm_area">
                        <div className="card_remove_confirm_button" onClick={this.RemoveCardFromCustomer}>是的</div>
                        <div className="card_remove_cancel_button" onClick={this.ShowRemoveReminder.bind(this, false)}>算了</div>
                    </div>
                );
                if (this.arrCards.length > 0) {
                    uiCardList = (
                        <div>
                            {/* {uiLoading} */}
                            <Radio.Group onChange={this.PaymentCardChange} value={this.state.iPaymentCard}
                                style={{ marginBottom: "3vw", fontSize: "3.5vw" }}>
                                {this.arrCards.map((item, index) => {
                                    let uiCardBrand = <div className="card_brand_icon master_icon" />
                                    if (item.card.brand === "visa")
                                        uiCardBrand = <div className="card_brand_icon visa_icon" />
                                    let uiRemoveButton = <div />;
                                    if (index === this.state.iPaymentCard) {
                                        uiRemoveButton = (
                                            <Popover title={"确定删除该卡片？"} placement="left" content={uiConfirmRemove} trigger="click"
                                                visible={this.state.bRemoveCardConfirm}>
                                                <div className="card_remove_button" onClick={this.ShowRemoveReminder.bind(this, true)}> x </div>
                                            </Popover>
                                        );
                                    }
                                    let bCheck = false;
                                    if (index === this.state.iPaymentCard)
                                        bCheck = true;
                                    return (
                                        <Radio style={radioStyle} value={index} key={index} checked={bCheck}>
                                            <div className="card_info_row">
                                                {uiCardBrand}
                                                <div className="card_info_element">{item.card.last4}</div>
                                                <div className="card_info_element">{"到期：" + item.card.exp_month + "/" + item.card.exp_year}</div>
                                                {uiRemoveButton}
                                            </div>
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                            <div className="add_new_card_button" onClick={this.AddNewCardFormShow.bind(this, true)}>
                                + 添加新卡 </div>
                        </div>
                    );
                }
                uiPaymentDetails = uiCardList;
            }
        }
        // 支付方式选择框
        let uiPaymentOptions = (
            <Modal title="请选择支付方式" visible={this.state.bSelectPaymentDlg}
                onOk={this.PaymentMethodConfirm.bind(this, true)} okText="确认"
                onCancel={this.PaymentMethodConfirm.bind(this, false)} cancelText="取消">
                <Radio.Group onChange={this.PaymentMethodChange} value={this.state.iPaymentMethod}>
                    <Radio value={1}> {this.arrPaymentMethod[1]} </Radio>
                    <Radio value={2}> {this.arrPaymentMethod[2]} </Radio>
                    <Radio value={3}> {this.arrPaymentMethod[3]} </Radio>
                </Radio.Group>
                <div className="order_details_payment_method">
                    {uiPaymentDetails}
                    {uiLoading}
                </div>
            </Modal>
        );
        let uiCurrentPaymentMethod = <div>{this.arrPaymentMethod[this.iConfirmedPaymentMethod] + " >"}</div>
        // 如果是信用卡/借记卡支付
        if (this.iConfirmedPaymentMethod === 1) {
            let uiCardBrand = <div className="card_brand_icon master_icon align_right" />
            if (this.strCurrentCardBrand === "visa")
                uiCardBrand = <div className="card_brand_icon visa_icon align_right" />
            uiCurrentPaymentMethod = (
                <div className="select_card_brief_info">
                    {uiCardBrand}
                    <div className="select_card_brief_last4">{this.strCurrentLast4 + " >"}</div>
                </div>
            );
        }
        // 支付结果弹窗
        // 支付中……
        let bPaymentProcessing = true;
        let strPaymentResultDlgTitle = "您的订单";
        let uiPaymentResultContents = (
            <div className="payment_processing">
                <Spin tip="交易处理中……" />
            </div>
        );
        // 支付成功
        if (this.state.iPaymentResult === 1) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功 !
                </div>
            );
        }
        // 支付失败
        if (this.state.iPaymentResult === -1) {
            bPaymentProcessing = false;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付失败 !
                </div>
            );
        }
        let uiPaymentResultDlg = (
            <Modal title={strPaymentResultDlgTitle} visible={this.state.bPaymentResultDlg} closable={false} confirmLoading={bPaymentProcessing}
                onOk={this.AfterPayment.bind(this, false)} okText="查看订单"
                onCancel={this.AfterPayment.bind(this, true)} cancelText="继续购物">
                {uiPaymentResultContents}
            </Modal>
        );
        // 选时间控件的时间格式
        // const format = "h:mm a";
        // 选地址控件
        this.fDistance = GetLatLongDistance(this.objCurrentPosition.lat, this.objCurrentPosition.lng, this.props.objStorePos.lat, this.props.objStorePos.lng);
        let fDriveSpeed = this.fDistance / (50 / 60);
        if (fDriveSpeed >= 60)
            fDriveSpeed = (fDriveSpeed / 60).toFixed(1).toString() + " 小时";
        else
            fDriveSpeed = fDriveSpeed.toFixed(1).toString() + " 分钟";
        let fWalkSpeed = (this.fDistance * 1000) / 60;
        if (fWalkSpeed >= 60)
            fWalkSpeed = (fWalkSpeed / 60).toFixed(1).toString() + " 小时";
        else
            fWalkSpeed = fWalkSpeed.toFixed(1).toString() + " 分钟";
        let uiSelectAddress;
        if (this.state.bLocating) {
            uiSelectAddress = (
                <div>
                    <div className="order_details_info_row" >
                        <div className="order_details_icon store_icon" />
                        <div className="order_details_info_row_text"> {"取货地址：" + this.props.objStoreInfo.address}</div>
                    </div>
                    <div className="order_details_info_row order_details_row_middle order_details_no_bottom_line" >
                        <Spin />
                    </div>
                </div>
            );
        } else {
            uiSelectAddress = (
                <div>
                    <div className="order_details_info_row" >
                        <div className="order_details_icon store_icon" />
                        <div className="order_details_info_row_text"> {"取货地址：" + this.props.objStoreInfo.address}</div>
                    </div>
                    <div className="order_details_info_row" >
                        <div className="order_details_icon location_icon_3" />
                        <div className="order_details_info_row_text"> {"当前位置：" + this.strCurrentLocation}</div>
                        <div className="order_details_info_change align_right" onClick={this.SelectAddress.bind(this, 0)}>{"更改 >"}</div>
                    </div>
                    <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_icon distance_icon" />
                        <div className="order_details_distance_text">
                            {"距离 " + this.fDistance.toFixed(1) + " km"}
                        </div>
                        <div className="order_details_distance_icon drive_icon" />
                        <div className="order_details_distance_text">
                            {fDriveSpeed}
                        </div>
                        <div className="order_details_distance_icon walk_icon" />
                        <div className="order_details_distance_text">
                            {fWalkSpeed}
                        </div>
                    </div>
                </div>
            );
        }
        let strDeliveryAddress = "无";
        if (this.state.strAddress !== null && this.state.strAddress !== "")
            strDeliveryAddress = this.state.strAddress;
        if (this.state.bToDoor) {
            uiSelectAddress = (
                <div className="order_details_info_row order_details_no_bottom_line" >
                    <div className="order_details_icon location_icon_3" />
                    <div className="order_details_info_row_text"> {"送货地址：" + strDeliveryAddress}</div>
                    <div className="order_details_info_change align_right" onClick={this.SelectAddress.bind(this, 1)}>{"更改 >"}</div>
                </div>
            );
        }
        // 修改信息对话框
        let uiChangeName = (
            <div className="user_info_change_line">
                <div className="user_info_change_title font-adjust">姓名</div>
                <Input style={{ width: '70vw' }} size="large" allowClear={true}
                    defaultValue={this.strNewName} ref="user_info_input" />
            </div>
        );
        const prefixSelector = (
            <Select style={{ width: '17.5vw' }} onChange={this.PhoneCodeSelector}
                value={this.state.strPhoneCode}>
                <Option value="+1">+1</Option>
                <Option value="+86">+86</Option>
            </Select>
        );
        let strNewPhone = "无";
        if (this.strNewPhone) {
            if (this.strNewPhone !== null && this.strNewPhone !== "")
                strNewPhone = this.strNewPhone.split(') ')[1];
        }
        let uiChangePhone = (
            <div className="user_info_change_line">
                <div className="user_info_change_title font-adjust">电话</div>
                <Input style={{ width: '75vw' }} size="large" allowClear={true}
                    addonBefore={prefixSelector} defaultValue={strNewPhone} ref="user_info_input" />
            </div>
        );
        let uiChangeContactInfoContents;
        if (this.state.iContactInfo === 0)
            uiChangeContactInfoContents = uiChangeName;
        else
            uiChangeContactInfoContents = uiChangePhone;
        let uiUserInfoUpdateDlg = (
            <Modal visible={this.state.bChangeContactInfoDlg} okText="确定" cancelText="取消" closable={false} centered
                onOk={this.ConfirmCancelContactInfo.bind(this, true)} onCancel={this.ConfirmCancelContactInfo.bind(this, false)}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                {uiChangeContactInfoContents}
            </Modal>
        );
        // 默认取货方式
        let strToDoor = "to_store";
        if (this.state.bToDoor)
            strToDoor = "to_door";

        // 填写备注对话框
        let uiCommentsDlg = (
            <Modal visible={this.state.bChangeCommentsDlg} okText="确定" cancelText="取消" closable={false} centered title="填写备注"
                onOk={this.ConfirmComments.bind(this, true)} onCancel={this.ConfirmComments.bind(this, false)}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <TextArea rows={4} ref="comments_input" />
            </Modal>
        );
        // 联系方式相关不能为空
        let strPhone = "无";
        if (this.strNewPhone !== null)
            strPhone = this.strNewPhone;

        let objPosition = this.state.objMapPosition;
        if (!this.state.bToDoor)
            objPosition = this.props.objStorePos;

        this.ContactInfoUpdate();

        let uiCouponArea = (
            <div>暂无优惠券可用</div>
        );
        if (this.state.strCouponMethod === "points") {
            uiCouponArea = (
                <div>暂无积分可用</div>
            );
        }
        if (this.state.strCouponMethod === "coupon") {
            const radioStyle = {
                display: 'flex', alignItems: 'center',
                width: '100%', height: '10vw', lineHeight: '10vw',
            };
            // 当该商家的卡券存在且数量不为0时
            if (this.props.arrTicketsData) {
                if (this.props.arrTicketsData.length > 0) {
                    uiCouponArea = (
                        <Radio.Group onChange={this.SelectCoupon} value={this.state.iCurrentCoupon}>
                            <Radio style={radioStyle} value={-1} >
                                不使用优惠券
                            </Radio>
                            {this.props.arrTicketsData.map((item, index) => {
                                let bDisable = true;
                                let strTitleStyle = "order_details_ticket_title_disable";
                                // 折扣券
                                if (item.type === 0) {
                                    bDisable = false;
                                    strTitleStyle = "order_details_ticket_title";
                                    if (this.state.iCurrentCoupon === index) {
                                        this.fPriceOff = this.props.objCart.fItemTotalPrice * (100 - item.price_off) / 100;
                                    }
                                }
                                // 代金券
                                if (item.type === 1) {
                                    if (item.condition <= this.props.objCart.fItemTotalPrice) {
                                        bDisable = false;
                                        strTitleStyle = "order_details_ticket_title";
                                        if (this.state.iCurrentCoupon === index) {
                                            this.fPriceOff = item.price_off;
                                        }
                                    }
                                }
                                let iStatus = this.TicketStatusCheck(item.id);
                                let uiUsed = (<div />);
                                // 已使用
                                if (iStatus === 1) {
                                    bDisable = true;
                                    strTitleStyle = "order_details_ticket_title_disable";
                                    uiUsed = (
                                        <div className="ticket_used_sign">已用过</div>
                                    );
                                }
                                return (
                                    <Radio style={radioStyle} value={index} key={index} disabled={bDisable}>
                                        <div className="order_details_coupon_item">
                                            <div className={strTitleStyle}>{item.title}</div>
                                            <div className="order_details_ticket_subtitle">{item.subtitle}</div>
                                            {uiUsed}
                                        </div>
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    );
                }
            }
        }

        // PST+GST & 手续费计算
        let fTotalPrice = this.props.objCart.fItemTotalPrice - this.fPriceOff;
        let fGSTFee = fTotalPrice * 0.12;
        let fFinalPrice = (fTotalPrice + fGSTFee + 0.3) / (1 - (0.029 + 0.006));
        let fStripFee = fFinalPrice * (0.029 + 0.006) + 0.3;

        return (
            <div className="order_details_page">
                {/* 加载 */}
                {uiLoading}
                {/* 标题 */}
                <div className="order_details_title">
                    <div className="order_details_back_btn back_icon" onClick={this.props.HideOrderDetails} />
                    订单详情
                </div>
                {/* 地图 */}
                <div className="order_details_googlemap_area">
                    <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={objPosition}
                        center={objPosition}>
                        <Marker position={objPosition} />
                    </Map>
                </div>
                {/* 订单详情1：取货方式 */}
                <div className="order_details_info_area">
                    <div className="order_detials_pickup_options">
                        <Radio.Group defaultValue={strToDoor} buttonStyle="solid" size="large"
                            onChange={this.ItemPickupOptionChange.bind(this)}>
                            <Radio.Button value="to_store"> 到 店 取 </Radio.Button>
                            <Radio.Button value="to_door"> 送 上 门 </Radio.Button>
                        </Radio.Group>
                    </div>
                    {uiSelectAddress}
                </div>
                {/* 订单详情2：收货人信息，时间段 & 支付方式 */}
                <div className="order_details_info_area">
                    {/* <div className="order_details_info_row" >
                        <div className="order_details_icon campass_icon" />
                        送货/自取 时间：
                        <div className="order_details_right_side_option">
                            <TimePicker onChange={this.TimeSelectorChange} defaultOpenValue={moment('00:00', format)}
                                className="order_dertails_time_pickler" use12Hours format={format} size={"small"} />
                        </div>
                    </div> */}
                    <div className="order_details_info_row">
                        {uiAvatar}
                        <div className="order_details_info_row_text">{"联系姓名：" + this.strNewName}</div>
                        <div className="order_details_info_change align_right" onClick={this.ChangeContactInfo.bind(this, 0)}>{"更改 >"}</div>
                    </div>
                    <div className="order_details_info_row" >
                        <div className="order_details_icon phone_icon" />
                        <div className="order_details_info_row_text">{"联系方式：" + strPhone}</div>
                        <div className="order_details_info_change align_right" onClick={this.ChangeContactInfo.bind(this, 1)}>{"更改 >"}</div>
                    </div>
                    {uiUserInfoUpdateDlg}
                    <div className="order_details_info_row order_details_no_bottom_line" onClick={this.SelectPaymentMethod}>
                        <div className="order_details_icon card_icon" />
                        支付方式：
                        <div className="order_details_right_side_option">{uiCurrentPaymentMethod}</div>
                    </div>
                    {uiPaymentOptions}
                </div>
                {/* 订单详情3：商家 & 商品信息 */}
                <div className="order_details_info_area">
                    <div className="order_details_info_row" >
                        {uiStoreLogo}
                        {"商家：" + this.props.objStoreInfo.store_name}
                    </div>
                    {uiItems}
                    <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_total_price">
                            {"商品价格: $ " + this.props.objCart.fItemTotalPrice.toFixed(2).toString()}
                        </div>
                    </div>
                </div>
                {/* 订单详情5：优惠券优惠码等折扣信息 */}
                <div className="order_details_info_area">
                    <div className="order_detials_pickup_options">
                        <Radio.Group defaultValue="coupon" buttonStyle="solid" size="large"
                            onChange={this.CouponOptionChange.bind(this)}>
                            <Radio.Button value="coupon"> 优惠券 </Radio.Button>
                            {/* <Radio.Button value="coupon_code"> 优 惠 码 </Radio.Button> */}
                            <Radio.Button value="points"> 积 分 </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="order_details_coupon_area" >
                        {uiCouponArea}
                    </div>
                    <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_total_price">
                            {"优惠价格： $ - " + this.fPriceOff.toFixed(2).toString()}
                        </div>
                    </div>
                </div>
                {/* 订单详情4：PSD+GST & 手续费 */}
                <div className="order_details_info_area">
                    <div className="order_details_info_row" >
                        <div className="order_details_total_price order_details_grey_text">
                            <Tooltip placement="top" title="政府税费" trigger="click">
                                <div className="order_details_tips">
                                    <Icon type="question-circle" />
                                </div>
                            </Tooltip>
                            {"PST+GST：$ " + (fGSTFee).toFixed(2).toString()}
                        </div>
                    </div>
                    <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_total_price order_details_grey_text">
                            <Tooltip placement="top" title="手续费用于支付给合作的信用卡公司" trigger="click">
                                <div className="order_details_tips">
                                    <Icon type="question-circle" />
                                </div>
                            </Tooltip>
                            {"手续费：$ " + (fStripFee).toFixed(2).toString()}
                        </div>
                    </div>
                    {/* <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_total_price order_details_grey_text">
                            <Tooltip placement="top" title="政府税费和在线手续费，手续费用于支付给合作的信用卡公司" trigger="click">
                                <div className="order_details_tips">
                                    <Icon type="question-circle" />
                                </div>
                            </Tooltip>
                            {"PST+GST & 手续费：$ " + (fGSTFee + fStripFee).toFixed(2).toString()}
                        </div>
                    </div> */}
                </div>
                {/* 订单详情6：最终需要支付（实际支付）的金额 */}
                <div className="order_details_info_area">
                    <div className="order_details_info_row order_details_no_bottom_line" >
                        <div className="order_details_total_price">
                            {"实际支付：$ " + fFinalPrice.toFixed(2).toString()}
                        </div>
                    </div>
                </div>
                {/* 订单详情7：备注信息 */}
                <div className="order_details_info_area">
                    <div className="order_details_info_row order_details_no_bottom_line">
                        <div className="order_details_icon order_icon" />
                        <div className="order_details_info_row_text">{"备注信息：" + this.strComments}</div>
                        <div className="order_details_info_change align_right" onClick={this.ChangeComments}>{"填写 >"}</div>
                        {uiCommentsDlg}
                    </div>
                </div>
                {/* 确认下单支付的按钮 & 支付结果弹窗 */}
                <div className="order_details_confirm_area" onClick={this.OrderConfirmAndPay}>
                    {"确认下单并支付 $ " + fFinalPrice.toFixed(2).toString()}
                </div>
                {uiPaymentResultDlg}
            </div>
        );

    }
}
// export default OrderDetails;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'cn', region: 'CN'
})(OrderDetails);