import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";

import { deviceType } from "react-device-detect";

import Button from "antd/es/button";
import Table from "antd/es/table";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
// import Steps from "antd/es/steps";
import Spin from "antd/es/spin";
// import Empty from "antd/es/empty";
// import Radio from "antd/es/radio";
import Icon from "antd/es/icon";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import Form from 'antd/es/form';
import message from "antd/es/message";

import "../../styles/ClientSide/tmall_ubi_page.css";
import '../../styles/Background/ego_manager.css';
import '../../styles/Background/storage_manager.css';
import '../../styles/Background/UI_Module/user_management.css';
import "antd/dist/antd.css";

// const { Search } = Input;
// const { Step } = Steps;

const g_strMessageKey = 'updating';
// 表头
const arrTableHeader = [
    { title: '单号', dataIndex: 'order_no', width: 75, ellipsis: true },
    { title: '面单', dataIndex: 'print_ui', width: 50, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 50, ellipsis: true },
    { title: '货物件数', dataIndex: 'item_num', width: 50, ellipsis: true },
    { title: '计费重量(kg)', dataIndex: 'price_weight', width: 60, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_date_str', width: 70, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 70, ellipsis: true },
];
const arrTableHeaderManager = [
    { title: '单号', dataIndex: 'order_no', width: 75, ellipsis: true },
    { title: '用户', dataIndex: 'user_name', width: 50, ellipsis: true },
    { title: '面单', dataIndex: 'print_ui', width: 50, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 50, ellipsis: true },
    { title: '货物件数', dataIndex: 'item_num', width: 50, ellipsis: true },
    { title: '计费重量(kg)', dataIndex: 'price_weight', width: 60, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_date_str', width: 70, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 70, ellipsis: true },
];
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class JWSmallParcel extends Component {

    constructor(props) {
        super(props);

        // let strParams = window.location.pathname;
        // this.strHostName = window.location.hostname;
        // this.strLanguage = "";
        // if (strParams.indexOf('cn') >= 0 || strParams.indexOf('en') >= 0)
        //     this.strLanguage = strParams.split('/tracking/')[1];
        // else
        //     this.strLanguage = "cn";
        // this.strTrackingNo = "";
        // if (strParams.indexOf('no') >= 0) {
        //     this.strTrackingNo = strParams.split('no=')[1];
        //     console.log(this.strTrackingNo);
        // }
        // console.log("params = " + strParams);

        this.state = {
            bLogin: false,
            bLoading: true,
            btnControlDisable: [false, true, true],
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bValueWeightChange: false,
            bAddNew: false,
            bOrderStatusUpdate: false
        };

        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentOrder = {};
        this.fValue = 0.0;
        this.fValueWeight = 0.0;
        this.fPriceWeight = 0.0;
        this.arrOrderItem = [];
        this.arrOrderStatus = ['全部', '已预报', '已生成订单', '运输中', '已完成'];

        this.bMStation = false;
        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.objStoreInfo = -1;
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.iUserID = 0;
        this.strKeywords = "";

        this.bPrintButtonClick = false;
        this.iOrderStatusFilter = 0;

        // this.iStoreID = 0;
        // let strSubParams = window.location.search;  // 筛选出?后面的部分
        // strSubParams = strSubParams.substring(strSubParams.indexOf('?') + 1);
        // console.log("sub params = " + strSubParams);
        // if (strSubParams.indexOf('store_id=') >= 0) {
        //     this.iStoreID = parseInt(strSubParams.split('store_id=')[1], 10);
        // }

        // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';  // 跨域访问代理
        // this.strGetExpressCompanyUrl = proxyUrl + "http://www.kuaidi100.com/autonumber/auto?";  // 快递100智能识别接口

    }

    // 选中某行的响应函数
    selectRow = (record) => {
        if (this.bPrintButtonClick)
            return;
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.CurrentOrderInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, false];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.order_no !== undefined) {
                this.refs.order_no.state.value = "";
                this.refs.receiver.state.value = "";
                this.refs.address.state.value = "";
                this.refs.postcode.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.length.state.value = 0.0;
                this.refs.width.state.value = 0.0;
                this.refs.height.state.value = 0.0;
                this.refs.value.state.value = 0.0;
                this.refs.weight.state.value = 0.0;
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.arrOrderItem = [];
            this.CurrentOrderInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.order_no !== undefined) {
                this.refs.order_no.state.value = this.objCurrentOrder.order_no;
                this.refs.receiver.state.value = this.objCurrentOrder.receiver;
                this.refs.address.state.value = this.objCurrentOrder.address;
                this.refs.postcode.state.value = this.objCurrentOrder.postcode;
                this.refs.phone.state.value = this.objCurrentOrder.phone;
                this.refs.length.state.value = this.objCurrentOrder.length;
                this.refs.width.state.value = this.objCurrentOrder.width;
                this.refs.height.state.value = this.objCurrentOrder.height;
                this.refs.value.state.value = this.objCurrentOrder.value;
                this.refs.weight.state.value = this.objCurrentOrder.weight;
            }
            this.fValue = parseFloat(this.objCurrentOrder.value);
            this.fValueWeight = parseFloat(this.objCurrentOrder.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentOrder.price_weight);
            message.loading({ content: '数据加载中……', key: g_strMessageKey });
            axios('/GetTmallOrderItemInfo', { params: { id: this.objCurrentOrder.id } })
                .then(({ data }) => {
                    message.success({ content: "货物信息加载完成！", key: g_strMessageKey, duration: 2 });
                    if (data.length > 0) {
                        this.arrOrderItem = [];
                        for (let i = 0; i < data.length; i++) {
                            let objItemInfo = {
                                type: data[i].type,
                                num: data[i].num,
                                price: data[i].price,
                            }
                            this.arrOrderItem.push(objItemInfo);
                        }

                    }
                    this.setState({
                        ...this.state,
                        iDlgType: iDlgType,
                        bShowDlg: true,
                    });
                }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            let strOrder = this.refs.order_no.state.value;
            if (strOrder === null || strOrder.trim() === "") {
                message.warning("单号不能为空！"); return;
            }
            let strReceiver = this.refs.receiver.state.value;
            if (strReceiver === null || strReceiver.trim() === "") {
                message.warning("收件人不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone.trim() === "") {
                message.warning("电话不能为空！"); return;
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress.trim() === "") {
                message.warning("地址不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode.trim() === "") {
                message.warning("邮编不能为空！"); return;
            }
            if (this.arrOrderItem.length <= 0) {
                message.warning("每单至少1件货物！"); return;
            }
            let fTotalPrice = this.ItemPriceCalculate();
            if (isNaN(fTotalPrice)) {
                message.warning('请填写有效的货值数字！'); return;
            }
            if (fTotalPrice >= 20.00) {
                message.warning('总货值不能大于 $CAD 20 !'); return;
            }
            // 货物信息检查
            for (let i = 0; i < this.arrOrderItem.length; i++) {
                if (this.arrOrderItem[i].type === null || this.arrOrderItem[i].type === "") {
                    message.warning('货物类别信息不能为空！'); return;
                }
                if (this.arrOrderItem[i].type.trim() === "") {
                    message.warning('货物类别信息不能为空！'); return;
                }
                if (this.arrOrderItem[i].num === null || this.arrOrderItem[i].num === "") {
                    message.warning('货物数量不能为空！'); return;
                }
                if (this.arrOrderItem[i].num.toString().trim() === "") {
                    message.warning('货物数量不能为空！'); return;
                }
                if (parseInt(this.arrOrderItem[i].num, 10) <= 0) {
                    message.warning('货物数量必须大于0！'); return;
                }
            }

            let objNewOrder = {
                order_no: strOrder,
                store_id: this.objStoreInfo.id,
                user_id: this.iUserID,
                user_name: this.strCurrentUserName,
                receiver: strReceiver,
                address: strAddress,
                postcode: strPostcode,
                phone: strPhone,
                length: this.refs.length.state.value,
                width: this.refs.width.state.value,
                height: this.refs.height.state.value,
                value: this.refs.value.state.value,
                value_weight: this.refs.value_weight.state.value,
                weight: this.refs.weight.state.value,
                price_weight: this.fPriceWeight,
                status: 0,
                print: 0,
                item_num: this.ItemNumCalculate()
            }
            if (this.state.iDlgType === 1) {
                objNewOrder.id = this.objCurrentOrder.id;
                objNewOrder.status = this.objCurrentOrder.status;
                objNewOrder.store_id = this.objCurrentOrder.store_id;
                objNewOrder.user_id = this.objCurrentOrder.user_id;
                objNewOrder.user_name = this.objCurrentOrder.user_name;
            }
            message.loading({ content: '正在更新订单……', key: g_strMessageKey });
            axios.post('/UpdateTmallOrder', {
                objCurrentOrder: this.objCurrentOrder,
                objNewOrder: objNewOrder,
                arrItemInfo: this.arrOrderItem,
                action: this.state.iDlgType
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetTmallOrder();
                } else {
                    if (data.message)
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    else
                        message.warning({ content: "新建/更新订单信息失败！", key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
        }
        // if (this.state.iDlgType === 2) {
        //     message.loading({ content: 'Order删除中……', key: g_strMessageKey });
        //     axios.post('/DeleteOrder', { id: this.objCurrentOrder.id }).then(
        //         ({ data }) => {
        //             if (data.status === -1) {
        //                 message.warning({
        //                     content: "删除失败，数据库中未找到指定Order！",
        //                     key: g_strMessageKey, duration: 2
        //                 });
        //                 this.setState({
        //                     ...this.state,
        //                     btnControlDisable: [false, true, true],
        //                     bLoading: false,
        //                     bShowDlg: false,
        //                     selectedRowKeys: []
        //                 });
        //             } else {
        //                 this.GetOrder();
        //             }
        //         }).catch(function (error) { console.log(error); });
        // }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 表格点击重置
    TableClickReset = () => {
        this.bPrintButtonClick = false;
    }
    // 打印面单
    OrderPrint = (index) => {
        if (this.bPrintButtonClick)
            return;
        this.bPrintButtonClick = true;
        if (this.arrOgeTableData[index].print_order !== null && this.arrOgeTableData[index].print_order !== "") {
            if (parseInt(this.arrOgeTableData[index].print, 10) === 0) {
                axios.post('/UpdateTmallOrderPrintStatus', {
                    id: this.arrOgeTableData[index].id
                }).then(({ data }) => {
                    setTimeout(this.TableClickReset, 500);
                    this.arrOgeTableData[index].print = 1;
                    this.arrOgeTableData[index].print_ui = (
                        <div className="tmall_order_print_button" onClick={this.OrderPrint.bind(this, index)}>
                            下载 | 打印
                        </div>
                    );
                    this.setState({
                        ...this.state,
                        bOrderStatusUpdate: true
                    })
                }).catch(function (error) { console.log(error); });
            } else {
                setTimeout(this.TableClickReset, 500);
            }
            window.open(this.arrOgeTableData[index].print_order, 'target', '');
        }
        else {
            setTimeout(this.TableClickReset, 500);
            message.warning("还未生成面单数据，无法打印！");

        }
    }
    // 整理Order数据
    OrganizeOrderData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objOrderData = data[i];
            objOrderData.key = objOrderData.id;

            let strPrintButtonStyle = "tmall_order_print_button";
            let uiPrintRemind = null;
            if (objOrderData.print === 0)
                uiPrintRemind = (<div className="tmall_order_print_remind" />);
            if (objOrderData.status <= 0) {
                uiPrintRemind = null;
                strPrintButtonStyle = "tmall_order_print_button_disable";
            }
            objOrderData.print_ui = (
                <div className={strPrintButtonStyle} onClick={this.OrderPrint.bind(this, i)}>
                    下载 | 打印
                    {uiPrintRemind}
                </div>
            );
            objOrderData.status_str = this.arrOrderStatus[objOrderData.status + 1];
            objOrderData.create_date_str = objOrderData.create_date.split('T')[0];
            objOrderData.update_date_str = objOrderData.update_date.split('T')[0];
            objOrderData.color = '#tmall_order_normal_row';
            if (objOrderData.status >= 3)
                objOrderData.color = '#tmall_order_complete_row';
            arrTableData.push(objOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetTmallOrder = () => {
        this.CurrentOrderInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTmallOrder', { params: { name: this.KeywordsProcess(this.strKeywords), store_id: this.objStoreInfo.id, user_id: this.iUserID, user_level: this.iCurrentUserLevel, status: this.iOrderStatusFilter - 1 } })
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeOrderData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建订单完成！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "订单信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    // if (this.state.iDlgType === 2) {
                    //     message.success({ content: "Order已删除！", key: g_strMessageKey, duration: 2 })
                    // }
                } else {
                    message.success({ content: "订单加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 新建Order信息的初始化
    CurrentOrderInit = () => {
        this.objCurrentOrder = {
            id: -1,
            order_no: "",
            receiver: "",
            address: "",
            postcode: "",
            phone: "",
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            status: 0,
            print: 0,
            update_date: new Date()
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        if (strKeywords.length > 0)
            for (let i = 0; i < strKeywords.length; i++) {
                if (strKeywords[i] !== " ") {
                    if (i === strKeywords.length - 1)
                        newKeywords += strKeywords[i];
                    else
                        newKeywords += strKeywords[i] + "%";
                }
            }
        return newKeywords;
    }
    // 搜索订单
    OrderSearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            strKeywords = "";
        }
        this.strKeywords = strKeywords;
        this.GetTmallOrder();
    }
    // 新增申报货物
    AddNewItem = () => {
        let newOrderItem = { type: '', num: 0, price: '0.00' };
        this.arrOrderItem.splice(0, 0, newOrderItem);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 物品信息编辑
    ItemInfoChange = (index, info, e) => {
        if (info === 0) {
            this.arrOrderItem[index].type = e.target.value;
        }
        if (info === 1)
            this.arrOrderItem[index].num = e.target.value;
        if (info === 2)
            this.arrOrderItem[index].price = e.target.value;
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定物品信息
    RemoveItem = (index) => {
        this.arrOrderItem.splice(index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 货物总数统计
    ItemNumCalculate = () => {
        let iNum = 0;
        if (this.arrOrderItem.length > 0) {
            for (let i = 0; i < this.arrOrderItem.length; i++) {
                iNum += parseInt(this.arrOrderItem[i].num, 10);
            }
        }
        return iNum;
    }
    // 货物总价值统计
    ItemPriceCalculate = () => {
        let fTotalPrice = 0.0;
        for (let i = 0; i < this.arrOrderItem.length; i++) {
            fTotalPrice += parseFloat(this.arrOrderItem[i].price);
        }
        return fTotalPrice;
    }
    // 订单状态过滤器
    OrderStatusFilterClicked = (index) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        this.iOrderStatusFilter = index;
        this.GetTmallOrder();
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { client: this.strCurrentUserCode } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    this.iUserID = data.user_id;
                    this.strCurrentUserName = data.name;
                    this.iCurrentUserLevel = data.level;
                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            this.GetTmallOrder();
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                bLogin: true
                            });

                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/StoreBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                console.log(data);
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }
                else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = name;
                    this.strCurrentUserCode = data.code;
                    cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                    cookie.save('store_login_status', 1, { path: '/' });
                    this.GetStoreInfo();
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 10 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.name;
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }

    componentDidMount() {
        document.title = "JW订单预报";
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            if (this.strCurrentUserCode.indexOf('u_') >= 0)
                this.iCurrentUserLevel = 5;
            if (this.strCurrentUserCode.indexOf('s_') >= 0)
                this.iCurrentUserLevel = 9;
            this.GetStoreInfo();
        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }


    }

    render() {

        let uiTrackingPage = null;
        let uiModal = null;
        // 网页端
        if (!this.bMStation) {
            // 未登录
            if (!this.state.bLogin) {
                const { getFieldDecorator, getFieldsError/*,getFieldError, isFieldTouched*/ } = this.props.form;
                // Only show error after a field is touched.
                // const usernameError = isFieldTouched('username') && getFieldError('username');
                // const passwordError = isFieldTouched('password') && getFieldError('password');
                // 客户登录表单
                const uiClientLoginForm = (
                    <Form className="tmall_order_login_form" onSubmit={this.LoginSubmit}>
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '请输入用户名!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="用户名"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="密码"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <div className="storage_login_row">
                                <div className="storage_manager_register_button">注册申请</div>
                                <Button type="primary" htmlType="submit" className="login-form-button" disabled={hasErrors(getFieldsError())}>
                                    登录
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                );
                uiTrackingPage = (
                    <div className="tmall_ubi_page">
                        <div className="tmall_order_login_title">JW Small Prcel</div>
                        {uiClientLoginForm}
                    </div>
                );
            }
            // 已登录
            else {
                let uiOrderList = null;
                if (this.state.bLoading) {
                    uiOrderList = (
                        <div className="tmall_ubi_orders_area_loading">
                            <Spin className="manage_empty_loading" size="large" tip="加载中……" style={{ color: 'grey' }} />
                        </div>
                    );
                } else {
                    // 主体表格相关
                    const { selectedRowKeys } = this.state;
                    const rowSelection = {
                        selectedRowKeys,
                        onChange: this.onSelectedRowKeysChange,
                        columnWidth: 25
                    };
                    // 表格分页属性
                    const paginationProps = {
                        showSizeChanger: true,
                        showQuickJumper: false,
                        showTotal: () => `共 ${this.arrTableData.length} 笔 订单`,
                        defaultPageSize: 10,
                        defaultCurrent: this.state.iCurrentPage,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: this.arrTableData.length,
                        position: 'bottom'
                    };
                    let uiItemList = null;
                    if (this.arrOrderItem.length > 0) {
                        uiItemList = (
                            <div className="tmall_ubi_item_list_area">
                                {this.arrOrderItem.map((item, index) => {
                                    return (
                                        <div className="user_edit_info_row" key={index}>
                                            <div className="storage_edit_box_info_title">类别：</div>
                                            <Input className="storage_edit_box_info_input" ref={"item" + index + "_name"}
                                                defaultValue={item.type} value={item.type}
                                                onChange={this.ItemInfoChange.bind(this, index, 0)} />
                                            <div className="storage_edit_box_info_title">数量：</div>
                                            <Input className="storage_edit_box_info_input" ref={"item" + index + "_num"}
                                                defaultValue={item.num} value={item.num}
                                                onChange={this.ItemInfoChange.bind(this, index, 1)} />
                                            <div className="storage_edit_box_info_title">货值：</div>
                                            <Input className="storage_edit_box_info_input" ref={"item" + index + "_price"}
                                                defaultValue={item.price} value={item.price} addonBefore="$ CAD"
                                                onChange={this.ItemInfoChange.bind(this, index, 2)} />
                                            <div className="storage_edit_info_delete_button" onClick={this.RemoveItem.bind(this, index)}>
                                                <Icon type="delete" style={{ color: 'lightcoral', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    }
                    // 订单信息编辑弹窗
                    let fTotalPrice = this.ItemPriceCalculate();
                    let objTotalPrice = { color: 'lightcoral', fontWeight: 'bold', marginRight: '25px', marginTop: '15px' };
                    if (parseFloat(fTotalPrice) <= 20.00)
                        objTotalPrice = { color: 'gray', fontWeight: 'bold', marginRight: '25px', marginTop: '15px' };
                    uiModal = (
                        <Modal title="编辑订单信息(所有信息均为必填)" width={925} visible={this.state.bShowDlg}
                            okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">单号：</div>
                                <Input className="storage_edit_info_input" ref="order_no"
                                    defaultValue={this.objCurrentOrder.order_no} />
                                <div className="storage_edit_info_title">收件人：</div>
                                <Input className="storage_edit_info_input" ref="receiver"
                                    defaultValue={this.objCurrentOrder.receiver} />
                                <div className="storage_edit_info_title">电话：</div>
                                <Input className="storage_edit_info_input" ref="phone"
                                    defaultValue={this.objCurrentOrder.phone} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">地址：</div>
                                <Input className="storage_edit_info_input_mid" ref="address"
                                    defaultValue={this.objCurrentOrder.address} />
                                <div className="storage_edit_info_title">邮编：</div>
                                <Input className="storage_edit_info_input" ref="postcode"
                                    defaultValue={this.objCurrentOrder.postcode} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">长：</div>
                                <Input className="storage_edit_info_input" ref="length" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 0)}
                                    defaultValue={this.objCurrentOrder.length} />
                                <div className="storage_edit_info_title">宽：</div>
                                <Input className="storage_edit_info_input" ref="width" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 1)}
                                    defaultValue={this.objCurrentOrder.width} />
                                <div className="storage_edit_info_title">高：</div>
                                <Input className="storage_edit_info_input" ref="height" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 2)}
                                    defaultValue={this.objCurrentOrder.height} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">体积：</div>
                                <Input className="storage_edit_info_input" ref="value" disabled addonAfter="cm3" value={this.fValue}
                                    defaultValue={this.objCurrentOrder.value} />
                                <div className="storage_edit_info_title">体积重：</div>
                                <Input className="storage_edit_info_input" ref="value_weight" disabled addonAfter="kg" value={this.fValueWeight}
                                    defaultValue={this.objCurrentOrder.value_weight} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">称重：</div>
                                <Input className="storage_edit_info_input" ref="weight" addonAfter="kg" onChange={this.OnValueWeightChange.bind(this, 3)}
                                    defaultValue={this.objCurrentOrder.weight} />
                                <div className="storage_edit_info_title">计费重量：</div>
                                <Input className="storage_edit_info_input" ref="price_weight" disabled addonAfter="kg" value={this.fPriceWeight}
                                    defaultValue={this.objCurrentOrder.price_weight} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="tmall_ubi_add_button" onClick={this.AddNewItem}>
                                    + 申报货物
                                </div>
                                <div style={{ color: 'lightcoral', marginTop: '12.5px' }}> {'*请如实填写货物类别(必须为英文)，数量，货值（必须小于 $CAD 20）'}</div>
                                <div style={{ marginLeft: 'auto', marginRight: '5px', marginTop: '15px' }}>
                                    共 {this.ItemNumCalculate()} 件货物, 总货值 =
                                </div>
                                <div style={objTotalPrice}>$CAD {fTotalPrice.toFixed(2)}</div>
                            </div>
                            {uiItemList}
                        </Modal>
                    );
                    let uiTableHeader = arrTableHeader;
                    if (this.iCurrentUserLevel >= 9)
                        uiTableHeader = arrTableHeaderManager;
                    uiOrderList = (
                        <div className="tmall_ubi_orders_area">
                            <Table style={{ margin: '0px', width: '100%', overflowX: 'auto' }}
                                rowSelection={rowSelection} pagination={paginationProps}
                                columns={uiTableHeader} dataSource={this.arrTableData} bordered
                                onRow={(record) => ({ onClick: () => { this.selectRow(record); } })}
                                rowClassName={(record) => record.color.replace('#', '')} />
                        </div>
                    );
                }
                const menuStatus = (
                    <Menu>
                        {this.arrOrderStatus.map((status, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.OrderStatusFilterClicked.bind(this, index)}>
                                    {status}
                                </Menu.Item>
                            )
                        })}
                    </Menu>
                );
                uiTrackingPage = (
                    <div className="tmall_ubi_page">
                        <div className="tmall_logout_button" onClick={this.LogoutClick}>退出登录</div>
                        <div className="tmall_ubi_title">
                            <Button type="primary" style={{ marginRight: '10px' }} onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" />新建</Button>
                            <Button type="primary" style={{ marginRight: '10px' }} onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]} >
                                <Icon type="edit" />编辑</Button>
                            <Button type="primary" onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[2]} >
                                <Icon type="printer" />批量打印</Button>
                            <Dropdown overlay={menuStatus} trigger={['click']}>
                                <div className="tmall_order_status_dropdown">
                                    {this.arrOrderStatus[this.iOrderStatusFilter]} <Icon type="caret-down" style={{ marginLeft: '5px' }} />
                                </div>
                            </Dropdown>
                            <Input placeholder="搜索单号" ref="search" defaultValue={this.state.strKeywords} style={{ width: '150px', marginLeft: '10px' }} />
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.OrderSearch}>搜索</Button>
                        </div>
                        {uiOrderList}
                        {uiModal}
                    </div>
                );
            }
        }
        // 手机端
        else {
            uiTrackingPage = (
                <div className="m_tmall_ubi_page">
                    M-Station
                </div>
            );
        }

        return (
            <div className="page_basic">
                {uiTrackingPage}
            </div>
        );
    }

}
export default Form.create()(JWSmallParcel);
