import React, { Component } from "react";
import axios from 'axios';


import Statistic from 'antd/es/statistic';

// import { Statistic } from 'antd';
import "../../styles/ClientSide/start_page.css";
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"

const { Countdown } = Statistic;

class Start extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            iStep: 0,
            bCountDownVisible: true,
            iCountDown: Date.now() + 1000 * 2
        };
        this.bJumpToHome = false;
        this.strImage = "";
        this.iStoreID = 0;
    }

    JumpToHome = () => {
        this.iStoreID = 0;
        if (!this.bJumpToHome) {
            // DataStatistic
            UserActiveUpdateBack(1, { active: "跳过广告" }, this.URLLinkJump);
            this.bJumpToHome = true;
            this.setState({
                bCountDownVisible: false
            });
        }
    }
    // 倒计时结束响应函数，*该函数中无法使用Start类中的变量和函数*
    CountdownFinish = () => {
        // 平台硬广播放完
        if (this.state.iStep === 0) {
            axios('/GetAdsInfo', { params: { type: [0] } })
                .then(({ data }) => {
                    // 如果有设置启动广告，进入启动图播放第二阶段
                    if (data.length > 0) {
                        let iStoreID = data[Math.floor(Math.random() * data.length)].store_id;
                        axios('/GetAdsImages', {
                            params: { store_id: [iStoreID], ads_order: 11 }
                        }).then(
                            ({ data }) => {
                                this.strImage = data[0].image;
                                this.iStoreID = data[0].store_id
                                this.setState({
                                    bLoading: false,
                                    iStep: 1,
                                    bCountDownVisible: true,
                                    iCountDown: Date.now() + 1000 * 6
                                });
                            }
                        ).catch(function (error) { console.log(error); });
                    } else {  // 如果没有设置启动广告，直接跳转
                        if (!this.bJumpToHome) {
                            this.iStoreID = 0;
                            // DataStatistic
                            UserActiveUpdateBack(3, { active: "看完广告" }, this.URLLinkJump);
                            this.bJumpToHome = true;
                            this.setState({
                                bCountDownVisible: false
                            });
                        }
                    }
                });
        } else {
            // 启动图第二阶段播放完成
            if (this.state.iStep === 1) {
                if (!this.bJumpToHome) {
                    this.iStoreID = 0;
                    // DataStatistic
                    UserActiveUpdateBack(3, { active: "看完广告" }, this.URLLinkJump);
                    this.bJumpToHome = true;
                    this.setState({
                        bCountDownVisible: false
                    });
                }
            }
        }
    }
    // 数据统计完成后再跳转页面
    URLLinkJump = () => {
        if (this.iStoreID === 0)
            window.location.href = '/webapp/home';
        else
            window.location.href = '/webapp/store/' + this.iStoreID + "?tab=2";
    }
    // 点击了广告图
    AdsImageClick = () => {
        if (this.iStoreID <= 0)
            return;
        else {
            // DataStatistic
            UserActiveUpdateBack(2, { active: "点击广告图", store: this.iStoreID }, this.URLLinkJump);
        }
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问启动页" });
    }

    render() {
        const uiTitle = (
            <div className="countdown_title">跳过</div>
        );
        let uiCountDownArea;
        if (this.state.bCountDownVisible) {
            let strStyle = "countdown_area";
            if (this.state.iStep === 0)
                strStyle += " countdown_area_hide"
            uiCountDownArea = (
                <div>
                    <Countdown className={strStyle} title={uiTitle}
                        onFinish={this.CountdownFinish} format="s"
                        value={this.state.iCountDown}
                        valueStyle={{
                            margin: '0', padding: '0',
                            fontSize: '4vw', color: 'white'
                        }} />
                    <div className="jump_btn_mask" onClick={this.JumpToHome} />
                </div>
            );
        } else {
            uiCountDownArea = (<div />);
        }

        let uiAdsImage = (
            <div>
                <div className="start_page_title">Mouthing</div>
                <div className="start_page_logo mouthing_logo" />
                <div className="start_page_subtitle">大型服务娱乐信息平台</div>
            </div>
        );
        let strPageStyle = "start_page start_page_empty bgc_green_light";
        if (this.strImage !== "") {
            strPageStyle = "start_page"
            uiAdsImage = <img className="start_page_image" src={this.strImage} alt=''
                onClick={this.AdsImageClick} />
        }
        return (
            <div className={strPageStyle}>
                {uiCountDownArea}
                {uiAdsImage}
            </div>
        );
    }
}
export default Start;
