import React, { Component } from "react";
import "../../../styles/ClientSide/UI_Module/main_categories.css";
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

class MainCategories extends Component {
    constructor(props) {
        super();
        this.state = {};

        // 主分类列表控制
        this.arrMainCategoryList = [
            { id: 0, icon: "category_life_icon", name: "生活服务" },
            { id: 1, icon: "category_food_icon", name: "美食" },
            { id: 2, icon: "category_play_icon", name: "休闲娱乐" },
            { id: 3, icon: "category_travel_icon", name: "周边游" }
        ];
        if (props.arrMainCategory !== undefined) {
            this.arrMainCategoryList = props.arrMainCategory.slice(1);
            // let arrMainCategoryListOge = [];
            // for (let i = 1; i < props.arrMainCategory.length; i++) {
            //     this.arrMainCategoryList.push(props.arrMainCategory[i]);
            //     arrMainCategoryListOge.push(props.arrMainCategory[i]);
            // }
            // console.log(arrMainCategoryListOge);
            // for (let i = 0; i < this.arrMainCategoryList.length; i++) {
            //     if (arrMainCategoryListOge[i].sort >= 0)
            //         this.arrMainCategoryList[arrMainCategoryListOge[i].sort] = arrMainCategoryListOge[i];
            // }
        }
        this.iCurrentCategory = -1;
    }
    // 主分类点击
    MainCategoryClicked = (iCategoryID, iIndex) => {
        this.iCurrentCategory = iCategoryID;
        // DataStatistic
        UserActiveUpdateBack(5, { active: "点击主分类", category: iCategoryID, order: iIndex }, this.JumpToCategoryPage);
    }
    // 跳转到主分类页面（主分类按钮被点击）
    JumpToCategoryPage = () => {
        window.location.href = '/webapp/category?c0=' + this.iCurrentCategory + '&c1=-1';
    }
    // 渲染完毕……
    componentDidMount() { }

    render() {
        return (
            <div className="main_category_area">
                {this.arrMainCategoryList.map((data, index) => {
                    return (
                        <button className="main_category_item" key={index}
                            onClick={this.MainCategoryClicked.bind(this, data.id, index)} >
                            <div className={"main_category_icon " + data.icon} />
                            <div className="main_category_text">{data.name}</div>
                        </button>
                    );
                })}
            </div>
        );
    }
}
export default MainCategories;
