import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Clipboard from 'react-clipboard.js';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
global.arrClientData = [];

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    // { title: '用户信息', dataIndex: 'user_info', width: 100, ellipsis: true },
    { title: '收件人名', dataIndex: 'name_ui', width: 150, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 125, ellipsis: true },
    { title: '邮箱', dataIndex: 'email', width: 125, ellipsis: true },
    { title: '地址', dataIndex: 'address', width: 300, ellipsis: true },
    { title: '邮编', dataIndex: 'postcode', width: 125, ellipsis: true },
];

class StoreClientManagerJW extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通客户', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['客户名A-Z', '客户名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentClient = {};
        this.bCopyUserName = false;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {

        if (this.bCopyUserName) {
            this.bCopyUserName = false;
            return;
        }

        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentClient = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentClient = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索客户名
    ClientNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetClientsData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchClients', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeClientsData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了客户类别过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.name_search !== undefined)
    //         this.refs.name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了客户状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.email.state.value = "";
                this.refs.postcode.state.value = "";
                this.refs.address.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentClient.name;
                this.refs.phone.state.value = this.objCurrentClient.phone;
                this.refs.email.state.value = this.objCurrentClient.email;
                this.refs.postcode.state.value = this.objCurrentClient.postcode;
                this.refs.address.state.value = this.objCurrentClient.address;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("客户名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let strPhone = this.refs.name.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                message.warning("邮政编码不能为空！"); return;
            }
            if (strPostcode.trim() === "") {
                message.warning("邮政编码不能为空！"); return;
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                message.warning("收货地址不能为空"); return;
            }
            if (strAddress.trim() === "") {
                message.warning("收货地址不能为空"); return;
            }
            let objNewClient = {
                name: strName,
                phone: strPhone,
                email: this.refs.email.state.value,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: this.props.objUserInfo.id,
                user_name: this.props.objUserInfo.name,
                user_code: this.props.objUserInfo.storage_code,
                ca2cn: this.props.iCurrentTopBarIndex
            };
            message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
            axios.post('/CreateNewClient', objNewClient).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetClientsData();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("客户名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                message.warning("邮政编码不能为空！"); return;
            }
            if (strPostcode.trim() === "") {
                message.warning("邮政编码不能为空！"); return;
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                message.warning("收货地址不能为空"); return;
            }
            if (strAddress.trim() === "") {
                message.warning("收货地址不能为空"); return;
            }
            let objNewClient = {
                name: strName,
                phone: strPhone,
                email: this.refs.email.state.value,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: this.props.objUserInfo.id,
                user_name: this.props.objUserInfo.name,
                user_code: this.props.objUserInfo.storage_code,
                ca2cn: this.props.iCurrentTopBarIndex
            };
            message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
            axios.post('/UpdateClient', {
                objCurrentClient: this.objCurrentClient,
                objNewClient: objNewClient
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetClientsData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '客户删除中……', key: g_strMessageKey });
            axios.post('/DeleteClient', { id: this.objCurrentClient.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该客户已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 客户管理操作", action: "删除" });
                        this.GetClientsData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Users数据
    OrganizeClientsData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objClientData = {
                key: i, id: data[i].id,
                name: data[i].name,
                name_ui: (
                    <div className="copy_info_row">
                        <div className="copy_info_text">{data[i].name}</div>
                        <Clipboard data-clipboard-text={data[i].name} onClick={this.CopyUserName.bind(this)}
                            className="copy_info_button export_button_style"
                            style={{ border: 'none', borderRadius: '5px' }}>
                            复制
                            </Clipboard>
                    </div>),
                phone: data[i].phone,
                email: data[i].email,
                postcode: data[i].postcode,
                address: data[i].address,
                user_info: data[i].user_name + " / " + data[i].user_code
            }
            arrTableData.push(objClientData);
        }
        return arrTableData;
    }
    // 复制收件人名
    CopyUserName = () => {
        this.bCopyUserName = true;
        message.info('收件人名已复制！');
    }
    // 显示全部收件人
    ShowAllUsers = () => {
        this.refs.name_search.state.value = "";
        this.ClientNameSearch();
    }
    // 从数据库获取当前的数据结构
    GetClientsData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        console.log("Get CLients Data : ", this.props.objUserInfo);
        axios('/GetClients', { params: { store_id: this.props.iStoreID, user_id: this.props.objUserInfo.id, user_code: this.props.objUserInfo.storage_code } })
            .then(({ data }) => {
                global.arrClientData = data;
                this.arrOgeTableData = this.OrganizeClientsData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建客户信息成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "客户信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "客户已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetClientsData();
    }
    // 渲染函数
    render() {

        // 顶部栏客户类别下拉菜单
        // const uiUserTypeMenu = (
        //     <Menu>
        //         {this.arrFilterUserType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserTypeFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 顶部栏客户状态下拉菜单
        // const uiUserStatusMenu = (
        //     <Menu>
        //         {this.arrFilterUserStatus.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserStatusFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );

        /* 客户管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllUsers}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索客户名/代码" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ClientNameSearch}>检索
                    </Button>
                </div>
                {/* <div className="manage_title_filter_area">
                    <span className="manage_stretch_right_tiny">客户类别</span>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiUserTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterUserType[this.state.objFilter.iCurrentUserType]}
                            <Icon className="dropdown_icon" type="down" />
                        </Button>
                    </Dropdown>
                </div> */}
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 客户`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 客户信息编辑弹窗
            let uiModal = (
                <Modal title="编辑客户" width={725} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>客户名称：</div>
                        <Input className="user_edit_info_input_long" ref="name" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentClient.name} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>联系电话：</div>
                        <Input className="user_edit_info_input_long" ref="phone"
                            defaultValue={this.objCurrentClient.phone} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">联系邮箱：</div>
                        <Input className="user_edit_info_input_long" ref="email" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentClient.email} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>邮政编码：</div>
                        <Input className="user_edit_info_input_long" ref="postcode"
                            defaultValue={this.objCurrentClient.postcode} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>收货地址</div>
                        <Input className="user_edit_info_input_full" ref="address"
                            defaultValue={this.objCurrentClient.address} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除客户信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentClient.name}
                                <br /><br />选中客户的所有信息都将被删除……<br />确定删除选中的客户？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps} bordered
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StoreClientManagerJW;
