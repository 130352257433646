import axios from 'axios';
import cookie from 'react-cookies';
import {
    deviceType, osVersion, osName, mobileVendor, mobileModel, browserName, fullBrowserVersion
} from "react-device-detect";

export const UserActiveUpdate = (iWhat, objWhatInfo) => {
    UserActiveObjectPost(iWhat, objWhatInfo, false, null);
}

export const UserActiveUpdateBack = (iWhat, objWhatInfo, funCallBack) => {
    UserActiveObjectPost(iWhat, objWhatInfo, true, funCallBack);
}

function UserActiveObjectPost(iWhat, objWhatInfo, bCallBack, funCallBack) {
    let objDataStatistic = {};
    objDataStatistic.where_url = window.location.href;

    // 针对微信自带的nsukey做处理
    if (objDataStatistic.where_url.indexOf('nsukey=') !== -1) {
        if (objDataStatistic.where_url.indexOf('?nsukey=') !== -1)
            objDataStatistic.where_url = objDataStatistic.where_url.split('?nsukey=')[0];
        if (objDataStatistic.where_url.indexOf('&nsukey=') !== -1)
            objDataStatistic.where_url = objDataStatistic.where_url.split('&nsukey=')[0];
    }

    let strUserKey = "wm_user_code";
    let strLoginStatusKey = "wm_login_status";
    if (objDataStatistic.where_url.indexOf('webapp-manager') !== -1) {
        strUserKey = "ego_user_code";
        strLoginStatusKey = "ego_login_status";
    }
    if (objDataStatistic.where_url.indexOf('store-manager') !== -1) {
        strUserKey = "store_user_code";
        strLoginStatusKey = "store_login_status";
    }
    let strUserCode = cookie.load(strUserKey);
    let iUserLoginStatus = cookie.load(strLoginStatusKey);
    if (iUserLoginStatus === -1 || iUserLoginStatus === '-1' ||
        strUserCode === undefined || strUserCode === "" || strUserCode === null) {
        strUserCode = "visitor";
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
    }
    objDataStatistic.who = strUserCode;
    objDataStatistic.what = iWhat;
    objDataStatistic.what_info = {};
    objDataStatistic.what_info = objWhatInfo;
    let what_info = JSON.stringify(objDataStatistic.what_info);
    objDataStatistic.what_info = what_info;
    // 获取IP地址
    axios.get("https://ipapi.co/json/")  // Another Option: https://api.ipify.org/?format=json  https://ipapi.co/json/
        .then((response) => {
            // console.log("Get IP Address:");
            // console.log(response);
            objDataStatistic.who_info = {};
            objDataStatistic.who_info.IP = response.data.ip;
            objDataStatistic.who_info.DeviceType = deviceType;
            objDataStatistic.who_info.Vendor = mobileVendor + "-" + mobileModel;
            objDataStatistic.who_info.OS = osName + "-" + osVersion;
            objDataStatistic.who_info.Browser = browserName + "-" + fullBrowserVersion;
            let who_info = JSON.stringify(objDataStatistic.who_info);
            objDataStatistic.who_info = who_info;
            axios.post('/DataMonitorUpdate', { objDataStatistic })
                .then(({ data }) => {
                    // console.log(data);
                    if (bCallBack)
                        funCallBack();
                }).catch(err => { console.log(err); });
        }).catch(err => { console.log(err); });

    // objDataStatistic.who_info = {};
    // objDataStatistic.who_info.IP = "0.0.0.0";
    // objDataStatistic.who_info.DeviceType = deviceType;
    // objDataStatistic.who_info.Vendor = mobileVendor + "-" + mobileModel;
    // objDataStatistic.who_info.OS = osName + "-" + osVersion;
    // objDataStatistic.who_info.Browser = browserName + "-" + fullBrowserVersion;
    // let who_info = JSON.stringify(objDataStatistic.who_info);
    // objDataStatistic.who_info = who_info;
    // console.log("objDataStatistic ", objDataStatistic);
    // axios.post('/DataMonitorUpdate', { objDataStatistic })
    //     .then(({ data }) => {
    //         console.log(data);
    //         if (bCallBack)
    //             funCallBack();
    //     }).catch(err => { console.log(err); });

}

export let GetItemsID = (objCart) => {
    let arrItems = [];
    if (objCart.arrBudgetItems.length > 0) {
        for (let i = 0; i < objCart.arrBudgetItems.length; i++) {
            let fItemID = objCart.arrBudgetItems[i].item.id;
            arrItems.push(fItemID);
        }
    }
    return arrItems;
}

export let StripePriceCalculate = (fItemTotalPrice, fDeliveryFee, bPSTGST) => {
    // console.log("StripePriceCalculate : fDeliveryFee = " + fDeliveryFee);
    let fTotalPrice = fItemTotalPrice;
    let fGSTFee = fTotalPrice * 0.05;
    if (bPSTGST)
        fGSTFee = fTotalPrice * 0.12;
    // let fFinalPrice = (fTotalPrice + fGSTFee + 0.3) / (1 - (0.029 + 0.006));
    let fFinalPrice = fTotalPrice + fGSTFee + fDeliveryFee;
    let fStripFee = fFinalPrice * (0.029 + 0.006) + 0.3;
    let fMouthingBenefit = fFinalPrice * 0.1;
    let fStoreBenefit = fFinalPrice - fMouthingBenefit;
    if (fTotalPrice <= 0.0) {
        fFinalPrice = 0.0;
        fStripFee = 0.0;
    }
    let objPrice = {
        fTotalPrice: fTotalPrice,
        fGSTFee: fGSTFee,
        fFinalPrice: fFinalPrice,
        fDeliveryFee: fDeliveryFee,
        fStripFee: fStripFee,
        fMouthingBenefit: fMouthingBenefit,
        fStoreBenefit: fStoreBenefit
    };
    return objPrice;
}
