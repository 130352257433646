import React, { Component } from 'react';

import axios from 'axios';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
// import Checkbox from 'antd/es/checkbox';
// import Popover from 'antd/es/popover';
// import Pagination from 'antd/es/pagination';
import message from 'antd/es/message';

import "antd/dist/antd.css";
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js";

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '物流单号', dataIndex: 'express_order', width: 150, ellipsis: true },
    { title: '运单编号', dataIndex: 'package_no', width: 150, ellipsis: true },
    // { title: '用户信息', dataIndex: 'user_info', width: 150, ellipsis: true },
    { title: '货运方式', dataIndex: 'transit_type_str', width: 100, ellipsis: true },
    { title: '货物重量', dataIndex: 'weight_str', width: 100, ellipsis: true },
    { title: '物品信息', dataIndex: 'item_info', width: 250, ellipsis: true },
    { title: '备注信息', dataIndex: 'comments', width: 250, ellipsis: true },
    { title: '包裹状态', dataIndex: 'status_str', width: 125, ellipsis: true },
    { title: '创建日期', dataIndex: 'order_date_str', width: 125, ellipsis: true }
];
const arrTableHeader_m = [
    // { title: '包裹编号', dataIndex: 'enter_no', width: 125, ellipsis: true },
    { title: '用户信息', dataIndex: 'receiver', width: 125, ellipsis: true },
    { title: '物流单号', dataIndex: 'express_order', width: 175, ellipsis: true },
    { title: '货物信息', dataIndex: 'item_info', width: 200, ellipsis: true },
    { title: '包裹状态', dataIndex: 'status_check', width: 65, ellipsis: true },
    { title: '备注信息', dataIndex: 'comments', width: 200, ellipsis: true }
];

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

class ExpressEnterJW extends Component {
    constructor(props) {
        super();
        this.strToday = new Date();
        this.state = {
            btnControlDisable: [false, true, true, true, true, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iExpressType: 1,
            iExpressTypeFilter: 0,
            iPackage: 0,
            iTransitType: 0,
            bItemValueChange: false,
            iStatusFilter: 0,
            iCurrentStatus: 0,
            bMultiEditDlg: false,
            // bPackageCombineDlg: false,
            bDeliveryStatus: true,
            selectedFile: null,
            selectedFiles: null,
            bFileDelete: false
        };
        // 成员变量
        this.arrTableDataOge = [];  // 原始数组
        this.arrTableData = [];  // 过滤数组
        this.objCurrentExpressEnter = {
            enter_no: '', receiver: '', user_name: '', express_type: 0, express_order: '', status: 0, comments: '', transit_type: 0,
            express_info: {
                // item_weight: 0.0,
                item_info: [{ cn: '', en: '', num: 1 }]
            }
        };
        this.arrSelectedExpressEnter = [];
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;

        this.arrExpressTypeFilter = ["全部", "其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];
        this.iExpressTypeFilter = 0;
        this.arrExpressType = ["其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];

        this.strYear = this.strToday.toISOString().split('-')[0];
        this.strMonth = this.strToday.toISOString().split('-')[1];
        this.strDay = this.strToday.toISOString().split('-')[2].split('T')[0];

        this.strEnterNoKeyWord = "";
        this.strUserKeyWord = "";
        this.strItemKeyWord = "";
        this.arrTransitType = ["海运", "空运"];
        // this.arrTransitType = ["海运", "空运", "小包裹"];
        this.arrStatusFilter = ['全部', '未到库', '已到库', '待合箱', '合箱中', '已合箱', '已发货', '无头件', '退货中', '已退货'];
        this.arrStatusChange = ['未到库', '已到库', '待合箱', '合箱中', '已合箱', '已发货', '无头件', '退货中', '已退货'];
        this.arrDeliveryStatus = ["立刻发货", "寄存仓库"];

        this.bSubmitting = false;

        this.arrFileWaitList = [];
        this.bDeletingFile = false;

        this.iCurrentPage = 1;
        this.iTotalOrderNum = 0;
        this.iTotalPage = 0;
        this.iNumPerPage = 10;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 双击某行的响应函数
    DoubleClickRow = (record) => {
        // console.log("Double Click Row : ", record);
        // const selectedRowKeys = [...this.state.selectedRowKeys];
        // if (selectedRowKeys.indexOf(record.key) >= 0) {
        //     selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        // } else {
        //     selectedRowKeys.push(record.key);
        // }
        const selectedRowKeys = [record.key];
        this.SelectedRowStateSetting(selectedRowKeys, true);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 处理选中的行
    SelectedRowStateSetting = (selectedRowKeys, bDoubleClick) => {
        let btnControlDisable = [];
        this.arrSelectedExpressEnter = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true, true, true, true, true];
                this.objCurrentExpressEnter = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false, false, true, false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        // this.objCurrentExpressEnter = Object.assign({}, this.arrTableData[i]);
                        let objExpressEnter = JSON.stringify(this.arrTableData[i]);
                        this.objCurrentExpressEnter = JSON.parse(objExpressEnter);
                        this.arrSelectedExpressEnter.push(this.arrTableData[i]);
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, false, true, true, false, false];
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedExpressEnter.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
        if (bDoubleClick)
            this.ShowDlg(1);
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        // 新建
        if (iDlgType === 0) {
            if (this.refs.enter_no) {
                this.refs.enter_no.state.value = "LK39";
                // this.refs.receiver.state.value = "";
                this.refs.user_name.state.value = "";
                this.refs.express_order.state.value = "";
                this.refs.comments.state.value = "";
                this.refs.item_price.state.value = 0.0;
                // this.refs.item_weight.state.value = 0.0;
                this.refs.item_length.state.value = 0.0;
                this.refs.item_width.state.value = 0.0;
                this.refs.item_height.state.value = 0.0;
                this.refs.phone.state.value = "";
            }
            this.objCurrentExpressEnter = {
                enter_no: 'LK39', receiver: '', user_name: '', express_type: 0, express_order: '', status: 0, comments: '', delivery_status: 0,
                transit_type: 0,
                express_info: {
                    // item_weight: 0.0,
                    item_info: [{ cn: '', en: '', num: 1 }]
                }
            };
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iExpressType: 1,
                iCurrentStatus: 0,
                iPackage: 0,
                iTransitType: 0,
                bDeliveryStatus: true
            });
        }
        // 编辑
        if (iDlgType === 1) {
            if (this.refs.comments !== undefined) {
                this.refs.enter_no.state.value = this.objCurrentExpressEnter.enter_no;
                // this.refs.receiver.state.value = this.objCurrentExpressEnter.receiver;
                this.refs.user_name.state.value = this.objCurrentExpressEnter.user_name;
                this.refs.express_order.state.value = this.objCurrentExpressEnter.express_order;
                this.refs.comments.state.value = this.objCurrentExpressEnter.comments;
                // this.refs.item_weight.state.value = this.objCurrentExpressEnter.express_info.item_weight;
                this.refs.phone.state.value = this.objCurrentExpressEnter.phone;
            }
            let iStatus = this.objCurrentExpressEnter.status;
            let bDeliveryStatus = true;
            if (parseInt(this.objCurrentExpressEnter.delivery_status, 10) === 1)
                bDeliveryStatus = false;
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iExpressType: this.objCurrentExpressEnter.express_type,
                iCurrentStatus: iStatus,
                bDeliveryStatus: bDeliveryStatus,
                iTransitType: this.objCurrentExpressEnter.transit_type
            });
        }
        // 删除
        if (iDlgType === 2) {
            console.log(this.arrSelectedExpressEnter);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        // 批量编辑
        if (iDlgType === 3) {
            this.setState({
                ...this.state,
                bMultiEditDlg: true
            });
        }
        // 提交运单
        if (iDlgType === 4) {
            let bStatusCheck = true;
            for (let i = 0; i < this.arrSelectedExpressEnter.length; i++) {
                if (this.arrSelectedExpressEnter[i].status !== 1) {
                    bStatusCheck = false; break;
                }
            }
            if (bStatusCheck) {
                if (this.arrSelectedExpressEnter.length > 1) {
                    // // 是否来自同一个用户的判断
                    // let bSameUser = true;
                    // let strUserInfo = this.arrSelectedExpressEnter[0].user_info;
                    // for (let i = 1; i < this.arrSelectedExpressEnter.length; i++) {
                    //     if (this.arrSelectedExpressEnter[i].user_info !== strUserInfo) {
                    //         bSameUser = false; break;
                    //     }
                    // }
                    // if (bSameUser) {
                    //     console.log("准备跳转页面，提交订单");
                    //     this.props.CreatePackageCombine(this.arrSelectedExpressEnter);
                    // } else
                    //     message.warning("请选择同一个用户的包裹！");
                    let iStatusCheck = 1;
                    let iTransitType = this.arrSelectedExpressEnter[0].transit_type;
                    for (let i = 0; i < this.arrSelectedExpressEnter.length; i++) {
                        // 海运不提供用户合箱服务
                        if (this.arrSelectedExpressEnter[i].transit_type === 0) {
                            message.warning('海运会根据船期由仓库自动合箱并生成运单，请保持关注！');
                            iStatusCheck = -3; return;
                        }
                        // 包裹状态必须为已到库
                        if (this.arrSelectedExpressEnter[i].status < 1) {
                            message.warning('请选择已到库的包裹！');
                            iStatusCheck = -1; return;
                        }
                        // 运输方式必须一致
                        if (this.arrSelectedExpressEnter[i].transit_type !== iTransitType) {
                            message.warning('请选择同一种货运方式的包裹！');
                            iStatusCheck = -3; return;
                        }
                        // 物品信息检查
                        if (this.arrSelectedExpressEnter[i].express_info === null || this.arrSelectedExpressEnter[i].express_info === "") {
                            message.warning('包裹的物品信息不能为空，请检查！');
                            iStatusCheck = -2; return;
                        } else {
                            if (this.arrSelectedExpressEnter[i].express_info.item_info.length <= 0) {
                                message.warning('包裹的物品信息不能为空，请检查！');
                                iStatusCheck = -2; return;
                            }
                        }
                    }
                    if (iStatusCheck >= 0) {
                        if (this.arrSelectedExpressEnter.length > 1) {
                            // 是否来自同一个用户的判断
                            let bSameUser = true;
                            let strUserInfo = this.arrSelectedExpressEnter[0].user_info;
                            for (let i = 1; i < this.arrSelectedExpressEnter.length; i++) {
                                if (this.arrSelectedExpressEnter[i].user_info !== strUserInfo) {
                                    bSameUser = false; break;
                                }
                            }
                            if (bSameUser) {
                                this.props.CreatePackageCombine(this.arrSelectedExpressEnter);
                            } else
                                message.warning("请选择同一个用户的包裹！");
                        } else
                            this.props.CreatePackageCombine(this.arrSelectedExpressEnter);
                    }
                } else {
                    console.log("只有一个包裹，准备跳转页面，提交订单");
                    // 海运不提供用户合箱服务
                    if (this.arrSelectedExpressEnter[0].transit_type === 0) {
                        message.warning('海运会根据船期由仓库自动合箱并生成运单，请保持关注！');
                        return;
                    }
                    this.props.CreatePackageCombine(this.arrSelectedExpressEnter);
                }
            } else
                message.warning('请选择已到库的包裹！');
        }
    }
    // 物品信息检查
    ItemInfoCheck = () => {
        for (let i = 0; i < this.objCurrentExpressEnter.express_info.item_info.length; i++) {
            if (this.objCurrentExpressEnter.express_info.item_info[i].cn === null ||
                this.objCurrentExpressEnter.express_info.item_info[i].cn === "") {
                return -1;
            }
            if (this.objCurrentExpressEnter.express_info.item_info[i].en === null ||
                this.objCurrentExpressEnter.express_info.item_info[i].en === "") {
                return -1;
            }
            if (this.objCurrentExpressEnter.express_info.item_info[i].num === null ||
                this.objCurrentExpressEnter.express_info.item_info[i].num === "") {
                return -1;
            }
            let iNum = parseInt(this.objCurrentExpressEnter.express_info.item_info[i].num, 10);
            if (isNaN(iNum)) {
                return -2;
            } else {
                if (iNum <= 0) {
                    return -2;
                }
            }
        }
        return 0;
    }
    // 获取新输入的信息
    GetNewInputInfo = () => {
        let strEnterNo = this.refs.enter_no.state.value;
        // let strStorageCode = this.refs.storage_code.state.value;
        let strStorageCode = this.props.objUserInfo.storage_code;
        let strExpressOrder = this.refs.express_order.state.value;
        // let strWeight = this.refs.item_weight.state.value;

        if (strEnterNo === null || strEnterNo === "") {
            message.warning("包裹编号不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strStorageCode === null || strStorageCode === "") {
            message.warning("用户编码不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strExpressOrder === null || strExpressOrder === "") {
            message.warning("物流单号不能为空！"); this.bSubmitting = false; return -1;
        }
        // if (strWeight === null || strWeight === "") {
        //     message.warning("货物重量不能为空！"); this.bSubmitting = false;
        //     return -1;
        // }
        // let fWeight = parseFloat(strWeight);
        // if (!isNaN(fWeight)) {
        //     if (fWeight <= 0.0) {
        //         message.warning("货物重量必须大于0 ！"); this.bSubmitting = false;
        //         return -1;
        //     }
        // } else {
        //     message.warning("货物重量请填写标准数字！"); this.bSubmitting = false;
        //     return -1;
        // }


        this.objCurrentExpressEnter.enter_no = strEnterNo;
        this.objCurrentExpressEnter.receiver = '';
        this.objCurrentExpressEnter.storage_code = strStorageCode;
        this.objCurrentExpressEnter.express_type = this.state.iExpressType;
        this.objCurrentExpressEnter.status = this.state.iCurrentStatus;
        if (this.state.iPackage === 1)
            this.objCurrentExpressEnter.status = 2;
        this.objCurrentExpressEnter.express_order = strExpressOrder;
        this.objCurrentExpressEnter.express_type = this.state.iExpressType;
        this.objCurrentExpressEnter.comments = this.refs.comments.state.value;
        if (this.state.bDeliveryStatus)
            this.objCurrentExpressEnter.delivery_status = 0;
        else
            this.objCurrentExpressEnter.delivery_status = 1;
        if (typeof (this.objCurrentExpressEnter.express_info) === 'object') {
            // this.objCurrentExpressEnter.express_info.item_weight = fWeight;
            if (this.objCurrentExpressEnter.express_info.item_info.length <= 0) {
                message.warning("物品信息不能为空 ！"); this.bSubmitting = false;
                return -1;
            } else {
                let iItemCheckResult = this.ItemInfoCheck();
                if (iItemCheckResult === -1) {
                    message.warning("物品名称和数量不能为空 ！"); this.bSubmitting = false;
                    return -1;
                }
                if (iItemCheckResult === -2) {
                    message.warning("物品数量必须大于0 ！"); this.bSubmitting = false;
                    return -1;
                }
            }
            this.objCurrentExpressEnter.express_info = JSON.stringify(this.objCurrentExpressEnter.express_info);
        }

        this.objCurrentExpressEnter.transit_type = this.state.iTransitType;
        return 1;
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.bSubmitting)
            return;
        this.bSubmitting = true;
        if (this.props.iUserLevel <= 5) {
            message.warning("权限不够，只能查看，无法编辑！");
            return;
        }
        this.objCurrentExpressEnter.store_id = this.props.iStoreID;
        delete this.objCurrentExpressEnter.key;
        delete this.objCurrentExpressEnter.rowKey;
        delete this.objCurrentExpressEnter.status_check;
        delete this.objCurrentExpressEnter.package_check;
        delete this.objCurrentExpressEnter.delivery_status_str;
        delete this.objCurrentExpressEnter.item_info;
        delete this.objCurrentExpressEnter.order_date_str;
        delete this.objCurrentExpressEnter.user_info;
        delete this.objCurrentExpressEnter.transit_type_str;
        delete this.objCurrentExpressEnter.status_str;
        delete this.objCurrentExpressEnter.weight_str;
        // 新建包裹
        if (this.state.iDlgType === 0) {
            delete this.objCurrentExpressEnter.id;
            if (this.GetNewInputInfo() === -1)
                return;
            this.iExpressTypeFilter = 0;
            message.loading({ content: '正在创建包裹信息……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: this.objCurrentExpressEnter
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.setState({
                        ...this.state,
                        iExpressTypeFilter: 0,
                        bShowDlg: false
                    })
                    // DataStatistic
                    UserActiveUpdate(4, { active: "物流后台: 包裹管理操作", action: "新建" });
                    this.GetExpressEnterData();
                } else {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.bSubmitting = false;
                    this.objCurrentExpressEnter.express_info = JSON.parse(this.objCurrentExpressEnter.express_info);
                    this.setState({
                        ...this.state,
                        iExpressTypeFilter: 0
                    });
                }
            }).catch(function (error) { console.log(error); });
        }
        // 编辑包裹
        if (this.state.iDlgType === 1) {
            if (this.GetNewInputInfo() === -1) {
                return;
            }
            if (this.state.iCurrentStatus >= 4) {
                message.warning("该包裹已经合箱完成，无法修改信息，请联系客服！");
                return;
            }
            message.loading({ content: '正在更新包裹信息……', key: g_strMessageKey });
            axios.post('/UpdateExpressEnter', {
                objExpressEnter: this.objCurrentExpressEnter,
                bk_manager: 1
            }).then(({ data }) => {
                this.setState({
                    ...this.state,
                    bShowDlg: false
                })
                // DataStatistic
                UserActiveUpdate(4, { active: "物流后台:包裹管理操作", action: "编辑" });
                this.GetExpressEnterData();
            }).catch(function (error) { console.log(error); });
        }
        // 删除包裹
        if (this.state.iDlgType === 2) {
            let arrDeleteID = [];
            for (let i = 0; i < this.arrSelectedExpressEnter.length; i++)
                arrDeleteID.push(this.arrSelectedExpressEnter[i].id);
            message.loading({ content: '包裹删除中……', key: g_strMessageKey });
            axios.post('/DeleteExpressEnter', { arrDeleteID: arrDeleteID }).then(
                ({ data }) => {
                    console.log(data);
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                    this.GetExpressEnterData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.objCurrentExpressEnter = {};
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true, true, true, true, true]
        });
    };
    // 从数据库获取当前的数据结构
    GetExpressEnterData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetExpressEnter', {
            params: {
                enter_no_search: this.strEnterNoKeyWord,
                user_search: this.props.objUserInfo.name,
                item_search: this.strItemKeyWord,
                store_id: this.props.iStoreID,
                user_name: this.props.objUserInfo.name,
                storage_code: this.props.objUserInfo.storage_code,
                page: this.iCurrentPage,
                num: this.iNumPerPage,
                user_center: 1
            }
        }).then(({ data }) => {
            this.bSubmitting = false;
            this.iTotalOrderNum = data.total_num;
            this.iTotalPage = data.total_page;
            this.arrTableData = [];
            this.arrTableData = data.orders;
            if (this.arrTableData.length > 0) {
                for (let i = 0; i < this.arrTableData.length; i++) {
                    this.arrTableData[i].key = i;
                    this.arrTableData[i].rowKey = i;
                    this.arrTableData[i].user_info = this.arrTableData[i].user_name + ' / ' + this.arrTableData[i].storage_code;
                    this.arrTableData[i].transit_type_str = this.arrTransitType[this.arrTableData[i].transit_type];

                    if (this.arrTableData[i].order_date !== null)
                        if (this.arrTableData[i].order_date.indexOf('T') >= 0)
                            this.arrTableData[i].order_date_str = this.arrTableData[i].order_date.split('T')[0];

                    if (this.arrTableData[i].express_info === null || this.arrTableData[i].express_info === "")
                        this.arrTableData[i].express_info = {
                            // item_weight: 0.0,
                            item_info: []
                        };
                    else {
                        this.arrTableData[i].express_info = JSON.parse(this.arrTableData[i].express_info);
                        if (typeof (this.arrTableData[i].express_info) === 'string') {
                            this.arrTableData[i].express_info = JSON.parse(this.arrTableData[i].express_info);
                        }
                    }
                    if (this.arrTableData[i].express_info.item_info) {
                        if (this.arrTableData[i].express_info.item_info === null || this.arrTableData[i].express_info.item_info === "")
                            this.arrTableData[i].express_info.item_info = [];
                    }

                    if (this.arrTableData[i].express_info.item_info.length > 0) {
                        let iItemNum = 0;
                        for (let j = 0; j < this.arrTableData[i].express_info.item_info.length; j++)
                            iItemNum += parseInt(this.arrTableData[i].express_info.item_info[j].num);
                        this.arrTableData[i].item_info = this.arrTableData[i].express_info.item_info[0].cn +
                            " 等 " + iItemNum + " 件物品";
                    }
                    // this.arrTableData[i].weight_str = this.arrTableData[i].express_info.item_weight + " kg";
                    this.arrTableData[i].status_str = this.arrStatusChange[this.arrTableData[i].status];

                    // this.arrTableData[i].status_check = (
                    //     <Checkbox className="express_enter_checkbox" checked={false} disabled={true} >未签收</Checkbox>
                    // );
                    // this.arrTableData[i].package_check = (
                    //     <Checkbox className="express_enter_checkbox" checked={false} disabled={true} >未合箱</Checkbox>
                    // );
                    // this.arrTableData[i].delivery_status_str = this.arrDeliveryStatus[this.arrTableData[i].delivery_status];
                    // if (this.arrTableData[i].delivery_status === 1) {
                    //     this.arrTableData[i].delivery_status_str = (
                    //         <div style={{ color: 'darkcyan' }}>{this.arrDeliveryStatus[this.arrTableData[i].delivery_status]}</div>
                    //     );
                    // } else {
                    //     this.arrTableData[i].delivery_status_str = (
                    //         <div style={{ color: 'lightcoral', fontWeight: 'bold' }}>{this.arrDeliveryStatus[this.arrTableData[i].delivery_status]}</div>
                    //     );
                    // }
                    // if (this.props.bMStation) {
                    //     this.arrTableData[i].status_check = (
                    //         <Checkbox className="express_enter_checkbox" checked={false} disabled={true} />
                    //     );
                    //     this.arrTableData[i].package_check = (
                    //         <Checkbox className="express_enter_checkbox" checked={false} disabled={true} />
                    //     );
                    // }
                    // if (this.arrTableData[i].status === 1) {
                    //     this.arrTableData[i].status_check = (
                    //         <Checkbox className="express_enter_checkbox" checked={true} disabled={true} >已签收</Checkbox>
                    //     );
                    //     if (this.props.bMStation) {
                    //         this.arrTableData[i].status_check = (
                    //             <Checkbox className="express_enter_checkbox" checked={true} disabled={true} />
                    //         );
                    //     }
                    // }
                    // if (this.arrTableData[i].status >= 2) {
                    //     this.arrTableData[i].status_check = (
                    //         <Checkbox className="express_enter_checkbox" checked={true} disabled={true} >已签收</Checkbox>
                    //     );
                    //     this.arrTableData[i].package_check = (
                    //         <Checkbox className="express_enter_checkbox" checked={true} disabled={true} >已合箱</Checkbox>
                    //     );
                    //     if (this.props.bMStation) {
                    //         this.arrTableData[i].status_check = (
                    //             <Checkbox className="express_enter_checkbox" checked={true} disabled={true} />
                    //         );
                    //         this.arrTableData[i].package_check = (
                    //             <Checkbox className="express_enter_checkbox" checked={true} disabled={true} />
                    //         );
                    //     }
                    // }
                }
                this.arrTableDataOge = this.arrTableData;
            }
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "包裹预报完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "包裹信息已更新！", key: g_strMessageKey, duration: 2 });
                    if (this.state.iCurrentStatus !== 0) {
                        this.SwitchStatusFilter(this.state.iStatusFilter);
                    }
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "包裹信息已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.objCurrentExpressEnter = {
                enter_no: '', receiver: '', user_name: '', storage_code: '', express_type: 0, express_order: '', status: 0, comments: '', phone: '', delivery_status: 0,
                express_info: {
                    // item_weight: 0.0,
                    item_info: [{ cn: '', en: '', num: 1 }]
                }
            };
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                bMultiEditDlg: false,
                btnControlDisable: [false, true, true, true, true, true, true],
                selectedRowKeys: []
            });
        }).catch(function (error) {
            console.log(error);
        });
    }
    // 包裹信息查询
    ExpressOrderLookup = (iType) => {
        let strKeyWord = "";
        strKeyWord = this.refs.enter_no_search.state.value;
        this.strEnterNoKeyWord = strKeyWord;
        // strKeyWord = this.refs.user_search.state.value;
        // this.strUserKeyWord = strKeyWord;
        strKeyWord = this.refs.item_search.state.value;
        this.strItemKeyWord = strKeyWord;
        this.GetExpressEnterData();
    }
    // 快递公司过滤器切换
    SwitchExpressTypeFilter = (index) => {
        console.log("SwitchExpressTypeFilter >>> ", this.arrTableData);
        this.iExpressTypeFilter = index;
        // this.strKeyWord = "";
        // this.refs.search.state.value = "";

        if (index === 0) {
            this.arrTableData = this.arrTableDataOge;
        } else {

            let iExpressType = index - 1;
            let arrNewTableData = [];
            for (let i = 0; i < this.arrTableData.length; i++) {
                if (iExpressType === this.arrTableData[i].express_type)
                    arrNewTableData.push(this.arrTableData[i]);
            }
            this.arrTableData = arrNewTableData;
        }
        this.setState({
            ...this.state,
            iExpressTypeFilter: index
        });

        // this.GetExpressEnterData();
    }
    // 快递公司切换
    SwitchExpressType = (index) => {
        this.setState({
            ...this.state,
            iExpressType: index
        });
    }
    // 包裹状态过滤器响应
    SwitchStatusFilter = (index) => {
        this.arrTableData = [];
        if (index > 0) {
            let iStatus = index - 1;
            for (let i = 0; i < this.arrTableDataOge.length; i++) {
                if (iStatus === 2 && iStatus <= this.arrTableDataOge[i].status)
                    this.arrTableData.push(this.arrTableDataOge[i]);
                else {
                    if (iStatus === this.arrTableDataOge[i].status)
                        this.arrTableData.push(this.arrTableDataOge[i]);
                }
            }
        } else {
            this.arrTableData = this.arrTableDataOge;
        }
        this.setState({
            ...this.state,
            iStatusFilter: index
        });
    }
    // 包裹状态变更
    SwitchExpressStatus = (index) => {
        this.setState({
            ...this.state,
            iCurrentStatus: index
        });
    }
    // 状态更新
    StatusChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iCurrentStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iCurrentStatus: 0,
                iPackage: 0
            });
    }
    // 合箱更新
    PackageChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iPackage: 1,
                iCurrentStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iPackage: 0
            });
    }
    // 发货选项更新
    DeliveryStatusChange = (e) => {
        this.setState({
            ...this.state,
            bDeliveryStatus: e.target.checked
        });
    }
    // 复制粘贴
    CopyPasteAction = (action) => {
        // 复制
        if (action === 0) {
            this.setState({
                ...this.state,
                btnControlDisable: [false, false, false, false, false, false, true]
            });
        }
        // 粘贴
        if (action === 1) {
            delete this.objCurrentExpressEnter.key;
            delete this.objCurrentExpressEnter.rowKey;
            delete this.objCurrentExpressEnter.status_check;
            delete this.objCurrentExpressEnter.package_check;
            delete this.objCurrentExpressEnter.item_info;
            delete this.objCurrentExpressEnter.id;

            this.iExpressTypeFilter = 0;
            this.setState({
                ...this.state,
                iExpressTypeFilter: 0
            })
            message.loading({ content: '正在新建包裹信息……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: this.objCurrentExpressEnter
            }).then(({ data }) => {
                if (data.status === 1) {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台: 包裹新信息管理操作", action: "粘贴" });
                    this.GetExpressEnterData();
                } else {
                    message.warning(data.message);
                }
            }).catch(function (error) { console.log(error); });
        }
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        let arrExportData = [
            ['用户信息', '物流单号', '货运方式', '货物重量', '物品信息', '备注信息', '包裹状态']
        ];
        for (let i = 0; i < this.arrSelectedExpressEnter.length; i++) {
            let arrData = [];
            // arrData.push(this.arrSelectedExpressEnter[i].enter_no);
            arrData.push(this.arrSelectedExpressEnter[i].user_name + ' / ' + this.arrSelectedExpressEnter[i].storage_code);
            arrData.push(this.arrSelectedExpressEnter[i].express_order);
            arrData.push(this.arrTransitType[this.arrSelectedExpressEnter[i].transit_type]);
            // arrData.push(this.arrSelectedExpressEnter[i].express_info.item_weight + " kg");
            let strItemInfo = "";
            for (let j = 0; j < this.arrSelectedExpressEnter[i].express_info.item_info.length; j++) {
                strItemInfo += this.arrSelectedExpressEnter[i].express_info.item_info[j].cn + " x ";
                if (j === this.arrSelectedExpressEnter[i].express_info.item_info.length - 1)
                    strItemInfo += this.arrSelectedExpressEnter[i].express_info.item_info[j].num;
                else
                    strItemInfo += this.arrSelectedExpressEnter[i].express_info.item_info[j].num + " | ";
            }
            arrData.push(strItemInfo);
            arrData.push(this.arrSelectedExpressEnter[i].comments);
            // if (this.arrSelectedExpressEnter[i].status === 0)
            //     arrData.push('×');
            // else
            //     arrData.push('√');
            // if (this.arrSelectedExpressEnter[i].status <= 1)
            //     arrData.push('×');
            // else
            //     arrData.push('√');
            arrData.push(this.arrStatusChange[this.arrSelectedExpressEnter[i].status]);
            arrExportData.push(arrData);
        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '包裹管理.xlsx');

    }
    // 显示全部包裹信息
    ShowAllExpressEnter = () => {
        this.iExpressTypeFilter = 0;
        this.strEnterNoKeyWord = "";
        this.refs.enter_no_search.state.value = "";
        this.strUserKeyWord = "";
        // this.refs.user_search.state.value = "";
        this.strItemKeyWord = "";
        this.refs.item_search.state.value = "";
        this.setState({
            ...this.state,
            iExpressTypeFilter: 0,
            iCurrentStatus: 0
        });
        this.GetExpressEnterData();
    }
    // 新增包裹信息
    AddNewItemInfo = () => {
        let objItemInfo = { cn: '', en: '', num: 1 };
        this.objCurrentExpressEnter.express_info.item_info.splice(0, 0, objItemInfo);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 后续运输方式切换
    SwitchTransitType = (index) => {
        if (this.props.iUserLevel <= 5) {
            message.warning("权限不够，只能查看，无法编辑！");
            return;
        }
        this.objCurrentExpressEnter.enter_no = 'LK39';
        if (index === 1)
            this.objCurrentExpressEnter.enter_no = 'JW';
        this.setState({
            ...this.state,
            iTransitType: index,
            bItemValueChange: true
        });
    }
    // 物品信息发生变化
    ItemInfoChange = (indexItem, value, e) => {
        if (this.props.iUserLevel <= 5) {
            message.warning("权限不够，只能查看，无法编辑！");
            return;
        }
        if (value === 0) {
            this.objCurrentExpressEnter.express_info.item_info[indexItem].cn = e.target.value;
        }
        if (value === 1) {
            this.objCurrentExpressEnter.express_info.item_info[indexItem].en = e.target.value;
        }
        if (value === 2) {
            this.objCurrentExpressEnter.express_info.item_info[indexItem].num = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 移除一个物品信息
    RemoveItemInfo = (indexItem) => {

        if (this.objCurrentExpressEnter.express_info.item_info.length <= 1) {
            message.warning("每单包裹至少包含一条物品信息！");
            return;
        }

        this.objCurrentExpressEnter.express_info.item_info.splice(indexItem, 1);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });

    }
    // 清空物品信息
    ClearItemInfo = () => {

        this.objCurrentExpressEnter.express_info.item_info = [{ cn: '', en: '', num: 1 }];

        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 批量编辑确认按钮响应
    // MultiEditConfirm = () => {
    //     let strEnterNo = this.refs.multi_enter_no.state.value;
    //     let strReceiver = this.refs.multi_receiver.state.value;
    //     let strStorageCode = this.refs.multi_storage_code.state.value;
    //     let strComments = this.refs.multi_comments.state.value;
    //     let strPhone = this.refs.multi_phone.state.value;
    //     let iStatus = 0;
    //     let arrOrderID = [];
    //     for (let i = 0; i < this.arrSelectedExpressEnter.length; i++) {
    //         arrOrderID.push(this.arrSelectedExpressEnter[i].id);
    //         if (strEnterNo !== "")
    //             this.arrSelectedExpressEnter[i].enter_no = strEnterNo;
    //         if (strReceiver !== "")
    //             this.arrSelectedExpressEnter[i].receiver = strReceiver;
    //         if (strStorageCode !== "")
    //             this.arrSelectedExpressEnter[i].storage_code = strStorageCode;
    //         if (strComments !== "")
    //             this.arrSelectedExpressEnter[i].comments = strComments;
    //         if (strPhone !== "")
    //             this.arrSelectedExpressEnter[i].phone = strPhone;
    //         this.arrSelectedExpressEnter[i].status = this.state.iCurrentStatus;
    //         if (this.state.iPackage === 1)
    //             this.arrSelectedExpressEnter[i].status = 2;
    //         iStatus = this.arrSelectedExpressEnter[i].status;
    //     }
    //     this.setState({
    //         ...this.state,
    //         bLoading: true
    //     });
    //     message.loading({ content: '正在更新订单信息……', key: g_strMessageKey });
    //     axios.post('/MultiEditExpressEnter', {
    //         arrOrderID: arrOrderID,
    //         enter_no: strEnterNo,
    //         receiver: strReceiver,
    //         storage_code: strStorageCode,
    //         comments: strComments,
    //         phone: strPhone,
    //         status: iStatus
    //     }).then(({ data }) => {
    //         // DataStatistic
    //         UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "批量编辑按序生成" });
    //         message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
    //         console.log("Update completed!");
    //         console.log(data);
    //         this.GetExpressEnterData();
    //     });
    // }
    // 取消按钮响应
    MultiEditCancel = () => {
        this.setState({
            ...this.state,
            bMultiEditDlg: false
        });
    }
    // 选择单个文件按钮响应
    SelectSingleFile = event => {
        this.setState({
            ...this.state,
            selectedFile: event.target.files[0]
        });
        console.log(event.target.files[0]);
        // 获取上传的文件对象
        // const { files } = file.target;
        // console.log("files : ", files);
        // console.log("file = ", file);
    }
    // 选择多个文件按钮响应
    SelectMultipleFile = event => {
        this.arrFileWaitList = [];
        for (let i = 0; i < event.target.files.length; i++)
            this.arrFileWaitList.push(event.target.files[i].name);
        if (event.target.files.length > 20) {
            message.warning("一次最多上传20个文件!请重新选择");
            return;
        }
        this.setState({
            ...this.state,
            selectedFiles: event.target.files
        });
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = () => {
        const data = new FormData();
        // If file selected
        if (this.state.selectedFile) {
            data.append('profileImage', this.state.selectedFile, this.state.selectedFile.name);
            console.log("文件数据整理完成 : ", data);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName', fileName);
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        } else {
            // if file not selected throw error
            message.warning("请选择需要上传的文件！");
        }
    };
    // 多个文件上传按钮响应
    multipleFileUploadHandler = () => {
        let data = new FormData();
        let selectedFiles = this.state.selectedFiles;
        // If file selected
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
            }
            message.loading({ content: '文件上传中……', key: g_strMessageKey });
            axios.post('/aws-multiple-file-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                    action: 0,
                    oid: this.objCurrentExpressEnter.id
                }
            }).then((response) => {
                console.log('res', response);
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("单个文件大小不能超过2Mb!");
                        } else if ('LIMIT_UNEXPECTED_FILE' === response.data.error.code) {
                            message.warning("最多同时选择20个文件!");
                        } else {
                            // If not the given file type
                            console.log(response.data.error);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        message.success({ content: "文件上传成功！", key: g_strMessageKey, duration: 2 });
                        let strNewFileList = "";
                        for (let i = 0; i < fileName.locationArray.length; i++) {
                            strNewFileList += fileName.locationArray[i]
                            if (i !== fileName.locationArray.length - 1)
                                strNewFileList += ",";
                        }
                        if (this.objCurrentExpressEnter.s3_link !== null && this.objCurrentExpressEnter.s3_link !== "")
                            this.objCurrentExpressEnter.s3_link = strNewFileList + "," + this.objCurrentExpressEnter.s3_link;
                        else
                            this.objCurrentExpressEnter.s3_link = strNewFileList;
                        // if (this.arrTableData[this.state.selectedRowKeys[0]].s3_link !== null && this.arrTableData[this.state.selectedRowKeys[0]].s3_link !== "")
                        //     this.arrTableData[this.state.selectedRowKeys[0]].s3_link = strNewFileList + "," + this.arrTableData[this.state.selectedRowKeys[0]].s3_link;
                        // else
                        //     this.arrTableData[this.state.selectedRowKeys[0]].s3_link = strNewFileList;
                        // this.objCurrentExpressEnter = Object.assign({}, this.arrTableData[this.state.selectedRowKeys[0]]);
                        this.arrFileWaitList = [];
                        if (this.refs.file_upload) {
                            document.getElementById("file_upload").value = "";
                        }
                        this.setState({
                            ...this.state,
                            selectedFile: null,
                            selectedFiles: null
                        })
                    }
                }
            }).catch((error) => {
                // If another error
                console.log("error : ", error);
                message.warning(error);
            });
        } else {
            // if file not selected throw error
            message.warning("请选择需要上传的文件！");
        }
    };
    // 下载文件
    DownloadFile = (index) => {
        window.open("https://mouthingmanager.s3.amazonaws.com/" + this.objCurrentExpressEnter.s3_link.split(',')[index]);
    }
    // 删除文件
    DeleteFile = (index) => {
        if (this.bDeletingFile)
            return;
        this.bDeletingFile = true;
        this.setState({
            ...this.state,
            bFileDelete: true
        })

        let strTargetS3Link = this.objCurrentExpressEnter.s3_link;
        if (this.objCurrentExpressEnter.s3_link.indexOf(',') >= 0) {
            strTargetS3Link = this.objCurrentExpressEnter.s3_link.split(',')[index];
        }
        axios.post('/aws-profile-delete', {
            store_id: this.props.iStoreID,
            action: 0,
            oid: this.objCurrentExpressEnter.id,
            s3_link: strTargetS3Link,
            index: index
        }).then(({ data }) => {
            // DataStatistic
            UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "删除发票文件" });
            message.success({ content: '文件删除完成！', key: g_strMessageKey, duration: 2 });
            this.objCurrentExpressEnter.s3_link = data;
            // this.objCurrentExpressEnter = Object.assign({}, this.arrTableData[this.state.selectedRowKeys[0]]);
            this.bDeletingFile = false;
            this.setState({
                ...this.state,
                bFileDelete: false
            })
        });
    }
    // 切换表格页码
    TablePageChange = (page, pageSize) => {
        this.iCurrentPage = page;
        this.GetExpressEnterData();
    }
    // 切换每页显示的订单数量
    TableNumPerPageChange = (current, size) => {
        this.iNumPerPage = size;
        this.iCurrentPage = 1;
        this.GetExpressEnterData();
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        if (this.props.objUserInfo !== undefined) {
            this.GetExpressEnterData();
        }
    }
    // 渲染函数
    render() {
        /* 用户管理页面控制栏 */
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;
        // 物流公司过滤器
        // const menuExpressTypeFilter = (
        //     <Menu>
        //         {this.arrExpressTypeFilter.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchExpressTypeFilter.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 物流公司下拉框
        // const menuExpressType = (
        //     <Menu>
        //         {this.arrExpressType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchExpressType.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 运输方式下拉菜单
        const menuTransitType = (
            <Menu>
                {this.arrTransitType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 包裹状态过滤器
        const menuStatusFilter = (
            <Menu>
                {this.arrStatusFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStatusFilter.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 包裹状态变更
        const menuStatusChange = (
            <Menu>
                {this.arrStatusChange.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchExpressStatus.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        strAreaStyle = "store_manage_contents store_manage_top_space_double";
        uiTitleArea = (
            <div>
                <div className="store_contents_title">
                    <div className="transit_price_title" >{"包裹状态"}</div>
                    <Dropdown className="order_filter_dropdown" trigger={['click']} overlay={menuStatusFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button" style={{ maxWidth: '100px' }}>
                            <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    {/* <div className="order_filter_title">用户信息</div>
                    <Input className="order_search_input" ref="user_search" defaultValue="" /> */}
                    <div className="order_filter_title">物流单号</div>
                    <Input className="order_search_input" ref="enter_no_search" defaultValue="" />
                    <div className="order_filter_title">物品/备注信息</div>
                    <Input className="order_search_input" ref="item_search" defaultValue="" />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExpressOrderLookup} >
                        <Icon type="search" /></Button>
                </div >
                <div className="store_contents_title_sec">
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllExpressEnter} >
                        显示全部<Icon type="redo" /></Button>
                    <Button className="manage_contents_title_margin new_button_general_style"
                        onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />预报</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style" onClick={this.ExportExcelFile}
                        disabled={this.state.btnControlDisable[5]}>
                        <Icon type="export" />导出Excel</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 4)} disabled={this.state.btnControlDisable[5]}>
                        <Icon type="inbox" />提交运单</Button>
                </div>
            </div>
        );
        // M站针对加载状态时的界面处理
        if (this.props.bMStation) {
            // uiTitleArea = null;
            // strLoadingStyle = "manage_empty_loading_m";
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            // let uiPagination = (
            //     <Pagination showQuickJumper={false} position={'bottom'}
            //         defaultPageSize={10} pageSizeOptions={['10', '20', '50', '100']}
            //         total={this.iTotalOrderNum}
            //         showTotal={total => `Total ${total} 条 包裹记录`}
            //         showSizeChanger onShowSizeChange={this.TableNumPerPageChange()}
            //         defaultCurrent={1}
            //     />
            // );
            let paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.iTotalOrderNum} 条 包裹记录`,
                defaultPageSize: 10,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.iTotalOrderNum,
                position: 'bottom',
                onChange: this.TablePageChange.bind(this),
                onShowSizeChange: this.TableNumPerPageChange.bind(this)
            };
            // 用户信息编辑弹窗
            let strTitle = "包裹预报 - " + this.props.objUserInfo.name + "（ " + this.props.objUserInfo.storage_code + " ）";
            if (this.state.iDlgType === 1)
                strTitle = "编辑包裹 - " + this.props.objUserInfo.name + "（ " + this.props.objUserInfo.storage_code + " ）";

            let bDefaultChecked = [false, false];
            if (this.state.iCurrentStatus === 1)
                bDefaultChecked[0] = true;
            if (this.state.iPackage)
                bDefaultChecked[1] = true;

            let uiModal = null;
            if (this.state.bShowDlg && this.state.iDlgType !== 2) {
                // 包裹编号中的物品信息列表
                let uiItemInfoList = null;
                if (this.objCurrentExpressEnter.express_info.item_info !== undefined)
                    if (this.objCurrentExpressEnter.express_info.item_info !== null) {
                        if (this.objCurrentExpressEnter.express_info.item_info.length > 0) {
                            uiItemInfoList = (
                                this.objCurrentExpressEnter.express_info.item_info.map((item, indexItem) => {
                                    let strItemBlockStyle = "express_item_info_block";
                                    if (indexItem % 2 === 0)
                                        strItemBlockStyle = "express_item_info_block_light";
                                    return (
                                        <div key={indexItem} className={strItemBlockStyle}>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title">中文品名：</div>
                                                <Input style={{ width: '240px' }} ref={"item_cn_" + indexItem}
                                                    defaultValue={item.cn} value={item.cn}
                                                    onChange={this.ItemInfoChange.bind(this, indexItem, 0)} />
                                                <div className="express_enter_edit_title">英文品名：</div>
                                                <Input style={{ width: '240px' }} ref={"item_en_" + indexItem}
                                                    defaultValue={item.en} value={item.en}
                                                    onChange={this.ItemInfoChange.bind(this, indexItem, 1)} />
                                                <div className="express_enter_edit_title_short">数量：</div>
                                                <Input className="express_enter_item_info_input_short" ref={"item_num_" + indexItem}
                                                    defaultValue={item.num} value={item.num}
                                                    onChange={this.ItemInfoChange.bind(this, indexItem, 2)} />
                                                <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                                    onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                                    <Icon type="delete" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            );
                        }
                    }
                let uiOrderStatus = null;
                let uiDateInfo = null;
                let uiCreateDate = null;
                let uiPackageInfo = null;
                if (this.state.iDlgType === 1) {
                    uiCreateDate = (
                        <Input className="user_edit_info_input" ref="order_date" disabled
                            defaultValue={this.objCurrentExpressEnter.order_date.split('T')[0]}
                            value={this.objCurrentExpressEnter.order_date.split('T')[0]} />
                    );

                    if (this.objCurrentExpressEnter.package_no !== null && this.objCurrentExpressEnter.package_no !== "") {
                        uiPackageInfo = (
                            <Input className="user_edit_info_input" ref="package_no" disabled
                                defaultValue={this.objCurrentExpressEnter.package_no}
                                value={this.objCurrentExpressEnter.package_no} />
                        );
                    } else {
                        uiPackageInfo = (
                            <Input className="user_edit_info_input" ref="package_no" disabled
                                defaultValue={'暂无'} value={'暂无'} />
                        );
                    }
                    uiOrderStatus = (
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">包裹状态：</div>
                            <Dropdown /*className="order_detail_dropdown"*/ trigger={['click']}
                                overlay={menuStatusChange} placement="bottomLeft" disabled>
                                <Button className="transit_type_dropdown_button" style={{ minWidth: '200px', marginRight: '0px' }}>
                                    <div className="order_details_dropdown_text">{this.arrStatusChange[this.state.iCurrentStatus]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="express_enter_edit_title">运单编号：</div>
                            {uiPackageInfo}
                            <div className="express_enter_edit_title">创建日期：</div>
                            {uiCreateDate}
                        </div>
                    );
                }
                let bEditDisable = true;
                if (/*this.objCurrentExpressEnter.transit_type === 0 &&*/ this.objCurrentExpressEnter.status <= 1)
                    bEditDisable = false;
                uiModal = (
                    <Modal title={strTitle} width={950} visible={this.state.bShowDlg}
                        okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                        <div className="no_mouse_select">
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>包裹编号：</div>
                                <Input className="user_edit_info_input" ref="enter_no" disabled
                                    defaultValue={this.objCurrentExpressEnter.enter_no} value={this.objCurrentExpressEnter.enter_no} />
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>货运方式：</div>
                                <Dropdown overlay={menuTransitType} trigger={['click']} disabled={bEditDisable}>
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.arrTransitType[this.state.iTransitType]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>物流单号：</div>
                                <Input className="user_edit_info_input" ref="express_order" disabled={bEditDisable}
                                    defaultValue={this.objCurrentExpressEnter.express_order} />
                                {/* <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>用户编码：</div>
                                <Input className="user_edit_info_input" ref="storage_code" disabled
                                    defaultValue={this.props.objUserInfo.storage_code} value={this.props.objUserInfo.storage_code} />
                                <div className="express_enter_edit_title">用户名称：</div>
                                <Input className="user_edit_info_input" ref="user_name" disabled
                                    defaultValue={this.props.objUserInfo.name} value={this.props.objUserInfo.name} /> */}
                            </div>
                            {/* <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>货物重量：</div>
                                <Input className="user_edit_info_input" ref="item_weight" addonAfter={"kg"} disabled={bEditDisable}
                                    defaultValue={this.objCurrentExpressEnter.express_info.item_weight} />
                            </div> */}
                            {uiOrderStatus}
                            {uiDateInfo}
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title">备注信息：</div>
                                <Input className="express_enter_item_info_input_full" ref="comments"
                                    defaultValue={this.objCurrentExpressEnter.comments} />
                            </div>
                            <div className="user_edit_info_row">
                                {/* <div className="express_enter_edit_title">
                                    {"货物体积：" + parseFloat(this.objCurrentExpressEnter.express_info.item_value).toFixed(2) + " cm3"}</div>
                                <div className="express_enter_edit_title">
                                    {"计费重量：" + parseFloat(this.objCurrentExpressEnter.express_info.price_weight).toFixed(2) + " kg"}</div> */}
                                <div className="express_enter_express_info_add_button express_enter_express_info_add_button_left"
                                    onClick={this.AddNewItemInfo}>+ 物品信息</div>
                                <div className="express_enter_express_info_clean_button" onClick={this.ClearItemInfo}>
                                    <Icon type="delete" theme="filled" style={{ marginRight: '10px' }} />清空</div>
                            </div>
                            {uiItemInfoList}
                        </div>
                    </Modal>
                );
            }
            if (this.state.iDlgType === 2) {
                let strDeletePackages = "";
                for (let i = 0; i < this.arrSelectedExpressEnter.length; i++) {
                    strDeletePackages += this.arrSelectedExpressEnter[i].express_order;
                    if (i !== this.arrSelectedExpressEnter.length - 1)
                        strDeletePackages += ", ";
                }
                uiModal = (
                    <Modal width={450} title={"删除包裹信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>
                                确定删除选中的包裹？
                                <br /><br />
                                物流单号：
                                <br />
                                {strDeletePackages}
                            </div>
                        </div>
                    </Modal>
                );
            }
            let uiTable = (
                <Table style={{ marginLeft: '30px', marginRight: '30px', marginTop: '10px', width: '95%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({
                        onClick: () => { this.selectRow(record); },
                        onDoubleClick: () => { this.DoubleClickRow(record); }
                    })} />
            );

            // 批量编辑对话框
            let uiMultiEditDlg = null;
            if (this.state.bMultiEditDlg) {
                uiMultiEditDlg = (
                    <Modal width={950} title={"批量修改"} visible={this.state.bMultiEditDlg} okText="确定" cancelText="取消"
                        onOk={this.MultiEditConfirm} onCancel={this.MultiEditCancel} >
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">包裹编号：</div>
                            <Input className="user_edit_info_input" ref="multi_enter_no" defaultValue={""} />
                            <div className="express_enter_edit_title">收件人名：</div>
                            <Input className="user_edit_info_input" ref="multi_receiver" defaultValue={""} />
                            <div className="express_enter_edit_title">用户编码：</div>
                            <Input className="user_edit_info_input" ref="multi_storage_code" defaultValue={""} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">合箱编码：</div>
                            <Input className="user_edit_info_input" ref="multi_package_no" disabled={true} defaultValue={""} />
                            <div className="express_enter_edit_title">联系方式：</div>
                            <Input className="user_edit_info_input" ref="multi_phone" defaultValue={""} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">备注信息：</div>
                            <Input className="express_enter_item_info_input_long" ref="multi_comments"
                                defaultValue={""} />
                            {/* <Checkbox className="express_enter_checkbox" onChange={this.StatusChange.bind(this)} checked={bDefaultChecked[0]}>已签收</Checkbox>
                            <Checkbox className="express_enter_checkbox" onChange={this.PackageChange.bind(this)} checked={bDefaultChecked[1]}>已合箱</Checkbox> */}
                        </div>
                    </Modal>
                );
            }

            // M站布局
            if (this.props.bMStation) {
                // strAreaStyle = "store_manage_contents_m";
                // paginationProps = {
                //     showSizeChanger: true,
                //     showQuickJumper: false,
                //     showTotal: () => `共 ${this.arrTableData.length} 条 入仓信息`,
                //     defaultPageSize: 10,
                //     defaultCurrent: this.state.iCurrentPage,
                //     pageSizeOptions: ['10', '20', '30', '40', '50'],
                //     total: this.arrTableData.length,
                //     position: 'bottom'
                // };
                // let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
                // uiTitleArea = (
                //     <div>
                //         <div className="store_contents_title_m" >
                //             <div className="order_filter_title">关键字</div>
                //             <Input className="order_search_input" ref="search" defaultValue="" />
                //             <Button className="store_manager_control_button" type="primary" onClick={this.ExpressOrderLookup} >
                //                 <Icon type="search" />
                //             </Button>
                //         </div >
                //         <div className="store_contents_title_sec_m" >
                //             <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllExpressEnter} >
                //                 显示全部
                //             </Button>
                //             <div className="transit_price_title" >{"包裹状态"}</div>
                //             <Dropdown className="order_detail_dropdown" trigger={['click']}
                //                 overlay={menuStatusFilter} placement="bottomLeft">
                //                 <Button className="transit_type_filter_dropdown_button">
                //                     <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                //                     <Icon type="caret-down" />
                //                 </Button>
                //             </Dropdown>
                //         </div>
                //         <div className="store_contents_title_thd_m" >
                //             <Button className="store_manager_control_button" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)}
                //                 disabled={this.state.btnControlDisable[0]}>
                //                 <Icon type="plus" /></Button>
                //             <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                //                 <Icon type="edit" /></Button>
                //             <Button className="store_manager_control_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                //                 <Icon type="close" /></Button>
                //         </div>
                //     </div>
                // );
                // uiTable = (
                //     <div className="express_enter_table_area_m">
                //         <Table style={{ width: '100%', height: '100%', overflowX: 'scroll', overflowY: 'scroll' }} bordered
                //             rowSelection={rowSelection} pagination={paginationProps}
                //             columns={this.tableHead} dataSource={this.arrTableData}
                //             onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                //     </div>

                // );
            }

            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                    {uiMultiEditDlg}
                    {/* {uiPackageCombineDlg} */}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default ExpressEnterJW;
