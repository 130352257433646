import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';
// import Clipboard from 'react-clipboard.js';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Popover from 'antd/es/popover';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/UI_Module/auto_order_management.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/express_enter.css';
import '../../../styles/Background/UI_Module/transit_price.css';
import '../../../styles/Background/UI_Module/order_management.css';
import '../../../styles/Background/UI_Module/ca_cn_order.css';

import { UserActiveUpdate } from "../../../functions/DataStatistic.js";

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '运单编号', dataIndex: 'package_no', width: 125, ellipsis: true },
    { title: '收件信息', dataIndex: 'receiver_info', width: 175, ellipsis: true },
    { title: '收货仓库', dataIndex: 'storage_str', width: 100, ellipsis: true },
    { title: '货运方式', dataIndex: 'transit_way_str', width: 100, ellipsis: true },
    { title: '运输路线', dataIndex: 'package_type_str', width: 100, ellipsis: true },
    // { title: '计费重量', dataIndex: 'price_weight_str', width: 125, ellipsis: true },
    { title: '费用总计(CAD)', dataIndex: 'total_price_str', width: 100, ellipsis: true },
    { title: '运单状态', dataIndex: 'status_str', width: 125, ellipsis: true },
    { title: '创建日期', dataIndex: 'order_date_str', width: 125, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true }
];
const arrTableHeader_m = [
    { title: '运单编码', dataIndex: 'package_no', width: 125 },
    { title: '运单状态', dataIndex: 'status_check', width: 100 },
    { title: '货运方式', dataIndex: 'transit_way_str', width: 100 },
    { title: '货物品类', dataIndex: 'package_type_str', width: 100 },
    { title: '总重量', dataIndex: 'price_weight_str', width: 125 },
    { title: '总费用', dataIndex: 'total_price_str', width: 125 },
    { title: '总货值', dataIndex: 'total_value_str', width: 125 }
];

// csv转sheet对象
// function csv2sheet(csv) {
//     var sheet = {}; // 将要生成的sheet
//     csv = csv.split('\n');
//     csv.forEach(function (row, i) {
//         row = row.split(',');
//         if (i == 0) sheet['!ref'] = 'A1:' + String.fromCharCode(65 + row.length - 1) + (csv.length - 1);
//         row.forEach(function (col, j) {
//             sheet[String.fromCharCode(65 + j) + (i + 1)] = { v: col };
//         });
//     });
//     return sheet;
// }
// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}
// 传入csv，执行后就会弹出下载框
// function exportExcel(csv) {
//     var sheet = csv2sheet(csv);
//     var blob = sheet2blob(sheet);
//     openDownloadDialog(blob, '导出.xlsx');
// }

class PackageCombineSN extends Component {
    constructor(props) {
        super();

        this.strToday = new Date();

        this.state = {
            btnControlDisable: [false, true, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iStatus: 0,
            bItemValueChange: false,
            iTransitType: 0,
            iPackageType: 0,
            iShipDate: 0,
            iStatusFilter: 0,
            iStorageFilter: 0,
            iStorage: 0,
            bViewQRCodeDlg: false,
            bPackageInfoChange: false,
            bViewItemInfoDlg: false,
            selectedFile: null,
            selectedFiles: null,
            bFileDelete: false,
            bFeeChange: false,
            bSelectReceiverDlg: false,
            bReceiverFilter: false
        };
        // 成员变量
        this.arrTableData = [];  // 用于显示的数组
        this.arrTableDataOge = [];  // 原始运单数据
        this.objCurrentPackageCombine = {};
        this.arrCurrentExpressInfo = [];
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;

        this.arrExpressTypeFilter = ["全部", "其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];
        this.iExpressTypeFilter = 0;
        this.arrExpressType = ["其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];

        this.strYear = this.strToday.toISOString().split('-')[0];
        this.strMonth = this.strToday.toISOString().split('-')[1];
        this.strDay = this.strToday.toISOString().split('-')[2].split('T')[0];

        this.strKeyWord = "";
        this.arrTransitType = ["海运", "空运"];
        this.arrPackageType = [];
        this.arrOceanTransitLine = ['集运', '特货', '食品']
        this.arrAirTransitLine = ['普货', '敏感', '集运（不包税）'];
        this.arrShipDate = ["月中(15号)", "月底(30号)"];
        this.fCurrentTotalWeight = 0.0;
        this.fCurrentTotalPrice = 0.0;
        this.fCurrentTotalValue = 0.0;
        this.arrStatus = ["待合箱", "合箱中", "已合箱", "已发货"];
        this.arrStatusFilter = ["全部", "待合箱", "合箱中", "已合箱", "已发货"];
        this.bViewQRCodeDlg = false;

        this.arrCurrentItemInfo = [];
        this.arrSelectedPackageCombine = [];
        this.arrSelectedPackageCombineItemInfo = [];

        this.arrFileWaitList = [];
        this.bDeletingFile = false;

        this.bPhotoFee = false;
        this.bFirmFee = false;
        this.bDestroyFee = false;
        this.bAfterDeliveryFee = false;

        this.strCurrency = "$";
        this.iTransitType = 0;
        this.iStorageFilter = 0;
        this.arrStorageFilter = ['全部', '总店', '维多利亚'];
        this.arrStorage = ['总店', '维多利亚'];

        this.arrReceiverShow = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 双击某行的响应函数
    DoubleClickRow = (record) => {
        const selectedRowKeys = [record.key];
        this.SelectedRowStateSetting(selectedRowKeys, true);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys, bDoubleClick) => {
        let btnControlDisable = [];
        this.arrSelectedPackageCombine = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true, true];
                this.objCurrentPackageCombine = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentPackageCombine = Object.assign({}, this.arrTableData[i]);
                        this.arrSelectedPackageCombine.push(this.arrTableData[i]);
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, false, false];
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedPackageCombine.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
        if (bDoubleClick)
            this.ShowDlg(1);
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.receiver_name !== undefined && this.refs.receiver_name !== null) {
                this.refs.package_no.state.value = "";
                this.refs.comments.state.value = "";
                this.refs.receiver_name.state.value = "";
                this.refs.receiver_phone.state.value = "";
                this.refs.receiver_postcode.state.value = "";
                this.refs.receiver_address.state.value = "";
                // this.refs.delivery_fee.state.value = 0.0;
                this.refs.operate_fee.state.value = 0.0;
                this.refs.firm_fee.state.value = 0;
                // this.refs.destroy_fee.state.value = 0;
                // this.refs.after_delivery_fee.state.value = 0.0;
                this.bPhotoFee = false;
                this.bFirmFee = false;
                this.bDestroyFee = false;
                this.bAfterDeliveryFee = false;
            }
            this.arrCurrentExpressInfo = [];
            this.objCurrentPackageCombine = {
                package_no: '', package_type: 0, transit_way: 0, total_price: 0.0, total_value: 0.0, total_weight: 0.0, status: 0, comments: '',
                value_weight: 0.0, length: 0.0, width: 0.0, height: 0.0, price_weight: 0.0, ship_date: 0, receiver_name: '', receiver_postcode: '', receiver_address: '', receiver_phone: '', auto_order_no: '', delivery_fee: '0.0', operate_fee: '0.0', firm_fee: '0'
                // , destroy_fee: '0', after_delivery_fee: '0.0'
            };
            if (this.props.arrExpressEnter.length > 0) {
                this.arrCurrentExpressInfo = this.props.arrExpressEnter;
                this.objCurrentPackageCombine.transit_way = this.arrCurrentExpressInfo[0].transit_type;
            }
            this.SwitchTransitType(this.arrCurrentExpressInfo[0].transit_type);
            // this.SwitchTransitType(0);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0,
                iStorage: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.receiver_name !== undefined && this.refs.receiver_name !== null) {
                this.refs.package_no.state.value = this.objCurrentPackageCombine.package_no;
                this.refs.comments.state.value = this.objCurrentPackageCombine.comments;
                this.refs.auto_order_no.state.value = this.objCurrentPackageCombine.auto_order_no;
                this.refs.operate_fee.state.value = this.objCurrentPackageCombine.operate_fee;
                this.refs.firm_fee.state.value = this.objCurrentPackageCombine.firm_fee;
                this.refs.receiver_name.state.value = this.objCurrentPackageCombine.receiver_name;
                this.refs.receiver_phone.state.value = this.objCurrentPackageCombine.receiver_phone;
                this.refs.receiver_postcode.state.value = this.objCurrentPackageCombine.receiver_postcode;
                this.refs.receiver_address.state.value = this.objCurrentPackageCombine.receiver_address;
            }
            if (this.objCurrentPackageCombine.receiver_id !== null && this.objCurrentPackageCombine.receiver_id !== undefined &&
                this.objCurrentPackageCombine.reciever_id !== "") {
                this.objCurrentReceiver = {
                    id: this.objCurrentPackageCombine.receiver_id,
                    name: this.objCurrentPackageCombine.receiver_id.receiver_name,
                    phone: this.objCurrentPackageCombine.receiver_phone,
                    postcode: this.objCurrentPackageCombine.receiver_postcode,
                    address: this.objCurrentPackageCombine.receiver_address
                }
            }
            this.bPhotoFee = false;
            if (parseFloat(this.objCurrentPackageCombine.photo_fee) > 0)
                this.bPhotoFee = true;
            this.bFirmFee = false;
            if (parseInt(this.objCurrentPackageCombine.firm_fee, 10) > 0)
                this.bFirmFee = true;
            this.bDestroyFee = false;
            if (parseInt(this.objCurrentPackageCombine.destroy_fee, 10) > 0)
                this.bDestroyFee = true;
            this.bAfterDeliveryFee = false;
            if (parseFloat(this.objCurrentPackageCombine.after_delivery_fee) > 0.0)
                this.bAfterDeliveryFee = true;
            this.SwitchTransitType(this.objCurrentPackageCombine.transit_way);
            axios.post('/UpdatePackageCombineExpressInfo', {
                objPackageCombine: this.objCurrentPackageCombine
            }).then(({ data }) => {
                this.objCurrentPackageCombine.express_info = data;
                this.arrCurrentExpressInfo = [];
                if (this.objCurrentPackageCombine.express_info.length > 0) {
                    for (let i = 0; i < this.objCurrentPackageCombine.express_info.length; i++) {
                        this.objCurrentPackageCombine.express_info[i].express_info = JSON.parse(this.objCurrentPackageCombine.express_info[i].express_info);
                        this.arrCurrentExpressInfo.push(this.objCurrentPackageCombine.express_info[i]);
                    }
                }
                if (this.objCurrentPackageCombine.express_info === null || this.objCurrentPackageCombine.express_info === "")
                    this.arrCurrentExpressInfo = [];

                if (this.objCurrentPackageCombine.ship_date !== 0 && this.objCurrentPackageCombine.ship_date !== 1)
                    this.objCurrentPackageCombine.ship_date = 0;
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bShowDlg: true,
                    iStatus: this.objCurrentPackageCombine.status,
                    iTransitType: this.objCurrentPackageCombine.transit_way,
                    iShipDate: this.objCurrentPackageCombine.ship_date,
                    iPackageType: this.objCurrentPackageCombine.package_type,
                    iStorage: this.objCurrentPackageCombine.storage
                });
            }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 获取新输入的信息
    GetNewInputInfo = () => {
        // 运单编码
        let strPackageNo = this.refs.package_no.state.value;
        if (strPackageNo === null || strPackageNo.trim() === "") {
            strPackageNo = "";
            if (this.state.iDlgType === 1) {
                message.warning("运单编号不能为空！"); return -1;
            }
        }
        // 收件人信息
        let strReceiverName = this.refs.receiver_name.state.value;
        let strReceiverPhone = this.refs.receiver_phone.state.value;
        let strReceiverPostcode = this.refs.receiver_postcode.state.value;
        let strReceiverAddress = this.refs.receiver_address.state.value;
        if (strReceiverName === undefined || strReceiverName === null || strReceiverName === "") {
            message.warning("收件人名不能为空！"); return -1;
        }
        if (strReceiverPhone === undefined || strReceiverPhone === null || strReceiverPhone === "") {
            message.warning("收件人联系方式不能为空！"); return -1;
        }
        if (strReceiverPostcode === undefined || strReceiverPostcode === null || strReceiverPostcode === "") {
            message.warning("收件人邮政编码不能为空！"); return -1;
        }
        if (strReceiverAddress === undefined || strReceiverAddress === null || strReceiverAddress === "") {
            message.warning("收件地址不能为空！"); return -1;
        }
        // 包裹列表信息
        let iExpressEnterCheck = this.ExpressEnterInfoCheck(0);
        if (iExpressEnterCheck === -1)
            return -1;
        if (this.arrCurrentExpressInfo.length <= 0) {
            this.arrCurrentExpressInfo = [];
        }
        this.objCurrentPackageCombine.package_no = strPackageNo;
        this.objCurrentPackageCombine.status = this.state.iStatus;
        this.objCurrentPackageCombine.storage = this.state.iStorage;
        this.objCurrentPackageCombine.transit_way = this.state.iTransitType;
        this.objCurrentPackageCombine.ship_date = this.state.iShipDate;
        this.objCurrentPackageCombine.package_type = this.state.iPackageType;
        this.objCurrentPackageCombine.comments = this.refs.comments.state.value;
        this.objCurrentPackageCombine.receiver_name = strReceiverName;
        this.objCurrentPackageCombine.receiver_phone = strReceiverPhone;
        this.objCurrentPackageCombine.receiver_postcode = strReceiverPostcode;
        this.objCurrentPackageCombine.receiver_address = strReceiverAddress;
        this.objCurrentPackageCombine.auto_order_no = this.refs.auto_order_no.state.value;
        this.objCurrentPackageCombine.operate_fee = this.refs.operate_fee.state.value;
        this.objCurrentPackageCombine.firm_fee = this.refs.firm_fee.state.value;
        if (this.objCurrentReceiver !== undefined && this.objCurrentReceiver !== null && this.objCurrentReceiver !== "") {
            this.objCurrentPackageCombine.receiver_id = this.objCurrentReceiver.id;
            // console.log("current receiver : ", this.objCurrentReceiver, this.objCurrentPackageCombine.receiver_id);
        }
        // else {
        //     console.log("No Clients Selected!");
        // }
        if (this.state.iStatus >= 2) {
            message.warning("已合箱完成，无法更改，请联系客服！")
            return -1;
        }
        return 1;
    }
    // 包裹信息复查
    ExpressEnterInfoCheck = (iCheck) => {
        // 物流单号不能为空
        for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
            if (this.arrCurrentExpressInfo[i].express_order === null || this.arrCurrentExpressInfo[i].express_order === "") {
                message.warning("包裹的物流单号不能为空！"); return -1;
            }
        }
        // 检查重复物流单号
        for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
            for (let j = 0; j < this.arrCurrentExpressInfo.length; j++) {
                if (i !== j && this.arrCurrentExpressInfo[i].express_order === this.arrCurrentExpressInfo[j].express_order) {
                    message.warning("有重复的包裹物流单号，请检查！");
                    return -1;
                }
            }
        }
        if (iCheck === 1) {
            // 检查用户信息
            if (this.arrCurrentExpressInfo.length > 1) {
                let strUserInfo = this.arrCurrentExpressInfo[0].storage_code;
                for (let i = 1; i < this.arrCurrentExpressInfo.length; i++) {
                    if (this.arrCurrentExpressInfo[i].storage_code !== strUserInfo) {
                        console.log("express index = ", i);
                        console.log("this.arrCurrentExpressInfo[i].storage_code = ", this.arrCurrentExpressInfo[i].storage_code);
                        console.log("strUserInfo = ", strUserInfo);
                        message.warning("包裹不能来自不同的用户，请检查！");
                        return -1;
                    }
                }
            }
            // 检查货运方式
            if (this.arrCurrentExpressInfo.length > 1) {
                let iTransit = this.arrCurrentExpressInfo[0].transit_type;
                for (let i = 1; i < this.arrCurrentExpressInfo.length; i++) {
                    if (this.arrCurrentExpressInfo[i].transit_type !== iTransit) {
                        // message.warning("包裹的货运方式不同，请检查！");
                        message.warning({ content: "包裹的货运方式不同，请检查！", key: g_strMessageKey, duration: 2 });
                        return -1;
                    }
                }
            }
        }
        return 1;
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.objCurrentPackageCombine.transit_way === 0) {
            message.warning("海运订单无法编辑，如有信息需要修改，请联系管理员！");
            return;
        }
        this.objCurrentPackageCombine.store_id = this.props.iStoreID;
        delete this.objCurrentPackageCombine.key;
        delete this.objCurrentPackageCombine.status_str;
        delete this.objCurrentPackageCombine.package_type_str;
        delete this.objCurrentPackageCombine.transit_way_str;
        delete this.objCurrentPackageCombine.total_price_str;
        delete this.objCurrentPackageCombine.total_value_str;
        delete this.objCurrentPackageCombine.price_weight_str;
        delete this.objCurrentPackageCombine.receiver_info;
        delete this.objCurrentPackageCombine.order_date_str;
        delete this.objCurrentPackageCombine.user_info;
        delete this.objCurrentPackageCombine.storage_str;
        // 新建
        if (this.state.iDlgType === 0) {
            delete this.objCurrentPackageCombine.id;
            if (this.GetNewInputInfo() === -1)
                return;
            console.log("new pc info : ", this.objCurrentPackageCombine);
            message.loading({ content: '正在新增运单信息……', key: g_strMessageKey });
            if (this.arrCurrentExpressInfo.length > 0) {
                let arrExpressOrder = [];
                for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                    arrExpressOrder.push(this.arrCurrentExpressInfo[i].express_order);
                }
                axios('/GetPackageUserCode', { params: { arrExpressOrder: arrExpressOrder, store_id: this.props.iStoreID } })
                    .then(({ data }) => {
                        this.arrCurrentExpressInfo = data;
                        let iExpressEnterCheck = this.ExpressEnterInfoCheck(1);
                        if (iExpressEnterCheck === -1)
                            return;
                        this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                        axios.post('/NewPackageCombine', {
                            objPackageCombine: this.objCurrentPackageCombine,
                            user_name: this.props.objUserInfo.name,
                            user_code: this.props.objUserInfo.storage_code
                        }).then(({ data }) => {
                            this.setState({
                                ...this.state,
                                bShowDlg: false
                            })
                            this.props.ResetExpressOrderInfo();
                            if (data.status === 1) {
                                this.arrCurrentExpressInfo = [];
                                // DataStatistic
                                UserActiveUpdate(4, { active: "商家后台: 运单管理操作", action: "新建" });
                                this.GetPackageCombineData();
                            } else {
                                message.warning({ content: "运单编号已存在！请重新输入", key: g_strMessageKey, duration: 2 });
                            }
                        }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                axios.post('/NewPackageCombine', {
                    objPackageCombine: this.objCurrentPackageCombine,
                    user_name: this.props.objUserInfo.name,
                    user_code: this.props.objUserInfo.storage_code
                }).then(({ data }) => {
                    if (data.status === 1) {
                        this.arrCurrentExpressInfo = [];
                        // DataStatistic
                        UserActiveUpdate(4, { active: "商家后台: 运单信息管理操作", action: "新建" });
                        this.GetPackageCombineData();
                    } else {
                        message.warning({ content: "运单编码已存在！请重新输入", key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
            }
        }
        // 编辑
        if (this.state.iDlgType === 1) {
            if (this.GetNewInputInfo() === -1)
                return;
            console.log("edit pc : ", this.objCurrentPackageCombine);
            message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
            if (this.arrCurrentExpressInfo.length > 0) {
                let arrExpressOrder = [];
                for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                    arrExpressOrder.push(this.arrCurrentExpressInfo[i].express_order);
                }
                axios('/GetPackageUserCode', { params: { arrExpressOrder: arrExpressOrder, store_id: this.props.iStoreID } })
                    .then(({ data }) => {
                        for (let j = 0; j < this.arrCurrentExpressInfo.length; j++) {
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].express_order === this.arrCurrentExpressInfo[j].express_order) {
                                    this.arrCurrentExpressInfo[j] = data[i];
                                    break;
                                }
                            }
                        }
                        let iExpressEnterCheck = this.ExpressEnterInfoCheck(1);
                        if (iExpressEnterCheck === -1)
                            return;
                        this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                        axios.post('/UpdatePackageCombine', {
                            objPackageCombine: this.objCurrentPackageCombine,
                            // user_id: this.props.objUserInfo.id,
                            user_name: this.props.objUserInfo.name,
                            user_code: this.props.objUserInfo.storage_code
                        }).then(({ data }) => {
                            this.GetPackageCombineData();
                            // DataStatistic
                            UserActiveUpdate(4, { active: "商家后台:运单记录管理操作", action: "编辑" });
                        }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                axios.post('/UpdatePackageCombine', {
                    objPackageCombine: this.objCurrentPackageCombine,
                    // user_id: this.props.objUserInfo.id,
                    user_name: this.props.objUserInfo.name,
                    user_code: this.props.objUserInfo.storage_code
                }).then(({ data }) => {
                    this.GetPackageCombineData();
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台:运单记录管理操作", action: "编辑" });

                }).catch(function (error) { console.log(error); });
            }
        }
        // 删除
        if (this.state.iDlgType === 2) {
            message.loading({ content: '运单删除中……', key: g_strMessageKey });
            if (this.arrSelectedPackageCombine.length > 1) {
                let arrPackageCombine = [];
                for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
                    arrPackageCombine.push(this.arrSelectedPackageCombine[i].id);
                }
                axios.post('/DeletePackageCombines', { arrPackageCombine: arrPackageCombine }).then(
                    ({ data }) => {
                        console.log("批量删除完成！", data);
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                        this.GetPackageCombineData();
                    }).catch(function (error) { console.log(error); });
            } else {
                axios.post('/DeletePackageCombine', { id: this.objCurrentPackageCombine.id }).then(
                    ({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                        this.GetPackageCombineData();
                    }).catch(function (error) { console.log(error); });
            }
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.props.ResetExpressOrderInfo();
        this.objCurrentPackageCombine = {};
        this.arrCurrentExpressInfo = [];
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true, true]
        });
    };
    // 查看运单二维码
    ViewQRCode = (index, bShow) => {
        if (index >= 0)
            console.log("View QRCode : ", this.arrTableData[index].package_no, bShow);
        this.bViewQRCodeDlg = bShow;
        this.setState({
            ...this.state,
            bViewQRCodeDlg: bShow
        });
    }
    // 从数据库获取当前的数据结构
    GetPackageCombineData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetPackageCombine', {
            params: {
                user_name: this.props.objUserInfo.name,
                storage_code: this.props.objUserInfo.storage_code,
                search: this.strKeyWord,
                store_id: this.props.iStoreID,
                status: this.iStatusFilter,
                storage: this.iStorageFilter
            }
        }).then(({ data }) => {
            console.log("Get PackageCombine: ", data);
            this.arrTableData = data;
            this.arrTableDataOge = data;
            if (this.arrTableData.length > 0) {
                for (let i = 0; i < this.arrTableData.length; i++) {
                    this.arrTableData[i].key = i;
                    if (this.arrTableData[i].express_info === null || this.arrTableData[i].express_info === "")
                        this.arrTableData[i].express_info = [];
                    else {
                        this.arrTableData[i].express_info = JSON.parse(this.arrTableData[i].express_info);
                    }
                    this.arrTableData[i].user_info = this.arrTableData[i].express_info[0].user_name + " / " + this.arrTableData[i].express_info[0].storage_code;
                    this.arrTableData[i].transit_way_str = this.arrTransitType[this.arrTableData[i].transit_way];
                    this.arrTableData[i].storage_str = this.arrStorage[this.arrTableData[i].storage];
                    if (this.arrTableData[i].transit_way === 0)
                        this.arrTableData[i].package_type_str = this.arrOceanTransitLine[this.arrTableData[i].package_type];
                    else
                        this.arrTableData[i].package_type_str = this.arrAirTransitLine[this.arrTableData[i].package_type];
                    this.arrTableData[i].total_price_str = "$ " + parseFloat(this.arrTableData[i].total_price).toFixed(2);
                    this.arrTableData[i].total_value_str = parseFloat(this.arrTableData[i].total_value).toFixed(2) + " cm3";
                    this.arrTableData[i].price_weight_str = parseFloat(this.arrTableData[i].price_weight).toFixed(2) + " kg";
                    this.arrTableData[i].status_str = this.arrStatus[this.arrTableData[i].status];
                    if (this.arrTableData[i].order_date !== null && this.arrTableData[i].order_date !== "" && this.arrTableData[i].order_date !== undefined)
                        if (this.arrTableData[i].order_date.indexOf('T') >= 0)
                            this.arrTableData[i].order_date_str = this.arrTableData[i].order_date.split('T')[0];
                    this.arrTableData[i].receiver_info = this.arrTableData[i].receiver_name + " / " + this.arrTableData[i].receiver_phone;
                    if (this.arrTableData[i].receiver_name === '无')
                        this.arrTableData[i].receiver_info = '无';
                    // 费用相关处理
                    if (this.arrTableData[i].delivery_fee === undefined || this.arrTableData[i].delivery_fee === null || this.arrTableData[i].delivery_fee === "") {
                        this.arrTableData[i].delivery_fee = '0.0';
                    }
                    if (this.arrTableData[i].operate_fee === undefined || this.arrTableData[i].operate_fee === null || this.arrTableData[i].operate_fee === "") {
                        this.arrTableData[i].operate_fee = '0.0';
                    }
                    if (this.arrTableData[i].photo_fee === undefined || this.arrTableData[i].photo_fee === null || this.arrTableData[i].photo_fee === "") {
                        this.arrTableData[i].photo_fee = '0.0';
                    }

                    if (this.arrTableData[i].firm_fee === undefined || this.arrTableData[i].firm_fee === null || this.arrTableData[i].firm_fee === "") {
                        this.arrTableData[i].firm_fee = '0';
                    }

                    if (this.arrTableData[i].destroy_fee === undefined || this.arrTableData[i].destroy_fee === null || this.arrTableData[i].destroy_fee === "") {
                        this.arrTableData[i].destroy_fee = '0.0';
                    }

                    if (this.arrTableData[i].after_delivery_fee === undefined || this.arrTableData[i].after_delivery_fee === null ||
                        this.arrTableData[i].after_delivery_fee === "") {
                        this.arrTableData[i].after_delivery_fee = '0.0';
                    }
                }
                this.arrTableDataOge = this.arrTableData;
            }
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新增运单信息信息完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "运单信息信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "运单信息新信息已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.objCurrentPackageCombine = {};
            this.objCurrentReceiver = null
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                btnControlDisable: [false, true, true, true],
                selectedRowKeys: []
            });
            if (this.props.arrExpressEnter.length > 0) {
                this.ShowDlg(0);
            }

        }).catch(function (error) { console.log(error); });
    }
    // 运单信息信息查询
    PackageCombineLookup = () => {
        let strKeyWord = this.refs.search.state.value;
        if (strKeyWord.trim() === "") {
            message.warning('搜索内容不能为空！');
            return;
        }
        this.strKeyWord = strKeyWord;
        this.GetPackageCombineData();
    }
    // 后续运输方式切换
    SwitchTransitType = (index) => {
        // if (index === this.state.iTransitType)
        //     return;
        let fTransitPrice = 6000;
        // if (index === 2)
        //     fTransitPrice = 5000;
        if (this.arrCurrentExpressInfo.length > 0) {
            for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                let fValueWeight = parseFloat(this.arrCurrentExpressInfo[i].length) * parseFloat(this.arrCurrentExpressInfo[i].width) *
                    parseFloat(this.arrCurrentExpressInfo[i].height) / parseFloat(fTransitPrice);
                this.arrCurrentExpressInfo[i].value_weight = fValueWeight;
                if (fValueWeight >= parseFloat(this.arrCurrentExpressInfo[i].item_weight))
                    this.arrCurrentExpressInfo[i].price_weight = fValueWeight;
                else
                    this.arrCurrentExpressInfo[i].price_weight = this.arrCurrentExpressInfo[i].item_weight;
            }
        }
        this.strCurrency = "$";
        this.objCurrentPackageCombine.operate_fee = 5.0;
        this.objCurrentPackageCombine.photo_fee = 2.0;
        if (index === 1) {
            this.strCurrency = "￥";
            this.objCurrentPackageCombine.operate_fee = 20.0;
            this.objCurrentPackageCombine.photo_fee = 10.0;
        }
        if (!this.bPhotoFee)
            this.objCurrentPackageCombine.photo_fee = 0.0;
        this.iTransitType = index;
        this.ComputePriceWeight(index, 0);
        this.setState({
            ...this.state,
            iTransitType: index,
            iPackageType: 0,
            bItemValueChange: true,
            bPackageInfoChange: true
        });
    }
    // 包裹品类切换
    SwitchPackageType = (index) => {
        this.ComputePriceWeight(-1, index);
        this.setState({
            ...this.state,
            iPackageType: index
        });
    }
    // 船期变更
    SwitchShipDate = (index) => {
        this.setState({
            ...this.state,
            iShipDate: index
        });
    }
    // 状态更新
    StatusChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iStatus: 0
            });
    }
    // 点击了导出Excel文档按钮
    ClickExportButton = () => {
        message.loading({ content: '数据处理中……', key: g_strMessageKey });
        if (this.arrSelectedPackageCombine.length > 100) {
            message.warning({ content: "导出数据不能超过100行！", key: g_strMessageKey, duration: 2 });
            return;
        }
        // 获取选中运单编码对应的快递列表和物品列表信息
        let arrExpressOrder1 = [];
        let arrExpressOrder2 = [];
        let arrExpressOrder3 = [];
        let arrExpressOrder4 = [];
        for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
            if (i < 30) {
                if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                    for (let j = 0; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressOrder1.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    }
                }
            }
            if (i >= 30 && i < 60) {
                if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                    for (let j = 0; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressOrder2.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    }
                }
            }
            if (i >= 60 && i < 90) {
                if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                    for (let j = 0; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressOrder3.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    }
                }
            }
            if (i >= 90 && i < 120) {
                if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                    for (let j = 0; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressOrder4.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    }
                }
            }
        }
        if (arrExpressOrder1.length > 0) {
            axios('/GetPackageCombineItemDetails', {
                params: {
                    express_order: arrExpressOrder1,
                    store_id: this.props.iStoreID
                }
            }).then(({ data }) => {
                this.arrSelectedPackageCombineItemInfo = data;
                if (arrExpressOrder2.length > 0) {
                    axios('/GetPackageCombineItemDetails', {
                        params: {
                            express_order: arrExpressOrder2,
                            store_id: this.props.iStoreID
                        }
                    }).then(({ data }) => {
                        if (data.length > 0)
                            this.arrSelectedPackageCombineItemInfo = this.arrSelectedPackageCombineItemInfo.concat(data);
                        if (arrExpressOrder3.length > 0) {
                            axios('/GetPackageCombineItemDetails', {
                                params: {
                                    express_order: arrExpressOrder3,
                                    store_id: this.props.iStoreID
                                }
                            }).then(({ data }) => {
                                if (data.length > 0)
                                    this.arrSelectedPackageCombineItemInfo = this.arrSelectedPackageCombineItemInfo.concat(data);
                                if (arrExpressOrder4.length > 0) {
                                    axios('/GetPackageCombineItemDetails', {
                                        params: {
                                            express_order: arrExpressOrder4,
                                            store_id: this.props.iStoreID
                                        }
                                    }).then(({ data }) => {
                                        if (data.length > 0)
                                            this.arrSelectedPackageCombineItemInfo = this.arrSelectedPackageCombineItemInfo.concat(data);
                                        this.ExportExcelFile();
                                    }).catch(function (error) { console.log(error); });
                                } else {
                                    this.ExportExcelFile();
                                }
                            }).catch(function (error) { console.log(error); });
                        } else {
                            this.ExportExcelFile();
                        }
                    }).catch(function (error) { console.log(error); });
                } else {
                    this.ExportExcelFile();
                }
            }).catch(function (error) { console.log(error); });
        } else {
            this.ExportExcelFile();
        }
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        console.log("ExportExcelFile : ", this.arrSelectedPackageCombine);
        let arrExportData = [['运单编号', '货运方式', '运输路线', '总重量', '总体积', '费用总计', '备注', '运单状态', '物流单号', '寄件人', '收件人', '货物信息']];
        for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
            if (i > 0)
                arrExportData.push(['']);
            let arrData = [];
            if (this.arrSelectedPackageCombine[i].package_no !== null && this.arrSelectedPackageCombine[i].package_no !== "")
                arrData.push(this.arrSelectedPackageCombine[i].package_no);
            else
                arrData.push("");
            arrData.push(this.arrTransitType[this.arrSelectedPackageCombine[i].transit_way]);
            arrData.push(this.arrPackageType[this.arrSelectedPackageCombine[i].package_type]);
            if (this.arrSelectedPackageCombine[i].price_weight !== null && this.arrSelectedPackageCombine[i].price_weight !== "")
                arrData.push(this.arrSelectedPackageCombine[i].price_weight + " kg");
            else
                arrData.push("");
            if (this.arrSelectedPackageCombine[i].total_value !== null && this.arrSelectedPackageCombine[i].total_value !== "")
                arrData.push(this.arrSelectedPackageCombine[i].total_value + " cm3");
            else
                arrData.push("");
            arrData.push("$ " + this.arrSelectedPackageCombine[i].total_value);
            if (this.arrSelectedPackageCombine[i].comments !== null && this.arrSelectedPackageCombine[i].comments !== "")
                arrData.push(this.arrSelectedPackageCombine[i].comments);
            else
                arrData.push("");
            arrData.push(this.arrStatus[this.arrSelectedPackageCombine[i].status]);
            // 如果有快递和货物信息
            if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                arrData.push(this.arrSelectedPackageCombine[i].express_info[0].express_order);
                arrData.push(this.arrSelectedPackageCombine[i].express_info[0].user_name + " / " + this.arrSelectedPackageCombine[i].storage_code);
                arrData.push(this.arrSelectedPackageCombine[i].receiver_name + " / " + this.arrSelectedPackageCombine[i].receiver_phone);
                // 获取货物列表信息
                this.GetPackageCombineExpressInfo(arrExportData, arrData, i, 0);
                if (this.arrSelectedPackageCombine[i].express_info.length > 1) {
                    let arrExpressInfo = ['', '', '', '', '', '', '', '', '', ''];
                    for (let j = 1; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressInfo = ['', '', '', '', '', '', '', '', '', '', ''];
                        // arrExpressInfo.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                        // arrExpressInfo.push(this.arrSelectedPackageCombine[i].receiver_name + " / " + this.arrSelectedPackageCombine[i].receiver_phone);
                        // arrExpressInfo.push(this.arrSelectedPackageCombine[i].express_info[j].storage_code);
                        // 获取货物列表信息
                        this.GetPackageCombineExpressInfo(arrExportData, arrExpressInfo, i, j);
                    }
                }
            } else {
                arrExportData.push(arrData);
            }
        }
        message.success({ content: "数据处理完成！", key: g_strMessageKey, duration: 2 });
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '运单记录.xlsx');
    }
    // 获取指定运单/指定快递单号中的货物信息
    GetPackageCombineExpressInfo = (arrParentData, arrData, i, indexExpress) => {
        for (let j = 0; j < this.arrSelectedPackageCombineItemInfo.length; j++) {
            if (this.arrSelectedPackageCombineItemInfo[j].package_no === this.arrSelectedPackageCombine[i].package_no &&
                this.arrSelectedPackageCombineItemInfo[j].express_order === this.arrSelectedPackageCombine[i].express_info[indexExpress].express_order) {
                if (this.arrSelectedPackageCombineItemInfo[j].express_info !== null && this.arrSelectedPackageCombineItemInfo[j].express_info !== "") {
                    let objExpressInfo = JSON.parse(this.arrSelectedPackageCombineItemInfo[j].express_info);
                    if (objExpressInfo.item_info !== undefined) {
                        if (objExpressInfo.item_info !== null && objExpressInfo.item_info !== "") {
                            if (objExpressInfo.item_info.length > 0) {
                                for (let k = 0; k < objExpressInfo.item_info.length; k++) {
                                    if (k === 0) {
                                        arrData.push(objExpressInfo.item_info[k].cn + " / " + objExpressInfo.item_info[k].en);
                                        arrData.push(objExpressInfo.item_info[k].num.toString());
                                    }
                                    else {
                                        arrData.push('', '', '', '', '', '', '', '', '', '', '');
                                        arrData.push(objExpressInfo.item_info[k].cn + " / " + objExpressInfo.item_info[k].en);
                                        arrData.push(objExpressInfo.item_info[k].cn.toString());
                                    }
                                    arrParentData.push(arrData);
                                    arrData = [];
                                }
                            } else {
                                arrParentData.push(arrData);
                                arrData = [];
                            }
                        } else {
                            arrParentData.push(arrData);
                            arrData = [];
                        }
                    } else {
                        arrParentData.push(arrData);
                        arrData = [];
                    }
                } else {
                    console.log("No Express Info");
                }
            }
        }
    }
    // 显示全部包裹
    ShowAllPackageCombine = () => {
        this.iCurrentPackage = -1;
        this.iExpressTypeFilter = 0;
        this.strKeyWord = "";
        this.refs.search.state.value = "";
        this.iStorageFilter = 0;
        this.iStatusFilter = 0;
        this.iCurrentPage = 1;
        this.setState({
            ...this.state,
            iExpressTypeFilter: 0,
            iStatusFilter: 0,
            iStorageFilter: 0
        });
        this.GetPackageCombineData();
    }
    // 新增包裹信息
    AddNewExpressEnterInfo = () => {
        let objExpressEnter = {
            express_order: '', express_info: { item_info: [{ cn: '', en: '', num: 0 }] }
        };
        // this.arrCurrentExpressInfo.push(objExpressEnter);
        this.arrCurrentExpressInfo.splice(0, 0, objExpressEnter);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 移除包裹信息
    RemoveExpressInfo = (index) => {
        if (this.arrCurrentExpressInfo.length <= 1) {
            message.warning('每个运单至少包含一个包裹！');
            return;
        }
        this.arrCurrentExpressInfo.splice(index, 1);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 物品信息发生变化
    ExpressInfoChange = (index, value, e) => {
        if (value === 0) {
            this.arrCurrentExpressInfo[index].express_order = e.target.value;
        }
        if (value === 1) {
            this.arrCurrentExpressInfo[index].receiver = e.target.value;
        }
        if (value === 2) {
            this.arrCurrentExpressInfo[index].storage_code = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 运单状态选择
    SwitchStatus = (index) => {
        this.setState({
            ...this.state,
            iStatus: index
        });
    }
    // 运单状态过滤器切换
    SwitchStatusFilter = (index) => {

        // this.arrTableData = [];
        // if (index > 0) {
        //     let iStatus = index - 1;
        //     for (let i = 0; i < this.arrTableDataOge.length; i++) {
        //         if (iStatus === this.arrTableDataOge[i].status)
        //             this.arrTableData.push(this.arrTableDataOge[i]);
        //     }
        // } else {
        //     this.arrTableData = this.arrTableDataOge;
        // }
        this.iStatusFilter = index;
        this.iCurrentPage = 1;
        this.setState({
            ...this.state,
            iStatusFilter: index
        });
        this.GetPackageCombineData();
    }
    // 仓库选择
    SwitchStorage = (index) => {
        this.setState({
            ...this.state,
            iStorage: index
        });
    }
    // 运单状态过滤器切换
    SwitchStorageFilter = (index) => {
        this.iStorageFilter = index;
        this.iCurrentPage = 1;
        this.setState({
            ...this.state,
            iStorageFilter: index
        });
        this.GetPackageCombineData();
    }
    // 运单信息更新
    PackageInfoChange = (index, e) => {

        // 净重
        if (index === 0) {
            this.objCurrentPackageCombine.total_weight = e.target.value;
        }
        // 长度
        if (index === 1) {
            this.objCurrentPackageCombine.length = e.target.value;
        }
        // 宽度
        if (index === 2) {
            this.objCurrentPackageCombine.width = e.target.value;
        }
        // 高度
        if (index === 3) {
            this.objCurrentPackageCombine.height = e.target.value;
        }

        this.ComputePriceWeight(-1, -1);

        this.setState({
            bPackageInfoChange: true
        });
    }
    // 体积重比较计算
    ComputePriceWeight = (iTransitType, iPackageType) => {
        if (iTransitType < 0)
            iTransitType = this.state.iTransitType;
        if (iPackageType < 0)
            iPackageType = this.state.iPackageType;
        let fTransitPrice = 6000;
        // if (iTransitType === 2)
        //     fTransitPrice = 5000;

        this.objCurrentPackageCombine.total_value = parseFloat(this.objCurrentPackageCombine.length);
        this.objCurrentPackageCombine.total_value *= parseFloat(this.objCurrentPackageCombine.width);
        this.objCurrentPackageCombine.total_value *= parseFloat(this.objCurrentPackageCombine.height);
        this.objCurrentPackageCombine.value_weight = this.objCurrentPackageCombine.total_value / fTransitPrice;

        if (this.objCurrentPackageCombine.total_weight === "") {
            this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.value_weight;
        } else {
            if (this.objCurrentPackageCombine.length === "" && this.objCurrentPackageCombine.width === "" && this.objCurrentPackageCombine.height === "") {
                this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.total_weight;
            } else {
                this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.total_weight;
                let fCurrentTotalWeight = parseFloat(this.objCurrentPackageCombine.total_weight);
                let fCurrentValueWeight = parseFloat(this.objCurrentPackageCombine.value_weight);
                if (fCurrentTotalWeight < fCurrentValueWeight)
                    this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.value_weight;
            }
        }
        if (iTransitType === 0) {
            if (iPackageType === 0)
                this.objCurrentPackageCombine.delivery_fee = 3.5 * parseFloat(this.objCurrentPackageCombine.price_weight);
            if (iPackageType === 1)
                this.objCurrentPackageCombine.delivery_fee = 12.5 * parseFloat(this.objCurrentPackageCombine.price_weight);
            if (iPackageType === 2)
                this.objCurrentPackageCombine.delivery_fee = 5 * parseFloat(this.objCurrentPackageCombine.price_weight);
        }

        this.TotalFeeCalculate();

    }
    // 查看某运单记录中的快递单号对应的货物信息
    ViewItemDetails = (strOrder) => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetExpressItemDetails', {
            params: {
                express_order: strOrder,
                store_id: this.props.iStoreID
            }
        }).then(({ data }) => {
            console.log(data);
            if (data !== null && data.length > 0) {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.arrCurrentItemInfo = [];
                let objExpressInfo = JSON.parse(data[0].express_info);
                this.arrCurrentItemInfo = objExpressInfo.item_info;
                console.log(this.arrCurrentItemInfo);
                if (this.arrCurrentItemInfo.length > 0) {
                    this.setState({
                        ...this.state,
                        bViewItemInfoDlg: true
                    });
                } else
                    message.info({ content: "未找到任何货物信息，请检查该快递单号！", key: g_strMessageKey, duration: 2 });
            } else {
                message.info({ content: "未找到任何货物信息，请检查该快递单号！", key: g_strMessageKey, duration: 2 });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 关闭查看货物明细对话框
    CloseViewItemDetailsDlg = () => {
        this.setState({
            ...this.state,
            bViewItemInfoDlg: false
        })
    }
    // 复制运单编码
    CopyPackageOrder = () => {
        // console.log("Copy Package Order !", this.arrSelectedPackageCombine);
        // let strCopyContents = "";
        // if (this.arrSelectedPackageCombine.length > 0) {
        //     for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
        //         strCopyContents += this.arrSelectedPackageCombine[i].package_no + "\n";
        //     }
        message.info("已复制选中的运单编码！");
        //     navigator.clipboard.writeText(strCopyContents);
        // }
    }
    // 下载文件
    DownloadFile = (index) => {
        window.open("https://mouthingmanager.s3.amazonaws.com/" + this.objCurrentPackageCombine.s3_link.split(',')[index]);
    }
    // 删除文件
    DeleteFile = (index) => {
        if (this.bDeletingFile)
            return;
        this.bDeletingFile = true;
        this.setState({
            ...this.state,
            bFileDelete: true
        })

        let strTargetS3Link = this.objCurrentPackageCombine.s3_link;
        if (this.objCurrentPackageCombine.s3_link.indexOf(',') >= 0) {
            strTargetS3Link = this.objCurrentPackageCombine.s3_link.split(',')[index];
        }
        axios.post('/aws-profile-delete', {
            store_id: this.props.iStoreID,
            action: 2,
            id: this.objCurrentPackageCombine.id,
            s3_link: strTargetS3Link,
            index: index
        }).then(({ data }) => {
            // DataStatistic
            UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "删除发票文件" });
            message.success({ content: '文件删除完成！', key: g_strMessageKey, duration: 2 });
            this.objCurrentPackageCombine.s3_link = data;
            this.bDeletingFile = false;
            this.setState({
                ...this.state,
                bFileDelete: false
            })
        });
    }
    // 增值服务选项
    ExtraServiceSelect = (index, e) => {
        // 拍照费用
        if (index === 0) {
            if (e.target.checked) {
                this.objCurrentPackageCombine.photo_fee = 2.0;
                if (this.state.iTransitType === 1)
                    this.objCurrentPackageCombine.photo_fee = 10.0;
            } else
                this.objCurrentPackageCombine.photo_fee = 0.0;
            this.bPhotoFee = e.target.checked;
            this.TotalFeeCalculate();
        }
        // 加固费用
        if (index === 1) {
            this.objCurrentPackageCombine.firm_fee = 0.0;
            this.bFirmFee = e.target.checked;
            this.TotalFeeCalculate();
        }
        // 销毁费用
        if (index === 2) {
            this.objCurrentPackageCombine.destroy_fee = 0.0;
            this.bDestroyFee = e.target.checked;
            this.TotalFeeCalculate();
        }
        // 到付费用
        if (index === 3) {
            this.objCurrentPackageCombine.after_delivery_fee = 0.0;
            this.bAfterDeliveryFee = e.target.checked;
            this.TotalFeeCalculate();
        }
    }
    // 服务费用变化
    FeeChange = (iType, e) => {
        // 运费
        if (iType === 0) {
            let strDeliveryFee = e.target.value;
            // if (strDeliveryFee === null || strDeliveryFee === undefined || strDeliveryFee === "")
            //     strDeliveryFee = '0.0';
            this.objCurrentPackageCombine.delivery_fee = strDeliveryFee;
        }
        // 操作费用
        if (iType === 1) {
            let strOperateFee = e.target.value;
            // if (strOperateFee === null || strOperateFee === undefined || strOperateFee === "")
            //     strOperateFee = '0.0';
            this.objCurrentPackageCombine.operate_fee = strOperateFee;
        }
        // 特殊加固费用
        if (iType === 2 && this.bFirmFee) {
            let strFirmFee = e.target.value;
            this.objCurrentPackageCombine.firm_fee = strFirmFee;
        }
        // 销毁费用
        if (iType === 3) {
            let strDestroyFee = e.target.value;
            this.objCurrentPackageCombine.destroy_fee = strDestroyFee;
        }
        // 快递到付
        if (iType === 4) {
            let strAfterDeliveryFee = e.target.value;
            this.objCurrentPackageCombine.after_delivery_fee = strAfterDeliveryFee;
        }
        this.TotalFeeCalculate();
    }
    // 总费用累计
    TotalFeeCalculate = () => {
        this.objCurrentPackageCombine.total_price = parseFloat(this.objCurrentPackageCombine.delivery_fee);
        this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.operate_fee);
        this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.photo_fee);
        if (this.iTransitType === 0)
            this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.firm_fee) * 4;
        else
            this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.firm_fee) * 20;
        // this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.destroy_fee) * 10;
        // this.objCurrentPackageCombine.total_price += parseFloat(this.objCurrentPackageCombine.after_delivery_fee);

        if (!isNaN(this.objCurrentPackageCombine.total_price) && this.objCurrentPackageCombine.total_price !== null &&
            this.objCurrentPackageCombine.total_price !== "" && this.objCurrentPackageCombine.total_price !== undefined)
            this.objCurrentPackageCombine.total_price = this.objCurrentPackageCombine.total_price.toFixed(2);
        this.setState({
            ...this.state,
            bFeeChange: true
        })
    }
    // 选择收件人
    SelectReceiver = () => {
        this.arrReceiverShow = global.arrClientData;
        this.setState({
            ...this.state,
            bSelectReceiverDlg: true,
            bReceiverFilter: false
        });
    }
    // 确认发件人
    SelectReceiverConfirm = (index) => {
        this.objCurrentReceiver = this.arrReceiverShow[index];
        this.refs.receiver_name.state.value = this.objCurrentReceiver.name;
        this.refs.receiver_phone.state.value = this.objCurrentReceiver.phone;
        this.refs.receiver_postcode.state.value = this.objCurrentReceiver.postcode;
        this.refs.receiver_address.state.value = this.objCurrentReceiver.address;
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false
        });
    }
    // SelectCancel
    SelectCancel = () => {
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false
        });
    }
    // 搜索收件人
    SearchReceiver = () => {
        let strReceiverSearch = this.refs.search_receiver.state.value;
        if (strReceiverSearch !== undefined && strReceiverSearch !== null && strReceiverSearch !== '') {
            if (strReceiverSearch.trim() !== '') {
                this.arrReceiverShow = [];
                for (let i = 0; i < global.arrClientData.length; i++) {
                    let strReceiverInfo = JSON.stringify(global.arrClientData[i]);
                    if (strReceiverInfo.indexOf(strReceiverSearch) >= 0) {
                        this.arrReceiverShow.push(global.arrClientData[i]);
                    }
                }
            }
        }

        this.setState({
            ...this.state,
            bReceiverFilter: true
        });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetPackageCombineData();

        if (global.arrClientData.length <= 0) {
            axios('/GetClients', { params: { store_id: this.props.iStoreID, user_id: this.props.objUserInfo.id, user_code: this.props.objUserInfo.storage_code } })
                .then(({ data }) => {
                    global.arrClientData = data;
                }).catch(function (error) { console.log(error); });
        }

    }
    // 渲染函数
    render() {
        /* 用户管理页面控制栏 */
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;
        // 运输方式下拉菜单
        const menuTransitType = (
            <Menu>
                {this.arrTransitType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 运输路线下拉菜单1 & 2
        let menuPackageType = null;
        if (this.state.iTransitType === 0) {
            this.arrPackageType = this.arrOceanTransitLine;
            menuPackageType = (
                <Menu>
                    {this.arrOceanTransitLine.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchPackageType.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
        }
        if (this.state.iTransitType === 1) {
            this.arrPackageType = this.arrAirTransitLine;
            menuPackageType = (
                <Menu>
                    {this.arrAirTransitLine.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchPackageType.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
        }
        // 船期选择下拉菜单
        // const menuShipDate = (
        //     <Menu>
        //         {this.arrShipDate.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchShipDate.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 运单状态选择
        const menuStatus = (
            <Menu>
                {this.arrStatus.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStatus.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 运单状态过滤器
        const menuStatusFilter = (
            <Menu>
                {this.arrStatusFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStatusFilter.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 仓库过滤器
        const menuStorageFilter = (
            <Menu>
                {this.arrStorageFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStorageFilter.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 顶部栏操作区域
        strAreaStyle = "store_manage_contents store_manage_top_space_double";
        // let strCopy = "";
        // if (this.arrSelectedPackageCombine.length > 0) {
        //     for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
        //         strCopy += this.arrSelectedPackageCombine[i].package_no + "\n";
        //     }
        // }
        uiTitleArea = (
            <div>
                <div className="store_contents_title" >
                    <div className="transit_price_title" >{"运单状态"}</div>
                    <Dropdown className="order_filter_dropdown" trigger={['click']}
                        overlay={menuStatusFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button">
                            <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div className="transit_price_title" >{"收货仓库"}</div>
                    <Dropdown className="order_filter_dropdown" trigger={['click']}
                        overlay={menuStorageFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button">
                            <div className="order_details_dropdown_text">{this.arrStorageFilter[this.state.iStorageFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div className="order_filter_title">关键字</div>
                    <Input className="order_search_input" ref="search" defaultValue="" />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.PackageCombineLookup}
                        style={{ background: 'darkorange', fontWeight: 'bold', color: 'white' }} >
                        <Icon type="search" />搜索</Button>
                </div >
                <div className="store_contents_title_sec">
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllPackageCombine}
                        style={{ background: 'darkorange', fontWeight: 'bold', color: 'white' }}>
                        显示全部<Icon type="redo" /></Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="search" />查看</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                    {/* <Button className="manage_contents_title_margin sooning_export_button_style" onClick={this.ClickExportButton}
                        disabled={this.state.btnControlDisable[3]}>
                        <Icon type="export" />导出Excel</Button>
                    <Clipboard data-clipboard-text={strCopy} onClick={this.CopyPackageOrder} disabled={this.state.btnControlDisable[3]}
                        style={{ border: 'none', width: '110px', height: '32.5px', borderRadius: '5px', background: 'darkorange', color: 'white', fontWeight: 'bold' }}
                        className="manage_contents_title_margin sooning_export_button_style" >
                        复制运单编码
                    </Clipboard> */}
                </div>
            </div>
        );
        // M站针对加载状态时的界面处理
        if (this.props.bMStation) {
            uiTitleArea = null;
            strLoadingStyle = "manage_empty_loading_m";
        }
        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            let paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 价格信息`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let strTitle = "新建运单信息";
            if (this.state.iDlgType === 1)
                strTitle = "编辑运单信息";
            // 包裹列表
            let uiExpressEnterList = null;

            if (this.state.bShowDlg) {
                if (this.props.arrExpressEnter.length > 0 && this.state.iDlgType === 0) {
                    this.arrCurrentExpressInfo = this.props.arrExpressEnter;
                }
                uiExpressEnterList = (
                    this.arrCurrentExpressInfo.map((item, index) => {
                        let strItemBlockStyle = "sooning_express_item_info_block";
                        if (index % 2 === 0)
                            strItemBlockStyle = "sooning_express_item_info_block_light";
                        let arrItemList = item.express_info.item_info;
                        let uiItemList = [];
                        for (let i = 0; i < arrItemList.length; i++) {
                            let uiItemInfo = (
                                <div className="express_item_info_row" key={index * 10 + i}>
                                    <div className={"express_enter_item_info_name"} >
                                        {arrItemList[i].cn}
                                    </div>
                                    <div className={"express_enter_item_info_name"} >
                                        {arrItemList[i].en}
                                    </div>
                                    <div className={"express_enter_item_info_name express_enter_item_info_num"} >
                                        {arrItemList[i].num}
                                    </div>
                                </div>
                            );
                            uiItemList.push(uiItemInfo);
                        }
                        return (
                            <div key={index} className={strItemBlockStyle}>
                                <div className="express_item_info_row">
                                    <Input style={{ width: '200px', minWidth: '200px', maxWidth: '200px', marginLeft: '20px' }} ref={"express_order_" + index}
                                        defaultValue={item.express_order} value={item.express_order}
                                        onChange={this.ExpressInfoChange.bind(this, index, 0)} />
                                    <div className="express_enter_item_info_block">
                                        {uiItemList}
                                    </div>
                                    <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                        onClick={this.RemoveExpressInfo.bind(this, index)}>
                                        <Icon type="delete" theme="filled" />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                );
            }
            // 货物明细弹窗
            let uiViewItemDetails = null;
            if (this.arrCurrentItemInfo.length > 0) {
                uiViewItemDetails = (
                    <Modal width={500} title={"货物明细"} visible={this.state.bViewItemInfoDlg} footer={null}
                        onCancel={this.CloseViewItemDetailsDlg} >
                        <div className="view_item_details_area">
                            {this.arrCurrentItemInfo.map((item, index) => {
                                return (
                                    <div key={index} className="view_item_details_row">
                                        <div className="express_item_info_row">
                                            {item.name + " x " + item.num}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Modal>
                );
            }
            // 运单信息
            let strValue = "体积：" + parseFloat(this.objCurrentPackageCombine.total_value).toFixed(2) + " cm3";
            if (parseFloat(this.objCurrentPackageCombine.total_value) >= 1000000.00)
                strValue = "体积：" + (parseFloat(this.objCurrentPackageCombine.total_value) / 1000 / 1000).toFixed(2) + " m3";

            let uiPackageInfo = (
                <div className="sooning_package_combine_info_block">
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">合箱长度：</div>
                        <Input className="user_edit_info_input" ref="package_length" addonAfter={"cm"} value={this.objCurrentPackageCombine.length}
                            defaultValue={this.objCurrentPackageCombine.length} onChange={this.PackageInfoChange.bind(this, 1)} disabled />
                        <div className="express_enter_edit_title">合箱宽度：</div>
                        <Input className="user_edit_info_input" ref="package_width" addonAfter={"cm"} value={this.objCurrentPackageCombine.width}
                            defaultValue={this.objCurrentPackageCombine.width} onChange={this.PackageInfoChange.bind(this, 2)} disabled />
                        <div className="express_enter_edit_title">合箱高度：</div>
                        <Input className="user_edit_info_input" ref="package_height" addonAfter={"cm"} value={this.objCurrentPackageCombine.height}
                            defaultValue={this.objCurrentPackageCombine.height} onChange={this.PackageInfoChange.bind(this, 3)} disabled />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">合箱净重：</div>
                        <Input className="user_edit_info_input" ref="total_weight" addonAfter={"kg"} value={this.objCurrentPackageCombine.total_weight}
                            defaultValue={this.objCurrentPackageCombine.total_weight} onChange={this.PackageInfoChange.bind(this, 0)} disabled />
                        <div className="express_enter_edit_title" >运输费用：</div>
                        <Input className="user_edit_info_input" ref="delivery_fee" addonBefore={this.strCurrency} value={this.objCurrentPackageCombine.delivery_fee}
                            defaultValue={this.objCurrentPackageCombine.delivery_fee} disabled onChange={this.FeeChange.bind(this, 0)} />
                        <div className="express_enter_edit_title">
                            {strValue}</div>
                        <div className="express_enter_edit_title" style={{ marginLeft: '10px' }}>
                            {"计费重：" + parseFloat(this.objCurrentPackageCombine.price_weight).toFixed(2) + " kg"}</div>
                    </div>
                </div>
            );
            // 收件信息
            let uiReceiverInfo = null;
            uiReceiverInfo = (
                <div className="sooning_package_combine_info_block">
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title package_combine_info_required_title">收件人名：</div>
                        <Input className="user_edit_info_input" ref="receiver_name" defaultValue={this.objCurrentPackageCombine.receiver_name} />
                        <div className="express_enter_edit_title package_combine_info_required_title">联系电话：</div>
                        <Input className="user_edit_info_input" ref="receiver_phone" defaultValue={this.objCurrentPackageCombine.receiver_phone} />
                        <div className="express_enter_edit_title package_combine_info_required_title">邮政编码：</div>
                        <Input className="user_edit_info_input" ref="receiver_postcode" defaultValue={this.objCurrentPackageCombine.receiver_postcode} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title package_combine_info_required_title">邮寄地址：</div>
                        <Input className="express_enter_item_info_input_long" ref="receiver_address" defaultValue={this.objCurrentPackageCombine.receiver_address} />
                        <div className="ca_cn_order_button ca_cn_order_button_right_layout express_enter_express_info_add_button"
                            style={{ color: 'white', fontWeight: 'bold', background: 'darkorange', marginRight: '20px' }}
                            onClick={this.SelectReceiver}>选择收件人</div>
                    </div>

                </div>
            );

            let uiSelectReceiverDlg = null;
            if (this.state.bSelectReceiverDlg) {
                let uiTitle = (
                    <div className="user_edit_info_row">
                        {"选择收件人"}
                        <Input className="order_search_input" ref="search_receiver" defaultValue="" style={{ marginLeft: '20px' }} />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.SearchReceiver}>
                            <Icon type="search" />搜索</Button>
                    </div>
                )
                uiSelectReceiverDlg = (
                    <Modal width={960} title={uiTitle} visible={this.state.bSelectReceiverDlg} footer={null} centered
                        onCancel={this.SelectCancel} >
                        {
                            this.arrReceiverShow.map((receiver, indexReceiver) => {
                                return (
                                    <div key={indexReceiver} className='shipper_receiver_select_block' onClick={this.SelectReceiverConfirm.bind(this, indexReceiver)}>
                                        <div className="user_edit_info_row">
                                            <div className="express_enter_edit_title">姓名：</div>
                                            <Input className="user_edit_info_input" value={receiver.name} disabled />
                                            <div className="express_enter_edit_title">电话：</div>
                                            <Input className="user_edit_info_input" value={receiver.phone} disabled />
                                            <div className="express_enter_edit_title">邮编：</div>
                                            <Input className="user_edit_info_input" value={receiver.postcode} disabled />
                                            {/* <div className="express_enter_edit_title">邮箱：</div>
                                            <Input className="user_edit_info_input" value={receiver.email} disabled /> */}
                                        </div>
                                        <div className="user_edit_info_row">
                                            <div className="express_enter_edit_title">地址：</div>
                                            <Input className="express_enter_item_info_input_full" value={receiver.address} disabled />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Modal>
                );
            }

            // 用户信息
            let uiUserInfo = null;
            if (this.arrCurrentExpressInfo.length > 0) {
                let strUserInfo = this.arrCurrentExpressInfo[0].user_info;
                if (strUserInfo === undefined)
                    strUserInfo = this.arrCurrentExpressInfo[0].user_name + " / " + this.arrCurrentExpressInfo[0].storage_code;
                if (this.arrCurrentExpressInfo[0].user_name === undefined || this.arrCurrentExpressInfo[0].storage_code === undefined)
                    strUserInfo = "";
                uiUserInfo = (
                    <div className="user_edit_info_input" style={{ fontWeight: 'bold' }}>
                        {strUserInfo}
                    </div>
                );
            }
            // let bDisable = false;
            if (this.state.iDlgType === 0) {
                uiPackageInfo = null;
                // bDisable = true;
            }

            // 照片上传区域
            let uiUploadedFiles = null;
            let arrUploadedFiles = [];
            if (this.objCurrentPackageCombine.s3_link !== null && this.objCurrentPackageCombine.s3_link !== '' && typeof (this.objCurrentPackageCombine.s3_link) === 'string') {
                if (this.objCurrentPackageCombine.s3_link.indexOf(',') >= 0) {
                    arrUploadedFiles = this.objCurrentPackageCombine.s3_link.split(',');
                } else {
                    arrUploadedFiles.push(this.objCurrentPackageCombine.s3_link);
                }
            }
            if (arrUploadedFiles.length > 0) {
                uiUploadedFiles = (
                    <div className="sooning_auto_order_upload_file_area" style={{ borderTop: 'none' }}>
                        <div className="auto_order_file_wait_row" style={{ fontWeight: 'bold' }}>已上传照片</div>
                        { arrUploadedFiles.map((file, index) => {
                            let uiImgPreview = (
                                <img src={"https://mouthingmanager.s3.amazonaws.com/" + file} alt='' style={{ maxWidth: '50vw' }} />
                            );
                            return (
                                <div className="auto_order_file_wait_row" key={index}>
                                    {file}
                                    <Popover placement="right" title={null} content={uiImgPreview} trigger="click">
                                        <Icon type="search" style={{ marginLeft: '15px', color: 'darkorange', fontWeight: 'bold' }}
                                            className="auto_order_file_operate_button" />
                                    </Popover>
                                    <Icon type="download" style={{ marginLeft: '15px', color: 'darkorange', fontWeight: 'bold' }}
                                        className="auto_order_file_operate_button" onClick={this.DownloadFile.bind(this, index)} />
                                    {/* <Icon type="delete" style={{ marginLeft: '15px', color: 'lightcoral', fontWeight: 'bold' }}
                                        className="auto_order_file_operate_button" onClick={this.DeleteFile.bind(this, index)} /> */}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            // 操作费用
            let fOperateFee = parseFloat(this.objCurrentPackageCombine.operate_fee);
            if (!isNaN(fOperateFee) && fOperateFee !== undefined && fOperateFee !== null) {
                fOperateFee = fOperateFee.toFixed(2);
            }
            let strPhotoFee = "2";
            let strFirmFee = "4";
            if (this.state.iTransitType === 1) {
                strPhotoFee = "10";
                strFirmFee = "20";
            }
            let bExtraEdit = true;
            if (this.bFirmFee)
                bExtraEdit = false;
            let bExtraDisable = false;
            if (this.objCurrentPackageCombine.status >= 1) {
                bExtraDisable = true;
                bExtraEdit = true;
            }
            // 仓库选择
            const menuStorage = (
                <Menu>
                    {this.arrStorage.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchStorage.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiModal = (
                <Modal title={strTitle} width={950} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="sooning_package_combine_info_block">
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">运单编号：</div>
                            <Input className="user_edit_info_input" ref="package_no" disabled placeholder={"提交后自动生成"}
                                defaultValue={this.objCurrentPackageCombine.package_no}
                                value={this.objCurrentPackageCombine.package_no} />
                            <div className="express_enter_edit_title">运单状态：</div>
                            <Dropdown overlay={menuStatus} trigger={['click']} disabled>
                                <Button className="transit_type_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrStatus[this.state.iStatus]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="express_enter_edit_title">收货仓库：</div>
                            <Dropdown overlay={menuStorage} trigger={['click']}>
                                <Button className="transit_type_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrStorage[this.state.iStorage]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            {/* <div className="express_enter_edit_title">创建日期：</div>
                            <Input className="user_edit_info_input" ref="order_date" disabled
                                defaultValue={this.objCurrentPackageCombine.order_date_str} /> */}
                        </div>
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">货运方式：</div>
                            <Dropdown overlay={menuTransitType} trigger={['click']} /*disabled={bExtraDisable}*/ disabled>
                                <Button className="transit_type_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrTransitType[this.state.iTransitType]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="express_enter_edit_title">运输线路：</div>
                            <Dropdown overlay={menuPackageType} trigger={['click']} disabled={bExtraDisable}>
                                <Button className="transit_type_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrPackageType[this.state.iPackageType]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="express_enter_edit_title">物流单号：</div>
                            <Input className="user_edit_info_input" ref="auto_order_no" disabled
                                defaultValue={this.objCurrentPackageCombine.auto_order_no} />
                        </div>
                    </div>
                    {uiReceiverInfo}
                    {uiPackageInfo}
                    <div className="sooning_package_combine_info_block">
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title">操作费用：</div>
                            <Input className="user_edit_info_input" ref="operate_fee" addonBefore={this.strCurrency} disabled
                                value={fOperateFee} onChange={this.FeeChange.bind(this, 1)}
                                defaultValue={fOperateFee} />
                            <Checkbox onChange={this.ExtraServiceSelect.bind(this, 0)} style={{ marginLeft: '20px' }} checked={this.bPhotoFee} disabled={bExtraDisable}>
                                {"合箱拍照(" + this.strCurrency + " " + strPhotoFee + ")"}</Checkbox>
                            <Checkbox onChange={this.ExtraServiceSelect.bind(this, 1)} style={{ marginLeft: '40px' }} checked={this.bFirmFee} disabled={bExtraDisable}>
                                {"特殊加固(" + this.strCurrency + " " + strFirmFee + "/箱)"}</Checkbox>
                            <Input className="user_edit_info_input" style={{ maxWidth: '100px' }} ref="firm_fee" defaultValue={this.objCurrentPackageCombine.firm_fee}
                                value={this.objCurrentPackageCombine.firm_fee} onChange={this.FeeChange.bind(this, 2)} disabled={bExtraEdit} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="express_enter_edit_title" style={{ color: 'darkorange', fontWeight: 'bold' }}>费用总计：</div>
                            <div className="user_edit_info_input" style={{ color: 'darkorange', fontWeight: 'bold' }}>
                                {this.strCurrency + " " + this.objCurrentPackageCombine.total_price}</div>
                            <div className="express_enter_edit_title">备注信息：</div>
                            <Input className="express_enter_item_info_input_long" ref="comments"
                                defaultValue={this.objCurrentPackageCombine.comments} />
                        </div>
                    </div>
                    {uiUploadedFiles}
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">用户信息：</div>
                        {uiUserInfo}
                        <div className="express_enter_express_info_add_button" onClick={this.AddNewExpressEnterInfo}
                            style={{ color: 'white', fontWeight: 'bold', background: 'darkorange' }} >+ 添加快递</div>
                    </div>
                    <div className="express_enter_info_list_title">
                        <div className="sooning_express_enter_info_list_title_tracking_no">物流单号</div>
                        <div className="sooning_express_enter_info_list_title_tracking_no">中文品名</div>
                        <div className="sooning_express_enter_info_list_title_tracking_no">英文品名</div>
                        <div className="sooning_express_enter_info_list_title_tracking_no" style={{ width: '150px', border: 'none' }}>物品数量</div>
                    </div>
                    {uiExpressEnterList}
                    {uiViewItemDetails}
                    {uiSelectReceiverDlg}
                </Modal >
            );
            if (this.state.iDlgType === 2) {
                uiModal = null;
                if (this.state.bShowDlg) {
                    let strPackageNo = "";
                    for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
                        strPackageNo += this.arrSelectedPackageCombine[i].package_no;
                        if (i !== this.arrSelectedPackageCombine.length - 1)
                            strPackageNo += ", ";
                    }
                    uiModal = (
                        <Modal width={450} title={"删除运单"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                确定删除选中的运单？<br /><br />
                                运单编号：<br />
                                {strPackageNo}
                            </div>
                        </Modal>
                    );
                }
            }

            let uiTable = (
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '95%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({
                        onClick: () => { this.selectRow(record); },
                        onDoubleClick: () => { this.DoubleClickRow(record); }
                    })} />
            );
            let uiViewQRCodeDlg = (
                <Modal title={strTitle} width={950} visible={this.bViewQRCodeDlg} footer={null}
                    okText="确定" cancelText="取消" onOk={this.ViewQRCode.bind(this, -1, false)}
                    onCancel={this.ViewQRCode.bind(this, -1, false)} >
                    <div className="user_edit_info_row">
                        this is qr code
                    </div>
                </Modal >
            );

            // M站布局
            if (this.props.bMStation) {
                strAreaStyle = "store_manage_contents_m";
                paginationProps = {
                    showSizeChanger: true,
                    showQuickJumper: false,
                    showTotal: () => `共 ${this.arrTableData.length} 条 运单信息`,
                    defaultPageSize: 9,
                    defaultCurrent: this.state.iCurrentPage,
                    total: this.arrTableData.length,
                    position: 'bottom'
                };
                let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
                uiTitleArea = (
                    <div>
                        <div className="store_contents_title_m" >
                            <div className="order_filter_title">关键字</div>
                            <Input className="order_search_input" ref="search" defaultValue="" />
                            <Button className="store_manager_control_button" type="primary" onClick={this.PackageCombineLookup} >
                                <Icon type="search" />
                            </Button>
                        </div >
                        <div className="store_contents_title_sec_m" >
                            <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllPackageCombine} >
                                显示全部</Button>
                        </div>
                        <div className="store_contents_title_thd_m" >
                            <Button className="store_manager_control_button" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)}
                                disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" /></Button>
                            <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                                <Icon type="edit" /></Button>
                            <Button className="store_manager_control_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                                <Icon type="close" /></Button>
                        </div>
                    </div>
                );
            }

            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                    {uiViewQRCodeDlg}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default PackageCombineSN;
