import React, { Component } from "react";
import axios from 'axios';

import Modal from 'antd/es/modal';
import Radio from 'antd/es/radio';
import Spin from 'antd/es/spin';
import Input from 'antd/es/input';
import Popover from 'antd/es/popover';
import message from 'antd/es/message';

// Stripe Relevants
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CardSection from './CardSection.js';

// const stripePromise = loadStripe("pk_test_Ck37fMoMJvFwBDTkjTPltHz400q1ObN1bc");
const stripePromise = loadStripe("pk_live_7qiaS8i9Ly6x9jrlZalNGA7c004ryGPVdZ");
// const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const g_strMessageKey = 'updating';

class PaymentMethodDlg extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: false,  // 信息加载标志
            iPaymentMethod: 0,  // 支付方式：无，信用卡，微信，支付宝
            iPaymentCard: -1,  // 当前选中的卡片Index
            bAddNewCard: false,  // 新增卡片标志
            bRemoveCardConfirm: false,  // 确认移除卡片标志

        };
        this.bFirstOpen = true;
        this.arrPaymentMethodCn = ["选择支付方式", "信用卡/借记卡", "微信", "支付宝", "现金"];
        this.arrPaymentMethodEn = ["Select Payment Method", "Credit/Debit Card", "WeChat", "AliPay", "Cash"];
        this.arrPaymentMethod = this.arrPaymentMethodCn;

        this.arrCards = [];  // 用户卡片列表
        this.iConfirmedPaymentMethod = 0;  // 已确认的当前选择的支付方式ID
        this.bNewCardSubmitting = false;  // 提交新卡片标志
        this.strCardHolder = "";
        this.strCurrentLast4 = "";
        this.strCurrentCardBrand = "";
    }
    // 支付方式切换
    PaymentMethodChange = (e) => {
        this.setState({
            ...this.state,
            iPaymentMethod: e.target.value,
        });
    }
    // 确认支付方式
    PaymentMethodConfirm = (bConfirm) => {
        // 获取当前选中的支付方式(未确认的)
        let iPaymentMethod = this.state.iPaymentMethod;
        if (iPaymentMethod <= 0)
            iPaymentMethod = 0;

        // 获取当前选中的卡片(未确认的)
        let iCurrentCard = this.state.iPaymentCard;
        if (bConfirm) {
            // 不支持情况判断
            if (iPaymentMethod !== 1 && iPaymentMethod !== 4) {
                if (iPaymentMethod !== 0)
                    message.info("微信/支付宝支付还未开通，请选择别的支付方式！");
                else
                    message.warning("请选择一种有效的支付方式！");
                return;
            }
            if (iPaymentMethod === 1 && this.state.iPaymentCard < 0) {
                message.warning("还未添加任何卡片！");
                return;
            }
            // 现金和卡片 等支持的方式
            message.loading({ content: '正在保存支付方式……', key: g_strMessageKey });
            // 卡片支付
            if (iPaymentMethod === 1) {
                this.iConfirmedPaymentMethod = this.state.iPaymentMethod;
                iCurrentCard = this.state.iPaymentCard;
                let objCardInfo = { pm_id: this.arrCards[iCurrentCard].id }
                // 更新默认支付方式为用户最新确认使用的卡片
                axios.post('/UpdateDefaultPaymentMethod', {
                    objCardInfo: objCardInfo, objUserInfo: this.props.objUserInfo
                }).then(({ data }) => {
                    for (let i = 0; i < this.arrCards.length; i++) {
                        if (this.arrCards[i].id === objCardInfo.pm_id) {
                            this.strCurrentLast4 = this.arrCards[i].card.last4;
                            this.strCurrentCardBrand = this.arrCards[i].card.brand;
                            break;
                        }
                    }
                    this.props.PaymentMethodResult(bConfirm, this.strCurrentCardBrand, this.strCurrentLast4,
                        this.iConfirmedPaymentMethod, this.arrCards[iCurrentCard]);
                    this.bNewCardSubmitting = false;
                    this.setState({
                        ...this.state,
                        bSelectPaymentDlg: false,
                        iPaymentMethod: iPaymentMethod,
                        iPaymentCard: iCurrentCard,
                        bAddNewCard: false,
                        bRemoveCardConfirm: false,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
                // 更新用户表中的默认支付方式ID
                this.props.objUserInfo.payment_id = iCurrentCard;
                console.log("confirm current card = " + iCurrentCard);
                axios.post('/UpdateUserPaymentMethod', { objUserInfo: this.props.objUserInfo })
                    .then(({ data }) => {
                        if (data.status !== 1) {
                            message.warning({ content: "用户支付信息更新失败,请重新尝试!", key: g_strMessageKey, duration: 2 });
                        } else {
                            message.success({ content: "支付方式已保存!", key: g_strMessageKey, duration: 2 });
                        }
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
            }
            // 现金支付
            if (iPaymentMethod === 4) {
                this.iConfirmedPaymentMethod = -4;
                this.props.objUserInfo.payment_id = -4;
                axios.post('/UpdateUserPaymentMethod', { objUserInfo: this.props.objUserInfo })
                    .then(({ data }) => {
                        if (data.status !== 1) {
                            message.warning({ content: "用户支付信息更新失败,请重新尝试!", key: g_strMessageKey, duration: 2 });
                        } else {
                            message.success({ content: "支付方式已保存!", key: g_strMessageKey, duration: 2 });
                        }
                        this.props.PaymentMethodResult(bConfirm, this.strCurrentCardBrand, this.strCurrentLast4,
                            this.iConfirmedPaymentMethod, this.arrCards[iCurrentCard]);
                        this.bNewCardSubmitting = false;
                        this.setState({
                            ...this.state,
                            bSelectPaymentDlg: false,
                            iPaymentMethod: 4,
                            iPaymentCard: 0,
                            bLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }
        // 取消更改支付方式,则需要还原之前的支付方式以及卡片
        else {
            iPaymentMethod = this.iConfirmedPaymentMethod;
            if (this.state.iPaymentCard === -1)
                iPaymentMethod = -1;
            this.props.PaymentMethodResult(bConfirm, this.strCurrentCardBrand, this.strCurrentLast4, iPaymentMethod, {});
            if (this.props.objUserInfo.payment_id !== undefined) {
                if (this.props.objUserInfo.payment_id !== null) {
                    iCurrentCard = this.props.objUserInfo.payment_id;
                    console.log("取消了选择，当前的卡片恢复为之前的记录： " + iCurrentCard);
                } else {
                    iCurrentCard = -1;
                }
            } else {
                iCurrentCard = -1;
            }
            if (iCurrentCard >= 0) {
                this.strCurrentLast4 = this.arrCards[iCurrentCard].card.last4;
                this.strCurrentCardBrand = this.arrCards[iCurrentCard].card.brand;
            }
            this.bNewCardSubmitting = false;
            this.setState({
                ...this.state,
                bSelectPaymentDlg: false,
                iPaymentMethod: iPaymentMethod,
                iPaymentCard: iCurrentCard,
                bAddNewCard: false,
                bRemoveCardConfirm: false,
                bLoading: false
            });
        }
    }
    // Get Customer's Exist Cards List
    GetCardList = () => {
        if (this.props.objUserInfo < 0) {
            return;
        }
        if (this.props.objUserInfo.payment_id === null) {
            return;
        }
        // 设置加载状态
        this.setState({
            ...this.state,
            bAddNewCard: false,
            bRemoveCardConfirm: false,
            bLoading: true
        });
        // 现金/微信/支付宝
        if (this.props.objUserInfo.payment_id < 0) {
            this.iConfirmedPaymentMethod = this.props.objUserInfo.payment_id * -1;
            axios('/ListCustomerCards', {
                params: this.props.objUserInfo
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.arrCards = data.confirm;
                } else {
                    console.log(data.message);
                }
                this.setState({
                    ...this.state,
                    iPaymentMethod: this.iConfirmedPaymentMethod,
                    iPaymentCard: 0,
                    bAddNewCard: false,
                    bRemoveCardConfirm: false,
                    bLoading: false
                });
            }).catch(function (error) { console.log(error); });
        }
        // 使用卡片支付
        else {
            axios('/ListCustomerCards', {
                params: this.props.objUserInfo
            }).then(({ data }) => {
                console.log('ListCustomerCards : ', data);
                // 未找到有效的用户卡片列表
                if (data.status === -1) {
                    console.log(data.message);
                    this.setState({
                        ...this.state,
                        iPaymentMethod: 1,
                        bAddNewCard: false,
                        bRemoveCardConfirm: false,
                        bLoading: false
                    });
                }
                // 找到了有效的用户卡片列表
                else {
                    this.arrCards = data.confirm;
                    this.bNewCardSubmitting = false;
                    if (this.arrCards.length < 0) {
                        this.props.objUserInfo.payment_id = null;
                        this.iConfirmedPaymentMethod = 0;
                        // 更新用户的支付信息
                        axios.post('/UpdateUserPaymentMethod', { objUserInfo: this.props.objUserInfo })
                            .then(({ data }) => {
                                if (data.status !== 1) {
                                    message.warning("用户支付信息更新失败,请重新尝试!");
                                }
                            }).catch(function (error) { console.log(error); });
                    }
                    if (this.bFirstOpen) {
                        this.bFirstOpen = false;
                        this.GetCustomerDefaultPaymentMethod();
                    } else {
                        this.setState({
                            ...this.state,
                            iPaymentMethod: 1,
                            bAddNewCard: false,
                            bRemoveCardConfirm: false,
                            bLoading: false
                        });
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    // 获取用户当前的支付方式（如果是卡片，获取卡片相应信息）
    GetCustomerDefaultPaymentMethod = () => {
        axios('/GetUserPaymentMethod', {
            params: { code: this.props.objUserInfo.code }
        }).then(({ data }) => {
            console.log('GetUserPaymentMethod: ', data);
            let iCardID = 0;
            if (data.length > 0) {
                if (data[0].payment_id !== undefined && data[0].payment_id !== null && data[0].payment_id !== '') {
                    // 卡片
                    if (data[0].payment_id >= 0) {
                        iCardID = data[0].payment_id;
                        this.iConfirmedPaymentMethod = 1;
                        this.strCurrentCardBrand = this.arrCards[iCardID].card.brand;
                        this.strCurrentLast4 = this.arrCards[iCardID].card.last4;
                    }
                }
            }
            this.setState({
                ...this.state,
                iPaymentMethod: 1,
                iPaymentCard: iCardID,
                bAddNewCard: false,
                bRemoveCardConfirm: false,
                bLoading: false
            });
        }).catch(function (error) { console.log(error); });
    }
    // 显示添加新卡的表单
    AddNewCardFormShow = (bShow) => {
        this.setState({
            ...this.state,
            bAddNewCard: bShow
        });
    }
    // 解绑（删除）卡片
    RemoveCardFromCustomer = () => {
        this.setState({
            ...this.state,
            bRemoveCardConfirm: false
        });
        message.loading({ content: '正在删除卡片……', key: g_strMessageKey });
        axios.post('/RemoveCardFromCustomer', {
            pm_id: this.arrCards[this.state.iPaymentCard].id
        }).then(({ data }) => {
            console.log(data);
            message.info({ content: "卡片删除成功！", key: g_strMessageKey, duration: 2 });
            // 如果当前删除的卡片是目前的默认支付卡片，则当前选中（还未确认）的以及用户默认支付的更改为第1张卡片，无论是否点击确认按钮
            if (this.state.iPaymentCard === this.props.objUserInfo.payment_id) {
                this.props.objUserInfo.payment_id = 0;
            } else {
                // 如果当前删除的卡片是在默认卡片的前面
                if (this.state.iPaymentCard < this.props.objUserInfo.payment_id) {
                    this.props.objUserInfo.payment_id--;
                }
            }
            this.GetCardList();
        }).catch(function (error) { console.log(error); });
    }
    // 显示删除卡片提示框
    ShowRemoveReminder = (bShow) => {
        this.setState({
            ...this.state,
            bRemoveCardConfirm: bShow
        });
    }
    // 切换用于支付的卡片
    PaymentCardChange = (event) => {
        this.setState({
            ...this.state,
            iPaymentCard: event.target.value
        });
    }
    // Card Hold Input Change
    CardHolderInputChange = (e) => {
        this.strCardHolder = e.target.value;
    }
    componentDidMount() {
        if (this.props.bEnglish)
            this.arrPaymentMethod = this.arrPaymentMethodEn;
        this.GetCardList();
    }

    render() {

        if (this.props.bEnglish)
            this.arrPaymentMethod = this.arrPaymentMethodEn;
        else
            this.arrPaymentMethod = this.arrPaymentMethodCn;

        let arrTextCn = ['该功能即将上线，敬请期待', '请填写持卡人姓名！', '正在提交卡片信息……', '卡片提交成功！', '卡片提交失败！',
            '持卡人（* 必填）', '卡号（* 必填）', '< 返回', '提交卡片', '还未添加信用卡/借记卡',
            '+ 添加卡片', '确认', '取消', '确定删除该卡片？', '到期：',
            '请选择一种支付方式', '请联系商家微信进行转账', '请在提货时使用现金支付'];
        let arrTextEn = ['Coming up soon', 'Card Holder Name Required!', 'Submitting Card ...', 'Card Submit Succeed!', 'Card Submit Failed!',
            'Card Holder (*Require)', 'Card No. (*Require)', '< Back', 'Submit', 'No Card',
            '+ Card', 'Confirm', 'Cancel', 'Remove the card?', 'Expire：',
            'Select your payment method', 'Please add store Wechat', 'Cash Payment'];
        let arrText = arrTextCn;
        if (this.props.bEnglish)
            arrText = arrTextEn;

        let strTitle = "请选择支付方式";
        if (this.props.strTitle !== "")
            strTitle = this.props.strTitle;
        let uiPaymentDetails = (<div>{arrText[0]}</div>);
        // 还未选择支付方式
        if (this.state.iPaymentMethod === 0) {
            uiPaymentDetails = (<div>{arrText[15]}</div>);
        }
        // 信用卡/借记卡 支付方式的信息确认区域
        if (this.state.iPaymentMethod === 1) {
            // 添加新卡片的表单控件
            if (this.state.bAddNewCard) {
                const AddNewCardForm = () => {
                    const stripe = useStripe();
                    const elements = useElements();
                    // Handle the form submit
                    const handleSubmit = async (event) => {
                        event.preventDefault();
                        if (this.bNewCardSubmitting)
                            return;
                        this.bNewCardSubmitting = true;
                        let card_object = {};
                        if (this.strCardHolder.trim() === "") {
                            message.warning(arrText[1]);
                            return;
                        } else {
                            card_object = {
                                type: 'card',
                                card: elements.getElement(CardElement),
                                billing_details: { name: this.strCardHolder }
                            };
                        }
                        message.loading({ content: arrText[2], key: g_strMessageKey });
                        const { error, paymentMethod } = await stripe.createPaymentMethod(card_object);
                        if (!error) {
                            const { id } = paymentMethod;
                            let objCardInfo = { pm_id: id, card_holder: this.strCardHolder };
                            try {
                                const { data } = await axios.post("/AddCardToCustomer",
                                    { objUserInfo: this.props.objUserInfo, objCardInfo: objCardInfo });
                                console.log("Stripe: New Card Submit Succeed >>> data = ", data);
                                message.info({ content: arrText[3], key: g_strMessageKey, duration: 2 });
                                // 成功新增一张卡片后，会默认出现在列表第一位，因此之前记录的用户的默认支付卡片编号需要+1
                                if (this.props.objUserInfo.payment_id !== null) {
                                    if (this.props.objUserInfo.payment_id >= 0) {
                                        this.props.objUserInfo.payment_id += 1;
                                    } else {
                                        this.props.objUserInfo.payment_id = 0;
                                    }
                                } else {
                                    this.props.objUserInfo.payment_id = 0;
                                }
                                this.GetCardList();
                            } catch (error) {
                                message.warning({ content: arrText[4], key: g_strMessageKey, duration: 2 });
                                console.log("Stripe Error >>> ", error);
                            }

                        } else {
                            console.log("Stripe >>> handleSubmit Error : ");
                            console.log(error);
                            message.warning({ content: error.message, key: g_strMessageKey, duration: 2 });
                        }
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="payment_tips">{arrText[5]}</div>
                            <Input className="payment_info_input" id="name" type="name" placeholder=""
                                required={true} autoComplete="name"
                                onChange={this.CardHolderInputChange}
                                style={{ marginBottom: "2.5vw" }} />
                            <div className="payment_tips">{arrText[6]}</div>
                            <CardSection />
                            <div className="new_card_button_area">
                                <div className="new_card_cancle_button" type="button"
                                    onClick={this.AddNewCardFormShow.bind(this, false)}>
                                    {arrText[7]}</div>
                                <button className="new_card_submit_button" type="submit">{arrText[8]}</button>
                            </div>
                        </form>
                    );
                }
                uiPaymentDetails = (
                    <Elements stripe={stripePromise}>
                        <AddNewCardForm />
                    </Elements>
                );
            }
            // 用户已有卡片列表
            else {
                const radioStyle = {
                    display: 'flex', alignItems: 'center',
                    width: '100%', height: '10vw', lineHeight: '10vw',
                };
                let uiCardList = (
                    <div className="no_card_yet_row">
                        <div>{arrText[9]}</div>
                        <div className="add_new_card_button align_right" onClick={this.AddNewCardFormShow.bind(this, true)}>{arrText[10]}</div>
                    </div>
                );
                let uiConfirmRemove = (
                    <div className="card_remove_confirm_area">
                        <div className="card_remove_confirm_button" onClick={this.RemoveCardFromCustomer}>{arrText[11]}</div>
                        <div className="card_remove_cancel_button" onClick={this.ShowRemoveReminder.bind(this, false)}>{arrText[12]}</div>
                    </div>
                );
                if (this.arrCards.length > 0) {
                    uiCardList = (
                        <div>
                            <Radio.Group onChange={this.PaymentCardChange} value={this.state.iPaymentCard}
                                style={{ marginBottom: "3vw", fontSize: "3.5vw" }}>
                                {this.arrCards.map((item, index) => {
                                    let uiCardBrand = <div className="card_brand_icon master_icon" />
                                    if (item.card.brand === "visa")
                                        uiCardBrand = <div className="card_brand_icon visa_icon" />
                                    let uiRemoveButton = <div />;
                                    if (index === this.state.iPaymentCard) {
                                        uiRemoveButton = (
                                            <Popover title={arrText[13]} placement="left" content={uiConfirmRemove} trigger="click"
                                                visible={this.state.bRemoveCardConfirm}>
                                                <div className="card_remove_button" onClick={this.ShowRemoveReminder.bind(this, true)}> x </div>
                                            </Popover>
                                        );
                                    }
                                    let bCheck = false;
                                    if (index === this.state.iPaymentCard)
                                        bCheck = true;
                                    return (
                                        <Radio style={radioStyle} value={index} key={index} checked={bCheck}>
                                            <div className="card_info_row">
                                                {uiCardBrand}
                                                <div className="card_info_element">{item.card.last4}</div>
                                                <div className="card_info_element">{arrText[14] + item.card.exp_month + "/" + item.card.exp_year}</div>
                                                {uiRemoveButton}
                                            </div>
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                            <div className="add_new_card_button" onClick={this.AddNewCardFormShow.bind(this, true)}>
                                {arrText[10]}</div>
                        </div>
                    );
                }
                uiPaymentDetails = uiCardList;
            }
        }
        // 微信支付
        if (this.state.iPaymentMethod === 2) {
            let strWechat = "";
            if (this.props.objStoreInfo)
                strWechat = this.props.objStoreInfo.wechat;
            uiPaymentDetails = (
                <div>
                    {arrText[16]}
                    <img className="user_center_order_detials_store_qrcode" src={strWechat} alt='' />
                </div>
            );
        }
        // 现金支付
        if (this.state.iPaymentMethod === 4) {
            uiPaymentDetails = (<div>{arrText[17]}</div>);
        }


        // 正在加载中控件……
        let uiLoading;
        if (this.state.bLoading) {
            uiLoading = (
                <div className="whole_page_loading_cover">
                    <Spin tip="处理中……" size="large" />
                </div>
            );
        } else {
            uiLoading = (
                <div className="whole_page_loading_cover_hide" />
            );
        }

        let uiCardPayment = null;
        if (this.props.iStoreType !== 1)
            uiCardPayment = (
                <Radio value={1}> {this.arrPaymentMethod[1]} </Radio>
            );

        return (
            <Modal title={strTitle} visible={this.props.bSelectPaymentDlg} okText={arrText[11]} cancelText={arrText[12]} centered={true}
                onOk={this.PaymentMethodConfirm.bind(this, true)} onCancel={this.PaymentMethodConfirm.bind(this, false)}>
                <Radio.Group onChange={this.PaymentMethodChange} value={this.state.iPaymentMethod}>
                    {/* <Radio value={4}> {this.arrPaymentMethod[4]} </Radio> */}
                    {uiCardPayment}
                    {/* <Radio value={2}> {this.arrPaymentMethod[2]} </Radio>
                    <Radio value={3}> {this.arrPaymentMethod[3]} </Radio> */}
                </Radio.Group>
                <div className="order_details_payment_method">
                    {uiPaymentDetails}
                    {uiLoading}
                </div>
            </Modal>
        );
    }
}
export default PaymentMethodDlg;
