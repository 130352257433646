import React, { Component } from "react";

import Empty from 'antd/es/empty';

// import { Empty } from 'antd';
import '../../../styles/ClientSide/UI_Module/store_item_list.css';
import '../../../styles/icon_image.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

// The list of a store's item or service
class StoreItemList extends Component {
    constructor(props) {
        super();
        this.state = {
            bCartInfo: false
        };

        this.arrItemsData = [];
        this.arrBudgetItems = [];
        this.iItemTotalNum = 0;
        this.fItemTotalPrice = 0.0;
        this.iItemID = -1;
        // this.objCart = {
        //     arrBudgetItems: [], iItemTotalNum: 0, fItemTotalPrice: 0.0
        // };
    }

    // 点击了商品缩略图
    ItemThumbClicked = (iItemID, iItemIndex, strItemName) => {
        this.iItemID = iItemID;
        // DataStatistic
        UserActiveUpdateBack(13, {
            active: "点击商品缩略图", id: iItemID, order: iItemIndex, name: strItemName
        }, this.JumpToItemDetail);
    }

    // 跳转到商品页
    JumpToItemDetail = () => {
        window.location.href = '/webapp/store/' + this.props.iStoreID + '/item/' + this.iItemID;
    }

    // 加号按钮点击响应
    AddBtnClicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            let bExistItem = false;
            if (this.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.arrBudgetItems.length; i++) {
                    if (item.id === this.arrBudgetItems[i].item.id) {
                        this.arrBudgetItems[i].num++;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: this.arrBudgetItems[i].num });
                        bExistItem = true;
                        break;
                    }
                }
            }
            if (!bExistItem) {
                let objItem = { num: 0, item: {} };
                objItem.num = 1; objItem.item = item;
                this.arrBudgetItems.push(objItem);
                // DataStatistic
                UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: 1 });
            }
            this.iItemTotalNum++;
            this.fItemTotalPrice += parseFloat(strPrice, 10);
            this.props.RefreshBudget(this.arrBudgetItems, this.iItemTotalNum, this.fItemTotalPrice);
        }
    }
    // 减号按钮点击响应
    RemoveBtnCLicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            if (this.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.arrBudgetItems.length; i++) {
                    if (item.id === this.arrBudgetItems[i].item.id) {
                        this.arrBudgetItems[i].num--;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "从购物车移除商品", item: item.name, num: this.arrBudgetItems[i].num });
                        this.iItemTotalNum--;
                        this.fItemTotalPrice -= parseFloat(strPrice, 10);
                        if (this.arrBudgetItems[i].num <= 0) {
                            this.arrBudgetItems.splice(i, 1);
                        }
                        break;
                    }
                }
            }
            this.props.RefreshBudget(this.arrBudgetItems, this.iItemTotalNum, this.fItemTotalPrice);
        }
    }
    // 刷新物品数量
    RefreshItemNum = (iItemID) => {
        let iItemNum = 0;
        if (this.arrBudgetItems.length > 0) {
            for (let i = 0; i < this.arrBudgetItems.length; i++) {
                if (iItemID === this.arrBudgetItems[i].item.id) {
                    iItemNum = this.arrBudgetItems[i].num;
                    break;
                }
            }
        }
        return iItemNum;
    }

    // 获取购物车信息
    GetCartInfo = () => {
        this.arrBudgetItems = this.props.arrBudgetItems;
        this.iItemTotalNum = this.props.iItemTotalNum;
        this.fItemTotalPrice = this.props.fItemTotalPrice;
    }

    componentDidMount() {

    }

    render() {

        this.GetCartInfo();

        if (this.props.arrItemsData !== undefined) {
            this.arrItemsData = this.props.arrItemsData;
        }
        let uiItemList = (<Empty className="store_item_empty" description="该商家还未上线任何商品/服务" />);
        if (this.arrItemsData.length > 0) {
            uiItemList = (
                <div className="store_item_list">
                    {this.arrItemsData.map((item, index) => {
                        let strImage = item.logo;
                        if (item.logo === "" || item.logo === undefined || item.logo === null)
                            strImage = item.image;
                        let iItemNum = this.RefreshItemNum(item.id);
                        return (
                            <div className="store_item" key={index}>
                                <img className="store_item_icon" src={strImage} alt=''
                                    onClick={this.ItemThumbClicked.bind(this, item.id, index, item.name)} />
                                <div className="store_item_info">
                                    <div className="store_item_name" onClick={this.ItemThumbClicked.bind(this, item.id, index, item.name)}>
                                        {item.name}</div>
                                    <div className="store_item_options">{item.options}</div>
                                    <div className="store_item_info_bottom">
                                        <div className="store_item_price">{"$" + item.price}</div>
                                        <div className="store_item_add_area">
                                            <div className="store_item_add_btn"
                                                onClick={this.RemoveBtnCLicked.bind(this, item)}>－</div>
                                            <div className="store_item_add_num">{iItemNum}</div>
                                            <div className="store_item_add_btn"
                                                onClick={this.AddBtnClicked.bind(this, item)}>＋</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return (
            <div>
                {uiItemList}
            </div>
        );
    }
}
export default StoreItemList;
