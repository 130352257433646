import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

// import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Drawer from 'antd/es/drawer';
import Modal from 'antd/es/modal';
// import Dropdown from 'antd/es/dropdown';
import Collapse from 'antd/es/collapse';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import message from 'antd/es/message';
import TextareaItem from 'antd-mobile/es/textarea-item';
import AdsCarousel from './UI_Module/AdsCarousel.js';

import '../../styles/ClientSide/store_page.css';
import '../../styles/ClientSide/store_pickup_page.css';
import '../../styles/icon_image.css';
import '../../styles/ClientSide/UI_Module/ticket_list.css';
import '../../styles/ClientSide/stripe.css';
import '../../styles/color_set.css';

import PaymentMethodDlg from './UI_Module/PaymentMethodDlg.js'
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { GetItemsID } from "../../functions/DataStatistic.js"
import { StripePriceCalculate } from "../../functions/DataStatistic.js"

const mapStyles = { width: '83vw', height: '60vw', borderRadius: '2.5vw' };

const { Panel } = Collapse;

class StorePickup extends Component {
    constructor(props) {
        super();
        let strParams = window.location.search;
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        let iTab = parseInt(strParams.split('&')[0].split('=')[1], 10);
        if (iTab !== 0 && iTab !== 1 && iTab !== 2) {
            iTab = 0;
        }
        this.state = {
            bLoading: false,
            bItemListLoading: true,
            iCurrentTab: iTab,
            iCurrentItemType: 0,
            objStorePos: { lat: props.objStoreInfo.latitude_x, lng: props.objStoreInfo.longitude_y },
            strFormattedAddress: "Richmond Center",
            arrItemsData: [],
            bBudgetUpdate: true,
            bOrderDetails: false,
            bBudgetDetails: false,
            bLogin: false,
            bStaring: false,
            bPaymentDlg: false,
            bEnglish: false,
            bMainMenu: false,
            bItemDetails: false,
            arrItemTypeOpen: [],
            objOption: { iOptionType: 0, iOptionIndex: 0 },
            iCurrentItemNum: 1,
            strSpecialRequests: "",
            bSelectPaymentDlg: false,
            bPaymentResultDlg: false,
            iPaymentResult: 0,
            iUserCenterReminder: 0,
            iContactInfoDlg: -1,
            bContactInfoDlg: false,
            bLoginReminderDlg: false,
            iDeliveryWay: -1
        };

        this.audioNewOrder = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1603042409/0-%E9%B2%B8%E5%9B%BEWhaleMap/NewMessage_glprkc.mp3');
        this.audioNewOrder.load();

        this.arrItemTypeData = [
            { id: 0, store_id: this.iStoreID, type_name: "全部", type_order: 0, description: "" }
        ];

        this.arrItemsData = [];
        this.arrTicketsData = -1;
        this.arrTicketsStatus = [];
        this.arrOpenTime = [
            "周一 09:00am - 09:00pm",
            "周二 09:00am - 09:00pm",
            "周三 09:00am - 09:00pm",
            "周四 09:00am - 09:00pm",
            "周五 09:00am - 09:00pm",
            "周六 09:00am - 09:00pm",
            "周日 09:00am - 09:00pm"
        ];
        this.arrOpenTime = props.objStoreInfo.open_time.split(',');
        this.objStoreInfo = props.objStoreInfo;
        this.iCurrentDate = 0;
        this.arrTabTriggered = [false, false, false];
        this.arrTicketType = ["折扣券", "代金券", "兑换券"];
        this.objCart = {
            arrBudgetItems: [], iItemTotalNum: 0, fItemTotalPrice: 0.0
        };
        this.strCurrentUserCode = "";
        this.objUserInfo = -1;
        this.bStarred = false;
        this.bOrderDetails = false;  // 从地址页返回商家页时如果订单详情之前是打开的，用此参数作判断标志，等用户信息获取完毕后在打开订单详情
        this.iCurrentTab = 0;

        this.arrMainMenu = [
            { cn: "关于商家", en: "About", icon: "shop" },
            { cn: "浏览菜单", en: "Menu", icon: "read" },
            // { cn: "购物车", en: "Cart", icon: "shopping-cart" },
            { cn: "个人中心", en: "Profile", icon: "user" },
            // { cn: "联系我们", en: "Contact", icon: "phone" },
            { cn: "中文→English", en: "English→中文", icon: "message" }
        ]
        this.arrMainTab = [
            { cn: "商家信息", en: "About" },
            { cn: "自提下单", en: "Order" }
        ];
        this.arrItemTypeOpen = [];
        this.objCurrentItem = -1;
        this.arrCurrentOptionsCN = [];
        this.arrCurrentOptionsEN = [];
        this.fCurrentItemTotalPrice = 0.0;
        this.fCartTotalPrice = 0.0;
        this.arrCurrentCart = [];
        this.arrPaymentMethod = ["选择支付方式", "信用卡/借记卡", "微信", "支付宝"];
        this.iConfirmedPaymentMethod = 0;
        this.strCurrentLast4 = "";
        this.strCurrentCardBrand = "";
        this.objCardInfo = {};
        this.arrCards = [];
        this.strOrderID = "";
        this.bModifyItem = false;
        this.iCurrentModifyItem = 0;

        this.arrStoreList0 = [76];  // 卡片；物流邮寄
        this.arrStoreList1 = [72, 79, 82, 112];   // 现金; 自提
        this.arrStoreList2 = [112, 82];    // 英文优先
    }

    // 获取商家位置信息
    GetStoreLocation = (strAddress) => {
        let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        axios.get(strUrl)
            .then((response) => {
                this.setState({
                    ...this.state,
                    bLoading: false,
                    objStorePos: response.data.results[0].geometry.location,
                    strFormattedAddress: response.data.results[0].formatted_address
                });
            }).catch(err => {
                console.log(err) //Axios entire error message
                console.log(err.response.data.error) //Google API error message 
            });
    }
    // 切换商品类别
    SwitchItemType = (iTypeIndex) => {
        this.setState({
            ...this.state,
            bItemListLoading: true
        });
        // DataStatistic
        UserActiveUpdate(12, { active: "切换商品分类", type: iTypeIndex, name: this.arrItemTypeData[iTypeIndex].type_name });
        // 获取所有商品信息
        let strType = '%';
        if (iTypeIndex !== 0)
            strType += this.arrItemTypeData[iTypeIndex].id.toString() + '%';
        axios('/GetItems', { params: { store_id: this.iStoreID, type: strType } })
            .then(({ data }) => {
                this.arrItemsData = data;
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bItemListLoading: false,
                    iCurrentItemType: iTypeIndex,
                });
            });
    }
    // 排队按钮响应
    LineUpClicked() {
        console.log("line up btn clicked...");
    }
    // 日期转换为索引
    DateToIndex(strDate) {
        switch (strDate) {
            case 'Mon': {
                this.iCurrentDate = 0;
                break;
            }
            case 'Tue': {
                this.iCurrentDate = 1;
                break;
            }
            case 'Wed': {
                this.iCurrentDate = 2;
                break;
            }
            case 'Thu': {
                this.iCurrentDate = 3;
                break;
            }
            case 'Fri': {
                this.iCurrentDate = 4;
                break;
            }
            case 'Sat': {
                this.iCurrentDate = 5;
                break;
            }
            case 'Sun': {
                this.iCurrentDate = 6;
                break;
            }
            default: {
                this.iCurrentDate = 0;
                break;
            }
        }
    }
    // 标签页被点击的响应
    TabChanged = (activeKey) => {
        let iTab = parseInt(activeKey, 10);
        this.setState({
            ...this.state,
            iCurrentTab: iTab
        });
        this.iCurrentTab = iTab;
        // DataStatistic
        UserActiveUpdate(8, { active: "商家标签页点击", tab: iTab });

        if (!this.arrTabTriggered[iTab]) {
            this.arrTabTriggered[iTab] = true;
            switch (iTab) {
                case 0: {
                    // 4, 获取所有商品信息
                    this.GetStoreItems();
                    this.SwitchItemType(0);
                    break;
                }
                case 1: {
                    // 5, 获取所有优惠券信息
                    this.GetStoreTickets();
                    break;
                }
                case 2: {
                    // 6. 获取商家位置信息
                    this.GetStoreLocation(this.objStoreInfo.address);
                    break;
                }
                default: break;
            }
        } else {
            if (iTab === 0) {
                this.SwitchItemType(this.state.iCurrentItemType);
            }
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === 1) {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.collect_stores === null ||
                        this.objUserInfo.collect_stores === "") {
                        this.bStarred = false;
                    } else {
                        if (this.objUserInfo.collect_stores.indexOf(this.objStoreInfo.id.toString())
                            === -1)
                            this.bStarred = false;
                        else
                            this.bStarred = true;
                    }
                    if (this.state.objStorePos.lat === null || this.state.objStorePos.lng === null)
                        this.GetStoreLocation(this.objStoreInfo.address);
                    this.setState({
                        ...this.state,
                        bOrderDetails: this.bOrderDetails,
                        bLogin: true
                    });
                } else {
                    this.objUserInfo = -1;
                    this.setState({
                        ...this.state,
                        bLogin: false
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 收藏按钮点击响应
    StarBtnClicked = () => {
        if (this.state.bStaring)
            return;
        else
            this.setState({ ...this.state, bStaring: true });
        // 还未登录
        if (this.objUserInfo === -1) {
            message.info("请先注册/登录！");
            // message.info({ content: "请先注册/登录！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLogin: false,
                bStaring: false
            });
        } else {  // 已有用户信息
            // 还未收藏
            if (!this.bStarred) {
                if (this.objUserInfo.collect_stores === null)
                    this.objUserInfo.collect_stores = "";
                if (this.objUserInfo.collect_stores === "")
                    this.objUserInfo.collect_stores = this.objStoreInfo.id.toString();
                else
                    this.objUserInfo.collect_stores += "," + this.objStoreInfo.id.toString();
            } else {  // 已收藏
                let arrCollectStores = this.objUserInfo.collect_stores.split(',');
                let strNewCollects = "";
                for (let i = 0; i < arrCollectStores.length; i++) {
                    if (arrCollectStores[i] !== this.objStoreInfo.id.toString()) {
                        if (strNewCollects === "")
                            strNewCollects += arrCollectStores[i].toString();
                        else
                            strNewCollects += "," + arrCollectStores[i].toString();
                    }
                }
                this.objUserInfo.collect_stores = strNewCollects;
            }
            // 更新用户表中的商家收藏信息
            axios.post('/UpdateUserCollectStores', { objUserInfo: this.objUserInfo })
                .then(({ data }) => {
                    if (data.status === 1) {
                        if (!this.bStarred) {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "关注商家" });
                            message.success("已关注！");
                            // message.success({ content: "已关注！", key: g_strMessageKey, duration: 2 });
                        } else {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "取关商家" });
                            message.info("已取关！");
                            // message.success({ content: "已取关！", key: g_strMessageKey, duration: 2 });
                        }
                        this.bStarred = !this.bStarred;
                        this.setState({
                            ...this.state,
                            bLogin: true,
                            bStaring: false
                        });
                    } else {
                        message.warning("关注失败，请重新登录后尝试！");
                        // message.warning({
                        //     content: "关注失败，请重新登录后尝试！",
                        //     key: g_strMessageKey, duration: 2
                        // });
                        this.setState({
                            ...this.state,
                            bStaring: false
                        });
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 预算计算
    RefreshBudget = (arrBudgetItems, iItemTotalNum, fItemTotalPrice) => {

        if (iItemTotalNum <= 0) {
            iItemTotalNum = 0;
            fItemTotalPrice = 0.0;
        }
        this.objCart = {
            arrBudgetItems: arrBudgetItems,
            iItemTotalNum: iItemTotalNum,
            fItemTotalPrice: fItemTotalPrice
        }
        if (iItemTotalNum === 0)
            cookie.save('wm_user_cart', "", { path: '/' });
        else
            cookie.save('wm_user_cart', this.objCart, { path: '/' });
        this.setState({
            ...this.state,
            bBudgetUpdate: true
        });
    }
    // 预算详情抽屉控制
    ShowBudgetDetails = (bShow, e) => {
        if (bShow) {
            // DataStatistic
            let arrItems = GetItemsID(this.objCart);
            UserActiveUpdate(15, { active: "打开购物车", arrItems: arrItems });
        }
        this.setState({
            bBudgetDetails: bShow,
        });
    };
    // 打开订单详情
    ShowOrderDetails = () => {
        // 检查商品数量
        if (this.objCart.iItemTotalNum <= 0 && this.objCart.fItemTotalPrice <= 0.0) {
            message.info("还未添加任何商品……");
        } else {
            // 判断登录状态
            if (this.state.bLogin) {

                // 电商则打开订单详情，允许下单支付
                if (this.iStoreType === 1) {
                    // DataStatistic
                    let arrItems = GetItemsID(this.objCart);
                    UserActiveUpdate(16, { active: "打开订单详情", arrItems: arrItems });
                    this.UserCodeCheck();
                    this.bOrderDetails = true;
                    this.setState({
                        bOrderDetails: true
                    });
                }
                // 黄页商家则给出提示
                else {
                    message.info("该商家只提供商品/服务的价格/预算查询");
                }
            } else {
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            }
        }
    };
    // 收起订单详情
    HideOrderDetails = () => {
        // this.bOrderDetails = false;
        this.setState({
            bOrderDetails: false,
        });
    }
    // 商家详情区域点击
    StoreInfoAreaClicked = (iInfo) => {
        // DataStatistic
        UserActiveUpdate(9, { active: "查看商家详情", info: iInfo });
    }
    // 点击了用户按钮（仅限于特制的独立商家）
    UserIconClicked = () => {
        window.location.href = '/webapp/user?tab=3&store=' + this.iStoreID.toString();
    }
    // Cancel Payment / Close Payment Dlg
    CancelPayment = () => {
        this.setState({
            ...this.state,
            bPaymentDlg: false
        });
    }
    AddBtnClicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            let bExistItem = false;
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num++;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        bExistItem = true;
                        break;
                    }
                }
            }
            if (!bExistItem) {
                let objItem = { num: 0, item: {} };
                objItem.num = 1; objItem.item = item;
                this.objCart.arrBudgetItems.push(objItem);
                // DataStatistic
                UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: 1 });
            }
            this.objCart.iItemTotalNum++;
            this.objCart.fItemTotalPrice += parseFloat(strPrice, 10);
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 减号按钮点击响应
    RemoveBtnCLicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num--;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "从购物车移除商品", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        this.objCart.iItemTotalNum--;
                        this.objCart.fItemTotalPrice -= parseFloat(strPrice, 10);
                        if (this.objCart.arrBudgetItems[i].num <= 0) {
                            this.objCart.arrBudgetItems.splice(i, 1);
                        }
                        break;
                    }
                }
            }
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 清空购物车
    ClearCart = () => {
        this.RefreshBudget([], 0, 0);
    }
    // 电商顶部栏Logo按钮点击
    MouthingLogoClicked = () => {
        // DataStatistic
        UserActiveUpdateBack(19, { active: "返回Mouthing平台首页" }, this.JumpToHome);
    }
    // 从电商页返回首页
    JumpToHome = () => {
        window.location.href = '/webapp/home';
    }

    /******** 自提外卖新增函数 ********/
    // 打开主菜单
    OpenMainMenu = (bShow) => {
        this.setState({
            ...this.state,
            bMainMenu: bShow
        });
    }
    // 切换主按钮
    SwitchMainTab = (iTab) => {
        if (iTab === 0) {
            this.setState({
                ...this.state,
                arrItemTypeOpen: [],
                iCurrentTab: iTab,
                bMainMenu: false,
                bViewCart: false
            });
            this.arrItemTypeOpen = [];
        } else {
            this.setState({
                ...this.state,
                iCurrentTab: iTab,
                bMainMenu: false,
                bViewCart: false
            });
        }
    }
    // 营业时间格式处理
    StoreHoursFormatProcess = (strOpenTime) => {
        let objHour = { day: "", hour: "" };
        let strDay = strOpenTime.split(' ')[0];
        strDay = this.CnToEnDay(strDay);
        let strStartHour = strOpenTime.split(' ')[1];
        let strEndHour = strOpenTime.split(' ')[4];
        let strHour = strStartHour + " am - " + strEndHour + " pm";
        if (strStartHour === undefined || strStartHour === null || strStartHour === "" ||
            strEndHour === undefined || strEndHour === null || strEndHour === "") {
            strHour = "不营业";
            if (this.state.bEnglish)
                strHour = "Closed";
        }
        objHour.day = strDay; objHour.hour = strHour;
        return objHour;
    }
    // 中文日转为英文日
    CnToEnDay = (strDay) => {
        let strEnDay = strDay;
        if (this.state.bEnglish) {
            switch (strDay) {
                case "周一": strEnDay = "Mon"; break;
                case "周二": strEnDay = "Tue"; break;
                case "周三": strEnDay = "Wed"; break;
                case "周四": strEnDay = "Thu"; break;
                case "周五": strEnDay = "Fri"; break;
                case "周六": strEnDay = "Sat"; break;
                case "周日": strEnDay = "Sun"; break;
                default: break;
            }
        }
        return strEnDay;
    }
    // 展开/合上某个菜单（商品类别）
    ItemTypeOpen = (key) => {
        this.arrItemTypeOpen = key;
        this.setState({
            ...this.state,
            arrItemTypeOpen: key
        });
    }
    // 处理商品的Modifier (options)
    ItemOptionsProcess = (strOptions, arrSelectedOptions) => {
        if (arrSelectedOptions === -1)
            this.bModifyItem = false;
        else
            this.bModifyItem = true;
        this.arrCurrentOptionsCN = [];
        this.arrCurrentOptionsEN = [];
        if (strOptions === null || strOptions === "") {
            return;
        }
        let arrOptionsType = strOptions.split('\n');
        for (let i = 0; i < arrOptionsType.length; i++) {
            let objOptionInfoCN = {};
            let objOptionInfoEN = {};
            // 选项标题
            let strOptionTypeTitle = arrOptionsType[i].split('=')[0];
            // 多选几
            let strOptionSelectMethod = arrOptionsType[i].split('#')[1];
            objOptionInfoCN.select_method = parseInt(strOptionSelectMethod, 10);
            objOptionInfoEN.select_method = parseInt(strOptionSelectMethod, 10);
            // 必选项
            if (strOptionTypeTitle.indexOf('*') >= 0) {
                strOptionTypeTitle = strOptionTypeTitle.split('*')[1];
                objOptionInfoCN.bRequired = true;
                objOptionInfoEN.bRequired = true;
            } else {
                objOptionInfoCN.bRequired = false;
                objOptionInfoEN.bRequired = false;
            }
            objOptionInfoCN.title = strOptionTypeTitle.split(',')[0].trim();
            objOptionInfoEN.title = strOptionTypeTitle.split(',')[1].trim();
            /******** 选项内容 ********/
            let strOptionContents = (arrOptionsType[i].split('=')[1]).split('#')[0];
            let arrOptions = [];
            if (strOptionContents.indexOf('/') >= 0) {
                arrOptions = strOptionContents.split('/');
            } else {
                arrOptions = [strOptionContents];
            }
            // 获取选项内容
            objOptionInfoCN.contents = [];
            objOptionInfoEN.contents = [];
            for (let j = 0; j < arrOptions.length; j++) {
                let objOptionContentCN = {};
                let objOptionContentEN = {};
                let strOptionName = arrOptions[j].split('$')[0];
                let strPrice = arrOptions[j].split('$')[1];
                objOptionContentCN.name = strOptionName.split(',')[0];
                objOptionContentCN.price = strPrice;
                objOptionContentCN.active = false;
                objOptionContentEN.name = strOptionName.split(',')[1];
                objOptionContentEN.price = strPrice;
                objOptionContentEN.active = false;
                if (this.bModifyItem && arrSelectedOptions[i] !== -1 && arrSelectedOptions[i].name.split(',')[0] === objOptionContentCN.name) {
                    objOptionContentCN.active = true;
                    objOptionContentEN.active = true;
                }
                objOptionInfoCN.contents.push(objOptionContentCN);
                objOptionInfoEN.contents.push(objOptionContentEN);
            }
            if (objOptionInfoCN.select_method === 1 && objOptionInfoCN.bRequired && arrSelectedOptions === -1) {
                objOptionInfoCN.contents[0].active = true;
                objOptionInfoEN.contents[0].active = true;
            }

            this.arrCurrentOptionsCN.push(objOptionInfoCN);
            this.arrCurrentOptionsEN.push(objOptionInfoEN);
        }
    }
    // 在菜单中点击了一个商品
    ClickAnItem = (item) => {
        this.ItemOptionsProcess(item.options, -1);
        this.objCurrentItem = item;
        this.setState({
            ...this.state,
            iCurrentItemNum: 1,
            bItemDetails: true,
            strSpecialRequests: ""
        });
    }
    // 关闭商品详情页
    CloseItemDetails = () => {
        let bViewCart = false;
        if (this.bModifyItem) {
            bViewCart = true;
        }
        this.setState({
            ...this.state,
            bViewCart: bViewCart,
            bItemDetails: false
        });
    }
    // 点击了菜单
    ClickMenu = (index) => {
        // 商家信息
        if (index === 0) {
            this.SwitchMainTab(0);
            // this.setState({
            //     ...this.state,
            //     bMainMenu: false
            // });
        }
        // 查看菜单
        if (index === 1) {
            this.SwitchMainTab(1);
        }
        // 个人中心
        if (index === 2) {
            let strLang = "cn";
            if (this.state.bEnglish)
                strLang = "en";
            window.location.href = '/webapp/user_profile?tab=3&store=' + this.props.objStoreInfo.id + '&lang=' + strLang;
        }
        // 切换中英文
        if (index === 3) {
            this.setState({
                ...this.state,
                bMainMenu: false,
                bEnglish: !this.state.bEnglish
            });
        }
        // 查看购物车
        if (index === 4) {
            this.setState({
                ...this.state,
                bViewCart: true,
                bMainMenu: false
            })
        }
    }
    // 点击了Modifier选项
    ItemModifierOptionCLick = (iOptionType, iOptionIndex, bRadio) => {
        if (bRadio) {
            for (let i = 0; i < this.arrCurrentOptionsCN[iOptionType].contents.length; i++) {
                this.arrCurrentOptionsCN[iOptionType].contents[i].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[i].active = false;
            }
            this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = true;
            this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = true;
        } else {

            if (this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active) {
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = false;
            } else {
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = true;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = true;
            }
            let iSelectNum = 0;
            for (let i = 0; i < this.arrCurrentOptionsCN[iOptionType].contents.length; i++) {
                if (this.arrCurrentOptionsCN[iOptionType].contents[i].active)
                    iSelectNum++;
            }
            if (iSelectNum > this.arrCurrentOptionsCN[iOptionType].select_method) {
                let strRemind = "最多只能选 " + this.arrCurrentOptionsCN[iOptionType].select_method + " 项 ！";
                if (this.state.bEnglish)
                    strRemind = "Max select " + this.arrCurrentOptionsCN[iOptionType].select_method;
                message.warning(strRemind);
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = false;
                return;
            }

        }
        this.setState({
            ...this.state,
            objOption: { iOptionType: iOptionType, iOptionIndex: iOptionIndex }
        });
    }
    // 点击了调整商品数量按钮
    ItemNumChange = (bAdd) => {
        let iItemNum = this.state.iCurrentItemNum;
        if (bAdd) {
            iItemNum++;
            if (iItemNum >= 999)
                iItemNum = 999;
        } else {
            iItemNum--;
            if (iItemNum <= 1)
                iItemNum = 1;
        }
        this.setState({
            ...this.state,
            iCurrentItemNum: iItemNum
        });
    }
    // 备注变更
    SpecialRequestsInput = (e) => {
        this.setState({
            ...this.state,
            strSpecialRequests: e
        })
    }
    // 点击了添加到购物车按钮
    AddToCartButtonClicked = () => {
        if (this.props.objUserInfo === -1) {
            this.setState({
                ...this.state,
                bLoginReminderDlg: true
            });
            return;
        }
        let objCartItem = {};
        objCartItem.id = this.objCurrentItem.id;
        objCartItem.name = this.objCurrentItem.name;
        objCartItem.num = this.state.iCurrentItemNum;
        objCartItem.price = parseFloat(this.fCurrentItemTotalPrice).toFixed(2);
        objCartItem.comments = this.state.strSpecialRequests;
        // 当前物品的Options信息整理
        let arrOptions = [];
        for (let i = 0; i < this.arrCurrentOptionsCN.length; i++) {
            let objOption = -1;
            for (let j = 0; j < this.arrCurrentOptionsCN[i].contents.length; j++) {
                if (this.arrCurrentOptionsCN[i].contents[j].active) {
                    if (objOption === -1) {
                        objOption = {};
                        objOption.index = j;
                        objOption.title = this.arrCurrentOptionsCN[i].title + "," + this.arrCurrentOptionsEN[i].title;
                        objOption.name = this.arrCurrentOptionsCN[i].contents[j].name + "," + this.arrCurrentOptionsEN[i].contents[j].name;
                    } else {
                        objOption.name += "/" + this.arrCurrentOptionsCN[i].contents[j].name + "," +
                            this.arrCurrentOptionsEN[i].contents[j].name;
                    }
                }
            }
            arrOptions.push(objOption);
        }
        objCartItem.options = arrOptions;
        // 编辑购物车中的物品
        if (this.bModifyItem) {
            this.arrCurrentCart[this.iCurrentModifyItem] = objCartItem;
            this.UpdateCartRecord();
            this.setState({
                ...this.state,
                bItemDetails: false,
                bViewCart: true
            });
        }
        // 新增一个物品到购物车
        else {
            if (this.arrCurrentCart === null || this.arrCurrentCart === "")
                this.arrCurrentCart = [];
            this.arrCurrentCart.push(objCartItem);
            this.UpdateCartRecord();
            this.setState({
                ...this.state,
                bItemDetails: false
            });
        }

    }
    // 关闭购物车
    CloseCart = () => {
        this.setState({
            ...this.state,
            bViewCart: false
        });
    }
    // 移除购物车里的商品
    RemoveCartItem = (index) => {
        if (index >= 0) {
            this.arrCurrentCart.splice(index, 1);

        } else {
            this.arrCurrentCart = [];
        }
        this.UpdateCartRecord();
        this.setState({
            ...this.state,
            bViewCart: true
        });
    }
    // 编辑购物车里的商品
    ModifyCartItem = (index) => {
        if (this.arrCurrentCart.length <= 0 || !this.arrCurrentCart[index])
            return;
        for (let i = 0; i < this.props.arrItemsData.length; i++) {
            if (this.props.arrItemsData[i].id === this.arrCurrentCart[index].id) {
                this.objCurrentItem = this.props.arrItemsData[i];
                this.ItemOptionsProcess(this.objCurrentItem.options, this.arrCurrentCart[index].options);
                break;
            }
        }
        this.iCurrentModifyItem = index;
        this.iCurrentTab = 1;
        this.setState({
            ...this.state,
            bViewCart: false,
            bMainMenu: false,
            bItemDetails: true,
            iCurrentTab: 1,
            iCurrentItemNum: this.arrCurrentCart[index].num,
            strSpecialRequests: this.arrCurrentCart[index].comments
        });
    }
    // 更新购物车在用户表中的记录
    UpdateCartRecord = () => {
        this.props.objUserInfo.cart = this.arrCurrentCart;
        axios.post('/UpdateUserCartInfo', {
            id: this.props.objUserInfo.id,
            cart: this.arrCurrentCart
        }).then(({ data }) => {
            if (data.status === 1) {
                // DataStatistic
                UserActiveUpdate(20, { active: "用户购物车信息更新", user_code: this.props.objUserInfo.code });
            }
        }).catch(function (error) { console.log(error); });
    }
    // Get Customer's Exist Cards List
    GetCardList = () => {
        // 当该用户的支付方式不为空，且是使用卡片支付时，采取调取卡片列表信息
        if (this.props.objUserInfo === -1) {
            return;
        }
        if (this.props.objUserInfo.payment_id === null) {
            return;
        }
        if (this.props.objUserInfo.payment_id < 0) {
            return;
        }
        axios('/ListCustomerCards', {
            params: this.props.objUserInfo
        }).then(({ data }) => {
            this.arrCards = data.confirm;
            this.GetCustomerDefaultPaymentMethod();
        }).catch(function (error) { console.log(error); });
    }
    // 获取用户当前的支付方式（如果是卡片，获取卡片相应信息）
    GetCustomerDefaultPaymentMethod = () => {
        axios('/GetUserPaymentMethod', {
            params: { code: this.props.objUserInfo.code }
        }).then(({ data }) => {
            let iCardID = 0;
            if (data[0].payment_id !== null) {
                // 卡片
                if (data[0].payment_id >= 0) {
                    iCardID = data[0].payment_id;
                    this.iConfirmedPaymentMethod = 1;
                    this.strCurrentCardBrand = this.arrCards[iCardID].card.brand;
                    this.strCurrentLast4 = this.arrCards[iCardID].card.last4;
                    this.objCardInfo = this.arrCards[iCardID];
                }
            }
        }).catch(function (error) { console.log(error); });
    }
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付弹窗结果返回
    PaymentMethodResult = (bConfirm, iCardBrand, iCardLast4, iPaymentMethod, objCardInfo) => {
        if (bConfirm) {
            this.strCurrentLast4 = iCardLast4;
            this.strCurrentCardBrand = iCardBrand;
            this.iConfirmedPaymentMethod = iPaymentMethod;
            this.objCardInfo = objCardInfo;
        }
        if (iPaymentMethod === -1) {
            this.strCurrentLast4 = "";
            this.strCurrentCardBrand = "";
            this.iConfirmedPaymentMethod = 0;
            this.objCardInfo = {};
        }
        this.setState({
            ...this.state,
            bSelectPaymentDlg: false
        });
    }
    // 下单付款
    ConfirmAndPay = (objPrice) => {
        this.iConfirmedPaymentMethod = 0;

        // 有效的支付方式检查
        // if (this.iConfirmedPaymentMethod === 0 || this.strCurrentLast4 === "") {
        //     message.warning("无法下单支付，请选择有效的支付方式！");
        //     return;
        // }
        // 有效的联系方式检查
        if (this.props.objUserInfo.contact === null || this.props.objUserInfo.contact.trim() === "") {
            if (this.props.objUserInfo.name === null || this.props.objUserInfo.name.trim() === "") {
                message.warning("联系人不能为空！");
                return;
            } else {
                this.props.objUserInfo.contact = this.props.objUserInfo.name;
            }
        }
        if (this.props.objUserInfo.contact_phone === null || this.props.objUserInfo.contact_phone.trim() === "" || this.props.objUserInfo.contact_phone === "无") {
            if (this.props.objUserInfo.phone === null || this.props.objUserInfo.phone.trim() === "" || this.props.objUserInfo.phone === "无") {
                message.warning("电话不能为空！"); return;
            } else {
                this.props.objUserInfo.contact_phone = this.props.objUserInfo.phone;
                if (this.props.objUserInfo.contact_phone.indexOf('86') >= 0) {
                    message.warning("请填写有效的本地联系电话！"); return;
                }
            }
        }
        // 同步更新注册电话（完善之前的部分用户信息）
        if (this.props.objUserInfo.phone === null || this.props.objUserInfo.phone.trim() === "" || this.props.objUserInfo.phone === "无") {
            this.props.objUserInfo.phone = this.props.objUserInfo.contact_phone;
        }

        // 更新用户联系方式
        this.UpdateUserContactInfo();

        this.setState({
            ...this.state,
            bPaymentResultDlg: true,
            iPaymentResult: 0
        });

        let iItemTotalNum = 0;
        if (this.arrCurrentCart.length > 0) {
            for (let i = 0; i < this.arrCurrentCart.length; i++) {
                iItemTotalNum += this.arrCurrentCart[i].num;
            }
        }
        let objCart = {
            iItemTotalNum: iItemTotalNum,
            fItemTotalPrice: objPrice.fTotalPrice,
        };

        console.log("current cart = ", this.arrCurrentCart);

        // 暂时只支持现金支付自提
        this.props.objUserInfo.payment_id = -4;

        // 刷卡支付
        if (this.props.objUserInfo.payment_id >= 0) {
            console.log("选择了卡片支付！", this.iConfirmedPaymentMethod, this.strCurrentLast4);
            // 有效的支付方式检查
            if (this.iConfirmedPaymentMethod === 0 || this.strCurrentLast4 === "") {
                message.warning("无法下单支付，请选择有效的支付方式！");
                return;
            }
            this.setState({
                ...this.state,
                bPaymentResultDlg: true,
                iPaymentResult: 0
            });
            // 1. Stripe 订单生成，扣款
            axios.post('/PlaceOrderAndPay', {
                objUserInfo: this.props.objUserInfo,
                objCardInfo: this.objCardInfo,
                objCartInfo: objCart,
                objStoreInfo: this.props.objStoreInfo,
                fPriceOff: 0
            }).then(({ data }) => {
                console.log("支付完成后的Strip数据返回", data);
                // 支付成功
                if (data.message === "succeed" && data.pid !== "") {
                    this.setState({
                        ...this.state,
                        bPaymentResultDlg: true,
                        iPaymentResult: 1
                    });
                    this.OrderRecordInDB(data, objCart, objPrice);
                }
                // 支付失败（卡片无效/网络问题？等）
                else {
                    this.setState({
                        ...this.state,
                        bPaymentResultDlg: true,
                        iPaymentResult: -1
                    });
                }
            }).catch(function (error) { console.log(error); });
        }
        // 非卡片支付
        else {
            // 现金支付
            if (this.props.objUserInfo.payment_id === -4) {
                console.log("选择了现金支付", this.props.objUserInfo.payment_id);
                this.OrderRecordInDB(-4, objCart, objPrice);
            }
        }
    }
    // 在数据库记录订单
    OrderRecordInDB = (dataStripe, objCart, objPrice) => {
        console.log("开始记录订单……");
        let strPid = "";
        if (dataStripe !== -4)
            strPid = dataStripe.pid;
        let objOrderItems = { total_num: objCart.iItemTotalNum, items: [] };
        let arrItemID = [];
        let arrItemSales = [];
        objOrderItems.items = this.arrCurrentCart;
        for (let i = 0; i < this.arrCurrentCart.length; i++) {
            let item = { id: 0, num: 0 };
            item.id = this.arrCurrentCart[i].id;
            item.name = this.arrCurrentCart[i].name;
            item.num = this.arrCurrentCart[i].num;
            arrItemID.push(item.id);
            arrItemSales.push(item.num);
        }
        // 更新商品的sale数(包括数据库中的)
        // axios.post('/UpdateItemsSale', { items: arrItemID, sales: arrItemSales })
        //     .then(({ data }) => {
        //         console.log("商品销量更新：", data);
        //     });
        let strCardInfo = this.strCurrentCardBrand + "," + this.strCurrentLast4;
        if (dataStripe === -4)
            strCardInfo = "-5";
        // 生成目标订单信息
        let objOrderInfo = {
            pid: strPid,
            status: 0,
            final_price: objPrice.fFinalPrice.toFixed(4),
            gst_pst: objPrice.fGSTFee.toFixed(4),
            total_price: objPrice.fTotalPrice.toFixed(4),
            stripe_fee: objPrice.fStripFee.toFixed(4),
            mouthing_benefit: objPrice.fMouthingBenefit.toFixed(4),
            store_benefit: objPrice.fStoreBenefit.toFixed(4),
            delivery_fee: objPrice.fDeliveryFee.toFixed(4),
            price_off: 0,//objPrice.fDiscount.toFixed(4),
            items: JSON.stringify(objOrderItems),
            store_id: this.props.objStoreInfo.id,
            store_name: this.props.objStoreInfo.store_name,
            user_code: this.props.objUserInfo.code,
            client_order_time: new Date(),
            card_info: strCardInfo,
            contact: this.props.objUserInfo.contact,
            phone: this.props.objUserInfo.phone,
            comments: "",
            cut_price_id: -1,
            cut_price_batch: -1,
            cut_price_status: 1,
            delivery_way: this.state.iDeliveryWay,
            address: this.objStoreInfo.address, //this.strDeliveryAddress,
            deliver_address: this.objStoreInfo.address, // this.objShippingAddress,
            area: "",
            viewed: 0,
            user_viewed: 0,
            coupon_code: objPrice.strCouponCode
        };
        console.log(objOrderInfo);
        // 5. 订单在数据库的记录
        axios.post('/RecordOrder', {
            objOrderInfo: objOrderInfo, objUserInfo: this.props.objUserInfo
        }).then(({ data }) => {
            console.log("订单记录完成，返回数据 ： ", data);
            if (data.oid === -1) {
                // 订单在mouthing数据库记录异常
                message.warning(data.message);
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: -2
                });
            } else {
                // 订单支付记录成功，此时订单结果对话框处于打开状态并显示结果
                console.log("订单记录成功：", data);
                this.RemoveCartItem();
                this.strOrderID = data.oid;
                let iPaymentResult = 1;
                if (dataStripe === -4)
                    iPaymentResult = 4;
                console.log("iPaymentResult = " + iPaymentResult);
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: iPaymentResult,
                    iUserCenterReminder: this.state.iUserCenterReminder + 1
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 订单支付完成后的操作
    AfterPayment = (bStay) => {
        if (!bStay) {
            let strLang = "cn";
            if (this.state.bEnglish)
                strLang = "en";
            window.location.href = '/webapp/user_profile?tab=3&store=' + this.props.objStoreInfo.id + '&lang=' + strLang;
        } else {
            // window.location.href = '/webapp/store/' + this.props.objStoreInfo.id;
            this.setState({
                bViewCart: false,
                bPaymentResultDlg: false
            });
        }
    }
    // 商家分类判断
    StoreClassification = () => {
        let iStoreType = 0;
        for (let i = 0; i < this.arrStoreList0.length; i++) {
            if (this.arrStoreList0[i] === this.props.objStoreInfo.id) {
                iStoreType = 0;
                break;
            }
        }
        for (let i = 0; i < this.arrStoreList1.length; i++) {
            if (this.arrStoreList1[i] === this.props.objStoreInfo.id) {
                iStoreType = 1;
                break;
            }
        }
        return iStoreType;
    }
    // 获取用户的订单信息
    GetOrderList = () => {
        if (this.props.objUserInfo === -1)
            return;
        axios('/GetTargetOrders', { params: { store_id: this.props.objStoreInfo.id, user_code: this.props.objUserInfo.code, status: -1, user_viewed: 0 } })
            .then(({ data }) => {
                if (data.length > 0) {
                    console.log("data : ", data);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].status > 1) {
                            this.audioNewOrder.play();
                            if (this.state.bEnglish)
                                message.info("You has an order updated!");
                            else
                                message.info("您的订单状态有更新！");
                            break;
                        }
                    }
                }
                this.setState({
                    ...this.state,
                    iUserCenterReminder: data.length
                })
            }).catch(function (error) { console.log(error); });
    }
    // 更改联系人信息
    ChangeContactInfo = (index) => {
        // 联系人
        if (index === 0) {

        }
        // 联系电话
        if (index === 1) {

        }
        this.setState({
            ...this.state,
            iContactInfoDlg: index,
            bContactInfoDlg: true
        });
    }
    // 确认修改联系人信息
    ConfirmContactInfoChange = (bConfirm) => {
        console.log("ConfirmContactInfoChange : " + bConfirm);
        if (bConfirm) {
            if (this.state.iContactInfoDlg === 0) {
                let strContact = this.refs.contact_input.state.value;
                if (strContact.trim() === "" || strContact === null) {
                    message.warning("联系人不能空！"); return;
                }
                console.log("contact input = " + strContact);
                this.props.objUserInfo.contact = strContact;
            }
            if (this.state.iContactInfoDlg === 1) {
                let strContactPhone = this.refs.contact_phone_input.state.value;
                if (strContactPhone.trim() === "" || strContactPhone === null) {
                    message.warning("联系电话不能空！"); return;
                }
                console.log("contact phone input = " + strContactPhone);
                this.props.objUserInfo.contact_phone = "(+1)" + strContactPhone;
            }
            console.log("confirm : ", this.props.objUserInfo);
        }

        this.setState({
            ...this.state,
            bContactInfoDlg: false
        });
    }
    // 数据库中用户联系方式更新
    UpdateUserContactInfo = () => {
        axios.post('/UpdateUserBasicInfo', {
            objUserInfo: this.props.objUserInfo,
            bNameCheck: false
        }).then(({ data }) => {
            if (data.status === 1) {
                // DataStatistic
                UserActiveUpdate(4, { active: "用户信息修改成功", name: this.props.objUserInfo.name });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 登录提示框
    LoginReminder = (type) => {
        if (type === 1) {
            this.ClickMenu(2);
        }
        if (type === -1) {

        }
        this.setState({
            ...this.state,
            bLoginReminderDlg: false
        });
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问商家页" });
        // 获取日期信息
        let today = new Date().toString();
        this.DateToIndex(today.split(' ')[0]);
        // 获取购物车信息
        if (this.props.objUserInfo.cart) {
            this.arrCurrentCart = JSON.parse(this.props.objUserInfo.cart);
        }
        this.GetCardList();
        this.GetOrderList();
        let bLogin = false;
        if (parseInt(this.props.objUserInfo, 10) !== -1)
            bLogin = true;

        // 中英文默认设置
        let bEnglish = false;
        for (let i = 0; i < this.arrStoreList2.length; i++) {
            if (this.arrStoreList2[i] === this.props.objStoreInfo.id) {
                bEnglish = true;
                break;
            }
        }
        if (bEnglish)
            document.title = this.props.objStoreInfo.store_name.split(',')[1];
        else
            document.title = this.props.objStoreInfo.store_name.split(',')[0];
        console.log("登录状态：", bLogin);
        this.setState({
            ...this.state,
            bLogin: bLogin,
            bEnglish: bEnglish
        });

    }

    render() {

        // 顶部菜单按钮
        let uiMenuReminder = null;
        if (this.state.iUserCenterReminder > 0)
            uiMenuReminder = (
                <div className="store_pickup_menu_reminder" />
            );
        let uiMenButton = (
            <div className="store_pickup_menu_button" onClick={this.OpenMainMenu.bind(this, true)}>
                <Icon type="menu" />
                {uiMenuReminder}
            </div>
        );
        // 顶部菜单栏
        let uiMainMenuContents = (
            <Drawer title={null} placement="top" closable={false} height={"60vw"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.OpenMainMenu.bind(this, false)} visible={this.state.bMainMenu}>
                <div>
                    {this.arrMainMenu.map((item, index) => {
                        let strStyle = "store_pickup_main_menu_row";
                        let strText = item.cn;
                        if (this.state.bEnglish)
                            strText = item.en;
                        let uiUserCenterReminder = null;
                        if (index === 2) {
                            if (this.state.iUserCenterReminder > 0) {
                                uiUserCenterReminder = (
                                    <div className="store_pickup_menu_cart_item_num">{this.state.iUserCenterReminder}</div>
                                );
                            }
                        }
                        return (
                            <div className={strStyle} key={index} onClick={this.ClickMenu.bind(this, index)}>
                                <Icon type={item.icon} className="store_pickup_menu_icon" style={{ color: 'darkcyan' }} />
                                <div className="store_pickup_main_menu_text" key={index}>{strText}</div>
                                {uiUserCenterReminder}
                            </div>
                        );
                    })}
                    <div className="store_pickup_menu_button_close">
                        <Icon type="close-circle" theme="filled" style={{ color: 'darkcyan' }} onClick={this.OpenMainMenu.bind(this, false)} />
                    </div>
                </div>
            </Drawer>
        );
        // 底部购物车按钮
        let uiCartReminder = null;
        if (this.arrCurrentCart) {
            if (this.arrCurrentCart !== null && this.arrCurrentCart !== -1 && this.arrCurrentCart.length > 0)
                uiCartReminder = (<div className="store_pickup_cart_reminder" />);
        }
        let uiCartButton = (
            <div className="store_pickup_cart_button" onClick={this.ClickMenu.bind(this, 4)}>
                <Icon type="shopping-cart" />
                {uiCartReminder}
            </div>
        );
        // 头图区域
        let uiAdsTitle = (
            <AdsCarousel iCurrentPage={2} iStoreID={this.props.objStoreInfo.id}
                arrAdsImages={this.props.objStoreInfo.arrAdsImages} />
        );
        // 商家名称＆地址
        let strStoreName = this.objStoreInfo.store_name.split(',')[0];
        if (this.state.bEnglish)
            strStoreName = this.objStoreInfo.store_name.split(',')[1];
        const uiStoreMainInfo = (
            <div className="store_key_info">
                <div className="store_pickup_name">{strStoreName}</div>
                <div className="store_pickup_location_row">
                    <Icon type="environment" theme="filled" />
                    <div className="store_pickup_location_text">
                        {this.objStoreInfo.address}
                    </div>
                </div>
            </div>
        );
        // 商家详情 & 下单 切换按钮
        let uiMainTabs = null;
        if (this.state.bLoading) {
            uiMainTabs = (
                <div className="store_main_tabs">
                    <Spin className="store_main_tabs_loading" size="large" style={{ paddingTop: '21.5vw' }} />
                </div>
            );
        } else {
            let strStyle0 = "store_pickup_main_tab_button";
            let strStyle1 = "store_pickup_main_tab_button";
            if (this.state.iCurrentTab === 0)
                strStyle0 = "store_pickup_main_tab_button store_pickup_main_tab_button_select";
            if (this.state.iCurrentTab === 1)
                strStyle1 = "store_pickup_main_tab_button store_pickup_main_tab_button_select";
            let strTab0 = this.arrMainTab[0].cn;
            let strTab1 = this.arrMainTab[1].cn;
            if (this.state.bEnglish) {
                strTab0 = this.arrMainTab[0].en;
                strTab1 = this.arrMainTab[1].en;
            }
            uiMainTabs = (
                <div className="store_pickup_main_tab_area">
                    <div className={strStyle0} onClick={this.SwitchMainTab.bind(this, 0)}>{strTab0}</div>
                    <div className={strStyle1} onClick={this.SwitchMainTab.bind(this, 1)}>{strTab1}</div>
                </div>
            );
        }
        // 商家简介
        let strDescription = this.objStoreInfo.description.split('/')[0];
        if (this.state.bEnglish)
            strDescription = this.objStoreInfo.description.split('/')[1];
        let strAbout = "简介";
        if (this.state.bEnglish)
            strAbout = "About";
        let uiStoreIntro = (
            <div className="store_pickup_intro_area">
                <div className="store_pickup_info_title">
                    <Icon type="info-circle" style={{ marginRight: "2vw" }} />
                    {strAbout}
                </div>
                <div className="store_pickup_intro_text">
                    {strDescription}
                </div>
            </div>
        );
        // 营业时间
        let strHours = "营业时间";
        if (this.state.bEnglish)
            strHours = "Hours";
        let uiStoreHours = (
            <div className="store_pickup_intro_area">
                <div className="store_pickup_info_title">
                    <Icon type="clock-circle" style={{ marginRight: "2vw" }} />
                    {strHours}
                </div>
                <div className="store_pickup_intro_text">
                    {this.arrOpenTime.map((item, index) => {
                        let objOpenTime = this.StoreHoursFormatProcess(item);
                        return (
                            <div className="store_pickup_hour_row" key={index}>
                                {objOpenTime.day}
                                <div className="store_pickup_hour">
                                    {objOpenTime.hour}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
        // 商家更多信息
        let uiPhone = null;
        let uiEmail = null;
        let uiAddress = null;
        if (this.objStoreInfo.phone !== null && this.objStoreInfo.phone !== "") {
            uiPhone = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="phone" /*theme="filled"*/ style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text"> {this.objStoreInfo.phone}</div>
                </div>
            );
        }
        if (this.objStoreInfo.email !== null && this.objStoreInfo.email !== "") {
            uiEmail = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="mail" style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text">{this.objStoreInfo.email}</div>
                </div>
            );
        }
        if (this.objStoreInfo.address !== null && this.objStoreInfo.address !== "") {
            let strShortAddress = this.objStoreInfo.address;
            if (strShortAddress.indexOf(',') >= 0) {
                strShortAddress = strShortAddress.split(',')[0];
            }
            uiAddress = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="environment" style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text">{strShortAddress}</div>
                </div>
            );
        }
        let uiStoreDetails = (
            <div className="store_pickup_intro_area">
                {uiPhone}
                {uiEmail}
                {uiAddress}
                <div className="store_pickup_info_map">
                    <Map google={this.props.google} zoom={15} style={mapStyles} containerStyle={{ position: 'relative', width: '83vw', height: '60vw' }}
                        initialCenter={this.state.objStorePos} center={this.state.objStorePos}>
                        <Marker position={this.state.objStorePos} />
                    </Map>
                </div>
            </div>
        );
        // 营业时间
        let objTodayOpenTime = this.StoreHoursFormatProcess(this.arrOpenTime[this.iCurrentDate]);
        let uiTodayHour = (
            <div className="store_pickup_time_location_text">{objTodayOpenTime.hour}</div>
        );
        let strOpenStatus = "营业中";
        if (this.state.bEnglish)
            strOpenStatus = "Open";
        if (this.props.objStoreInfo.status === 2) {
            uiTodayHour = null;
            strOpenStatus = "已打样";
            if (this.state.bEnglish)
                strOpenStatus = "Closed";
        }
        let strAddress = "暂无详细地址";
        if (this.objStoreInfo.address !== null && this.objStoreInfo.address !== "") {
            strAddress = this.objStoreInfo.address;
            if (strAddress.indexOf(',') >= 0) {
                strAddress = strAddress.split(',')[0];
            }
        }
        let strPickupTimeLocations = "营业时间 和 自提地点";
        let strMenu = "菜单";
        if (this.state.bEnglish) {
            strPickupTimeLocations = "Pickup Time & Locations";
            strMenu = "Menu";
        }
        // 商品类别 & 列表
        let uiItemList = (
            <div>
                <div className="store_pickup_intro_area">
                    <div className="store_pickup_info_title">
                        <Icon type="shopping-cart" style={{ marginRight: "3vw", fontWeight: "bold", fontSize: "5.5vw" }} />
                        {strPickupTimeLocations}
                    </div>
                    <div className="store_pickup_time_location_row">
                        <Icon type="clock-circle" style={{ marginRight: "2vw" }} />
                        {uiTodayHour}
                        <div className="store_pickup_status">{strOpenStatus}</div>
                    </div>
                    <div className="store_pickup_time_location_row">
                        <Icon type="environment" style={{ marginRight: "2vw" }} />
                        <div className="store_pickup_time_location_text">{strAddress}</div>
                    </div>
                </div>
                <div className="store_pickup_item_types_line">{strMenu}</div>
                <Collapse bordered={false} defaultActiveKey={0} expandIconPosition={"right"} onChange={this.ItemTypeOpen}
                    expandIcon={({ isActive }) => <Icon type="caret-right" style={{ color: "darkcyan", fontWeight: "10vw" }} rotate={isActive ? 90 : 0} />}>
                    {this.props.arrItemTypeData.map((type, index) => {
                        let strStyle = "store_pickup_item_type_title";
                        if (this.arrItemTypeOpen.length > 0 && this.arrItemTypeOpen.indexOf(index.toString()) >= 0)
                            strStyle += " store_pickup_item_type_title_selected";
                        let strTypeName = type.type_name.split(',')[0];
                        if (this.state.bEnglish)
                            strTypeName = type.type_name.split(',')[1];
                        let uiItemTypeTitle = (
                            <div className={strStyle}> {strTypeName}</div>
                        );

                        return (
                            <Panel header={uiItemTypeTitle} key={index} /*className="store_pickup_menu_title"*/>
                                {this.props.arrItemsData.map((item, index) => {
                                    if (parseInt(item.type, 10) === type.id) {
                                        let strItemName = item.name.split(',')[0];
                                        if (this.state.bEnglish)
                                            strItemName = item.name.split(',')[1];
                                        let uiItemIcon = (
                                            <div className="store_pickup_item_icon" />
                                        );
                                        if (item.logo !== "")
                                            uiItemIcon = (
                                                <img className="store_pickup_item_icon" src={item.logo} alt="" />
                                            );
                                        return (
                                            <div className="store_pickup_item_row" key={item.id} onClick={this.ClickAnItem.bind(this, item)}>
                                                {uiItemIcon}
                                                <div>
                                                    <div className="store_pickup_item_name" >{strItemName}</div>
                                                    <div className="store_pickup_item_price">{"$ " + item.price}</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else
                                        return null;
                                })}
                            </Panel>
                        );
                    })}
                </Collapse>
                <div className="store_pickup_bottom_space" />
            </div>
        );
        // 商品详情抽屉
        let strItemName = "";
        let strItemInfo = ""
        if (this.objCurrentItem !== -1) {
            strItemName = this.objCurrentItem.name.split(',')[0].trim();
            if (this.state.bEnglish)
                strItemName = this.objCurrentItem.name.split(',')[1].trim();
            if (this.objCurrentItem.description !== null && this.objCurrentItem.description !== "") {
                strItemInfo = this.objCurrentItem.description.split('/')[0].trim();
                if (this.state.bEnglish)
                    strItemInfo = this.objCurrentItem.description.split('/')[1].trim();
            }
        }
        let strAddToCart = "加入购物车";
        if (this.bModifyItem)
            strAddToCart = "保存修改";
        if (this.state.bEnglish) {
            strAddToCart = "Add To Cart";
            if (this.bModifyItem)
                strAddToCart = "Save";
        }
        let uiItemDetails = null;
        let strRequire = "必选";
        let arrCurrentOptions = this.arrCurrentOptionsCN;
        if (this.state.bEnglish) {
            strRequire = "Required";
            arrCurrentOptions = this.arrCurrentOptionsEN;
        }
        // Modifer 列表
        let fTotalPrice = 0.00;
        if (this.objCurrentItem !== -1) {
            fTotalPrice = parseFloat(this.objCurrentItem.price);
        }
        let uiOptionList = null;
        if (arrCurrentOptions.length > 0)
            uiOptionList = arrCurrentOptions.map((option, indexType) => {
                let uiSelectMethod = null;
                let strSelectMethod = "最多 " + option.select_method + " 种";
                if (this.state.bEnglish)
                    strSelectMethod = "Max " + option.select_method;
                if (option.select_method >= 1)
                    uiSelectMethod = (
                        <div className="store_pickup_item_details_require">
                            {strSelectMethod}</div>
                    );
                let uiRequire = null;
                if (option.bRequired) {
                    if (option.select_method > 1) {
                        uiRequire = (
                            <div className="store_pickup_item_details_require">
                                {strRequire + " (  " + strSelectMethod + " )"}</div>
                        );

                    } else {
                        uiRequire = (
                            <div className="store_pickup_item_details_require">
                                {strRequire}</div>
                        );
                    }
                    uiSelectMethod = null;
                }

                return (
                    <div className="store_pickup_item_details_modifier_area" key={indexType}>
                        <div className="store_pickup_item_details_modifier_title">
                            {option.title}
                            {uiRequire}
                            {uiSelectMethod}
                        </div>
                        <div>
                            {option.contents.map((content, indexOption) => {
                                let uiOptionRadio = null;
                                let bRadioButton = true;
                                // 必选项且单选时，使用Radio Button
                                if (option.bRequired && option.select_method === 1) {
                                    if (content.active)
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_radio_select" />
                                        );
                                    else
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_radio" />
                                        );
                                }
                                // 非必选项使用Check Box
                                else {
                                    bRadioButton = false;
                                    if (content.active)
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_checkbox_select">
                                                <Icon type="check" style={{ color: "white", fontWeight: "bold" }} />
                                            </div>
                                        );
                                    else
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_checkbox" />
                                        );
                                }
                                // 价格显示
                                let strPrice = "";
                                let strPriceStyle = "store_pickup_item_details_modifier_option_price";
                                if (content.active) {
                                    strPriceStyle = "store_pickup_item_details_modifier_option_price_select";
                                    if (content.price !== "0") {
                                        if (content.price.indexOf('+') >= 0)
                                            fTotalPrice += parseFloat(content.price.split('+')[1]);
                                        else
                                            fTotalPrice = parseFloat(content.price);
                                    }
                                }
                                if (content.price !== "0") {
                                    strPrice = "$" + content.price;
                                    if (content.price.indexOf('+') >= 0)
                                        strPrice = "+ $" + content.price.split('+')[1];
                                }

                                return (
                                    <div className="store_pickup_item_details_modifier_option_row" key={indexOption}
                                        onClick={this.ItemModifierOptionCLick.bind(this, indexType, indexOption, bRadioButton)}>
                                        {uiOptionRadio}
                                        <div className="store_pickup_item_details_modifier_option_text">{content.name}</div>
                                        <div className={strPriceStyle}>{strPrice}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            });
        // 商品详情抽屉
        this.fCurrentItemTotalPrice = this.state.iCurrentItemNum * fTotalPrice;
        let strCommentsTitle = "备注（特殊需求）";
        let strCommentsTips = "最多100字";
        if (this.state.bEnglish) {
            strCommentsTitle = "Comments ( Special Requests )";
            strCommentsTips = "Max 100 Characters";
        }
        const { getFieldProps } = this.props.form;

        let uiItemImage = (
            <div className="store_pickup_item_details_picture" />
        );
        if (this.objCurrentItem.image !== "")
            uiItemImage = (
                <img className="store_pickup_item_details_picture" src={this.objCurrentItem.image} alt="" />
            );

        uiItemDetails = (
            <Drawer title={null} placement="left" closable={false} width={"100vw"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.CloseItemDetails.bind(this)} visible={this.state.bItemDetails}>
                <div className="store_pickup_item_details_dlg">
                    <div className="store_pickup_item_details_title">
                        <div className="store_pickup_item_details_back_button" onClick={this.CloseItemDetails.bind(this)} >
                            <Icon type="left-circle" theme="filled" style={{ color: 'darkcyan' }} />
                        </div>
                        <div className="store_pickup_item_details_store_name">{strStoreName}</div>
                    </div>
                    {uiItemImage}
                    <div className="store_pickup_item_details_name">{strItemName}</div>
                    <div className="store_pickup_item_details_info">{strItemInfo}</div>
                    {uiOptionList}
                    <div className="store_pickup_item_details_modifier_title">
                        {strCommentsTitle}
                    </div>
                    <div className="store_pickup_item_details_modifier_comments">
                        <TextareaItem {...getFieldProps('count')} ref="special_requests" value={this.state.strSpecialRequests} onChange={this.SpecialRequestsInput}
                            cols={40} /*rows={2}*/ autoHeight count={100} placeholder={strCommentsTips}
                            style={{
                                width: '100vw', lineHeight: '6.5vw', fontSize: '3.75vw', border: 'none', outline: 'none',
                                margin: '0vw', marginTop: '1.5vw', padding: '2vw', paddingLeft: '5vw'
                            }}
                        />
                    </div>
                    <div className="store_pickup_item_details_num_select_row">
                        <div className="store_pickup_item_details_add_button" onClick={this.ItemNumChange.bind(this, false, fTotalPrice.toFixed(2))}>
                            <Icon type="minus" style={{ color: "white", fontWeight: "bold" }} />
                        </div>
                        <div className="store_pickup_item_details_num_text">
                            {this.state.iCurrentItemNum}
                        </div>
                        <div className="store_pickup_item_details_add_button" onClick={this.ItemNumChange.bind(this, true, fTotalPrice.toFixed(2))}>
                            <Icon type="plus" style={{ color: "white", fontWeight: "bold" }} />
                        </div>
                    </div>
                    <div className="store_pickup_item_details_bottom_margin" />
                    <div className="store_pickup_item_details_bottom">
                        <div className="store_pickup_item_details_total_price">{"$ " + this.fCurrentItemTotalPrice.toFixed(2)}</div>
                        <div className="store_pickup_item_details_add_to_cart_button" onClick={this.AddToCartButtonClicked}>{strAddToCart}</div>
                    </div>
                </div>
            </Drawer>
        );
        // 购物车抽屉
        let strCartTitle = "购物车";
        if (this.state.bEnglish)
            strCartTitle = "Cart";
        let uiCartItemList = null;
        let fSubtotal = 0.0;
        let uiCartEmpty = null;
        let strAddMore = "添加更多";
        let strPayButton = "立刻下单";
        let arrPriceTitle = ["小计", "税费", "手续费", "总计", "支付方式 : ",
            "更改 > ", "购物车还是空的,快去选购吧!", "请先登录", "联系姓名 : ", "联系电话 : ",
            "请到前台支付"];
        if (this.state.bEnglish) {
            strAddMore = "Add More Items";
            arrPriceTitle = ["Subtotal", "Tax", "Strip Fee", "Total", "Payment Method",
                "Change > ", "Cart is empty, shopping now!", "Login", "Contact", "Phone",
                "Pay at reciption"];
            strPayButton = "Order Now";
        }
        let uiPriceArea = null;
        let uiAddMore = null;
        let uiPayButton = null;
        let uiPaymentMethod = null;
        let uiContact = null;
        let uiContactPhone = null;
        let uiContactInfoDlg = null;
        if (this.state.bViewCart && this.arrCurrentCart.length > 0) {
            let iLanguageIndex = 0;
            if (this.state.bEnglish)
                iLanguageIndex = 1;
            uiCartItemList = (
                <div style={{ marginTop: '15vw' }}>
                    {this.arrCurrentCart.map((item, indexItem) => {
                        fSubtotal += parseFloat(item.price);
                        let uiOptions = null;
                        if (item.options.length > 0) {
                            uiOptions = item.options.map((option, indexOption) => {
                                if (option === -1 || option === null)
                                    return null;
                                else {
                                    let strOptions = option.title.split(',')[iLanguageIndex] + ": ";
                                    if (option.name.indexOf('/') >= 0) {
                                        let arrOptions = option.name.split('/');
                                        for (let i = 0; i < arrOptions.length; i++) {
                                            strOptions += arrOptions[i].split(',')[iLanguageIndex];
                                            if (i !== arrOptions.length - 1)
                                                strOptions += ", ";
                                        }
                                    } else {
                                        strOptions += option.name.split(',')[iLanguageIndex];
                                    }

                                    return (
                                        <div className="store_pickup_cart_item_option_row" key={indexOption}>
                                            {strOptions}
                                        </div>
                                    );
                                }
                            });
                        }
                        let uiComments = <div className="store_pickup_cart_item_bottom_margin" />;
                        if (item.comments !== "" && item.comments !== null) {
                            uiComments = (
                                <div className="store_pickup_cart_item_comments">
                                    <Icon type="info-circle" theme="filled" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '4vw', marginRight: '2vw' }} />
                                    {item.comments}
                                </div>
                            );
                        }
                        return (
                            <div className="store_pickup_cart_item_area" key={indexItem} onClick={this.ModifyCartItem.bind(this, indexItem)}>
                                <div className="store_pickup_cart_item_title">
                                    <div className="store_pickup_cart_item_num">{item.num}</div>
                                    <div className="store_pickup_cart_item_name">{item.name.split(',')[iLanguageIndex]}</div>
                                    <div className="store_pickup_cart_item_price">{"$" + parseFloat(item.price).toFixed(2)}</div>
                                    <div className="store_pickup_cart_item_remove_button" onClick={this.RemoveCartItem.bind(this, indexItem)}>
                                        <Icon type="close-circle" style={{ color: 'darkcyan' }} />
                                    </div>
                                </div>
                                <div className="store_pickup_cart_item_options_area">
                                    {uiOptions}
                                </div>
                                {uiComments}
                            </div>
                        );
                    })}
                </div>
            );
            let objPrice = {};
            objPrice = StripePriceCalculate(fSubtotal, 0.0, false);
            uiPriceArea = (
                <div>
                    <div className="store_pickup_cart_price_row">
                        {arrPriceTitle[0]}
                        <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>{"$ " + parseFloat(fSubtotal).toFixed(2)}</div>
                    </div>
                    <div className="store_pickup_cart_price_row">
                        {arrPriceTitle[1]}
                        <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>{"$ " + parseFloat(objPrice.fGSTFee).toFixed(2)}</div>
                    </div>
                    {/* <div className="store_pickup_cart_price_row">
                        {arrPriceTitle[2]}
                        <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>{"$ " + objPrice.fStripFee.toFixed(2)}</div>
                    </div> */}
                    <div className="store_pickup_cart_price_row store_pickup_cart_total_price_row" style={{ fontSize: "4.75vw", fontWeight: 'bold' }}>
                        {arrPriceTitle[3]}
                        <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'black', fontSize: '4.75vw', fontWeight: 'bold' }}>{"$ " + parseFloat(objPrice.fFinalPrice).toFixed(2)}</div>
                    </div>
                </div>
            );
            uiAddMore = (
                <div className="store_pickup_cart_add_more_item" onClick={this.SwitchMainTab.bind(this, 1)}>
                    <div className="store_pickup_cart_item_num" style={{ color: 'darkcyan', fontSize: '4.5vw', borderColor: 'darkcyan', paddingTop: '0vw' }}>+</div>
                    <div style={{ fontSize: '4.25vw', marginTop: '0.5vw' }}>{strAddMore}</div>
                </div>
            );
            uiPayButton = (
                <div className="store_pickup_cart_payment_button" onClick={this.ConfirmAndPay.bind(this, objPrice)}>
                    {strPayButton}
                    <div className="store_pickup_cart_payment_total_price_bottom">{"$ " + parseFloat(objPrice.fFinalPrice).toFixed(2)}</div>
                </div>
            );
            uiPaymentMethod = (
                <div className="store_pickup_cart_payment_method">
                    {arrPriceTitle[4]}
                    <div className="store_pickup_cart_dlg_change_button" onClick={this.SelectPaymentMethod}>
                        {this.arrPaymentMethod[this.iConfirmedPaymentMethod] + " >"}</div>
                </div>
            );
            // 如果是信用卡/借记卡支付
            if (this.iConfirmedPaymentMethod === 1) {
                uiPaymentMethod = (
                    <div className="store_pickup_cart_payment_method" onClick={this.SelectPaymentMethod}>
                        {arrPriceTitle[4]}
                        <Icon type="credit-card" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                        <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>{this.strCurrentLast4}</div>
                        <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                    </div>
                );
            }
            uiPaymentMethod = (
                <div className="store_pickup_cart_payment_method" /*onClick={this.SelectPaymentMethod}*/>
                    {arrPriceTitle[4]}
                    <Icon type="dollar" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                    <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>{arrPriceTitle[10]}</div>
                </div>
            );
            // 联系人和联系方式
            let strContact = this.props.objUserInfo.contact;
            if (strContact === null || strContact === "") {
                strContact = this.props.objUserInfo.name;
            }
            uiContact = (
                <div className="store_pickup_cart_contact" onClick={this.ChangeContactInfo.bind(this, 0)} >
                    {arrPriceTitle[8]}
                    <Icon type="user" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                    <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>{strContact}</div>
                    <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                </div>
            );
            let strContactPhone = this.props.objUserInfo.contact_phone;
            if (strContactPhone === null || strContactPhone === "") {
                strContactPhone = this.props.objUserInfo.phone;
            }
            if (strContactPhone.indexOf(')') >= 0) {
                if (strContactPhone.indexOf('86') >= 0)
                    strContactPhone = "";
            }
            uiContactPhone = (
                <div className="store_pickup_cart_contact" onClick={this.ChangeContactInfo.bind(this, 1)} >
                    {arrPriceTitle[9]}
                    <Icon type="user" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                    <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>{strContactPhone}</div>
                    <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                </div>
            );
            if (this.state.bContactInfoDlg) {
                let strTitle = "姓名：";
                let uiContactInput = (
                    <Input placeholder="请输入联系人姓名" className="store_pickup_cart_contact_input" ref="contact_input"
                        defaultValue={strContact} />
                );
                if (this.state.iContactInfoDlg === 1) {
                    strTitle = "电话：";
                    uiContactInput = (
                        <Input placeholder="请输入联系电话" className="store_pickup_cart_contact_input" ref="contact_phone_input"
                            defaultValue={strContactPhone.split(')')[1]} addonBefore="+1" />
                    );
                }
                uiContactInfoDlg = (
                    <Modal title={"修改联系方式"} visible={this.state.bContactInfoDlg} closable={false} centered={true}
                        onOk={this.ConfirmContactInfoChange.bind(this, true)} okText="确定"
                        onCancel={this.ConfirmContactInfoChange.bind(this, false)} cancelText="取消">
                        <div className="store_pickup_cart_contact_info_row">
                            <div className="store_pickup_cart_contact_title">{strTitle}</div>
                            {uiContactInput}
                        </div>
                    </Modal>
                );
            }

        } else {
            console.log("this.state.bLogin : " + this.state.bLogin)
            let uiLoginButton = null;
            if (!this.state.bLogin)
                uiLoginButton = (<div className="store_pickup_cart_login_button" onClick={this.ClickMenu.bind(this, 2)}>{arrPriceTitle[7]}</div>);
            uiCartEmpty = (
                <Empty style={{ marginTop: '50vw', color: 'lightgrey', fontSize: '3.5vw' }} image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={arrPriceTitle[6]} >
                    {uiLoginButton}
                </Empty>
            );
        }
        // 支付方式选择框
        let uiPaymentOptions = (
            <PaymentMethodDlg bSelectPaymentDlg={this.state.bSelectPaymentDlg} PaymentMethodResult={this.PaymentMethodResult}
                strTitle={"支付方式"} objUserInfo={this.props.objUserInfo} />
        );
        // 支付结果弹窗
        let bPaymentProcessing = true;
        let strPaymentResultDlgTitle = "您的订单";
        let uiPaymentResultContents = (
            <div className="payment_processing">
                <Spin tip="交易处理中……" />
            </div>
        );
        // 支付成功
        if (this.state.iPaymentResult === 1) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功 !
                </div>
            );
        }
        // 支付失败
        if (this.state.iPaymentResult === -1) {
            bPaymentProcessing = false;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付失败 !
                </div>
            );
        }
        // 支付成功 但 订单信息记录出现问题
        if (this.state.iPaymentResult === -2) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功, 但订单记录出现问题，请联系客服！
                </div>
            );
        }
        // 使用现金支付
        if (this.state.iPaymentResult === 4) {
            bPaymentProcessing = false;
            let strTip = "请到前台出示订单编号并与商家结款！";
            strPaymentResultDlgTitle = "您的订单编号: " + this.strOrderID;
            if (this.state.bEnglish) {
                strTip = "Please show your order code to the store and make the payment at the reciption !";
                strPaymentResultDlgTitle = "Your Order Code : " + this.strOrderID;
            }

            uiPaymentResultContents = (
                <div className="payment_processing">
                    {strTip}
                </div>
            );
        }
        let uiPaymentResultDlg = (
            <Modal title={strPaymentResultDlgTitle} visible={this.state.bPaymentResultDlg} closable={false}
                confirmLoading={bPaymentProcessing}
                onOk={this.AfterPayment.bind(this, false)} okText="查看订单"
                onCancel={this.AfterPayment.bind(this, true)} cancelText="继续选购">
                {uiPaymentResultContents}
            </Modal>
        );
        // 购物车
        let uiCart = (
            <Drawer title={null} placement="bottom" closable={false} height={"100vh"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.CloseItemDetails.bind(this)} visible={this.state.bViewCart}>
                <div className="store_pickup_item_details_dlg" style={{ height: 'auto' }}>
                    <div className="store_pickup_item_details_title">
                        <div className="store_pickup_item_details_back_button" onClick={this.CloseCart.bind(this)} >
                            <Icon type="down-circle" theme="filled" style={{ color: 'darkcyan' }} />
                        </div>
                        <div className="store_pickup_item_details_store_name">{strCartTitle}</div>
                        <div className="store_pickup_item_details_delete_button" onClick={this.RemoveCartItem.bind(this, -1)} >
                            <Icon type="delete" /*theme="filled"*/ style={{ color: 'darkcyan' }} />
                        </div>
                    </div>
                    {uiCartEmpty}
                    {uiCartItemList}
                    {uiAddMore}
                    {uiPriceArea}
                    {uiContact}
                    {uiContactPhone}
                    {uiContactInfoDlg}
                    {uiPaymentMethod}
                    {uiPayButton}
                    {uiPaymentOptions}
                    {uiPaymentResultDlg}

                </div>
            </Drawer>
        );

        // 商家信息/菜单区域
        let uiStoreMainArea = null;
        if (this.state.iCurrentTab === 0) {
            uiStoreMainArea = (
                <div>
                    {uiStoreIntro}
                    {uiStoreHours}
                    {uiStoreDetails}
                </div>
            );
        }
        if (this.state.iCurrentTab === 1) {
            uiStoreMainArea = (
                <div>
                    {uiItemList}
                    {uiItemDetails}
                </div>
            );
        }

        return (
            <div className="store_pickup_page">
                {uiMenButton}
                {uiCartButton}
                {uiMainMenuContents}
                {/* 商家自己的广告轮播区域 */}
                {uiAdsTitle}
                {/* 商家信息区域：名称和地址 */}
                {uiStoreMainInfo}
                {/* 主标签切换区域：详情和下单 */}
                {uiMainTabs}
                {uiStoreMainArea}
                {/* 购物车 */}
                {uiCart}
                {/* 登录提示框 */}
                <Modal title={""} visible={this.state.bLoginReminderDlg} centered={true} maskClosable={true} closable={false}
                    onOk={this.LoginReminder.bind(this, 1)} onCancel={this.LoginReminder.bind(this, -1)}
                    okText={"去登录"} cancelText={"再看看"}>
                    <div className="store_pickup_login_reminder">
                        <Icon type="info-circle" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '5vw', marginRight: '2vw' }} />
                        {"请先登录！"}</div>
                </Modal>
            </div>
        );
    }

}
// export default Store;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'en', region: 'CA'
})(Form.create()(StorePickup));
// export default Form.create()(Store);
