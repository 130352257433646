import React, { Component } from "react";

import Carousel from 'antd/es/carousel';

// import { Carousel } from 'antd'
import '../../../styles/ClientSide/UI_Module/sub_categories.css'
import '../../../styles/icon_image.css'
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

class SubCategories extends Component {
    constructor(props) {
        super();
        this.state = {
            iCurrentGroup: 0,
            iCurrentCategory: 0
        };
        // 子分类列表控制
        this.arrSubCategoryGroup = [
            [
                { id: 0, parent_id: 1, icon: "category_milktea_icon", name: "奶茶甜品" },
                { id: 1, parent_id: 0, icon: "category_car_icon", name: "爱车服务" },
                { id: 2, parent_id: 0, icon: "category_haircut_icon", name: "美发丽人" },
                { id: 3, parent_id: 2, icon: "category_deskgame_icon", name: "卡牌桌游" },
                { id: 4, parent_id: 0, icon: "category_children_icon", name: "母婴亲子" }
            ],
            [
                { id: 5, parent_id: 0, icon: "category_medical_icon", name: "营养保健" },
                { id: 6, parent_id: 0, icon: "category_carrepair_icon", name: "汽车维修" },
                { id: 7, parent_id: 0, icon: "category_lecture_icon", name: "成人教育" },
                { id: 8, parent_id: 2, icon: "category_ktvbar_icon", name: "酒吧KTV" },
                { id: 9, parent_id: 0, icon: "category_cleaning_icon", name: "清扫保洁" }
            ],
            [
                { id: 10, parent_id: 0, icon: "category_musical_icon", name: "钢琴陪练" },
                { id: 11, parent_id: 0, icon: "category_investement_icon", name: "投资理财" },
                { id: 12, parent_id: 0, icon: "category_tax_icon", name: "税务咨询" },
                { id: 13, parent_id: 0, icon: "category_moving_icon", name: "搬家安置" },
                { id: 14, parent_id: 2, icon: "category_dating_icon", name: "约会交友" }
            ]
        ];
        // 首页子分类列表
        if (props.arrSubCategory !== undefined) {
            let iSubCategoryNum = props.arrSubCategory.length;
            this.arrSubCategoryGroup = [];
            let iGroupNum = parseInt(iSubCategoryNum / 5, 10);
            if (parseInt(iSubCategoryNum % 5, 10) !== 0)
                iGroupNum++;
            for (let i = 0; i < iGroupNum; i++) {
                this.arrSubCategoryGroup.push([]);
            }
            for (let i = 0; i < iSubCategoryNum; i++) {
                let iGroup = parseInt(i / 5, 10);
                this.arrSubCategoryGroup[iGroup].push(props.arrSubCategory[i]);
            }
        }
        // 分类页分类列表
        if (props.arrCategoryList !== undefined) {
            let iSubCategoryNum = props.arrCategoryList.length;
            this.arrSubCategoryGroup = [];
            let iGroupNum = parseInt(iSubCategoryNum / 5, 10);
            if (parseInt(iSubCategoryNum % 5, 10) !== 0)
                iGroupNum++;
            for (let i = 0; i < iGroupNum; i++) {
                this.arrSubCategoryGroup.push([]);
            }
            for (let i = 0; i < iSubCategoryNum; i++) {
                let iGroup = parseInt(i / 5, 10);
                this.arrSubCategoryGroup[iGroup].push(props.arrCategoryList[i]);
            }
        }
        this.iMainCategory = -1;
        this.iClickedCategory = -1;
    }
    // 子分类切换（子分类按钮被点击）
    CategoryClicked(iLevel, iMainCategory, iSubCategory, iCurrentGroup, iCurrentCategory, strCategoryName) {
        this.iMainCategory = iMainCategory;
        this.iClickedCategory = iSubCategory;
        if (this.props.bSwitching)
            return;
        // 剑鱼集运/特殊分类专用
        if (iSubCategory === 9) {
            // DataStatistic
            UserActiveUpdateBack(6, { active: "点击特殊分类: 集运", category: 9 },
                this.JumpToSpecialCategoryPage);
            return;
        }
        if (this.props.type === 0) {
            // DataStatistic
            UserActiveUpdateBack(6, { active: "点击子分类按钮", category: strCategoryName },
                this.JumpToCategoryPage);
        } else {
            // DataStatistic
            UserActiveUpdate(6, { active: "点击子分类按钮", category: strCategoryName });
            if (iLevel <= 1)
                this.props.GetStoreList(iSubCategory);
            else
                this.props.RefreshStoreList(iSubCategory);
            this.setState({
                ...this.state,
                iCurrentGroup: iCurrentGroup,
                iCurrentCategory: iCurrentCategory
            });
        }
    }
    // 跳转到剑鱼集运
    JumpToSpecialCategoryPage = () => {
        window.location.href = 'https://sv.mikecrm.com/lSPPaDN?from=singlemessage';
    }
    // 跳转到分类页面
    JumpToCategoryPage = () => {
        window.location.href = '/webapp/category?c0=' + this.iMainCategory +
            '&c1=' + this.iClickedCategory;
    }
    // 跳转到相应的走马灯位置
    JumpToCategoryGroup = (iCategoryGroup) => {
        this.slider && this.slider.innerSlider.slickGoTo(iCategoryGroup, false);
    }
    // 获取当前分类所在的组别和索引
    GetCategoryGroupIndex = (iCategoryID) => {
        let arrGroupIndex = [0, 0];
        for (let i = 0; i < this.arrSubCategoryGroup.length; i++) {
            let arrCategoryGroup = this.arrSubCategoryGroup[i];
            for (let j = 0; j < arrCategoryGroup.length; j++) {
                if (arrCategoryGroup[j].id === iCategoryID) {
                    arrGroupIndex = [i, j];
                    break;
                }
            }
        }
        return arrGroupIndex;
    }
    // 子分类区域滑动
    CarouselChange = (from, to) => {
        // DataStatistic
        UserActiveUpdate(7, { active: "子分类区域滑动", group_from: from, group_to: to });
    }
    // 渲染完毕……
    componentDidMount() {
        if (this.props.type === 1) {
            if (this.props.initParms.length > 0) {
                let arrGroupIndex = this.GetCategoryGroupIndex(this.props.initParms[2]);
                this.CategoryClicked(this.props.initParms[0], this.props.initParms[1],
                    this.props.initParms[2], arrGroupIndex[0], arrGroupIndex[1]);
                this.JumpToCategoryGroup(arrGroupIndex[0]);
            }
        }
    }

    render() {

        let uiSubCategoryGroupList = [];
        for (let i = 0; i < this.arrSubCategoryGroup.length; i++) {
            const uiSubCategoryGroup = (
                <div className="sub_category_area" key={i}>
                    {this.arrSubCategoryGroup[i].map((data, index) => {
                        let strCategoryBtnStyle = "sub_category_item sub_item_" + index.toString();
                        if (i === this.state.iCurrentGroup && index === this.state.iCurrentCategory && this.props.type === 1) {
                            strCategoryBtnStyle = "sub_category_item_selected sub_item_" + index.toString();
                        }
                        return (
                            <div className={strCategoryBtnStyle} key={index}
                                onClick={this.CategoryClicked.bind(this, data.level, data.parent_id, data.id, i, index, data.name)} >
                                <div className={"sub_category_icon " + data.icon} />
                                <div className="sub_category_text">{data.name}</div>
                            </div>
                        );
                    })}
                </div>
            );
            uiSubCategoryGroupList.push(uiSubCategoryGroup);
        }

        return (
            <Carousel className="sub_categories_carousel" dots={false} ref={el => (this.slider = el)}
                beforeChange={this.CarouselChange}>
                {uiSubCategoryGroupList}
            </Carousel>
        );
    }
}
export default SubCategories;
