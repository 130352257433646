import React, { Component } from 'react';

import Input from 'antd/es/input';
// import message from 'antd/es/message';

import Sidebar from './Sidebar.js'
import '../../../styles/ClientSide/UI_Module/navbar.css';
import '../../../styles/icon_image.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

// const { Search } = Input;

class Navbar extends Component {

    constructor(props) {
        super();
        this.state = {
            bSideBarVisible: false,
            strLocation: '6240 Dickson Rd., Richmond, BC, V6E 4C0',
            iCurrentLocation: 0,
            strAffixStyle: "affix_navbar_white",
            strInputValue: ""
        };
        this.arrLocationFilter = ['Vancouver', 'Richmond', 'Burnaby', 'Surrey', 'Coquitlam'];
        this.arrMainCategories = [
            { name: "美食餐饮", icon: "category_food_icon" },
            { name: "生活服务", icon: "category_life_icon" },
            { name: "休闲娱乐", icon: "category_play_icon" },
            { name: "周边游", icon: "category_travel_icon" }
        ];
        this.arrMajorInfo = [
            { name: "首页", icon: "home_icon" },
            { name: "搜索", icon: "search_icon" },
            { name: "订单详情", icon: "about_us_icon" },
            { name: "个人信息", icon: "user_icon" }
        ];
        this.arrMoreInfo = [
            { name: "联系客服", icon: "about_us_icon" },
            { name: "关于我们", icon: "user_icon" }
        ];
        this.strSearchKeywords = "";
    }
    // 聚焦到搜索栏
    UserSearchBox = () => {
        // 在搜索界面不需要记录搜索栏的聚焦
        if (this.props.iCurrentPage !== 4) {
            // DataStatistic
            UserActiveUpdate(1, { active: "使用顶部搜索栏", page: this.props.iCurrentPage });
        }
    }
    // 点击了搜索按钮
    SearchButtonClicked = (value) => {
        this.strSearchKeywords = value;
        if (this.props.iCurrentPage === 4) {
            this.props.KeywordsOnSearch(value);
            if (value !== undefined && value !== null && value.trim() !== "") {
                // DataStatistic
                UserActiveUpdate(2, { active: "点击顶部搜索栏按钮", keywords: value });
            }
        } else {
            // DataStatistic
            UserActiveUpdateBack(2, {
                active: "点击顶部搜索栏按钮", keywords: value
            }, this.JumpToSearchPage);
        }
    }
    // 跳转到搜索界面
    JumpToSearchPage = () => {
        let value = this.strSearchKeywords;
        if (value !== null && value.trim() !== "" && value !== undefined && value !== 'undefined')
            window.location.href = '/webapp/search?=' + value;
        else
            window.location.href = '/webapp/search';
    }
    // 返回按钮点击
    BackBtnClick = () => {
        // DataStatistic
        UserActiveUpdateBack(3, {
            active: "点击顶部栏返回按钮",
            page: this.props.iCurrentPage,
        }, this.PageJumBack);
    }
    // 返回按钮响应
    PageJumBack = () => {

        // 用户中心页面
        if (this.props.iCurrentPage === 3) {
            if (this.props.iStoreID !== 0) {
                window.location.href = '/webapp/store/' + this.props.iStoreID + "?tab=0";
            } else {
                window.history.back();
            }
        } else {
            window.history.back();
        }

    }
    // 渲染完毕……
    componentDidMount() { }

    render() {
        let objUserInfo = -1;
        if (this.props.objUserInfo !== undefined) {
            objUserInfo = this.props.objUserInfo;
        }
        let bAllowClear = false;
        if (this.props.iCurrentPage === 4)
            bAllowClear = true;

        return (
            <div className="navbar">
                <Sidebar objUserInfo={objUserInfo} iCurrentPage={this.props.iCurrentPage} />
                <div className="navbar_back_btn_container"
                    onClick={this.BackToHomePage} >
                    <div className="navbar_back_btn back_icon" onClick={this.BackBtnClick} />
                </div>
                <Input.Search className="navbar_search_box navbar_search_box1"
                    allowClear={bAllowClear} placeholder={this.props.strNavbarPlaceHolder}
                    defaultValue={this.props.strKeywords}
                    onFocus={this.UserSearchBox}
                    onSearch={this.SearchButtonClicked.bind(this)} />
            </div>
        );
    }
}
export default Navbar;
