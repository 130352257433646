import React, { Component } from 'react';
import cookie from 'react-cookies';
import axios from 'axios';

import Drawer from 'antd/es/drawer';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';

// import { Drawer, Icon, Tooltip } from 'antd';
import '../../../styles/ClientSide/UI_Module/sidebar.css';
import '../../../styles/icon_image.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

const DrawerBodyStyle = {
    border: 0, margin: 0, padding: 0
};

class Sidebar extends Component {

    constructor(props) {
        super();
        this.state = {
            bSideBarVisible: false,
            bLoading: true,
            iCollectStores: 0,
            iOrderNum: 0,
            iTicketNum: 0
        };
        // 主要快捷菜单（在此处写死）
        this.arrMajorInfo = [
            { name: "首 页", icon: "home_icon", tips: "" },
            { name: "订 单", icon: "order_icon", tips: "" },
            { name: "搜 索", icon: "search_icon", tips: "" },
            { name: "定 位", icon: "location_icon_2", tips: "" }

        ];
        // 主分类（From DB）
        this.arrMainCategories = [
            { name: "美 食", icon: "category_food_icon" },
            { name: "生 活", icon: "category_life_icon" },
            { name: "娱 乐", icon: "category_play_icon" },
            { name: "购 物", icon: "category_travel_icon" }
        ];
        // 更多信息（在此处写死）
        this.arrMoreInfo = [
            { name: "客 服", icon: "service_icon", tips: "请拨打: 250-619-6318" },
            { name: "入 驻", icon: "category_house_renting_icon", tips: "即将上线，敬请期待" },
            { name: "关 于", icon: "mouthing_logo", tips: '欢迎来到 Mouthing 大型服务娱乐互助平台：我们不生产服务和娱乐，我们只做服务和娱乐的搬运工。' }
        ];
        this.objUserInfo = -1;
        this.strCurrentUserCode = "";
        this.iTopAreaTab = -1;
        this.iMainFunctionIndex = -1;
        this.iMainCategoryID = -1;
        this.iSubFunctionIndex = -1;
    }
    // 顶部区域（跳转用户中心）
    TopAreaClicked = (iTab, strClickArea) => {
        this.iTopAreaTab = iTab;
        // DataStatistic
        UserActiveUpdateBack(1, { active: "侧边栏->用户中心", tab: iTab, click: strClickArea }, this.JumpToUserPage);
    }
    // 跳转到用户界面
    JumpToUserPage = () => {
        let strFromParm = window.location.href.split('/webapp/')[1];
        if (strFromParm.indexOf('user') === -1)
            window.location.href = '/webapp/user?tab=' + this.iTopAreaTab + '&from=' + strFromParm;
        else
            window.location.href = '/webapp/user?tab=' + this.iTopAreaTab;
    }
    // 主功能页区域点击
    MainFunctionAreaClicked = (iIndex, strName) => {
        this.iMainFunctionIndex = iIndex;
        // DataStatistic
        UserActiveUpdateBack(2, { active: "侧边栏->主功能页", order: iIndex, function: strName }, this.JumpToMainPage);
    }
    // 跳转到主功能页面
    JumpToMainPage = () => {
        switch (this.iMainFunctionIndex) {
            case 0: window.location.href = '/webapp/home'; break;
            case 1: {
                let strFromParm = window.location.href.split('/webapp/')[1];
                if (strFromParm.indexOf('user') === -1)
                    window.location.href = '/webapp/user?tab=3&from=' + strFromParm;
                else
                    window.location.href = '/webapp/user?tab=3';
                break;
            }
            case 2: window.location.href = '/webapp/search'; break;
            case 3: window.location.href = '/webapp/location'; break;
            default: break;
        }
    }
    // 主分类区域点击
    MainCategoriesAreaClicked = (iIndex, objCategory) => {
        this.iMainCategoryID = objCategory.id;
        // DataStatistic
        UserActiveUpdateBack(3, { active: "侧边栏->主分类页", oder: iIndex, category: objCategory.name }, this.JumpToCategoryPage);
    }
    // 跳转到主分类页面（主分类按钮被点击）
    JumpToCategoryPage = () => {
        window.location.href = '/webapp/category?c0=' + this.iMainCategoryID + '&c1=-1';
    }
    // 辅助功能页区域点击
    SubFunctionAreaClicked = (iIndex, strName) => {
        this.iSubFunctionIndex = iIndex;
        // DataStatistic
        UserActiveUpdate(4, { active: "侧边栏->辅助功能", order: iIndex, function: strName });
    }
    // 从数据库获取所有主分类
    GetMainCategoriesData = () => {
        axios('/GetMainCategories').then(
            ({ data }) => {
                this.arrMainCategories = data.slice(1, data.length);
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }
        ).catch(function (error) { console.log(error); });
    }
    // 显示侧边栏
    ShowSideBar = () => {
        // DataStatistic
        UserActiveUpdate(0, { active: "打开侧边栏", page: this.props.iCurrentPage });
        // 用户状态识别
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.setState({
                ...this.state,
                bSideBarVisible: true
            });
        }
    }
    // 隐藏侧边栏
    HideSideBar = () => {
        this.setState({
            bSideBarVisible: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                let iCollectStores = 0;
                let iOrderNum = 0;
                let iTicketNum = 0;
                if (data.status === 1) {
                    this.objUserInfo = data.user;
                    console.log("SideBar >>> user info = ");
                    console.log(this.objUserInfo);
                    if (this.objUserInfo.collect_stores) {
                        if (this.objUserInfo.collect_stores.indexOf(',') >= 0) {
                            iCollectStores = this.objUserInfo.collect_stores.split(',').length;
                        } else {
                            if (this.objUserInfo.collect_stores !== "" && this.objUserInfo.collect_stores !== null)
                                iCollectStores = 1;
                        }
                    }
                    if (this.objUserInfo.order_list) {
                        if (this.objUserInfo.order_list.indexOf(',') >= 0) {
                            iOrderNum = this.objUserInfo.order_list.split(',').length;
                        }
                    }
                    if (this.objUserInfo.ticket_list) {
                        if (this.objUserInfo.ticket_list.indexOf(',') >= 0) {
                            iTicketNum = this.objUserInfo.ticket_list.split(',').length;
                        }
                    }
                } else {
                    this.objUserInfo = -1;
                }
                this.setState({
                    ...this.state,
                    bSideBarVisible: true,
                    iCollectStores: iCollectStores,
                    iOrderNum: iOrderNum,
                    iTicketNum: iTicketNum
                });
            }).catch(function (error) { console.log(error); });
    }
    // 渲染完毕……
    componentDidMount() {
        if (this.props !== undefined) {
            if (this.props.objUserInfo !== undefined) {
                this.objUserInfo = this.props.objUserInfo;
            }
        }
        this.GetMainCategoriesData();
    }

    render() {
        // 侧边栏相关
        let strName = "用户";
        let uiAvatar = <div className="sidebar_avatar  user_icon" />;
        if (this.objUserInfo !== -1 && this.objUserInfo.avatar !== undefined) {
            strName = this.objUserInfo.name;
            if (this.objUserInfo.avatar !== "" && this.objUserInfo.avatar !== null) {
                uiAvatar = <img className="sidebar_avatar" src={this.objUserInfo.avatar} alt="" />;
            }
        }
        // 1. 标题部分
        const uiSidebarTitle = (
            <div className="sidebar_user_button"
                onClick={this.TopAreaClicked.bind(this, 2, "头像区域")} >
                {uiAvatar}
                <div className="sidebar_user_name">{strName}</div>
                <Icon className="sidebar_goto_icon" type="right" />
            </div>
        );
        // 2. 用户收集信息部分: 优惠券、收藏、积分
        const uiUserCollects = (
            <div className="sidebar_user_collects_area">
                <div className="sidebar_user_collects_item"
                    onClick={this.TopAreaClicked.bind(this, 0, "优惠券")} >
                    <div className="sidebar_user_collects_num">
                        <div className="sidebar_user_collects_icon ticket_icon" />{this.state.iTicketNum}</div>
                    <div className="sidebar_user_collects_text">优惠券</div>
                </div>
                <div className="sidebar_divider sidebar_divider_v" />
                <div className="sidebar_user_collects_item"
                    onClick={this.TopAreaClicked.bind(this, 1, "收藏")} >
                    <div className="sidebar_user_collects_num">
                        <div className="sidebar_user_collects_icon collects_icon" />{this.state.iCollectStores}</div>
                    <div className="sidebar_user_collects_text">收藏</div>
                </div>
                <div className="sidebar_divider sidebar_divider_v" />
                <div className="sidebar_user_collects_item"
                    onClick={this.TopAreaClicked.bind(this, 2, "积分")} >
                    <div className="sidebar_user_collects_num">
                        <div className="sidebar_user_collects_icon credit_icon" />0</div>
                    <div className="sidebar_user_collects_text">积分</div>
                </div>
            </div>
        );
        // 3. 主要快捷菜单
        const uiMajorInfo = (
            <div>
                {this.arrMajorInfo.map((item, index) => {
                    let strStyle = "sidebar_item_icon " + item.icon;
                    let uiItem = (
                        <div className="sidebar_item" key={index} onClick={this.MainFunctionAreaClicked.bind(this, index, item.name)}>
                            <div className={strStyle} />{item.name}
                        </div>
                    );
                    if (item.tips !== "") {
                        uiItem = (
                            <Tooltip placement="top" title={item.tips} key={index} trigger="click">
                                <div className="sidebar_item" key={index} onClick={this.MainFunctionAreaClicked.bind(this, index, item.name)}>
                                    <div className={strStyle} />{item.name}
                                </div>
                            </Tooltip>
                        );
                    }
                    return (uiItem);
                })}
            </div>
        );
        // 4. 主分类
        let uiMainCategories = <div />;
        if (!this.state.bLoading) {
            uiMainCategories = (
                <div>
                    {this.arrMainCategories.map((item, index) => {
                        let strStyle = "sidebar_item_icon " + item.icon;
                        return (
                            <div className="sidebar_item" key={index}
                                onClick={this.MainCategoriesAreaClicked.bind(this, index, item)}>
                                <div className={strStyle} />{item.name}
                            </div>
                        );
                    })}
                </div>
            );
        }
        // 5. 更多信息
        const uiMoreInfo = (
            <div>
                {this.arrMoreInfo.map((item, index) => {
                    let strStyle = "sidebar_item_icon " + item.icon;
                    return (
                        <Tooltip placement="top" title={item.tips} key={index} trigger="click">
                            <div className="sidebar_item"
                                onClick={this.SubFunctionAreaClicked.bind(this, index, item.name)}>
                                <div className={strStyle} />{item.name}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        );

        return (
            <div className="sidebar_container">
                <div className="sidebar_logo_btn mouthing_logo" onClick={this.ShowSideBar} />
                <Drawer bodyStyle={DrawerBodyStyle} placement="left" closable={false}
                    onClose={this.HideSideBar} visible={this.state.bSideBarVisible} >
                    {/* 侧边栏内容 */}
                    <div className="sidebar_contents">
                        {/*1. 用户头像、昵称，点击会跳转到用户中心 */}
                        {uiSidebarTitle}
                        {/*2. 用户信息：优惠券、收藏、余额 */}
                        {uiUserCollects}
                        {/*3. 分类快捷菜单：首页，搜索；美食，生活服务，休闲娱乐，周边游 */}
                        {uiMajorInfo}
                        <div className="sidebar_divider sidebar_divider_h" />
                        {uiMainCategories}
                        <div className="sidebar_divider sidebar_divider_h" />
                        {uiMoreInfo}
                        {/*4. 侧边栏底图 */}
                        <div className="sidebar_bottom_area sidebar_bgp" />
                    </div>
                </Drawer>
            </div>
        );
    }
}
export default Sidebar;
