import React, { Component } from "react";
import axios from 'axios';

import Empty from 'antd/es/empty';
import Progress from 'antd/es/progress';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';

// import { Empty, Progress, message } from 'antd';

import '../../../styles/ClientSide/UI_Module/ticket_list.css';
import '../../../styles/ClientSide/UI_Module/store_item_list.css';
import '../../../styles/icon_image.css';

import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

// The list of a store's item or service
class TicketList extends Component {
    constructor(props) {
        super();
        this.state = {
            iTicketGet: -1,
            bCheckTicket: false,
            bShowTicket: false,
            bTicketUseSucceed: false,
            bTicketStatusLoading: false,
            bBuyTicket: false
        };
        this.arrTicketsData = -1;
        this.arrTicketType = ["折扣券", "代金券", "兑换券"];

        this.iCurrentTicket = -1;
        this.iTicketStore = -1;
        this.strTicketTitle = "";
        this.strTicketSubtitle = "";
        this.strCurrentTicketDetail = "";
        this.strTicketCodeUrl = "";

        this.bGettingTicket = false;

        this.arrTicketsStatus = [];  // 针对于当前用户的该门店/该用户所有的优惠券状态

        this.bTicketStatusGet = false;
        this.fCurrentPrice = 0.0;

    }
    // 获取优惠券基本&详细信息
    GetTicketInfo = (iTicket) => {
        this.iCurrentTicket = iTicket;
        this.iTicketStore = this.arrTicketsData[iTicket].store_id;
        this.strTicketTitle = this.arrTicketsData[iTicket].title.split('_')[1];
        this.strTicketSubtitle = this.arrTicketsData[iTicket].subtitle;
        this.strCurrentTicketDetail = this.arrTicketsData[iTicket].description;
        if (this.strCurrentTicketDetail === "")
            this.strCurrentTicketDetail = " - 暂无 详情介绍/使用细则 - ";
        this.strTicketCodeUrl = "https://www.mouthing.info/store-player/scan/?store=" + this.iTicketStore +
            "?ticket=" + this.arrTicketsData[iTicket].id +
            "?user=" + this.props.objUserInfo.id;
    }
    // 领取优惠券
    GetTicket = (iTicket) => {
        if (this.props.objUserInfo === -1) {
            message.warning("请先登录");
        } else {
            if (this.bGettingTicket)
                return;
            this.bGettingTicket = true;

            if (this.arrTicketsData.length > 0) {

                let fPrice = parseFloat(this.arrTicketsData[iTicket].price);
                if (fPrice <= 0 || this.arrTicketsData[iTicket].price === null ||
                    this.arrTicketsData[iTicket].price.isNaN || this.arrTicketsData[iTicket].price.trim() === "") {
                    console.log("fPrice is NaN");
                } else {
                    console.log("Get Ticket : ", fPrice);
                    this.bGettingTicket = false;
                    this.fCurrentPrice = fPrice;
                    this.strCurrentTicketDetail = this.arrTicketsData[iTicket].description;
                    this.setState({
                        ...this.state,
                        bBuyTicket: true
                    });

                    return;
                }

                let objTicketUserInfo = {
                    ticket_id: this.arrTicketsData[iTicket].id,
                    user_id: this.props.objUserInfo.id,
                    status: 0,
                    get_date: new Date()
                }

                axios.post('/UserGetTicket', { objTicketUserInfo: objTicketUserInfo, objUserInfo: this.props.objUserInfo })
                    .then(({ data }) => {
                        this.props.objUserInfo.ticket_list = data;
                        // DataStatistic
                        UserActiveUpdate(15, { active: "领取优惠券", store: this.props.iStoreID, ticket: objTicketUserInfo.ticket_id, user: objTicketUserInfo.user_id });
                        message.success('优惠券领取成功！');
                        this.bGettingTicket = false;
                        this.setState({
                            ...this.state,
                            iTicketGet: iTicket
                        })

                    }).catch(function (error) { console.log(error); });


            } else {
                console.log("Error: No Ticket Data !");
            }
        }
    }
    // 购买优惠券确认
    BuyTicketConfirm = (bConfirm) => {
        console.log("BuyTicketConfirm : " + bConfirm);
        this.setState({
            ...this.state,
            bBuyTicket: false
        });
    }
    // 使用优惠券
    UseTicket = (iTicket) => {
        if (this.bGettingTicket || this.state.bTicketStatusLoading)
            return;

        if (this.props.objUserInfo === -1) {
            message.warning("用户信息失效，请重新登录");
            return;
        }

        let iStoreID = this.arrTicketsData[iTicket].store_id;
        // 电商
        if (iStoreID >= 10000) {
            message.info("请选择商品/服务并使用优惠券");
            if (this.props.iCurrentPage === 2) {

            }
            if (this.props.iCurrentPage === 3) {
                window.location.href = '/webapp/store/' + iStoreID + "?tab=0";
            }
        } else {
            this.ShowTicketToStore(true, iTicket);
        }
    }
    // 初始优惠券给商家看来进行确认核销
    ShowTicketToStore = (bShow, iTicket) => {
        if (bShow)
            this.GetTicketInfo(iTicket);
        this.setState({
            ...this.state,
            bShowTicket: bShow
        });
    }
    // 是否已领取优惠券的判断
    TicketGetCheck = (iTicket) => {
        let bGet = false;
        if (this.props.objUserInfo) {
            if (this.props.objUserInfo.ticket_list) {
                if (this.props.objUserInfo.ticket_list !== "") {
                    let strTicketID = this.arrTicketsData[iTicket].id.toString();
                    let arrUserTicketList = this.props.objUserInfo.ticket_list.split(',');
                    if (arrUserTicketList.indexOf(strTicketID) >= 0)
                        bGet = true;
                }
            }
        }
        return bGet;
    }
    // 是否已使用优惠券的判断
    TicketsUseCheck = () => {
        this.setState({
            ...this.state,
            bTicketStatusLoading: true
        });
        let arrTicketsID = [];
        for (let i = 0; i < this.arrTicketsData.length; i++)
            arrTicketsID.push(this.arrTicketsData[i].id);
        axios('/GetTicketsUseStatus', { params: { arrTicketsID: arrTicketsID, user_id: this.props.objUserInfo.id } })
            .then(({ data }) => {
                this.arrTicketsStatus = data;
                this.setState({
                    ...this.state,
                    bTicketStatusLoading: false
                });
            }).catch(function (error) { console.log(error); });
    }
    // 某个优惠券当前的使用状态检查
    TicketUseCheck = (iTicket) => {
        let bUsed = false;
        for (let i = 0; i < this.arrTicketsStatus.length; i++) {
            if (this.arrTicketsStatus[i].ticket_id === this.arrTicketsData[iTicket].id) {
                if (this.arrTicketsStatus[i].status === 1) {
                    bUsed = true;
                    break;
                }
            }
        }
        return bUsed;
    }
    // 点击查看优惠券
    ClickTicket = (iTicket) => {
        this.GetTicketInfo(iTicket);
        this.setState({
            ...this.state,
            bCheckTicket: true
        })
    }
    // 取消查看优惠券
    CheckTicketCancel = e => {
        this.setState({
            ...this.state,
            bCheckTicket: false
        });
    };
    // 确定使用优惠券（核销）
    ConfirmUseTicket = () => {
        axios.post('/TicketUseRecord', { ticket_id: this.arrTicketsData[this.iCurrentTicket].id, user_id: this.props.objUserInfo.id })
            .then(({ data }) => {
                this.TicketsUseCheck();
                this.TicketUseSucceed(true);
            }).catch(function (error) { console.log(error); });

    }
    // 优惠券使用（核销）成功
    TicketUseSucceed = (bShow) => {
        this.setState({
            ...this.state,
            bShowTicket: false,
            bTicketUseSucceed: bShow
        });
    }

    componentDidMount() {
        if (this.arrTicketsData.length > 0 && this.props.objUserInfo !== -1) {
            this.bTicketStatusGet = true;
            if (this.arrTicketsStatus.length <= 0) {
                this.TicketsUseCheck();
            }
        }
    }

    render() {

        let uiTicketList = (
            <div className="store_item_list_area">
                <div className="store_item_list_loading">
                    <Spin className="ads_spin" size="large" />
                </div>
            </div>
        );
        if (this.props.arrTicketsData !== undefined && !this.bTicketStatusGet) {
            if (this.props.arrTicketsData !== -1) {
                this.arrTicketsData = this.props.arrTicketsData;
                if (this.props.arrTicketsData.length > 0) {
                    this.bTicketStatusGet = true;
                    this.arrTicketsStatus = this.props.arrTicketsStatus;
                } else {
                    uiTicketList = (<Empty className="ticket_empty" description="该商家还未上线任何优惠券" />);
                    if (this.props.iCurrentPage === 3)
                        uiTicketList = (<Empty className="ticket_empty" description="还未领取任何优惠券" />);
                }
            }
        }

        if (this.arrTicketsData !== -1 && this.arrTicketsData.length > 0) {
            uiTicketList = (
                <div className="ticket_list">
                    {this.arrTicketsData.map((item, index) => {
                        let strTitle = item.title;
                        if (strTitle.indexOf('_') !== -1)
                            strTitle = strTitle.split('_')[1];
                        let dateExpire = item.expire_date;
                        if (dateExpire === null)
                            dateExpire = "";
                        else
                            dateExpire = dateExpire.split('T')[0] + " 到期";
                        let bGet = this.TicketGetCheck(index);
                        let fPrice = parseFloat(item.price);
                        if (fPrice <= 0 || item.price === null || item.price.isNaN || item.price.trim() === "")
                            fPrice = "领 取"
                        else
                            fPrice = "$ " + fPrice;
                        let uiGetButton = (
                            <div className="ticket_get_btn" onClick={this.GetTicket.bind(this, index)}>{fPrice}</div>
                        );
                        if (bGet) {
                            uiGetButton = (
                                <div className="ticket_get_btn_use" onClick={this.UseTicket.bind(this, index)}>去使用</div>
                            );
                        }
                        if (!this.state.bTicketStatusLoading) {
                            if (this.TicketUseCheck(index)) {
                                uiGetButton = (
                                    <div className="ticket_get_btn_used" >已使用</div>
                                );
                            }
                        }
                        let fSoldPersentage = (item.sold_num * 100) / item.total_num;
                        if (fSoldPersentage <= 10.0)
                            fSoldPersentage = 10.0;
                        fSoldPersentage = parseInt(fSoldPersentage, 10);
                        return (
                            <div className="ticket_bgp ticket_bgp_image" key={index} >
                                <div className="ticket_store_info">
                                    <div className="ticket_store_name">{item.store_name}</div>
                                    <div className="ticket_type">{this.arrTicketType[item.type]}</div>
                                </div>
                                <div className="ticket_details_area">
                                    <img className="ticket_item_icon" src={item.store_logo} alt='' />
                                    <div className="ticket_title_area" onClick={this.ClickTicket.bind(this, index)}>
                                        <div className="ticket_title">{strTitle}</div>
                                        <div className="ticket_subtitle">{item.subtitle}</div>
                                        <div className="ticket_expire_date">{dateExpire}</div>
                                    </div>
                                    <div className="ticket_type_get_area">
                                        {uiGetButton}
                                        <div className="ticket_get_info">已被领取</div>
                                        <div className="ticket_get_info">
                                            <Progress percent={fSoldPersentage} size="small" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        // 优惠券详情弹窗
        let uiTicketDetails = (
            <Modal title={this.strTicketTitle} visible={this.state.bCheckTicket} onCancel={this.CheckTicketCancel}
                footer={null} centered={true}>
                <div className="ticket_details_title">优惠券详情(使用规则) : </div>
                <div className="ticket_details_text">{this.strCurrentTicketDetail}</div>
            </Modal>
        );

        // 优惠券核销弹窗
        let uiTicketUse = (
            <Modal title="请将二维码出示给商家" visible={this.state.bShowTicket} onCancel={this.ShowTicketToStore.bind(this, false)}
                footer={null} centered={true}>
                <div className="ticket_details_title">{this.strTicketTitle}</div>
                <div className="ticket_details_text">{this.strCurrentTicketDetail}</div>
                <div className="ticket_code">
                    <img src={"https://api.qrserver.com/v1/create-qr-code/?data=" + this.strTicketCodeUrl + "&amp;size=64x64"}
                        alt="" title="" />
                </div>
                {/* <div className="ticket_use_confirm_button" onClick={this.ConfirmUseTicket}>确定使用</ div> */}
            </Modal>
        );

        // 优惠券使用（核销）成功
        let uiTicketUseSucceed = (
            <Modal title="优惠券使用成功！" visible={this.state.bTicketUseSucceed} onCancel={this.TicketUseSucceed.bind(this, false)}
                footer={null} centered={true}>
                <div className="ticket_details_title">感谢您的使用，欢迎下次光临！</div>
                <div className="ticket_use_confirm_button" onClick={this.TicketUseSucceed.bind(this, false)}>好的</ div>
            </Modal>
        );

        // 优惠券购买弹窗
        let fGST = parseFloat((this.fCurrentPrice * 0.12).toFixed(2));
        let fTotalPrice = (this.fCurrentPrice + fGST).toFixed(2);
        // let uiCurrentPaymentMethod = <div>{this.arrPaymentMethod[this.iConfirmedPaymentMethod] + " >"}</div>
        // // 如果是信用卡/借记卡支付
        // if (this.iConfirmedPaymentMethod === 1) {
        //     let uiCardBrand = <div className="card_brand_icon master_icon align_right" />
        //     if (this.strCurrentCardBrand === "visa")
        //         uiCardBrand = <div className="card_brand_icon visa_icon align_right" />
        //     uiCurrentPaymentMethod = (
        //         <div className="select_card_brief_info">
        //             {uiCardBrand}
        //             <div className="select_card_brief_last4">{this.strCurrentLast4 + " >"}</div>
        //         </div>
        //     );
        // }
        let uiTicketPurchase = (
            <Modal title="购买优惠券" visible={this.state.bBuyTicket} onCancel={this.BuyTicketConfirm.bind(this, false)}
                onOk={this.BuyTicketConfirm.bind(this, true)} centered={true}
                okText="确定支付" cancelText="取消">
                <div className="ticket_details_title">优惠券详情(使用规则) : </div>
                <div className="ticket_details_text">{this.strCurrentTicketDetail}</div>

                <div className="ticket_details_payment_method" /*onClick={this.SelectPaymentMethod}*/>
                    <div className="order_details_icon card_icon" />
                    支付方式：
                    <div className="order_details_right_side_option">{"1161"}</div>
                </div>

                <div className="ticket_price_text">{"价格：" + this.fCurrentPrice.toFixed(2)}</div>
                <div className="ticket_price_text">{"GST+手续费：" + fGST}</div>
                <div className="ticket_price_text ticket_price_bottom_line">{"实际支付：" + fTotalPrice}</div>
            </Modal>
        );

        return (
            <div>
                {uiTicketList}
                {/* 优惠券详情展示框 */}
                {uiTicketDetails}
                {/* 优惠券使用二次确认框 */}
                {uiTicketUse}
                {/* 优惠券使用成功提示框 */}
                {uiTicketUseSucceed}
                {/* 优惠券购买提示框 */}
                {uiTicketPurchase}
            </div>
        );
    }
}
export default TicketList;
