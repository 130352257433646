import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Menu from 'antd/es/menu';
import Tabs from 'antd/es/tabs';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Drawer from 'antd/es/drawer';
import Modal from 'antd/es/modal';
import Affix from 'antd/es/affix';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

// import { Menu, Tabs, Icon, Tooltip, Empty, Spin, Drawer, message, Modal, Affix, Dropdown } from 'antd';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import StoreCutPrice from './StoreCutPrice.js'  // 团购拼单商家
import StorePickup from './StorePickup.js'  // 自提外卖商家
import StoreRetail from './StoreRetail.js'  // 电商零售商家模板1
import StoreRetail2 from './StoreRetail2'  // 电商零售商家模板2
import Navbar from './UI_Module/Navbar.js'
import AdsCarousel from './UI_Module/AdsCarousel.js'
import StoreItemList from './UI_Module/StoreItemList.js'
import TicketList from './UI_Module/TicketList.js'
import CardSection from './UI_Module/CardSection.js';
import OrderDetails from './OrderDetails.js';
import LoadingPage from './UI_Module/LoadingPage.js';
import '../../styles/ClientSide/store_page.css';
import '../../styles/icon_image.css';
import '../../styles/ClientSide/UI_Module/ticket_list.css';
import '../../styles/ClientSide/stripe.css';
import '../../styles/color_set.css';
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { GetItemsID } from "../../functions/DataStatistic.js"

// const stripePromise = loadStripe("pk_test_Ck37fMoMJvFwBDTkjTPltHz400q1ObN1bc");
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

// const g_strMessageKey = 'updating';

// Tab Panel & Drawer Style Control
const { TabPane } = Tabs;
const mapStyles = { width: '90vw', height: '60vw' };
const DrawerBodyStyle = {
    border: 0, margin: 0, padding: 0,
    width: '100vw', height: '75vh',
    backgroundColor: 'white'
};

class Store extends Component {
    constructor(props) {
        super();

        this.iStoreID = props.match.params.id;
        let strParams = window.location.search;
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        let iTab = parseInt(strParams.split('&')[0].split('=')[1], 10);
        if (iTab !== 0 && iTab !== 1 && iTab !== 2) {
            iTab = 0;
        }

        let bOrderDetails = false;
        if (strParams.indexOf('order') !== -1) {
            let iOrderDetails = parseInt(strParams.split('&')[1].split('=')[1], 10);
            if (iOrderDetails >= 0)
                bOrderDetails = true;
        }

        this.state = {
            bLoading: true,
            bItemListLoading: true,
            iCurrentTab: iTab,
            iCurrentItemType: 0,
            objStorePos: { lat: null, lng: null },
            strFormattedAddress: "Richmond Center",
            arrItemsData: [],
            bBudgetUpdate: true,
            bOrderDetails: false,
            bBudgetDetails: bOrderDetails,
            bLogin: false,
            bStaring: false,
            bPaymentDlg: false
        };

        this.arrItemTypeData = [
            { id: 0, store_id: this.iStoreID, type_name: "全部", type_order: 0, description: "" }
        ];
        this.arrItemsData = [];
        this.arrTicketsData = -1;
        this.arrTicketsStatus = [];
        this.arrOpenTime = [
            "周一 09:00am - 09:00pm",
            "周二 09:00am - 09:00pm",
            "周三 09:00am - 09:00pm",
            "周四 09:00am - 09:00pm",
            "周五 09:00am - 09:00pm",
            "周六 09:00am - 09:00pm",
            "周日 09:00am - 09:00pm"
        ];
        this.objStoreInfo = {
            id: 0, store_name: "", cell_phone: "", address: "", description: "", mall: "", logo: "",
            open_time: "", arrAdsImages: []
        };
        this.iCurrentDate = 0;
        this.arrTabTriggered = [false, false, false];
        this.arrTicketType = ["折扣券", "代金券", "兑换券"];
        this.objCart = {
            arrBudgetItems: [], iItemTotalNum: 0, fItemTotalPrice: 0.0
        };
        this.strCurrentUserCode = "";
        this.objUserInfo = -1;
        this.bStarred = false;
        this.bOrderDetails = bOrderDetails;  // 从地址页返回商家页时如果订单详情之前是打开的，用此参数作判断标志，等用户信息获取完毕后在打开订单详情
        this.iCurrentTab = iTab;

        this.iStoreType = 0;
        this.GetStoreData();

    }
    // 从数据库获取当前商家的数据
    GetStoreData() {
        // 1. 发送获取分类数据的请求
        axios('/GetStoreInfo', {
            params: { id: this.iStoreID }
        }).then(
            ({ data }) => {
                if (data.length <= 0) {
                    message.warning("商家不存在！");
                    return;
                }
                this.iStoreType = parseInt(data[0].type, 10);
                this.objStoreInfo = data[0];
                if (this.objStoreInfo.status <= 0) {
                    message.warning("商家正在审核中，即将上线……");
                    return;
                }
                if (this.objStoreInfo.phone === "") {
                    if (this.objStoreInfo.cell_phone === "")
                        this.objStoreInfo.phone = "暂无联系方式";
                    else
                        this.objStoreInfo.phone = this.objStoreInfo.cell_phone;
                }
                if (this.objStoreInfo.description === "")
                    this.objStoreInfo.description = "暂无描述……";
                if (this.objStoreInfo.address === "")
                    this.objStoreInfo.address = "Richmond Center";
                if (this.objStoreInfo.open_time !== undefined) {
                    if (this.objStoreInfo.open_time !== null && this.objStoreInfo.open_time !== "") {
                        this.arrOpenTime = [];
                        this.arrOpenTime = this.objStoreInfo.open_time.split(',');
                    }
                }
                // 获取用户信息，判断收藏状态
                let strUserCode = cookie.load('wm_user_code');
                let iUserLoginStatus = cookie.load('wm_login_status');
                if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
                    this.strCurrentUserCode = strUserCode;
                    this.UserCodeCheck();
                } else {
                    this.objUserInfo = -1;
                    this.bStarred = false;
                }
                // 2. 发送获取头图数据的请求
                axios('/GetAdsImages', {
                    params: { store_id: this.iStoreID, ads_order: -1 }
                }).then(
                    ({ data }) => {
                        this.objStoreInfo.arrAdsImages = data;
                        // 3. 获取商品分类信息
                        axios('/GetStoreItemTypes', { params: { store_id: this.iStoreID } })
                            .then(({ data }) => {
                                this.arrItemTypeData = [
                                    { id: 0, store_id: this.iStoreID, type_name: "全部", type_order: 0, description: "" }
                                ];
                                if (this.objStoreInfo.type === 1 || this.objStoreInfo.type === 3)
                                    this.arrItemTypeData = [];

                                if (data.length > 0) {
                                    for (let i = 0; i < data.length; i++) {
                                        this.arrItemTypeData.push(data[i]);
                                    }
                                }
                                this.arrTabTriggered[this.state.iCurrentTab] = true;
                                // 4, 获取所有商品信息
                                this.GetStoreItems();
                                // 5, 获取所有优惠券信息
                                this.GetStoreTickets();
                                // 6. 获取商家位置信息
                                this.GetStoreLocation(this.objStoreInfo.address);
                            });
                    }
                ).catch(function (error) { console.log(error); });
            }
        ).catch(function (error) { console.log(error); });
    }
    // 获取商家商品信息，Tab0响应
    GetStoreItems = () => {
        this.setState({
            ...this.state,
            iCurrentTab: 0,
            bItemListLoading: true
        });
        this.iCurrentTab = 0;
        let strType = '%';
        if (this.state.iCurrentItemType !== 0)
            strType += this.arrItemTypeData[this.state.iCurrentItemType].id.toString() + '%';
        axios('/GetItems', { params: { store_id: this.iStoreID, type: strType } })
            .then(({ data }) => {
                // console.log("GetItems : ", data);
                this.arrItemsData = data;
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bItemListLoading: false
                });
            });
    }
    // 获取商家优惠券信息，Tab1响应
    GetStoreTickets = () => {
        axios('/GetTickets', {
            params: { store_id: this.iStoreID, status: 1 }
        }).then(({ data }) => {
            this.arrTicketsData = data;
            if (this.props.objUserInfo !== -1 && this.arrTicketsData.length > 0) {
                let arrTicketsID = [];
                for (let i = 0; i < this.arrTicketsData.length; i++)
                    arrTicketsID.push(this.arrTicketsData[i].id);
                if (this.objUserInfo === -1) {
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    axios('/GetTicketsUseStatus', { params: { arrTicketsID: arrTicketsID, user_id: this.objUserInfo.id } })
                        .then(({ data }) => {
                            this.arrTicketsStatus = data;
                            this.setState({
                                ...this.state,
                                bLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            } else {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 获取商家位置信息，Tab2响应
    GetStoreLocation = (strAddress) => {
        let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        axios.get(strUrl)
            .then((response) => {

                // console.log("GetStoreLocation = ");
                // console.log(response.data.results[0].geometry.location);

                this.setState({
                    ...this.state,
                    bLoading: false,
                    // bOrderDetails: this.bOrderDetails,
                    objStorePos: response.data.results[0].geometry.location,
                    strFormattedAddress: response.data.results[0].formatted_address
                });
            }).catch(err => {
                console.log(err) //Axios entire error message
                console.log(err.response.data.error) //Google API error message 
            });
    }
    // 切换商品类别
    SwitchItemType = (iTypeIndex) => {
        this.setState({
            ...this.state,
            bItemListLoading: true
        });
        // DataStatistic
        UserActiveUpdate(12, { active: "切换商品分类", type: iTypeIndex, name: this.arrItemTypeData[iTypeIndex].type_name });
        // 获取所有商品信息
        let strType = '%';
        if (iTypeIndex !== 0)
            strType += this.arrItemTypeData[iTypeIndex].id.toString() + '%';
        axios('/GetItems', { params: { store_id: this.iStoreID, type: strType } })
            .then(({ data }) => {
                this.arrItemsData = data;
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bItemListLoading: false,
                    iCurrentItemType: iTypeIndex,
                });
            });
    }
    // 排队按钮响应
    LineUpClicked() {
        console.log("line up btn clicked...");
    }
    // 日期转换为索引
    DateToIndex(strDate) {
        switch (strDate) {
            case 'Mon': {
                this.iCurrentDate = 0;
                break;
            }
            case 'Tue': {
                this.iCurrentDate = 1;
                break;
            }
            case 'Wed': {
                this.iCurrentDate = 2;
                break;
            }
            case 'Thu': {
                this.iCurrentDate = 3;
                break;
            }
            case 'Fri': {
                this.iCurrentDate = 4;
                break;
            }
            case 'Sat': {
                this.iCurrentDate = 5;
                break;
            }
            case 'Sun': {
                this.iCurrentDate = 6;
                break;
            }
            default: {
                this.iCurrentDate = 0;
                break;
            }
        }
    }
    // 标签页被点击的响应
    TabChanged = (activeKey) => {
        let iTab = parseInt(activeKey, 10);
        this.setState({
            ...this.state,
            iCurrentTab: iTab
        });
        this.iCurrentTab = iTab;
        // DataStatistic
        UserActiveUpdate(8, { active: "商家标签页点击", tab: iTab });

        if (!this.arrTabTriggered[iTab]) {
            this.arrTabTriggered[iTab] = true;
            switch (iTab) {
                case 0: {
                    // 4, 获取所有商品信息
                    this.GetStoreItems();
                    this.SwitchItemType(0);
                    break;
                }
                case 1: {
                    // 5, 获取所有优惠券信息
                    this.GetStoreTickets();
                    break;
                }
                case 2: {
                    // 6. 获取商家位置信息
                    this.GetStoreLocation(this.objStoreInfo.address);
                    break;
                }
                default: break;
            }
        } else {
            if (iTab === 0) {
                this.SwitchItemType(this.state.iCurrentItemType);
            }
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === 1) {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.collect_stores === null ||
                        this.objUserInfo.collect_stores === "") {
                        this.bStarred = false;
                    } else {
                        if (this.objUserInfo.collect_stores.indexOf(this.objStoreInfo.id.toString())
                            === -1)
                            this.bStarred = false;
                        else
                            this.bStarred = true;
                    }
                    if (this.state.objStorePos.lat === null || this.state.objStorePos.lng === null)
                        this.GetStoreLocation(this.objStoreInfo.address);
                    this.setState({
                        ...this.state,
                        bOrderDetails: this.bOrderDetails,
                        bLogin: true
                    });
                } else {
                    this.objUserInfo = -1;
                    this.setState({
                        ...this.state,
                        bLogin: false
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 收藏按钮点击响应
    StarBtnClicked = () => {
        if (this.state.bStaring)
            return;
        else
            this.setState({ ...this.state, bStaring: true });
        // 还未登录
        if (this.objUserInfo === -1) {
            message.info("请先注册/登录！");
            // message.info({ content: "请先注册/登录！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLogin: false,
                bStaring: false
            });
        } else {  // 已有用户信息
            // 还未收藏
            if (!this.bStarred) {
                if (this.objUserInfo.collect_stores === null)
                    this.objUserInfo.collect_stores = "";
                if (this.objUserInfo.collect_stores === "")
                    this.objUserInfo.collect_stores = this.objStoreInfo.id.toString();
                else
                    this.objUserInfo.collect_stores += "," + this.objStoreInfo.id.toString();
            } else {  // 已收藏
                let arrCollectStores = this.objUserInfo.collect_stores.split(',');
                let strNewCollects = "";
                for (let i = 0; i < arrCollectStores.length; i++) {
                    if (arrCollectStores[i] !== this.objStoreInfo.id.toString()) {
                        if (strNewCollects === "")
                            strNewCollects += arrCollectStores[i].toString();
                        else
                            strNewCollects += "," + arrCollectStores[i].toString();
                    }
                }
                this.objUserInfo.collect_stores = strNewCollects;
            }
            // 更新用户表中的商家收藏信息
            axios.post('/UpdateUserCollectStores', { objUserInfo: this.objUserInfo })
                .then(({ data }) => {
                    if (data.status === 1) {
                        if (!this.bStarred) {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "关注商家" });
                            message.success("已关注！");
                            // message.success({ content: "已关注！", key: g_strMessageKey, duration: 2 });
                        } else {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "取关商家" });
                            message.info("已取关！");
                            // message.success({ content: "已取关！", key: g_strMessageKey, duration: 2 });
                        }
                        this.bStarred = !this.bStarred;
                        this.setState({
                            ...this.state,
                            bLogin: true,
                            bStaring: false
                        });
                    } else {
                        message.warning("关注失败，请重新登录后尝试！");
                        // message.warning({
                        //     content: "关注失败，请重新登录后尝试！",
                        //     key: g_strMessageKey, duration: 2
                        // });
                        this.setState({
                            ...this.state,
                            bStaring: false
                        });
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 预算计算
    RefreshBudget = (arrBudgetItems, iItemTotalNum, fItemTotalPrice) => {

        if (iItemTotalNum <= 0) {
            iItemTotalNum = 0;
            fItemTotalPrice = 0.0;
        }
        this.objCart = {
            arrBudgetItems: arrBudgetItems,
            iItemTotalNum: iItemTotalNum,
            fItemTotalPrice: fItemTotalPrice
        }
        if (iItemTotalNum === 0)
            cookie.save('wm_user_cart', "", { path: '/' });
        else
            cookie.save('wm_user_cart', this.objCart, { path: '/' });
        this.setState({
            ...this.state,
            bBudgetUpdate: true
        });
    }
    // 预算详情抽屉控制
    ShowBudgetDetails = (bShow, e) => {
        if (bShow) {
            // DataStatistic
            let arrItems = GetItemsID(this.objCart);
            UserActiveUpdate(15, { active: "打开购物车", arrItems: arrItems });
        }
        this.setState({
            bBudgetDetails: bShow,
        });
    };
    // 打开订单详情
    ShowOrderDetails = () => {
        // 检查商品数量
        if (this.objCart.iItemTotalNum <= 0 && this.objCart.fItemTotalPrice <= 0.0) {
            message.info("还未添加任何商品……");
        } else {
            // 判断登录状态
            if (this.state.bLogin) {

                // 电商则打开订单详情，允许下单支付
                if (this.iStoreType === 1) {
                    // DataStatistic
                    let arrItems = GetItemsID(this.objCart);
                    UserActiveUpdate(16, { active: "打开订单详情", arrItems: arrItems });
                    this.UserCodeCheck();
                    this.bOrderDetails = true;
                    this.setState({
                        bOrderDetails: true
                    });
                }
                // 黄页商家则给出提示
                else {
                    message.info("该商家只提供商品/服务的价格/预算查询");
                }
            } else {
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            }
        }
    };
    // 收起订单详情
    HideOrderDetails = () => {
        // this.bOrderDetails = false;
        this.setState({
            bOrderDetails: false,
        });
    }
    // 商家详情区域点击
    StoreInfoAreaClicked = (iInfo) => {
        // DataStatistic
        UserActiveUpdate(9, { active: "查看商家详情", info: iInfo });
    }
    // 点击了用户按钮（仅限于特制的独立商家）
    UserIconClicked = () => {
        window.location.href = '/webapp/user?tab=3&store=' + this.iStoreID.toString();
    }
    // Cancle Payment / Close Payment Dlg
    CancelPayment = () => {
        console.log("Store >>> Cancel Payment");
        this.setState({
            ...this.state,
            bPaymentDlg: false
        });
    }
    // 加号按钮点击响应
    AddBtnClicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            let bExistItem = false;
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num++;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        bExistItem = true;
                        break;
                    }
                }
            }
            if (!bExistItem) {
                let objItem = { num: 0, item: {} };
                objItem.num = 1; objItem.item = item;
                this.objCart.arrBudgetItems.push(objItem);
                // DataStatistic
                UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: 1 });
            }
            this.objCart.iItemTotalNum++;
            this.objCart.fItemTotalPrice += parseFloat(strPrice, 10);
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 减号按钮点击响应
    RemoveBtnCLicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num--;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "从购物车移除商品", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        this.objCart.iItemTotalNum--;
                        this.objCart.fItemTotalPrice -= parseFloat(strPrice, 10);
                        if (this.objCart.arrBudgetItems[i].num <= 0) {
                            this.objCart.arrBudgetItems.splice(i, 1);
                        }
                        break;
                    }
                }
            }
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 清空购物车
    ClearCart = () => {
        this.RefreshBudget([], 0, 0);
    }
    // 电商顶部栏Logo按钮点击
    MouthingLogoClicked = () => {
        // DataStatistic
        UserActiveUpdateBack(19, { active: "返回Mouthing平台首页" }, this.JumpToHome);
    }
    // 从电商页返回首页
    JumpToHome = () => {
        window.location.href = '/webapp/home';
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问商家页" });
        // 获取日期信息
        let today = new Date().toString();
        this.DateToIndex(today.split(' ')[0]);
        // 获取购物车信息
        let objCart = cookie.load('wm_user_cart');
        if (objCart !== undefined) {
            if (objCart.iItemTotalNum === undefined)
                return;
            if (objCart.iItemTotalNum < 0) {
                objCart.iItemTotalNum = 0;
                cookie.save('wm_user_cart', objCart, { path: '/' });
            }
            this.objCart = objCart;
            this.setState({
                ...this.state,
                bPaymentDlg: false
            })
        }
    }

    render() {

        // 头图区域
        let uiAdsTitle = (
            <div className="ads_loading">
                <Spin className="ads_spin" size="large" />
            </div>
        );
        if (this.state.bLoading) {
            return <LoadingPage />;
        } else {
            uiAdsTitle = <AdsCarousel iCurrentPage={2} iStoreID={this.iStoreID}
                arrAdsImages={this.objStoreInfo.arrAdsImages} />
        }
        // 商家名称 ＆ 热度
        const uiStoreMainInfo = (
            <div className="store_key_info">
                <div className="store_name">{this.objStoreInfo.store_name}</div>
                {/* <div className="store_hot_area">
                    <div className="hot_star_icon store_page_hot_stars" />
                    <div className="hot_star_icon store_page_hot_stars" />
                    <div className="hot_star_icon store_page_hot_stars" />
                    <div className="hot_star_icon_empty store_page_hot_stars" />
                    <div className="hot_star_icon_empty store_page_hot_stars" />
                </div> */}
                <Tooltip title={this.objStoreInfo.mall} placement="topRight" >
                    <div className="store_mall_info color_brown">{this.objStoreInfo.mall}</div>
                </Tooltip>
            </div>
        );

        let arrTabStyle = ["store_tab_text", "store_tab_text", "store_tab_text"];
        arrTabStyle[this.iCurrentTab] = "store_tab_text_active";
        // 商品切换按钮
        const uiItemTab = (
            <Affix target={() => this.container}
                onChange={affixed => console.log(affixed)}>
                <div className="store_tab_button" >
                    <Icon type="gift" theme="filled" />
                    <div className={arrTabStyle[0]}>商品</div>
                    <div className="store_tab_text_hide">服</div>
                </div>
            </Affix>
        );
        // 优惠券切换按钮
        const uiTicketTab = (
            <Affix target={() => this.container}
                onChange={affixed => console.log(affixed)}>
                <div className="store_tab_button" >
                    <Icon type="tag" theme="filled" />
                    <div className={arrTabStyle[1]}>优惠券</div>
                    <div className="store_tab_text_hide">卡</div>
                </div>
            </Affix>
        );
        // 商家信息切换按钮
        const uiDetailsTab = (
            <Affix target={() => this.container}
                onChange={affixed => console.log(affixed)}>
                <div className="store_tab_button" >
                    <Icon type="shop" theme="filled" />
                    <div className={arrTabStyle[2]}>商家信息</div>
                </div>
            </Affix>
        );
        // 底部预算统计区域
        let strTotalPrice = "0.00";
        if (this.objCart.fItemTotalPrice > 0.0)
            strTotalPrice = this.objCart.fItemTotalPrice.toFixed(2).toString();
        let uiBottomArea = (
            <div>
                <div className="store_bottom_space" />
                <div className="store_bottom_cart_area color_brown" onClick={this.ShowBudgetDetails.bind(this, true)}>
                    <div className="store_cart_icon_area" >
                        <div className="store_cart_icon cart_icon" />
                        <div className="store_budget_item_num">{this.objCart.iItemTotalNum}</div>
                    </div>
                    <div className="store_cart_area_title" >
                        {"$ " + strTotalPrice}</div>
                    {/* <div className="store_cart_area_right_space" /> */}
                    <div className="store_cart_area_btn color_brown">选好了</div>
                </div>
            </div>
        );
        // 购物车详情中的物品列表
        let uiItems = <div />;
        if (this.objCart.arrBudgetItems.length > 0) {
            uiItems = (
                <div className="store_budget_details_contents">
                    {this.objCart.arrBudgetItems.map((item, index) => {
                        return (
                            <div className="store_budget_details_item" key={index}>
                                <img className="store_budget_details_item_logo"
                                    src={item.item.logo} alt="" />
                                <div className="store_budget_details_item_name">
                                    {item.item.name}
                                    <div className="store_item_options">{item.item.options}</div>
                                </div>
                                <div className="store_item_add_area">
                                    <div className="store_item_add_btn"
                                        onClick={this.RemoveBtnCLicked.bind(this, item.item)}>－</div>
                                    <div className="store_item_add_num">{item.num}</div>
                                    <div className="store_item_add_btn"
                                        onClick={this.AddBtnClicked.bind(this, item.item)}>＋</div>
                                </div>
                                {/* <div className="store_budget_details_item_price">
                                    {" $ " + item.item.price.toString()}
                                </div> */}
                            </div>
                        );
                    })}
                </div>
            );
        }
        // 购物车详情
        let strPay = "登录并结算";
        if (this.state.bLogin)
            strPay = "去 结 算";
        let uiBudgetDetails = (
            <Drawer bodyStyle={DrawerBodyStyle}
                placement="bottom" closable={false} height='75vh'
                onClose={this.ShowBudgetDetails.bind(this, false)} visible={this.state.bBudgetDetails}  >
                <div className="store_budget_details_area">
                    <div className="store_budget_details_title">
                        <div className="store_cart_icon_area">
                            <div className="store_cart_icon cart_icon" />
                            <div className="store_budget_item_num">{this.objCart.iItemTotalNum}</div>
                        </div>
                        <div className="store_cart_area_title">{"$ " + strTotalPrice}</div>
                        <div className="store_cart_area_right_space" onClick={this.ShowBudgetDetails.bind(this, false)}> x </div>
                    </div>
                    <div className="store_cart_clear_button" onClick={this.ClearCart}><Icon type="delete" /></div>
                    <div className="store_checkout_button color_brown" onClick={this.ShowOrderDetails}>{strPay}</div>
                    {uiItems}
                </div>
            </Drawer>
        );
        // 订单详情左抽屉
        let uiOrderDetails = (
            <Drawer bodyStyle={DrawerBodyStyle}
                placement="left" closable={false} width='100vw' height='auto'
                onClose={this.HideOrderDetails} visible={this.state.bOrderDetails} >
                <OrderDetails objStorePos={this.state.objStorePos} objUserInfo={this.objUserInfo}
                    objStoreInfo={this.objStoreInfo} objCart={this.objCart}
                    arrTicketsData={this.arrTicketsData} arrTicketsStatus={this.arrTicketsStatus}
                    HideOrderDetails={this.HideOrderDetails} />
            </Drawer>
        );
        // 商品类别下拉菜单
        const uiItemTypeMenu = (
            <Menu>
                {this.arrItemTypeData.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchItemType.bind(this, index)} >
                            {item.type_name}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiTypeName = (
            <div className="store_item_type_title">
                {this.arrItemTypeData[this.state.iCurrentItemType].type_name}
            </div>
        );
        if (this.state.iCurrentItemType === 0) {
            uiTypeName = (
                <div className="store_item_type_title">
                    全 部
                </div>
            );
        }
        const uiItemTypeDropdown = (
            <Dropdown overlay={uiItemTypeMenu} trigger={['click']}>
                <div className="store_item_type_dropdown">
                    {uiTypeName} <Icon type="down" />
                </div>
            </Dropdown>
        );
        // 商品列表区域
        let uiItemList = (
            <div className="store_item_list_area">
                <Empty className="store_item_empty" description="未找到对应的任何商品/服务" />
                {uiBottomArea}
            </div>
        );
        if (this.state.bItemListLoading) {
            uiItemList = (
                <div className="store_item_list_area">
                    <div className="store_item_list_loading">
                        <Spin className="ads_spin" size="large" />
                    </div>
                    {uiBottomArea}
                </div>
            );
        } else {
            if (this.arrItemsData.length > 0 && this.arrItemTypeData.length > 0) {
                // let uiTypeName = (
                //     <div className="store_item_type_title">
                //         {this.arrItemTypeData[this.state.iCurrentItemType].type_name}
                //     </div>
                // );
                // if (this.state.iCurrentItemType === 0) {
                //     uiTypeName = (<div />);
                // }
                uiItemList = (
                    <div className="store_item_list_area">
                        {uiItemTypeDropdown}
                        <StoreItemList iStoreID={this.iStoreID} arrItemsData={this.arrItemsData}
                            iItemTotalNum={this.objCart.iItemTotalNum} fItemTotalPrice={this.objCart.fItemTotalPrice} arrBudgetItems={this.objCart.arrBudgetItems}
                            RefreshBudget={this.RefreshBudget} />
                        {uiBottomArea}
                    </div>
                );
            }
        }
        // 优惠券列表
        let uiTicketList = (
            <div className="store_ticket_list_area bgc_brown_super_light">
                <TicketList objStoreInfo={{ id: this.iStoreID, logo: this.objStoreInfo.logo, store_name: this.objStoreInfo.store_name }}
                    arrTicketsData={this.arrTicketsData} arrTicketsStatus={this.arrTicketsStatus}
                    iCurrentPage={2} objUserInfo={this.objUserInfo} arrTicketUser={-1} />
                {uiBottomArea}
            </div>
        );
        // 营业时间列表
        const uiOpenTimeList = (
            <div>
                {this.arrOpenTime.map((item, index) => {
                    let strStyle = "store_details_time_list";
                    if (index === this.iCurrentDate)
                        strStyle += " store_details_time_list_bold";
                    return (
                        <div className={strStyle} key={index} > {item} </div>
                    );
                })}
            </div>
        );
        // 商家详细信息
        const uiStoreDetails = (
            <div className="store_details_area">
                <div className="store_description bgc_brown_super_light">
                    {this.objStoreInfo.description}
                </div>
                <div className="store_details_contact" onClick={this.StoreInfoAreaClicked.bind(this, 0)}>
                    <div className="sidebar_details_icon phone_icon" />{this.objStoreInfo.phone}
                </div>
                <div className="store_details_address" onClick={this.StoreInfoAreaClicked.bind(this, 1)}>
                    <div className="sidebar_details_icon location_icon_3" />
                    <div className="store_details_address_text">{this.state.strFormattedAddress}</div>
                </div>
                <div className="store_details_time_area">
                    <div className="sidebar_details_icon campass_icon" />
                    <div className="store_details_time_title"> 营业时间： </div>
                    <Tooltip title={uiOpenTimeList} trigger="click"
                        onClick={this.StoreInfoAreaClicked.bind(this, 2)}>
                        <div className="store_details_time_show">
                            {this.arrOpenTime[this.iCurrentDate] + "　"}</div>
                    </Tooltip>
                </div>

                <div className="store_details_map">
                    <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={this.state.objStorePos}
                        center={this.state.objStorePos}>
                        <Marker position={this.state.objStorePos} />
                    </Map>
                </div>
                {uiBottomArea}
            </div>
        );
        // 商家详情页主要功能切换标签按钮
        let uiMainTabs;

        if (this.state.bLoading) {
            uiMainTabs = (
                <div className="store_main_tabs">
                    <Spin className="store_main_tabs_loading" size="large" style={{ paddingTop: '21.5vw' }} />
                </div>
            );
        } else {
            uiMainTabs = (
                <Tabs className="store_main_tabs" onChange={this.TabChanged}
                    defaultActiveKey={this.state.iCurrentTab.toString()} size="large">
                    <TabPane tab={uiItemTab} key="0">
                        {uiItemList}
                    </TabPane>
                    <TabPane tab={uiTicketTab} key="1">
                        {uiTicketList}
                    </TabPane>
                    <TabPane tab={uiDetailsTab} key="2">
                        {uiStoreDetails}
                    </TabPane>
                </Tabs>
            );
        }
        // 用户操作区域：收藏，喜欢，分享等
        let uiUserActives = <div />;
        if (!this.state.bLoading) {
            let strStarBtnStyle = "store_star_btn unstar_icon";
            if (this.bStarred) {
                strStarBtnStyle = "store_star_btn starred_icon";
            }
            uiUserActives = (
                <div className={strStarBtnStyle} onClick={this.StarBtnClicked} />
                // <div className="store_actives_area">
                //     <div className="store_actives_btn like_icon" />
                //     <div className="store_actives_btn unstar_icon" />
                //     <div className="store_actives_btn share_icon" />
                // </div>
            );
        }
        // 商家商标
        let uiLogo = (<div className="store_logo" />);
        if (this.objStoreInfo.logo !== "" &&
            this.objStoreInfo.logo !== undefined &&
            this.objStoreInfo.logo !== null) {
            uiLogo = <img className="store_logo" src={this.objStoreInfo.logo} alt='' />
        }
        // 顶部栏
        let uiNavBar;
        if (this.iStoreType === 1) {
            let strSlogan = "Mouthing电商服务";
            if (this.objStoreInfo.slogan != null && this.objStoreInfo.slogan !== "")
                strSlogan = this.objStoreInfo.slogan;
            uiNavBar = (
                <div>
                    <div className="mouthing_title_logo_area mouthing_logo" onClick={this.MouthingLogoClicked} />
                    <div className="mouthing_title">{strSlogan}</div>
                    <div className="indie_store_navbar user_icon" onClick={this.UserIconClicked} />
                </div>
            );
        } else {
            uiNavBar = (
                <div>
                    <Navbar iCurrentPage={2}
                        iMainCategory={this.objStoreInfo.main_category}
                        iSubCategory={this.objStoreInfo.sub_category}
                        iStoreID={this.objStoreInfo.id}
                    />
                    {/* <div className="top_space" /> */}
                </div>
            );
        }

        /******** Stripe Payment Relevants ********/
        const CheckoutForm = ({ objCart }) => {

            const stripe = useStripe();
            const elements = useElements();

            // Handle the form submit
            const handleSubmit = async (event) => {

                event.preventDefault();

                console.log("Stripe >>> Card Form Submit");

                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement)
                });
                if (!error) {
                    console.log("Stripe >>> Payment Method : ");
                    console.log(paymentMethod);
                    const { id } = paymentMethod;
                    console.log("Stripe >>> paymentMethod pm_id = ", id);

                    try {
                        this.CancelPayment();
                        const { data } = await axios.post("/create-payment-intent", { pm_id: id, amount: objCart.fItemTotalPrice * 100 });
                        console.log("Stripe >>> data from response = ", data);
                        message.success("Congratulations, you payment is succeed !");

                    } catch (error) {
                        console.log("Stripe Error >>> ", error);
                    }

                } else {
                    console.log("Stripe >>> handleSubmit Error : ");
                    console.log(error);
                    message.warning(error.message);
                }
            }

            return (
                <form onSubmit={handleSubmit}>
                    <CardSection />
                    <button>{"Pay $ " + objCart.fItemTotalPrice.toString()}</button>
                </form>
            );
        }

        switch (this.iStoreType) {
            // 电商零售商家页面
            case 1: {
                if (this.objStoreInfo.id === 81) {
                    // console.log("store id : ", this.objStoreInfo.id);
                    return <StoreRetail2 objStoreInfo={this.objStoreInfo} arrItemTypeData={this.arrItemTypeData} arrItemsData={this.arrItemsData}
                        objUserInfo={this.objUserInfo} iCurrentTab={this.state.iCurrentTab} />;
                } else {
                    return <StoreRetail objStoreInfo={this.objStoreInfo} arrItemTypeData={this.arrItemTypeData} arrItemsData={this.arrItemsData}
                        objUserInfo={this.objUserInfo} iCurrentTab={this.state.iCurrentTab} />;
                }
            }
            // 团购商家页面
            case 2: {
                return <StoreCutPrice iStoreID={this.iStoreID} />;
            }
            // 自提商家页面
            case 3: {
                return <StorePickup objStoreInfo={this.objStoreInfo} arrItemTypeData={this.arrItemTypeData} arrItemsData={this.arrItemsData}
                    objUserInfo={this.objUserInfo} iCurrentTab={this.state.iCurrentTab} />;
            }
            default: {
                return (
                    <div className="store_page">
                        {uiNavBar}
                        <div className="store_page_container"
                            ref={node => { this.container = node; }}>
                            {/* 商家自己的广告轮播区域 */}
                            {uiAdsTitle}
                            {/* 用户操作区域 */}
                            {uiUserActives}
                            {/* 商家Logo */}
                            <div className="store_logo_area">
                                {uiLogo}
                                {/* <div className="store_lineup_area" onClick={this.LineUpClicked.bind(this)}>
                        <div className="store_lineup_btn">
                            <div className="store_lineup_icon lineup_icon" />
                        </div>
                        <div className="store_lineup_text">排队</div>
                    </div> */}
                            </div>
                            {/* 商家信息区域 */}
                            {uiStoreMainInfo}
                            {/* 标签页 */}
                            {uiMainTabs}
                            {/* 预算详情 */}
                            {uiBudgetDetails}
                            {/* 订单详情 */}
                            {uiOrderDetails}
                            {/* 支付弹窗 */}
                            <Modal title="确认支付" visible={this.state.bPaymentDlg} footer={null}
                                onOk={this.ConfirmPayment} onCancel={this.CancelPayment} >
                                <div className="payment_form">
                                    {/* <PaymentForm total_price={this.objCart.fItemTotalPrice} /> */}
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm objCart={this.objCart} />
                                    </Elements>
                                </div>
                            </Modal>
                        </div>
                    </div>
                );
            }
        }

    }
}
// export default Store;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'cn', region: 'CN'
})(Store);
