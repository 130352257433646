import React, { Component } from 'react'
import axios from 'axios';
import Navbar from './UI_Module/Navbar.js'
import Recommends from './UI_Module/Recommends.js'
import SubCategories from './UI_Module/SubCategories.js'
import StoreList from './UI_Module/StoreList.js'
import '../../styles/ClientSide/category_page.css'
import '../../styles/icon_image.css'
import message from 'antd/es/message';
// import { message } from 'antd';
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { CheckUserLocationFromCookie } from "../../functions/PositionProcess"
import { GetCityIDFromName } from "../../functions/PositionProcess"
import { StoreOrderBasedOnDistance } from "../../functions/PositionProcess"

class Category extends Component {
    constructor(props) {
        super()
        this.state = {
            bLoading: [true, true, true],  // 分类、推荐、商家列表
        };
        let strParams = window.location.search;  // 筛选出?后面的部分
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.iMainCategoryID = parseInt(strParams.split('&')[0].split('=')[1], 10);
        this.iSubCategoryID = parseInt(strParams.split('&')[1].split('=')[1], 10);
        this.iCurrentCategoryID = 0;
        this.arrSubCategories = [];
        if (this.iSubCategoryID <= 0)
            this.iCurrentCategoryID = this.iMainCategoryID;
        else
            this.iCurrentCategoryID = this.iSubCategoryID;
        this.arrCategoryList = [];
        this.arrRecommendStores = [];
        this.arrStoreList = [];
        this.strNavbarPlaceHolder = "搜索商家或服务……";
        this.initParms = [];
        this.bSwitching = false;
        this.arrPosition = [];
        this.strCityName = "";
        this.iLocationID = 0;
    }
    // 获取目标分类信息 
    GetCategoriesInfo = () => {
        let arrMainCategory = [];
        let arrSubCategory = [];
        axios('/GetTargetCategory', {
            params: { id: this.iMainCategoryID }
        }).then(({ data }) => {
            if (data.length <= 0) {
                message.warning("分类不存在！");
                this.arrCategoryList[0] = undefined;
                let bLoading = this.state.bLoading;
                bLoading[0] = true;
                this.setState({
                    ...this.state,
                    bLoading: bLoading
                });
            } else {
                if (data[0].level !== 1) {
                    message.warning("分类编号有误！");
                    this.arrCategoryList[0] = undefined;
                    let bLoading = this.state.bLoading;
                    bLoading[0] = true;
                    this.setState({
                        ...this.state,
                        bLoading: bLoading
                    });
                } else {
                    arrMainCategory = data;
                    this.strNavbarPlaceHolder = "搜索 '" + data[0].name + "' 中的商家或服务……";
                    arrMainCategory.name = "全部";
                    axios('/GetChildrenCategories', {
                        params: { parent_id: this.iMainCategoryID }
                    }).then(({ data }) => {
                        arrSubCategory = data;
                        arrMainCategory = arrMainCategory.concat(arrSubCategory);
                        this.arrCategoryList = arrMainCategory;
                        let bLoading = this.state.bLoading;
                        bLoading[0] = false;
                        this.setState({
                            ...this.state,
                            bLoading: bLoading
                        });
                    }).catch(function (error) { console.log(error); });
                }
            }
        }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取推荐区域数据
    GetRecommendList = () => {
        axios('/GetAdsInfo', { params: { type: [2, 3, 4, 5] } })
            .then(({ data }) => {
                let arrStoreID = [];
                for (let i = 0; i < data.length; i++) {
                    arrStoreID.push(data[i].store_id);
                }
                axios('/GetStoreThumb', {
                    params: { id: arrStoreID }
                }).then(
                    ({ data }) => {
                        this.arrRecommendStores = data;
                        let bLoading = this.state.bLoading;
                        bLoading[1] = false;
                        this.setState({
                            ...this.state,
                            bLoading: bLoading
                        });
                    }
                ).catch(function (error) { console.log(error); });
            });
    }
    // 从数据库获取商家列表信息（通过ParentID）
    GetStoreList = (iMainCategoryID) => {
        this.bSwitching = true;
        let bLoading = this.state.bLoading;
        bLoading[2] = true;
        this.setState({
            ...this.state,
            bLoading: bLoading,
        });
        if (iMainCategoryID !== -1)
            this.iMainCategoryID = iMainCategoryID;
        // 仅限大温地区（临时）
        // axios('/GetChildrenLocations', { params: { id: 3 } })
        //     .then(({ data }) => {
        //         let arrLocations = []
        //         for (let i = 0; i < data.length; i++)
        //             arrLocations.push(data[i].id);
        axios('/GetStores', {
            params: { location: [this.iLocationID], main_category: [this.iMainCategoryID] }
        }).then(
            ({ data }) => {
                this.bSwitching = false;
                this.arrStoreList = StoreOrderBasedOnDistance(this.arrPosition, data);
                let bLoading = this.state.bLoading;
                bLoading[2] = false;
                this.setState({
                    ...this.state,
                    bLoading: bLoading,
                });
            }
        ).catch(function (error) { console.log(error); });
        // }).catch(function (error) { console.log(error); });
    }
    // 刷新商家列表（通过ID）
    RefreshStoreList = (iCategoryID) => {
        this.bSwitching = true;
        let bLoading = this.state.bLoading;
        bLoading[2] = true;
        this.setState({
            ...this.state,
            bLoading: bLoading,
        });
        // 仅限大温地区（临时）
        // axios('/GetChildrenLocations', { params: { id: 3 } })
        //     .then(({ data }) => {
        //         let arrLocations = []
        //         for (let i = 0; i < data.length; i++)
        //             arrLocations.push(data[i].id);
        axios('/GetStores', {
            params: { location: [this.iLocationID], sub_category: iCategoryID }
        }).then(
            ({ data }) => {
                this.bSwitching = false;
                this.arrStoreList = StoreOrderBasedOnDistance(this.arrPosition, data);
                let bLoading = this.state.bLoading;
                bLoading[2] = false;
                this.setState({
                    ...this.state,
                    bLoading: bLoading,
                });
            }
        ).catch(function (error) { console.log(error); });
        // }).catch(function (error) { console.log(error); });
    }
    // 获取用户所在地区ID后
    AfterGetLocationID = (iLocationID) => {
        this.iLocationID = iLocationID;
        this.GetCategoriesInfo();
        this.GetRecommendList();
        // 如果没有指定子分类，则说明来自主分类按钮
        if (this.iSubCategoryID === -1) {
            this.GetStoreList(-1);
        } else {
            this.initParms = [2, this.iMainCategoryID, this.iSubCategoryID];
            this.RefreshStoreList(this.iSubCategoryID);
        }
    }
    // 获取用户定位信息之后
    AfterGetLocation = (city, address, position) => {
        this.arrPosition = position;
        this.strCityName = city;
        GetCityIDFromName(this.AfterGetLocationID, city);
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问分类页" }, null);
        // 检查Cookie中的用户位置信息
        CheckUserLocationFromCookie(false, this.AfterGetLocation);
    }

    render() {

        let uiSubCategories = (<div />);
        if (!this.state.bLoading[0]) {
            uiSubCategories = (
                <SubCategories type={1} arrCategoryList={this.arrCategoryList} initParms={this.initParms}
                    GetStoreList={this.GetStoreList} RefreshStoreList={this.RefreshStoreList}
                    bSwitching={this.bSwitching} />
            );
        }
        // Mouthing推荐区域
        let uiRecommends = (<div />);
        if (!this.state.bLoading[0] && !this.state.bLoading[1] &&
            this.arrCategoryList.length > 0 && this.arrCategoryList[0] !== undefined) {
            uiRecommends = <Recommends bShowFilter={false} iCurrentPage={1}
                arrRecommendStores={this.arrRecommendStores}
                arrMainCategory={[this.arrCategoryList[0]]} />;
        }
        // 商家列表区域
        let uiStoreList = (<div />);
        if (!this.state.bLoading[0] && !this.state.bLoading[2] && this.arrCategoryList[0] !== undefined) {
            uiStoreList = <StoreList iCurrentPage={1} strTitle={"附近商家"}
                bShowFilter={true} /*iFromPage={1}*/
                arrMainCategory={[this.arrCategoryList[0]]}
                arrSubCategory={this.arrCategoryList}
                arrStoreList={this.arrStoreList} />;
        }

        return (
            <div className="category_page">
                {/* 顶部标题栏 */}
                <Navbar strNavbarPlaceHolder={this.strNavbarPlaceHolder} iCurrentPage={1} />
                <div className="top_space" />
                {/* Mouthing优惠 */}
                {uiRecommends}
                {/* 子分类轮播区域 */}
                {uiSubCategories}
                {/* 附近商家 */}
                {uiStoreList}
            </div>
        )
    }
}
export default Category
