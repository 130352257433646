import React, { Component } from "react";

import Spin from 'antd/es/spin';

import '../../../styles/ClientSide/UI_Module/loading_page.css'

class LoadingPage extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    componentDidMount() { }

    render() {
        return (
            <div className="loading_age">
                <Spin size="large" />
            </div>
        );
    }
}
export default LoadingPage;
