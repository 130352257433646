import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import Clipboard from 'react-clipboard.js';

import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Collapse from 'antd/es/collapse';
import Modal from 'antd/es/modal';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Steps from 'antd/es/steps';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import "../../styles/ClientSide/login_register_page.css";
import "../../styles/ClientSide/webpage.css";
import "../../styles/icon_image.css";
import "../../styles/ClientSide/zhihe_user_page.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js";
import { deviceType } from "react-device-detect";

const g_strMessageKey = 'updating';
const { Panel } = Collapse;
const { TextArea } = Input;
const { Step } = Steps;
const { Search } = Input;
const mapStyles = { width: '100%', height: '100%' };

class ZhiHeWebsite extends Component {
    constructor(props) {
        super();

        console.log("host name = ", window.location.hostname, window.location.host);
        console.log("domain name = ", document.domain);
        console.log("url = ", window.location.href);

        let strDomain = window.location.href;
        if (strDomain.indexOf('mouthing.life') >= 0/*|| strDomain.indexOf('mouthing.info') >= 0*/) {
            console.log("forward to zhihe express");
            window.location.href = 'https://www.mouthing.info/zhihe';
            return;
        }

        let strParams = window.location.search;  // 筛选出?后面的部分
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.strCNum = "";
        if (strParams.indexOf('cnum') >= 0)
            this.strCNum = strParams.split('cnum=')[1];
        this.strUserID = "";
        if (strParams.indexOf('uid') >= 0)
            this.strUserID = strParams.split('uid=')[1];
        this.iTab = 0;
        if (strParams.indexOf('tab') >= 0)
            this.iTab = parseInt(strParams.split('tab=')[1], 10);
        this.strUserCode = "";
        if (strParams.indexOf('user') >= 0)
            this.strUserCode = strParams.split('user=')[1];
        let strDevice = deviceType;
        if (strDevice === "mobile") {
            this.iTab = 0;
            if (strParams.indexOf('menu') >= 0)
                this.iTab = parseInt(strParams.split('menu=')[1], 10);
            this.bMStation = true;
        } else
            this.bMStation = false;

        this.state = {
            bLoading: true,
            bLogin: false,
            bSubmitOrderDlg: false,
            iCurrentMenu: this.iTab,
            objPickupLocation: { lat: null, lng: null },
            iCurrentOrderStatus: 0,
            bDeliveryStatusFilterChange: false,
            iTransitType: 0,
            iItemType: 0
        };
        this.objUserInfo = {};
        this.arrAddOrders = [];
        this.arrMenuButton = [
            { name: "首页", icon: 'home' },
            { name: "我要寄货", icon: 'code-sandbox' },
            { name: "订单记录", icon: 'profile' },
            // { name: "运费预估", icon: 'dollar' }
        ];
        this.arrOrderListOge = [];
        this.arrOrderList = [];
        this.arrOrderShowList = [];
        this.arrTransitTrackingNo = [];
        this.arrPackageDetails = [];
        this.arrOrderStatus = ["待签收", "已签收", "已合箱", "已发货", "已投递"];  // 物流用户订单状态用
        this.arrOrderStatusMenu = ["全部", "待签收", "已签收", "已合箱", "已发货", "已投递"];
        this.objCurrentOrder = { comments: "" };
        this.arrTransitTypeFilter = ["签收后立即转运", "签收后寄存在仓库（最多90天），等待其它货物"];
        this.arrOrderDeliveryStatus = [];
        this.arrTransitDateData = [];  // 船期
        this.arrTransitPrice = []; // 单价
        this.arrTransitTypeFilter = ['海运', '空运', '小包裹'];
        this.arrItemTypeFilter = ['普货', '食品', '敏感'];
        this.fTotalFee = 0.00;
        this.objExpressOrder = null;
        this.arrOrderStatus = ["待签收", "已签收", "合箱中", "已合箱发货"];  // 手机端至禾用户查询专用
    }
    // 切换菜单按钮
    SwitchMenuButton = (index) => {
        if (index !== 0 && !this.state.bLogin) {
            this.JumpToLogin();
            return;
        }
        this.iTab = index;
        // 首页
        if (index === 0) {
            this.setState({
                ...this.state,
                iCurrentMenu: index,
                bLoading: false,
                iCurrentOrderStatus: 0
            });
        }
        // 我要寄货
        if (index === 1) {
            this.setState({
                ...this.state,
                iCurrentMenu: index,
                bLoading: false,
                iCurrentOrderStatus: 0
            });
        }
        // 订单记录
        if (index === 2) {
            this.setState({
                ...this.state,
                iCurrentMenu: index,
                bLoading: true,
                iCurrentOrderStatus: 0
            });
            this.arrPackageDetails = [];
            if (this.objUserInfo.code !== "") {
                if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                    // 分配storage Code
                    axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id })
                        .then(({ data }) => {
                            this.objUserInfo.storage_code = data[0].storage_code;
                        }).catch(function (error) { console.log(error); });
                } else {
                    axios('/GetUserExpressEnter', {
                        params: {
                            name: this.objUserInfo.name,
                            user_code: this.objUserInfo.code,
                            storage_code: this.objUserInfo.storage_code
                        }
                    }).then(({ data }) => {
                        this.arrOrderList = [];
                        if (data.length > 0) {
                            this.arrOrderListOge = data;
                            this.arrOrderList = data;
                            if (this.state.iCurrentOrderStatus > 0)
                                this.OrganizeOrderList();
                            if (this.arrOrderList.length > 0) {
                                this.GetOrderPackageList();
                                this.GetPackageTransitInfo();
                            } else {
                                this.arrOrderShowList = [];
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }
                        } else {
                            this.arrOrderShowList = [];
                            this.setState({
                                ...this.state,
                                bLoading: false
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                }
            }
        }
    }
    // 获取已合箱包裹的物流信息
    GetPackageTransitInfo = () => {
        for (let i = 0; i < this.arrOrderShowList.length; i++) {
            if (this.arrOrderShowList[i].length > 0) {
                axios('/GetPackageDetails', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                    .then(({ data }) => {
                        if (data.length > 0)
                            this.arrPackageDetails.push(data[0]);
                        axios('/GetPackageAutoOrder', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                            .then(({ data }) => {
                                if (data !== "")
                                    this.arrTransitTrackingNo.push(data);
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }
        }
    }
    // 整理出合箱分级数据
    GetOrderPackageList = () => {
        this.arrOrderShowList = [];
        let strPackageNo = "";
        let arrOrderPackage = [];
        for (let i = 0; i < this.arrOrderList.length; i++) {
            if (this.arrOrderList[i].package_no !== null && this.arrOrderList[i].package_no !== '') {
                if (strPackageNo === "") {
                    strPackageNo = this.arrOrderList[i].package_no;
                    arrOrderPackage.push(this.arrOrderList[i]);
                    if (i === this.arrOrderList.length - 1) {
                        this.arrOrderShowList.push(arrOrderPackage);
                    }
                } else {
                    if (strPackageNo === this.arrOrderList[i].package_no) {
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    } else {
                        this.arrOrderShowList.push(arrOrderPackage);
                        arrOrderPackage = [];
                        strPackageNo = this.arrOrderList[i].package_no;
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    }
                }
            } else {
                if (strPackageNo !== "") {
                    strPackageNo = "";
                    this.arrOrderShowList.push(arrOrderPackage);
                    arrOrderPackage = [];
                }
                this.arrOrderShowList.push(this.arrOrderList[i]);
            }
        }
    }
    // 生成订单子控件
    GenerateChildPanelUI = (item, index) => {
        let strDateTime = "无记录";
        if (item.order_date !== null) {
            if (item.order_date.indexOf('T') >= 0) {
                let dateNow = new Date();
                let dateOrder = new Date(item.order_date);
                let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                let day = parseInt(drr / (24 * 60 * 60 * 1000));
                if (day > 0)
                    strDateTime = day + "天前";
                else {
                    let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                    if (hours > 0) {
                        strDateTime = hours + "小时前";
                    } else {
                        let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                        if (minutes > 0) {
                            strDateTime = minutes + "分钟前";
                        } else {
                            strDateTime = "刚刚";
                        }
                    }
                }
            }
        }
        // let strPriceWeight = "还未称重";
        // if (item.express_info !== null && item.express_info !== "") {
        //     strPriceWeight = JSON.parse(item.express_info);
        //     if (parseFloat(strPriceWeight.price_weight) <= 0.0)
        //         strPriceWeight = "还未称重";
        //     else
        //         strPriceWeight = parseFloat(strPriceWeight.price_weight) + " kg";
        // }
        let uiPackageNo = null;
        let uiStatus = null;
        if (item.package_no !== null && item.package_no !== "")
            uiPackageNo = (
                <div className="web_center_order_details_text">
                    {"合箱编码：" + item.package_no}</div>
            );
        else
            uiStatus = (<div className="web_center_order_details_title_right">
                {" " + this.arrOrderStatus[parseInt(item.status, 10)]}
            </div>);
        let uiTitle = (
            <div className="web_center_order_details_title">
                <div className="web_center_order_details_title_left">
                    <Icon type="file" theme="filled" />
                    {" " + item.express_order}
                </div>
                {uiStatus}
            </div>
        );
        uiPackageNo = null;
        let uiComments = null;
        if (item.comments)
            if (item.comments !== null && item.comments !== "")
                if (item.comments.trim() !== "")
                    uiComments = <div className="web_center_order_details_text">
                        {"备注信息：" + item.comments}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>

        let iDeliveryStatus = 0;
        if (item.delivery_status) {
            if (item.delivery_status !== null && item.delivery_status !== "") {
                iDeliveryStatus = parseInt(item.delivery_status, 10);
            }
        }
        return (
            <Panel header={uiTitle} key={(index + 1).toString()} >
                <div className="web_center_order_details_area">
                    <div className="web_center_order_details_text">
                        {"提交时间：" + strDateTime}
                    </div>
                    <div className="web_center_order_details_text">
                        {"发货选项："}
                        {this.arrTransitTypeFilter[iDeliveryStatus]}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>
                    {uiComments}
                    {uiPackageNo}
                </div>
            </Panel>
        );
    }
    // 生成订单母控件
    GenerateParentPanelUI = (orders, index) => {
        let uiPanel = null;

        // 已合箱的订单
        if (orders.length) {
            let uiTitle = (
                <div className="web_center_order_details_title">
                    <div className="web_center_order_details_title_left">
                        <Icon type="code-sandbox" />
                        {" 合箱编码 " + orders[0].package_no}
                    </div>
                    <div className="web_center_order_details_title_right">
                        {" " + this.arrOrderStatus[parseInt(orders[0].status, 10)]}
                    </div>
                </div>
            );
            let strPriceWeight = "货物称重：还未称重";
            let strTotalPrice = "总计费用：还未称重";
            let strAutoOrderNo = "";
            if (this.arrPackageDetails.length > 0) {
                for (let i = 0; i < this.arrPackageDetails.length; i++) {
                    if (this.arrPackageDetails[i].package_no === orders[0].package_no) {
                        strPriceWeight = "货物称重：" + this.arrPackageDetails[i].price_weight + " kg";
                        strTotalPrice = "总计费用：$ " + this.arrPackageDetails[i].total_price;
                        strAutoOrderNo = this.arrPackageDetails[i].auto_order_no;
                        break;
                    }
                }
            }
            let iTargetOrder = 0;
            if (this.arrTransitTrackingNo.length > 0) {
                for (let i = 0; i < this.arrTransitTrackingNo.length; i++) {
                    if (this.arrTransitTrackingNo[i] === strAutoOrderNo) {
                        iTargetOrder = i;
                        break;
                    }
                }
            }
            // if (this.arrPackageDetails[index]) {
            //     strPriceWeight = "货物称重：" + this.arrPackageDetails[index].price_weight + " kg";
            // }
            // if (this.arrPackageDetails[index]) {
            //     strTotalPrice = "总计费用：$ " + this.arrPackageDetails[index].total_price;
            // }
            uiPanel = (
                <Panel header={uiTitle} key={(index + 1).toString()}>
                    {/* <div className="web_center_transit_tracking_no">
                        {"物流追踪号：" + this.arrTransitTrackingNo[index]}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, index)}>查看物流</div>
                    </div> */}
                    <div className="web_center_transit_tracking_no">
                        {strPriceWeight}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, iTargetOrder)}>查看物流</div>
                    </div>
                    <div className="web_center_transit_tracking_no">
                        {strTotalPrice}
                    </div>
                    <Collapse >
                        {orders.map((order, indexChild) => {
                            let uiChildPanel = this.GenerateChildPanelUI(order, indexChild);
                            return (uiChildPanel);
                        })}
                    </Collapse>
                </Panel>
            );
        }
        // 未合箱的订单
        else {
            uiPanel = this.GenerateChildPanelUI(orders, index);
        }
        return uiPanel;
    }
    // 跳转到物流追踪页面
    ViewTransitDetails = (index) => {
        window.location.href = '/tracking/no=' + this.arrTransitTrackingNo[index];
    }
    // 跳转用户中心
    JumpToUserCenter = () => {
        // 已登录
        if (this.state.bLogin) {

        }
        // 未登录
        else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            window.location.href = '/webapp/login?store=zhihe';
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    if (this.iTab !== 0 && !this.bMStation)
                        this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                        // 分配storage Code
                        axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id })
                            .then(({ data }) => {
                                this.objUserInfo.storage_code = data[0].storage_code;
                                console.log("user info = ", this.objUserInfo);
                                this.SwitchMenuButton(this.iTab);
                                this.setState({
                                    ...this.state,
                                    bLogin: true
                                });
                            }).catch(function (error) { console.log(error); });
                    } else {
                        this.SwitchMenuButton(this.iTab);
                        this.setState({
                            ...this.state,
                            bLogin: true
                        });
                    }
                }
            }).catch(function (error) { console.log(error); });
    }
    // 跳回首页
    HomePage = () => {
        window.location.href = '/zhihe';
    }
    // 拷贝信息到粘贴板
    CopyAddressInfo = () => {
        // console.log(strInfo);
        // if (navigator.clipboard) {
        message.info("复制成功！");
        //     navigator.clipboard.writeText(strInfo);
        // } else {
        //     message.info("正在为您跳转到安全链接！请重新尝试复制");
        //     window.location.href = "https://www.mouthing.info";
        // }
    }
    // 添加订单
    AddNewOrder = (iType, objOrder) => {
        if (iType === 0) {
            let strAddOrder = this.refs.order_input.state.value;
            if (!strAddOrder || strAddOrder.trim() === "") {
                message.warning("请输入快递单号！");
                return;
            }
            this.arrAddOrders = [];
            this.arrOrderDeliveryStatus = [];
            if (strAddOrder.indexOf('\n') >= 0) {
                let arrAddOrders = strAddOrder.split('\n');
                for (let i = 0; i < arrAddOrders.length; i++) {
                    if (arrAddOrders[i] !== "" && arrAddOrders[i].trim() !== "") {
                        this.arrAddOrders.push(arrAddOrders[i].trim());
                        this.arrOrderDeliveryStatus.push(0);
                    }
                }
            } else {
                this.arrAddOrders.push(strAddOrder);
                this.arrOrderDeliveryStatus.push(0);
            }
        } else {
            this.arrAddOrders = [];
            this.arrAddOrders.push(objOrder.express_order);
            this.arrOrderDeliveryStatus = [];
            this.arrOrderDeliveryStatus.push(objOrder.delivery_status);
            this.objCurrentOrder = objOrder;
        }
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            bSubmitOrderDlg: true
        });
    }
    // 确认/取消提交订单（物流单号专用）
    SubmitOrder = (bConfirm) => {
        this.objCurrentOrder = { comments: "" };
        if (!bConfirm) {
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            return;
        }
        let arrExpressOrders = [];
        for (let i = 0; i < this.arrAddOrders.length; i++) {
            let strInputKey = "express_comments_input_" + i.toString();
            let strEnterNo = this.objUserInfo.invite_code;
            if (strEnterNo === undefined || strEnterNo === null || strEnterNo === "")
                strEnterNo = "LK88";
            let objExpressOrders = {
                enter_no: strEnterNo,
                express_order: this.arrAddOrders[i],
                receiver: this.objUserInfo.name,
                storage_code: this.objUserInfo.storage_code,
                user_code: this.objUserInfo.code,
                store_id: 20000,
                comments: this.refs[strInputKey].state.value,
                status: 0,
                delivery_status: this.arrOrderDeliveryStatus[i]
            }
            if (objExpressOrders.comments === undefined || objExpressOrders.comments === null) {
                objExpressOrders.comments = "";
            }
            arrExpressOrders.push(objExpressOrders);
        }
        console.log('准备提交的预报：', arrExpressOrders);
        message.loading({ content: '正在提交……', key: g_strMessageKey });
        axios.post('/CreateExpressOrders', {
            arrExpressOrders: arrExpressOrders
        }).then(({ data }) => {
            message.success({ content: "订单编号提交成功！请在订单列表页查看", key: g_strMessageKey, duration: 2 });
            // DataStatistic
            UserActiveUpdate(4, { active: "用户端: 批量提交订单号", action: "新建" });
            if (this.refs.order_input)
                this.refs.order_input.state.value = "";
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            this.SwitchMenuButton(2);
        }).catch(function (error) { console.log(error); });

    }
    // 取消订单（自提/通用）
    CancelOrder = (type, index) => {
        if (type === 0) {
            this.setState({
                ...this.state,
                bCancelOrderDlg: true
            });
        } else {
            if (type === 1) {
                let oid = this.arrOrderList[index].oid;
                axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: -1 })
                    .then(({ data }) => {
                        message.info("订单已取消！");
                        this.ShowList(3);
                    });
            }
            this.setState({
                ...this.state,
                bCancelOrderDlg: false
            })
        }
    }
    // 获取自提点位置信息
    GetPickupLocation = (strAddress) => {
        let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        axios.get(strUrl)
            .then((response) => {
                this.setState({
                    ...this.state,
                    objPickupLocation: response.data.results[0].geometry.location
                });
            }).catch(err => {
                console.log(err) //Axios entire error message
                // console.log(err.response.data.error) //Google API error message 
            });
    }
    // 跳转回登录页
    JumpToLogin = () => {
        console.log('JumpToLogin');
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });

        window.location.href = '/webapp/login?store=zhihe';
    }
    // 追踪订单
    OrderTracking = () => {
        let strSearch = this.refs.order_search.state.value;
        if (strSearch) {
            if (strSearch === null || strSearch === "") {
                message.warning("请输入需要查询的订单号！");
            } else {
                if (strSearch.trim() === "")
                    message.warning("请输入需要查询的订单号！");
                else
                    window.location.href = '/tracking/no=' + strSearch;
            }
        } else
            message.warning("请输入需要查询的订单号！");
    }
    // 订单筛选下拉框点击
    OrderTypeFilterCLicked = (index) => {
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            iCurrentOrderStatus: index
        });
    }
    // 订单筛选整理
    OrganizeOrderList = () => {
        this.arrOrderList = []
        for (let i = 0; i < this.arrOrderListOge.length; i++) {
            if (this.arrOrderListOge[i].status === this.state.iCurrentOrderStatus - 1) {
                this.arrOrderList.push(this.arrOrderListOge[i]);
            }
        }
    }
    // 退出登录
    LogOut = () => {
        this.setState({
            ...this.state,
            bLogin: false
        });
        // DataStatistic
        UserActiveUpdate(3, { active: "退出登录" });
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        // window.location.href = '/';
    }
    // 转运状态过滤器切换
    SwitchTransitTypeFilter = (index, status) => {
        this.arrOrderDeliveryStatus[index] = status;
        this.setState({
            ...this.state,
            bDeliveryStatusFilterChange: true
        });
    }
    // 手机用户端切换主菜单
    SwitchMainMenu = (index) => {
        console.log("SwitchMainMenu : ", index);
        // 0-船期查询
        if (index === 0) {
            this.GetTransitDate();
        }
        // 1-价格预估
        if (index === 1) {
            this.GetTransitPrice();
        }
        // 2-包裹状态查询
        if (index === 2) {
            this.setState({
                ...this.state,
                iCurrentMenu: 2
            })
        }
    }
    // 获取船期信息
    // 从数据库获取当前的数据结构
    GetTransitDate() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTransitDate', { params: { store_id: 20000 } })
            .then(({ data }) => {
                console.log("Get Transit Date : ", data);
                this.arrTransitDateData = data;
                if (this.arrTransitDateData.length > 0) {
                    for (let i = 0; i < this.arrTransitDateData.length; i++) {
                        this.arrTransitDateData[i].key = i;
                        if (this.arrTransitDateData[i].transit_date !== null)
                            this.arrTransitDateData[i].transit_date = this.arrTransitDateData[i].transit_date.split('T')[0];
                        else
                            this.arrTransitDateData[i].transit_date = this.strTransitDate.toISOString().split('T')[0];
                        if (this.arrTransitDateData[i].update_date !== null)
                            this.arrTransitDateData[i].update_date = this.arrTransitDateData[i].update_date.split('T')[0];
                        else
                            this.arrTransitDateData[i].update_date = this.strTransitDate.toISOString().split('T')[0];
                        this.arrTransitDateData[i].comments = this.arrTransitDateData[i].comments;
                    }
                }
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    iCurrentMenu: 0
                })
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetTransitPrice() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTransitPrice', { params: { store_id: 20000 } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                console.log("Get Transit Price : ", data);
                this.arrTransitPrice = data;
                this.fTotalFee = 0.00;
                this.setState({
                    ...this.state,
                    iCurrentMenu: 1
                })
            }).catch(function (error) { console.log(error); });
    }
    // 运输方式切换（手机端价格预估专用）
    SwitchTransitType = (index) => {
        this.setState({
            ...this.state,
            iTransitType: index
        });
    }
    // 物品种类切换（手机端价格预估专用）
    SwitchItemType = (index) => {
        this.setState({
            ...this.state,
            iItemType: index
        });
    }
    // 预估费用计算
    CalculateFee = () => {
        let fDiscount = 100;
        let fUnitPrice = 0.00;
        for (let i = 0; i < this.arrTransitPrice.length; i++) {
            if (this.arrTransitPrice[i].transit_type === this.state.iItemType) {
                fUnitPrice = parseFloat(this.arrTransitPrice[i].price);
                fDiscount = parseFloat(this.arrTransitPrice[i].discount);
                break;
            }
        }
        let fTransitPrice = 6000;
        if (this.state.iTransitType === 2)
            fTransitPrice = 5000;
        let fPriceWeight = this.refs.weight.state.value;
        let fLength = this.refs.length.state.value;
        let fWidht = this.refs.width.state.value;
        let fHeight = this.refs.height.state.value;
        if (fPriceWeight === undefined || fPriceWeight === null || fPriceWeight === "")
            fPriceWeight = 0.00;
        if (fLength === undefined || fLength === null || fLength === "")
            fLength = 0.00;
        if (fWidht === undefined || fWidht === null || fWidht === "")
            fWidht = 0.00;
        if (fHeight === undefined || fHeight === null || fHeight === "")
            fHeight = 0.00;
        let fValue = parseFloat(fLength) * parseFloat(fWidht) * parseFloat(fHeight);
        let fValueWeight = fValue / parseFloat(fTransitPrice);
        console.log(fDiscount, fUnitPrice, fTransitPrice, fPriceWeight, fValue, fValueWeight);
        if (parseFloat(fValueWeight) >= parseFloat(fPriceWeight))
            fPriceWeight = fValueWeight.toFixed(2);
        else
            fPriceWeight = parseFloat(fPriceWeight).toFixed(2);
        this.fTotalFee = (fUnitPrice * fPriceWeight * fDiscount) / 100.00;
        this.fTotalFee = this.fTotalFee.toFixed(2);
        this.setState({
            ...this.state,
            bLoading: false
        });
    }
    // 包裹状态查询
    OnSearchClicked = (value) => {
        let strKey = value;
        if (strKey === null || strKey.trim() === "") {
            message.warning("查询内容不能为空！");
            return;
        }
        message.loading({ content: '包裹查找中……', key: g_strMessageKey });
        axios('/GetExpressOrderStatus', { params: { store_id: 20000, express_order: strKey } })
            .then(({ data }) => {
                console.log("GetExpressOrderStatus : ", data);
                if (data !== null && data.length > 0) {
                    this.objExpressOrder = data[0];
                    if (this.objExpressOrder.status === 2)
                        this.objExpressOrder.status = 3;
                    else {
                        if (this.objExpressOrder.package_no !== undefined && this.objExpressOrder.package_no !== null && this.objExpressOrder.package_no !== "") {
                            this.objExpressOrder.status = 2;
                            if (this.objExpressOrder.package_status === 1)
                                this.objExpressOrder.status = 3;
                        }
                    }
                    message.success({ content: "包裹信息加载完成！", key: g_strMessageKey, duration: 2 });
                } else {
                    this.objExpressOrder = null;
                    message.warning({ content: "未找到包裹，请检查单号！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    iCurrentMenu: 2
                })
            }).catch(function (error) { console.log(error); });
    }

    // 渲染完毕……
    componentDidMount() {
        document.title = "至禾国际物流";
        this.GetPickupLocation("7080 River Rd., Richmond");
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');

        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            if (!this.bMStation) {
                console.log("strUserCode : ", strUserCode);
                console.log("iUserLoginStatus : ", iUserLoginStatus);
                this.UserCodeCheck();
            }
        } else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            console.log(this.iTab, this.bMStation);
            if (this.iTab !== 0 && !this.bMStation)
                this.JumpToLogin();
        }
        if (this.bMStation) {
            console.log("手机端: ", this.iTab);
            this.SwitchMainMenu(this.iTab);
        }

    }

    render() {

        let strUserName = "";
        let uiFunctionArea = null;
        // 0-首页
        if (this.state.iCurrentMenu === 0) {
            uiFunctionArea = (
                <div className="web_center_home_page">
                    <div className="web_center_home_title">轻松寄全球 国货买不停</div>
                    <div className="web_center_home_subtitle">100万+ 华人用户，每年500万+ 包裹寄运全球</div>
                    <div className="web_center_home_search_area">
                        <Input className="web_center_search_input" style={{ width: '290px', height: '40px' }} ref="order_search"
                            size="large" placeholder="请输入至禾国际货运单号" />
                        <div className="web_center_search_button" onClick={this.OrderTracking}>查 询</div>
                    </div>
                </div>
            );
        }
        if (this.state.bLogin) {
            strUserName = this.objUserInfo.name;
            // 1-我要寄货
            if (this.state.iCurrentMenu === 1) {
                let strReceiver = "何顺明 ( " + this.objUserInfo.storage_code + " )";
                let strPhone = "13750335052";
                let strPostCode = "529000";
                let strAddress = "广东省江门市蓬江区周郡海滩围55号";
                let strCopyAllText = strReceiver + ",";
                strCopyAllText += strPhone + ",";
                let strInviteCode = "";
                if (this.objUserInfo.invite_code !== null && this.objUserInfo.invite_code !== "") {
                    strInviteCode = " " + this.objUserInfo.invite_code;
                }
                strCopyAllText += strAddress + strInviteCode + ",";
                strCopyAllText += strPostCode;

                let uiSubmitOrderDlg = (
                    <Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null} centered={true} width={"40%"}
                        onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                        okText="确定" cancelText="取消">
                        <div>
                            <div className="web_center_order_submit_dlg_tip">
                                提示：备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                        </div>
                            {this.arrAddOrders.map((item, index) => {

                                // 寄送状态过滤器
                                const menuDeliveryStatusFilter = (
                                    <Menu>
                                        {this.arrTransitTypeFilter.map((item, status) => {
                                            return (
                                                <Menu.Item key={index} onClick={this.SwitchTransitTypeFilter.bind(this, index, status)}>
                                                    {item}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                );

                                return (
                                    <div className="web_center_express_order_info_area">
                                        <div className="web_center_express_order_info_row">
                                            <div className="web_center_express_info_title">
                                                <Icon type="codepen" />
                                                {" 单号 : "}</div>
                                            <div className="web_center_express_info_contents">{item}</div>
                                        </div>
                                        <div className="web_center_express_order_info_row">
                                            <div className="web_center_express_info_title">
                                                <Icon type="rocket" />
                                                {" 发货 : "}</div>
                                            <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                <Button className="webpage_delivery_filter_dropdown_button">
                                                    {this.arrTransitTypeFilter[this.arrOrderDeliveryStatus[index]]}
                                                    <Icon type="caret-down" />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                        <div className="web_center_express_order_info_row" >
                                            <div className="web_center_express_info_title">
                                                <Icon type="file-text" />
                                                {" 备注 : "}</div>
                                            <div className="web_center_express_info_contents">
                                                <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="web_center_express_order_info_row">
                                <div className="web_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                <div className="web_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                            </div>
                        </div>
                    </Modal>
                );
                uiFunctionArea = (
                    <div className="web_center_transit_function_area">
                        <div className="web_center_transit_function_vertical_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="shop" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />转运中心
                                </div>
                            <div className="web_center_transit_fucntion_row">
                                {"收件人：" + strReceiver}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strReceiver)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strReceiver} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">{"手机：" + strPhone}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPhone)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strPhone} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">
                                <div style={{ marginRight: '5px' }}>
                                    {"地址：" + strAddress + strInviteCode}</div>
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strAddress + strInviteCode)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strAddress + strInviteCode} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            <div className="web_center_transit_fucntion_row">{"邮编：" + strPostCode}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPostCode)}> 复 制 </div> */}
                                <Clipboard data-clipboard-text={strPostCode} className="web_center_transit_fucntion_copy_button"
                                    style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                    复 制
                                </Clipboard>
                            </div>
                            {/* <div className="web_center_transit_function_copy_all" onClick={this.CopyAddressInfo.bind(this, strCopyAllText)}>复 制 全 部</div> */}
                            <Clipboard data-clipboard-text={strCopyAllText} className="web_center_transit_function_copy_all"
                                style={{ border: 'none', fontWeight: 'bold' }} onClick={this.CopyAddressInfo}>
                                复 制 全 部
                                </Clipboard>
                            <div className="web_center_transit_function_tips">
                                <b>小提示：</b><br />
                            务必将收件人括号内的编码填写在快递单上！避免延误签收！
                        </div>
                        </div>
                        <div className="web_center_transit_function_horizontal_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="rocket" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />提交订单
                        </div>
                            <div className="web_center_transit_fucntion_row">
                                {"物流单号输入："}
                            </div>
                            <div className="web_center_express_order_input_area">
                                <TextArea size="large" autoSize={{ minRows: 5 }} allowClear={true} ref="order_input"
                                    placeholder="可以用回车分隔，一次输入多个单号" />
                            </div>
                            <div className="web_center_express_order_submit_button" onClick={this.AddNewOrder.bind(this, 0, null)}>确认添加</div>
                            <div className="web_center_exress_order_submit_tip_area">
                                <div className="web_center_exress_order_submit_tip_row">
                                    <b>温馨提示：</b>
                                </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    1. 批量输入快递单号后点击“确认添加”
                            </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    2. 对每笔订单进行备注，填写完成后“提交”
                            </div>
                                <div className="web_center_exress_order_submit_tip_row">
                                    3. 如果仍有疑问，请咨询
                                <div className="web_center_exress_order_submit_tip_link" /*onClick={this.ShowContactUsDlg.bind(this, true)}*/>在线客服</div>
                                </div>
                            </div>
                            {uiSubmitOrderDlg}
                        </div>
                        <div className="web_center_transit_function_vertical_area">
                            <div className="web_center_transit_fucntion_title">
                                <Icon type="home" style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '25px' }} />提货地址
                            </div>
                            <div className="web_center_transit_fucntion_row">
                                {"联系人：Lekon 客服"}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strReceiver)}> 复 制 </div> */}
                            </div>
                            <div className="web_center_transit_fucntion_row">{"手机：778-952-0886"}
                                {/* <div className="web_center_transit_fucntion_copy_button" onClick={this.CopyAddressInfo.bind(this, strPhone)}> 复 制 </div> */}
                            </div>
                            <div className="web_center_transit_fucntion_row" style={{ border: 'none' }}>
                                <div style={{ marginRight: '5px' }}>
                                    {"地址：Unit 119, 7080 River Rd., Richmond, BC V6X1X5"}</div>
                            </div>
                            <div className="web_center_pickup_reminds">
                                {"提货时请提供收件人名或用户编码"}
                            </div>
                            <div className="web_pickup_location_map" >
                                <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={this.state.objPickupLocation}
                                    center={this.state.objPickupLocation} containerStyle={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Marker position={this.state.objPickupLocation} />
                                </Map>
                            </div>
                        </div>
                    </div>
                );
            }
            // 2-订单记录
            if (this.state.iCurrentMenu === 2) {
                if (!this.state.bLoading) {
                    let uiOrderList = null;
                    let uiSubmitOrderDlg = null;
                    if (this.state.bSubmitOrderDlg)
                        uiSubmitOrderDlg = (<Modal title="提交物流单号" visible={this.state.bSubmitOrderDlg} footer={null} centered={true} width={"40%"}
                            onOk={this.SubmitOrder.bind(this, true)} onCancel={this.SubmitOrder.bind(this, false)}
                            okText="确定" cancelText="取消">
                            <div>
                                <div className="web_center_order_submit_dlg_tip">
                                    提示：备注中请尽量详细填写货物内容，数量，单价等信息，以便仓库代收时核对包裹
                            </div>
                                {this.arrAddOrders.map((item, index) => {
                                    // 寄送状态过滤器
                                    const menuDeliveryStatusFilter = (
                                        <Menu>
                                            {this.arrTransitTypeFilter.map((item, status) => {
                                                return (
                                                    <Menu.Item key={index} onClick={this.SwitchTransitTypeFilter.bind(this, index, status)}>
                                                        {item}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    );
                                    return (
                                        <div className="web_center_express_order_info_area">
                                            <div className="web_center_express_order_info_row">
                                                <div className="web_center_express_info_title">
                                                    <Icon type="codepen" />
                                                    {" 单号 : "}</div>
                                                <div className="web_center_express_info_contents"> {item}</div>
                                            </div>
                                            <div className="web_center_express_order_info_row">
                                                <div className="web_center_express_info_title">
                                                    <Icon type="rocket" />
                                                    {" 发货 : "}</div>
                                                <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuDeliveryStatusFilter} >
                                                    <Button className="webpage_delivery_filter_dropdown_button">
                                                        {this.arrTransitTypeFilter[this.arrOrderDeliveryStatus[index]]}
                                                        <Icon type="caret-down" />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <div className="web_center_express_order_info_row" >
                                                <div className="web_center_express_info_title">
                                                    <Icon type="file-text" />
                                                    {" 备注 : "}</div>
                                                <div className="web_center_express_info_contents">
                                                    <Input placeholder="货物内容，数量，单价" ref={"express_comments_input_" + index} defaultValue={this.objCurrentOrder.comments} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="web_center_express_order_info_row">
                                    <div className="web_info_change_cancel_button" onClick={this.SubmitOrder.bind(this, false)}>取 消</div>
                                    <div className="web_info_change_confirm_button" onClick={this.SubmitOrder.bind(this, true)}>提 交</div>
                                </div>
                            </div>
                        </Modal>);
                    const uiOrderTypeMenu = (
                        <Menu>
                            {this.arrOrderStatusMenu.map((menu, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.OrderTypeFilterCLicked.bind(this, index)}>
                                        <div className="webpage_dropdown_menu_item">{menu}</div>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    let uiOrderTypeDropdown = (
                        <Dropdown overlay={uiOrderTypeMenu} trigger={['click']}>
                            <div className="webpage_dropdown_menu_button" onClick={e => e.preventDefault()}>
                                {this.arrOrderStatusMenu[this.state.iCurrentOrderStatus]}<Icon type="caret-down" style={{ marginLeft: '12.5px' }} />
                            </div>
                        </Dropdown>
                    );
                    if (this.arrOrderShowList.length <= 0) {
                        uiFunctionArea = (
                            <div className="web_center_transit_function_area">
                                <div className="web_center_order_list_area">
                                    <div className="web_center_transit_fucntion_title" style={{ height: '60px', marginBottom: '15px', padding: '10px' }}>
                                        <Icon type={"profile"} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />我的订单
                                        {uiOrderTypeDropdown}
                                    </div>
                                    <Empty description={"暂无该类型订单的记录，快去提交订单吧！"} className="web_center_empty" />
                                </div>
                            </div>
                        );
                    } else {
                        let arrActiveKey = [];
                        uiOrderList = (
                            <div>
                                <Collapse defaultActiveKey={arrActiveKey} style={{ marginLeft: '20px', marginRight: '20px' }}>
                                    {this.arrOrderShowList.map((item, index) => {
                                        let uiPanel = null;
                                        uiPanel = this.GenerateParentPanelUI(item, index);
                                        return (uiPanel);
                                    })}
                                </Collapse>
                                { uiSubmitOrderDlg}
                            </div>

                        );

                        uiFunctionArea = (
                            <div className="web_center_transit_function_area">
                                <div className="web_center_order_list_area">
                                    <div className="web_center_transit_fucntion_title" style={{ height: '60px', marginBottom: '15px', padding: '10px' }}>
                                        <Icon type={"profile"} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />我的订单
                                        {uiOrderTypeDropdown}
                                    </div>
                                    {uiOrderList}
                                </div>
                            </div>
                        );
                    }
                } else {
                    uiFunctionArea = (
                        <div className="web_center_transit_function_area" style={{ width: '600px', height: '90%' }}>
                            <Spin size="large" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    );
                }
            }
            // 3-运费预估
            if (this.state.iCurrentMenu === 3) {

            }
        } else {
            strUserName = "立即登录/注册";
        }

        let uiLogoutButton = null;
        uiLogoutButton = (
            <div className="webpage_title_logout" onClick={this.LogOut}>退出登录</div>
        );
        let uiUserName = null;
        if (this.state.bLogin) {
            uiUserName = (
                <Tooltip placement="bottom" title={uiLogoutButton}>
                    {strUserName}
                </Tooltip>
            );
        } else {
            uiUserName = strUserName;
        }

        let uiPage = null;
        if (this.bMStation) {
            let arrMenuStyle = ["bottom_menu_btn", "bottom_menu_btn", "bottom_menu_btn"];
            arrMenuStyle[this.state.iCurrentMenu] += " bottom_menu_btn_select";
            let uiCenterArea = null;
            // 船期列表
            if (this.state.iCurrentMenu === 0) {
                uiCenterArea = (
                    <div className="zhihe_center_area">
                        <div className="zhihe_center_title">最近船期</div>
                        <Steps progressDot current={this.arrTransitDateData.length - 1}
                            direction="vertical" className="zhihe_center_contents" style={{ padding: '10vw' }}>
                            {this.arrTransitDateData.map((date, index) => {
                                let strDescription = date.comments;
                                return (
                                    <Step key={index} title={date.transit_date} description={strDescription} />
                                );
                            })}
                        </Steps>
                    </div>
                );
            }
            // 价格预估
            if (this.state.iCurrentMenu === 1) {
                const menuTransitTypeFilter = (
                    <Menu>
                        {this.arrTransitTypeFilter.map((type, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                                    {type}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                const menuItemTypeFilter = (
                    <Menu>
                        {this.arrItemTypeFilter.map((type, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchItemType.bind(this, index)}>
                                    {type}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                uiCenterArea = (
                    <div className="zhihe_center_area">
                        <div className="zhihe_center_title">运输方式</div>
                        <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuTransitTypeFilter} >
                            <Button className="webpage_delivery_filter_dropdown_button"
                                style={{ width: '80vw', height: '5vh', fontWeight: 'normal', fontSize: '18px', marginLeft: '10vw' }}>
                                {this.arrTransitTypeFilter[this.state.iTransitType]}
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="zhihe_center_title">货物种类</div>
                        <Dropdown className="webpage_delivery_filter_dropdown_button" trigger={['click']} overlay={menuItemTypeFilter} >
                            <Button className="webpage_delivery_filter_dropdown_button"
                                style={{ width: '80vw', height: '5vh', fontWeight: 'normal', fontSize: '18px', marginLeft: '10vw' }}>
                                {this.arrItemTypeFilter[this.state.iItemType]}
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="zhihe_center_title">
                            {"实际重量"}
                        </div>
                        <Input placeholder="请输入货物的 实际称重" ref={"weight"} defaultValue={0.0} addonAfter={"KG"} size="large"
                            style={{ width: '80vw', fontWeight: 'normal', fontSize: '20px', marginLeft: '10vw' }}>
                        </Input>
                        <div className="zhihe_center_title">
                            {"货物体积"}
                        </div>
                        <Input placeholder="请输入货物的 长度" ref={"length"} defaultValue={0.0} addonAfter={"cm"} size="large"
                            style={{ width: '80vw', fontWeight: 'normal', fontSize: '20px', marginLeft: '10vw', marginBottom: '10px' }}>
                        </Input>
                        <Input placeholder="请输入货物的 宽度" ref={"width"} defaultValue={0.0} addonAfter={"cm"} size="large"
                            style={{ width: '80vw', fontWeight: 'normal', fontSize: '20px', marginLeft: '10vw', marginBottom: '10px' }}>
                        </Input>
                        <Input placeholder="请输入货物的 高度" ref={"height"} defaultValue={0.0} addonAfter={"cm"} size="large"
                            style={{ width: '80vw', fontWeight: 'normal', fontSize: '20px', marginLeft: '10vw', marginBottom: '20px' }}>
                        </Input>
                        <Button className="webpage_delivery_filter_dropdown_button" type="primary" onClick={this.CalculateFee}
                            style={{ background: 'darkcyan', width: '80vw', height: '5vh', fontWeight: 'bold', fontSize: '18px', marginLeft: '10vw' }}>
                            计算价格
                        </Button>
                        <div className="zhihe_center_title">{"预估费用 = ￥ " + this.fTotalFee.toString()}</div>
                    </div>

                );
            }
            // 物流追踪
            if (this.state.iCurrentMenu === 2) {
                let uiOrderInfo = null;
                if (this.objExpressOrder !== null) {
                    uiOrderInfo = (
                        <div>
                            <div className="zhihe_center_title">{"状态：" + this.arrOrderStatus[this.objExpressOrder.status]}</div>
                            <div className="zhihe_center_title">{"收件人：" + this.objExpressOrder.receiver}</div>
                            <div className="zhihe_center_title">{"更新于：" + this.objExpressOrder.update_date.split('T')[0]}</div>
                        </div>
                    );
                }
                uiCenterArea = (
                    <div className="zhihe_center_area">
                        <div className="zhihe_center_title">包裹查询</div>
                        <Search defaultValue={""} onSearch={value => this.OnSearchClicked(value)} ref="order_search" enterButton
                            size="large" style={{ margin: '10%', maxWidth: '80%' }} />
                        {uiOrderInfo}
                    </div>
                );
            }
            uiPage = (
                <div className="zhihe_user_page">
                    <div className="title_row">
                        <div className="login_page_web_title_logo" />
                        <div className="login_page_web_title_slogan" />
                    </div>
                    {uiCenterArea}
                    <div className="bottom_menu_row">
                        <div className={arrMenuStyle[0]} onClick={this.SwitchMainMenu.bind(this.setState, 0)}>
                            <Icon type="calendar" style={{ marginRight: "5px", marginBottom: '5px', fontSize: '20px' }} />  {"船期列表"}
                        </div>
                        <div className={arrMenuStyle[1]} onClick={this.SwitchMainMenu.bind(this.setState, 1)}>
                            <Icon type="calculator" style={{ marginRight: "5px", marginBottom: '5px', fontSize: '20px' }} />{"运费预估"}
                        </div>
                        <div className={arrMenuStyle[2]} onClick={this.SwitchMainMenu.bind(this.setState, 2)}>
                            <Icon type="search" style={{ marginRight: "5px", marginBottom: '5px', fontSize: '20px' }} />{"包裹查询"}
                        </div>
                    </div>
                </div>
            );

        } else {
            uiPage = (
                <div className="login_page_web">
                    <div className="login_page_web_title_area">
                        <div className="login_page_web_title_logo" onClick={this.HomePage} />
                        <div className="login_page_web_title_slogan" onClick={this.HomePage} />
                        <div className="webpage_title_menu_row">
                            {this.arrMenuButton.map((button, index) => {
                                let strMenuButtonStyle = "webpage_title_menu_button";
                                if (this.state.iCurrentMenu === index)
                                    strMenuButtonStyle += " webpage_title_menu_button_select";
                                if (index === 0)
                                    return (
                                        <div className={strMenuButtonStyle} style={{ width: '115px', minWidth: '115px' }}
                                            onClick={this.SwitchMenuButton.bind(this, index)}>
                                            <Icon type={button.icon} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />{button.name}</div>
                                    );
                                else
                                    return (
                                        <div className={strMenuButtonStyle} onClick={this.SwitchMenuButton.bind(this, index)}>
                                            <Icon type={button.icon} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />{button.name}</div>
                                    );
                            })}
                        </div>
                        <div className="webpage_title_user_avatar" onClick={this.JumpToUserCenter} />
                        <div className="webpage_title_user_name" onClick={this.JumpToUserCenter} >
                            {uiUserName}
                        </div>
                    </div>
                    <div className="login_page_web_center_area">
                        <div className="web_center_area_bgp web_center_area_bgp_info" />
                        <div className="web_center_black_cover">
                            {uiFunctionArea}
                        </div>
                    </div>
                    <div className="login_page_web_bottom_area">
                        <div>
                            <div className="login_page_web_bottom_copyright">
                                至禾国际信息科技有限公司版权所有
                        </div>
                            <div className="login_page_web_bottom_copyright">
                                Copyright © 2018-2028 mouthing.info All Right Reseved
                        {/* 粤ICP备16088898号 */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }


        return uiPage;
    }
}
// export default Start;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'cn', region: 'CN'
})(ZhiHeWebsite);
