import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
// import Upload from 'antd/es/upload';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Collapse from 'antd/es/collapse';
import Icon from 'antd/es/icon';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';

// import { Empty, Spin, message, Modal, Input, Upload, Radio,
// Select, Collapse, Icon, DatePicker } from 'antd';
import TicketList from './UI_Module/TicketList.js'
import StoreList from './UI_Module/StoreList.js'
import Navbar from './UI_Module/Navbar.js'
import PaymentMethodDlg from './UI_Module/PaymentMethodDlg.js'
import "../../styles/ClientSide/user_center_page.css";
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"

import moment from 'moment';
const dateFormat = 'YYYY/MM/DD';

const g_strMessageKey = 'updating';
const { Option } = Select;
const { Panel } = Collapse;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// }

class UserCenter extends Component {
    constructor(props) {
        super();
        let strParams = window.location.search;  // 筛选出?后面的部分
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.iTab = 0;
        this.iStoreID = 0;
        this.iItem = 0;
        this.strFromPage = "";
        if (strParams !== "") {
            // 针对从特殊指定商家页面跳转到用户中心
            if (strParams.indexOf('store=') === -1)
                this.iTab = parseInt(strParams.split('=')[1], 10);
            else {
                this.iTab = parseInt(strParams.split('&')[0].split('=')[1], 10);
                this.iStoreID = parseInt(strParams.split('&')[1].split('=')[1], 10);
                if (strParams.indexOf('&item=') !== -1) {
                    this.iItem = parseInt(strParams.split('&')[2].split('=')[1], 10);
                    console.log("iItem = " + this.iItem);
                }
            }
            // 针对从首页/分类页/普通商家页面/用户页/搜索页等跳转到用户中心；且iFromPage主要针对登录和注册页，在登录或注册完成后，跳转到之前的页面
            if (strParams.indexOf('from=') !== -1) {
                this.strFromPage = strParams.split('from=')[1];
            }
        }
        if (this.iStoreID === 10005)
            this.iTab = 3;
        this.state = {
            iCurrentTab: 0,  // 0-优惠券列表, 1-收藏列表, 2-积分相关信息, 3-个人信息
            bLogin: false,
            bShowDlg: false,  // 用户信息修改框
            iUserInfo: -1,
            strUserInfoTitle: "",
            bAvatarUploading: false,
            iGender: 0,
            strPhoneCode: "+1",
            iCollectStores: 0,
            bDataLoading: false,
            bSelectPaymentDlg: false,
            bShareDlg: false
        };
        // 个人信息控制的默认设置
        this.arrUserInfoItems = [
            { title: "昵称", contents: "Howard", btn: "更 改" },
            { title: "电话", contents: "250-125-1256", btn: "更 改" },
            { title: "邮箱", contents: "jianghao0201@163.com", btn: "更 改" },
            { title: "密码", contents: "", btn: "更 改" },
            { title: "微信", contents: "", btn: "填 写" },
            { title: "QQ", contents: "", btn: "填 写" },
            { title: "生日", contents: "", btn: "填 写" }
        ];
        this.strCurrentUserCode = '';
        this.objUserInfo = {};
        this.strDefaultUserInfo = "";
        this.bAvatarLocalUploading = false;
        this.arrGender = ['男', '女', ''];
        this.arrStoreList = [];
        this.arrMainCategory = [];
        this.arrSubCategory = [];
        this.strBirthday = "";
        this.arrOrderList = -1;
        this.arrOrderStatus = ["未支付", "已支付", "有争议", "已关闭", "已完成", "已退款"];
        this.arrTicketsData = -1;  // 用户拥有的优惠券列表
        this.arrTicketUser = [];  // 优惠券和用户之间的关系，使用状态等
        this.bDataLoading = false;
        this.arrTicketsStatus = [];
        this.iShareItem = 0;
    }
    // 展示用户中心相应的页面内容
    ShowList(iTab) {
        if (this.bDataLoading)
            return;
        let strTab = "";
        switch (iTab) {
            case 0: strTab = "优惠券列表"; break;
            case 1: strTab = "收藏列表"; break;
            case 2: strTab = "积分列表"; break;
            case 3: strTab = "订单列表"; break;
            case 4: strTab = "个人信息"; break;
            default: strTab = ""; break;
        }
        // DataStatistic
        UserActiveUpdate(1, { active: "用户中心", order: iTab, tab: strTab });
        if (iTab !== 4)
            this.iTab = iTab;
        // 获取收藏商家列表
        if (iTab === 1) {
            if (this.objUserInfo.collect_stores !== "" && this.objUserInfo.collect_stores !== null) {
                let arrStoresID = this.objUserInfo.collect_stores.split(',');
                if (arrStoresID.length > 0) {
                    for (let i = 0; i < arrStoresID.length; i++) {
                        arrStoresID[i] = parseInt(arrStoresID[i], 10);
                    }
                    this.bDataLoading = true;
                    this.setState({
                        ...this.state,
                        bDataLoading: true
                    });
                    axios('/GetStoresFromID', { params: { arrStoresID: arrStoresID } })
                        .then(({ data }) => {
                            this.arrStoreList = [];
                            for (let i = arrStoresID.length - 1; i >= 0; i--) {
                                for (let j = 0; j < data.length; j++) {
                                    if (arrStoresID[i] === data[j].id) {
                                        this.arrStoreList.push(data[j]);
                                        break;
                                    }
                                }
                            }
                            this.bDataLoading = false;
                            this.setState({
                                ...this.state,
                                iCurrentTab: iTab,
                                bDataLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            }
        }
        // 获取订单列表
        if (iTab === 3) {
            this.bDataLoading = true;
            this.setState({
                ...this.state,
                bDataLoading: true
            });
            if (this.objUserInfo.code !== "") {
                axios('/GetUserOrders', { params: { user_code: this.objUserInfo.code } })
                    .then(({ data }) => {
                        this.arrOrderList = data;
                        this.bDataLoading = false;
                        this.setState({
                            ...this.state,
                            bDataLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }
        // 获取优惠券列表
        if (iTab === 0) {
            this.bDataLoading = true;
            this.setState({
                ...this.state,
                bDataLoading: true
            });
            if (this.objUserInfo.id !== "") {
                axios('/GetUserTickets', { params: { user_id: this.objUserInfo.id } })
                    .then(({ data }) => {
                        this.arrTicketsData = data.tickets;
                        this.arrTicketUser = data.ticket_user;

                        let arrTicketsID = [];
                        for (let i = 0; i < this.arrTicketsData.length; i++)
                            arrTicketsID.push(this.arrTicketsData[i].id);
                        axios('/GetTicketsUseStatus', { params: { arrTicketsID: arrTicketsID, user_id: this.objUserInfo.id } })
                            .then(({ data }) => {
                                this.arrTicketsStatus = data;
                                console.log("UserCenter : GetTicketsUseStatus >>> ");
                                console.log(this.arrTicketsStatus);
                                this.bDataLoading = false;
                                this.setState({
                                    ...this.state,
                                    bDataLoading: false
                                });
                            }).catch(function (error) { console.log(error); });

                    }).catch(function (error) { console.log(error); });

            }
        }
        this.setState({
            ...this.state,
            iCurrentTab: iTab
        });
    }
    // 跳转回上个界面
    BackBtnClicked = () => {
        this.ShowList(this.iTab);
    };
    // 退出登录
    LogoutBtnClicked = () => {
        // this.strFromPage = window.location.href.split('/webapp/')[1];
        this.strFromPage = "";
        // DataStatistic
        UserActiveUpdateBack(3, { active: "退出登录" }, this.JumpToLogin);
    }
    // 跳转回登录页
    JumpToLogin = () => {
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        if (this.iStoreID === 0) {
            if (this.strFromPage === "")
                window.location.href = '/webapp/login';
            else
                window.location.href = '/webapp/login?from=' + this.strFromPage;
        } else {
            if (this.iItem === 0)
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            else
                window.location.href = '/webapp/login?store=' + this.iStoreID + '&item=' + this.iItem;
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    let strPhoneCode = ""
                    if (this.objUserInfo.phone !== null && this.objUserInfo.phone !== "")
                        strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
                    let iCollectStores = 0;
                    if (this.objUserInfo.collect_stores !== null &&
                        this.objUserInfo.collect_stores !== "") {
                        if (this.objUserInfo.collect_stores.indexOf(',') === -1)
                            iCollectStores = 1;
                        else
                            iCollectStores = this.objUserInfo.collect_stores.split(',').length;
                    }
                    // DataStatistic
                    UserActiveUpdate(0, { active: "访问用户中心" });
                    this.setState({
                        ...this.state,
                        bLogin: true,
                        imageUrl: this.objUserInfo.avatar,
                        iGender: this.objUserInfo.gender,
                        strPhoneCode: strPhoneCode,
                        iCollectStores: iCollectStores
                    });
                    this.ShowList(this.iTab);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 上传头像
    AfterLocalUpload = (imgUrl) => {
        // 上传到云端
        axios.post('/UploadAvatarToCloudinary', { id: this.objUserInfo.id, imgUrl: imgUrl })
            .then((response) => {
                // DataStatistic
                UserActiveUpdate(4, { active: "用户信息修改", order: -1, name: "头像修改" });
                message.success({ content: "头像已更新！", key: g_strMessageKey, duration: 2 });
                this.objUserInfo.avatar = response.data;
                this.setState({
                    ...this.state,
                    bAvatarUploading: false,
                    imageUrl: response.data
                });
            });
    }
    // 更换头像
    handleChange = info => {
        if (!this.bAvatarLocalUploading) {
            this.bAvatarLocalUploading = true;
            message.loading({ content: '头像上传中……', key: g_strMessageKey, duration: 0 });
        }
        if (info.file.status === 'uploading') {
            this.setState({ bAvatarUploading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.bAvatarLocalUploading = false;
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, this.AfterLocalUpload.bind(this));
        }
    };
    // 显示对话框
    ShowDlg = (iUserInfo, strTitle) => {
        let strInfoName = "";
        switch (iUserInfo) {
            case 0: {  // 用户名昵称
                strInfoName = "昵称";
                this.strDefaultUserInfo = this.objUserInfo.name;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.name;
                break;
            }
            case 1: {  // 邮箱
                strInfoName = "邮箱";
                this.strDefaultUserInfo = this.objUserInfo.email;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.email;
                break;
            }
            case 2: {  // 电话
                strInfoName = "电话";
                if (this.objUserInfo.phone !== null)
                    this.strDefaultUserInfo = this.objUserInfo.phone.split(') ')[1];
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.strDefaultUserInfo;
                break;
            }
            case 3: {  // QQ
                strInfoName = "QQ";
                this.strDefaultUserInfo = this.objUserInfo.qq;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.qq;
                break;
            }
            case 4: {  // 微信
                strInfoName = "微信";
                this.strDefaultUserInfo = this.objUserInfo.wechat;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.wechat;
                break;
            }
            case 5: {  // 生日
                strInfoName = "生日";
                this.strDefaultUserInfo = this.objUserInfo.birthday;
                // if (this.refs.user_info_input !== undefined)
                //     this.refs.user_info_input.state.value = this.objUserInfo.birthday;
                break;
            }
            case 6: {  // 地址
                strInfoName = "地址";
                this.strDefaultUserInfo = this.objUserInfo.address;
                if (this.refs.user_info_input !== undefined)
                    this.refs.user_info_input.state.value = this.objUserInfo.address;
                break;
            }
            case 7: {  // 密码
                strInfoName = "密码";
                if (this.refs.user_info_input0 !== undefined) {
                    this.refs.user_info_input0.state.value = "";
                    this.refs.user_info_input1.state.value = "";
                    this.refs.user_info_input2.state.value = "";
                }
                break;
            }
            case 8: strInfoName = "性别"; break;
            default: break;
        }
        // DataStatistic
        UserActiveUpdate(4, { active: "用户尝试修改信息", name: strInfoName });
        let strPhoneCode = "+1";
        if (iUserInfo === 2 & this.objUserInfo.phone !== null)
            strPhoneCode = this.objUserInfo.phone.split(') ')[0].split('(')[1];
        this.setState({
            ...this.state,
            bShowDlg: true,
            iUserInfo: iUserInfo,
            strUserInfoTitle: strTitle,
            strPhoneCode: strPhoneCode
        });
    };
    // 确认按钮点击相应
    OKButtonClicked = e => {
        // 获取 & 重置数据
        this.strDefaultUserInfo = "";
        let strOgeName = this.objUserInfo.name;
        let bNameCheck = false;
        let strInfoName = "";
        switch (this.state.iUserInfo) {
            case 0: { // 用户名昵称
                strInfoName = "昵称";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === "") {
                        message.warning("昵称（用户名）不能为空！");
                        return;
                    }
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.name) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    bNameCheck = true;
                    this.objUserInfo.name = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 1: { // 邮箱
                strInfoName = "邮箱";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.email) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.email = this.refs.user_info_input.state.value.trim();
                    if (this.objUserInfo.email === "")
                        this.objUserInfo.email = null;
                }
                break;
            }
            case 2: { // 电话
                strInfoName = "电话";
                if (this.refs.user_info_input !== undefined) {
                    let strNewPhone = this.refs.user_info_input.state.value.trim();
                    strNewPhone = "(" + this.state.strPhoneCode + ") " + strNewPhone;
                    if (strNewPhone === this.objUserInfo.phone) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    if (this.refs.user_info_input.state.value.trim() === "")
                        this.objUserInfo.phone = null;
                    else
                        this.objUserInfo.phone = strNewPhone;
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 3: { // QQ
                strInfoName = "QQ";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.qq) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.qq = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 4: { // 微信
                strInfoName = "微信";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.wechat) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.wechat = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 5: { // 生日
                strInfoName = "生日";
                if (this.strBirthday === this.objUserInfo.birthday) {
                    this.setState({ ...this.state, bShowDlg: false });
                    return;
                }
                this.objUserInfo.birthday = this.strBirthday;
                break;
            }
            case 6: { // 地址
                strInfoName = "地址";
                if (this.refs.user_info_input !== undefined) {
                    if (this.refs.user_info_input.state.value.trim() === this.objUserInfo.address) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.address = this.refs.user_info_input.state.value.trim();
                    this.refs.user_info_input.state.value = "";
                }
                break;
            }
            case 7: { // 密码
                strInfoName = "密码";
                if (this.refs.user_info_input0 !== undefined) {
                    // 旧密码不能为空
                    if (this.refs.user_info_input0.state.value.trim() === "") {
                        message.warning("请输入旧的密码！");
                        return;
                    }
                    if (this.refs.user_info_input1.state.value.trim().length < 6) {
                        message.warning("新的密码至少6位！");
                        return;
                    }
                    if (this.refs.user_info_input1.state.value.trim() !== this.refs.user_info_input2.state.value.trim()) {
                        message.warning("两次输入的密码不一致！");
                        return;
                    }
                    if (this.refs.user_info_input0.state.value.trim() === this.refs.user_info_input1.state.value.trim()) {
                        this.setState({ ...this.state, bShowDlg: false });
                        return;
                    }
                    this.objUserInfo.password = this.refs.user_info_input1.state.value.trim();
                }
                break;
            }
            case 8: { // 性别
                strInfoName = "性别";
                this.objUserInfo.gender = this.state.iGender;
                break;
            }
            default: break;
        }
        message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
        let strEmailCheck = this.objUserInfo.email;
        if (strEmailCheck === null)
            strEmailCheck = "";
        if (this.state.iUserInfo !== 1)
            strEmailCheck = "";
        let strPhoneCheck = this.objUserInfo.phone;
        if (strPhoneCheck === null)
            strPhoneCheck = "";
        if (this.state.iUserInfo !== 2)
            strPhoneCheck = "";
        let strOldPassword = "";
        if (this.state.iUserInfo === 7)
            strOldPassword = this.refs.user_info_input0.state.value.trim();
        axios('/EmailPhoneCheck', { params: { email: strEmailCheck, phone: strPhoneCheck } })
            .then(({ data }) => {
                if (data.status === 1) {
                    axios('/PasswordCheck', {
                        params: {
                            id: this.objUserInfo.id,
                            password: strOldPassword
                        }
                    }).then(({ data }) => {
                        if (data.status === 1) {
                            axios.post('/UpdateUserBasicInfo', {
                                objUserInfo: this.objUserInfo,
                                bNameCheck: bNameCheck
                            }).then(({ data }) => {
                                if (data.status === 1) {
                                    // DataStatistic
                                    UserActiveUpdate(4, { active: "用户信息修改成功", name: strInfoName });
                                    message.success({
                                        content: "更新完成！",
                                        key: g_strMessageKey, duration: 2
                                    });
                                    this.setState({
                                        ...this.state,
                                        bShowDlg: false
                                    });
                                }
                                else {
                                    message.warning({
                                        content: data.message,
                                        key: g_strMessageKey, duration: 2
                                    });
                                    this.objUserInfo.name = strOgeName;
                                }
                            }).catch(function (error) { console.log(error); });
                        } else {
                            message.warning({
                                content: data.message,
                                key: g_strMessageKey, duration: 2
                            });
                        }
                    }).catch(function (error) { console.log(error); });
                } else {
                    message.warning({
                        content: data.message,
                        key: g_strMessageKey, duration: 2
                    });
                }
            }).catch(function (error) { console.log(error); });
    };
    // 取消按钮点击相应
    CancelButtonClicked = e => {
        this.strDefaultUserInfo = "";
        if (this.refs.user_info_input !== undefined) {
            this.refs.user_info_input.state.value = "";
        }
        this.setState({
            ...this.state,
            bShowDlg: false
        });
    };
    // 性别选择
    GenderSelector = e => {
        this.setState({
            ...this.state,
            iGender: e.target.value,
        });
    };
    // 电话区号选择
    PhoneCodeSelector = e => {
        this.setState({
            ...this.state,
            strPhoneCode: e,
        });
    }
    // 获取目标分类信息 
    GetCategoriesInfo = () => {
        axios('/GetMainCategories').then(
            ({ data }) => {
                this.arrMainCategories = data.slice(1, data.length);
            }
        ).catch(function (error) { console.log(error); });
        axios('/GetSubCategories').then(
            ({ data }) => {
                this.arrSubCategories = data;
            }
        ).catch(function (error) { console.log(error); });
    }
    // 日期选择器
    onChange = (date, dateString) => {
        this.strBirthday = dateString;
    }
    // 订单列表折叠区域点击响应
    OrderListCollapse = (key) => {
        // console.log("OrderListCollapse : ");
        // console.log(key);
    }
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付弹窗结果返回
    PaymentMethodResult = (bConfirm, iCardBrand, iCardLast4, iPaymentMethod, objCardInfo) => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: false
        });
    }
    // 返回商家页面
    BackToStore = () => {
        if (this.iItem === 0)
            window.location.href = "/webapp/store/" + this.iStoreID;
        else
            window.location.href = "/webapp/store/" + this.iStoreID + "&item=" + this.iItem;
    }
    // 客服/分享弹窗
    ShowShareDlg = (bShow, iShareItem) => {
        if (iShareItem > 0) {
            this.iShareItem = iShareItem;
        }
        this.setState({
            bShareDlg: bShow
        });
    };

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {

        console.log("User Center");

        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
            this.GetCategoriesInfo();
        } else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            this.JumpToLogin();
        }
    }

    render() {

        document.title = "共享佳月，中秋别忘寄";

        // const { imageUrl } = this.state;

        if (this.state.bLogin) {
            // 1. 标题部分
            let uiAvatar = (
                <div className="user_center_avatar user_icon" onClick={this.ShowList.bind(this, 4)} />
            );
            if (this.state.imageUrl !== null & this.state.imageUrl !== "") {
                uiAvatar = <img className="user_center_avatar"
                    src={this.state.imageUrl} alt="avatar"
                    onClick={this.ShowList.bind(this, 4)} />;
            }
            // 支付方式选择框
            let uiPaymentOptions = (
                <PaymentMethodDlg bSelectPaymentDlg={this.state.bSelectPaymentDlg} PaymentMethodResult={this.PaymentMethodResult}
                    strTitle={"支付方式"} objUserInfo={this.objUserInfo} />
            );
            let uiUserTitle = (
                <div className="user_center_title">
                    {uiAvatar}
                    <div>
                        <div className="user_center_name">{this.objUserInfo.name}</div>
                        <div className="user_center_info_title_row">
                            <div className="user_center_user_info_btn" onClick={this.ShowList.bind(this, 4)}>个人信息</div>
                            {/* <div className="user_center_user_info_btn">地址修改 | </div>
                            <div className="user_center_user_info_btn">支付信息</div> */}
                        </div>
                    </div>
                    <div className="user_center_setting_area">
                        <div className="user_center_setting_btn card_icon" onClick={this.SelectPaymentMethod} />
                        <div className="user_center_setting_btn message_icon" />
                    </div>
                    {uiPaymentOptions}
                </div>
            );
            // 2. 用户收集信息部分: 优惠券、收藏、积分
            let strSelectStyle = ["user_collects_item", "user_collects_item", "user_collects_item", "user_collects_item"];
            strSelectStyle[this.state.iCurrentTab] = "user_collects_item_select";
            let iOrderNum = 0;
            if (this.objUserInfo.order_list !== null) {
                if (this.objUserInfo.order_list !== "" && this.objUserInfo.order_list.indexOf(',') >= 0) {
                    iOrderNum = this.objUserInfo.order_list.split(',').length;
                }
            }
            if (this.arrOrderList !== -1) {
                iOrderNum = this.arrOrderList.length;
            }
            let iTicketNum = 0;
            if (this.objUserInfo.ticket_list !== null) {
                iTicketNum = this.objUserInfo.ticket_list.split(',').length;
            }
            if (this.arrTicketsData) {
                if (this.arrTicketsData !== -1) {
                    iTicketNum = this.arrTicketsData.length;
                }
            }
            let uiUserCollects = (
                <div className="user_collects_area">
                    <div className={strSelectStyle[3]} onClick={this.ShowList.bind(this, 3)} >
                        <div className="user_collects_num">
                            <div className="user_collects_icon order_icon" />{iOrderNum}</div>
                        <div className="user_collects_text">订 单</div>
                    </div>
                    <div className="user_center_divider user_center_divider_v" />
                    <div className={strSelectStyle[1]} onClick={this.ShowList.bind(this, 1)} >
                        <div className="user_collects_num">
                            <div className="user_collects_icon collects_icon" />{this.state.iCollectStores}</div>
                        <div className="user_collects_text">收 藏</div>
                    </div>
                    <div className="user_center_divider user_center_divider_v" />
                    <div className={strSelectStyle[0]} onClick={this.ShowList.bind(this, 0)} >
                        <div className="user_collects_num">
                            <div className="user_collects_icon ticket_icon" />{iTicketNum}</div>
                        <div className="user_collects_text">卡 券</div>
                    </div>
                    <div className="user_center_divider user_center_divider_v" />
                    <div className={strSelectStyle[2]} onClick={this.ShowList.bind(this, 2)} >
                        <div className="user_collects_num">
                            <div className="user_collects_icon credit_icon" />0</div>
                        <div className="user_collects_text">积 分</div>
                    </div>
                </div>
            );
            uiUserCollects = (
                <div className="user_collects_area">
                    <div className="user_collects_text">我的订单</div>
                    <div className="user_collects_num">
                        {/* <div className="user_collects_icon order_icon" /> */}
                        <Icon type="file-text" theme="filled" />
                        {iOrderNum}
                    </div>
                    {/* <div className={strSelectStyle[3]} onClick={this.ShowList.bind(this, 3)} >
                    </div> */}
                </div>
            );
            // 3. 列表区域内容
            let uiList = (
                <div className="user_center_list_area">
                    <div className="user_center_loading_area">
                        <Spin size="large" />
                    </div>
                </div>
            );
            // 3.0 优惠券列表
            if (this.state.iCurrentTab === 0) {
                if (!this.bDataLoading) {
                    let uiTicketList = (
                        <div className="store_ticket_list_area bgc_brown_super_light">
                            <TicketList objStoreInfo={-1} arrTicketUser={this.arrTicketUser} iCurrentPage={3}
                                arrTicketsData={this.arrTicketsData} arrTicketsStatus={this.arrTicketsStatus}
                                objUserInfo={this.objUserInfo} />
                        </div>
                    );
                    uiList = uiTicketList;
                }
            } else {
                // 3.1 收藏列表
                if (this.state.iCurrentTab === 1) {
                    uiList = (
                        <div className="user_center_list_area">
                            <StoreList iCurrentPage={3} /*iFromPage={3}*/
                                strTitle={"关注的商家"} bShowFilter={false}
                                arrMainCategory={this.arrMainCategories}
                                arrSubCategory={this.arrSubCategories}
                                arrStoreList={this.arrStoreList} />
                        </div>
                    );
                } else {
                    // 3.2 积分列表
                    if (this.state.iCurrentTab === 2) {
                        uiList = (<Empty description="暂无数据" className="user_center_empty" />);
                    } else {
                        // 3.3 订单列表
                        if (this.state.iCurrentTab === 3) {
                            if (!this.bDataLoading) {
                                let uiOrderList = (<div />);
                                let strListAreaStyle = "user_center_list_area";
                                if (this.iStoreID === 10005)
                                    strListAreaStyle = "user_center_list_area user_center_list_area_top_space";
                                if (iOrderNum <= 0) {
                                    uiOrderList = <Empty description="无订单信息" className="user_center_empty" />;
                                    if (this.iStoreID === 10005)
                                        uiOrderList = (
                                            <div className={strListAreaStyle}>
                                                <Empty description="还未参与任何拼单活动" className="user_center_cut_price_empty" />
                                                <div className="go_to_cut_price" onClick={this.BackToStore}>我要拼单！</div>
                                            </div>
                                        );
                                } else {
                                    if (this.iStoreID === 10005) {
                                        uiOrderList = (
                                            <div className={strListAreaStyle}>
                                                <Collapse onChange={this.OrderListCollapse}>
                                                    {this.arrOrderList.map((item, index) => {
                                                        let strCommentsShort = item.options;
                                                        if (strCommentsShort.indexOf(",") >= 0) {
                                                            strCommentsShort = strCommentsShort.split(',')[0];
                                                        }
                                                        if (strCommentsShort.indexOf("，") >= 0) {
                                                            strCommentsShort = strCommentsShort.split('，')[0];
                                                        }
                                                        let uiTitle = (
                                                            <div className="user_center_order_details_title">
                                                                <div className="user_center_order_details_title_left">
                                                                    <Icon type="snippets" theme="filled" />
                                                                    {" 订单 " + item.oid}
                                                                </div>
                                                                <div className="user_center_order_details_title_center">
                                                                    <Icon type="calendar" theme="filled" />
                                                                    {" " + item.client_order_time.split('T')[0]}
                                                                </div>
                                                                <div className="user_center_order_details_title_right">
                                                                    {strCommentsShort}
                                                                </div>
                                                            </div>
                                                        );
                                                        let objItems = JSON.parse(item.items);
                                                        let strItemName = "月饼";
                                                        let strName = "无";
                                                        let strPhone = "无";
                                                        let uiComments = null;
                                                        if (item.contact !== null && item.contact !== "")
                                                            strName = item.contact;
                                                        if (item.phone !== null && item.phone !== "")
                                                            strPhone = item.phone;
                                                        if (item.options !== null && item.options !== "") {
                                                            let strComments = item.options;
                                                            uiComments = (
                                                                <div className="user_center_order_details_text">
                                                                    {" 备注信息：" + strComments}
                                                                </div>
                                                            );
                                                            if (item.cut_price_batch === -1) {
                                                                strComments += ", 即将发货";
                                                                uiComments = (
                                                                    <div className="user_center_order_details_text">
                                                                        {" 备注信息：" + strComments}
                                                                    </div>
                                                                );
                                                            } else {
                                                                if (item.cut_price_status === 1 && strComments === "拼单中") {
                                                                    strComments += ", 分享给好友一起拼！";
                                                                    uiComments = (
                                                                        <div>
                                                                            <div className="user_center_order_details_text">
                                                                                {" 备注信息：" + strComments}
                                                                            </div>
                                                                            <div className="user_center_share_button" onClick={this.ShowShareDlg.bind(this, true, objItems.items[0].id)}>
                                                                                <Icon type="share-alt" />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                if (item.cut_price_status === 2 && strComments === "已拼成") {
                                                                    strComments += ", 即将发货";
                                                                    uiComments = (
                                                                        <div className="user_center_order_details_text">
                                                                            {" 备注信息：" + strComments}
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        }
                                                        if (objItems.items[0].name)
                                                            strItemName = objItems.items[0].name;
                                                        let uiAddress = null;
                                                        if (item.delivery_way === 0)
                                                            uiAddress = (
                                                                <div className="user_center_order_details_text user_center_order_details_address">
                                                                    {" 到店自提：" + item.address}
                                                                </div>
                                                            );
                                                        else
                                                            uiAddress = (
                                                                <div className="user_center_order_details_text user_center_order_details_address">
                                                                    {" 送货上门：" + item.address}
                                                                </div>
                                                            );
                                                        return (
                                                            <Panel header={uiTitle} key={index}>
                                                                <div className="user_center_order_details_area">
                                                                    <div className="user_center_order_details_text">
                                                                        {" 订单详情：" + strItemName + " x " + objItems.total_num + "盒"}
                                                                    </div>
                                                                    {/* <div className="user_center_order_details_text">
                                                                        {" 下单时间：" + strDateTime}
                                                                    </div>
                                                                    {uiPaymentMethod}
                                                                    <div className="user_center_order_details_text">
                                                                        {" 支付金额：CAD " + (item.total_price / 100).toFixed(2)}</div> */}
                                                                    <div className="user_center_order_details_text">
                                                                        {" 联系姓名：" + strName}
                                                                    </div>
                                                                    <div className="user_center_order_details_text">
                                                                        {" 联系电话：" + strPhone}
                                                                    </div>
                                                                    {uiAddress}
                                                                    {uiComments}
                                                                </div>
                                                            </Panel>
                                                        );
                                                    })}
                                                </Collapse>
                                            </div>
                                        );
                                    } else
                                        uiOrderList = (
                                            <div className={strListAreaStyle}>
                                                <Collapse onChange={this.OrderListCollapse}>
                                                    {this.arrOrderList.map((item, index) => {
                                                        console.log("item = ", index, item);
                                                        let uiTitle = (
                                                            <div className="user_center_order_details_title">
                                                                <div className="user_center_order_details_title_left">
                                                                    <Icon type="shop" theme="filled" />
                                                                    {" " + item.store_name}
                                                                </div>
                                                                <div className="user_center_order_details_title_center">
                                                                    <Icon type="calendar" theme="filled" />
                                                                    {" " + item.client_order_time.split('T')[0]}
                                                                </div>
                                                                <div className="user_center_order_details_title_right">
                                                                    {" " + this.arrOrderStatus[item.status]}
                                                                    <Icon type="check" />
                                                                </div>
                                                            </div>
                                                        );
                                                        let strDateTime = item.client_order_time.split('T')[0] + " " + item.client_order_time.split('T')[1].split('.')[0];
                                                        let uiPaymentMethod = (
                                                            <div className="user_center_order_details_text">
                                                                {" 支付方式：未知"}
                                                            </div>
                                                        );
                                                        if (item.card_info !== null) {
                                                            uiPaymentMethod = (
                                                                <div className="user_center_order_details_text">
                                                                    {" 支付方式："}
                                                                    <Icon type="credit-card" style={{ marginRight: '1vw', marginBottom: '0.5vw' }} />
                                                                    {" " + item.card_info.split(',')[1]}
                                                                </div>
                                                            );
                                                        }
                                                        let strName = "无";
                                                        let strPhone = "无";
                                                        let strComments = "无";
                                                        if (item.contact !== null && item.contact !== "")
                                                            strName = item.contact;
                                                        if (item.phone !== null && item.phone !== "")
                                                            strPhone = item.phone;
                                                        if (item.options !== null && item.options !== "")
                                                            strComments = item.options;
                                                        return (
                                                            <Panel header={uiTitle} key={index}>
                                                                <div className="user_center_order_details_area">
                                                                    <div className="user_center_order_details_text">
                                                                        {" 订单编号：" + item.oid}
                                                                    </div>
                                                                    <div className="user_center_order_details_text">
                                                                        {" 下单时间：" + strDateTime}
                                                                    </div>
                                                                    {uiPaymentMethod}
                                                                    <div className="user_center_order_details_text">
                                                                        {" 支付金额：CAD " + (item.total_price / 100).toFixed(2)}</div>
                                                                    <div className="user_center_order_details_text">
                                                                        {" 联系姓名：" + strName}
                                                                    </div>
                                                                    <div className="user_center_order_details_text">
                                                                        {" 联系电话：" + strPhone}
                                                                    </div>
                                                                    <div className="user_center_order_details_text">
                                                                        {" 备注信息：" + strComments}
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        );
                                                    })}
                                                </Collapse>
                                            </div>
                                        );
                                }
                                uiList = uiOrderList;
                            }
                        }
                    }
                }
            }
            // 4. 修改信息对话框
            let uiUserInfoUpdateContents = (
                <div className="user_info_change_line">
                    <div className="user_info_change_title">{this.state.strUserInfoTitle}</div>
                    <Input style={{ width: '75vw' }} size="large" allowClear={true}
                        defaultValue={this.strDefaultUserInfo} ref="user_info_input" />
                </div>
            );
            if (this.state.iUserInfo === 7) {
                uiUserInfoUpdateContents = (
                    <div>
                        <div className="user_info_change_line user_password_input_margin">
                            <div className="user_info_change_title">旧的密码</div>
                            <Input type="password" style={{ width: '65vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input0" />
                        </div>
                        <div className="user_info_change_line user_password_input_margin">
                            <div className="user_info_change_title">新的密码</div>
                            <Input type="password" style={{ width: '65vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input1" />
                        </div>
                        <div className="user_info_change_line">
                            <div className="user_info_change_title">再次确认</div>
                            <Input type="password" style={{ width: '65vw', height: '8vw' }} allowClear={true}
                                defaultValue={this.strDefaultUserInfo} ref="user_info_input2" />
                        </div>
                    </div>
                );
            }
            if (this.state.iUserInfo === 8) {
                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">性别</div>
                        <Radio.Group onChange={this.GenderSelector} value={this.state.iGender}>
                            <Radio value={0}>男</Radio>
                            <Radio value={1}>女</Radio>
                        </Radio.Group>
                    </div>
                );
            }
            if (this.state.iUserInfo === 2) {
                const prefixSelector = (
                    <Select style={{ width: '17.5vw' }} onChange={this.PhoneCodeSelector}
                        value={this.state.strPhoneCode}>
                        <Option value="+1">+1</Option>
                        <Option value="+86">+86</Option>
                    </Select>
                );
                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">{this.state.strUserInfoTitle}</div>
                        <Input style={{ width: '75vw' }} size="large" allowClear={true}
                            addonBefore={prefixSelector} ref="user_info_input"
                            defaultValue={this.strDefaultUserInfo} />
                    </div>
                );
            }
            if (this.state.iUserInfo === 5) {
                let strDate = new Date().toISOString().split('T')[0];
                let arrDate = strDate.split('-');
                strDate = arrDate[0] + "/" + arrDate[1] + "/" + arrDate[2];
                if (this.objUserInfo.birthday !== "" && this.objUserInfo.birthday !== null)
                    strDate = this.objUserInfo.birthday;
                this.strBirthday = strDate;
                uiUserInfoUpdateContents = (
                    <div className="user_info_change_line">
                        <div className="user_info_change_title">生日</div>
                        <DatePicker defaultValue={moment(strDate, dateFormat)}
                            style={{ width: '75vw' }} size="large" format={dateFormat}
                            onChange={this.onChange} />
                    </div>
                );
            }
            let uiUserInfoUpdateDlg = (
                <Modal visible={this.state.bShowDlg} okText="确定" cancelText="取消" closable={false}
                    onOk={this.OKButtonClicked} onCancel={this.CancelButtonClicked} centered
                    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    {uiUserInfoUpdateContents}
                </Modal>
            );
            // 4. 个人信息
            // let uiAvatarButton = (
            //     <div className="user_info_avatar_icon user_icon" />
            //     // <div className="user_info_avatar_area">
            //     //     <div className="user_info_avatar_change_btn edit_icon"
            //     //         onClick={this.ChangeAvatar} />
            //     // </div>
            // );
            let uiUserInfo = (
                <div className="user_center_page">
                    <div className="user_info_title_area">
                        {/* <div className="user_center_back_btn back_icon" onClick={this.BackBtnClicked} /> */}
                        <div className="login_back_btn" onClick={this.BackBtnClicked} >
                            <Icon type="left-circle" theme="filled" />
                        </div>
                        <div className="user_info_title">个人信息</div>
                    </div>
                    <div className="user_avatar_space" />
                    {/* <div className="user_info_avatar_area">
                        <Upload name="avatar" listType="picture" className="avatar_uploader"
                            style={{ width: '18vw', height: '18vw' }} ref={"avatar_uploader"}
                            showUploadList={false} action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload} onChange={this.handleChange} >
                            {imageUrl ? <img className="avator_uploader_shadow" src={imageUrl} alt="avatar" style={{ width: '18vw', height: '18vw' }} /> : uiAvatarButton}
                        </Upload>
                    </div> */}
                    {/* 昵称 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">昵称</div>
                        <div className="user_info_item_content">{this.objUserInfo.name}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 0, "昵称")}>
                            更改</div>
                    </div>
                    {/* 密码 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">密码</div>
                        <div className="user_info_item_content">********</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 7, "密码")}>
                            更改</div>
                    </div>
                    {/* 性别 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">性别</div>
                        <div className="user_info_item_content">{this.arrGender[this.state.iGender]}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 8, "性别")}>
                            更改</div>
                    </div>
                    {/* 生日 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">生日</div>
                        <div className="user_info_item_content">{this.objUserInfo.birthday}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 5, "生日")}>
                            更改</div>
                    </div>
                    {/* 邮箱 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">邮箱</div>
                        <div className="user_info_item_content">{this.objUserInfo.email}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 1, "邮箱")}>
                            更改</div>
                    </div>
                    {/* 电话 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">电话</div>
                        <div className="user_info_item_content">{this.objUserInfo.phone}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 2, "电话")}>
                            更改</div>
                    </div>
                    {/* QQ */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">QQ</div>
                        <div className="user_info_item_content">{this.objUserInfo.qq}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 3, "QQ")}>
                            更改</div>
                    </div>
                    {/* WeChat */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">微信</div>
                        <div className="user_info_item_content">{this.objUserInfo.wechat}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 4, "微信")}>
                            更改</div>
                    </div>
                    {/* 地址 */}
                    <div className="user_info_item">
                        <div className="user_info_item_title">地址</div>
                        <div className="user_info_item_content user_info_address">{this.objUserInfo.address}</div>
                        <div className="user_info_item_btn" onClick={this.ShowDlg.bind(this, 6, "地址")}>
                            更改</div>
                    </div>
                    {/* 用户信息修改框 */}
                    {uiUserInfoUpdateDlg}
                    <div className="user_info_bottom_area" onClick={this.LogoutBtnClicked}>
                        退出
                    </div>
                </div>
            );

            let uiNavBar = <Navbar iCurrentPage={3} objUserInfo={this.objUserInfo} iStoreID={this.iStoreID} />;
            if (this.iStoreID === 10005) {
                uiNavBar = null;
                uiUserTitle = (
                    <div className="user_title">
                        <div className="user_avatar">
                            <Icon type="user" />
                        </div>
                        <div className="user_name">
                            {this.objUserInfo.name}
                        </div>
                        <div className="user_order">
                            {"我参与的拼单"}
                        </div>
                        <div className="user_setting" onClick={this.ShowList.bind(this, 4)}>
                            <Icon type="setting" theme="filled" />
                        </div>
                        <div className="user_card" onClick={this.SelectPaymentMethod}>
                            <Icon type="credit-card" theme="filled" />
                        </div>
                        {uiPaymentOptions}
                    </div>
                );
                uiUserCollects = null;
                // uiList = (
                //     <div className="user_order_list">
                //         test
                //     </div>
                // );
            }

            // 分享弹窗
            let strShareDlgTitle = "分享拼单，大家一起来团！";
            let strShareDlgDetails = "扫描或长按二维码，分享给您的家人朋友";
            let uiShareDlg = (
                <Modal title={strShareDlgTitle} visible={this.state.bShareDlg} footer={null} centered={true}
                    onCancel={this.ShowShareDlg.bind(this, false, -1)} >
                    <div className="share_dlg_title">
                        {strShareDlgDetails}
                        <div className="share_dlg_qrcode">
                            <img src={"https://api.qrserver.com/v1/create-qr-code/?data=https://www.mouthing.info/webapp/store/10005?item=" + this.iShareItem + "&amp;size=100x100"}
                                alt="" title="" />
                        </div>
                    </div>
                </Modal>
            );

            // 0. 页面内容
            let uiPageContent = (
                <div className="user_center_page">
                    {uiNavBar}
                    {/* 用户头像、昵称 */}
                    {uiUserTitle}
                    {/*用户信息：优惠券、收藏、余额 */}
                    {uiUserCollects}
                    {/* 列表区域 */}
                    {uiList}
                    <div className="login_back_btn" onClick={this.BackToStore} >
                        <Icon type="left-circle" theme="filled" />
                    </div>
                    {uiShareDlg}
                </div>
            );
            // 3.4 个人信息
            if (this.state.iCurrentTab === 4) {
                uiPageContent = uiUserInfo;
            }
            return (uiPageContent);
        } else {
            return (
                <div className="user_page_spin">
                    <Spin size="large" />
                </div>
            );
        }

    }
}
export default UserCenter;
