import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import message from 'antd/es/message';
// import Clipboard from 'react-clipboard.js';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js";
import { GetCNProvince } from "./CNCity";
import { GetCNCity } from "./CNCity";
import { GetCNArea } from "./CNCity";

const g_strMessageKey = 'updating';
global.arrReceiverData = [];

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '用户信息', dataIndex: 'user_info', width: 150, ellipsis: true },
    { title: '收件人', dataIndex: 'name', width: 150, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 125, ellipsis: true },
    { title: '地址', dataIndex: 'address', width: 300, ellipsis: true },
    { title: '邮编', dataIndex: 'postcode', width: 100, ellipsis: true },
    { title: '身份证号', dataIndex: 'id_number', width: 200, ellipsis: true },
    { title: '身份证照片', dataIndex: 'photo_id', width: 125, ellipsis: true }
];

class StoreReceiverManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: "",
            selectedFile1: null,
            selectedFile2: null,
            bViewPhotoDlg: false,
            iCurrentProvince: 0,
            iCurrentCity: 0,
            iCurrentArea: 0
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通收件人', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['收件人名A-Z', '收件人名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentReceiver = {};
        this.bCopyUserName = false;
        this.bViewPhotoID = false;

        this.objChinaCity = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        if (this.bViewPhotoID)
            return;
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        this.arrSelectedUserID = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentReceiver = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentReceiver = this.arrTableData[i];
                        this.arrSelectedUserID.push(this.arrTableData[i].id);
                        break;
                    }
                }
                break;
            }
            default: {
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedUserID.push(this.arrTableData[i].id);
                            break;
                        }
                    }
                }
                btnControlDisable = [false, true, false]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索收件人名
    ReceiverNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetReceiversData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchReceivers', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeReceiversData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了收件人类别过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.name_search !== undefined)
    //         this.refs.name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了收件人状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.email.state.value = "";
                this.refs.postcode.state.value = "";
                this.refs.address.state.value = "";
                this.refs.id_number.state.value = "";
            }
            this.objCurrentReceiver = {};
            this.SwitchCNProvince(0);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: "",
                iCurrentProvince: 0,
                iCurrentCity: 0,
                iCurrentArea: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentReceiver.name;
                this.refs.phone.state.value = this.objCurrentReceiver.phone;
                this.refs.email.state.value = this.objCurrentReceiver.email;
                this.refs.postcode.state.value = this.objCurrentReceiver.postcode;
                this.refs.address.state.value = this.objCurrentReceiver.address;
                this.refs.id_number.state.value = this.objCurrentReceiver.id_number;
            }
            this.GetCNAreaInfo(this.objCurrentReceiver.province, this.objCurrentReceiver.city);
            console.log(this.objCurrentReceiver.province, this.objCurrentReceiver.city, this.objCurrentReceiver.area)
            // this.SwitchCNProvince(this.objCurrentReceiver.province);
            // this.SwitchCNCity(this.objCurrentReceiver.city);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: "",
                iCurrentProvince: this.objCurrentReceiver.province,
                iCurrentCity: this.objCurrentReceiver.city,
                iCurrentArea: this.objCurrentReceiver.area
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        // 新建
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("收件人名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("收件人名不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                // message.warning("邮政编码不能为空！"); return;
                strPostcode = "";
            }
            if (strPostcode.trim() === "") {
                // message.warning("邮政编码不能为空！"); return;
                strPostcode = "";
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                message.warning("收货地址不能为空"); return;
            }
            if (strAddress.trim() === "") {
                message.warning("收货地址不能为空"); return;
            }
            let objNewReceiver = {
                name: strName,
                phone: strPhone,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: this.props.objUserInfo.id,
                user_name: this.props.objUserInfo.name,
                user_code: this.props.objUserInfo.storage_code,
                email: this.refs.email.state.value,
                id_number: this.refs.id_number.state.value,
                id_s3_link1: '',
                id_s3_link2: '',
                province: this.state.iCurrentProvince,
                city: this.state.iCurrentCity,
                area: this.state.iCurrentArea
            };
            this.SaveReceiverInfo(objNewReceiver, 0);
        }
        // 编辑
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("收件人名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("收件人名不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                // message.warning("邮政编码不能为空！"); return;
                strPostcode = "";
            }
            if (strPostcode.trim() === "") {
                // message.warning("邮政编码不能为空！"); return;
                strPostcode = "";
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                message.warning("收货地址不能为空"); return;
            }
            if (strAddress.trim() === "") {
                message.warning("收货地址不能为空"); return;
            }
            let objNewReceiver = {
                name: strName,
                phone: strPhone,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: this.props.objUserInfo.id,
                user_name: this.props.objUserInfo.name,
                user_code: this.props.objUserInfo.storage_code,
                email: this.refs.email.state.value,
                id_number: this.refs.id_number.state.value,
                id_s3_link1: this.objCurrentReceiver.id_s3_link1,
                id_s3_link2: this.objCurrentReceiver.id_s3_link2,
                province: this.state.iCurrentProvince,
                city: this.state.iCurrentCity,
                area: this.state.iCurrentArea
            };
            this.SaveReceiverInfo(objNewReceiver, 1);
        }
        // 删除
        if (this.state.iDlgType === 2) {
            message.loading({ content: '收件人删除中……', key: g_strMessageKey });
            axios.post('/DeleteReceiver', { arrReceiverID: this.arrSelectedUserID })
                .then(({ data }) => {
                    this.arrSelectedUserID = [];
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 收件人管理操作", action: "删除" });
                        this.GetReceiversData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        if (this.refs.file_upload1 !== undefined) {
            document.getElementById("file_upload1").value = "";
        }
        if (this.refs.file_upload2 !== undefined) {
            document.getElementById("file_upload2").value = "";
        }
        this.setState({
            bLoading: false,
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true],
            btnCopyPasteDisable: [true, true],
            selectedFile1: null,
            selectedFile2: null
        });
    };
    // 保存收件人信息
    SaveReceiverInfo = (objNewReceiver, iAction) => {
        console.log("new receiver : ", objNewReceiver);
        message.loading({ content: '正在更新收件人信息……', key: g_strMessageKey });
        // 无需更新身份证照片
        if (this.state.selectedFile1 === null && this.state.selectedFile2 === null) {
            axios.post('/UpdateReceiver', {
                objCurrentReceiver: this.objCurrentReceiver,
                objNewReceiver: objNewReceiver,
                action: iAction
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetReceiversData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        // 身份证图片需要更新时
        if (this.state.selectedFile1 !== null || this.state.selectedFile2 !== null) {
            this.singleFileUploadHandler(objNewReceiver);
        }
    }
    // 整理Users数据
    OrganizeReceiversData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strUserInfo = data[i].user_name + " / " + data[i].user_code;
            if (data[i].user_name === 'xiyouji')
                strUserInfo = data[i].user_name;
            let uiPhotoID = '还未上传';
            let bFile1 = false; let bFile2 = false;
            if (data[i].id_s3_link1 !== '' && data[i].id_s3_link1 !== null && data[i].id_s3_link1 !== undefined)
                bFile1 = true;
            if (data[i].id_s3_link2 !== '' && data[i].id_s3_link2 !== null && data[i].id_s3_link2 !== undefined)
                bFile2 = true;
            if (bFile1 && bFile2)
                uiPhotoID = (
                    <div className="ca_cn_order_photo_id_view_button" onClick={this.ViewPhotoID.bind(this, i)}>查看</div>
                );
            if ((bFile1 && !bFile2) || (!bFile1 && bFile2))
                uiPhotoID = '未上传完全';
            let iProvince = data[i].province;
            let iCity = data[i].city;
            let iArea = data[i].area;
            if (iProvince === undefined || iProvince === null || iProvince === '' || iProvince < 0)
                iProvince = 0;
            if (iCity === undefined || iCity === null || iCity === '' || iCity < 0)
                iCity = 0;
            if (iArea === undefined || iArea === null || iArea === '' || iArea < 0)
                iArea = 0;

            let objReceiverData = {
                key: i, id: data[i].id,
                name: data[i].name,
                phone: data[i].phone,
                postcode: data[i].postcode,
                address: data[i].address,
                user_info: strUserInfo,
                email: data[i].email,
                id_number: data[i].id_number,
                photo_id: uiPhotoID,
                id_s3_link1: data[i].id_s3_link1,
                id_s3_link2: data[i].id_s3_link2,
                province: iProvince,
                city: iCity,
                area: iArea
            }
            arrTableData.push(objReceiverData);
        }
        return arrTableData;
    }
    // 复制收件人名
    CopyUserName = () => {
        this.bCopyUserName = true;
        message.info('收件人名已复制！');
    }
    // 显示全部收件人
    ShowAllUsers = () => {
        this.refs.name_search.state.value = "";
        this.ReceiverNameSearch();
    }
    // 从数据库获取当前的数据结构
    GetReceiversData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetReceivers', {
            params: {
                store_id: this.props.iStoreID,
                user_id: this.props.objUserInfo.id
            }
        }).then(({ data }) => {
            console.log("GetReceivers : ", data);
            global.arrReceiverData = data;
            this.arrOgeTableData = this.OrganizeReceiversData(data);
            this.arrTableData = this.arrOgeTableData;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建收件人信息成功！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "收件人信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "收件人已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            if (this.refs.file_upload1) {
                document.getElementById("file_upload1").value = "";
            }
            if (this.refs.file_upload2) {
                document.getElementById("file_upload2").value = "";
            }
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true],
                btnCopyPasteDisable: [true, true],
                selectedFile1: null,
                selectedFile2: null
            });
        }).catch(function (error) { console.log(error); });
    }
    // 选择单个文件按钮响应
    SelectSingleFile = (index, event) => {
        if (index === 0) {
            this.setState({
                ...this.state,
                selectedFile1: event.target.files[0]
            });
        }
        else {
            this.setState({
                ...this.state,
                selectedFile2: event.target.files[0]
            });
        }
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = (objNewReceiver) => {
        let data = new FormData();
        // 正反面都更新
        if (this.state.selectedFile1 !== null && this.state.selectedFile2 !== null) {
            data.append('profileImage', this.state.selectedFile1, this.state.selectedFile1.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 1: ', fileName);
                        objNewReceiver.id_s3_link1 = fileName;
                        data = new FormData();
                        data.append('profileImage', this.state.selectedFile2, this.state.selectedFile2.name);
                        axios.post('/aws-profile-upload', data, {
                            headers: {
                                'accept': 'application/json',
                                'Accept-Language': 'en-US,en;q=0.8',
                                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                                store_id: this.props.iStoreID,
                            }
                        }).then((response) => {
                            if (200 === response.status) {
                                // If file size is larger than expected.
                                if (response.data.error) {
                                    if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                        message.warning("文件大小不能超过2Mb!");
                                    } else {
                                        console.log(response.data);
                                        message.error("文件类型不正确！");
                                    }
                                } else {
                                    // Success
                                    let fileName = response.data;
                                    console.log('fileName 2: ', fileName);
                                    objNewReceiver.id_s3_link2 = fileName;
                                    axios.post('/UpdateReceiver', {
                                        objCurrentReceiver: this.objCurrentReceiver,
                                        objNewReceiver: objNewReceiver,
                                        action: this.state.iDlgType
                                    }).then(({ data }) => {
                                        if (data.status === 1)
                                            this.GetReceiversData();
                                        else
                                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                                    }).catch(function (error) { console.log(error); });
                                }
                            }
                        }).catch((error) => {
                            // If another error
                            console.log(error);
                            message.warning(error);
                        });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
        // 只更新正面
        if (this.state.selectedFile1 !== null && this.state.selectedFile2 === null) {
            data.append('profileImage', this.state.selectedFile1, this.state.selectedFile1.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 1: ', fileName);
                        objNewReceiver.id_s3_link1 = fileName;
                        axios.post('/UpdateReceiver', {
                            objCurrentReceiver: this.objCurrentReceiver,
                            objNewReceiver: objNewReceiver,
                            action: this.state.iDlgType
                        }).then(({ data }) => {
                            if (data.status === 1)
                                this.GetReceiversData();
                            else
                                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        }).catch(function (error) { console.log(error); });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
        // 只更新反面
        if (this.state.selectedFile1 === null && this.state.selectedFile2 !== null) {
            data.append('profileImage', this.state.selectedFile2, this.state.selectedFile2.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 2: ', fileName);
                        objNewReceiver.id_s3_link2 = fileName;
                        axios.post('/UpdateReceiver', {
                            objCurrentReceiver: this.objCurrentReceiver,
                            objNewReceiver: objNewReceiver,
                            action: this.state.iDlgType
                        }).then(({ data }) => {
                            if (data.status === 1)
                                this.GetReceiversData();
                            else
                                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        }).catch(function (error) { console.log(error); });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
    };
    // 拷贝一行数据
    CopyReceiver = () => {
        this.objCopiedReceiver = {};
        this.objCopiedReceiver = Object.assign({}, this.objCurrentReceiver);
        this.setState({
            ...this.state,
            btnCopyPasteDisable: [true, false]
        });
        const hide = message.success("已复制收件人：<" + this.objCopiedReceiver.name + ">");
        setTimeout(hide, 2000);
    }
    // 粘贴一行数据
    PasteReceiver = () => {
        let objNewReceiver = {
            name: this.objCopiedReceiver.name,
            phone: this.objCopiedReceiver.phone,
            address: this.objCopiedReceiver.address,
            postcode: this.objCopiedReceiver.postcode,
            store_id: this.props.iStoreID,
            user_id: 0,
            user_name: this.objCopiedReceiver.user_name,
            user_code: this.objCopiedReceiver.user_code,
            id_number: this.objCopiedReceiver.id_number,
            id_s3_link1: this.objCopiedReceiver.id_s3_link1,
            id_s3_link2: this.objCopiedReceiver.id_s3_link2,
        };
        this.SaveReceiverInfo(objNewReceiver, 0);
    }
    // 查看身份证按钮相应
    ViewPhotoID = (index) => {
        this.bViewPhotoID = true;
        this.objCurrentReceiver = this.arrTableData[index];
        this.setState({
            ...this.state,
            bViewPhotoDlg: true
        });
    }
    // 关闭查看身份证窗口
    CloseViewPhotoDlg = () => {
        this.bViewPhotoID = false;
        this.setState({
            ...this.state,
            bViewPhotoDlg: false
        });
    }
    // 获取某个省份的城市信息
    GetCNCityInfo = (province) => {
        this.objChinaCity.cities = GetCNCity(this.objChinaCity.provinces[province]);
        this.objChinaCity.areas = GetCNArea(this.objChinaCity.provinces[province], this.objChinaCity.cities[0]);
    }
    // 获取某个省份的城市信息
    GetCNAreaInfo = (province, city) => {
        this.objChinaCity.cities = GetCNCity(this.objChinaCity.provinces[province]);
        this.objChinaCity.areas = GetCNArea(this.objChinaCity.provinces[province], this.objChinaCity.cities[city]);
    }
    // 切换省份
    SwitchCNProvince = (index) => {
        this.GetCNAreaInfo(index, 0);
        this.setState({
            ...this.state,
            iCurrentProvince: index,
            iCurrentCity: 0,
            iCurrentArea: 0
        })
    }
    // 切换城市
    SwitchCNCity = (index) => {
        this.GetCNAreaInfo(this.state.iCurrentProvince, index);
        this.setState({
            ...this.state,
            iCurrentCity: index,
            iCurrentArea: 0
        })
    }
    // 切换地区
    SwitchCNArea = (index) => {
        this.setState({
            ...this.state,
            iCurrentArea: index
        })
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        console.log("GetReceivers UI: ");
        this.GetReceiversData();
        this.objChinaCity.provinces = GetCNProvince();
        this.GetCNCityInfo(0, 0);
    }
    // 渲染函数
    render() {

        let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
        if (this.state.btnCopyPasteDisable[0])
            strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
        /* 收件人管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllUsers}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索收件人名/代码" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ReceiverNameSearch}>检索
                    </Button>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                    <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                        onClick={this.CopyReceiver} disabled={this.state.btnCopyPasteDisable[0]}>
                        <Icon type="copy" />复制</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteReceiver} disabled={this.state.btnCopyPasteDisable[1]}>
                        <Icon type="edit" /> 粘贴</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 40
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 收件人`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 收件人信息编辑弹窗
            let uiPhotoID1 = null;
            if (this.objCurrentReceiver.id_s3_link1 !== undefined && this.objCurrentReceiver.id_s3_link1 !== null && this.objCurrentReceiver.id_s3_link1 !== '') {
                let objFile1 = JSON.parse(this.objCurrentReceiver.id_s3_link1);
                uiPhotoID1 = (
                    <img src={objFile1.location} alt='' style={{ minWidth: '40%', maxWidth: '40%', marginRight: '12%' }} />
                );
            }
            let uiPhotoID2 = null;
            if (this.objCurrentReceiver.id_s3_link2 !== undefined && this.objCurrentReceiver.id_s3_link2 !== null && this.objCurrentReceiver.id_s3_link2 !== '') {
                let objFile2 = JSON.parse(this.objCurrentReceiver.id_s3_link2);
                uiPhotoID2 = (
                    <img src={objFile2.location} alt='' style={{ minWidth: '40%', maxWidth: '40%' }} />
                );
            }
            // 省份下拉菜单
            const menuCNProvinces = (
                <Menu>
                    {this.objChinaCity.provinces.map((province, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCNProvince.bind(this, index)}>
                                {province}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 城市下拉菜单
            const menuCNCities = (
                <Menu>
                    {this.objChinaCity.cities.map((city, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCNCity.bind(this, index)}>
                                {city}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 地区下拉菜单
            const menuCNAreas = (
                <Menu>
                    {this.objChinaCity.areas.map((area, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCNArea.bind(this, index)}>
                                {area}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiModal = (
                <Modal title="编辑收件人" width={940} visible={this.state.bShowDlg} centered
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>姓名：</div>
                        <Input className="user_edit_info_input" ref="name" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentReceiver.name} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>电话：</div>
                        <Input className="user_edit_info_input" ref="phone" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentReceiver.phone} />
                        <div className="user_edit_info_title" >邮箱：</div>
                        <Input className="user_edit_info_input" ref="email"
                            defaultValue={this.objCurrentReceiver.email} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>省份：</div>
                        <Dropdown overlay={menuCNProvinces} trigger={['click']} placement="bottomLeft"
                            overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto', marginRight: '25px' }}>
                            <Button className="transit_type_dropdown_button" style={{ marginRight: '25px' }}>
                                <div className="order_details_dropdown_text">{this.objChinaCity.provinces[this.state.iCurrentProvince]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>城市：</div>
                        <Dropdown overlay={menuCNCities} trigger={['click']} placement="bottomLeft"
                            overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto', marginRight: '25px' }}>
                            <Button className="transit_type_dropdown_button" style={{ marginRight: '25px' }}>
                                <div className="order_details_dropdown_text">{this.objChinaCity.cities[this.state.iCurrentCity]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>地区：</div>
                        <Dropdown overlay={menuCNAreas} trigger={['click']} placement="bottomLeft"
                            overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.objChinaCity.areas[this.state.iCurrentArea]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        {/* <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>省份：</div>
                        <Input className="user_edit_info_input" ref="name" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentReceiver.name} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>城市：</div>
                        <Input className="user_edit_info_input" ref="phone" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentReceiver.phone} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>地区：</div>
                        <Input className="user_edit_info_input" ref="phone"
                            defaultValue={this.objCurrentReceiver.phone} /> */}
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>地址：</div>
                        <Input className="user_edit_info_input_long" ref="address" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentReceiver.address} />
                        <div className="user_edit_info_title" >邮编：</div>
                        <Input className="user_edit_info_input" ref="postcode"
                            defaultValue={this.objCurrentReceiver.postcode} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >身份证：</div>
                        <Input className="user_edit_info_input_full" ref="id_number"
                            defaultValue={this.objCurrentReceiver.id_number} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >正面：</div>
                        <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                            ref='file_upload1' id="file_upload1" onChange={this.SelectSingleFile.bind(this, 0)}
                            style={{ marginRight: '27.5px' }} />
                        <div className="user_edit_info_title" >反面：</div>
                        <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                            ref='file_upload2' id="file_upload2" onChange={this.SelectSingleFile.bind(this, 1)} />
                    </div>
                    <div className="user_edit_info_row">
                        {uiPhotoID1} {uiPhotoID2}
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                let uiTips = null;
                if (this.arrSelectedUserID.length > 0) {
                    uiTips = '确定删除选中的收件人？';
                } else {
                    uiTips = (
                        <div>{this.objCurrentReceiver.name}
                            <br /><br />确定删除选中的收件人？
                        </div>
                    );
                }
                uiModal = (
                    <Modal width={500} title={"删除收件人信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            {uiTips}
                        </div>
                    </Modal>
                );
            }
            // 查看身份证弹窗
            let uiViewPhotoDlg = null;
            if (this.objCurrentReceiver.id_s3_link1 !== undefined && this.objCurrentReceiver.id_s3_link1 !== null && this.objCurrentReceiver.id_s3_link1 !== '') {
                let objFile1 = JSON.parse(this.objCurrentReceiver.id_s3_link1);
                uiPhotoID1 = (
                    <img src={objFile1.location} alt='' style={{ width: '95%', marginBottom: '20px' }} />
                );
            }
            if (this.objCurrentReceiver.id_s3_link2 !== undefined && this.objCurrentReceiver.id_s3_link2 !== null && this.objCurrentReceiver.id_s3_link2 !== '') {
                let objFile2 = JSON.parse(this.objCurrentReceiver.id_s3_link2);
                uiPhotoID2 = (
                    <img src={objFile2.location} alt='' style={{ width: '95%' }} />
                );
            }
            if (this.state.bViewPhotoDlg) {
                uiViewPhotoDlg = (
                    <Modal width={650} title={"查看身份证"} visible={this.state.bViewPhotoDlg} onCancel={this.CloseViewPhotoDlg} footer={null} centered>
                        <div className="ca_cn_order_view_photo_dlg">
                            {uiPhotoID1}{uiPhotoID2}
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {uiViewPhotoDlg}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StoreReceiverManager;
