import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Icon from 'antd/es/icon';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Drawer from 'antd/es/drawer';
import Modal from 'antd/es/modal';
import Radio from 'antd/es/radio';
import Collapse from 'antd/es/collapse';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import TextareaItem from 'antd-mobile/es/textarea-item';
import AdsCarousel from './UI_Module/AdsCarousel.js';
import message from 'antd/es/message';

import '../../styles/ClientSide/store_page.css';
import '../../styles/ClientSide/store_pickup_page.css';
import '../../styles/icon_image.css';
import '../../styles/ClientSide/UI_Module/ticket_list.css';
import '../../styles/ClientSide/stripe.css';
import '../../styles/color_set.css';

import PaymentMethodDlg from './UI_Module/PaymentMethodDlg.js'
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { GetItemsID } from "../../functions/DataStatistic.js"
import { StripePriceCalculate } from "../../functions/DataStatistic.js"

const mapStyles = { width: '83vw', height: '60vw', borderRadius: '2.5vw' };

const { Panel } = Collapse;

class StoreRetail extends Component {
    constructor(props) {
        super();
        let iTab = 0;
        let bEnglish = false;
        let strParams = window.location.search;
        if (strParams.indexOf('?') >= 0) {
            strParams = strParams.substring(strParams.indexOf('?') + 1);
            if (strParams.indexOf('tab') >= 0) {
                iTab = parseInt(strParams.split('&')[0].split('=')[1], 10);
                if (iTab !== 0 && iTab !== 1 && iTab !== 2) {
                    iTab = 0;
                }
                if (strParams.indexOf('lang') >= 0) {
                    bEnglish = strParams.split('&')[1].split('=')[1];
                    if (bEnglish === 'cn')
                        bEnglish = false;
                    else
                        bEnglish = true;
                }
            }
        }
        this.state = {
            bLoading: false,
            bItemListLoading: true,
            iCurrentTab: iTab,
            iCurrentItemType: 0,
            objStorePos: { lat: props.objStoreInfo.latitude_x, lng: props.objStoreInfo.longitude_y },
            strFormattedAddress: "Richmond Center",
            arrItemsData: [],
            bBudgetUpdate: true,
            bOrderDetails: false,
            bBudgetDetails: false,
            bLogin: true,
            bStaring: false,
            bPaymentDlg: false,
            bEnglish: bEnglish,
            bMainMenu: false,
            bItemDetails: false,
            arrItemTypeOpen: ['0'],
            objOption: { iOptionType: 0, iOptionIndex: 0 },
            iCurrentItemNum: 1,
            strSpecialRequests: "",
            bSelectPaymentDlg: false,
            bPaymentResultDlg: false,
            iPaymentResult: 0,
            iUserCenterReminder: 0,
            iContactInfoDlg: -1,
            bContactInfoDlg: false,
            bLoginReminderDlg: false,
            iDeliveryWay: 0,
            iModifyAddress: 0,
            iCurrentState: 1,
            bModifyCouponCode: false
        };
        this.audioNewOrder = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1603042409/0-%E9%B2%B8%E5%9B%BEWhaleMap/NewMessage_glprkc.mp3');
        this.audioNewOrder.load();

        this.arrItemTypeData = [
            { id: 0, store_id: this.iStoreID, type_name: "全部", type_order: 0, description: "" }
        ];

        this.arrItemsData = [];
        this.arrTicketsData = -1;
        this.arrTicketsStatus = [];
        this.arrOpenTime = [
            "周一 09:00am - 09:00pm",
            "周二 09:00am - 09:00pm",
            "周三 09:00am - 09:00pm",
            "周四 09:00am - 09:00pm",
            "周五 09:00am - 09:00pm",
            "周六 09:00am - 09:00pm",
            "周日 09:00am - 09:00pm"
        ];
        this.arrOpenTime = props.objStoreInfo.open_time.split(',');
        this.objStoreInfo = props.objStoreInfo;
        this.iCurrentDate = 0;
        this.arrTabTriggered = [false, false, false];
        this.arrTicketType = ["折扣券", "代金券", "兑换券"];
        this.objCart = {
            arrBudgetItems: [], iItemTotalNum: 0, fItemTotalPrice: 0.0
        };
        this.strCurrentUserCode = "";
        this.objUserInfo = -1;
        this.bStarred = false;
        this.bOrderDetails = false;  // 从地址页返回商家页时如果订单详情之前是打开的，用此参数作判断标志，等用户信息获取完毕后在打开订单详情
        this.iCurrentTab = 0;

        this.arrMainMenu = [
            { cn: "关于商家", en: "About", icon: "shop" },
            { cn: "浏览菜单", en: "Menu", icon: "read" },
            // { cn: "购物车", en: "Cart", icon: "shopping-cart" },
            { cn: "个人中心", en: "Profile", icon: "user" },
            // { cn: "联系我们", en: "Contact", icon: "phone" },
            { cn: "中文→English", en: "English→中文", icon: "message" }
        ]
        this.arrMainTab = [
            { cn: "商品下单", en: "Order" },
            { cn: "商家信息", en: "About" }
        ];
        this.arrItemTypeOpen = [];
        this.objCurrentItem = -1;
        this.arrCurrentOptionsCN = [];
        this.arrCurrentOptionsEN = [];
        this.fCurrentItemTotalPrice = 0.0;
        this.fCartTotalPrice = 0.0;
        this.arrCurrentCart = [];
        this.iConfirmedPaymentMethod = 0;
        this.strCurrentLast4 = "";
        this.strCurrentCardBrand = "";
        this.objCardInfo = {};
        this.arrCards = [];
        this.strOrderID = "";
        this.bModifyItem = false;
        this.iCurrentModifyItem = 0;
        this.strDeliveryAddress = "";
        this.bDeleting = false;

        this.arrPaymentMethodCn = ["选择支付方式", "信用卡/借记卡", "微信", "支付宝", "现金"];
        this.arrPaymentMethodEn = ["Select Payment Method", "Credit/Debit Card", "WeChat", "AliPay", "Cash"];
        this.arrPaymentMethod = this.arrPaymentMethodCn;
        if (this.state.bEnglish)
            this.arrPaymentMethod = this.arrPaymentMethodEn;

        if (props.objStoreInfo.store_name.indexOf(',') >= 0) {
            if (this.state.bEnglish)
                document.title = props.objStoreInfo.store_name.split(',')[1];
            else
                document.title = props.objStoreInfo.store_name.split(',')[0];
        } else {
            document.title = props.objStoreInfo.store_name;
        }

        this.arrStoreList0 = [76, 81, 112];  // 卡片；物流邮寄
        this.arrStoreList1 = [72, 79];   // 现金; 自提
        this.arrStoreList2 = [82];    // 英文优先

        this.arrItemSearch = [];
        this.bNoSearhResult = false;

        this.iCurrentItemInventory = 0;
        this.bSoldOut = false;
        this.bNotEnough = false;
        this.arrShippingStates = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland', 'Northwest Territories', 'Nova Scotia', 'Nunavat', 'Ontario', 'Prince Edward Island', 'Quebec Saskatchewan', 'Yukon'];
        this.arrShippingStatesShort = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PEI', 'QC', 'YT'];
        this.objShippingAddress = { contact_name: '', phone: '', country: 'Canada', state: 'BC', state_index: 1, city: '', postal_code: '', street1: '', street2: '', street3: '' };
        this.objShippingWeightInfo = {
            weight: 0.0,
            length: 0.0,
            width: 0.0,
            height: 0.0
        }
        this.fShippingFee = 0.0;
        this.strCouponCode = '';
        this.objCouponInfo = {};
        this.bAllDiscount = false;
    }
    // 获取商家位置信息，Tab2响应
    GetStoreLocation = (strAddress) => {
        let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        axios.get(strUrl)
            .then((response) => {
                this.setState({
                    ...this.state,
                    bLoading: false,
                    objStorePos: response.data.results[0].geometry.location,
                    strFormattedAddress: response.data.results[0].formatted_address
                });
            }).catch(err => {
                console.log(err) //Axios entire error message
                console.log(err.response.data.error) //Google API error message 
            });
    }
    // 切换商品类别
    SwitchItemType = (iTypeIndex) => {
        this.setState({
            ...this.state,
            bItemListLoading: true
        });
        // DataStatistic
        UserActiveUpdate(12, { active: "切换商品分类", type: iTypeIndex, name: this.arrItemTypeData[iTypeIndex].type_name });
        // 获取所有商品信息
        let strType = '%';
        if (iTypeIndex !== 0)
            strType += this.arrItemTypeData[iTypeIndex].id.toString() + '%';
        axios('/GetItems', { params: { store_id: this.iStoreID, type: strType } })
            .then(({ data }) => {
                this.arrItemsData = data;
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bItemListLoading: false,
                    iCurrentItemType: iTypeIndex,
                });
            });
    }
    // 排队按钮响应
    LineUpClicked() {
        console.log("line up btn clicked...");
    }
    // 日期转换为索引
    DateToIndex(strDate) {
        switch (strDate) {
            case 'Mon': {
                this.iCurrentDate = 0;
                break;
            }
            case 'Tue': {
                this.iCurrentDate = 1;
                break;
            }
            case 'Wed': {
                this.iCurrentDate = 2;
                break;
            }
            case 'Thu': {
                this.iCurrentDate = 3;
                break;
            }
            case 'Fri': {
                this.iCurrentDate = 4;
                break;
            }
            case 'Sat': {
                this.iCurrentDate = 5;
                break;
            }
            case 'Sun': {
                this.iCurrentDate = 6;
                break;
            }
            default: {
                this.iCurrentDate = 0;
                break;
            }
        }
    }
    // 标签页被点击的响应
    TabChanged = (activeKey) => {
        let iTab = parseInt(activeKey, 10);
        this.setState({
            ...this.state,
            iCurrentTab: iTab
        });
        this.iCurrentTab = iTab;
        // DataStatistic
        UserActiveUpdate(8, { active: "商家标签页点击", tab: iTab });

        if (!this.arrTabTriggered[iTab]) {
            this.arrTabTriggered[iTab] = true;
            switch (iTab) {
                case 0: {
                    // 4, 获取所有商品信息
                    this.GetStoreItems();
                    this.SwitchItemType(0);
                    break;
                }
                case 1: {
                    // 5, 获取所有优惠券信息
                    this.GetStoreTickets();
                    break;
                }
                case 2: {
                    // 6. 获取商家位置信息
                    this.GetStoreLocation(this.objStoreInfo.address);
                    break;
                }
                default: break;
            }
        } else {
            if (iTab === 0) {
                this.SwitchItemType(this.state.iCurrentItemType);
            }
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                console.log("User Code Exist Check: ", data);
                if (data.status === 1) {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.collect_stores === null ||
                        this.objUserInfo.collect_stores === "") {
                        this.bStarred = false;
                    } else {
                        if (this.objUserInfo.collect_stores.indexOf(this.objStoreInfo.id.toString())
                            === -1)
                            this.bStarred = false;
                        else
                            this.bStarred = true;
                    }
                    if (this.state.objStorePos.lat === null || this.state.objStorePos.lng === null)
                        this.GetStoreLocation(this.objStoreInfo.address);
                    this.setState({
                        ...this.state,
                        bOrderDetails: this.bOrderDetails,
                        bLogin: true
                    });
                } else {
                    this.objUserInfo = -1;
                    this.setState({
                        ...this.state,
                        bLogin: false
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 收藏按钮点击响应
    StarBtnClicked = () => {
        if (this.state.bStaring)
            return;
        else
            this.setState({ ...this.state, bStaring: true });
        // 还未登录
        if (this.objUserInfo === -1) {
            message.info("请先注册/登录！");
            // message.info({ content: "请先注册/登录！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLogin: false,
                bStaring: false
            });
        } else {  // 已有用户信息
            // 还未收藏
            if (!this.bStarred) {
                if (this.objUserInfo.collect_stores === null)
                    this.objUserInfo.collect_stores = "";
                if (this.objUserInfo.collect_stores === "")
                    this.objUserInfo.collect_stores = this.objStoreInfo.id.toString();
                else
                    this.objUserInfo.collect_stores += "," + this.objStoreInfo.id.toString();
            } else {  // 已收藏
                let arrCollectStores = this.objUserInfo.collect_stores.split(',');
                let strNewCollects = "";
                for (let i = 0; i < arrCollectStores.length; i++) {
                    if (arrCollectStores[i] !== this.objStoreInfo.id.toString()) {
                        if (strNewCollects === "")
                            strNewCollects += arrCollectStores[i].toString();
                        else
                            strNewCollects += "," + arrCollectStores[i].toString();
                    }
                }
                this.objUserInfo.collect_stores = strNewCollects;
            }
            // 更新用户表中的商家收藏信息
            axios.post('/UpdateUserCollectStores', { objUserInfo: this.objUserInfo })
                .then(({ data }) => {
                    if (data.status === 1) {
                        if (!this.bStarred) {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "关注商家" });
                            message.success("已关注！");
                            // message.success({ content: "已关注！", key: g_strMessageKey, duration: 2 });
                        } else {
                            // DataStatistic
                            UserActiveUpdate(3, { active: "取关商家" });
                            message.info("已取关！");
                            // message.success({ content: "已取关！", key: g_strMessageKey, duration: 2 });
                        }
                        this.bStarred = !this.bStarred;
                        this.setState({
                            ...this.state,
                            bLogin: true,
                            bStaring: false
                        });
                    } else {
                        message.warning("关注失败，请重新登录后尝试！");
                        // message.warning({
                        //     content: "关注失败，请重新登录后尝试！",
                        //     key: g_strMessageKey, duration: 2
                        // });
                        this.setState({
                            ...this.state,
                            bStaring: false
                        });
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 预算计算
    RefreshBudget = (arrBudgetItems, iItemTotalNum, fItemTotalPrice) => {

        if (iItemTotalNum <= 0) {
            iItemTotalNum = 0;
            fItemTotalPrice = 0.0;
        }
        this.objCart = {
            arrBudgetItems: arrBudgetItems,
            iItemTotalNum: iItemTotalNum,
            fItemTotalPrice: fItemTotalPrice
        }
        if (iItemTotalNum === 0)
            cookie.save('wm_user_cart', "", { path: '/' });
        else
            cookie.save('wm_user_cart', this.objCart, { path: '/' });
        this.setState({
            ...this.state,
            bBudgetUpdate: true
        });
    }
    // 预算详情抽屉控制
    ShowBudgetDetails = (bShow, e) => {
        if (bShow) {
            // DataStatistic
            let arrItems = GetItemsID(this.objCart);
            UserActiveUpdate(15, { active: "打开购物车", arrItems: arrItems });
        }
        this.setState({
            bBudgetDetails: bShow,
        });
    };
    // 打开订单详情
    ShowOrderDetails = () => {
        // 检查商品数量
        if (this.objCart.iItemTotalNum <= 0 && this.objCart.fItemTotalPrice <= 0.0) {
            message.info("还未添加任何商品……");
        } else {
            // 判断登录状态
            if (this.state.bLogin) {
                // 电商则打开订单详情，允许下单支付
                if (this.iStoreType === 1) {
                    // DataStatistic
                    let arrItems = GetItemsID(this.objCart);
                    UserActiveUpdate(16, { active: "打开订单详情", arrItems: arrItems });
                    this.UserCodeCheck();
                    this.bOrderDetails = true;
                    this.setState({
                        bOrderDetails: true
                    });
                }
                // 黄页商家则给出提示
                else {
                    message.info("该商家只提供商品/服务的价格/预算查询");
                }
            } else {
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            }
        }
    };
    // 收起订单详情
    HideOrderDetails = () => {
        // this.bOrderDetails = false;
        this.setState({
            bOrderDetails: false,
        });
    }
    // 商家详情区域点击
    StoreInfoAreaClicked = (iInfo) => {
        // DataStatistic
        UserActiveUpdate(9, { active: "查看商家详情", info: iInfo });
    }
    // 点击了用户按钮（仅限于特制的独立商家）
    UserIconClicked = () => {
        window.location.href = '/webapp/user?tab=3&store=' + this.iStoreID.toString();
    }
    // Cancle Payment / Close Payment Dlg
    CancelPayment = () => {
        this.setState({
            ...this.state,
            bPaymentDlg: false
        });
    }
    // 加号按钮点击响应
    AddBtnClicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            let bExistItem = false;
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num++;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        bExistItem = true;
                        break;
                    }
                }
            }
            if (!bExistItem) {
                let objItem = { num: 0, item: {} };
                objItem.num = 1; objItem.item = item;
                this.objCart.arrBudgetItems.push(objItem);
                // DataStatistic
                UserActiveUpdate(14, { active: "添加商品到购物车", item: item.name, num: 1 });
            }
            this.objCart.iItemTotalNum++;
            this.objCart.fItemTotalPrice += parseFloat(strPrice, 10);
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 减号按钮点击响应
    RemoveBtnCLicked = (item) => {
        let strPrice = item.price;
        if (strPrice !== "" && strPrice !== "0") {
            if (this.objCart.arrBudgetItems.length > 0) {
                for (let i = 0; i < this.objCart.arrBudgetItems.length; i++) {
                    if (item.id === this.objCart.arrBudgetItems[i].item.id) {
                        this.objCart.arrBudgetItems[i].num--;
                        // DataStatistic
                        UserActiveUpdate(14, { active: "从购物车移除商品", item: item.name, num: this.objCart.arrBudgetItems[i].num });
                        this.objCart.iItemTotalNum--;
                        this.objCart.fItemTotalPrice -= parseFloat(strPrice, 10);
                        if (this.objCart.arrBudgetItems[i].num <= 0) {
                            this.objCart.arrBudgetItems.splice(i, 1);
                        }
                        break;
                    }
                }
            }
            this.RefreshBudget(this.objCart.arrBudgetItems, this.objCart.iItemTotalNum, this.objCart.fItemTotalPrice);
        }
    }
    // 清空购物车
    ClearCart = () => {
        this.RefreshBudget([], 0, 0);
    }
    // 电商顶部栏Logo按钮点击
    MouthingLogoClicked = () => {
        // DataStatistic
        UserActiveUpdateBack(19, { active: "返回Mouthing平台首页" }, this.JumpToHome);
    }
    // 从电商页返回首页
    JumpToHome = () => {
        window.location.href = '/webapp/home';
    }

    /******** 自提外卖新增函数 ********/
    // 打开主菜单
    OpenMainMenu = (bShow) => {
        this.setState({
            ...this.state,
            bMainMenu: bShow
        });
    }
    // 点击了菜单
    ClickMenu = (index) => {
        // 商家信息
        if (index === 0) {
            this.SwitchMainTab(0);
            // this.setState({
            //     ...this.state,
            //     bMainMenu: false
            // });
        }
        // 查看菜单
        if (index === 1) {
            this.SwitchMainTab(1);
        }
        // 个人中心
        if (index === 2) {
            let strLang = "cn";
            if (this.state.bEnglish)
                strLang = "en";
            window.location.href = '/webapp/user_profile?tab=3&store=' + this.props.objStoreInfo.id + '&lang=' + strLang;
        }
        // 切换中英文
        if (index === 3) {
            if (this.state.bEnglish)
                document.title = this.props.objStoreInfo.store_name.split(',')[0];
            else
                document.title = this.props.objStoreInfo.store_name.split(',')[1];
            this.setState({
                ...this.state,
                bMainMenu: false,
                bEnglish: !this.state.bEnglish
            });
        }
        // 查看购物车
        if (index === 4) {
            this.ShippingFeeCalculate();
            this.setState({
                ...this.state,
                bViewCart: true,
                bMainMenu: false
            })
        }
    }
    // 切换主按钮
    SwitchMainTab = (iTab) => {
        if (iTab === 1) {
            this.setState({
                ...this.state,
                arrItemTypeOpen: [],
                iCurrentTab: iTab,
                bMainMenu: false,
                bViewCart: false
            });
            this.arrItemTypeOpen = [];
        } else {
            this.bNoSearhResult = false;
            this.arrItemSearch = [];
            this.setState({
                ...this.state,
                iCurrentTab: iTab,
                bMainMenu: false,
                bViewCart: false
            });
        }
    }
    // 营业时间格式处理
    StoreHoursFormatProcess = (strOpenTime) => {
        let objHour = { day: "", hour: "" };
        let strDay = strOpenTime.split(' ')[0];
        strDay = this.CnToEnDay(strDay);
        let strStartHour = strOpenTime.split(' ')[1];
        let strEndHour = strOpenTime.split(' ')[4];
        let strHour = strStartHour + " am - " + strEndHour + " pm";
        if (strStartHour === undefined || strStartHour === null || strStartHour === "" ||
            strEndHour === undefined || strEndHour === null || strEndHour === "") {
            strHour = "不营业";
            if (this.state.bEnglish)
                strHour = "Closed";
        }
        objHour.day = strDay; objHour.hour = strHour;
        return objHour;
    }
    // 中文日转为英文日
    CnToEnDay = (strDay) => {
        let strEnDay = strDay;
        if (this.state.bEnglish) {
            switch (strDay) {
                case "周一": strEnDay = "Mon"; break;
                case "周二": strEnDay = "Tue"; break;
                case "周三": strEnDay = "Wed"; break;
                case "周四": strEnDay = "Thu"; break;
                case "周五": strEnDay = "Fri"; break;
                case "周六": strEnDay = "Sat"; break;
                case "周日": strEnDay = "Sun"; break;
                default: break;
            }
        }
        return strEnDay;
    }
    // 展开/合上某个菜单（商品类别）
    ItemTypeOpen = (key) => {
        this.arrItemTypeOpen = key;
        this.setState({
            ...this.state,
            arrItemTypeOpen: key
        });
    }
    // 处理商品的Modifier (options)
    ItemOptionsProcess = (strOptions, arrSelectedOptions) => {
        if (arrSelectedOptions === -1)
            this.bModifyItem = false;
        else
            this.bModifyItem = true;
        this.arrCurrentOptionsCN = [];
        this.arrCurrentOptionsEN = [];
        if (strOptions === null || strOptions === "") {
            return;
        }
        console.log("选项脚本：", strOptions);
        let arrOptionsType = strOptions.split('\n');
        for (let i = 0; i < arrOptionsType.length; i++) {
            let objOptionInfoCN = {};
            let objOptionInfoEN = {};
            // 选项标题
            let strOptionTypeTitle = arrOptionsType[i].split('=')[0];
            // 多选几
            let strOptionSelectMethod = arrOptionsType[i].split('#')[1];
            objOptionInfoCN.select_method = parseInt(strOptionSelectMethod, 10);
            objOptionInfoEN.select_method = parseInt(strOptionSelectMethod, 10);
            // 必选项
            if (strOptionTypeTitle.indexOf('*') >= 0) {
                strOptionTypeTitle = strOptionTypeTitle.split('*')[1];
                objOptionInfoCN.bRequired = true;
                objOptionInfoEN.bRequired = true;
            } else {
                objOptionInfoCN.bRequired = false;
                objOptionInfoEN.bRequired = false;
            }
            objOptionInfoCN.title = strOptionTypeTitle.split(',')[0];
            objOptionInfoEN.title = strOptionTypeTitle.split(',')[1];
            /******** 选项内容 ********/
            let strOptionContents = (arrOptionsType[i].split('=')[1]).split('#')[0];
            let arrOptions = [];
            if (strOptionContents.indexOf('/') >= 0) {
                arrOptions = strOptionContents.split('/');
            } else {
                arrOptions = [strOptionContents];
            }
            // 获取选项内容
            objOptionInfoCN.contents = [];
            objOptionInfoEN.contents = [];
            for (let j = 0; j < arrOptions.length; j++) {
                let objOptionContentCN = {};
                let objOptionContentEN = {};
                let strOptionName = arrOptions[j].split('$')[0];
                let strPrice = arrOptions[j].split('$')[1];
                objOptionContentCN.name = strOptionName.split(',')[0];
                objOptionContentCN.price = strPrice;
                objOptionContentCN.active = false;
                objOptionContentEN.name = strOptionName.split(',')[1];
                objOptionContentEN.price = strPrice;
                objOptionContentEN.active = false;
                if (this.bModifyItem && arrSelectedOptions[i] !== -1 && arrSelectedOptions[i].name.split(',')[0] === objOptionContentCN.name) {
                    objOptionContentCN.active = true;
                    objOptionContentEN.active = true;
                }
                objOptionInfoCN.contents.push(objOptionContentCN);
                objOptionInfoEN.contents.push(objOptionContentEN);
            }
            if (objOptionInfoCN.select_method === 1 && objOptionInfoCN.bRequired && arrSelectedOptions === -1) {
                objOptionInfoCN.contents[0].active = true;
                objOptionInfoEN.contents[0].active = true;
            }

            this.arrCurrentOptionsCN.push(objOptionInfoCN);
            this.arrCurrentOptionsEN.push(objOptionInfoEN);
        }
    }
    // 在菜单中点击了一个商品
    ClickAnItem = (item) => {
        this.ItemOptionsProcess(item.options, -1);
        this.objCurrentItem = item;
        console.log("Click An Item: ", this.objCurrentItem);
        if (this.objCurrentItem.view !== null && !isNaN(parseInt(this.objCurrentItem.view, 10))) {
            if (parseInt(this.objCurrentItem.view, 10) < 0)
                this.objCurrentItem.view = 0;
        } else {
            this.objCurrentItem.view = 0;
        }
        this.objCurrentItem.view++;
        axios.post('/UpdateItemView', { item: this.objCurrentItem })
            .then(({ data }) => {
            });
        this.setState({
            ...this.state,
            iCurrentItemNum: 1,
            bItemDetails: true,
            strSpecialRequests: ""
        });
    }
    // 关闭商品详情页
    CloseItemDetails = () => {
        let bViewCart = false;
        if (this.bModifyItem) {
            bViewCart = true;
        }
        this.setState({
            ...this.state,
            bViewCart: bViewCart,
            bItemDetails: false
        });
    }
    // 点击了Modifier选项
    ItemModifierOptionCLick = (iOptionType, iOptionIndex, bRadio) => {
        if (bRadio) {
            for (let i = 0; i < this.arrCurrentOptionsCN[iOptionType].contents.length; i++) {
                this.arrCurrentOptionsCN[iOptionType].contents[i].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[i].active = false;
            }
            this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = true;
            this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = true;
        } else {

            if (this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active) {
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = false;
            } else {
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = true;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = true;
            }
            let iSelectNum = 0;
            for (let i = 0; i < this.arrCurrentOptionsCN[iOptionType].contents.length; i++) {
                if (this.arrCurrentOptionsCN[iOptionType].contents[i].active)
                    iSelectNum++;
            }
            if (iSelectNum > this.arrCurrentOptionsCN[iOptionType].select_method) {
                let strRemind = "最多只能选 " + this.arrCurrentOptionsCN[iOptionType].select_method + " 项 ！";
                if (this.state.bEnglish)
                    strRemind = "Max select " + this.arrCurrentOptionsCN[iOptionType].select_method;
                message.warning(strRemind);
                this.arrCurrentOptionsCN[iOptionType].contents[iOptionIndex].active = false;
                this.arrCurrentOptionsEN[iOptionType].contents[iOptionIndex].active = false;
                return;
            }

        }
        this.setState({
            ...this.state,
            objOption: { iOptionType: iOptionType, iOptionIndex: iOptionIndex }
        });
    }
    // 点击了调整商品数量按钮
    ItemNumChange = (bAdd) => {
        let iItemNum = this.state.iCurrentItemNum;
        if (bAdd) {
            iItemNum++;
            if (iItemNum >= 999)
                iItemNum = 999;
        } else {
            iItemNum--;
            if (iItemNum <= 1)
                iItemNum = 1;
        }
        this.setState({
            ...this.state,
            iCurrentItemNum: iItemNum
        });
    }
    // 备注变更
    SpecialRequestsInput = (e) => {
        this.setState({
            ...this.state,
            strSpecialRequests: e
        })
    }
    // 点击了添加到购物车按钮
    AddToCartButtonClicked = () => {
        if (this.bSoldOut) {
            message.warning('已售罄，无法下单该商品！');
            return;
        }
        if (this.state.iCurrentItemNum > this.iCurrentItemInventory) {
            message.warning('库存不足，请检查下单数量！');
            return;
        }
        if (this.props.objUserInfo === -1) {
            this.setState({
                ...this.state,
                bLoginReminderDlg: true
            });
            return;
        }
        let objCartItem = {};
        objCartItem.id = this.objCurrentItem.id;
        objCartItem.name = this.objCurrentItem.name;
        objCartItem.num = this.state.iCurrentItemNum;
        objCartItem.price = parseFloat(this.fCurrentItemTotalPrice).toFixed(2);
        objCartItem.comments = this.state.strSpecialRequests;
        objCartItem.store_id = this.objStoreInfo.id;

        // 当前物品的Options信息整理
        let arrOptions = [];
        for (let i = 0; i < this.arrCurrentOptionsCN.length; i++) {
            let objOption = -1;
            for (let j = 0; j < this.arrCurrentOptionsCN[i].contents.length; j++) {
                if (this.arrCurrentOptionsCN[i].contents[j].active) {
                    if (objOption === -1) {
                        objOption = {};
                        objOption.index = j;
                        objOption.title = this.arrCurrentOptionsCN[i].title + "," + this.arrCurrentOptionsEN[i].title;
                        objOption.name = this.arrCurrentOptionsCN[i].contents[j].name + "," + this.arrCurrentOptionsEN[i].contents[j].name;
                    } else {
                        objOption.name += "/" + this.arrCurrentOptionsCN[i].contents[j].name + "," + this.arrCurrentOptionsEN[i].contents[j].name;
                    }
                }
            }
            arrOptions.push(objOption);
        }
        objCartItem.options = arrOptions;
        let fUnit = 0.0;
        let fLength = 0.0;
        let fWidth = 0.0;
        let fHeight = 0.0;
        // console.log(this.objCurrentItem);
        if (typeof (this.objCurrentItem.item_unit) === 'string') {
            if (this.objCurrentItem.item_unit.indexOf(',') >= 0)
                fUnit = parseFloat(this.objCurrentItem.item_unit.split(',')[objCartItem.options[0].index]);
            else
                fUnit = parseFloat(this.objCurrentItem.item_unit);
        }
        if (typeof (this.objCurrentItem.length) === 'string') {
            if (this.objCurrentItem.length.indexOf(',') >= 0)
                fLength = parseFloat(this.objCurrentItem.length.split(',')[objCartItem.options[0].index]);
            else
                fLength = parseFloat(this.objCurrentItem.length);
        }
        if (typeof (this.objCurrentItem.width) === 'string') {
            if (this.objCurrentItem.width.indexOf(',') >= 0)
                fWidth = parseFloat(this.objCurrentItem.width.split(',')[objCartItem.options[0].index]);
            else
                fWidth = parseFloat(this.objCurrentItem.width);
        }
        if (typeof (this.objCurrentItem.height) === 'string') {
            if (this.objCurrentItem.height.indexOf(',') >= 0)
                fHeight = parseFloat(this.objCurrentItem.height.split(',')[objCartItem.options[0].index]);
            else
                fHeight = parseFloat(this.objCurrentItem.height);
        }
        objCartItem.weight = fUnit;
        objCartItem.length = fLength;
        objCartItem.width = fWidth;
        objCartItem.height = fHeight;
        console.log("objCartItem : ", objCartItem);

        // 编辑购物车中的物品
        if (this.bModifyItem) {
            this.arrCurrentCart[this.iCurrentModifyItem] = objCartItem;
            this.UpdateCartRecord();
            this.setState({
                ...this.state,
                bItemDetails: false,
                bViewCart: true
            });
        }
        // 新增一个物品到购物车
        else {
            if (this.arrCurrentCart === null || this.arrCurrentCart === "")
                this.arrCurrentCart = [];

            let iInCart = parseInt(this.objCurrentItem.in_cart, 10);
            if (isNaN(iInCart))
                iInCart = 0;
            else {
                if (iInCart < 0)
                    iInCart = 0;
            }
            iInCart++;
            this.objCurrentItem.in_cart = iInCart;
            axios.post('/UpdateItemInCart', { item: this.objCurrentItem })
                .then(({ data }) => {
                });

            this.arrCurrentCart.push(objCartItem);
            this.UpdateCartRecord();
            this.setState({
                ...this.state,
                bItemDetails: false
            });
        }

    }
    // 关闭购物车
    CloseCart = () => {
        this.setState({
            ...this.state,
            bViewCart: false
        });
    }
    // 移除购物车里的商品
    RemoveCartItem = (index) => {
        this.bDeleting = true;
        if (index >= 0) {
            this.arrCurrentCart.splice(index, 1);
            this.ShippingFeeCalculate();
        } else {
            this.arrCurrentCart = [];
            this.objShippingWeightInfo = {
                weight: 0.0,
                length: 0.0,
                width: 0.0,
                height: 0.0
            }
        }
        this.UpdateCartRecord();
        this.setState({
            ...this.state,
            bViewCart: true
        });
    }
    // 编辑购物车里的商品
    ModifyCartItem = (index) => {
        if (this.arrCurrentCart.length <= 0 || !this.arrCurrentCart[index] || this.bDeleting)
            return;
        for (let i = 0; i < this.props.arrItemsData.length; i++) {
            if (this.props.arrItemsData[i].id === this.arrCurrentCart[index].id) {
                this.objCurrentItem = this.props.arrItemsData[i];
                this.ItemOptionsProcess(this.objCurrentItem.options, this.arrCurrentCart[index].options);
                break;
            }
        }
        this.iCurrentModifyItem = index;
        this.iCurrentTab = 0;
        this.setState({
            ...this.state,
            bViewCart: false,
            bMainMenu: false,
            bItemDetails: true,
            iCurrentTab: 0,
            iCurrentItemNum: this.arrCurrentCart[index].num,
            strSpecialRequests: this.arrCurrentCart[index].comments
        });
    }
    // 更新购物车在用户表中的记录
    UpdateCartRecord = () => {
        this.props.objUserInfo.cart = this.arrCurrentCart;
        axios.post('/UpdateUserCartInfo', {
            id: this.props.objUserInfo.id,
            cart: this.arrCurrentCart
        }).then(({ data }) => {
            if (data.status === 1) {
                this.bDeleting = false;
            }
        }).catch(function (error) { console.log(error); });
    }
    // Get Customer's Exist Cards List
    GetCardList = () => {
        // 当该用户的支付方式不为空，且是使用卡片支付时，采取调取卡片列表信息
        if (this.props.objUserInfo === -1) {
            return;
        }
        if (this.props.objUserInfo.payment_id === null) {
            return;
        }
        // if (this.props.objUserInfo.payment_id < 0) {
        //     // this.iConfirmedPaymentMethod = this.props.objUserInfo.payment_id * -1;
        //     this.iConfirmedPaymentMethod = this.props.objUserInfo.payment_id;
        //     axios('/ListCustomerCards', {
        //         params: this.props.objUserInfo
        //     }).then(({ data }) => {
        //         console.log("card data 0 : ", data);
        //         this.arrCards = data.confirm;
        //     }).catch(function (error) { console.log(error); });
        // } else {
        this.iConfirmedPaymentMethod = this.props.objUserInfo.payment_id;
        axios('/ListCustomerCards', {
            params: this.props.objUserInfo
        }).then(({ data }) => {
            console.log("ListCustomerCards: ", data);
            if (data.status === 1) {
                this.arrCards = data.confirm;
                if (this.props.objUserInfo.payment_id >= 0)
                    this.GetCustomerDefaultPaymentMethod();
            } else
                console.log('未找到有效的用户卡片列表!');

        }).catch(function (error) { console.log(error); });
        // }
    }
    // 获取用户当前的支付方式（如果是卡片，获取卡片相应信息）
    GetCustomerDefaultPaymentMethod = () => {
        axios('/GetUserPaymentMethod', {
            params: { code: this.props.objUserInfo.code }
        }).then(({ data }) => {
            console.log('GetUserPaymentMethod : ', data, this.arrCards);
            let iCardID = 0;
            if (data[0].payment_id !== null) {
                // 卡片
                if (data[0].payment_id >= 0) {
                    iCardID = data[0].payment_id;
                    this.iConfirmedPaymentMethod = 1;
                    this.strCurrentCardBrand = this.arrCards[iCardID].card.brand;
                    this.strCurrentLast4 = this.arrCards[iCardID].card.last4;
                    this.objCardInfo = this.arrCards[iCardID];
                }
            }
        }).catch(function (error) { console.log(error); });
    }
    // 弹出选择支付方式对话框
    SelectPaymentMethod = () => {
        this.setState({
            ...this.state,
            bSelectPaymentDlg: true
        });
    }
    // 支付弹窗结果返回
    PaymentMethodResult = (bConfirm, iCardBrand, iCardLast4, iPaymentMethod, objCardInfo) => {
        if (bConfirm) {
            this.strCurrentLast4 = iCardLast4;
            this.strCurrentCardBrand = iCardBrand;
            this.iConfirmedPaymentMethod = iPaymentMethod;
            this.objCardInfo = objCardInfo;
        }
        if (iPaymentMethod < 0) {
            this.strCurrentLast4 = "";
            this.strCurrentCardBrand = "";
            this.iConfirmedPaymentMethod = iPaymentMethod;
            this.objCardInfo = {};
        }
        this.setState({
            ...this.state,
            bSelectPaymentDlg: false
        });
    }
    // 更新指定商品ID的销量和库存
    UpdateItemInventorySale = (index, objCart, objPrice) => {
        let iIndex = -1;
        if (this.arrCurrentCart[index].options.length > 0)
            iIndex = this.arrCurrentCart[index].options[0].index;
        // 更新商品销量和库存
        axios.post('/UpdateItemInventorySale', {
            item_id: this.arrCurrentCart[index].id,
            num: this.arrCurrentCart[index].num,
            index: iIndex
        }).then(({ data }) => {
            index++;
            if (index >= this.arrCurrentCart.length) {
                console.log("商品销量和库存更新完毕：", data);
                this.PaymentProcess(objCart, objPrice);
            } else {
                this.UpdateItemInventorySale(index, objCart, objPrice);
                console.log("商品" + index + "销量和库存已更新：", data);
            }
        });
    }
    // 下单付款
    ConfirmAndPay = (objPrice) => {
        console.log("Confirm and Pay");
        console.log("payment_id = ", this.props.objUserInfo.payment_id);
        if (this.props.objUserInfo.payment_id === null || this.props.objUserInfo.payment_id === '') {
            message.warning('请选择有效的支付方式！');
            return;
        }

        // 有效的联系方式检查
        if (this.props.objUserInfo.contact === null || this.props.objUserInfo.contact === "") {
            // if (this.props.objUserInfo.name === null || this.props.objUserInfo.name === "") {
            //     if (this.state.bEnglish)
            //         message.warning("Please enter your name！");
            //     else
            //         message.warning("联系人不能为空！");
            //     return;
            // } else {
            //     this.props.objUserInfo.contact = this.props.objUserInfo.name;
            // }
            this.props.objUserInfo.contact_name = this.objShippingAddress.name;
        }
        if (this.props.objUserInfo.contact_phone === null || this.props.objUserInfo.contact_phone === "" ||
            this.props.objUserInfo.contact_phone === "无") {
            // if (this.props.objUserInfo.phone === null || this.props.objUserInfo.phone === "" || this.props.objUserInfo.phone === "无") {
            //     if (this.state.bEnglish)
            //         message.warning("Please enter your phone number !");
            //     else
            //         message.warning("电话不能为空！"); return;
            // } else {
            //     this.props.objUserInfo.contact_phone = this.props.objUserInfo.phone; 
            // }
            this.props.objUserInfo.contact_phone = this.objShippingAddress.phone;
        }
        // 同步更新注册电话（完善之前的部分用户信息）
        if (this.props.objUserInfo.phone === null || this.props.objUserInfo.phone === "" || this.props.objUserInfo.phone === "无") {
            this.props.objUserInfo.phone = this.props.objUserInfo.contact_phone;
        }
        // 更新用户联系方式
        this.UpdateUserContactInfo();
        // 运费检查
        if (this.fShippingFee <= 0.0) {
            message.warning('运费计算中，请稍后再试！');
            return;
        }
        // 购物车商品信息
        let iItemTotalNum = 0;
        if (this.arrCurrentCart.length > 0) {
            console.log("购物车信息:", this.arrCurrentCart);
            for (let i = 0; i < this.arrCurrentCart.length; i++) {
                iItemTotalNum += this.arrCurrentCart[i].num;
            }
            let objCart = {
                iItemTotalNum: iItemTotalNum,
                fItemTotalPrice: objPrice.fTotalPrice,
                objPrice: objPrice
            };
            this.setState({
                ...this.state,
                bPaymentResultDlg: true,
                iPaymentResult: 0
            });
            // 先更新库存和销量，更新完之后才会划款下单
            this.UpdateItemInventorySale(0, objCart, objPrice);
        }
    }
    // 支付流程
    PaymentProcess = (objCart, objPrice) => {
        // 刷卡支付
        if (this.props.objUserInfo.payment_id >= 0) {
            console.log("选择了卡片支付！", this.iConfirmedPaymentMethod, this.strCurrentLast4);
            // 有效的支付方式检查
            if (this.iConfirmedPaymentMethod === 0 || this.strCurrentLast4 === "") {
                message.warning("无法下单支付，请选择有效的支付方式！");
                return;
            }
            this.setState({
                ...this.state,
                bPaymentResultDlg: true,
                iPaymentResult: 0
            });
            // 1. Stripe 订单生成，扣款
            axios.post('/PlaceOrderAndPay', {
                objUserInfo: this.props.objUserInfo,
                objCardInfo: this.objCardInfo,
                objCartInfo: objCart,
                objStoreInfo: this.props.objStoreInfo,
                fPriceOff: 0
            }).then(({ data }) => {
                console.log("支付完成后的Strip数据返回", data);
                // 支付成功
                if (data.message === "succeed" && data.pid !== "") {
                    this.setState({
                        ...this.state,
                        bPaymentResultDlg: true,
                        iPaymentResult: 1
                    });
                    this.OrderRecordInDB(data, objCart, objPrice);
                }
                // 支付失败（卡片无效/网络问题？等）
                else {
                    this.setState({
                        ...this.state,
                        bPaymentResultDlg: true,
                        iPaymentResult: -1
                    });
                }
            }).catch(function (error) { console.log(error); });
        }
        // 非卡片支付
        else {
            // 现金支付
            if (this.props.objUserInfo.payment_id === -4) {
                console.log("选择了现金支付", this.props.objUserInfo.payment_id);
                this.OrderRecordInDB(-4, objCart, objPrice);
            }
        }
    }
    // 在数据库记录订单
    OrderRecordInDB = (dataStripe, objCart, objPrice) => {
        console.log("开始记录订单……");
        let strPid = "";
        if (dataStripe !== -4)
            strPid = dataStripe.pid;
        let objOrderItems = { total_num: objCart.iItemTotalNum, items: [] };
        let arrItemID = [];
        let arrItemSales = [];
        objOrderItems.items = this.arrCurrentCart;
        for (let i = 0; i < this.arrCurrentCart.length; i++) {
            let item = { id: 0, num: 0 };
            item.id = this.arrCurrentCart[i].id;
            item.name = this.arrCurrentCart[i].name;
            item.num = this.arrCurrentCart[i].num;
            arrItemID.push(item.id);
            arrItemSales.push(item.num);
        }
        // 2. 更新商品的sale数(包括数据库中的)
        // axios.post('/UpdateItemsSale', { items: arrItemID, sales: arrItemSales })
        //     .then(({ data }) => {
        //         console.log("商品销量更新：", data);
        //     });
        let strCardInfo = this.strCurrentCardBrand + "," + this.strCurrentLast4;
        if (dataStripe === -4)
            strCardInfo = "-4";
        let iDeliveryWay = this.state.iDeliveryWay;
        let iStoreType = this.StoreClassification();
        if (iStoreType === 0)
            iDeliveryWay = 2;
        // 生成目标订单信息
        let objOrderInfo = {
            pid: strPid,
            status: 1,
            final_price: objPrice.fFinalPrice.toFixed(4),
            gst_pst: objPrice.fGSTFee.toFixed(4),
            total_price: objPrice.fTotalPrice.toFixed(4),
            stripe_fee: objPrice.fStripFee.toFixed(4),
            mouthing_benefit: objPrice.fMouthingBenefit.toFixed(4),
            store_benefit: objPrice.fStoreBenefit.toFixed(4),
            delivery_fee: objPrice.fDeliveryFee.toFixed(4),
            price_off: objPrice.fDiscount.toFixed(4),
            items: JSON.stringify(objOrderItems),
            store_id: this.props.objStoreInfo.id,
            store_name: this.props.objStoreInfo.store_name,
            user_code: this.props.objUserInfo.code,
            client_order_time: new Date(),
            card_info: strCardInfo,
            contact: this.props.objUserInfo.contact,
            phone: this.props.objUserInfo.contact_phone,
            comments: "",
            cut_price_id: -1,
            cut_price_batch: -1,
            cut_price_status: 1,
            delivery_way: iDeliveryWay,
            address: this.strDeliveryAddress,
            deliver_address: this.objShippingAddress,
            area: "",
            viewed: 0,
            user_viewed: 0,
            coupon_code: objPrice.strCouponCode,
            package_weight: this.objShippingWeightInfo.weight,
            package_length: this.objShippingWeightInfo.length,
            package_width: this.objShippingWeightInfo.width,
            package_height: this.objShippingWeightInfo.height
        };
        if (this.objShippingAddress.state === 'British Columbia') {
            this.objShippingAddress.state = 'BC';
            objOrderInfo.deliver_address = this.objShippingAddress
        }

        console.log("Order Info : ", objOrderInfo);
        // 5. 订单在数据库的记录
        axios.post('/RecordOrder', {
            objOrderInfo: objOrderInfo, objUserInfo: this.props.objUserInfo
        }).then(({ data }) => {
            console.log("订单记录完成，返回数据 ： ", data);
            if (data.oid === -1) {
                // 订单在mouthing数据库记录异常
                message.warning(data.message);
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: -2
                });
            } else {
                // 订单支付记录成功，此时订单结果对话框处于打开状态并显示结果
                this.RemoveCartItem(-1);
                this.strOrderID = data.oid;
                let iPaymentResult = 1;
                if (dataStripe === -4)
                    iPaymentResult = 4;
                this.setState({
                    ...this.state,
                    bPaymentResultDlg: true,
                    iPaymentResult: iPaymentResult,
                    iUserCenterReminder: this.state.iUserCenterReminder + 1
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 订单支付完成后的操作
    AfterPayment = (bStay) => {
        if (!bStay) {
            let strLang = "cn";
            if (this.state.bEnglish)
                strLang = "en";
            window.location.href = '/webapp/user_profile?tab=3&store=' + this.props.objStoreInfo.id + '&lang=' + strLang;
        } else {
            // window.location.href = '/webapp/store/' + this.props.objStoreInfo.id;
            this.setState({
                bViewCart: false,
                bPaymentResultDlg: false
            });
        }
    }
    // 获取用户的订单信息
    GetOrderList = () => {
        if (this.props.objUserInfo === -1)
            return;
        axios('/GetTargetOrders', { params: { store_id: this.props.objStoreInfo.id, user_code: this.props.objUserInfo.code, status: -1, user_viewed: 0 } })
            .then(({ data }) => {
                // console.log("GetTargetOrder : ", data);
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].status > 1) {
                            this.audioNewOrder.play();
                            if (this.state.bEnglish)
                                message.info("You has an order updated!");
                            else
                                message.info("您的订单状态有更新！");
                            break;
                        }
                    }
                }
                this.setState({
                    ...this.state,
                    iUserCenterReminder: data.length
                })
            }).catch(function (error) { console.log(error); });
    }
    // 更改联系人信息/配送方式
    ChangeContactInfo = (index) => {
        // 联系人
        if (index === 0) {

        }
        // 联系电话
        if (index === 1) {

        }
        // 配送方式
        if (index === 2) {
            console.log("修改配送方式");
        }
        this.setState({
            ...this.state,
            iContactInfoDlg: index,
            bContactInfoDlg: true
        });
    }
    // 更改配送方式
    DeliveryMethodChange = (e) => {
        if (this.refs.address_input) {
            if (e.target.value >= 1)
                this.refs.address_input.state.value = this.props.objUserInfo.address;
            else
                this.refs.address_input.state.value = this.props.objStoreInfo.address;
        }
        this.setState({
            iDeliveryWay: e.target.value,
        });
    }
    // 确认修改联系人信息
    ConfirmContactInfoChange = (bConfirm) => {
        if (bConfirm) {
            if (this.state.iContactInfoDlg === 0) {
                let strContact = this.refs.contact_input.state.value;
                if (strContact === "" || strContact === null) {
                    if (this.state.bEnglish)
                        message.warning("Please enter your name!");
                    else
                        message.warning("联系人不能为空！"); return;
                }
                this.props.objUserInfo.contact = strContact;
            }
            if (this.state.iContactInfoDlg === 1) {
                let strContactPhone = this.refs.contact_phone_input.state.value;
                if (strContactPhone === "" || strContactPhone === null) {
                    if (this.state.bEnglish)
                        message.warning("Please enter your phone number!");
                    else
                        message.warning("联系电话不能为空！"); return;
                }
                this.props.objUserInfo.contact_phone = "(+1)" + strContactPhone;
            }
            if (this.state.iContactInfoDlg === 2) {
                this.strDeliveryAddress = this.refs.address_input.state.value;
                if (this.strDeliveryAddress === "" || this.strDeliveryAddress === null) {
                    if (this.state.bEnglish)
                        message.warning("Please enter your address!");
                    else
                        message.warning("配送地址不能为空！"); return;
                }
                // if (this.state.iDeliveryWay === 1)
                //     this.props.objUserInfo.address = this.strDeliveryAddress;
            }
        }

        this.setState({
            ...this.state,
            bContactInfoDlg: false
        });
    }
    // 数据库中用户联系方式更新
    UpdateUserContactInfo = () => {
        axios.post('/UpdateUserBasicInfo', {
            objUserInfo: this.props.objUserInfo,
            bNameCheck: false
        }).then(({ data }) => {
            if (data.status === 1) {
                // DataStatistic
                UserActiveUpdate(4, { active: "用户信息修改成功", name: this.props.objUserInfo.name });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 登录提示框
    LoginReminder = (type) => {
        if (type === 1) {
            this.ClickMenu(2);
        }
        if (type === -1) {

        }
        this.setState({
            ...this.state,
            bLoginReminderDlg: false
        });
    }
    // 购物车中当前商店的商品书
    CurrentStoreItemInCart = () => {
        let iItemNum = 0;
        if (this.arrCurrentCart !== undefined) {
            if (this.arrCurrentCart.length > 0) {
                for (let i = 0; i < this.arrCurrentCart.length; i++) {
                    if (this.arrCurrentCart[i].store_id === this.objStoreInfo.id)
                        iItemNum++;
                }
            }
        }
        return iItemNum;
    }
    // 商家分类判断
    StoreClassification = () => {
        let iStoreType = 0;
        for (let i = 0; i < this.arrStoreList0.length; i++) {
            if (this.arrStoreList0[i] === this.props.objStoreInfo.id) {
                iStoreType = 0;
                break;
            }
        }
        for (let i = 0; i < this.arrStoreList1.length; i++) {
            if (this.arrStoreList1[i] === this.props.objStoreInfo.id) {
                iStoreType = 1;
                break;
            }
        }
        return iStoreType;
    }
    // 搜索商品/服务
    SearchItem = () => {
        let strKeywords = this.refs.item_search.state.value;
        if (this.arrItemSearch.length <= 0 && !this.bNoSearhResult) {
            if (strKeywords === null || strKeywords === "") {
                message.warning("请输入需要搜索的商品/服务名称！");
                return;
            }
            if (strKeywords.trim() === "") {
                message.warning("请输入需要搜索的商品/服务名称！");
                return;
            }
        } else {
            this.arrItemSearch = [];
            this.bNoSearhResult = false;
            if (strKeywords === null || strKeywords === "") {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
                return;
            }
            if (strKeywords.trim() === "") {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
                return;
            }
        }
        this.bNoSearhResult = false;
        this.arrItemSearch = [];
        axios('/SearchStoreItem', { params: { name: /*this.KeywordsProcess(*/strKeywords/*)*/, store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                console.log("search items : ", data);
                this.arrItemSearch = data;
                if (data.length <= 0)
                    this.bNoSearhResult = true;
                let arrItemTypeOpen = [];
                for (let i = 0; i < this.props.arrItemTypeData.length; i++) {
                    for (let j = 0; j < this.arrItemSearch.length; j++) {
                        if (parseInt(this.arrItemSearch[j].type, 10) === this.props.arrItemTypeData[i].id) {
                            arrItemTypeOpen.push(i.toString());
                            break;
                        }
                    }
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    arrItemTypeOpen: arrItemTypeOpen
                });
            }).catch(function (error) { console.log(error); });

    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        console.log("keywords = " + newKeywords);
        return newKeywords;
    }
    // 修改邮寄地址
    ModifyShippingAddress = (iModify) => {
        let iCurrentState = this.state.iCurrentState;
        if (iModify === 0)
            iCurrentState = 1;
        if (iModify === 2) {
            if (this.ShippingAddressCheck()) {
                console.log("更新用户收货地址：", this.objShippingAddress);
                // 更新收货地址
                axios.post('/UpdateUserShippingAddress', {
                    user_id: this.props.objUserInfo.id,
                    shipping_address: this.objShippingAddress
                }).then(({ data }) => {
                    if (data.status === 1) {
                        message.info(data.message);
                        this.ShippingFeeCalculate();
                        this.setState({
                            ...this.state,
                            iModifyAddress: iModify,
                            iCurrentState: iCurrentState
                        });
                    } else {
                        message.warning(data.message);
                    }

                });
            }
        } else {
            this.setState({
                ...this.state,
                iModifyAddress: iModify,
                iCurrentState: iCurrentState
            });
        }
    }
    // 切换邮寄省份
    SwitchShippingStates = (index) => {
        this.setState({
            ...this.state,
            iCurrentState: index
        })
    }
    // 检查收件地址
    ShippingAddressCheck = () => {
        let strCountry = this.refs.shipping_address_country.state.value;
        let iState = this.state.iCurrentState;
        let strName = this.refs.shipping_address_name.state.value;
        let strPhone = this.refs.shipping_address_phone.state.value;
        let strCity = this.refs.shipping_address_city.state.value;
        let strPostcode = this.refs.shipping_address_postcode.state.value;
        let strAddress1 = this.refs.shipping_address1.state.value;
        let strAddress2 = this.refs.shipping_address2.state.value;
        // let strAddress3 = this.refs.shipping_address3.state.value;
        if (strName === undefined || strName === null || strName === "") {
            message.warning("收件人不能为空！"); return false;
        }
        if (typeof (strName) === 'string') {
            if (strName.trim() === "") {
                message.warning("收件人不能为空！"); return false;
            }
        }
        if (strPhone === undefined || strPhone === null || strPhone === "") {
            message.warning("联系电话不能为空！"); return false;
        }
        if (typeof (strPhone) === 'string') {
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return false;
            }
        }
        if (strCity === undefined || strCity === null || strCity === "") {
            message.warning("城市不能为空！"); return false;
        }
        if (typeof (strCity) === 'string') {
            if (strCity.trim() === "") {
                message.warning("城市不能为空！"); return false;
            }
        }
        if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
            message.warning("邮编不能为空！"); return false;
        }
        if (typeof (strPostcode) === 'string') {
            if (strPostcode.trim() === "") {
                message.warning("邮编不能为空！"); return false;
            }
        }
        if (strAddress1 === undefined || strAddress1 === null || strAddress1 === "") {
            message.warning("地址第1行不能为空！"); return false;
        }
        if (typeof (strAddress1) === 'string') {
            if (strAddress1.trim() === "") {
                message.warning("地址第1行不能为空！"); return false;
            }
        }
        if (strAddress2 === undefined || strAddress2 === null) {
            strAddress2 = "";
        }
        // if (strAddress3 === undefined || strAddress3 === null) {
        //     strAddress3 = "";
        // }
        this.strDeliveryAddress = strAddress1;
        this.objShippingAddress = {
            contact_name: strName,
            phone: strPhone,
            country: strCountry,
            state: this.arrShippingStatesShort[iState],
            state_index: iState,
            city: strCity,
            postal_code: strPostcode.toUpperCase(),
            street1: strAddress1,
            street2: strAddress2,
            street3: ""
        }
        this.props.objUserInfo.contact = strName;
        this.props.objUserInfo.contact_phone = strPhone;
        return true;
    }
    // 获取省份信息Index
    // GetStateIndex = (strState) => {
    //     for (let i = 0; i < this.arrShippingStatesShort.length; i++) {
    //         if (this.arrShippingStatesShort[i] === strState) {
    //             return i;
    //         }
    //     }
    //     return 0;
    // }
    // 运费计算
    ShippingFeeCalculate = () => {
        this.setState({
            ...this.state,
            bLoading: true
        })
        // 计算运费
        let fTotalWeight = 0.0; let fTotalLength = 0.0; let fTotalWidth = 0.0; let fTotalHeight = 0.0;
        let fTotalValue = 0.0;
        for (let i = 0; i < this.arrCurrentCart.length; i++) {
            fTotalWeight += this.arrCurrentCart[i].weight * this.arrCurrentCart[i].num;
            fTotalLength += this.arrCurrentCart[i].length * this.arrCurrentCart[i].num;
            fTotalWidth += this.arrCurrentCart[i].width * this.arrCurrentCart[i].num;
            fTotalHeight += this.arrCurrentCart[i].height * this.arrCurrentCart[i].num;
            fTotalValue += this.arrCurrentCart[i].length * this.arrCurrentCart[i].width * this.arrCurrentCart[i].height * this.arrCurrentCart[i].num;
        }
        console.log("pure weight = " + fTotalWeight);
        fTotalWeight *= 1.2;
        console.log("shipping weight = " + fTotalWeight.toFixed(4));
        fTotalLength = Math.pow(fTotalValue, 1 / 3);
        fTotalWidth = fTotalLength;
        fTotalHeight = fTotalLength;
        console.log("v, l, w, h : ", fTotalValue.toFixed(4), fTotalLength.toFixed(4), fTotalWidth.toFixed(4), fTotalHeight.toFixed(4))
        this.objShippingWeightInfo = {
            weight: fTotalWeight.toFixed(4),
            length: fTotalLength.toFixed(4),
            width: fTotalWidth.toFixed(4),
            height: fTotalHeight.toFixed(4)
        }
        if (fTotalWeight <= 0.0 || isNaN(fTotalWeight) || this.objShippingAddress.postal_code === null || this.objShippingAddress.postal_code === '') {
            if (this.state.bLogin && this.arrCurrentCart.length > 0) {
                if (fTotalWeight <= 0.0 || isNaN(fTotalWeight))
                    message.warning('重量信息有误，请重新选择商品！');
                else
                    message.warning('请在收货地址中填在有效的邮编！！');
            }
        } else {
            console.log("destPostalCode: ", this.objShippingAddress.postal_code);
            this.objShippingAddress.postal_code = this.objShippingAddress.postal_code.toUpperCase();
            this.fShippingFee = 0.0;
            axios.post('/cpapi/rate', {
                originPostalCode: 'V6X 1X5',
                destPostalCode: this.objShippingAddress.postal_code,
                weight: fTotalWeight.toFixed(4),
                length: fTotalLength.toFixed(4),
                width: fTotalWidth.toFixed(4),
                height: fTotalHeight.toFixed(4)
            }).then(({ data }) => {
                console.log("CP shipping fee : ", data);
                if (data === undefined || data === null || data === "")
                    data = [];
                if (data.length > 1) {
                    this.fShippingFee = data[0].price.total;
                } else {
                    message.warning('运费获取失败，请检查您的邮编！');
                }
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            });
        }
    }
    // 修改优惠码
    ModifyCouponCode = (bModify) => {
        this.setState({
            ...this.state,
            bModifyCouponCode: bModify
        })
    }
    // 应用优惠码
    ApplyCouponeCode = () => {
        this.strCouponCode = this.refs.coupon_code.state.value;
        // 检查优惠码
        if (this.strCouponCode === undefined || this.strCouponCode === null || this.strCouponCode === "") {
            // message.warning('优惠码不能为空！');
            this.strCouponCode = "";
            this.objCouponInfo = {};
            this.ModifyCouponCode(false);
            return;
        }
        if (this.strCouponCode.trim() === "") {
            // message.warning('优惠码不能为空！');
            this.strCouponCode = "";
            this.objCouponInfo = {};
            this.ModifyCouponCode(false);
            return;
        }
        axios('/CheckCouponCode', {
            params: {
                store_id: this.props.objStoreInfo.id,
                code: this.strCouponCode
            }
        }).then(({ data }) => {
            console.log("CheckCouponCode result : ", data);
            if (data.length > 0) {
                this.objCouponInfo = data[0];
            } else {
                message.warning('请输入有效的优惠码！');
            }
            this.ModifyCouponCode(false);
        }).catch(function (error) { console.log(error); });
    }
    // 计算优惠价格
    CalculateDiscount = (fTotalPrice) => {
        let fDiscount = 0.0;
        // 折扣
        if (this.objCouponInfo.type === 0) {
            fDiscount = fTotalPrice * (100.0 - parseFloat(this.objCouponInfo.discount)) / 100.0;
        }
        // 减免
        if (this.objCouponInfo.type === 1) {
            fDiscount = parseFloat(this.objCouponInfo.price_off);
        }

        return fDiscount;
    }
    // 生成商品ItemUI控件
    GenerateItemUI = (item) => {
        let strItemName = item.name.split(',')[0];
        if (this.state.bEnglish)
            strItemName = item.name.split(',')[1];
        let uiItemIcon = (
            <div className="store_pickup_item_icon" />
        );
        if (item.logo !== "")
            uiItemIcon = (
                <img className="store_pickup_item_icon" src={item.logo} alt="" />
            );
        else
            uiItemIcon = null;
        let uiPriceLabel = (
            <div className="store_pickup_item_price">{"$ " + item.price}</div>
        );
        let uiDiscountPrice = null;
        if (this.bAllDiscount) {
            uiPriceLabel = (
                <div className="store_pickup_item_price" style={{ textDecoration: 'line-through', color: 'gray' }}>
                    {"$ " + item.price}</div>
            );
            let fDiscountPrice = parseFloat(item.price) * parseFloat(this.props.objStoreInfo.all_discount) / 100;
            uiDiscountPrice = (
                <div className="store_pickup_item_sales">{"$ " + fDiscountPrice.toFixed(2)}</div>
            );
        }
        if (item.discount !== undefined && item.discount !== null && item.discount !== "") {
            uiPriceLabel = (
                <div className="store_pickup_item_price" style={{ textDecoration: 'line-through', color: 'gray' }}>
                    {"$ " + item.price}</div>
            )
            let fDiscountPrice = 0.0;
            if (item.discount.indexOf(',') >= 0)
                fDiscountPrice = parseFloat(item.price) * parseFloat(item.discount.split(',')[0]) / 100;
            else
                fDiscountPrice = parseFloat(item.price) * parseFloat(item.discount) / 100;
            uiDiscountPrice = (
                <div className="store_pickup_item_sales">{"$ " + fDiscountPrice.toFixed(2)}</div>
            );
        }
        return (
            <div className="store_pickup_item_info_block" key={item.id} onClick={this.ClickAnItem.bind(this, item)}>
                {uiItemIcon}
                <div className="store_pickup_item_info">
                    <div className="store_pickup_item_name" >{strItemName}</div>
                    <div className="store_pickup_item_sec_row">
                        {uiPriceLabel}
                        {uiDiscountPrice}
                    </div>
                </div>
            </div>
        );
    }
    // 渲染完毕……
    componentDidMount() {
        let strAllDiscount = this.props.objStoreInfo.all_discount;
        if (strAllDiscount !== null && strAllDiscount !== undefined && strAllDiscount !== "") {
            this.bAllDiscount = true;
        }
        // DataStatistic
        UserActiveUpdate(0, { active: "访问商家页" });
        // 获取日期信息
        let today = new Date().toString();
        this.DateToIndex(today.split(' ')[0]);
        // 获取购物车信息
        if (this.props.objUserInfo.cart !== undefined) {
            console.log("cart info : ", this.props.objUserInfo.cart);
            if (this.props.objUserInfo.cart !== null && this.props.objUserInfo.cart !== "")
                this.arrCurrentCart = JSON.parse(this.props.objUserInfo.cart);
        }
        this.GetCardList();
        this.GetOrderList();
        if (this.props.objUserInfo !== -1 && typeof (this.props.objUserInfo) === 'object') {
            console.log("user info : ", this.props.objUserInfo);
            this.setState({
                ...this.state,
                bLogin: true
            });
        } else {
            this.setState({
                ...this.state,
                bLogin: false
            });
        }
        // 检查当前用户的收件地址信息
        if (this.props.objUserInfo.deliver_address !== undefined && this.props.objUserInfo.deliver_address !== null && this.props.objUserInfo !== "") {
            if (typeof (this.props.objUserInfo.deliver_address) === 'string') {
                if (this.props.objUserInfo.deliver_address.indexOf('contact_name') >= 0) {
                    this.objShippingAddress = JSON.parse(this.props.objUserInfo.deliver_address);
                    if (this.objShippingAddress.state_index === undefined || this.objShippingAddress.state_index === null || this.objShippingAddress.state_index === "")
                        this.objShippingAddress.state_index = 0;
                    this.setState({
                        ...this.state,
                        iCurrentState: this.objShippingAddress.state_index
                    });
                    console.log("shipping address : ", this.objShippingAddress);
                }
            }
        }
        // 中英文默认设置
        let bEnglish = false;
        for (let i = 0; i < this.arrStoreList2.length; i++) {
            if (this.arrStoreList2[i] === this.props.objStoreInfo.id) {
                bEnglish = true;
                break;
            }
        }
        if (bEnglish)
            document.title = this.props.objStoreInfo.store_name.split(',')[1];
        else
            document.title = this.props.objStoreInfo.store_name.split(',')[0];
        this.setState({
            ...this.state,
            bEnglish: bEnglish
        });

    }

    render() {

        // 顶部菜单按钮
        let uiMenuReminder = null;
        if (this.state.iUserCenterReminder > 0)
            uiMenuReminder = (
                <div className="store_pickup_menu_reminder" />
            );
        let uiMenButton = (
            <div className="store_pickup_menu_button" onClick={this.OpenMainMenu.bind(this, true)}>
                <Icon type="menu" />
                {uiMenuReminder}
            </div>
        );
        // 顶部菜单栏
        let uiMainMenuContents = (
            <Drawer title={null} placement="top" closable={false} height={"60vw"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.OpenMainMenu.bind(this, false)} visible={this.state.bMainMenu}>
                <div>
                    {this.arrMainMenu.map((item, index) => {
                        let strStyle = "store_pickup_main_menu_row";
                        let strText = item.cn;
                        if (this.state.bEnglish)
                            strText = item.en;
                        let uiUserCenterReminder = null;
                        if (index === 2) {
                            if (this.state.iUserCenterReminder > 0) {
                                uiUserCenterReminder = (
                                    <div className="store_pickup_menu_cart_item_num">{this.state.iUserCenterReminder}</div>
                                );
                            }
                        }
                        return (
                            <div className={strStyle} key={index} onClick={this.ClickMenu.bind(this, index)}>
                                <Icon type={item.icon} className="store_pickup_menu_icon" style={{ color: 'darkcyan' }} />
                                <div className="store_pickup_main_menu_text" key={index}>{strText}</div>
                                {uiUserCenterReminder}
                            </div>
                        );
                    })}
                    <div className="store_pickup_menu_button_close">
                        <Icon type="close-circle" theme="filled" style={{ color: 'darkcyan' }} onClick={this.OpenMainMenu.bind(this, false)} />
                    </div>
                </div>
            </Drawer>
        );
        // 底部购物车按钮
        let uiCartReminder = null;
        let iItemNum = this.CurrentStoreItemInCart();

        if (this.arrCurrentCart !== undefined && iItemNum > 0) {
            if (this.arrCurrentCart !== null && this.arrCurrentCart !== -1 && this.arrCurrentCart.length > 0)
                uiCartReminder = (<div className="store_pickup_cart_reminder" />);
        }
        let uiCartButton = (
            <div className="store_pickup_cart_button" onClick={this.ClickMenu.bind(this, 4)}>
                <Icon type="shopping-cart" />
                {uiCartReminder}
            </div>
        );
        // 头图区域
        let uiAdsTitle = (
            <AdsCarousel iCurrentPage={2} iStoreID={this.props.objStoreInfo.id}
                arrAdsImages={this.props.objStoreInfo.arrAdsImages} />
        );
        // 商家名称＆地址
        let strStoreName = this.objStoreInfo.store_name.split(',')[0];
        if (this.state.bEnglish)
            strStoreName = this.objStoreInfo.store_name.split(',')[1];
        let uiStoreMainInfo = (
            <div className="store_key_info">
                <div className="store_pickup_name">{strStoreName}</div>
                <div className="store_pickup_location_row">
                    <Icon type="environment" theme="filled" />
                    <div className="store_pickup_location_text">
                        {this.objStoreInfo.address}
                    </div>
                </div>
            </div>
        );
        // 商家详情 & 下单 切换按钮
        let uiMainTabs = null;
        if (this.state.bLoading) {
            uiMainTabs = (
                <div className="store_main_tabs">
                    <Spin className="store_main_tabs_loading" size="large" style={{ paddingTop: '21.5vw' }} />
                </div>
            );
        } else {
            let strStyle0 = "store_pickup_main_tab_button";
            let strStyle1 = "store_pickup_main_tab_button";
            if (this.state.iCurrentTab === 0)
                strStyle0 = "store_pickup_main_tab_button store_pickup_main_tab_button_select";
            if (this.state.iCurrentTab === 1)
                strStyle1 = "store_pickup_main_tab_button store_pickup_main_tab_button_select";
            let strTab0 = this.arrMainTab[0].cn;
            let strTab1 = this.arrMainTab[1].cn;
            if (this.state.bEnglish) {
                strTab0 = this.arrMainTab[0].en;
                strTab1 = this.arrMainTab[1].en;
            }
            uiMainTabs = (
                <div className="store_pickup_main_tab_area">
                    <div className={strStyle0} onClick={this.SwitchMainTab.bind(this, 0)}>{strTab0}</div>
                    <div className={strStyle1} onClick={this.SwitchMainTab.bind(this, 1)}>{strTab1}</div>
                </div>
            );
        }
        // 商家简介
        let strDescription = "";
        if (this.objStoreInfo.description.indexOf('/') >= 0) {
            strDescription = this.objStoreInfo.description.split('/')[0];
            if (this.state.bEnglish)
                strDescription = this.objStoreInfo.description.split('/')[1];
        } else {
            strDescription = this.objStoreInfo.description;
        }
        if (strDescription !== null && strDescription !== '') {
            if (strDescription.indexOf('#') >= 0) {
                let arrDescription = strDescription.split('#');
                strDescription = (
                    <div>{arrDescription[0]}<b style={{ color: 'darkcyan' }}>{arrDescription[1]}</b>{arrDescription[2]}</div>
                );
            }
        }
        let strAbout = "简介";
        if (this.state.bEnglish)
            strAbout = "About";
        let uiStoreIntro = (
            <div className="store_pickup_intro_area">
                <div className="store_pickup_info_title">
                    <Icon type="info-circle" style={{ marginRight: "2vw" }} />
                    {strAbout}
                </div>
                <div className="store_pickup_intro_text">
                    {strDescription}
                </div>
            </div>
        );
        // 营业时间
        let strHours = "营业时间";
        if (this.state.bEnglish)
            strHours = "Hours";
        let uiStoreHours = (
            <div className="store_pickup_intro_area">
                <div className="store_pickup_info_title">
                    <Icon type="clock-circle" style={{ marginRight: "2vw" }} />
                    {strHours}
                </div>
                <div className="store_pickup_intro_text">
                    {this.arrOpenTime.map((item, index) => {
                        let objOpenTime = this.StoreHoursFormatProcess(item);
                        return (
                            <div className="store_pickup_hour_row" key={index}>
                                {objOpenTime.day}
                                <div className="store_pickup_hour">
                                    {objOpenTime.hour}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
        // 商家更多信息
        let uiPhone = null;
        let uiEmail = null;
        let uiAddress = null;
        if (this.objStoreInfo.phone !== null && this.objStoreInfo.phone !== "") {
            uiPhone = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="phone" /*theme="filled"*/ style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text"> {this.objStoreInfo.phone}</div>
                </div>
            );
        }
        if (this.objStoreInfo.email !== null && this.objStoreInfo.email !== "") {
            uiEmail = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="mail" style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text">{this.objStoreInfo.email}</div>
                </div>
            );
        }
        if (this.objStoreInfo.address !== null && this.objStoreInfo.address !== "") {
            let strShortAddress = this.objStoreInfo.address;
            if (strShortAddress.indexOf(',') >= 0) {
                strShortAddress = strShortAddress.split(',')[0];
            }
            uiAddress = (
                <div className="store_pickup_info_title store_pickup_more_details_tow">
                    <Icon type="environment" style={{ marginRight: "2vw" }} />
                    <div className="store_picup_info_text">{strShortAddress}</div>
                </div>
            );
        }
        let uiStoreDetails = (
            <div className="store_pickup_intro_area">
                {uiPhone}
                {uiEmail}
                {uiAddress}
                <div className="store_pickup_info_map">
                    <Map google={this.props.google} zoom={15} style={mapStyles} containerStyle={{ position: 'relative', width: '83vw', height: '60vw' }}
                        initialCenter={this.state.objStorePos} center={this.state.objStorePos}>
                        <Marker position={this.state.objStorePos} />
                    </Map>
                </div>
            </div>
        );
        // 营业时间
        let objTodayOpenTime = this.StoreHoursFormatProcess(this.arrOpenTime[this.iCurrentDate]);
        let uiTodayHour = (
            <div className="store_pickup_time_location_text">{objTodayOpenTime.hour}</div>
        );
        let strOpenStatus = "营业中";
        if (this.state.bEnglish)
            strOpenStatus = "Open";
        if (this.props.objStoreInfo.status === 2 || objTodayOpenTime.hour === "不营业") {
            uiTodayHour = null;
            strOpenStatus = "不营业";
            if (this.state.bEnglish)
                strOpenStatus = "Closed";
        }
        let strAddress = "暂无详细地址";
        if (this.objStoreInfo.address !== null && this.objStoreInfo.address !== "") {
            strAddress = this.objStoreInfo.address;
            if (strAddress.indexOf(',') >= 0) {
                strAddress = strAddress.split(',')[0];
            }
        }
        let strPickupTimeLocations = "营业时间 和 实体店地点";
        let strMenu = "菜单"; let strSearch = "搜索商品/服务";
        if (this.state.bEnglish) {
            strPickupTimeLocations = "Opening Hours & Locations";
            strMenu = "Menu"; strSearch = "Search Item or Service";
        }
        let arrItemList = this.props.arrItemsData;
        if (this.arrItemSearch.length > 0) {
            arrItemList = this.arrItemSearch;
        }

        let uiTypeList = null;
        if (this.bNoSearhResult) {
            uiTypeList = (
                <div className="store_pickup_no_search_result">未找到匹配的商品/服务</div>
            );
        } else {
            uiTypeList = (
                <Collapse bordered={false} defaultActiveKey={['0']} activeKey={this.state.arrItemTypeOpen} expandIconPosition={"right"}
                    onChange={this.ItemTypeOpen} expandIcon={({ isActive }) => <Icon type="caret-right" style={{ color: "darkcyan", fontWeight: "10vw" }} rotate={isActive ? 90 : 0} />}>
                    {this.props.arrItemTypeData.map((type, index) => {
                        let strStyle = "store_pickup_item_type_title";
                        if (this.arrItemTypeOpen.length > 0 && this.arrItemTypeOpen.indexOf(index.toString()) >= 0)
                            strStyle += " store_pickup_item_type_title_selected";
                        let strTypeName = type.type_name.split(',')[0];
                        if (this.state.bEnglish)
                            strTypeName = type.type_name.split(',')[1];
                        let uiItemTypeTitle = (
                            <div className={strStyle}> {strTypeName}</div>
                        );
                        return (
                            <Panel header={uiItemTypeTitle} key={index}>
                                {arrItemList.map((item, index) => {
                                    // 有多个分类
                                    if (item.type.indexOf(',') >= 0) {
                                        let arrType = item.type.split(',');
                                        if (arrType.indexOf(type.id.toString()) >= 0) {
                                            return this.GenerateItemUI(item);
                                        } else
                                            return null;
                                    }
                                    // 只有一个分类
                                    else {
                                        if (parseInt(item.type, 10) === type.id) {
                                            return this.GenerateItemUI(item);
                                        } else
                                            return null;
                                    }
                                })}
                            </Panel>
                        );
                    })}
                </Collapse>
            );
        }

        // 商家类别
        let iStoreType = this.StoreClassification();
        let uiPickupInfo = (
            <div className="store_pickup_intro_area">
                <div className="store_pickup_info_title">
                    <Icon type="shopping-cart" style={{ marginRight: "3vw", fontWeight: "bold", fontSize: "5.5vw" }} />
                    {strPickupTimeLocations}
                </div>
                <div className="store_pickup_time_location_row">
                    <Icon type="clock-circle" style={{ marginRight: "2vw" }} />
                    {uiTodayHour}
                    <div className="store_pickup_status">{strOpenStatus}</div>
                </div>
                <div className="store_pickup_time_location_row">
                    <Icon type="environment" style={{ marginRight: "2vw" }} />
                    <div className="store_pickup_time_location_text">{strAddress}</div>
                </div>
            </div>
        );
        if (iStoreType === 0) {
            uiPickupInfo = null;
        }

        // 商品类别 & 列表
        let uiItemList = (
            <div>
                {uiPickupInfo}
                <div className="store_pickup_item_types_line">
                    <div className="store_pickup_item_types_menu">{strMenu}</div>
                    <Input placeholder={strSearch} style={{ maxWidth: '60%', marginLeft: '20px' }}
                        allowClear enterButton ref="item_search" />
                    <div className="store_pickup_search_button" onClick={this.SearchItem.bind(this)}>
                        <Icon type="search" />
                    </div>
                </div>
                {uiTypeList}
                <div className="store_pickup_bottom_space" />
            </div>
        );
        // 商品详情抽屉
        let strItemName = "";
        let strItemInfo = ""
        if (this.objCurrentItem !== -1) {
            strItemName = this.objCurrentItem.name.split(',')[0];
            if (this.state.bEnglish)
                strItemName = this.objCurrentItem.name.split(',')[1];
            if (this.objCurrentItem.description !== null && this.objCurrentItem.description !== "") {
                if (this.objCurrentItem.description.indexOf(' || ') >= 0) {
                    strItemInfo = this.objCurrentItem.description.split(' || ')[0];
                    if (this.state.bEnglish)
                        strItemInfo = this.objCurrentItem.description.split(' || ')[1];
                } else {
                    strItemInfo = this.objCurrentItem.description;
                }
            }
        }
        let strAddToCart = "加入购物车";
        if (this.bModifyItem)
            strAddToCart = "保存修改";
        if (this.state.bEnglish) {
            strAddToCart = "Add To Cart";
            if (this.bModifyItem)
                strAddToCart = "Save";
        }
        let uiItemDetails = null;
        let strRequire = "必选";
        let arrCurrentOptions = this.arrCurrentOptionsCN;
        if (this.state.bEnglish) {
            strRequire = "Required";
            arrCurrentOptions = this.arrCurrentOptionsEN;
        }
        // Modifer 列表
        this.bSoldOut = false;
        let uiInventory = null;
        if (this.objCurrentItem.inventory !== undefined) {
            if (this.objCurrentItem.inventory !== null && this.objCurrentItem.inventory !== "") {
                if (this.objCurrentItem.inventory.indexOf(',') < 0 && parseInt(this.objCurrentItem.inventory, 10) <= 0) {
                    this.iCurrentItemInventory = 0;
                    uiInventory = (
                        <div className="store_pickup_item_details_info" style={{ color: 'lightcoral' }}>{"已售罄"}</div>
                    );
                    this.bSoldOut = true;
                } else {
                    this.iCurrentItemInventory = parseInt(this.objCurrentItem.inventory, 10);
                    uiInventory = (
                        <div className="store_pickup_item_details_info">{"库存：" + this.objCurrentItem.inventory + " 件"}</div>
                    );
                }
            }
        }
        let fTotalPrice = this.objCurrentItem.price;
        if (this.objCurrentItem.discount !== undefined && this.objCurrentItem.discount !== null && this.objCurrentItem.discount !== "") {
            if (this.objCurrentItem.discount.indexOf(',') >= 0)
                fTotalPrice = fTotalPrice * parseFloat(this.objCurrentItem.discount.split(',')[this.state.objOption.iOptionIndex]) / 100.0;
            else
                fTotalPrice = fTotalPrice * parseFloat(this.objCurrentItem.discount) / 100.0;
        }
        fTotalPrice = parseFloat(fTotalPrice);

        let uiOptionList = null;
        if (arrCurrentOptions.length > 0)
            uiOptionList = arrCurrentOptions.map((option, indexType) => {
                let uiSelectMethod = null;
                let strSelectMethod = "最多 " + option.select_method + " 种";
                if (this.state.bEnglish)
                    strSelectMethod = "Max " + option.select_method;
                if (option.select_method >= 1)
                    uiSelectMethod = (
                        <div className="store_pickup_item_details_require">
                            {strSelectMethod}</div>
                    );
                let uiRequire = null;
                if (option.bRequired) {
                    if (option.select_method > 1) {
                        uiRequire = (
                            <div className="store_pickup_item_details_require">
                                {strRequire + " (  " + strSelectMethod + " )"}</div>
                        );

                    } else {
                        uiRequire = (
                            <div className="store_pickup_item_details_require">
                                {strRequire}</div>
                        );
                    }
                    uiSelectMethod = null;
                }
                return (
                    <div className="store_pickup_item_details_modifier_area" key={indexType}>
                        <div className="store_pickup_item_details_modifier_title">
                            {option.title}
                            {uiRequire}
                            {uiSelectMethod}
                        </div>
                        <div>
                            {option.contents.map((content, indexOption) => {
                                let uiOptionRadio = null;
                                let bRadioButton = true;
                                // 必选项且单选时，使用Radio Button
                                if (option.bRequired && option.select_method === 1) {
                                    if (content.active) {
                                        if (indexType === 0 && this.objCurrentItem.inventory !== null && this.objCurrentItem.inventory !== "") {
                                            let strInventory = this.objCurrentItem.inventory.split(',')[indexOption];
                                            this.iCurrentItemInventory = parseInt(strInventory, 10);
                                            if (strInventory === '0') {
                                                this.bSoldOut = true;
                                                uiInventory = (
                                                    <div className="store_pickup_item_details_info" style={{ color: 'lightcoral' }}>
                                                        {"已售罄"}
                                                    </div>
                                                );
                                            } else {
                                                strInventory.trim();
                                                uiInventory = (
                                                    <div className="store_pickup_item_details_info">
                                                        {"库存：" + strInventory + " 件"}
                                                    </div>
                                                );
                                            }
                                        }
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_radio_select" />
                                        );
                                    } else {
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_radio" />
                                        );
                                    }
                                }
                                // 非必选项使用Check Box
                                else {
                                    bRadioButton = false;
                                    if (content.active)
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_checkbox_select">
                                                <Icon type="check" style={{ color: "white", fontWeight: "bold" }} />
                                            </div>
                                        );
                                    else
                                        uiOptionRadio = (
                                            <div className="store_pickup_item_details_modifier_option_checkbox" />
                                        );
                                }
                                // 价格显示
                                let strPrice = "";
                                let strPriceStyle = "store_pickup_item_details_modifier_option_price";

                                if (content.active) {
                                    strPriceStyle = "store_pickup_item_details_modifier_option_price_select";
                                    // 有Option选项时的价格
                                    if (content.price !== "0") {
                                        fTotalPrice = 0.00;
                                        if (content.price.indexOf('+') >= 0)
                                            fTotalPrice += parseFloat(content.price.split('+')[1]);
                                        else
                                            fTotalPrice = parseFloat(content.price);
                                        if (this.objCurrentItem.discount !== undefined && this.objCurrentItem.discount !== null &&
                                            this.objCurrentItem.discount !== "") {
                                            if (this.objCurrentItem.discount.indexOf(',') >= 0)
                                                fTotalPrice = fTotalPrice * parseFloat(this.objCurrentItem.discount.split(',')[this.state.objOption.iOptionIndex]) / 100.0;
                                            else
                                                fTotalPrice = fTotalPrice * parseFloat(this.objCurrentItem.discount) / 100.0;
                                        }
                                        fTotalPrice = parseFloat(fTotalPrice);
                                    }
                                }
                                if (content.price !== "0") {
                                    strPrice = "$" + content.price;

                                    if (content.price.indexOf('+') >= 0)
                                        strPrice = "+ $" + content.price.split('+')[1];
                                    else {
                                        if (this.objCurrentItem.discount !== undefined && this.objCurrentItem.discount !== null && this.objCurrentItem.discount !== "") {
                                            let fPrice = 0.0;
                                            if (this.objCurrentItem.discount.indexOf(',') >= 0)
                                                fPrice = parseFloat(content.price) * parseFloat(this.objCurrentItem.discount.split(',')[indexOption]) / 100.0;
                                            else
                                                fPrice = parseFloat(content.price) * parseFloat(this.objCurrentItem.discount) / 100.0;
                                            strPrice = "$" + fPrice.toFixed(2).toString();
                                        }
                                    }

                                }
                                return (
                                    <div className="store_pickup_item_details_modifier_option_row" key={indexOption}
                                        onClick={this.ItemModifierOptionCLick.bind(this, indexType, indexOption, bRadioButton)}>
                                        {uiOptionRadio}
                                        <div className="store_pickup_item_details_modifier_option_text">{content.name}</div>
                                        <div className={strPriceStyle}>{strPrice}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            });
        // 商品详情抽屉
        this.fCurrentItemTotalPrice = this.state.iCurrentItemNum * fTotalPrice.toFixed(2);
        let strCommentsTitle = "备注（特殊需求）";
        let strCommentsTips = "最多100字";
        if (this.state.bEnglish) {
            strCommentsTitle = "Comments ( Special Requests )";
            strCommentsTips = "Max 100 Characters";
        }
        const { getFieldProps } = this.props.form;

        let uiItemImage = (
            <div className="store_pickup_item_details_picture" />
        );
        if (this.objCurrentItem.image !== "")
            uiItemImage = (
                <img className="store_pickup_item_details_picture" src={this.objCurrentItem.image} alt="" />
            );
        let strPlaceOrderButtonStyle = "store_pickup_item_details_add_to_cart_button";
        uiItemDetails = (
            <Drawer title={null} placement="left" closable={false} width={"100vw"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.CloseItemDetails.bind(this)} visible={this.state.bItemDetails}>
                <div className="store_pickup_item_details_dlg">
                    <div className="store_pickup_item_details_title">
                        <div className="store_pickup_item_details_back_button" onClick={this.CloseItemDetails.bind(this)} >
                            <Icon type="left-circle" theme="filled" style={{ color: 'darkcyan' }} />
                        </div>
                        <div className="store_pickup_item_details_store_name">{strStoreName}</div>
                    </div>
                    {uiItemImage}
                    <div className="store_pickup_item_details_name">{strItemName}</div>
                    {uiInventory}
                    <div className="store_pickup_item_details_info">{strItemInfo}</div>
                    {uiOptionList}
                    <div className="store_pickup_item_details_modifier_title">
                        {strCommentsTitle}
                    </div>
                    <div className="store_pickup_item_details_modifier_comments">
                        <TextareaItem {...getFieldProps('count')} ref="special_requests" value={this.state.strSpecialRequests} onChange={this.SpecialRequestsInput}
                            cols={40} /*rows={2}*/ autoHeight count={100} placeholder={strCommentsTips}
                            style={{
                                width: '100vw', lineHeight: '6.5vw', fontSize: '3.75vw', border: 'none', outline: 'none',
                                margin: '0vw', marginTop: '1.5vw', padding: '2vw', paddingLeft: '5vw'
                            }}
                        />
                    </div>
                    <div className="store_pickup_item_details_num_select_row">
                        <div className="store_pickup_item_details_add_button" onClick={this.ItemNumChange.bind(this, false, fTotalPrice.toFixed(2))}>
                            <Icon type="minus" style={{ color: "white", fontWeight: "bold" }} />
                        </div>
                        <div className="store_pickup_item_details_num_text">
                            {this.state.iCurrentItemNum}
                        </div>
                        <div className="store_pickup_item_details_add_button" onClick={this.ItemNumChange.bind(this, true, fTotalPrice.toFixed(2))}>
                            <Icon type="plus" style={{ color: "white", fontWeight: "bold" }} />
                        </div>
                    </div>
                    <div className="store_pickup_item_details_bottom_margin" />
                    <div className="store_pickup_item_details_bottom">
                        <div className="store_pickup_item_details_total_price">{"$ " + this.fCurrentItemTotalPrice.toFixed(2)}</div>
                        <div className={strPlaceOrderButtonStyle} onClick={this.AddToCartButtonClicked}>{strAddToCart}</div>
                    </div>
                </div>
            </Drawer>
        );
        // 购物车抽屉
        let strCartTitle = "购物车";
        if (this.state.bEnglish)
            strCartTitle = "Cart";
        let uiCartItemList = null;
        let fSubtotal = 0.00;
        let fDeliveryFee = 0.0;
        let uiCartEmpty = null;
        let strAddMore = "添加更多";
        let strPayButton = "立刻下单";
        let arrDeliverMethod = ["到店自提", "送货上门", "快递邮寄"];
        let arrUserInfo = ["姓名", "电话", "地址"];
        let arrUserInfoDlgTitle = ["修改联系信息", "修改配送方式"];
        // 15个
        let arrPriceTitle =
            [
                "小计", "税费", "手续费", "总计", "支付方式 : ",
                "更改 > ", "购物车还是空的,快去选购吧!", "请先登录", "联系姓名 : ", "联系电话 : ",
                "配送方式 : ", "送货地址：", "运费", "优惠编码 : ", '优惠'
            ];
        if (this.state.bEnglish) {
            strAddMore = "Add More Items";
            arrPriceTitle = ["Subtotal", "Tax", "Strip Fee", "Total", "Payment Method", "Change > ", "Cart is empty, shopping now!", "Login", "Contact", "Phone", "Delivery", "Address", "Delivery Fee", "Coupon", 'Discount'];
            strPayButton = "Order Now";
            arrDeliverMethod = ["Pickup", "To Door", "Express"];
            arrUserInfo = ["Name", "Phone", "Address"];
            arrUserInfoDlgTitle = ["Change Contact Info.", "Change Delivery Option"];
        }

        if (iStoreType === 0)
            arrDeliverMethod = ['Canada Post'];
        let uiPriceArea = null;
        let uiAddMore = null;
        let uiPayButton = null;
        let uiPaymentMethod = null;
        let uiContact = null;
        let uiContactPhone = null;
        let uiContactInfoDlg = null;
        let uiCouponCodeDlg = null;
        let uiDeliveryMethod = null;
        let uiDeliveryAddress = null;
        let uiCouponCode = null;
        if (this.state.bViewCart && this.arrCurrentCart.length > 0) {
            let iLanguageIndex = 0;
            if (this.state.bEnglish)
                iLanguageIndex = 1;
            // 无本店商品
            if (iItemNum <= 0) {
                uiCartReminder = null;
                uiCartItemList = null;
                let uiLoginButton = null;
                if (!this.state.bLogin)
                    uiLoginButton = (<div className="store_pickup_cart_login_button" onClick={this.ClickMenu.bind(this, 2)}>{arrPriceTitle[7]}</div>);
                uiCartEmpty = (
                    <Empty style={{ marginTop: '50vw', color: 'lightgrey', fontSize: '3.5vw' }} image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={arrPriceTitle[6]} >
                        {uiLoginButton}
                    </Empty>
                );
            }
            // 有本店商品
            else {
                uiCartItemList = (
                    <div style={{ marginTop: '15vw' }}>
                        {this.arrCurrentCart.map((item, indexItem) => {
                            if (item.store_id !== this.objStoreInfo.id)
                                return null;
                            fSubtotal += parseFloat(item.price);
                            // fDeliveryFee += parseFloat(item.delivery_fee);
                            let uiOptions = null;
                            if (item.options.length > 0) {
                                uiOptions = item.options.map((option, indexOption) => {
                                    if (option === -1 || option === null)
                                        return null;
                                    else {
                                        let strOptions = option.title.split(',')[iLanguageIndex] + ": ";
                                        if (option.name.indexOf('/') >= 0) {
                                            let arrOptions = option.name.split('/');
                                            for (let i = 0; i < arrOptions.length; i++) {
                                                strOptions += arrOptions[i].split(',')[iLanguageIndex];
                                                if (i !== arrOptions.length - 1)
                                                    strOptions += ", ";
                                            }
                                        } else {
                                            strOptions += option.name.split(',')[iLanguageIndex];
                                        }

                                        return (
                                            <div className="store_pickup_cart_item_option_row" key={indexOption}>
                                                {strOptions}
                                            </div>
                                        );
                                    }
                                });
                            }
                            let uiComments = <div className="store_pickup_cart_item_bottom_margin" />;
                            if (item.comments !== "" && item.comments !== null) {
                                uiComments = (
                                    <div className="store_pickup_cart_item_comments">
                                        <Icon type="info-circle" theme="filled" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '4vw', marginRight: '2vw' }} />
                                        {item.comments}
                                    </div>
                                );
                            }
                            let fPrice = parseFloat(item.price);
                            return (
                                <div className="store_pickup_cart_item_area" key={indexItem} onClick={this.ModifyCartItem.bind(this, indexItem)}>
                                    <div className="store_pickup_cart_item_title">
                                        <div className="store_pickup_cart_item_num">{item.num}</div>
                                        <div className="store_pickup_cart_item_name">{item.name.split(',')[iLanguageIndex]}</div>
                                        <div className="store_pickup_cart_item_price">{"$" + fPrice.toFixed(2)}</div>
                                        <div className="store_pickup_cart_item_remove_button" onClick={this.RemoveCartItem.bind(this, indexItem)}>
                                            <Icon type="close-circle" style={{ color: 'darkcyan' }} />
                                        </div>
                                    </div>
                                    <div className="store_pickup_cart_item_options_area">
                                        {uiOptions}
                                    </div>
                                    {uiComments}
                                </div>
                            );
                        })}
                    </div>
                );
                console.log("render : ", fSubtotal);
                let objPrice = {};
                objPrice = StripePriceCalculate(fSubtotal, fDeliveryFee, true);
                if (this.iConfirmedPaymentMethod === -4) {
                    objPrice.fGSTFee = 0.0;
                    objPrice.fStripFee = 0.0;
                    objPrice.fFinalPrice = objPrice.fTotalPrice;
                    objPrice.fMouthingBenefit = 0.0;
                    objPrice.fStoreBenefit = objPrice.fFinalPrice;
                    objPrice.fDeliveryFee = 0.0;
                }
                objPrice.fDiscount = 0.0;
                objPrice.strCouponCode = "";
                if (iStoreType === 0) {
                    objPrice.fDeliveryFee = this.fShippingFee;
                    objPrice.fFinalPrice += objPrice.fDeliveryFee;
                }
                let uiDiscount = null;
                // 已有优惠信息
                if (this.objCouponInfo.code !== undefined) {
                    let fDiscount = this.CalculateDiscount(fSubtotal);
                    objPrice.fFinalPrice -= fDiscount;
                    objPrice.fDiscount = fDiscount;
                    objPrice.strCouponCode = this.objCouponInfo.code;
                    uiDiscount = (
                        <div className="store_pickup_cart_price_row">
                            {arrPriceTitle[14]}
                            <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'lightcoral', fontWeight: 'bold' }}>
                                {"$ -" + objPrice.fDiscount.toFixed(2)}</div>
                        </div>
                    );
                }
                uiPriceArea = (
                    <div>
                        <div className="store_pickup_cart_price_row">
                            {arrPriceTitle[0]}
                            <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>{"$ " + parseFloat(fSubtotal).toFixed(2)}</div>
                        </div>
                        <div className="store_pickup_cart_price_row">
                            {arrPriceTitle[1]}
                            <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>{"$ " + parseFloat(objPrice.fGSTFee).toFixed(2)}</div>
                        </div>
                        <div className="store_pickup_cart_price_row">
                            {arrPriceTitle[12]}
                            <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'darkcyan', fontWeight: 'bold' }}>
                                {"$ " + parseFloat(objPrice.fDeliveryFee).toFixed(2)}</div>
                        </div>
                        {uiDiscount}
                        <div className="store_pickup_cart_price_row store_pickup_cart_total_price_row" style={{ fontSize: "4.75vw", fontWeight: 'bold' }}>
                            {arrPriceTitle[3]}
                            <div style={{ marginLeft: 'auto', marginRight: '0vw', color: 'black', fontSize: '4.75vw', fontWeight: 'bold' }}>
                                {"$ " + parseFloat(objPrice.fFinalPrice).toFixed(2)}</div>
                        </div>
                    </div>
                );
                uiAddMore = (
                    <div className="store_pickup_cart_add_more_item" onClick={this.SwitchMainTab.bind(this, 0)}>
                        <div className="store_pickup_cart_item_num" style={{ color: 'darkcyan', fontSize: '4.5vw', borderColor: 'darkcyan', paddingTop: '0vw' }}>+</div>
                        <div style={{ fontSize: '4.25vw', marginTop: '0.5vw' }}>{strAddMore}</div>
                    </div>
                );
                uiPayButton = (
                    <div className="store_pickup_cart_payment_button" onClick={this.ConfirmAndPay.bind(this, objPrice)}>
                        {strPayButton}
                        <div className="store_pickup_cart_payment_total_price_bottom">{"$ " + parseFloat(objPrice.fFinalPrice).toFixed(2)}</div>
                    </div>
                );
                let iConfirmedPaymentMethod = this.iConfirmedPaymentMethod;
                if (iConfirmedPaymentMethod < 0) {
                    iConfirmedPaymentMethod *= -1;
                }
                uiPaymentMethod = (
                    <div className="store_pickup_cart_payment_method" onClick={this.SelectPaymentMethod}>
                        {arrPriceTitle[4]}
                        <div className="store_pickup_cart_dlg_change_button" style={{ fontSize: '4vw' }} >
                            {this.arrPaymentMethod[iConfirmedPaymentMethod] + " >"}</div>
                    </div>
                );
                // 信用卡/借记卡支付
                if (this.iConfirmedPaymentMethod >= 0) {
                    uiPaymentMethod = (
                        <div className="store_pickup_cart_payment_method" onClick={this.SelectPaymentMethod}>
                            {arrPriceTitle[4]}
                            <Icon type="credit-card" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '5vw', marginBottom: '1vw' }} />
                            <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>{this.strCurrentLast4}</div>
                            <div className="store_pickup_cart_dlg_change_button" style={{ fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                        </div>
                    );
                }
                // 现金支付
                if (this.iConfirmedPaymentMethod === -4) {
                    uiPaymentMethod = (
                        <div className="store_pickup_cart_payment_method" onClick={this.SelectPaymentMethod}>
                            {arrPriceTitle[4]}
                            <Icon type="dollar" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '5vw', marginBottom: '1vw' }} />
                            <div className="select_card_brief_last4" style={{ fontSize: '4vw', color: 'grey', fontWeight: 'bold' }}>
                                {this.arrPaymentMethod[iConfirmedPaymentMethod]}</div>
                            <div className="store_pickup_cart_dlg_change_button" style={{ fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                        </div>
                    );
                }
                // 联系人和联系方式 (现金、自提类型才显示，零售电商显示在收货地址中)
                let strContact = "";
                let strContactPhone = "";
                if (iStoreType === 1) {
                    strContact = this.props.objUserInfo.contact;
                    if (strContact === null || strContact === "") {
                        strContact = this.props.objUserInfo.name;
                    }
                    uiContact = (
                        <div className="store_pickup_cart_contact" onClick={this.ChangeContactInfo.bind(this, 0)} >
                            {arrPriceTitle[8]}
                            <Icon type="user" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                            <div className="store_pickup_contact_info_contents" >{strContact}</div>
                            <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                        </div>
                    );
                    strContactPhone = this.props.objUserInfo.contact_phone;
                    if (strContactPhone === null || strContactPhone === "") {
                        strContactPhone = this.props.objUserInfo.phone;
                    }
                    if (strContactPhone.indexOf(')') >= 0) {
                        if (strContactPhone.indexOf('86') >= 0)
                            strContactPhone = "";
                    }
                    uiContactPhone = (
                        <div className="store_pickup_cart_contact" onClick={this.ChangeContactInfo.bind(this, 1)} >
                            {arrPriceTitle[9]}
                            <Icon type="user" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                            <div className="store_pickup_contact_info_contents" >{strContactPhone}</div>
                            <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                        </div>
                    );
                }
                if (this.state.bContactInfoDlg) {
                    let uiAddress = null;
                    let bAddressInputDisable = false;
                    let strTitle = arrUserInfoDlgTitle[0];
                    let strTips = "请填写合法的签收人姓名，以方便您的货物签收";
                    let uiTip1 = null;
                    let uiTip2 = null;
                    let strHoldPlace = "请输入联系人姓名";
                    if (this.state.bEnglish) {
                        strTips = "Please enter your legal name!";
                        strHoldPlace = "Please enter first and last name!";
                    }
                    let uiLabel = (<div className="store_pickup_cart_contact_title">{arrUserInfo[0]}</div>);
                    let uiContactInput = null;
                    if (iStoreType === 1) {
                        uiTip1 = (<div className="store_pickup_contact_info_tips">{strTips}</div>);
                        uiContactInput = (
                            <Input placeholder={strHoldPlace} className="store_pickup_cart_contact_input" ref="contact_input"
                                defaultValue={strContact} />
                        );
                        if (this.state.iContactInfoDlg === 1) {
                            strTips = "请填写有效的联系电话，以方便您的货物签收";
                            uiTip1 = (<div className="store_pickup_contact_info_tips">{strTips}</div>);
                            strHoldPlace = "请填写联系电话";
                            if (this.state.bEnglish) {
                                strTips = "Please enter a phone we can reach you!";
                                strHoldPlace = "Please enter your phone number";
                            }
                            uiLabel = (<div className="store_pickup_cart_contact_title">{arrUserInfo[1]}</div>);
                            uiContactInput = (
                                <Input placeholder={strHoldPlace} className="store_pickup_cart_contact_input" ref="contact_phone_input"
                                    defaultValue={strContactPhone.split(')')[1]} addonBefore="+1" />
                            );
                        }
                    }
                    // 联系方式2：配送方式选择
                    if (this.state.iContactInfoDlg === 2) {
                        strTitle = arrUserInfoDlgTitle[1];
                        strTips = "请填写有效的收货地址，以方便您的货物签收";
                        strHoldPlace = "请输入您的地址";
                        if (this.state.bEnglish) {
                            strTips = "Please enter valid delivery address!";
                            strHoldPlace = "Please enter your address";
                        }
                        uiTip1 = (<div className="store_pickup_contact_info_tips">{strTips}</div>);
                        uiLabel = null;

                        if (iStoreType === 0) {
                            uiContactInput = (
                                <Radio.Group onChange={this.DeliveryMethodChange} value={this.state.iDeliveryWay}>
                                    {/* <Radio value={0} style={{ fontSize: '3.75vw', fontWeight: 'bold' }}>{arrDeliverMethod[0]}</Radio> */}
                                    <Radio value={0} style={{ fontSize: '3.75vw', fontWeight: 'bold' }}>{arrDeliverMethod[0]}</Radio>
                                </Radio.Group>
                            );
                        }
                        if (iStoreType === 1) {
                            uiContactInput = (
                                <Radio.Group onChange={this.DeliveryMethodChange} value={this.state.iDeliveryWay}>
                                    <Radio value={0} style={{ fontSize: '3.75vw', fontWeight: 'bold' }}>{arrDeliverMethod[0]}</Radio>
                                </Radio.Group>
                            );
                        }
                        let strAddress = "";
                        if (this.state.iDeliveryWay >= 1) {
                            let strTip2 = "仅限大温地区配送";
                            if (this.state.bEnglish)
                                strTip2 = "Only For Greate Vancouver Area";
                            uiTip2 = (<div className="store_pickup_contact_info_tips">{strTip2}</div>);
                            if (this.props.objUserInfo.address !== null && this.props.objUserInfo.address !== "")
                                strAddress = this.props.objUserInfo.address;
                        } else {
                            uiTip1 = null;
                            uiTip2 = null;
                            bAddressInputDisable = true;
                            if (this.props.objStoreInfo.address !== null && this.props.objStoreInfo.address !== "")
                                strAddress = this.props.objStoreInfo.address;
                        }
                        uiAddress = (
                            <div className="store_pickup_cart_contact_info_row" style={{ marginTop: '5vw' }}>
                                <div className="store_pickup_cart_contact_title">{arrUserInfo[2]}</div>
                                <Input placeholder={strHoldPlace} className="store_pickup_cart_contact_input" ref="address_input"
                                    defaultValue={strAddress} disabled={bAddressInputDisable} />
                            </div>
                        );
                    }
                    uiContactInfoDlg = (
                        <Modal title={strTitle} visible={this.state.bContactInfoDlg} closable={false} centered={true}
                            onOk={this.ConfirmContactInfoChange.bind(this, true)} okText="确定"
                            onCancel={this.ConfirmContactInfoChange.bind(this, false)} cancelText="取消">
                            <div className="store_pickup_cart_contact_info_row">
                                {uiLabel}
                                {uiContactInput}
                            </div>
                            {uiAddress}
                            {uiTip1}
                            {uiTip2}
                        </Modal>
                    );
                }
                if (this.state.bModifyCouponCode) {
                    let strTitle = "输入优惠码";
                    if (this.bEnglish)
                        strTitle = "Input Coupon Code";
                    uiCouponCodeDlg = (
                        <Modal title={strTitle} visible={this.state.bModifyCouponCode} closable={false} centered={true}
                            onOk={this.ApplyCouponeCode.bind(this)} okText="确定"
                            onCancel={this.ModifyCouponCode.bind(this, false)} cancelText="取消">
                            <div className="store_pickup_cart_contact_info_row">
                                {/* {strTitle} */}
                                <Input className="store_pickup_cart_contact_input" style={{ width: '100%' }} ref="coupon_code" />
                            </div>
                        </Modal>
                    );
                }
                // 优惠编码
                uiCouponCode = (
                    <div className="store_pickup_cart_contact" onClick={this.ModifyCouponCode.bind(this, true)}>
                        {arrPriceTitle[13]}
                        <Icon type="truck" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                        <div className="store_pickup_contact_info_contents" >{this.strCouponCode}</div>
                        <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                    </div>
                );
                // 配送方式控件
                uiDeliveryMethod = (
                    <div className="store_pickup_cart_contact" /*onClick={this.ChangeContactInfo.bind(this, 2)}*/ >
                        {arrPriceTitle[10]}
                        <Icon type="truck" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '2.5vw' }} />
                        <div className="store_pickup_contact_info_contents" >{arrDeliverMethod[this.state.iDeliveryWay]}</div>
                        {/* <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div> */}
                    </div>
                );
                if (this.strDeliveryAddress === "") {
                    if (this.objShippingAddress.street1 === "") {
                        if (this.state.iDeliveryWay >= 1) {
                            if (this.props.objUserInfo.address !== null && this.props.objUserInfo.address !== "")
                                this.strDeliveryAddress = this.props.objUserInfo.address;
                        } else {
                            if (this.props.objStoreInfo.address !== null && this.props.objStoreInfo.address !== "")
                                this.strDeliveryAddress = "";
                        }
                    } else {
                        this.strDeliveryAddress = this.objShippingAddress.street1;
                    }
                }
                // 收货地址
                uiDeliveryAddress = (
                    <div className="store_pickup_cart_contact" onClick={this.ModifyShippingAddress.bind(this, 1)}>
                        {arrPriceTitle[11]}
                        <Icon type="truck" theme="filled" style={{ fontSize: '4.5vw', fontWeight: 'bold', marginLeft: '1vw' }} />
                        <div className="store_pickup_contact_info_contents" style={{ width: '37.5vw', minWidth: '37.5vw', maxWidth: '37.5vw' }}>{this.strDeliveryAddress}</div>
                        <div style={{ marginLeft: 'auto', marginRight: '7.5vw', fontWeight: 'bold', color: 'grey', fontSize: '4vw' }}>{arrPriceTitle[5]}</div>
                    </div>
                );
            }
        } else {
            let uiLoginButton = null;
            if (!this.state.bLogin)
                uiLoginButton = (<div className="store_pickup_cart_login_button" onClick={this.ClickMenu.bind(this, 2)}>{arrPriceTitle[7]}</div>);
            uiCartEmpty = (
                <Empty style={{ marginTop: '50vw', color: 'lightgrey', fontSize: '3.5vw' }} image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={arrPriceTitle[6]} >
                    {uiLoginButton}
                </Empty>
            );
        }
        let arrPaymentDlgTextCn = ['支付方式', ''];
        let arrPaymentDlgTextEn = ['Payment Method', ''];
        let arrPaymentDlgText = arrPaymentDlgTextCn;
        if (this.state.bEnglish)
            arrPaymentDlgText = arrPaymentDlgTextEn;
        // 支付方式选择框
        let uiPaymentOptions = (
            <PaymentMethodDlg bSelectPaymentDlg={this.state.bSelectPaymentDlg} PaymentMethodResult={this.PaymentMethodResult} bEnglish={this.state.bEnglish}
                strTitle={arrPaymentDlgText[0]} objUserInfo={this.props.objUserInfo} objStoreInfo={this.props.objStoreInfo} iStoreType={iStoreType} />
        );
        // 支付结果弹窗
        let bPaymentProcessing = true;
        let strPaymentResultDlgTitle = "您的订单";
        let uiPaymentResultContents = (
            <div className="payment_processing">
                <Spin tip="交易处理中……" />
            </div>
        );
        // 支付成功
        if (this.state.iPaymentResult === 1) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功 !
                </div>
            );
        }
        // 支付失败
        if (this.state.iPaymentResult === -1) {
            bPaymentProcessing = false;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付失败 !
                </div>
            );
        }
        // 支付成功 但订单信息记录出现问题
        if (this.state.iPaymentResult === -2) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    支付成功, 但订单记录出现问题，请联系客服！
                </div>
            );
        }
        // 使用现金支付
        if (this.state.iPaymentResult === 4) {
            bPaymentProcessing = false;
            strPaymentResultDlgTitle = "您的订单: " + this.strOrderID;
            uiPaymentResultContents = (
                <div className="payment_processing">
                    您选择了现金支付，请在提货时出示订单信息，并与商家结款！
                </div>
            );
        }
        let uiPaymentResultDlg = (
            <Modal title={strPaymentResultDlgTitle} visible={this.state.bPaymentResultDlg} closable={false}
                confirmLoading={bPaymentProcessing}
                onOk={this.AfterPayment.bind(this, false)} okText="查看订单"
                onCancel={this.AfterPayment.bind(this, true)} cancelText="继续选购">
                {uiPaymentResultContents}
            </Modal>
        );
        // 购物车
        let uiCart = (
            <Drawer title={null} placement="bottom" closable={false} height={"100vh"} bodyStyle={{ backgroundColor: 'white', padding: '0vw' }}
                onClose={this.CloseItemDetails.bind(this)} visible={this.state.bViewCart}>
                <div className="store_pickup_item_details_dlg" style={{ height: 'auto' }}>
                    <div className="store_pickup_item_details_title">
                        <div className="store_pickup_item_details_back_button" onClick={this.CloseCart.bind(this)} >
                            <Icon type="down-circle" theme="filled" style={{ color: 'darkcyan' }} />
                        </div>
                        <div className="store_pickup_item_details_store_name">{strCartTitle}</div>
                        <div className="store_pickup_item_details_delete_button" onClick={this.RemoveCartItem.bind(this, -1)} >
                            <Icon type="delete" /*theme="filled"*/ style={{ color: 'darkcyan' }} />
                        </div>
                    </div>
                    {uiCartEmpty}
                    {uiCartItemList}
                    {uiAddMore}
                    {uiPriceArea}
                    {uiContact}
                    {uiContactPhone}
                    {uiPaymentMethod}
                    {uiCouponCode}
                    {uiDeliveryMethod}
                    {uiDeliveryAddress}
                    <div className="store_pickup_bottom_space" />
                    {uiPayButton}
                    {uiPaymentOptions}
                    {uiPaymentResultDlg}
                    {uiContactInfoDlg}
                    {uiCouponCodeDlg}
                </div>
            </Drawer>
        );
        // 修改地址弹窗
        const menuStates = (
            <Menu>
                {this.arrShippingStates.map((state, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchShippingStates.bind(this, index)}>
                            {state}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );

        let uiModifyAddressDlg = null;

        if (this.state.iModifyAddress === 1) {
            uiModifyAddressDlg = (
                <Modal title={"收货地址"} visible={true} closable={false}
                    onOk={this.ModifyShippingAddress.bind(this, 2)} onCancel={this.ModifyShippingAddress.bind(this, 0)}
                    okText="确认" cancelText="取消" >
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">收件人</div>
                        <Input className="shipping_address_row_input" ref="shipping_address_name" defaultValue={this.objShippingAddress.contact_name} />
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">地址</div>
                        <Input className="shipping_address_row_input" ref="shipping_address1" placeholder={'第1行'} defaultValue={this.objShippingAddress.street1} />
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title"></div>
                        <Input className="shipping_address_row_input" ref="shipping_address2" placeholder={'第2行'} defaultValue={this.objShippingAddress.street2} />
                    </div>
                    {/* <div className="shipping_address_row">
                        <div className="shipping_address_row_title"></div>
                        <Input className="shipping_address_row_input" ref="shipping_address3" placeholder={'第3行'} defaultValue={this.objShippingAddress.street3} />
                    </div> */}
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">城市</div>
                        <Input className="shipping_address_row_input" ref="shipping_address_city" placeholder={'Vancouver'} defaultValue={this.objShippingAddress.city} />
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">省份/州</div>
                        <Dropdown overlay={menuStates} trigger={['click']} placement="bottomLeft">
                            <Button className="shipping_address_row_input" >
                                {this.arrShippingStates[this.state.iCurrentState]}
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">国家/地区</div>
                        <Input className="shipping_address_row_input" ref="shipping_address_country" defaultValue={'Canada'} disabled />
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">邮编</div>
                        <Input className="shipping_address_row_input" ref="shipping_address_postcode" defaultValue={this.objShippingAddress.postal_code} />
                    </div>
                    <div className="shipping_address_row">
                        <div className="shipping_address_row_title">联系电话</div>
                        <Input className="shipping_address_row_input" ref="shipping_address_phone" defaultValue={this.objShippingAddress.phone} />
                    </div>
                </Modal>
            );
        }
        // 商家信息/菜单区域
        let uiStoreMainArea = null;
        if (this.state.iCurrentTab === 1) {
            uiStoreMainArea = (
                <div>
                    {uiStoreIntro}
                    {uiStoreHours}
                    {uiStoreDetails}
                </div>
            );
        }
        if (this.state.iCurrentTab === 0) {
            uiStoreMainArea = (
                <div>
                    {uiItemList}
                    {uiItemDetails}
                </div>
            );
        }
        // 在线零售电商不提供商家地址等线下信息
        if (iStoreType === 0) {
            uiMainTabs = null;
            uiStoreMainInfo = (
                <div className="store_key_info">
                    <div className="store_pickup_name">{strStoreName}</div>
                    {uiStoreIntro}
                </div>
            );
        }


        return (
            <div className="store_pickup_page">
                {uiMenButton}
                {uiCartButton}
                {uiMainMenuContents}
                {/* 商家自己的广告轮播区域 */}
                {uiAdsTitle}
                {/* 商家信息区域：名称和地址 */}
                {uiStoreMainInfo}
                {/* 主标签切换区域：详情和下单 */}
                {uiMainTabs}
                {uiStoreMainArea}
                {/* 购物车 */}
                {uiCart}
                {uiModifyAddressDlg}
                {/* 登录提示框 */}
                <Modal title={""} visible={this.state.bLoginReminderDlg} centered={true} maskClosable={true} closable={false}
                    onOk={this.LoginReminder.bind(this, 1)} onCancel={this.LoginReminder.bind(this, -1)}
                    okText={"去登录"} cancelText={"再看看"}>
                    <div className="store_pickup_login_reminder">
                        <Icon type="info-circle" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '5vw', marginRight: '2vw' }} />
                        {"请先登录！"}</div>
                </Modal>
            </div>
        );
    }

}
// export default Store;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'en', region: 'CA'
})(Form.create()(StoreRetail));
// export default Form.create()(Store);
