import React, { Component } from "react";

import Input from 'antd/es/input';

// import { Input } from 'antd'
import '../../../styles/ClientSide/UI_Module/search_box.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

class SeachBox extends Component {
    constructor(props) {
        super();
        this.state = {};
        this.bOnFocus = false;
        this.strSearchKeywords = "";
    }
    // 进行搜索
    BeginSearch = (value) => {
        this.strSearchKeywords = value;
        // DataStatistic
        UserActiveUpdateBack(4, { active: "点击首页搜索栏按钮", keywords: value }, this.JumpToSearchPage);
    }
    // 跳转到搜索界面
    JumpToSearchPage = () => {
        if (this.strSearchKeywords !== null && this.strSearchKeywords.trim() !== "" && this.strSearchKeywords !== undefined && this.strSearchKeywords !== 'undefined')
            window.location.href = '/webapp/search?=' + this.strSearchKeywords;
        else
            window.location.href = '/webapp/search';
    }
    // 使用了搜索栏
    UserSearchBox = (value) => {
        if (this.bOnFocus)
            return;
        this.bOnFocus = true;
        // DataStatistic
        UserActiveUpdate(3, { active: "使用首页搜索栏" });
    }
    // 搜索栏内容发生了变化
    // SearchBoxChange = () => {
    //     console.log("Search Box Changed!");
    // }
    // 渲染完毕……
    componentDidMount() { }

    render() {
        return (
            <Input.Search className="search_box" /*size="large"*/ placeholder="搜索商家/商品/服务……"
                onSearch={this.BeginSearch.bind(this)} onFocus={this.UserSearchBox} />
        );
    }
}
export default SeachBox;
