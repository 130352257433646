import React, { Component } from "react";
import axios from 'axios';

import message from 'antd/es/message';
import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';

// import { message, Empty, Spin } from 'antd';
import '../../styles/ClientSide/item_page.css'
import '../../styles/icon_image.css'
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"

class Item extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            objItem: {
                id: 0, name: '商品/服务名称', price: '$0.00', image: '',
                description: '该商品没有任何描述……'
            }
        };
        let strUrl = window.location.pathname;
        let strParams = strUrl.split('/');
        this.iStoreID = strParams[strParams.length - 3];
        this.strStoreImage = "";
    }
    // 返回按钮响应
    BackBtnClick = () => {
        // DataStatistic
        UserActiveUpdateBack(2, { active: "返回商家页" }, this.JumpBackToStore);
    }
    // 跳回商家页面
    JumpBackToStore = () => {
        window.location.href = '/webapp/store/' + this.iStoreID + "?tab=0";
        // window.history.back();

        // window.location.href = document.referrer;
        // window.history.go(-1);
    }
    // 获取商品信息
    GetItemInfo = () => {
        axios('/GetItem', { params: this.props.match.params })
            .then(({ data }) => {
                if (data.length > 0) {
                    // DataStatistic
                    UserActiveUpdate(0, { active: "访问商品页" });
                    if (data[0].description === '') {
                        data[0].description = '该商品没有任何描述……';
                    }
                    if (data[0].price.indexOf('$') === -1) {
                        data[0].price = "$ " + data[0].price;
                    }
                    if (data[0].image === "") {
                        this.GetItemAdsImage(data[0]);
                    } else {
                        this.setState({
                            bLoading: false,
                            objItem: data[0]
                        });
                    }
                } else {
                    message.warning("商品不存在，可能已下架！");
                    this.setState({
                        bLoading: false,
                        objItem: null
                    });
                }
            });
    }
    // 获取商品通用头图
    GetItemAdsImage = (item) => {
        axios('/GetAdsImages', {
            params: { store_id: [this.iStoreID], ads_order: 10 }
        }).then(
            ({ data }) => {
                if (data.length > 0)
                    this.strStoreImage = data[0].image;
                this.setState({
                    bLoading: false,
                    objItem: item
                });
            }
        ).catch(function (error) { console.log(error); });
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetItemInfo();
    }

    render() {

        // 用户操作区域：收藏，喜欢，分享等
        // let uiUserActives = (
        //     // <div className="item_actives_area">
        //     //     <div className="item_actives_btn like_icon" />
        //     //     <div className="item_actives_btn unstar_icon" />
        //     //     <div className="item_actives_btn share_icon" />
        //     // </div>
        //     <div className="item_start_btn unstar_icon" />
        // );
        let uiImage = (
            <div className="item_ads_img_area" />
        );
        let uiItemInfo = (
            <div>
                {/* <div className="item_back_btn back_icon" onClick={this.BackBtnClick} /> */}
                <div className="item_loading">
                    <Spin size="large" />
                </div>
            </div>
        );
        if (!this.state.bLoading) {
            if (this.state.objItem === null) {
                uiItemInfo = (
                    <div>
                        <div className="item_back_btn back_icon" onClick={this.BackBtnClick} />
                        <Empty className="item_empty" description="未找到商品信息" />
                    </div>
                );
            } else {
                let uiLogo = (<div />);
                let strImage = "";
                // 当该商品没有专门的展示图片，并且它的图标不为空的话
                if (this.state.objItem.image === "") {
                    if (this.state.objItem.logo !== "") {
                        uiLogo = <img className="item_ads_logo" src={this.state.objItem.logo} alt='' />;
                    }
                    strImage = this.strStoreImage;
                }
                else
                    strImage = this.state.objItem.image;
                uiImage = (
                    <div className="item_ads_img_area">
                        <img className="item_ads_img" src={strImage} alt='' />
                        {uiLogo}
                    </div>);
                uiItemInfo = (
                    <div>
                        <div className="item_back_btn back_icon" onClick={this.BackBtnClick} />
                        <div className="item_tip">图片仅供参考</div>
                        {/* <Rate className="item_rate" character={<Icon type="fire" theme="filled" />} allowHalf defaultValue={3.5} /> */}
                        <div className="item_name">{this.state.objItem.name}</div>
                        <div className="item_price_area">
                            <div className="item_price">{this.state.objItem.price}</div>
                            <div className="item_add_area">
                                <div className="item_add_btn">－</div>
                                <div className="item_add_num">1</div>
                                <div className="item_add_btn">＋</div>
                            </div>
                        </div>
                        <div className="item_description">
                            {this.state.objItem.description}
                            <div className="item_description_icon about_us_icon" />
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="item_page">
                {uiImage}
                {/* {uiUserActives} */}
                {uiItemInfo}
            </div>
        );
    }
}
export default Item;
