import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';

// import { Input, Tooltip } from 'antd';

import "../../styles/ClientSide/location_page.css";
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { GetUserLocation } from "../../functions/PositionProcess.js"
import { CheckUserLocationFromCookie } from "../../functions/PositionProcess"


const { Search } = Input;
const mapStyles = { width: '90vw', height: '50vh' };

class Location extends Component {
    constructor(props) {
        super();

        let strParams = window.location.search
        strParams = strParams.substring(strParams.indexOf('?') + 1);

        this.iStoreID = -1;
        if (strParams.indexOf('store') !== -1) {
            this.iStoreID = parseInt(strParams.split('&')[0].split('=')[1], 10);
        }
        console.log("Store ID = " + this.iStoreID);

        this.iOrder = -1;
        if (strParams.indexOf('order') !== -1) {
            this.iOrder = parseInt(strParams.split('&')[1].split('=')[1], 10);
        }
        console.log("iOrder = " + this.iOrder);

        this.state = {
            iMapZoom: 15,
            arrCurrentPos: { lat: null, lng: null },
            strFormattedAddress: ""
        };
        this.bRelocating = true;
        this.strSearchDefaultValue = "";
    }
    // 跳转回上个界面
    BackButtonClicked = () => {
        // DataStatistic
        UserActiveUpdateBack(1, { active: "返回按钮点击" }, this.JumpToPreviousPage);
    };
    // 
    JumpToPreviousPage = () => {
        console.log("JumpToPreviousPage: " + this.iStoreID);
        if (this.iStoreID > 0) {
            window.location.href = '/webapp/store/' + this.iStoreID + "?tab=0&order=" + this.iOrder;
        } else {
            // window.location.href = document.referrer;
            window.history.go(-1);
        }
    }
    // 搜索一个位置
    LocationSearch = () => {
        let strSearch = this.refs.LocationSearchBox.input.state.value;
        // DataStatistic
        UserActiveUpdate(2, { active: "输入一个地址/关键词并定位", address: strSearch });
        let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        strUrl += strSearch + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        axios.get(strUrl)
            .then((response) => {
                console.log(response.data.results[0]);
                let strFullAddress = response.data.results[0].formatted_address;
                console.log(strFullAddress);
                let strCity = "";
                if (strFullAddress.indexOf(',') === -1) {
                    strCity = response.data.results[0].address_components[0].short_name;
                }
                else {
                    let arrAddress = response.data.results[0].formatted_address.split(',');
                    strCity = arrAddress[arrAddress.length - 2].trim();
                }
                console.log(strCity);
                let strLocation = strCity + "," +
                    response.data.results[0].geometry.location.lat + "," +
                    response.data.results[0].geometry.location.lng + "," +
                    strFullAddress;
                cookie.save('wm_user_location', strLocation, { path: '/' });
                this.setState({
                    ...this.state,
                    iMapZoom: 15,
                    arrCurrentPos: response.data.results[0].geometry.location,
                    strFormattedAddress: response.data.results[0].formatted_address
                });
            }).catch(err => {
                console.log(err)                     //Axios entire error message
                console.log(err.response.data.error) //Google API error message 
            });
    }
    // 自动定位按钮点击
    RelocateButtonClicked = () => {
        if (this.bRelocating)
            return;
        // DataStatistic
        UserActiveUpdate(3, { active: "点击自动定位按钮" });
        this.bRelocating = true;
        this.refs.LocationSearchBox.input.state.value = "";
        this.strSearchDefaultValue = "";
        GetUserLocation(this.AfterGetLocation);
    }
    // 获得了返回的地址
    AfterGetLocation = (city, address, position) => {
        console.log("After Get Location");
        console.log(city, address, position);
        this.bRelocating = false;
        this.setState({
            ...this.state,
            iMapZoom: 15,
            arrCurrentPos: position,
            strFormattedAddress: address
        });
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问定位页" });
        // 检查Cookie中的用户位置信息
        CheckUserLocationFromCookie(true, this.AfterGetLocation);
    }

    render() {
        return (
            <div className="location_page" style={{ height: 'window.height()' }}>
                <div className="location_back_button back_icon" onClick={this.BackButtonClicked} />
                <div className="location_title">
                    <div className="location_title_text">定位信息</div>
                </div>
                <div className="search_box_area">
                    <Search className="location_search_box" placeholder="输入目标地址/关键词……"
                        defaultValue={this.strSearchDefaultValue} ref="LocationSearchBox"
                        onSearch={this.LocationSearch} allowClear={true} />
                </div>
                <div className="relocate_area">
                    <div className="relocate_text">当前指定的地址:</div>
                    <Tooltip title={"如果定位有误，可手动输入您的目标地址"}
                        placement="topRight" >
                        <div className="relocate_button" onClick={this.RelocateButtonClicked}>
                            自动定位</div>
                    </Tooltip>
                </div>
                <div className="current_location">
                    {this.state.strFormattedAddress}
                </div>
                <div className="location_google_map" >
                    <Map google={this.props.google} zoom={this.state.iMapZoom} style={mapStyles} initialCenter={this.state.arrCurrentPos}
                        center={this.state.arrCurrentPos}>
                        <Marker position={this.state.arrCurrentPos} />
                    </Map>
                </div>
            </div>
        );
    }
}
// export default Location;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
    language: 'cn', region: 'CA'
})(Location);