import React, { Component } from "react";
// import ReactPlayer from 'react-player';
import axios from 'axios';
import cookie from 'react-cookies';

// import Clipboard from 'react-clipboard.js';

import Icon from 'antd/es/icon';
// import Input from 'antd/es/input';
import Collapse from 'antd/es/collapse';
// import Modal from 'antd/es/modal';
// import Empty from 'antd/es/empty';
// import Spin from 'antd/es/spin';
// import Menu from 'antd/es/menu';
// import Button from 'antd/es/button';
// import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import "../../styles/ClientSide/login_register_page.css";
import "../../styles/ClientSide/webpage.css";
import "../../styles/icon_image.css";

import "antd/dist/antd.css";

import { UserActiveUpdate } from "../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
const { Panel } = Collapse;
// const { TextArea } = Input;

// const mapStyles = { width: '100%', height: '100%' };

class LekonWebsite extends Component {
    constructor(props) {
        super();
        // console.log("host name = ", window.location.hostname, window.location.host);
        // console.log("domain name = ", document.domain);
        // console.log("url = ", window.location.href);


        let strDomain = window.location.href;
        if (strDomain.indexOf('mouthing.life') >= 0/*|| strDomain.indexOf('mouthing.info') >= 0*/) {
            console.log("forward to zhihe express");
            window.location.href = 'https://www.mouthing.info/zhihe';
        }

        // let strParams = window.location.search;  // 筛选出?后面的部分
        // strParams = strParams.substring(strParams.indexOf('?') + 1);
        // this.strCNum = "";
        // if (strParams.indexOf('cnum') >= 0)
        //     this.strCNum = strParams.split('cnum=')[1];
        // this.strUserID = "";
        // if (strParams.indexOf('uid') >= 0)
        //     this.strUserID = strParams.split('uid=')[1];
        // this.iTab = 0;
        // if (strParams.indexOf('tab') >= 0)
        //     this.iTab = parseInt(strParams.split('tab=')[1], 10);
        // this.strUserCode = "";
        // if (strParams.indexOf('user') >= 0)
        //     this.strUserCode = strParams.split('user=')[1];

        this.iTab = 0;
        let strJumpPosition = window.location.href;
        if (strJumpPosition.indexOf('#') >= 0) {
            strJumpPosition = strJumpPosition.split('#')[1];
            console.log("strJumpPosition = ", strJumpPosition);
            // if (strJumpPosition === "tracking")
            //     this.iTab = 0;
            if (strJumpPosition === "vision")
                this.iTab = 0;
            if (strJumpPosition === "partners")
                this.iTab = 1;
            if (strJumpPosition === "service")
                this.iTab = 2;
            if (strJumpPosition === "contact")
                this.iTab = 3;

        }


        this.state = {
            bLoading: true,
            bLogin: false,
            bSubmitOrderDlg: false,
            iCurrentMenu: this.iTab,
            // objPickupLocation: { lat: null, lng: null },
            iCurrentOrderStatus: 0,
            bDeliveryStatusFilterChange: false
        };
        this.objUserInfo = {};
        this.arrAddOrders = [];
        // this.arrMenuButton = [
        //     { name: "追踪", icon: 'search' },
        //     { name: "简介", icon: 'profile' },
        //     { name: "服务", icon: 'branches' },
        //     { name: "联系", icon: 'phone' }
        // ];
        this.arrMenuButton = [
            { name: "Vision", icon: 'compass' },
            { name: "Partners", icon: 'team' },
            { name: "Services", icon: 'branches' },
            { name: "Contacts", icon: 'phone' }
        ];
        this.arrOrderListOge = [];
        this.arrOrderList = [];
        this.arrOrderShowList = [];
        this.arrTransitTrackingNo = [];
        this.arrPackageDetails = [];
        this.arrOrderStatus = ["待签收", "已签收", "已合箱", "已发货", "已投递"];  // 物流用户订单状态用
        this.arrOrderStatusMenu = ["全部", "待签收", "已签收", "已合箱", "已发货", "已投递"];
        this.objCurrentOrder = { comments: "" };
        this.arrDeliveryStatusFilter = ["签收后立即转运", "签收后寄存在仓库（最多90天），等待其它货物"];
        this.arrOrderDeliveryStatus = [];
    }
    // 切换菜单按钮
    SwitchMenuButton = (index) => {
        console.log("SwitchMenuButton: index = ", index);
        this.setState({
            ...this.state,
            iCurrentMenu: index,
            bLoading: false,
            iCurrentOrderStatus: 0
        });
    }
    // 获取已合箱包裹的物流信息
    GetPackageTransitInfo = () => {
        for (let i = 0; i < this.arrOrderShowList.length; i++) {
            if (this.arrOrderShowList[i].length > 0) {
                axios('/GetPackageDetails', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                    .then(({ data }) => {
                        this.arrPackageDetails.push(data[0]);
                        axios('/GetPackageAutoOrder', { params: { package_no: this.arrOrderShowList[i][0].package_no, store_id: 20000 } })
                            .then(({ data }) => {
                                this.arrTransitTrackingNo.push(data);
                                this.setState({
                                    ...this.state,
                                    bLoading: false
                                });
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }
        }
    }
    // 整理出合箱分级数据
    GetOrderPackageList = () => {
        this.arrOrderShowList = [];
        let strPackageNo = "";
        let arrOrderPackage = [];
        for (let i = 0; i < this.arrOrderList.length; i++) {
            if (this.arrOrderList[i].package_no !== null && this.arrOrderList[i].package_no !== '') {
                if (strPackageNo === "") {
                    strPackageNo = this.arrOrderList[i].package_no;
                    arrOrderPackage.push(this.arrOrderList[i]);
                    if (i === this.arrOrderList.length - 1) {
                        this.arrOrderShowList.push(arrOrderPackage);
                    }
                } else {
                    if (strPackageNo === this.arrOrderList[i].package_no) {
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    } else {
                        this.arrOrderShowList.push(arrOrderPackage);
                        arrOrderPackage = [];
                        strPackageNo = this.arrOrderList[i].package_no;
                        arrOrderPackage.push(this.arrOrderList[i]);
                        if (i === this.arrOrderList.length - 1) {
                            this.arrOrderShowList.push(arrOrderPackage);
                        }
                    }
                }
            } else {
                if (strPackageNo !== "") {
                    strPackageNo = "";
                    this.arrOrderShowList.push(arrOrderPackage);
                    arrOrderPackage = [];
                }
                this.arrOrderShowList.push(this.arrOrderList[i]);
            }
        }
    }
    // 生成订单子控件
    GenerateChildPanelUI = (item, index) => {
        let strDateTime = "无记录";
        if (item.order_date !== null) {
            if (item.order_date.indexOf('T') >= 0) {
                let dateNow = new Date();
                let dateOrder = new Date(item.order_date);
                let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                let day = parseInt(drr / (24 * 60 * 60 * 1000));
                if (day > 0)
                    strDateTime = day + "天前";
                else {
                    let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                    if (hours > 0) {
                        strDateTime = hours + "小时前";
                    } else {
                        let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                        if (minutes > 0) {
                            strDateTime = minutes + "分钟前";
                        } else {
                            strDateTime = "刚刚";
                        }
                    }
                }
            }
        }
        // let strPriceWeight = "还未称重";
        // if (item.express_info !== null && item.express_info !== "") {
        //     strPriceWeight = JSON.parse(item.express_info);
        //     if (parseFloat(strPriceWeight.price_weight) <= 0.0)
        //         strPriceWeight = "还未称重";
        //     else
        //         strPriceWeight = parseFloat(strPriceWeight.price_weight) + " kg";
        // }
        let uiPackageNo = null;
        let uiStatus = null;
        if (item.package_no !== null && item.package_no !== "")
            uiPackageNo = (
                <div className="web_center_order_details_text">
                    {"合箱编码：" + item.package_no}</div>
            );
        else
            uiStatus = (<div className="web_center_order_details_title_right">
                {" " + this.arrOrderStatus[parseInt(item.status, 10)]}
            </div>);
        let uiTitle = (
            <div className="web_center_order_details_title">
                <div className="web_center_order_details_title_left">
                    <Icon type="file" theme="filled" />
                    {" " + item.express_order}
                </div>
                {uiStatus}
            </div>
        );
        uiPackageNo = null;
        let uiComments = null;
        if (item.comments)
            if (item.comments !== null && item.comments !== "")
                if (item.comments.trim() !== "")
                    uiComments = <div className="web_center_order_details_text">
                        {"备注信息：" + item.comments}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>

        let iDeliveryStatus = 0;
        if (item.delivery_status) {
            if (item.delivery_status !== null && item.delivery_status !== "") {
                iDeliveryStatus = parseInt(item.delivery_status, 10);
            }
        }
        return (
            <Panel header={uiTitle} key={(index + 1).toString()} >
                <div className="web_center_order_details_area">
                    <div className="web_center_order_details_text">
                        {"提交时间：" + strDateTime}
                    </div>
                    <div className="web_center_order_details_text">
                        {"发货选项："}
                        {this.arrDeliveryStatusFilter[iDeliveryStatus]}
                        <div className="web_center_modify_button" onClick={this.AddNewOrder.bind(this, 1, item)}>修 改</div>
                    </div>
                    {uiComments}
                    {uiPackageNo}
                </div>
            </Panel>
        );
    }
    // 生成订单母控件
    GenerateParentPanelUI = (orders, index) => {
        let uiPanel = null;

        // 已合箱的订单
        if (orders.length) {
            let uiTitle = (
                <div className="web_center_order_details_title">
                    <div className="web_center_order_details_title_left">
                        <Icon type="code-sandbox" />
                        {" 合箱编码 " + orders[0].package_no}
                    </div>
                    <div className="web_center_order_details_title_right">
                        {" " + this.arrOrderStatus[parseInt(orders[0].status, 10)]}
                    </div>
                </div>
            );
            let strPriceWeight = "货物称重：还未称重";
            let strTotalPrice = "总计费用：还未称重";
            let strAutoOrderNo = "";
            if (this.arrPackageDetails.length > 0) {
                for (let i = 0; i < this.arrPackageDetails.length; i++) {
                    if (this.arrPackageDetails[i].package_no === orders[0].package_no) {
                        strPriceWeight = "货物称重：" + this.arrPackageDetails[i].price_weight + " kg";
                        strTotalPrice = "总计费用：$ " + this.arrPackageDetails[i].total_price;
                        strAutoOrderNo = this.arrPackageDetails[i].auto_order_no;
                        break;
                    }
                }
            }
            let iTargetOrder = 0;
            if (this.arrTransitTrackingNo.length > 0) {
                for (let i = 0; i < this.arrTransitTrackingNo.length; i++) {
                    if (this.arrTransitTrackingNo[i] === strAutoOrderNo) {
                        iTargetOrder = i;
                        break;
                    }
                }
            }
            // if (this.arrPackageDetails[index]) {
            //     strPriceWeight = "货物称重：" + this.arrPackageDetails[index].price_weight + " kg";
            // }
            // if (this.arrPackageDetails[index]) {
            //     strTotalPrice = "总计费用：$ " + this.arrPackageDetails[index].total_price;
            // }
            uiPanel = (
                <Panel header={uiTitle} key={(index + 1).toString()}>
                    {/* <div className="web_center_transit_tracking_no">
                        {"物流追踪号：" + this.arrTransitTrackingNo[index]}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, index)}>查看物流</div>
                    </div> */}
                    <div className="web_center_transit_tracking_no">
                        {strPriceWeight}
                        <div className="web_center_view_order_details_button"
                            onClick={this.ViewTransitDetails.bind(this, iTargetOrder)}>查看物流</div>
                    </div>
                    <div className="web_center_transit_tracking_no">
                        {strTotalPrice}
                    </div>
                    <Collapse >
                        {orders.map((order, indexChild) => {
                            let uiChildPanel = this.GenerateChildPanelUI(order, indexChild);
                            return (uiChildPanel);
                        })}
                    </Collapse>
                </Panel>
            );
        }
        // 未合箱的订单
        else {
            uiPanel = this.GenerateChildPanelUI(orders, index);
        }
        return uiPanel;
    }
    // 跳转到物流追踪页面
    ViewTransitDetails = (index) => {
        window.location.href = '/tracking/no=' + this.arrTransitTrackingNo[index];
    }
    // 跳转用户中心
    JumpToUserCenter = () => {
        // 已登录
        if (this.state.bLogin) {
            window.location.href = '/xiyouji/user_center';
        }
        // 未登录
        else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            window.location.href = '/webapp/login?store=xiyouji';
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    if (this.iTab !== 0)
                        this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    console.log("user info : ", this.objUserInfo);
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                        // 分配storage Code
                        axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id, code: 'LK39' })
                            .then(({ data }) => {
                                this.objUserInfo.storage_code = data[0].storage_code;
                                console.log("user info = ", this.objUserInfo);
                                this.SwitchMenuButton(this.iTab);
                                this.setState({
                                    ...this.state,
                                    bLogin: true
                                });
                            }).catch(function (error) { console.log(error); });
                    } else {
                        this.SwitchMenuButton(this.iTab);
                        this.setState({
                            ...this.state,
                            bLogin: true
                        });
                    }
                }
            }).catch(function (error) { console.log(error); });
    }
    // 跳回首页
    HomePage = () => {
        window.location.href = '/lekon';
    }
    // 拷贝信息到粘贴板
    CopyAddressInfo = () => {
        // console.log(strInfo);
        // if (navigator.clipboard) {
        message.info("复制成功！");
        //     navigator.clipboard.writeText(strInfo);
        // } else {
        //     message.info("正在为您跳转到安全链接！请重新尝试复制");
        //     window.location.href = "https://www.mouthing.info";
        // }
    }
    // 添加订单
    AddNewOrder = (iType, objOrder) => {
        if (iType === 0) {
            let strAddOrder = this.refs.order_input.state.value;
            if (!strAddOrder || strAddOrder.trim() === "") {
                message.warning("请输入快递单号！");
                return;
            }
            this.arrAddOrders = [];
            this.arrOrderDeliveryStatus = [];
            if (strAddOrder.indexOf('\n') >= 0) {
                let arrAddOrders = strAddOrder.split('\n');
                for (let i = 0; i < arrAddOrders.length; i++) {
                    if (arrAddOrders[i] !== "" && arrAddOrders[i].trim() !== "") {
                        this.arrAddOrders.push(arrAddOrders[i].trim());
                        this.arrOrderDeliveryStatus.push(0);
                    }
                }
            } else {
                this.arrAddOrders.push(strAddOrder);
                this.arrOrderDeliveryStatus.push(0);
            }
        } else {
            this.arrAddOrders = [];
            this.arrAddOrders.push(objOrder.express_order);
            this.arrOrderDeliveryStatus = [];
            this.arrOrderDeliveryStatus.push(objOrder.delivery_status);
            this.objCurrentOrder = objOrder;
        }
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            bSubmitOrderDlg: true
        });
    }
    // 确认/取消提交订单（物流单号专用）
    SubmitOrder = (bConfirm) => {
        this.objCurrentOrder = { comments: "" };
        if (!bConfirm) {
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            return;
        }
        let arrExpressOrders = [];
        for (let i = 0; i < this.arrAddOrders.length; i++) {
            let strInputKey = "express_comments_input_" + i.toString();
            let objExpressOrders = {
                express_order: this.arrAddOrders[i],
                receiver: this.objUserInfo.name,
                storage_code: this.objUserInfo.storage_code,
                user_code: this.objUserInfo.code,
                store_id: 20000,
                comments: this.refs[strInputKey].state.value,
                status: 0,
                delivery_status: this.arrOrderDeliveryStatus[i]
            }
            if (objExpressOrders.comments === undefined || objExpressOrders.comments === null) {
                objExpressOrders.comments = "";
            }
            arrExpressOrders.push(objExpressOrders);
        }
        message.loading({ content: '正在提交……', key: g_strMessageKey });
        axios.post('/CreateExpressOrders', {
            arrExpressOrders: arrExpressOrders
        }).then(({ data }) => {
            message.success({ content: "订单编号提交成功！请在订单列表页查看", key: g_strMessageKey, duration: 2 });
            // DataStatistic
            UserActiveUpdate(4, { active: "用户端: 批量提交订单号", action: "新建" });
            if (this.refs.order_input)
                this.refs.order_input.state.value = "";
            this.setState({
                ...this.state,
                bSubmitOrderDlg: false
            });
            this.SwitchMenuButton(2);
        }).catch(function (error) { console.log(error); });

    }
    // 取消订单（自提/通用）
    CancelOrder = (type, index) => {
        if (type === 0) {
            this.setState({
                ...this.state,
                bCancelOrderDlg: true
            });
        } else {
            if (type === 1) {
                let oid = this.arrOrderList[index].oid;
                axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: -1 })
                    .then(({ data }) => {
                        message.info("订单已取消！");
                        this.ShowList(3);
                    });
            }
            this.setState({
                ...this.state,
                bCancelOrderDlg: false
            })
        }
    }
    // 获取自提点位置信息
    GetPickupLocation = (strAddress) => {
        // let strUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        // strUrl += strAddress + ",BC&region=ca&key=AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0";
        // axios.get(strUrl)
        //     .then((response) => {
        //         this.setState({
        //             ...this.state,
        //             objPickupLocation: response.data.results[0].geometry.location
        //         });
        //     }).catch(err => {
        //         console.log(err) //Axios entire error message
        //         console.log(err.response.data.error) //Google API error message 
        //     });
    }
    // 跳转回登录页
    JumpToLogin = () => {
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        // window.location.href = '/webapp/login';
        window.location.href = '/webapp/login?store=xiyouji';
    }
    // 追踪订单
    OrderTracking = () => {
        let strSearch = this.refs.order_search.state.value;
        if (strSearch) {
            if (strSearch === null || strSearch === "") {
                message.warning("请输入需要查询的订单号！");
            } else {
                if (strSearch.trim() === "")
                    message.warning("请输入需要查询的订单号！");
                else
                    window.location.href = '/tracking/no=' + strSearch;
            }
        } else
            message.warning("请输入需要查询的订单号！");
    }
    // 订单筛选下拉框点击
    OrderTypeFilterCLicked = (index) => {
        this.SwitchMenuButton(2);
        this.setState({
            ...this.state,
            iCurrentOrderStatus: index
        });
    }
    // 订单筛选整理
    OrganizeOrderList = () => {
        this.arrOrderList = []
        for (let i = 0; i < this.arrOrderListOge.length; i++) {
            if (this.arrOrderListOge[i].status === this.state.iCurrentOrderStatus - 1) {
                this.arrOrderList.push(this.arrOrderListOge[i]);
            }
        }
    }
    // 退出登录
    LogOut = () => {
        this.setState({
            ...this.state,
            bLogin: false
        });
        // DataStatistic
        UserActiveUpdate(3, { active: "退出登录" });
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        // window.location.href = '/';
    }
    // 转运状态过滤器切换
    SwitchDeliveryStatusFilter = (index, status) => {
        this.arrOrderDeliveryStatus[index] = status;
        this.setState({
            ...this.state,
            bDeliveryStatusFilterChange: true
        });
    }

    // 渲染完毕……
    componentDidMount() {
        document.title = "Lekon物流";
        this.GetPickupLocation("7080 River Rd., Richmond");

    }

    render() {

        let uiFunctionArea = null;
        let uiInfoArea0 = (
            <div className="lekon_website_mission_area" >
                <div className="lekon_website_mission_title">
                    Mission Statement
                    </div>
                <div className="lekon_website_mission_subtitle" >
                    Vision</div>
                <div className="lekon_website_mission_text">
                    · Our success relies on our ability to provide quality services, coupled with an intimate understanding of our clients’ needs.</div>
                <div className="lekon_website_mission_subtitle" >
                    Values</div>
                <div className="lekon_website_mission_text">
                    · We are passionate about our team and its reputation.<br />
                    · We build relationships based on trust and respect.<br />
                    · We continuously improve through innovative solutions.<br />
                    · We achieve success through teamwork and a strong work ethic.<br />
                </div>
            </div >
        );
        let uiInfoArea1 = (
            <div className="webpage_lekon_main_contents_area" >
                <div className="webpage_lekon_sub_contents_area_1">
                    <div className="webpage_area_title2_lekon">
                        LEKON Group Warehousing Partners
                            </div>
                    <div className="webpage_area_text_lekon">
                        <b>Warehouse and Distribution in China</b>
                        <br /><br />
                        Jiangmen, Guangzhou, Shenzhen, Shanghai , Ningbo and Tianjin
                        <br /><br />
                        <b>Warehouse and Distribution in Canada</b>
                        <br /><br />
                        Vancouver ( 3 BW ; 1 FC ; 2 TL ),
                        Calgary ( 2 TL ),
                        Edmonton ( 2 TL ),
                        Winnipeg ( Coming Soon ),
                        Toronto ( 1 BW ; 3 TL ),
                        Montreal ( 1 BW ; 1 FC ; 2 TL )
                    </div>
                </div>
                <div className="webpage_lekon_sub_contents_area_2">
                    <div className="webpage_contents_block1">
                        <div className="webpage_contents_text1">6</div>
                        <div className="webpage_contents_text2">Warehouse In China</div>
                    </div>
                    <div className="webpage_contents_block1 webpage_contents_block2">
                        <div className="webpage_contents_text1">6</div>
                        <div className="webpage_contents_text2">Warehouse In Canada</div>
                    </div>
                </div>
                <div className="webpage_lekon_sub_contents_area_2">
                    <div className="webpage_contents_block1 webpage_contents_block3">
                        <div className="webpage_contents_text1">2</div>
                        <div className="webpage_contents_text2">Headquarter and Branch</div>
                    </div>
                </div>
            </div>
        );
        let uiInfoArea2 = (
            <div className="webpage_lekon_main_contents_area" style={{ border: 'none' }} >
                <div className="webpage_lekon_sub_contents_area_3">
                    <div className="webpage_area_title2_lekon">
                        Our Services
                            </div>
                    <img className="webpage_contents_block4" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1626723038/100-Lecon/LekonImage01_juzvbo.jpg"} />
                </div>
                <div className="webpage_lekon_sub_contents_area_1">
                    <div className="webpage_area_title4_lekon" style={{ marginTop: '150px' }}>
                        Import and Export Bonded Canada Freight Forwarding
                            </div>
                    <div className="webpage_area_text_lekon">
                        As an asset light global logistics provider , LEKON partners with key air , ocean and ground carriers to provide integrated transportation solutions.
                         <br />
                        In addition , we offer multimodal transportation solutions , such as ocean / ground . For urgent or specialized shipments , we have customizable logistics options to keep your cargo visible and moving through your supply chain.
                            </div>
                </div>
            </div >
        );
        let uiInfoArea3 = (
            <div className="webpage_lekon_main_contents_area" style={{ border: 'none' }}>
                <div className="webpage_lekon_sub_contents_area_1">
                    <div className="webpage_area_title4_lekon" >
                        Customs Compliance Services (English , Mandarin , Cantonese)
                            </div>
                    <div className="webpage_area_text_lekon">
                        As a compliance-driven organization, we are positioned to understand and address the intricacies of trade regulations and agreements at the global, regional, national and local levels. We focus on three key elements to ensure adherence to all customs requirements and promote process-strict compliance:
                        <br />
                        <b>· Regulations</b>
                        <br />
                        <b>· Reliable release timing</b>
                        <br />
                        <b>· Value added data</b>
                        <br />
                        <b>· Confidential</b>
                    </div>
                </div>
                <div className="webpage_lekon_sub_contents_area_3">
                    <img className="webpage_contents_block4" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1626723038/100-Lecon/LekonImage02_bcvhzb.png"} />
                </div>
            </div>
        );
        let uiInfoArea4 = (
            <div className="webpage_lekon_main_contents_area" style={{ border: 'none' }}>
                <div className="webpage_lekon_sub_contents_area_3">
                    <img className="webpage_contents_block4" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1626723038/100-Lecon/LekonImage03_oxdqfs.jpg"} />
                </div>
                <div className="webpage_lekon_sub_contents_area_1">
                    <div className="webpage_area_title4_lekon" >
                        Trucking and Ground Services
                            </div>
                    <div className="webpage_area_text_lekon" style={{ paddingRight: '0px' }}>
                        <b>· Truckload Services – From DG to Dry; Domestics to cross border</b>
                        <br /><br />
                        <b>· FTL Services – Local and Trans Province and FBA</b>
                        <br /><br />
                        <b>· LTL Services – Commercial and Personal Moving</b>
                        <br /><br />
                        <b>· Consolidation, Roll Out and Distribution Capabilities</b>
                        <br /><br />
                        <b>· Specialized and Over Dimensional Expertise</b >
                        <br />
                    </div>
                </div>
            </div>
        );
        let uiInfoArea5 = (
            <div className="webpage_lekon_main_contents_area" style={{ marginBottom: '0px' }}>
                <div className="webpage_lekon_sub_contents_area_1">
                    <div className="webpage_area_title4_lekon" >
                        Warehousing and Distribution
                            </div>
                    <div className="webpage_area_text_lekon">
                        We provide a full range of programs to suit your specialized needs, including origin distribution, vendor managed inventory, order fulfillment and returns programs.<br /><br />
                        <b>· Inventory Management</b>
                        <br /><br />
                        <b>· Compliance Inspections</b>
                        <br /><br />
                        <b>· Trans-load</b>
                        <br /><br />
                        <b>· Cross Dock</b>
                        <br /><br />
                        <b>· Pick and Pack</b >
                        <br /><br />
                        <b>· Labeling Services</b >
                        <br /><br />
                        <b>· Bonded Warehousing</b >
                        <br /><br />
                        <b>· Order Fulfillment</b >
                    </div>
                </div>
                <div className="webpage_lekon_sub_contents_area_3">
                    <img className="webpage_contents_block4" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1626723038/100-Lecon/LekonImage04_vn3zoy.jpg"} />
                </div>
            </div>
        );
        let uiLekonInfo = (
            <div className="webpage_lekon_contact_area">
                <div className="webpage_lekon_sub_contents_area_1 webpage_lekon_info_area"
                    style={{ paddingLeft: '125px', paddingTop: '70px' }}>
                    <div className="webpage_lekon_info_row" style={{ fontSize: '30px', marginBottom: '50px' }}>
                        <b>LEKON LOGISTICS LTD.</b><br />
                            My Mission is your expectation
                        </div>
                    <div className="webpage_lekon_info_row">
                        <b>Web</b>: www.mouthing.info/lekon
                        </div>
                    <div className="webpage_lekon_info_row">
                        <b>Email</b>: CSR@Lekonlogistics.com
                        </div>
                    <div className="webpage_lekon_info_row">
                        <b>Address</b>: Unit 223, 7080 River Road, Richmond, BC, V6X 1X5
                        </div>
                </div>
            </div>
        );
        uiFunctionArea = (
            <div className="webpage_lekon_center_area" >
                <div className="webpage_jump_position" id="vision" />
                {uiInfoArea0}
                {/* <div className="webpage_background_vedio" id="vision">
                    <ReactPlayer width='100vw' height='56.25vw' playing={true} loop={true} muted 
                        url={"https://mouthingmanager.s3.us-west-2.amazonaws.com/stores/10000/LekonVedio.mp4"} />
                </div>
                <div className="webpage_area_title1_lekon" >WE DELIVER</div>
                <div className="webpage_area_title1_lekon" >GLOBAL LOGISTICS</div>
                <div className="webpage_search_area_lekon">
                    <Input style={{ width: '450px', height: '60px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '25px', border: 'none', borderRadius: '0px' }}
                        ref="order_search" size="large" placeholder="请输入要查询的运单号" />
                    <div className="webpage_search_button_lekon" onClick={this.OrderTracking}>
                        <Icon type="search" style={{ fontWeight: 'bold', fontSize: '30px' }} />
                    </div>
                </div> */}
                <div className="webpage_jump_position" id="partners" />
                {uiInfoArea1}
                <div className="webpage_jump_position" id="service" />
                {uiInfoArea2}
                {uiInfoArea3}
                {uiInfoArea4}
                {uiInfoArea5}
                <div className="webpage_jump_position" id="contact" />
                {uiLekonInfo}
            </div>
        );

        return (
            <div className="webpage_lekon">
                <div className="webpage_lekon_title_area">
                    <div className="webpage_title_icon_lekon" onClick={this.HomePage} />
                    <div className="webpage_title_menu_row">
                        {this.arrMenuButton.map((button, index) => {
                            let strMenuButtonStyle = "webpage_title_menu_button_lekon";
                            if (this.state.iCurrentMenu === index)
                                strMenuButtonStyle += " webpage_title_menu_button_select_lekon";
                            // let strJumpDiv = "#tracking";
                            let strJumpDiv = "#vision";
                            if (index === 1)
                                strJumpDiv = '#partners';
                            if (index === 2)
                                strJumpDiv = '#service';
                            if (index === 3)
                                strJumpDiv = '#contact';
                            return (
                                <a className={strMenuButtonStyle} /*style={{ width: '115px', minWidth: '115px' }}*/
                                    onClick={this.SwitchMenuButton.bind(this, index)} href={strJumpDiv}>
                                    <Icon type={button.icon} style={{ marginRight: '10px', marginBottom: '2.5px', fontSize: '22.5px' }} />{button.name}</a>
                            );
                        })}
                    </div>
                </div>
                {uiFunctionArea}
            </div>
        );
    }
}
export default LekonWebsite;
// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0',
//     language: 'cn', region: 'CN'
// })(LekonWebsite);



// <div className="webpage_lekon_sub_contents_area_3">
//     <div className="webpage_location_map_lekon">
//         <Map google={this.props.google} zoom={15} style={mapStyles} initialCenter={this.state.objPickupLocation}
//             center={this.state.objPickupLocation} containerStyle={{ position: 'relative', width: '100%', height: '100%' }}>
//             <Marker position={this.state.objPickupLocation} />
//         </Map>
//     </div>
// </div> 
