import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies'

import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Select from 'antd/es/select';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';

// import { Form, Input, Button, Checkbox, Select, message, Tooltip } from 'antd';
import "../../styles/ClientSide/login_register_page.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"

// const g_strMessageKey = 'updating';
const { Option } = Select;

class Register extends Component {
    constructor(props) {
        super();
        this.iStoreID = 0;
        this.iItem = 0;
        this.strFromPage = "";
        let strParams = window.location.search
        strParams = strParams.substring(strParams.indexOf('?') + 1);
        if (strParams.indexOf('store=') !== -1) {
            this.iStoreID = parseInt(strParams.split('=')[1], 10);
            if (strParams.indexOf('&item=') !== -1) {
                this.iItem = parseInt(strParams.split('&')[1].split('=')[1], 10);
            }
        }
        if (strParams.indexOf('from=') !== -1) {
            this.strFromPage = strParams.split('from=')[1];
        }
        let bInvite = false;
        this.iInviteBatch = -1;
        this.strInviteCode = "";
        if (strParams.indexOf('invite') >= 0) {
            bInvite = true;
            this.iInviteBatch = parseInt(strParams.split('invite=')[1], 10);
        }
        this.state = {
            confirmDirty: false,
            bInvite: bInvite,
            bStoreInfo: false
        };
        this.objStoreInfo = -1;
        if (this.iStoreID > 0)
            this.GetStoreData();
    }
    // 登录/注册切换
    BackLoginButtonClicked = (iType) => {
        if (iType === 0) {
            this.JumpToUserCenter();
        } else {
            // DataStatistic
            UserActiveUpdateBack(1, { active: "注册页->登录页" }, this.BackToLogin);
        }
    }
    BackToLogin = () => {
        if (this.iStoreID === 0) {
            if (this.strFromPage === "")
                window.location.href = '/webapp/login';
            else
                window.location.href = '/webapp/login?from=' + this.strFromPage;
        } else {
            if (this.iItem === 0)
                window.location.href = '/webapp/login?store=' + this.iStoreID.toString();
            else
                window.location.href = '/webapp/login?store=' + this.iStoreID + '&item=' + this.iItem;
        }
    }
    // 提交按钮响应
    handleRegisterSubmit = e => {
        // DataStatistic
        UserActiveUpdate(1, { active: "注册页->提交注册" });
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.name.length < 2) {
                    message.warning("用户名太短，至少2个字符！")
                    return;
                }
                if (values.password.length < 6) {
                    message.warning("密码太短，至少6位！")
                    return;
                }
                // 邀请码
                if (values.code !== undefined) {
                    if (values.code !== null && values.code.trim() !== "") {
                        if (values.code.split('_')[0] !== 'iu') {
                            message.warning("输入的邀请码无效！")
                            return;
                        }
                    }
                }
                // 推荐码
                if (values.invite_code) {
                    if (values.invite_code === null || values.invite_code.trim() === "") {
                        this.strInviteCode = "";
                    } else {
                        this.strInviteCode = values.invite_code;
                    }
                }
                if (values.agreement !== true) {
                    message.warning("请阅读并同意拼单购物协议！")
                    return;
                }
                let objNewUser = {};
                objNewUser.name = values.name;
                objNewUser.password = values.password;
                objNewUser.level = 1;
                objNewUser.status = 1;
                objNewUser.email = null;
                if (values.email !== undefined) {
                    if (values.email.trim() !== "" && values.email.trim() !== null)
                        objNewUser.email = values.email;
                }
                objNewUser.phone = null;
                if (values.phone !== undefined) {
                    if (values.phone.trim() !== "" && values.phone.trim() !== null)
                        objNewUser.phone = "(+" + values.phone_area_code + ") " + values.phone;
                }
                objNewUser.store_id = null;
                // 至禾物流专用判断
                if (this.objStoreInfo.id === 20000)
                    objNewUser.store_id = 20000;
                let strEmailCheck = objNewUser.email;
                if (strEmailCheck === null)
                    strEmailCheck = "";
                let strPhoneCheck = objNewUser.phone;
                if (strPhoneCheck === null)
                    strPhoneCheck = "";

                if (this.strInviteCode === "无")
                    this.strInviteCode = "";
                objNewUser.invite_code = this.strInviteCode;
                // console.log("EmailPhoneCheck : ", objNewUser);
                // return;
                axios('/EmailPhoneCheck', { params: { email: strEmailCheck, phone: strPhoneCheck } })
                    .then(({ data }) => {
                        if (data.status === 1) {
                            axios.post('/NewUser', objNewUser)
                                .then(({ data }) => {
                                    if (data.status === 1) {
                                        // console.log("NewUser ok : ", data);
                                        message.success(data.message);
                                        cookie.save('wm_user_code', data.code, { path: '/' });
                                        cookie.save('wm_login_status', 1, { path: '/' });
                                        // DataStatistic
                                        UserActiveUpdateBack(1, { active: "注册页->注册成功" }, this.JumpToUserCenter);
                                    } else {
                                        // console.log("NewUser failed : ", data);
                                        message.warning(data.message);
                                    }
                                }).catch(function (error) { console.log(error); });
                        } else {
                            message.warning(data.message);
                        }
                    }).catch(function (error) { console.log(error); });
            }
        });
    };
    // 跳转到用户中心/目标页面
    JumpToUserCenter = () => {
        if (this.iStoreID === 0) {
            if (this.strFromPage === "")
                window.location.href = '/webapp/user?tab=2';
            else
                window.location.href = '/webapp/' + this.strFromPage;
        } else {
            // if (this.iStoreID === 10005) {
            //     if (this.iItem === 0)
            //         window.location.href = '/webapp/store/' + this.iStoreID.toString();
            //     else
            //         window.location.href = '/webapp/store/' + this.iStoreID + '?item=' + this.iItem;
            // }
            // else
            //     window.location.href = '/webapp/store/' + this.iStoreID + "?tab=0";
            // 团购页面
            if (this.objStoreInfo.type === 2) {
                if (this.iItem === 0)
                    window.location.href = '/webapp/store/' + this.iStoreID.toString();
                else
                    window.location.href = '/webapp/store/' + this.iStoreID + '?item=' + this.iItem;
            }
            else {
                if (this.objStoreInfo.type === 5) {
                    window.location.href = '/webapp/user_profile/?tab=5&store=' + this.iStoreID;
                } else
                    window.location.href = '/webapp/store/' + this.iStoreID + "?tab=0";
            }
        }
    }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    // Mouthing协议点击
    AgreementClicked = () => {
        // DataStatistic
        UserActiveUpdate(1, { active: "查看Mouthing协议" });
    }
    // 获取并分配一个邀请码
    PublishInviteCode = () => {
        axios.get('/GetOnePublishCode', { params: { iBatch: this.iInviteBatch } })
            .then(({ data }) => {
                if (data.status === 1) {
                    this.strInviteCode = data.code.code;
                } else {
                    message.info("本批邀请码已发放完毕，欢迎关注下次活动！")
                    this.strInviteCode = "无";
                }
                this.setState({
                    ...this.state,
                    bInvite: true
                });
            });
    }
    // 从数据库获取当前商家的数据
    GetStoreData() {
        axios('/GetStoreInfo', { params: { id: this.iStoreID } }).then(({ data }) => {
            if (data.length <= 0) {
                message.warning("商家不存在！");
                return;
            }
            this.objStoreInfo = data[0];
            this.setState({
                ...this.state,
                bStoreInfo: true
            });

        }).catch(function (error) { console.log(error); });
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问注册页" });
        if (this.state.bInvite) {
            console.log("componentDidMount, invite is true");
            this.PublishInviteCode();
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;  // 注册用
        const prefixSelector = getFieldDecorator('phone_area_code', { initialValue: '1', })(
            <Select style={{ width: 'auto' }}>
                <Option value="1">+1</Option>
                <Option value="86">+86</Option>
            </Select>
        );

        // 注册表单
        let uiRegisterForm = null;
        let strAgreement = "";
        if (this.state.bStoreInfo !== undefined) {
            if (this.objStoreInfo.type === 5)
                strAgreement = "物流协议";
            if (this.objStoreInfo.type === 2 || this.objStoreInfo.type === 3)
                strAgreement = "在线购物协议";
        }

        if (this.state.bInvite) {
            uiRegisterForm = (
                <div className="register_form_area">
                    <div className="login_form_area register_area_horizontal_space">
                        <Form onSubmit={this.handleRegisterSubmit}>
                            <Form.Item label="用户名" style={{ margin: '0', padding: '0' }}>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入用户名!', whitespace: true }],
                                })(<Input allowClear={true} />)}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '4vw' }}
                                label="设置密码" hasFeedback>
                                {getFieldDecorator('password', {
                                    rules: [
                                        { required: true, message: '请输入密码', },
                                        { validator: this.validateToNextPassword, },
                                    ],
                                })(<Input.Password allowClear={true} />)}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '4vw' }}
                                label="确认密码" hasFeedback>
                                {getFieldDecorator('confirm', {
                                    rules: [
                                        { required: true, message: '请确认密码', },
                                        { validator: this.compareToFirstPassword, },
                                    ],
                                })(<Input.Password onBlur={this.handleConfirmBlur} allowClear={true} />)}
                            </Form.Item>
                            <Form.Item label="邮箱" style={{ margin: '0', padding: '0', marginTop: '4vw' }} >
                                {getFieldDecorator('email', {
                                    rules: [
                                        { type: 'email', message: '请输入有效的邮箱', },
                                        { required: false, message: '邮箱不能为空', },
                                    ],
                                })(<Input allowClear={true} />)}
                            </Form.Item>
                            <Form.Item label="电话" style={{ margin: '0', padding: '0', marginTop: '4vw' }} >
                                {getFieldDecorator('phone', {
                                    rules: [{ required: false, message: '请输入电话号码' }],
                                })(<Input addonBefore={prefixSelector} style={{ width: '100%' }}
                                    allowClear={true} />)}
                            </Form.Item>
                            <div className="invite_code">邀请码</div>
                            <Input ref="invite_code" value={this.strInviteCode} disabled={true} />
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5vw' }} >
                                {getFieldDecorator('agreement', {
                                    valuePropName: 'checked',
                                })(
                                    <div className="agreement_forget_password">
                                        <Checkbox>我已阅读</Checkbox>
                                        <Tooltip title={"即将呈现，敬请期待！"} trigger="click" onClick={this.AgreementClicked}>
                                            <div className="click_link">{strAgreement}</div>
                                        </Tooltip>
                                    </div>
                                )}
                            </Form.Item>
                            <div className="login_button_area">
                                <Button type="primary" htmlType="submit" className="register_button_line">
                                    注 册</Button>
                                或
                                <div className="click_link" onClick={this.BackLoginButtonClicked.bind(this, 1)}>使用已有账号登录</div>
                            </div>
                        </Form>
                    </div>
                </div>
            );
        } else {
            let uiInviteCode = null;
            if (this.objStoreInfo.type === 5)
                uiInviteCode = (
                    <Form.Item label="进仓号" style={{ margin: '0', padding: '0', marginTop: '4vw' }}>
                        {getFieldDecorator('invite_code', {
                            rules: [{ required: true, message: '请输入进仓号!', whitespace: true }],
                        })(<Input allowClear={true} />)}
                    </Form.Item>
                );
            uiRegisterForm = (
                <div className="register_form_area">
                    <div className="login_form_area register_area_horizontal_space">
                        <Form onSubmit={this.handleRegisterSubmit}>
                            <Form.Item label="用户名" style={{ margin: '0', padding: '0' }}>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入用户名!', whitespace: true }],
                                })(<Input allowClear={true} />)}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '4vw' }}
                                label="设置密码" hasFeedback>
                                {getFieldDecorator('password', {
                                    rules: [
                                        { required: true, message: '请输入密码', },
                                        { validator: this.validateToNextPassword, },
                                    ],
                                })(<Input.Password allowClear={true} />)}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '4vw' }}
                                label="确认密码" hasFeedback>
                                {getFieldDecorator('confirm', {
                                    rules: [
                                        { required: true, message: '请确认密码', },
                                        { validator: this.compareToFirstPassword, },
                                    ],
                                })(<Input.Password onBlur={this.handleConfirmBlur} allowClear={true} />)}
                            </Form.Item>
                            <Form.Item label="电话" style={{ margin: '0', padding: '0', marginTop: '4vw' }} >
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '请输入电话号码' }],
                                })(<Input addonBefore={prefixSelector} style={{ width: '100%' }}
                                    allowClear={true} />)}
                            </Form.Item>
                            {/* <Form.Item label="邮箱" style={{ margin: '0', padding: '0', marginTop: '4vw' }} >
                                {getFieldDecorator('email', {
                                    rules: [
                                        { type: 'email', message: '请输入有效的邮箱', },
                                        { required: false, message: '邮箱不能为空', },
                                    ],
                                })(<Input allowClear={true} />)}
                            </Form.Item> */}
                            {uiInviteCode}
                            {/* <Form.Item label="邀请码" style={{ margin: '0', padding: '0', marginTop: '5vw' }} >
                                {getFieldDecorator('code', {
                                    rules: [{ required: false, message: '请输入邀请码!', whitespace: true }],
                                })(<Input allowClear={true} />)}
                            </Form.Item> */}
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5vw' }} >
                                {getFieldDecorator('agreement', {
                                    valuePropName: 'checked',
                                })(
                                    <div className="agreement_forget_password">
                                        <Checkbox>我已阅读</Checkbox>
                                        <Tooltip title={"即将呈现，敬请期待！"} trigger="click" onClick={this.AgreementClicked}>
                                            <div className="click_link">{strAgreement}</div>
                                        </Tooltip>
                                    </div>
                                )}
                            </Form.Item>
                            <div className="login_button_area">
                                <Button type="primary" htmlType="submit" className="register_button_line">
                                    注 册</Button>
                                或
                                <div className="click_link" onClick={this.BackLoginButtonClicked.bind(this, 1)}>使用已有账号登录</div>
                            </div>
                        </Form>
                    </div>
                </div>
            );
        }

        return (
            <div className="login_page bgc_green_light">
                <div className="login_bgp_area">
                    <div className="login_bgp" />
                </div>
                {uiRegisterForm}
                <div className="login_back_btn" onClick={this.BackLoginButtonClicked.bind(this, 0)} >
                    <Icon type="left-circle" theme="filled" />
                </div>
            </div>
        );
    }
}

export default Form.create()(Register);
