import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import Navbar from './UI_Module/Navbar.js'
import StoreList from './UI_Module/StoreList.js'

import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';
import message from 'antd/es/message';

// import { message, Spin, Empty } from 'antd';
import "../../styles/ClientSide/search_page.css";
import "../../styles/icon_image.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../functions/DataStatistic.js"
import { StoreOrderBasedOnDistance } from "../../functions/PositionProcess"
import { CheckUserLocationFromCookie } from "../../functions/PositionProcess"

// 类名不能为Search，会和ant库里的搜索栏控件的类名冲突
class SearchPage extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            bGetSearchResult: false,
            bLogin: false,
            navbar: 0
        };
        this.strCurrentUserCode = "";
        this.arrStoreList = [];
        this.arrStoreItemList = [];
        this.arrMainCategory = [];
        this.arrSubCategory = [];
        this.arrHotSearch = [];
        this.objUserInfo = -1;
        this.strKeywords = "";
        let strParams = window.location.search
        if (strParams.indexOf('?=') !== -1)
            this.strKeywords = decodeURI(strParams.split('?=')[1]);
        this.arrPosition = -1;
        this.strCityName = "";
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 清空历史记录
    CleanSearchHistory = () => {
        // DataStatistic
        UserActiveUpdate(3, { active: "清空搜索记录" });
        this.objUserInfo.search_history = "";
        axios.post('/UpdateUserSearchHistory', { objUserInfo: this.objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    console.log(data.message);
                    console.log(data.err);
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bGetSearchResult: false
                });
            }).catch(function (error) { console.log(error); });
    }
    // 对搜索历史进行处理
    SearchHistroyOrganize = (strSearchKeywords) => {
        // 在搜索历史中没有完全匹配的词
        if (this.objUserInfo.search_history === null || this.objUserInfo.search_history === "")
            this.objUserInfo.search_history = strSearchKeywords;
        else {
            let arrSearchHistory = this.objUserInfo.search_history.split(',');
            this.objUserInfo.search_history = strSearchKeywords;
            for (let i = 0; i < arrSearchHistory.length; i++) {
                if (strSearchKeywords !== arrSearchHistory[i]) {
                    this.objUserInfo.search_history += "," + arrSearchHistory[i];
                }
            }
            arrSearchHistory = this.objUserInfo.search_history.split(',');
            if (arrSearchHistory.length >= 10) {
                this.objUserInfo.search_history = arrSearchHistory[0];
                for (let i = 1; i < 10; i++) {
                    this.objUserInfo.search_history += "," + arrSearchHistory[i];
                }
            }
        }
    }
    // 根据关键词查询商家等信息
    GetSearchResults = (strSearchKeywords) => {
        axios('/SearchStoresDetails', { params: { name: this.KeywordsProcess(strSearchKeywords) } })
            .then(({ data }) => {
                // 如果搜到了商家，则不进行下一层搜索
                // if (data.length > 0) {
                if (this.arrPosition === -1)
                    this.arrStoreList = data;
                else
                    this.arrStoreList = StoreOrderBasedOnDistance(this.arrPosition, data);
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bGetSearchResult: true
                });
                // } else {
                axios('/SearchItems', { params: { name: this.KeywordsProcess(strSearchKeywords), store_id: 0 } })
                    .then(({ data }) => {
                        this.arrStoreItemList = data;
                        console.log("SearchItems >>> ");
                        console.log(data);
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bGetSearchResult: true
                        });
                    }).catch(function (error) { console.log(error); });
                // }
            }).catch(function (error) { console.log(error); });
    }
    // 热门搜索
    HotSearchClicked = (strSearchKeywords, index) => {
        // DataStatistic
        UserActiveUpdate(1, { active: "点击热门搜索", order: index, keywords: strSearchKeywords });
        this.strKeywords = strSearchKeywords;
        let iNavbarKey = 0
        if (this.state.navbar === 0)
            iNavbarKey = 1
        this.setState({
            ...this.state,
            bLoading: true,
            bGetSearchResult: false,
            navbar: iNavbarKey
        });
        this.GetSearchResults(strSearchKeywords);
    }
    // 点击搜索历史按钮
    SearchHistoryClicked = (strSearchHistory, index) => {
        // DataStatistic
        UserActiveUpdate(2, { active: "点击搜索历史", order: index, keywords: strSearchHistory });
        this.strKeywords = strSearchHistory;
        let iNavbarKey = 0
        if (this.state.navbar === 0)
            iNavbarKey = 1
        this.setState({
            ...this.state,
            bLoading: true,
            bGetSearchResult: false,
            navbar: iNavbarKey
        });
        this.SearchHistroyOrganize(strSearchHistory);
        // 更新用户表中的搜索历史记录
        axios.post('/UpdateUserSearchHistory', { objUserInfo: this.objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    console.log(data.message);
                    console.log(data.err);
                }
                this.GetSearchResults(strSearchHistory);
            }).catch(function (error) { console.log(error); });
    }
    // 对关键次进行搜索
    KeywordsOnSearch = (strSearchKeywords) => {
        this.strKeywords = strSearchKeywords;
        let strKeywords = strSearchKeywords;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.setState({
                ...this.state,
                bLoading: false,
                bGetSearchResult: false
            });
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                bGetSearchResult: false
            });
            if (this.objUserInfo === -1) {
                this.GetSearchResults(strKeywords);
            } else {
                this.SearchHistroyOrganize(strSearchKeywords);
                // 更新用户表中的搜索历史记录
                axios.post('/UpdateUserSearchHistory', { objUserInfo: this.objUserInfo })
                    .then(({ data }) => {
                        if (data.status === -1) {
                            console.log(data.message);
                            console.log(data.err);
                        }
                        this.GetSearchResults(strKeywords);
                    }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                let bLogin = false;
                if (data.status === -1) {
                    this.objUserInfo = -1;
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });

                } else {
                    bLogin = true;
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                }
                this.setState({
                    ...this.state,
                    bLogin: bLogin
                });
                this.GetCategoriesInfo();
            }).catch(function (error) { console.log(error); });
    }
    // 获取目标分类信息 
    GetCategoriesInfo = () => {
        axios('/GetMainCategories')
            .then(({ data }) => {
                this.arrMainCategories = data.slice(1, data.length);
                axios('/GetSubCategories')
                    .then(({ data }) => {
                        this.arrSubCategories = data;
                        this.KeywordsOnSearch(this.strKeywords)
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 获取热门搜索标签
    GetHotSearchTags = () => {
        axios('/GetHotSearch').then(({ data }) => {
            if (data.length >= 6)
                this.arrHotSearch = data.slice(0, 5);
            else
                this.arrHotSearch = data;
        });
    }
    // 点击登录按钮
    LoginBtnClicked = () => {
        // DataStatistic
        UserActiveUpdateBack(4, { active: "搜索页: 点击登录按钮" }, this.JumpToLoginPage);
    }
    // 跳转到登录页
    JumpToLoginPage = () => {
        window.location.href = '/webapp/login';
    }
    // 获取用户定位信息之后
    AfterGetLocation = (city, address, position) => {
        this.arrPosition = position;
        this.strCityName = city;
        // 用户登录状态检查（未登录则不显示搜索历史记录）
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            cookie.save('wm_user_code', '', { path: '/' });
            cookie.save('wm_login_status', -1, { path: '/' });
            this.GetCategoriesInfo();
        }
        this.GetHotSearchTags();
    }
    // 渲染完毕……
    componentDidMount() {
        // DataStatistic
        UserActiveUpdate(0, { active: "访问搜索页面" });
        // 检查Cookie中的用户位置信息
        CheckUserLocationFromCookie(false, this.AfterGetLocation);
    }

    render() {

        let uiSearchArea = (
            <div className="user_page_spin">
                <Spin size="large" />
            </div>
        );
        if (!this.state.bLoading) {
            if (this.state.bGetSearchResult) {
                uiSearchArea = (
                    <div className="search_result_area">
                        <div className="search_keywords">
                            <div className="search_list_item">{this.strKeywords}</div>
                        </div>
                        <div className="search_store_list_area">
                            <StoreList iCurrentPage={4} strTitle={"找到的商家"} bShowFilter={false}
                                arrMainCategory={this.arrMainCategories}
                                arrSubCategory={this.arrSubCategories}
                                arrStoreList={this.arrStoreList} strKeywords={this.strKeywords} />
                        </div>
                    </div>
                );
            } else {
                let uiSearchHistory = (
                    <Empty description="没有搜索记录……" className="search_page_empty" />
                );
                if (this.state.bLogin) {
                    if (this.objUserInfo.search_history !== null &&
                        this.objUserInfo.search_history !== "") {
                        let arrSearchHistory = this.objUserInfo.search_history.split(',');
                        if (arrSearchHistory.length <= 5) {
                            uiSearchHistory = (
                                <div className="search_list_row">
                                    {arrSearchHistory.map((item, index) => {
                                        return (
                                            <div className="search_list_item search_history_item" key={index}
                                                onClick={this.SearchHistoryClicked.bind(this, item, index)}>{item}</div>
                                        );
                                    })}
                                </div>
                            );
                        } else {
                            let arrRow1 = [], arrRow2 = [];
                            arrRow1 = arrSearchHistory.slice(0, 5);
                            arrRow2 = arrSearchHistory.slice(5);
                            uiSearchHistory = (
                                <div>
                                    <div className="search_list_row">
                                        {arrRow1.map((item, index) => {
                                            return (
                                                <div className="search_list_item search_history_item" key={index}
                                                    onClick={this.SearchHistoryClicked.bind(this, item)}>{item}</div>
                                            );
                                        })}
                                    </div>
                                    <div className="search_list_row">
                                        {arrRow2.map((item, index) => {
                                            return (
                                                <div className="search_list_item search_history_item" key={index}
                                                    onClick={this.SearchHistoryClicked.bind(this, item)}>{item}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        }
                    }
                } else {
                    uiSearchHistory = (
                        <div className="search_page_empty" >
                            <Empty description="没有搜索记录……" />
                            <div className="search_login_btn" onClick={this.LoginBtnClicked}>去登录</div>
                        </div>
                    );
                }
                uiSearchArea = (
                    <div>
                        <div className="search_list_area">
                            <div className="search_list_title  search_top_area">热门搜索</div>
                            <div className="search_list_row">
                                {this.arrHotSearch.map((item, index) => {
                                    return (
                                        <div className="search_list_item search_hot_item" key={index}
                                            onClick={this.HotSearchClicked.bind(this, item.name, index)}>
                                            {item.name}</div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="search_list_area">
                            <div className="search_list_title">
                                历史记录
                                <div className="search_history_delete delete_icon"
                                    onClick={this.CleanSearchHistory} />
                            </div>
                            {uiSearchHistory}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="search_page">
                <Navbar iCurrentPage={4} objUserInfo={this.objUserInfo} key={this.state.navbar}
                    strKeywords={this.strKeywords} KeywordsOnSearch={this.KeywordsOnSearch}
                    strNavbarPlaceHolder={"请输入商家/商品/服务的名称……"} />
                {uiSearchArea}
            </div>
        );
    }
}
export default SearchPage;
