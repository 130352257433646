import React, { Component } from "react";
import axios from 'axios';

import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Empty from 'antd/es/empty';
import Icon from 'antd/es/icon';
import Tag from 'antd/es/tag';
import Spin from 'antd/es/spin';

// import { Menu, Dropdown, Empty, Icon, Tag, Spin } from 'antd'
import "../../../styles/ClientSide/UI_Module/store_list.css";
import '../../../styles/icon_image.css'
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { UserActiveUpdateBack } from "../../../functions/DataStatistic.js"

// const { Panel } = Collapse;
// function callback(key) {
//     console.log(key);
// }

class StoreList extends Component {
    constructor(props) {
        super();
        this.state = {
            iCurrentMainCategoryIndex: 0,
            iCurrentSortMethod: 0,
            bLoadMore: false,
            bLoading: true
        };
        this.arrSortMethodFilter = ['附近', '热度: 高→低', '热度: 低→高', '价位: 低→高', '价位: 高→低'];
        // 商家总列表：从上层传下来的获取到的符合条件的商家列表数据
        this.arrStoreList = [
            {
                id: 0, name: "本宫的茶", hot: 5,
                main_category: "美食", sub_category: "奶茶甜品",
                distance: "0.5km", price: "$15"
            },
            {
                id: 1, name: "大塘烧腊", hot: 4,
                main_category: "美食", sub_category: "港式早茶",
                distance: "0.75km", price: "$20"
            },
            {
                id: 2, name: "串串香", hot: 3,
                main_category: "美食", sub_category: "夜宵烧烤",
                distance: "1.0km", price: "$25"
            },
            {
                id: 3, name: "锐捷国际", hot: 2,
                main_category: "生活服务", sub_category: "留移民",
                distance: "1.5km", price: "$1000"
            }
        ];
        // 当前分类或条件下需要展示的商家总数
        this.arrStoreShow = [];
        if (props.arrStoreList !== undefined) {
            this.arrStoreList = props.arrStoreList;
            this.arrStoreShow = this.arrStoreList;
        }
        this.arrMainCategoryFilter = ['全部', '美食', '生活服务', '休闲娱乐', '周边游'];
        this.arrMainCategoryInfo = [];
        if (props.arrMainCategory !== undefined) {
            this.arrMainCategoryFilter = [];
            this.arrMainCategoryInfo = [];
            for (let i = 0; i < props.arrMainCategory.length; i++) {
                if (props.arrMainCategory[i].level === 1) {
                    this.arrMainCategoryInfo.push(props.arrMainCategory[i]);
                    this.arrMainCategoryFilter.push(props.arrMainCategory[i].name);
                }
            }
            // 如果不是用户中心的收藏页，需要根据分类来显示商家列表
            if (props.iCurrentPage !== 3) {
                this.arrStoreShow = [];
                for (let i = 0; i < this.arrStoreList.length; i++) {
                    if (this.arrStoreList[i].main_category === this.arrMainCategoryInfo[this.state.iCurrentMainCategoryIndex].id) {
                        this.arrStoreShow.push(this.arrStoreList[i]);
                    }
                }
            }
        }
        this.iStoreID = 0;
        this.iCurrentLoadStores = 5;
        this.bLoadMoreBtn = true;
        // 当前展示给用户的商家列表（每五个一组，点击加载更多按钮逐步加载展示）
        this.arrCurrentShowStores = [];
        this.arrLocationsData = [];
    }
    // 点击了分类过滤器
    RefreshStoreList = (iMainCategoryIndex, strName) => {
        // 只在首页记录分类过滤器点击
        if (this.props.iCurrentPage === 0) {
            // DataStatistic
            UserActiveUpdate(2, {
                active: "点击商家列表分类过滤器",
                category_id: this.arrMainCategoryInfo[iMainCategoryIndex].id,
                category_index: iMainCategoryIndex,
                category_name: strName
            });
        }
        this.arrStoreShow = [];
        // 列表有可能为空，因此需要原始商家列表和分类列表均不为空
        if (this.arrStoreList.length > 0 && this.arrMainCategoryInfo.length > 0) {
            for (let i = 0; i < this.arrStoreList.length; i++) {
                if (this.arrStoreList[i].main_category === this.arrMainCategoryInfo[iMainCategoryIndex].id) {
                    this.arrStoreShow.push(this.arrStoreList[i]);
                }
            }
        }
        this.setState({
            ...this.state,
            iCurrentMainCategoryIndex: iMainCategoryIndex
        });
    }
    // 点击了排序过滤器
    SortItemClicked = (iSortMethod) => {
        this.setState({
            ...this.state,
            iCurrentSortMethod: iSortMethod
        })
    }
    // 热度等级计算并生成控件
    HotCalculate = (iHot) => {
        let uiHotLevel = [];
        for (let i = 0; i < iHot; i++) {
            uiHotLevel.push(<div className="store_hot_stars hot_star_icon_empty" key={i} />)
        }
        let iRandomStars = Math.floor((Math.random() * 5) + 1);
        for (let i = 0; i < iRandomStars; i++) {
            uiHotLevel[i] = <div className="store_hot_stars hot_star_icon" key={i} />
        }
        return uiHotLevel;
    }
    // 点击推荐区域商家缩略图
    StoreThumbClicked = (iStoreID, iIndex) => {
        this.iStoreID = iStoreID;
        // DataStatistic
        UserActiveUpdateBack(1, { active: "点击商家列表缩略图", store: iStoreID, order: iIndex }, this.JumpToStore);
    }
    // 跳转到目标商家
    JumpToStore = () => {
        // window.location.href = '/webapp/store/' + iStoreID + "?tab=2&from=" + this.props.iFromPage;
        window.location.href = '/webapp/store/' + this.iStoreID + "?tab=2";
    }
    // 主类别ID转Index
    GetMainCategoryName = (iMainCategoryID) => {
        let strMainCategoryName = "";
        if (this.props.arrMainCategory !== undefined) {
            for (let i = 0; i < this.props.arrMainCategory.length; i++) {
                if (iMainCategoryID === this.props.arrMainCategory[i].id) {
                    strMainCategoryName = this.props.arrMainCategory[i].name;
                    break;
                }
            }
        }
        return strMainCategoryName;
    }
    // 子分类名称获取
    GetSubCategoryName = (iSubCategoryID) => {
        let strSubCategoryName = "";
        if (this.props.arrSubCategory !== undefined) {
            for (let i = 0; i < this.props.arrSubCategory.length; i++) {
                if (iSubCategoryID === this.props.arrSubCategory[i].id) {
                    strSubCategoryName = this.props.arrSubCategory[i].name;
                    break;
                }
            }
        }
        return strSubCategoryName;
    }
    // 商家列表展示前的判断&处理
    StoreListUIProcess = () => {
        let iTotalStoreNum = this.arrStoreShow.length;
        if (iTotalStoreNum > this.iCurrentLoadStores) {
            this.bLoadMoreBtn = true;
            this.arrCurrentShowStores = this.arrStoreShow.slice(0, this.iCurrentLoadStores);

        } else {
            this.bLoadMoreBtn = false;
            this.arrCurrentShowStores = this.arrStoreShow;
        }
    }
    // 展示更多商家
    LoadMoreStores = () => {
        // DataStatistic
        UserActiveUpdate(3, { active: "点击加载更多按钮", num: 5 });
        this.iCurrentLoadStores += 5;
        this.setState({
            ...this.state,
            bLoadMore: true
        });
    }
    // 获取商家所在城市
    GetStoreCity = (iLocation) => {
        let strCity = "";
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].id === iLocation) {
                strCity = this.arrLocationsData[i].en_name;
                break;
            }
        }
        return strCity;
    }
    // 渲染完毕……
    componentDidMount() {
        let iMainCategoryIndex = Math.floor(Math.random() * this.arrMainCategoryInfo.length);
        // 商家列表模块被用于：0-首页, 1-分类页, 3-用户中心的收藏
        // 用于测试版演示，首页固定显示编号3主分类：美食
        let strName = "";
        if (this.props.iCurrentPage === 0) {
            iMainCategoryIndex = 3;
            strName = this.arrMainCategoryInfo[iMainCategoryIndex].name;
        }
        this.RefreshStoreList(iMainCategoryIndex, strName);
        // 个人中心商家收藏页，不展示距离信息，但是需要展示城市信息
        if (this.props.iCurrentPage === 3) {
            axios('/GetLocations')
                .then(({ data }) => {
                    this.arrLocationsData = data;
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }

    render() {

        // 列表标题行
        let uiTitleArea = (
            <div className="store_list_title">
                {this.props.strTitle}
            </div>
        );
        if (this.props.bShowFilter) {
            // 排序方法过滤器
            // const uiSortFilterMenu = (
            //     <Menu className="store_list_category_filter_menu">
            //         {this.arrSortMethodFilter.map((item, index) => {
            //             return (
            //                 <Menu.Item onClick={this.SortItemClicked.bind(this, index)} key={index}>
            //                     {item}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // const uiSortFilter = (
            //     <Dropdown className="store_list_filter store_list_filter_stretch"
            //         overlay={uiSortFilterMenu} trigger={['click']} >
            //         <div> {this.arrSortMethodFilter[this.state.iCurrentSortMethod]}
            //             <Icon className="store_list_dropdown_icon" type="caret-down" />
            //         </div>
            //     </Dropdown>
            // );
            // 首页
            if (this.props.iCurrentPage === 0) {
                // 主分类筛选过滤器
                const uiCategoryFilter = (
                    <Menu className="store_list_category_filter_menu">
                        {this.arrMainCategoryInfo.map((item, index) => {
                            return (
                                <Menu.Item onClick={this.RefreshStoreList.bind(this, index, item.name)} key={index}>
                                    {item.name}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                uiTitleArea = (
                    <div className="store_list_title">
                        {this.props.strTitle}
                        {/* {uiSortFilter} */}
                        {/* <div className="divider_tiny_v" /> */}
                        <Dropdown className="store_list_filter" overlay={uiCategoryFilter}
                            trigger={['click']} >
                            <div> {this.arrMainCategoryFilter[this.state.iCurrentMainCategoryIndex]}
                                <Icon className="store_list_dropdown_icon" type="caret-down" />
                            </div>
                        </Dropdown>
                    </div>
                );
            }
            // 分类页/收藏页
            if (this.props.iCurrentPage === 1) {
                uiTitleArea = (
                    <div className="store_list_title">
                        {this.props.strTitle}
                        {/* {uiSortFilter} */}
                    </div>
                );
            }
        }
        // 商家列表本体
        let strDescription = "未找到任何商家";
        if (this.props.iCurrentPage === 3)
            strDescription = "还未关注任何商家";
        let uiStoreList = (
            <div className="store_list">
                {uiTitleArea}
                <Empty className="store_list_empty" description={strDescription} />
            </div>
        );
        // 用户中心或者搜索页面，需要在render时刷新父控件传下来的商家列表
        if (this.props.iCurrentPage === 3 || this.props.iCurrentPage === 4) {
            if (this.props.arrStoreList !== undefined) {
                this.arrStoreList = this.props.arrStoreList;
                this.arrStoreShow = this.arrStoreList;
            }
        }
        // 搜索界面才有的分类、商品、优惠券等折叠控件
        // let uiCollapse = (<div />);
        // if (this.props.iCurrentPage === 4) {
        //     uiCollapse = (
        //         <Collapse onChange={callback} bordered={false}
        //             expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
        //             expandIconPosition="left">
        //             <Panel header={"该店共10个包含 '" + this.props.strKeywords + "' 的商品/分类/优惠券"} key="0">
        //                 <Collapse onChange={callback} bordered={false} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
        //                     defaultActiveKey={['1', '2', '3']}>
        //                     <Panel header={"该店包含 '" + this.props.strKeywords + "' 的商品分类"} key="1">
        //                         <p>{"茶立方"}</p><p>{"果立方"}</p>
        //                     </Panel>
        //                     <Panel header={"该店包含 '" + this.props.strKeywords + "' 的商品"} key="2">
        //                         <p>{"大红袍黑丸"}</p><p>{"桂花乌龙黑丸"}</p><p>{"黑撞奶茶"}</p>
        //                     </Panel>
        //                     <Panel header={"该店包含 '" + this.props.strKeywords + "' 优惠券"} key="3" >
        //                         <p>{"买一送一"}</p>
        //                     </Panel>
        //                 </Collapse>
        //             </Panel>
        //         </ Collapse>
        //     );
        // }
        // 不是用户中心收藏页面，且不处于加载状态时
        if (!(this.props.iCurrentPage === 3 && this.state.bLoading)) {
            if (this.arrStoreShow.length > 0) {
                this.StoreListUIProcess();
                let uiLoadMoreBtn = <div />;
                if (this.bLoadMoreBtn)
                    uiLoadMoreBtn = <div className="store_list_load_more_btn"
                        onClick={this.LoadMoreStores}>展示更多…</div>;
                let uiBottomSpace = <div />;
                if (this.arrCurrentShowStores.length <= 3 && this.props.iCurrentPage === 0)
                    uiBottomSpace = <div className="home_page_bottom_space" />

                uiStoreList = (
                    <div className="store_list">
                        {uiTitleArea}
                        {this.arrCurrentShowStores.map((data, index) => {
                            let uiThumbImage = (
                                <div className="store_list_item_image"
                                    onClick={this.StoreThumbClicked.bind(this, data.id, index)} />
                            );
                            if (data.thumb !== "" && data.thumb !== null && data.thumb !== undefined) {
                                uiThumbImage = (
                                    <img className="store_list_item_image" src={data.thumb} alt='' />
                                );
                            }
                            let strAvePrice = "";
                            if (data.ave_price !== null && data.ave_price !== "")
                                strAvePrice = "人均: $" + data.ave_price;
                            if (data.mall === null || data.mall === "")
                                data.mall = "商圈: 未知";
                            let uiLabels = <div className="store_list_item_labels_empty" />;
                            if (data.labels !== null && data.labels !== "") {
                                let arrLabels = data.labels.split(',');
                                uiLabels = (
                                    <div className="store_list_item_labels">
                                        {arrLabels.map((label, index) => {
                                            return (
                                                <Tag color="volcano" key={index}>{label}</Tag>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            let strDistance = "";
                            // 收藏页不显示商家距离
                            if (this.props.iCurrentPage !== 3) {
                                if (data.distance !== undefined && data.distance !== null) {
                                    if (data.distance < 1.00)
                                        strDistance = (data.distance.toFixed(3) * 1000).toString() + " m";
                                    else
                                        strDistance = data.distance.toFixed(2).toString() + " km";
                                }
                            } else {
                                strDistance = this.GetStoreCity(data.location);
                            }
                            return (
                                <div key={index}>
                                    <div className="store_list_item"
                                        onClick={this.StoreThumbClicked.bind(this, data.id, index)} >
                                        {uiThumbImage}
                                        <div className="store_list_item_info">
                                            <div className="store_list_item_info_row">
                                                <div className="store_list_item_title">{data.store_name}</div>
                                            </div>
                                            <div className="store_list_item_info_row">
                                                <div className="store_hot_area">{this.HotCalculate(5)}</div>
                                                <div className="store_list_item_price">{strAvePrice}</div>
                                            </div>
                                            <div className="store_list_item_info_row">
                                                <div className="store_list_category_info">
                                                    {this.GetMainCategoryName(data.main_category) + " - " +
                                                        this.GetSubCategoryName(data.sub_category)}
                                                </div>
                                            </div>
                                            <div className="store_list_item_info_row">
                                                <div className="store_list_mall_info">{data.mall}</div>
                                                <div className="store_list_item_distance_icon location_icon" />
                                                <div className="store_list_item_distance_text">{strDistance}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {uiLabels}
                                </div>
                            );
                        })}
                        {uiLoadMoreBtn}
                        {/* 底部留白 */}
                        {uiBottomSpace}
                    </div>
                );
            }
        } else {
            uiStoreList = (
                <div className="store_list">
                    {uiTitleArea}
                    <div className="store_list_loading">
                        <Spin size="large" />
                    </div>
                </div>
            );
        }

        return (uiStoreList);
    }
}
export default StoreList;
