import React, { Component } from "react";
import axios from 'axios';
import md5 from 'js-md5';

import { deviceType } from "react-device-detect";

import Input from "antd/es/input";
import Steps from "antd/es/steps";
import Spin from "antd/es/spin";
import Empty from "antd/es/empty";
import Radio from "antd/es/radio";
import Icon from "antd/es/icon";
import message from "antd/es/message";

import "../../styles/ClientSide/transit_tracking.css";
import "antd/dist/antd.css";

React.Component.prototype.$md5 = md5;
const { Search } = Input;
const { Step } = Steps;

const g_strMessageKey = 'updating';

class Mouthing extends Component {

    constructor(props) {
        super(props);

        let strParams = window.location.pathname;
        this.strHostName = window.location.hostname;
        this.strLanguage = "";
        if (strParams.indexOf('cn') >= 0 || strParams.indexOf('en') >= 0)
            this.strLanguage = strParams.split('/tracking/')[1];
        else
            this.strLanguage = "cn";
        this.strTrackingNo = "";
        if (strParams.indexOf('no') >= 0) {
            this.strTrackingNo = strParams.split('no=')[1];
            console.log(this.strTrackingNo);
        }
        console.log("params = " + strParams);
        this.bMStation = false;
        if (this.strHostName.indexOf('m.') >= 0)
            this.bMStation = true;

        this.state = {
            bTracking: false
        };

        this.arrOrderTransitStatus = ["国内收货", "已发货", "已到港", "到目的地", "派送中", "已签收"];
        if (this.strLanguage === "en")
            this.arrOrderTransitStatus = ["Goods Received", "On-board", "Port of Discharge",
                "Destination Facility", "Out For Delivery", "Delivered"];
        this.iCurrentTransitStatus = -2;  // 还未进行任何搜索的初始状态
        this.strCurrentTransitDetails = "";
        this.strArriveDate = "";
        this.strFromWhere = "";
        this.strToWhere = "";
        this.strOID = "";
        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.iStoreID = 0;
        let strSubParams = window.location.search;  // 筛选出?后面的部分
        strSubParams = strSubParams.substring(strSubParams.indexOf('?') + 1);
        console.log("sub params = " + strSubParams);
        if (strSubParams.indexOf('store_id=') >= 0) {
            this.iStoreID = parseInt(strSubParams.split('store_id=')[1], 10);
        }
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/';  // 跨域访问代理
        this.strExpress100Key = "qXbZYMUW6309";
        this.strExpress100Customer = "75AE8F6D32CD86DFAD60415D3B90D20E";
        this.strExpress100Sign = "";
        this.strGetExpressCompanyUrl = proxyUrl + "http://www.kuaidi100.com/autonumber/auto?";  // 快递100智能识别接口
        this.strGetExpressTrackingInfo = proxyUrl + "http://poll.kuaidi100.com/poll/query.do?";  // 快递100实时查询接口
        this.strComCode = "";
        this.objExpressInfo = {};
        this.arrExpressDetails = [];

    }

    OnSearchClicked = (value) => {
        if (value.trim() === "") {
            if (this.strLanguage === "cn")
                message.warning("查询内容不能为空！");
            if (this.strLanguage === "en")
                message.warning("Please Enter Your Tracking No. !");
            return;
        }
        this.setState({
            ...this.state,
            bTracking: true
        });

        if (this.iStoreID !== 30000) {
            axios('/GetTargetAutoOrder', { params: { tracking_no: value } })
                .then(({ data }) => {
                    if (data.length > 0) {
                        if (data[0].transit_status === null)
                            this.iCurrentTransitStatus = 0;
                        else
                            this.iCurrentTransitStatus = parseInt(data[0].transit_status, 10);
                        if (data[0].transit_details === null)
                            this.strCurrentTransitDetails = "";
                        else
                            this.strCurrentTransitDetails = data[0].transit_details;
                        this.strArriveDate = data[0].arrive_date;
                        this.strFromWhere = data[0].from_where;
                        this.strToWhere = data[0].to_where;
                        this.strOID = data[0].oid;
                        this.strTrackingNo = data[0].tracking_no;
                        this.iStoreID = data[0].store_id;
                        axios('/GetTransitStatus', { params: { store_id: this.iStoreID } })
                            .then(({ data }) => {
                                if (data.length > 0) {
                                    this.arrOrderTransitStatus = [];
                                    for (let i = 0; i < data.length; i++) {
                                        if (this.strLanguage === "cn")
                                            this.arrOrderTransitStatus.push(data[i].cn_name);
                                        if (this.strLanguage === "en")
                                            this.arrOrderTransitStatus.push(data[i].en_name);
                                    }
                                    if (this.strCurrentTransitDetails >= 0 && this.iCurrentTransitStatus >= 1) {
                                        if (this.strLanguage === "cn")
                                            this.strCurrentTransitDetails = data[this.iCurrentTransitStatus - 1].sub_cn_name.split(',')[this.strCurrentTransitDetails];
                                        if (this.strLanguage === "en")
                                            this.strCurrentTransitDetails = data[this.iCurrentTransitStatus - 1].sub_en_name.split(',')[this.strCurrentTransitDetails];
                                    }
                                }
                                this.setState({
                                    ...this.state,
                                    bTracking: false
                                });
                            }).catch(function (error) { console.log(error); });
                    } else {
                        this.iCurrentTransitStatus = -1;
                        this.setState({
                            ...this.state,
                            bTracking: false
                        });
                    }
                }).catch(function (error) { console.log(error); });
        }
        // 西邮寄追踪页面
        else {
            message.loading({ content: '快递单号查询中……', key: g_strMessageKey });
            axios.get(this.strGetExpressCompanyUrl + "num=" + value + "&key=" + this.strExpress100Key)
                .then((response) => {
                    console.log("response.data 1 : ", response.data);
                    this.strComCode = response.data[0].comCode;
                    let strParam = '{"com":"' + this.strComCode + '","num":"' + value + '","from":"","phone":"","to":"","resultv2":"0","show":"0","order":"desc"}';
                    this.strExpress100Sign = this.$md5(strParam + this.strExpress100Key + this.strExpress100Customer);
                    this.strExpress100Sign = this.strExpress100Sign.toUpperCase();
                    let strGetExpressInfoUrl = this.strGetExpressTrackingInfo + "customer=" + this.strExpress100Customer + "&sign=" + this.strExpress100Sign + "&param=" + strParam;
                    axios.get(strGetExpressInfoUrl)
                        .then((response) => {
                            console.log("response : ", response);
                            if (response.status === 200) {
                                console.log("response.data 2 : ", response.data);
                                if (response.data.data) {
                                    console.log("express details : ", response.data.data);
                                    this.arrExpressDetails = [];
                                    if (response.data.data.length > 0) {
                                        this.arrExpressDetails = response.data.data;
                                    }
                                    message.success({ content: "快递信息加载完成！", key: g_strMessageKey, duration: 2 });
                                    this.setState({
                                        ...this.state,
                                        bTracking: false
                                    });
                                } else {
                                    this.arrExpressDetails = [{ time: '', context: response.data.message }];
                                    message.success({ content: "快递信息加载完成！", key: g_strMessageKey, duration: 2 });
                                    this.setState({
                                        ...this.state,
                                        bTracking: false
                                    });
                                }
                            } else {
                                console.log(response.data.message);
                            }
                        }).catch(err => { console.log(err); });
                }).catch(err => { console.log(err); });
        }
    }

    LanguageChange = (e) => {
        window.location.href = "/tracking/" + e.target.value;
    }

    GoBack = () => {
        if (this.bMStation) {
            window.history.back();
        } else {
            window.location.href = '/';
        }
    }

    componentDidMount() {
        document.title = "Mouthing 物流跟踪";
        if (this.strLanguage === "en")
            document.title = "Mouthing Cargo Tracking";
        if (this.strTrackingNo !== '') {
            this.OnSearchClicked(this.strTrackingNo);
        }
    }

    render() {

        let uiArriveDate = (<div />);
        let uiTrackingSteps = (<div />);
        if (this.state.bTracking) {
            uiTrackingSteps = (<Spin size="large" />);
        } else {
            // 通用物流系统追踪状态
            if (this.iCurrentTransitStatus === -2) {
                let strIntro = "请输入单号进行查询";
                if (this.strLanguage === "en")
                    strIntro = "Please Enter Tracking No.";
                let strStyle = "no_tracking";
                if (this.bMStation)
                    strStyle = "m_no_tracking";
                uiTrackingSteps = (<div className={strStyle}>{strIntro}</div>);
            }
            if (this.iCurrentTransitStatus === -1) {
                let strIntro = "未找到该物流单号的信息";
                if (this.strLanguage === "en")
                    strIntro = "Please check correct Tracking No.";
                uiTrackingSteps = (<Empty description={strIntro} />);
            }
            if (this.iCurrentTransitStatus === 0) {
                let strIntro = "您的货物正在分拣中";
                if (this.strLanguage === "en")
                    strIntro = "Your goods still processing shipment";
                let strStyle = "no_tracking";
                if (this.bMStation)
                    strStyle = "m_no_tracking";
                uiTrackingSteps = (
                    <div className={strStyle}>{strIntro}</div>
                );
            }
            if (this.iCurrentTransitStatus > 0) {
                let arrInfo = ["订单号 : ", "物流号 : ", "起始地 : ", "目的地 : ", "预计到达 : "];
                if (this.strLanguage === "en")
                    arrInfo = ["Order No. : ", "Tracking No. : ", "Origin : ", "Destination : ", "ETA : "];
                let strStyleMain = "tracking_key_info_area";
                let strStyle = "tracking_key_info";
                let uiFromWhere = null;
                let uiToWhere = null;
                if (this.strFromWhere !== "")
                    uiFromWhere = (
                        <div className={strStyle}><b>{arrInfo[2]}</b>&nbsp;{this.strFromWhere}</div>
                    );
                if (this.strToWhere !== "")
                    uiToWhere = (
                        <div className={strStyle}><b>{arrInfo[3]}</b>&nbsp;{this.strToWhere}</div>
                    );
                uiArriveDate = (
                    <div className={strStyleMain}>
                        {uiFromWhere}
                        {uiToWhere}
                        <div className={strStyle}><b>{arrInfo[4]}</b>&nbsp;{this.strArriveDate}</div>
                    </div>
                );
                if (this.bMStation) {
                    strStyleMain = "m_tracking_key_info_area";
                    strStyle = "m_tracking_key_info";
                    arrInfo[4] = "预计到达日期 : ";
                    if (this.strLanguage === "en")
                        arrInfo[4] = "Estimated Time of Arrival : ";
                    let uiFromWhere = null;
                    let uiToWhere = null;
                    if (this.strFromWhere !== "")
                        uiFromWhere = (
                            <div className={strStyle}><b>{arrInfo[2]}</b>&nbsp;{this.strFromWhere}</div>
                        );
                    if (this.strToWhere !== "")
                        uiToWhere = (
                            <div className={strStyle}><b>{arrInfo[3]}</b>&nbsp;{this.strToWhere}</div>
                        );
                    uiArriveDate = (
                        <div>
                            <div className={strStyleMain}>
                                {uiFromWhere}
                                {uiToWhere}
                            </div>
                            <div className={strStyleMain}>
                                <div className={strStyle}><b>{arrInfo[4]}</b>&nbsp;{this.strArriveDate}</div>
                            </div>
                        </div>
                    );
                }

                let strLayout = "horizontal";
                strStyle = "";
                if (this.bMStation) {
                    strLayout = "vertical";
                    strStyle = "m_tracking_steps"
                }

                uiTrackingSteps = (
                    <Steps size="small" current={this.iCurrentTransitStatus - 1} type="default" progressDot
                        direction={strLayout} className={strStyle}>
                        {this.arrOrderTransitStatus.map((item, index) => {
                            let strDescription = "";
                            if (index === this.iCurrentTransitStatus - 1)
                                strDescription = this.strCurrentTransitDetails;
                            return (
                                <Step key={index} title={item} description={strDescription} />
                            );
                        })}
                    </Steps>
                );
            }
            // 西邮寄物流单号追踪
            if (this.iStoreID === 30000) {
                let strIntro = "请输入单号进行查询";
                if (this.strLanguage === "en")
                    strIntro = "Please Enter Tracking No.";
                let strStyle = "no_tracking";
                if (this.arrExpressDetails.length > 0) {
                    strStyle = "tracking_vertical_steps_area";
                    uiTrackingSteps = (
                        <div className={strStyle}>
                            {this.arrExpressDetails.map((item, index) => {
                                let uiTitle = (
                                    <div className="tracking_step_title">{item.time}</div>
                                );
                                if (item.time === "" || item.time === null)
                                    uiTitle = (
                                        <div className="tracking_step_title tracking_step_title_null">{item.time}</div>
                                    );
                                let uiDescription = (
                                    <div className="tracking_step_description">{item.context}</div>
                                );
                                return (
                                    <div className="tracking_step_row">
                                        {uiTitle}
                                        {uiDescription}
                                    </div>
                                );
                            })}
                        </div>
                    );
                } else {
                    uiTrackingSteps = (<div className={strStyle}>{strIntro}</div>);
                }
            }
        }

        let strTitle = "货物跟踪";
        let strTrackingNo = "物流单号：";
        if (this.strLanguage === "en") {
            strTitle = "Cargo Tracking";
            strTrackingNo = "Tracking No. ";
        }
        let uiBackButton = null;
        let uiMouthingLogo = null;
        let uiLekonLogo = null;
        let uiXiyoujiLogo = null;
        let uiTrackingPage = null;
        let strCenterAreaStyle = "tracking_center_area";
        if (!this.bMStation) {
            uiBackButton = (
                <Icon type="left-circle" theme="filled" onClick={this.GoBack}
                    style={{ fontSize: '30px', fontWeight: 'bold', color: '#0090fb', marginRight: '3%', marginLeft: '2%' }} />
            );
            uiMouthingLogo = (
                <div className="tracking_logo mouthing_logo" />
            );
            uiLekonLogo = (
                <div className="tracking_logo lekon_logo margin_right_auto" />
            );
            uiXiyoujiLogo = (
                <div className="xiyouji_logo margin_right_auto" />
            );
            if (this.iStoreID === 30000) {
                uiBackButton = null;
                uiMouthingLogo = null;
                uiLekonLogo = null;
                strCenterAreaStyle = "tracking_center_area xiyouji_center_area";
            } else {
                uiXiyoujiLogo = null;
            }
            uiTrackingPage = (
                <div className="transit_tracking_page">
                    <div className={strCenterAreaStyle}>
                        <div className="tracking_language">
                            {uiBackButton}
                            {uiMouthingLogo}
                            {uiLekonLogo}
                            {uiXiyoujiLogo}
                            <Radio.Group defaultValue={this.strLanguage} buttonStyle="solid" onChange={this.LanguageChange}>
                                <Radio.Button value="cn">中文</Radio.Button>
                                <Radio.Button value="en">English</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="tracking_title">
                            {strTitle}<div className="tracking_title_icon transit_icon" />
                        </div>
                        <div className="tracking_no_input">
                            <div className="tracking_no_input_title">
                                <b>{strTrackingNo}</b>
                            </div>
                            <Search defaultValue={this.strTrackingNo} onSearch={value => this.OnSearchClicked(value)} ref="search_input" enterButton />
                        </div>
                        {uiArriveDate}
                        <div className="tracking_result_area">
                            {uiTrackingSteps}
                        </div>
                    </div>
                </div>
            );
        } else {
            uiBackButton = (
                <Icon type="left-circle" theme="filled" onClick={this.GoBack}
                    style={{ fontSize: '6vw', fontWeight: 'bold', color: '#0090fb', marginRight: '10vw', marginLeft: '2.5vw' }} />
            );
            if (this.iStoreID === 30000)
                uiBackButton = null;
            uiTrackingPage = (
                <div className="m_transit_tracking_page">
                    <div className="m_tracking_top_area">
                        {uiBackButton}
                        <div className="m_tracking_logo mouthing_logo" />
                        <div className="m_tracking_logo lekon_logo margin_right_auto" />
                        <Radio.Group defaultValue={this.strLanguage} buttonStyle="solid" onChange={this.LanguageChange}>
                            <Radio.Button value="cn">中文</Radio.Button>
                            <Radio.Button value="en">English</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="m_tracking_title">
                        {strTitle}<div className="m_tracking_title_icon transit_icon" />
                    </div>
                    <div className="m_tracking_no_input_title">
                        {strTrackingNo}
                        <div className="m_tracking_no_search_box">
                            <Search placeholder="" onSearch={value => this.OnSearchClicked(value)} enterButton defaultValue={this.strTrackingNo} />
                        </div>
                    </div>
                    {uiArriveDate}
                    <div className="m_tracking_result_area">
                        {uiTrackingSteps}
                    </div>
                </div>
            );
        }

        return (
            <div className="page_basic">
                {uiTrackingPage}
            </div>
        );
    }

}
export default Mouthing;
