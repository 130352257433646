import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies'

import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import UserInfoJW from "./BackendPage/UserInfoJW.js";
import ExpressEnterJW from "./BackendPage/ExpressEnterJW.js";
import PackageCombineJW from "./BackendPage/PackageCombineJW.js";
import StoreClientManagerJW from "./BackendPage/StoreClientManagerJW.js";
import CACNOrder from "./BackendPage/CACNOrder.js";
import StoreShipperManager from "./BackendPage/StoreShipperManager.js";
import StoreReceiverManager from "./BackendPage/StoreReceiverManager.js";

import { deviceType } from "react-device-detect";

import "antd/dist/antd.css";
import "../../styles/Background/ego_manager.css";
import "../../styles/Background/store_manager.css";
import "../../styles/Background/UI_Module/ca_cn_order.css";

const { SubMenu } = Menu;

class UserBackendJW extends Component {
    // 构造函数
    constructor(props) {
        document.title = "西邮寄物流";
        super();
        this.state = {
            bLoading: true,  // 加载状态
            iCurrentInterface: 0,  //当前显示的中心区域内容ID
            iCurrentTopBarIndex: 0,
            arrExpressEnter: []
        };
        this.arrSideMenuButton = [
            { id: 0, name: "用户信息", icon: "user" },
            { id: 1, name: "包裹管理", icon: "code-sandbox" },
            { id: 2, name: "运单管理", icon: "snippets" },
            { id: 3, name: "收件人", icon: "usergroup-add" },
        ];
        this.arrTopMenuButton = [
            { id: 0, name: "中国 → 加拿大", icon: "car" },
            { id: 1, name: "加拿大 → 中国", icon: "rocket" }
        ];

        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.objStoreInfo = { store_name: '' };

        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.strTargetOrderNo = "";
        this.LoginStatusCheck();

    }
    // 切换顶部栏菜单
    SwitchTopBarMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let iCurrentInterface = 0;
        if (iMenuID === 0) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "用户信息", icon: "user" },
                { id: 1, name: "包裹管理", icon: "code-sandbox" },
                { id: 2, name: "运单管理", icon: "snippets" },
                { id: 3, name: "收件人", icon: "usergroup-add" },
            ];
        }
        if (iMenuID === 1) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "订单管理", icon: "snippets" },
                { id: 1, name: "发件人", icon: "usergroup-add" },
                { id: 2, name: "收件人", icon: "usergroup-add" }
            ];

        }
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentTopBarIndex: iMenuID,
            iCurrentInterface: iCurrentInterface
        });
    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        if (iMenuID === 5 && this.bMStation) {
            message.info("手机版数据统计还未开放！");
            return;
        }
        this.setState({
            ...this.state,
            bLoading: true,
            arrExpressEnter: []
        });
        if (this.objStoreInfo.type === 5) {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: true,
                // iCurrentInterface: this.arrSideMenuButton[iMenuID].id,
                iCurrentInterface: iMenuID
            });
        } else {
            switch (iMenuID) {
                case 0: {
                    if (this.strCurrentUserName.indexOf('_a') >= 0) {
                        if (global.arrItemTypeData.length === 0) {
                            this.GetItemTypeInfo(iMenuID);
                        } else {
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                bLogin: true,
                                // iCurrentInterface: this.arrSideMenuButton[iMenuID].id,
                                iCurrentInterface: iMenuID
                            });
                        }
                    } else {
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bLogin: true,
                            // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                            iCurrentInterface: iMenuID
                        });
                    }
                    break;
                }
                case 3: {  // 商品页面
                    if (global.arrItemTypeData.length === 0) {
                        this.GetItemTypeInfo(iMenuID);
                    } else {
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bLogin: true,
                            // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                            iCurrentInterface: iMenuID
                        });
                    }
                    break;
                }
                default: {
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true,
                        // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                        iCurrentInterface: iMenuID
                    });
                    break;
                }
            }
        }
    }
    // 退出登录
    LogoutClick = () => {
        global.arrItemTypeData = [];
        // DataStatistic
        // UserActiveUpdate(0, { active: "商家后台: 退出登录", user: this.strCurrentUserName });
        cookie.save('wm_user_code', '', { path: '/' });
        cookie.save('wm_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
        window.location.href = '/webapp/login?store=xiyouji';
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        // 用户识别码检查
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 0 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    cookie.save('wm_user_code', '', { path: '/' });
                    cookie.save('wm_login_status', -1, { path: '/' });
                    // this.JumpToLogin();
                } else {
                    this.objUserInfo = data.user;
                    if (this.objUserInfo.gender === null)
                        this.objUserInfo.gender = 2;
                    this.objStoreInfo.id = 30000;
                    axios('/GetStoreInfo', { params: { id: 30000 } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            let iNewInterface = 0;
                            this.setState({
                                ...this.state,
                                bLogin: true
                            });
                            this.SwitchSideMenu(iNewInterface);
                            // 用户编码的检查和初始化
                            if (this.objUserInfo.storage_code === null || this.objUserInfo.storage_code === "") {
                                // 分配storage Code
                                axios.post('/AllocateUserStorageCode', { id: this.objUserInfo.id, code: 'LK39' })
                                    .then(({ data }) => {
                                        this.objUserInfo.storage_code = data[0].storage_code;
                                    }).catch(function (error) { console.log(error); });
                            } else {
                                this.setState({
                                    ...this.state,
                                    bLogin: true
                                });
                            }

                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    //提交运单
    CreatePackageCombine = (arrExpressEnter) => {
        console.log("UserBK >>> CreatePackageCombine : ", arrExpressEnter);
        this.setState({
            ...this.state,
            iCurrentInterface: 2,
            arrExpressEnter: arrExpressEnter
        });
    }
    // 重置选中包裹信息
    ResetExpressOrderInfo = () => {
        this.setState({
            ...this.state,
            arrExpressEnter: []
        })
    }
    // 跳回首页
    HomePage = () => {
        window.location.href = '/xiyouji';
    }
    // 登录状态检查
    LoginStatusCheck = () => {
        let strUserCode = cookie.load('wm_user_code');
        let iUserLoginStatus = cookie.load('wm_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            if (this.strCurrentUserCode.indexOf('u_') >= 0)
                this.iCurrentUserLevel = 5;
            if (this.strCurrentUserCode.indexOf('s_') >= 0)
                this.iCurrentUserLevel = 9;
            this.GetStoreInfo();
        } else {
            this.LogoutClick();
        }
    }
    // 渲染完毕……
    componentDidMount() {

    }

    render() {

        // 侧边栏
        let uiSideBar = <div />;
        // 顶部栏
        let uiLogo = <div className="store_manage_logo" >商家后台</div>;
        if (this.bMStation)
            uiLogo = null;
        let uiNavBar = (
            <div className="manage_top_bar">
                {uiLogo}
                <div className="manage_top_bar_menu" />
            </div>
        );
        uiSideBar = (
            <Menu className="store_manage_side_bar" defaultSelectedKeys={[this.state.iCurrentInterface.toString()]}
                selectedKeys={[this.state.iCurrentInterface.toString()]}
                mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}  >
                {this.arrSideMenuButton.map((menu, index) => {
                    let uiMenuItem = (
                        <Menu.Item key={menu.id} onClick={this.SwitchSideMenu.bind(this, menu.id)}>
                            <Icon type={menu.icon} />{menu.name}
                        </Menu.Item>
                    );
                    if (menu.sub) {
                        uiMenuItem = (
                            <SubMenu key={menu.id} title={<span><Icon type={menu.icon} />{menu.name}</span>}>
                                {menu.sub.map((sub, sub_index) => {
                                    return (
                                        <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>{sub.name}</Menu.Item>
                                    );
                                })}
                            </SubMenu>
                        );
                    }
                    return (uiMenuItem);
                })}
            </Menu>
        );
        let uiNavBarMenu = null;
        let uiMainTitle = (
            <div className="store_manage_logo return_to_home_button" onClick={this.HomePage.bind(this)}>首页</div>
        );
        uiNavBarMenu = (
            <div className="manage_top_bar_menu">
                <Menu className="manage_top_bar_menu" defaultSelectedKeys={["0"]} mode="horizontal"
                    theme="dark" onClick={this.handleClick} >
                    {this.arrTopMenuButton.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchTopBarMenu.bind(this, index)}>
                                <Icon type={item.icon} />{item.name}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        );
        let uiUserInfoArea = null;
        if (this.state.bLogin) {
            uiUserInfoArea = (
                <div className="manage_login_form" style={{ color: 'white' }}>
                    {this.objUserInfo.name + "（ " + this.objUserInfo.storage_code + " ）"}
                    <Button className="logout_btn" type="primary" htmlType="submit"
                        onClick={this.LogoutClick}>退出登录</Button>
                </div>
            );
        }
        uiNavBar = (
            <div className="manage_top_bar">
                {uiMainTitle}
                {uiNavBarMenu}
                {uiUserInfoArea}
            </div>
        );
        let uiCentralArea = null;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            let uiUserInfo = null;
            let uiExpressEnter = null;
            let uiPackageCombine = null;
            let uiClientManager = null;
            let uiShipperManager = null;
            let uiReceiverManager = null;
            if (this.state.iCurrentTopBarIndex === 0) {
                uiUserInfo = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <UserInfoJW iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation} />
                );
                uiExpressEnter = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <ExpressEnterJW iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation}
                        CreatePackageCombine={this.CreatePackageCombine.bind(this)} />
                );
                uiPackageCombine = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <PackageCombineJW iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation}
                        arrExpressEnter={this.state.arrExpressEnter} ResetExpressOrderInfo={this.ResetExpressOrderInfo.bind(this)} />
                );
                uiClientManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <StoreClientManagerJW iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation} iCurrentTopBarIndex={this.state.iCurrentTopBarIndex} />
                );
            }
            // CA To CN Order
            if (this.state.iCurrentTopBarIndex === 1) {
                uiExpressEnter = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <CACNOrder iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation}
                        CreatePackageCombine={this.CreatePackageCombine.bind(this)} />
                );
                uiShipperManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <StoreShipperManager iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation} />
                );
                uiReceiverManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <StoreReceiverManager iStoreID={this.objStoreInfo.id}
                        objUserInfo={this.objUserInfo} bMStation={this.bMStation} />
                );
            }

            uiCentralArea = (
                <div>
                    {/* 0. 用户信息 */}
                    {uiUserInfo}
                    {/* 1. 包裹管理 */}
                    {uiExpressEnter}
                    {/* 2. 运单管理 */}
                    {uiPackageCombine}
                    {/* 3. 发件人/收件人管理 */}
                    {uiClientManager}
                    {uiShipperManager}
                    {uiReceiverManager}
                </div>
            );
            // 手机站布局
            // if (this.bMStation) {

            // }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(UserBackendJW);
