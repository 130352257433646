import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// Mouthing客户端
import Mouthing from "./pages/ClientSide/Mouthing.js";
// 物流客户端
import TransitTracking from "./pages/ClientSide/TransitTracking.js";
// 天猫UBI转运服务
import JWSmallParcel from "./pages/ClientSide/JWSmallParcel.js";
// Mouthing管理后台
// import WebAppManager from "./pages/Background/WebAppManager.js";  // 管理员后台
// import StoreManager from "./pages/Background/StoreManager.js";  // 商家管理后台
// import StorePlayer from "./pages/Background/StorePlayer";  // 商家控制后台（小功能&特殊功能）

class App extends Component {
    render() {
        // document.title = "Mouthing互动娱乐服务平台";
        document.title = "服务/娱乐/资讯/便民";

        const App = () => (
            <div>
                <Switch>
                    {/* Mouthing客户端 */}
                    <Route path="/" exact component={Mouthing} />
                    <Route path="/zhihe" exact component={Mouthing} />
                    <Route path="/xiyouji" exact component={Mouthing} />
                    <Route path="/xiyouji" component={Mouthing} />
                    <Route path="/sooning" exact component={Mouthing} />
                    <Route path="/sooning" component={Mouthing} />
                    <Route path="/lekon" exact component={Mouthing} />
                    <Route path="/lekon" component={Mouthing} />
                    <Route path="/jeton" exact component={Mouthing} />
                    <Route path="/jeton" component={Mouthing} />
                    <Route path="/webapp" exact component={Mouthing} />
                    <Route path="/webapp" component={Mouthing} />
                    <Route path="/tracking" component={TransitTracking} />
                    <Route path="/smallparcel" component={JWSmallParcel} />
                    {/* Mouthing管理后台 */}
                    {/* <Route path="/webapp-manager" component={WebAppManager} />
                    <Route path="/store-manager" component={StoreManager} />
                    <Route path="/store-player/:id" component={StorePlayer} /> */}
                </Switch>
            </div>
        );
        return (
            <div>
                <App />
            </div>
        );
    }
}

export default App;
